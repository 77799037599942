<div class="container">
  <div class="small-div fs-22 ml-10 fw-500" *ngIf="header">
    {{ header }}
  </div>
  <div class="small-div ml-10" *ngIf="subheader">
    {{ subheader }}
  </div>
  <div class="small-div">
    <ion-searchbar (ionChange)="searchbarInputHandler($event?.detail?.value)"></ion-searchbar>
  </div>
  <div class="big-div overflow-auto">
    <ion-item *ngFor="let option of optionList" button (click)="dismiss(option)">
      <ion-label [color]="initialData === option ? 'brand-primary' : ''">
        {{ option }}
      </ion-label>
    </ion-item>
  </div>
  <div class="small-div mt-10">
    <ion-button
      fill="outline"
      color="button-primary"
      mode="ios"
      class="m-0 width-100-pct pl-10 pr-10"
      (click)="dismiss()"
    >
      {{ 'General.Cancel' | translate }}
    </ion-button>
  </div>
</div>
