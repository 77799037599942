import { Component, forwardRef } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputsConfig } from '../../../classes/commons/inputs';

@Component({
  selector: 'app-input-textarea-stacked-label',
  templateUrl: './input-textarea-stacked-label.component.html',
  styleUrls: ['./input-textarea-stacked-label.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextareaStackedLabelComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTextareaStackedLabelComponent),
      multi: true,
    },
  ],
})
export class InputTextareaStackedLabelComponent extends InputsConfig {}
