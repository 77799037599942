import { Component, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

// TODO: Check if we can replace this one of the other input components

@Component({
  selector: 'app-input-with-inline-button',
  templateUrl: './input-with-inline-button.component.html',
  styleUrls: ['./input-with-inline-button.component.scss'],
})
export class InputWithInlineButtonComponent {
  @Input() inputType: string;
  @Input() placeholder: string = '';
  @Input() submitButton: boolean;
  @Input() isDisable: boolean;
  @Input() maxLength: number | null = null;
  @Output() valueChangeEvent = new EventEmitter<string | number>();

  value: string | number;

  valueChanged($event: string | number): void {
    if (!this.submitButton) {
      this.valueChangeEvent.emit($event);
    } else {
      this.value = $event;
    }
  }

  valueSubmitted(): void {
    if (this.submitButton) {
      this.valueChangeEvent.emit(this.value);
    }
  }
}
