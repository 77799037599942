<ion-card class="card-design">
  <div *ngIf="facade?.vm$ == null || (facade.vm$ | async) == null || (facade.vm$ | async).loading; else ready">
    <app-skeleton-items [skeletonType]="SkeletonPlaceholder.CARD" class="w-100" [showCard]="true"></app-skeleton-items>
  </div>
  <ng-template #ready>
    <div class="mat-elevation-z8" *ngIf="!(facade.vm$ | async).error; else error">
      <div class="table-title-row">
        <div class="fs-22 fw-600 text-dark">{{ 'VirtualPowerPlant.Units' | translate }}</div>
        <div class="filter-div">
          <ion-searchbar
            class="base-input"
            showCancelButton="never"
            placeholder="Filter"
            autocomplete="on"
            [debounce]="300"
            (ionInput)="applyFilter($event.target.value)"
          ></ion-searchbar>
        </div>
      </div>
      <table mat-table [dataSource]="datasource" matSort>
        <ng-container matColumnDef="status">
          <th class="width-3-5-pct" mat-header-cell *matHeaderCellDef rowspan="2"></th>
          <td mat-cell *matCellDef="let row">
            <div
              class="ml-5 status-circle"
              [ngClass]="{
                'online-pulse-circle': row.activity$ | async,
                'online-pulse-circle2': !(row.activity$ | async)
              }"
            ></div>
          </td>
        </ng-container>
        <ng-container matColumnDef="unitName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header rowspan="2">
            {{ 'General.Unit' | translate }}
          </th>
          <td mat-cell *matCellDef="let row" class="cursor-pointer" (click)="navToUnit(row?.unitId)">
            {{ row?.unitName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="controlGroupNames">
          <th mat-header-cell *matHeaderCellDef mat-sort-header rowspan="2">
            {{ 'VirtualPowerPlant.ControlGroup' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row?.controlGroupNames }}</td>
        </ng-container>
        <ng-container matColumnDef="grid">
          <th class="bl-1 br-1 widthForValues ion-text-center" mat-header-cell *matHeaderCellDef>
            {{ 'Portfolio.Grid' | translate }}
          </th>
          <td class="ion-text-center bl-1 br-1" mat-cell *matCellDef="let row">
            {{ row?.gridPower$ | async | number: '1.0-3' }}
          </td>
        </ng-container>
        <ng-container *ngFor="let type of deviceTypes">
          <ng-container>
            <ng-container [matColumnDef]="type + '_pwr_1'">
              <th class="widthForValues ion-text-center" mat-header-cell *matHeaderCellDef></th>
              <td class="ion-text-center br-1-dashed" mat-cell *matCellDef="let row">
                {{ row.deviceInfo[type]?.power$ | async | number: '1.0-3' }}
              </td>
            </ng-container>
            <ng-container [matColumnDef]="type + '_ctrl_1'">
              <th class="widthForValues ion-text-center" mat-header-cell *matHeaderCellDef>
                {{ type }}
              </th>
              <td class="ion-text-center br-1-dashed" mat-cell *matCellDef="let row">
                <div *ngFor="let control of row.deviceInfo[type]?.activeControls$ | async">
                  {{ control }}
                </div>
              </td>
            </ng-container>
            <ng-container [matColumnDef]="type + '_soc_1'" *ngIf="type === VppUnitDeviceTypes.BESS">
              <th class="widthForValues ion-text-center" mat-header-cell *matHeaderCellDef></th>
              <td class="ion-text-center br-1-dashed" mat-cell *matCellDef="let row">
                <div>
                  {{ row.deviceInfo[type]?.soc$ | async | number: '1.0-3' }}
                </div>
              </td>
            </ng-container>
            <ng-container [matColumnDef]="type + '_qty_1'">
              <th class="br-1 widthForValues ion-text-center" mat-header-cell *matHeaderCellDef></th>
              <td class="ion-text-center br-1" mat-cell *matCellDef="let row">
                {{ row.deviceInfo[type]?.count }}
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container matColumnDef="temp">
          <th class="ion-text-center pr-0" mat-header-cell *matHeaderCellDef rowspan="2">
            {{ 'VirtualPowerPlant.Temp' | translate }}
          </th>
          <td class="ion-text-center pr-0" mat-cell *matCellDef="let row">
            {{ row?.temperature$ | async | number: '1.0-3' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="grid_pwr">
          <th class="bl-1 ion-text-center" mat-header-cell *matHeaderCellDef>PWR</th>
        </ng-container>
        <ng-container *ngFor="let type of deviceTypes">
          <ng-container
            [matColumnDef]="type + '_soc_2'"
            *ngIf="type === VppUnitDeviceTypes.BESS || type === VppUnitDeviceTypes.HYBRID"
          >
            <th class="bl-1 br-1-dashed ion-text-center" mat-header-cell *matHeaderCellDef>SOC</th>
          </ng-container>
          <ng-container [matColumnDef]="type + '_pwr_2'">
            <th class="bl-1 br-1-dashed ion-text-center" mat-header-cell *matHeaderCellDef>PWR</th>
          </ng-container>
          <ng-container [matColumnDef]="type + '_ctrl_2'">
            <th class="br-1-dashed ion-text-center" mat-header-cell *matHeaderCellDef>CTRL</th>
          </ng-container>
          <ng-container [matColumnDef]="type + '_qty_2'">
            <th class="ion-text-center br-1-dashed" mat-header-cell *matHeaderCellDef>QTY</th>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="firstRowHeader"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="sencondRowHeader"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <ng-template #error>
      <div class="icon-text-wrapper p-20">
        <ion-icon class="status-icon" src="assets/icons/svg/warning.svg"></ion-icon>
        <div class="fs-20 text-medium ion-text-center">{{ (facade.vm$ | async).error }}</div>
      </div>
    </ng-template>
  </ng-template>
  <mat-paginator
    [hidden]="!(facade?.vm$ | async)?.data"
    [pageSizeOptions]="VPP_TABLE_PAGE_SIZE_OPTIONS"
  ></mat-paginator>
</ion-card>
