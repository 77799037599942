import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { BrowserLogger } from '@class/core/browser-logger';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { VppControlGroupEditForm, VppSettingGroupsTabControlGroup } from '../../../classes/vpp/vpp-control-group.model';
import { VppUnitDeviceTypes, VPP_CONTROL_GROUP_UNIT_TABLE_DEVICE_TYPE_INFO } from '../../../classes/vpp/vpp-unit.model';
import {
  VppControlGroupEditCheckboxState,
  VppControlGroupEditRow,
  VppSettingEditControlGroupFacadeService,
} from '../../../services/virtualPowerPlants/facades/setting/group/edit-control-group/vpp-setting-edit-control-group-facade.service';

@Component({
  selector: 'app-vpp-edit-control-group-modal',
  templateUrl: './vpp-edit-control-group-modal.component.html',
  styleUrls: ['./vpp-edit-control-group-modal.component.scss'],
  providers: [VppSettingEditControlGroupFacadeService],
})
export class VppEditControlGroupModalComponent implements OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @Input()
  set controlGroup(controlGroup: VppSettingGroupsTabControlGroup) {
    setTimeout(() => {
      this.editControlGroupFacade.setControlGroupToEdit(controlGroup);
    }, 0);
  }
  public vm$ = this.editControlGroupFacade.viewModelSubject$;
  public deviceClasses = Object.values(VppUnitDeviceTypes);
  public readonly VppControlGroupEditCheckboxState = VppControlGroupEditCheckboxState;
  public readonly PermissionKey = PermissionKey;
  public deviceIconMap = VPP_CONTROL_GROUP_UNIT_TABLE_DEVICE_TYPE_INFO;
  public deviceClassToRow2Map: Record<VppUnitDeviceTypes, string> = Object.values(VppUnitDeviceTypes).reduce(
    (map: Record<VppUnitDeviceTypes, string>, deviceType: VppUnitDeviceTypes) => {
      map[deviceType] = deviceType + '2';
      return map;
    },
    {} as Record<VppUnitDeviceTypes, string>,
  );
  public unitRowsDataSource: MatTableDataSource<{ uuid: string; checkboxes: VppControlGroupEditRow }>;
  public displayedRow1Columns: string[] = Object.values(VppUnitDeviceTypes).map((deviceType) => deviceType);
  public displayedRow2Columns: string[] = ['name2', 'select2'].concat(
    Object.values(VppUnitDeviceTypes).map((deviceType) => deviceType + '2'),
  );
  public controlGroupEdit: VppControlGroupEditForm = {
    uuid: null,
    id: null,
    name: null,
    description: null,
  };
  private filterString: string;

  private viewModelSubscription: Subscription;
  private submitEditDataSubscription: Subscription;
  constructor(
    private editControlGroupFacade: VppSettingEditControlGroupFacadeService,
    private viewCtrl: ModalController,
  ) {
    BrowserLogger.log('VppEditControlGroupModal.constructor', { editControlGroupFacade });
    this.viewModelSubscription = this.vm$.subscribe(async (vm) => {
      BrowserLogger.log('VppEditControlGroupModal.editControlGroupFacade.subscribe', { vm });
      if (vm.dismiss) await this.dismiss();
      this.controlGroupEdit = {
        uuid: vm?.data?.uuid,
        id: vm?.data?.id,
        name: vm?.data?.name,
        description: vm?.data?.description,
      };

      const rows = vm?.data?.unitSelects ?? [];

      this.unitRowsDataSource = new MatTableDataSource(rows);
      this.unitRowsDataSource.filter = this.filterString;
      this.unitRowsDataSource.paginator = this.paginator;
    });
  }

  public async dismiss(): Promise<void> {
    BrowserLogger.log('VppEditControlGroupModal.dismiss');
    await this.viewCtrl.dismiss({ data: null, error: null });
  }

  public selectSingleUnitDevice(state: boolean, unitUuid: string, deviceClass: VppUnitDeviceTypes): void {
    BrowserLogger.log('VppEditControlGroupModal.selectSingleUnitDevice', { state, unitUuid, deviceClass });
    const checkboxState = this.convertBooleanToCheckboxState(state);
    this.editControlGroupFacade.setSingleUnitDeviceState(checkboxState, unitUuid, deviceClass);
  }

  public selectAllDevicesInUnit(state: boolean, unitUuid: string): void {
    BrowserLogger.log('VppEditControlGroupModal.selectAllDevicesInUnit', { state, unitUuid });
    const checkboxState = this.convertBooleanToCheckboxState(state);
    this.editControlGroupFacade.setAllDevicesInUnit(checkboxState, unitUuid);
  }

  public selectAllDevicesClass(state: boolean, deviceClass: VppUnitDeviceTypes): void {
    BrowserLogger.log('selectAllDevicesClass.selectAllDevicesInUnit', { state, deviceClass });
    const checkboxState = this.convertBooleanToCheckboxState(state);
    this.editControlGroupFacade.setOneDeviceClassForAllUnits(checkboxState, deviceClass);
  }

  public selectAllUnitDevices(state: boolean): void {
    BrowserLogger.log('selectAllDevicesClass.selectAllUnitDevices', { state });
    const checkboxState = this.convertBooleanToCheckboxState(state);
    this.editControlGroupFacade.setAllDevicesForAllUnits(checkboxState);
  }

  private convertBooleanToCheckboxState(
    state: boolean,
  ): VppControlGroupEditCheckboxState.CHECKED | VppControlGroupEditCheckboxState.EMPTY {
    return state ? VppControlGroupEditCheckboxState.CHECKED : VppControlGroupEditCheckboxState.EMPTY;
  }

  public submit(): void {
    BrowserLogger.log('selectAllDevicesClass.submit');
    this.editControlGroupFacade.submitEditInfo(this.controlGroupEdit);
  }

  public applyFilter(filterValue: string): void {
    BrowserLogger.log('selectAllDevicesClass.applyFilter', { filterValue });
    this.filterString = filterValue.trim().toLowerCase();
    this.unitRowsDataSource.filter = this.filterString;
  }

  ngOnDestroy(): void {
    BrowserLogger.log('selectAllDevicesClass.ngOnDestroy');
    this.viewModelSubscription?.unsubscribe();
    this.submitEditDataSubscription?.unsubscribe();
  }
}
