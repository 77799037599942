<ion-grid>
  <ion-row>
    <ion-col>
      <div class="ion-text-center">
        <h6>{{ 'General.Error.ServerUnavailable' | translate }}</h6>
        <h4>{{ 'General.Error.FailedToFetchData' | translate }}</h4>
        <div *ngIf="data != null && data != undefined">
          {{ data.status | translate }} {{ data.statusText | translate }}
        </div>
        <div *ngIf="data != null && data != undefined">
          {{ data?.error?.message }}
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
