import { Pipe, PipeTransform } from '@angular/core';
import {
  getVppDemandTypeTranslationString,
  returnRaiseVppDemandTypeGivenMode,
  VppModes,
} from '../../classes/vpp/vpp-demand-type-mode.model';
import { TranslationsService } from '../../services/common/translations.service';

@Pipe({
  name: 'vppModeToRaiseDemandTypeString',
})
export class VppModeToRaiseDemandTypeStringPipe implements PipeTransform {
  constructor(private trans: TranslationsService) {}

  transform(mode: VppModes): string {
    const demandType = returnRaiseVppDemandTypeGivenMode(mode);
    const translationKey = getVppDemandTypeTranslationString(demandType);
    return this.trans.instant(translationKey);
  }
}
