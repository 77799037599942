<ion-button
  (click)="handleClick()"
  color="button-primary"
  expand="block"
  fill="outline"
  class="back-button"
  mode="ios"
  [disabled]="isDisabled"
>
  <span class="fw-600 fs-16 ion-text-capitalize"> {{ 'General.Back' | translate }} </span>
</ion-button>
