<ion-card class="card-design">
  <form (ngSubmit)="submit(form.value)" [formGroup]="form" *ngIf="form" id="controlTargetForm">
    <ion-list-header>
      <h4>{{ 'VirtualPowerPlant.CreateVPP.ControlTarget' | translate }}</h4>
    </ion-list-header>
    <ion-item class="input-item ion-margin-bottom" lines="none">
      <ion-select
        class="max-width-100-pct"
        formControlName="thresholdType"
        interface="popover"
        label="{{ 'VirtualPowerPlant.CreateVPP.SelectReference' | translate }}"
        labelPlacement="floating"
      >
        <ion-select-option *ngFor="let type of VppThresholdType | keyvalue" [value]="type.value">
          {{ type.value }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <div [formGroupName]="VppThresholdType.POWER" *ngIf="(form?.value)[VppThresholdType.POWER]">
      <ion-item class="input-item ion-margin-bottom" lines="none" [disabled]="!units">
        <ion-input
          class="max-width-100-pct"
          formControlName="referenceUnitName"
          (click)="showFilterSelectForPowerUnits()"
          readonly
          label="{{ 'VirtualPowerPlant.CreateVPP.SelectUnit' | translate }}"
          labelPlacement="floating"
        >
        </ion-input>
      </ion-item>
      <ion-item class="input-item ion-margin-bottom" lines="none">
        <ion-input
          class="max-width-100-pct"
          formControlName="refDeviceMetricName"
          (click)="showFilterSelectForPowerMetric()"
          readonly
          label="{{ 'VirtualPowerPlant.CreateVPP.SelectMetric' | translate }}"
          labelPlacement="floating"
        >
        </ion-input>
      </ion-item>
      <ion-item class="input-item ion-margin-bottom" lines="none">
        <ion-input
          formControlName="maxDemand"
          type="number"
          label="{{ 'VirtualPowerPlant.CreateVPP.UpperDemandControlTarget' | translate }} (kW)"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
      <ion-item class="input-item ion-margin-bottom" lines="none">
        <ion-input
          formControlName="minDemand"
          type="number"
          label="{{ 'VirtualPowerPlant.CreateVPP.LowerDemandControlTarget' | translate }} (kW)"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </div>
    <div [formGroupName]="VppThresholdType.PRICE" *ngIf="(form?.value)[VppThresholdType.PRICE]">
      <ion-item *ngIf="availableMarkets?.length > 0" class="input-item ion-margin-bottom" lines="none">
        <ion-select
          class="max-width-100-pct"
          interface="popover"
          formControlName="market"
          label="{{ 'VirtualPowerPlant.CreateVPP.SelectMarket' | translate }}"
          labelPlacement="floating"
        >
          <ion-select-option *ngFor="let market of availableMarkets" [value]="market.uuid">
            {{ market.name }} - {{ market.description }}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item class="input-item ion-margin-bottom" lines="none">
        <ion-input
          formControlName="maxPrice"
          type="number"
          label="{{ 'VirtualPowerPlant.CreateVPP.UpperPriceControlTarget' | translate }} ($)"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
      <ion-item class="input-item ion-margin-bottom" lines="none">
        <ion-input
          formControlName="minPrice"
          type="number"
          label="{{ 'VirtualPowerPlant.CreateVPP.LowerPriceControlTarget' | translate }} ($)"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </div>
    <div *ngIf="(form?.value)[VppThresholdType.SCADA]">
      <ion-item class="input-item ion-margin-bottom" lines="none">
        <ion-label>{{ 'VirtualPowerPlant.CreateVPP.SCADATakeOffPoints' | translate }} </ion-label>
        <ion-button color="button-primary" (click)="addTakeOffPoint()" slot="end">
          <ion-icon slot="icon-only" name="add"></ion-icon>
        </ion-button>
      </ion-item>
      <div *ngFor="let takeOff of form.controls[VppThresholdType.SCADA].controls; let i = index">
        <div [formGroup]="takeOff">
          <ion-grid>
            <ion-row class="bt-1">
              <ion-col size="5" class="flex-center">
                {{ (form?.value)[VppThresholdType.SCADA]?.value }}
                <ion-item *ngIf="units" lines="none">
                  <ion-input
                    class="max-width-100-pct"
                    formControlName="unitName"
                    (click)="showFilterSelectForScadaUnits(i)"
                    readonly
                    label="{{ 'VirtualPowerPlant.CreateVPP.SelectUnit' | translate }}"
                    labelPlacement="floating"
                  >
                  </ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="5" class="flex-center">
                <ion-item lines="none" *ngIf="takeOff.value.unitName">
                  <ion-input
                    class="max-width-100-pct"
                    formControlName="deviceName"
                    (click)="showFilterSelectForScadaDevice(i)"
                    readonly
                    label="{{ 'VirtualPowerPlant.CreateVPP.selectDevice' | translate }}"
                    labelPlacement="floating"
                  >
                  </ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="2" class="flex-center flex-end">
                <ion-button fill="clear" color="medium" (click)="removeTakeOff(i)">
                  <ion-icon slot="icon-only" name="close"></ion-icon>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </div>
    <div class="ion-padding">
      <ion-button
        *ifHasPermission="[PermissionKey.VPP_CHANGE_VPP]"
        [disabled]="form.invalid"
        color="button-primary"
        size="large"
        type="submit"
        expand="block"
      >
        {{ 'VirtualPowerPlant.UpdateVPP.Title' | translate }}
      </ion-button>
    </div>
  </form>
</ion-card>
