<div *ngIf="rowCount > 0; else noUnits">
  <ion-grid>
    <ion-row>
      <ion-col size-lg="6">
        <ion-searchbar
          class="base-input"
          showCancelButton="never"
          [(ngModel)]="search"
          placeholder="Filter"
          autocomplete="on"
          [debounce]="1000"
          (ionInput)="applyFilter($event.target.value)"
        ></ion-searchbar>
      </ion-col>
      <ion-col size-lg="6">
        <ion-item lines="none">
          <ion-select
            interface="popover"
            class="base-input"
            aria-label="Vpp mode"
            [ngModel]="vppMode$ | async"
            (ngModelChange)="vppModeSelected($event)"
          >
            <ng-container *ngFor="let mode of VppModes | keyvalue">
              <ion-select-option [value]="mode.key">{{ mode.value }}</ion-select-option>
            </ng-container>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
  <table mat-table [dataSource]="dataSource" class="width-100-pct">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef rowspan="2">{{ 'VirtualPowerPlant.UnitName' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.unitName }}</td>
    </ng-container>
    <ng-container *ngFor="let deviceClass of deviceClasses" [matColumnDef]="deviceClass">
      <th class="width-10-pct ion-text-center tooltip" mat-header-cell *matHeaderCellDef rowspan="2">
        <span class="tooltiptext">{{ deviceIconMap[deviceClass].iconHoverText | translate }}</span>
        <ion-icon [name]="deviceIconMap[deviceClass].iconName" size="small"></ion-icon>
      </th>
      <td class="ion-text-center" mat-cell *matCellDef="let element">{{ element.deviceCount[deviceClass] }}</td>
    </ng-container>
    <ng-container matColumnDef="dr_avail">
      <th class="zIndex101 ion-text-end width-10-pct tooltip" mat-header-cell *matHeaderCellDef>
        <span class="tooltiptext">{{ vppMode$ | async | vppModeToLowerDemandTypeString }}</span>
        <ion-icon class="iconFS" name="trending-down"></ion-icon>
      </th>
      <td
        class="ion-text-center br-1-dashed"
        [ngClass]="{ 'fw-600': (element.power.lower.available$ | async) > 0 }"
        mat-cell
        *matCellDef="let element"
      >
        {{ element.power.lower.available$ | async | number: '1.2-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dr_disp">
      <th class="width-10-pct ion-text-center" mat-header-cell *matHeaderCellDef></th>
      <td
        class="ion-text-center br-1 pr-0"
        [ngClass]="{ 'fw-600': (element.power.lower.dispatched$ | async) > 0 }"
        mat-cell
        *matCellDef="let element"
      >
        {{ element.power.lower.dispatched$ | async | number: '1.2-2' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="di_avail">
      <th class="bl-1 ion-text-end zIndex101 width-10-pct tooltip" mat-header-cell *matHeaderCellDef>
        <span class="tooltiptext">{{ vppMode$ | async | vppModeToRaiseDemandTypeString }}</span>
        <ion-icon class="iconFS" name="trending-up"></ion-icon>
      </th>
      <td
        class="ion-text-center br-1-dashed bl-1"
        [ngClass]="{ 'fw-600': (element.power.raise.available$ | async) > 0 }"
        mat-cell
        *matCellDef="let element"
      >
        {{ element.power.raise.available$ | async | number: '1.2-2' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="di_disp">
      <th class="br-1 width-10-pct ion-text-center" mat-header-cell *matHeaderCellDef></th>
      <td
        class="ion-text-center br-1"
        [ngClass]="{ 'fw-600': (element.power.raise.dispatched$ | async) > 0 }"
        mat-cell
        *matCellDef="let element"
      >
        {{ element.power.raise.dispatched$ | async | number: '1.2-2' }}
      </td>
    </ng-container>
    <!-- row 2 -->
    <ng-container matColumnDef="di_avail_head">
      <th class="bl-1 br-1-dashed width-10-pct ion-text-center" mat-header-cell *matHeaderCellDef>
        {{ 'VirtualPowerPlant.Avail' | translate }}
      </th>
    </ng-container>

    <ng-container matColumnDef="di_disp_head">
      <th class="br-1 width-10-pct ion-text-center" mat-header-cell *matHeaderCellDef>
        {{ 'VirtualPowerPlant.Disp' | translate }}
      </th>
    </ng-container>

    <ng-container matColumnDef="dr_avail_head">
      <th class="br-1-dashed width-10-pct ion-text-center" mat-header-cell *matHeaderCellDef>
        {{ 'VirtualPowerPlant.Avail' | translate }}
      </th>
    </ng-container>
    <ng-container matColumnDef="dr_disp_head">
      <th class="width-10-pct ion-text-center" mat-header-cell *matHeaderCellDef>
        {{ 'VirtualPowerPlant.Disp' | translate }}
      </th>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="firstRowHeader"></tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns2; sticky: true" class="sencondRowHeader"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="VPP_TABLE_PAGE_SIZE_OPTIONS"></mat-paginator>
</div>
<ng-template #noUnits>
  <div id="noUnitsString">{{ 'VirtualPowerPlant.NoUnitsDisplayed' | translate }}</div>
</ng-template>
