import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BrowserLogger } from '@class/core/browser-logger';
import { isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, from, merge, Subject, Subscription, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Unit } from '../../../../../../classes/commons/unit.model';
import {
  VppControlGroupEditForm,
  VppControlGroupPutPayload,
  VppControlGroupUnitUpdatePayload,
  VppSettingGroupsTabControlGroup,
} from '../../../../../../classes/vpp/vpp-control-group.model';
import { VppUnit, VppUnitDeviceTypes } from '../../../../../../classes/vpp/vpp-unit.model';
import { TranslationsService } from '../../../../../common/translations.service';
import { UnitsService } from '../../../../../units/units.service';
import { VppControlGroupService } from '../../../../control-group/vpp-control-group.service';
import { VppUnitsService } from '../../../../units/vpp-units.service';
import { VirtualPowerPlantsService } from '../../../../virtual-power-plants.service';

export interface VppControlGroupEditViewModel {
  loading: string;
  error: string;
  dismiss: boolean;
  data: VppControlGroupEditInfo;
}

export enum VppControlGroupEditCheckboxState {
  EMPTY = 0,
  CHECKED = 1,
  DISABLED = 2,
}

type CheckboxTickedOrEmpty = VppControlGroupEditCheckboxState.EMPTY | VppControlGroupEditCheckboxState.CHECKED;

interface CheckboxToModify {
  unitUuid: string;
  deviceClass: VppUnitDeviceTypes;
  state: CheckboxTickedOrEmpty;
}

export interface VppControlGroupEditRow {
  name: string;
  selectAll: VppControlGroupEditCheckboxState;
  selectClass: Record<VppUnitDeviceTypes, VppControlGroupEditCheckboxState>;
}

export interface VppControlGroupEditInfo {
  uuid: string;
  id: string;
  name: string;
  description: string;
  active: boolean;

  unitAvailNumber: number;
  unitTotalNumber: number;

  overallSelect: VppControlGroupEditRow;
  unitSelects: { uuid: string; unitName: string; checkboxes: VppControlGroupEditRow }[];
}

@Injectable()
export class VppSettingEditControlGroupFacadeService implements OnDestroy {
  private controlGroupToEditSubject = new BehaviorSubject<VppSettingGroupsTabControlGroup>(null);
  private isLoadingSubject = new BehaviorSubject<string>(this.trans.instant('VirtualPowerPlant.FetchingUnitList'));
  private isErrorSubject = new BehaviorSubject<any>(null);
  private isDismissSubject = new BehaviorSubject<boolean>(false);
  private initialisedRowsSubject = new BehaviorSubject<Record<string, VppControlGroupEditRow>>(null);
  private checkboxesTickedSubject = new BehaviorSubject<CheckboxToModify[]>(null);
  private allCheckboxesForOneDeviceClassTickedSubject = new BehaviorSubject<CheckboxToModify>(null);
  private rowsWithUserTicksSubject = new BehaviorSubject<{
    overallSelect: VppControlGroupEditRow;
    unitSelects: Record<string, VppControlGroupEditRow>;
  }>(null);
  private deviceCheckboxToModify$ = combineLatest([
    this.allCheckboxesForOneDeviceClassTickedSubject,
    this.unitsService.allUnits$,
  ]).pipe(
    map(([deviceCheckbox, units]) =>
      this.adaptDeviceClassTickToMultipleTicks(deviceCheckbox?.state, deviceCheckbox?.deviceClass, units),
    ),
  );
  private checkboxChanged$ = merge(this.checkboxesTickedSubject, this.deviceCheckboxToModify$);
  private editInfo$ = combineLatest([this.rowsWithUserTicksSubject, this.controlGroupToEditSubject]).pipe(
    tap(() => this.isLoadingSubject.next(null)),
    map(([checkboxRows, controlGroup]) =>
      this.assembleModalData(controlGroup, checkboxRows?.overallSelect, checkboxRows?.unitSelects),
    ),
  );
  public viewModelSubject$ = new BehaviorSubject<VppControlGroupEditViewModel>(null);

  private modifiedRows: {
    overallSelect: VppControlGroupEditRow;
    unitSelects: Record<string, VppControlGroupEditRow>;
  };

  // submit data observable stream
  private controlGroupUpdatedSubject = new Subject<VppControlGroupEditForm>();
  public controlGroupPut$ = combineLatest([this.controlGroupUpdatedSubject, this.vppService.vppSelected$]).pipe(
    take(1),
    tap(() => this.isLoadingSubject.next(this.trans.instant('VirtualPowerPlant.SubmittingControlGroupDetails'))),
    map(([groupDetail, vpp]) => this.adaptControlGroupPutPayload(groupDetail, vpp?.id)),
    switchMap((payload) => this.vppControlGroupService.updateControlGroupDetails(payload)),
  );
  private controlGroupUnitPutPayloads$ = combineLatest([
    this.controlGroupPut$,
    this.initialisedRowsSubject,
    this.rowsWithUserTicksSubject,
  ]).pipe(
    tap(() => this.isLoadingSubject.next(this.trans.instant('VirtualPowerPlant.UpdatingControlGroupUnits'))),
    map(([_, originalRows, currentRows]) => {
      return this.adaptEditInfoToPayload(currentRows.unitSelects, originalRows);
    }),
  );
  private controlGroupUnitPut$ = combineLatest([
    this.controlGroupUnitPutPayloads$,
    this.controlGroupUpdatedSubject.pipe(distinctUntilChanged()),
  ]).pipe(
    take(1),
    switchMap(([payload, controlGroup]) =>
      from(this.vppUnitsService.modifyControlGroupUnits(payload, controlGroup.uuid)).pipe(
        catchError((e) => throwError([this.trans.instant('VirtualPowerPlant.FailedToUpdateUnits'), e])),
      ),
    ),
  );

  private subscriptions: Subscription[];

  constructor(
    private unitsService: UnitsService,
    private vppUnitsService: VppUnitsService,
    private vppService: VirtualPowerPlantsService,
    private vppControlGroupService: VppControlGroupService,
    private trans: TranslationsService,
  ) {
    this.subscriptions = [
      // initialises unit rows before user interaction
      combineLatest([this.unitsService.allUnits$, this.controlGroupToEditSubject, this.vppUnitsService.units$])
        .pipe(takeUntil(this.isDismissSubject.pipe(filter((isdismiss) => isdismiss == true))))
        .subscribe(([allUnits, controlGroupToEdit, units]) => {
          const initialisedRows = this.initialiseRows(controlGroupToEdit, allUnits, units);
          this.initialisedRowsSubject.next(initialisedRows);
        }),

      // process user interaction
      combineLatest([this.initialisedRowsSubject, this.checkboxChanged$]).subscribe(
        ([initalisedRows, multipleCheckboxTicked]) => {
          this.modifiedRows = isEmpty(this.modifiedRows)
            ? this.processUserTicks(multipleCheckboxTicked, { ...initalisedRows })
            : this.processUserTicks(multipleCheckboxTicked, this.modifiedRows.unitSelects);
          this.rowsWithUserTicksSubject.next(this.modifiedRows);
        },
      ),

      // present view model
      combineLatest([
        this.editInfo$.pipe(startWith(null as VppControlGroupEditInfo)),
        this.isLoadingSubject,
        this.isErrorSubject,
        this.isDismissSubject,
      ]).subscribe(([data, loading, error, dismiss]): void => {
        this.viewModelSubject$.next({ data, loading, error, dismiss });
      }),

      // change state when finish sending user submission to backend
      this.controlGroupUnitPut$.subscribe(
        (res: { data: { errors: string[] } }) => {
          if (!isEmpty(res?.data?.errors)) {
            this.isErrorSubject.next(
              this.trans.instant('VirtualPowerPlant.FailedToUpdateFollowingUnits') + res?.data?.errors.join(),
            );
            this.isLoadingSubject.next(null);
          } else {
            this.isDismissSubject.next(true);
          }
          this.vppControlGroupService.refreshControlGroup();
          this.vppUnitsService.refreshControlGroupUnits();
        },
        (err) => {
          console.error(err[1]);
          if (err[1] instanceof HttpErrorResponse) {
            this.isErrorSubject.next(`${err[0]}: ${err[1].status} ${err[1].statusText}`);
          } else {
            this.isErrorSubject.next(err[0]);
          }
          this.isLoadingSubject.next(null);
        },
      ),
    ];
  }

  public setControlGroupToEdit(controlGroup: VppSettingGroupsTabControlGroup): void {
    this.controlGroupToEditSubject.next(controlGroup);
  }

  // <--------- initialise unit rows functions ---------
  private initialiseRows(
    controlGroupToEdit: VppSettingGroupsTabControlGroup,
    allUnits: Unit[],
    vppUnits: VppUnit[],
  ): Record<string, VppControlGroupEditRow> {
    const rowsWithEmptyOrDisabledCheckboxes = this.createRowsWithDisabledCheckboxes(allUnits);
    const rowsWithSelections = this.tickRowCheckboxesFromControlGroupInfo(
      rowsWithEmptyOrDisabledCheckboxes,
      controlGroupToEdit,
      vppUnits,
    );
    return rowsWithSelections;
  }

  // rows are created based on units from units summary api
  // such rows are then initialised with checkboxes, one for every device class
  // the checkboxes are either inialised as empty or disabled
  private createRowsWithDisabledCheckboxes(units: Unit[]): Record<string, VppControlGroupEditRow> {
    if (isEmpty(units)) return null;

    const reducer = (
      rows: Record<string, VppControlGroupEditRow>,
      unit: Unit,
    ): Record<string, VppControlGroupEditRow> => {
      const checkboxes = this.emptyOrDisableCheckboxGivenUnitDevices(unit.devices, unit.name);
      rows[unit.uuid] = checkboxes;
      return rows;
    };
    const rows = units.reduce(reducer, {});
    return rows;
  }

  // initialise checkbox of device classes as empty if unit has a device of such category,
  // otherwise initialised as disabled since there's no such devices available.
  // if there is no empty checkboxes, the unit overall checkbox is initialised to disabled as well.
  private emptyOrDisableCheckboxGivenUnitDevices(
    unitsDevices: { category: VppUnitDeviceTypes }[],
    unitName: string,
  ): VppControlGroupEditRow {
    const unitDeviceCategories = unitsDevices.map((devices) => devices.category);
    const reducer = (
      checkboxes: Record<VppUnitDeviceTypes, VppControlGroupEditCheckboxState>,
      deviceType: VppUnitDeviceTypes,
    ): Record<VppUnitDeviceTypes, VppControlGroupEditCheckboxState> => {
      const checkbox = unitDeviceCategories.includes(deviceType)
        ? VppControlGroupEditCheckboxState.EMPTY
        : VppControlGroupEditCheckboxState.DISABLED;
      checkboxes[deviceType] = checkbox;
      return checkboxes;
    };

    const unitDeviceClassCheckbox = Object.values(VppUnitDeviceTypes).reduce(
      reducer,
      {} as Record<VppUnitDeviceTypes, VppControlGroupEditCheckboxState>,
    );
    const unitOverallCheckbox = Object.values(unitDeviceClassCheckbox).includes(VppControlGroupEditCheckboxState.EMPTY)
      ? VppControlGroupEditCheckboxState.EMPTY
      : VppControlGroupEditCheckboxState.DISABLED;
    const row: VppControlGroupEditRow = {
      name: unitName,
      selectAll: unitOverallCheckbox,
      selectClass: unitDeviceClassCheckbox,
    };
    return row;
  }

  // a checkbox is initialised with a tick if:
  // 1. row unit id matches a unit from control group unit api
  // 2. that control group unit device group name (ie controlGroupUnit.device.groups) matches with name of control group to be edited.
  // on top of previous disabled boxes, this will disable checkboxes of row unit and column device class if:
  // 1. row unit id matches a unit from control group unit api
  // 2. that control group unit device group name (ie controlGroupUnit.device.groups) does NOT match with name of control group to be edited,
  //    since that unit device category already belongs to another control group, therefore should be disabled for this current control group.
  private tickRowCheckboxesFromControlGroupInfo(
    rows: Record<string, VppControlGroupEditRow>,
    controlGroup: VppSettingGroupsTabControlGroup,
    vppUnits: VppUnit[],
  ): Record<string, VppControlGroupEditRow> {
    if (isEmpty(rows) || isEmpty(controlGroup || isEmpty(vppUnits))) return null;

    vppUnits.forEach((unit) => {
      if (isEmpty(rows[unit.uuid])) return;

      unit.devices.forEach((device) => {
        const checkboxState =
          device.groups.indexOf(controlGroup.name) > -1
            ? VppControlGroupEditCheckboxState.CHECKED
            : VppControlGroupEditCheckboxState.EMPTY;
        if (rows[unit.uuid]?.selectClass?.[device?.category] == null) return;
        rows[unit.uuid].selectClass[device.category] = checkboxState;
      });
    });
    BrowserLogger.log('VppSettingEditControlGroupFacadeService.tickRowCheckboxesFromControlGroupInfo', {
      rows,
      controlGroup,
      vppUnits,
    });
    return rows;
  }
  // --------- initialise unit rows functions --------->

  // <-------- process user checkbox interaction ---------
  private processUserTicks(
    multipleCheckboxTicked: CheckboxToModify[],
    initalisedRows: Record<string, VppControlGroupEditRow>,
  ): { overallSelect: VppControlGroupEditRow; unitSelects: Record<string, VppControlGroupEditRow> } {
    const rowsWithIndividualCheckboxesSet = this.applyChangesToRows(initalisedRows, multipleCheckboxTicked);
    const rowsWithOverallCheckboxesSet = this.setOverallCheckboxes(rowsWithIndividualCheckboxesSet);
    return rowsWithOverallCheckboxesSet;
  }

  private applyChangesToRows(
    rows: Record<string, VppControlGroupEditRow>,
    checkboxesToModify: CheckboxToModify[],
  ): Record<string, VppControlGroupEditRow> {
    if (isEmpty(checkboxesToModify)) return rows;
    let modifiedRows = rows;
    checkboxesToModify.forEach((checkbox) => {
      const { unitUuid, deviceClass, state } = checkbox;
      if (isEmpty(unitUuid) || isEmpty(deviceClass)) return;
      if (rows[unitUuid].selectClass[deviceClass] === VppControlGroupEditCheckboxState.DISABLED) return;
      modifiedRows = {
        ...modifiedRows,
        [unitUuid]: {
          ...modifiedRows[unitUuid],
          selectClass: { ...modifiedRows[unitUuid].selectClass, [deviceClass]: state },
        },
      };
    });
    return modifiedRows;
  }

  private setOverallCheckboxes(rows: Record<string, VppControlGroupEditRow>): {
    overallSelect: VppControlGroupEditRow;
    unitSelects: Record<string, VppControlGroupEditRow>;
  } {
    if (isEmpty(rows)) return null;

    const reducer = (
      rows: Record<string, VppControlGroupEditRow>,
      rowId: string,
    ): Record<string, VppControlGroupEditRow> => {
      rows[rowId] = this.determineRowOverallCheckbox(rows[rowId]);
      return rows;
    };

    const rowsWithSetUnitRowOverallCheckboxes = Object.keys(rows).reduce(reducer, rows);
    const totalRow = this.constructTotalRowFromUnitRows(rowsWithSetUnitRowOverallCheckboxes);
    return { overallSelect: totalRow, unitSelects: rowsWithSetUnitRowOverallCheckboxes };
  }

  private constructTotalRowFromUnitRows(rows: Record<string, VppControlGroupEditRow>): VppControlGroupEditRow {
    const reducer = (
      totalRow: Record<VppUnitDeviceTypes, VppControlGroupEditCheckboxState>,
      deviceClass: VppUnitDeviceTypes,
    ): Record<VppUnitDeviceTypes, VppControlGroupEditCheckboxState> => {
      const checkboxState = this.determineDeviceClassCheckbox(rows, deviceClass);
      totalRow[deviceClass] = checkboxState;
      return totalRow;
    };
    const totalDeviceClassCheckboxes = Object.values(VppUnitDeviceTypes).reduce(
      reducer,
      {} as Record<VppUnitDeviceTypes, VppControlGroupEditCheckboxState>,
    );
    const rowWithTotalDeviceClassCheckboxes: VppControlGroupEditRow = {
      name: '',
      selectAll: VppControlGroupEditCheckboxState.EMPTY,
      selectClass: totalDeviceClassCheckboxes,
    };
    const totalRow = this.determineRowOverallCheckbox(rowWithTotalDeviceClassCheckboxes);
    return totalRow;
  }

  private determineDeviceClassCheckbox(
    rows: Record<string, VppControlGroupEditRow>,
    deviceClass: VppUnitDeviceTypes,
  ): VppControlGroupEditCheckboxState {
    const states = Object.values(rows).map((row) => row.selectClass[deviceClass]);
    return this.determineAggregateCheckboxState(states);
  }

  private determineRowOverallCheckbox(row: VppControlGroupEditRow): VppControlGroupEditRow {
    const deviceClassCheckboxStates = Object.values(row.selectClass);
    const unitRowOverallCheckbox = this.determineAggregateCheckboxState(deviceClassCheckboxStates);
    return { ...row, selectAll: unitRowOverallCheckbox };
  }

  private determineAggregateCheckboxState(
    states: VppControlGroupEditCheckboxState[],
  ): VppControlGroupEditCheckboxState {
    // if there is at least one unchecked box, then leave the overall box empty
    if (states.includes(VppControlGroupEditCheckboxState.EMPTY)) return VppControlGroupEditCheckboxState.EMPTY;
    // if there is at least one checked box with no empty boxes, then tick overall box
    if (states.includes(VppControlGroupEditCheckboxState.CHECKED)) return VppControlGroupEditCheckboxState.CHECKED;
    // if all boxes are disabled, then disable overall box
    if (states.includes(VppControlGroupEditCheckboxState.DISABLED)) return VppControlGroupEditCheckboxState.DISABLED;

    return VppControlGroupEditCheckboxState.EMPTY;
  }

  public setSingleUnitDeviceState(
    state: CheckboxTickedOrEmpty,
    unitUuid: string,
    deviceClass: VppUnitDeviceTypes,
  ): void {
    const checkbox: CheckboxToModify = { state, unitUuid, deviceClass };
    this.setMultipleUnitDeviceStates([checkbox]);
  }

  public setAllDevicesInUnit(state: CheckboxTickedOrEmpty, unitUuid: string): void {
    const checkboxes: CheckboxToModify[] = Object.values(VppUnitDeviceTypes).map((deviceClass) => {
      return { state, unitUuid, deviceClass };
    });
    this.setMultipleUnitDeviceStates(checkboxes);
  }

  public setOneDeviceClassForAllUnits(state: CheckboxTickedOrEmpty, deviceClass: VppUnitDeviceTypes): void {
    this.allCheckboxesForOneDeviceClassTickedSubject.next({ state, deviceClass, unitUuid: null });
  }

  public setAllDevicesForAllUnits(state: CheckboxTickedOrEmpty): void {
    Object.values(VppUnitDeviceTypes).map((deviceType) => {
      this.setOneDeviceClassForAllUnits(state, deviceType);
    });
  }

  private setMultipleUnitDeviceStates(states: CheckboxToModify[]): void {
    this.checkboxesTickedSubject.next(states);
  }

  private adaptDeviceClassTickToMultipleTicks(
    state: CheckboxTickedOrEmpty,
    deviceClass: VppUnitDeviceTypes,
    units: Unit[],
  ): CheckboxToModify[] {
    if (state == null || isEmpty(deviceClass) || isEmpty(units)) return [];

    return units.map((unit) => {
      const checkboxToModify: CheckboxToModify = {
        state,
        deviceClass,
        unitUuid: unit.uuid,
      };
      return checkboxToModify;
    });
  }
  // --------- process user checkbox interaction -------->

  //  assemble unit rows, overall rows, and control group details to be presented in the modal
  private assembleModalData(
    controlGroup: VppSettingGroupsTabControlGroup,
    totalCheckboxRow: VppControlGroupEditRow,
    unitCheckboxRows: Record<string, VppControlGroupEditRow>,
  ): VppControlGroupEditInfo {
    if (isEmpty(controlGroup)) return null;
    const { name, description, id, uuid } = controlGroup;
    const unitCount = unitCheckboxRows ? Object.keys(unitCheckboxRows).length : 0;
    const unitSelects: { uuid: string; unitName: string; checkboxes: VppControlGroupEditRow }[] = unitCheckboxRows
      ? Object.entries(unitCheckboxRows).map(([key, value]) => {
          return { uuid: key, unitName: value.name, checkboxes: value };
        })
      : [];
    const modalData: VppControlGroupEditInfo = {
      uuid,
      id,
      name,
      description,
      active: false,

      unitAvailNumber: unitCount,
      unitTotalNumber: unitCount,

      overallSelect: totalCheckboxRow,
      unitSelects,
    };
    return modalData;
  }

  // <-------- functions used when users submit --------
  public submitEditInfo(controlGroupInfo: VppControlGroupEditForm): void {
    this.controlGroupUpdatedSubject.next(controlGroupInfo);
  }

  private adaptControlGroupPutPayload(
    controlGroupInfo: VppControlGroupEditForm,
    vppId: string,
  ): VppControlGroupPutPayload {
    if (isEmpty(controlGroupInfo) || vppId == null) return null;
    const { id, name, description, uuid } = controlGroupInfo;
    const payload = {
      uuid,
      id,
      name,
      description,
      active: true,
      vpp_id: vppId,
    };
    return payload;
  }

  private adaptEditInfoToPayload(
    currentRows: Record<string, VppControlGroupEditRow>,
    previousRows: Record<string, VppControlGroupEditRow>,
  ): VppControlGroupUnitUpdatePayload {
    //compare previous row with current edited row. changed checked devices = add, changed unchecked devices = remove
    const reducer = (payload: VppControlGroupUnitUpdatePayload, unitUuid: string): VppControlGroupUnitUpdatePayload => {
      if (!this.isSelectionChanged(currentRows[unitUuid], previousRows[unitUuid])) return payload;
      const changes = this.mapChangedDeviceStateToAddAndRemove(currentRows[unitUuid], previousRows[unitUuid]);
      if (!isEmpty(changes.add)) payload.add.push({ uuid: unitUuid, device_classes: changes.add });
      if (!isEmpty(changes.remove)) payload.remove.push({ uuid: unitUuid, device_classes: changes.remove });
      return payload;
    };
    const initialPayload: VppControlGroupUnitUpdatePayload = { add: [], remove: [] };
    const payload: VppControlGroupUnitUpdatePayload = Object.keys(currentRows).reduce(reducer, initialPayload);
    return payload;
  }

  private isSelectionChanged(
    currentRowDeviceSelection: VppControlGroupEditRow,
    previousRowDeviceSelection: VppControlGroupEditRow,
  ): boolean {
    const selectionChanged = Object.keys(currentRowDeviceSelection.selectClass).find(
      (deviceClass) =>
        currentRowDeviceSelection.selectClass[deviceClass] != previousRowDeviceSelection.selectClass[deviceClass],
    );
    const isSelectionChanged = selectionChanged !== undefined;

    return isSelectionChanged;
  }

  private mapChangedDeviceStateToAddAndRemove(
    currentRowDeviceSelection: VppControlGroupEditRow,
    previousRowDeviceSelection: VppControlGroupEditRow,
  ): { add: VppUnitDeviceTypes[]; remove: VppUnitDeviceTypes[] } {
    const changedDeviceClasses = Object.keys(currentRowDeviceSelection.selectClass).filter(
      (deviceClass) =>
        currentRowDeviceSelection.selectClass[deviceClass] != previousRowDeviceSelection.selectClass[deviceClass],
    ) as VppUnitDeviceTypes[];
    const devicesToAdd = changedDeviceClasses.reduce(
      (changes, device) => {
        if (currentRowDeviceSelection.selectClass[device] === VppControlGroupEditCheckboxState.CHECKED) {
          changes.add.push(device);
        } else {
          changes.remove.push(device);
        }
        return changes;
      },
      { add: [] as VppUnitDeviceTypes[], remove: [] as VppUnitDeviceTypes[] },
    );
    return devicesToAdd;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe);
  }
}
