<ion-card
  [ngClass]="IS_PLATFORM_DESKTOP ? 'home-list-item-desktop' : 'home-list-item-mobile'"
  (click)="Nameclick()"
  lines="none"
  class="fs-18 fw-600"
  [style]="bgImg"
  mode="md"
>
  <ion-card-content class="pl-0">
    <ion-item lines="none">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col size="20px">
            <div class="vertical-midle-aligner statusIcon">
              <app-status-circle [isCard]="false" [status]="data?.onlineStatus"></app-status-circle>
            </div>
          </ion-col>
          <ion-col [size]="IS_PLATFORM_DESKTOP ? '6' : '10'">
            <ion-card-title
              class="fw-600 fs-20 ion-text-capitalize"
              [ngClass]="IS_PLATFORM_DESKTOP ? ['', 'ml-21'] : isUnit() ? 'white-text' : 'ml-7'"
              >{{ data.name }}</ion-card-title
            >
          </ion-col>
          <ion-col [size]="IS_PLATFORM_DESKTOP ? '' : '10'" [class]="IS_PLATFORM_DESKTOP ? '' : 'ml-16'">
            <ng-container *ngIf="data.dataType === HomeCardDataType.UNIT">
              <ng-container *ngIf="data.address">
                <ion-card-subtitle
                  class="fw-400 ion-text-capitalize"
                  [ngClass]="
                    IS_PLATFORM_DESKTOP
                      ? ['ion-text-end', 'fs-16']
                      : isUnit()
                        ? ['ion-text-start', 'fs-13', 'white-text']
                        : ['ion-text-start', 'fs-13']
                  "
                >
                  {{ data.address }}
                </ion-card-subtitle>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="data.dataType === HomeCardDataType.PORTFOLIO">
              <ion-card-subtitle
                class="fw-400 ion-text-capitalize"
                [ngClass]="
                  IS_PLATFORM_DESKTOP
                    ? ['ion-text-end', 'fs-16']
                    : isUnit()
                      ? ['ion-text-start', 'fs-13', 'white-text']
                      : ['ion-text-start', 'fs-13']
                "
                *ngIf="data.locationDescription || data.unitCount"
              >
                {{ data.locationDescription }} | {{ data.unitCount }} {{ 'General.Units' | translate }}
              </ion-card-subtitle>
            </ng-container>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-card-content>
</ion-card>
