<ion-item class="ion-no-margin ion-no-padding sub-text" lines="none" *ngIf="!isDisabled">
  <ion-text *ngIf="!isEditUnit" class="ion-padding-top ion-padding-bottom" style="width: 60px">{{
    'General.Address' | translate
  }}</ion-text>
  <ion-label
    class="text-deco-under cursor-pointer ion-text-wrap ion-text-end"
    color="brand-primary"
    *ngIf="googleAddress == Address.FULL_ADDRESS_FROM_GOOGLE"
    (click)="changeAddressMode(Address.STREET_FROM_GOOGLE)"
  >
    {{ 'CreatePortfolioModal.SwitchToStreetAddress' | translate }}
  </ion-label>
  <ion-label
    class="text-deco-under cursor-pointer ion-text-wrap ion-text-end"
    color="brand-primary"
    *ngIf="googleAddress == Address.STREET_FROM_GOOGLE"
    (click)="changeAddressMode(Address.SUBURB_FROM_GOOGLE)"
  >
    {{ 'CreatePortfolioModal.SwitchToSuburbAddress' | translate }}
  </ion-label>
  <ion-label
    class="text-deco-under cursor-pointer ion-text-wrap ion-text-end"
    color="brand-primary"
    *ngIf="googleAddress == Address.SUBURB_FROM_GOOGLE"
    (click)="changeAddressMode(Address.MANUAL_ENTRY)"
  >
    {{ 'CreatePortfolioModal.SwitchToManualAddress' | translate }}
  </ion-label>
  <ion-label
    class="text-deco-under cursor-pointer ion-text-wrap ion-text-end"
    color="brand-primary"
    *ngIf="googleAddress == Address.MANUAL_ENTRY"
    (click)="changeAddressMode(Address.FULL_ADDRESS_FROM_GOOGLE)"
  >
    {{ 'CreatePortfolioModal.SwitchToGoogleAddress' | translate }}
  </ion-label>
</ion-item>
<div #mapEmpty></div>
<div *ngIf="googleAddress == Address.FULL_ADDRESS_FROM_GOOGLE">
  <!-- Address.FULL_ADDRESS_FROM_GOOGLE -->
  <ion-item class="md-like-swidin-input">
    <ion-input
      name="searchAddress"
      [(ngModel)]="searchAddress"
      (ngModelChange)="search()"
      (click)="addressTouched()"
      [disabled]="isDisabled"
      label=" {{ isEditUnit ? 'General.Address' : ('CreatePortfolioModal.SearchAddressFromGoogle' | translate) }}"
      labelPlacement="floating"
    ></ion-input>
  </ion-item>
  <ion-item *ngIf="IS_PLATFORM_CORDOVA" lines="none"></ion-item>
  <ion-list *ngIf="viewResults">
    <ion-item button="true" (click)="selectAddress(item)" *ngFor="let item of results">
      <ion-label class="ion-text-wrap">{{ item.description }}</ion-label>
      <ion-icon
        slot="end"
        color="brand-primary"
        *ngIf="selectedAddress != null && selectedAddress != undefined && item.place_id == selectedAddress.place_id"
        name="checkmark-circle"
      ></ion-icon>
    </ion-item>
  </ion-list>
</div>
<div *ngIf="googleAddress == Address.STREET_FROM_GOOGLE">
  <!-- Address.STREET_FROM_GOOGLE -->
  <ion-row class="ion-padding-bottom">
    <ion-col class="ion-no-padding ion-padding-end" size="6">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="unitNumber"
          [(ngModel)]="manualAddress.unitNo"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.UnitNo' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
    <ion-col class="ion-no-padding" size="6">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="streetNumber"
          [(ngModel)]="manualAddress.streetNo"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.StreetNo' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-item class="md-like-swidin-input">
    <ion-input
      name="searchAddress"
      [(ngModel)]="searchAddress"
      (ngModelChange)="search()"
      label="{{ 'CreatePortfolioModal.SearchStreet' | translate }}"
      labelPlacement="floating"
    ></ion-input>
  </ion-item>
  <ion-list *ngIf="viewResults">
    <ion-item button="true" (click)="selectAddress(item)" *ngFor="let item of results">
      <ion-label class="ion-text-wrap">{{ item.description }}</ion-label>
      <ion-icon
        slot="end"
        color="brand-primary"
        *ngIf="selectedAddress != null && selectedAddress != undefined && item.place_id == selectedAddress.place_id"
        name="checkmark-circle"
      ></ion-icon>
    </ion-item>
  </ion-list>
</div>
<div *ngIf="googleAddress == Address.SUBURB_FROM_GOOGLE">
  <!-- Address.SUBURB_FROM_GOOGLE -->
  <ion-row class="ion-padding-bottom">
    <ion-col class="ion-no-padding ion-padding-end" size="6">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="unitNumber"
          [(ngModel)]="manualAddress.unitNo"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.UnitNo' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
    <ion-col class="ion-no-padding" size="6">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="streetNumber"
          [(ngModel)]="manualAddress.streetNo"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.StreetNo' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="ion-padding-bottom">
    <ion-col class="ion-no-padding" size="12">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="streetName"
          [(ngModel)]="manualAddress.streetName"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.StreetName' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-item class="md-like-swidin-input">
    <ion-input
      name="searchAddress"
      [(ngModel)]="searchAddress"
      (ngModelChange)="search()"
      label="{{ 'CreatePortfolioModal.SearchSuburb' | translate }}"
      labelPlacement="floating"
    ></ion-input>
  </ion-item>
  <ion-list *ngIf="viewResults">
    <ion-item button="true" (click)="selectAddress(item)" *ngFor="let item of results">
      <ion-label class="ion-text-wrap">{{ item.description }}</ion-label>
      <ion-icon
        slot="end"
        color="brand-primary"
        *ngIf="selectedAddress != null && selectedAddress != undefined && item.place_id == selectedAddress.place_id"
        name="checkmark-circle"
      ></ion-icon>
    </ion-item>
  </ion-list>
</div>
<div *ngIf="googleAddress == Address.MANUAL_ENTRY">
  <!-- Address.MANUAL -->
  <ion-row class="ion-padding-bottom">
    <ion-col class="ion-no-padding ion-padding-end" size="6">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="unitNumber"
          [(ngModel)]="manualAddress.unitNo"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.UnitNo' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
    <ion-col class="ion-no-padding" size="6">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="streetNumber"
          [(ngModel)]="manualAddress.streetNo"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.StreetNo' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="ion-padding-bottom">
    <ion-col class="ion-no-padding" size="12">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="streetName"
          [(ngModel)]="manualAddress.streetName"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.StreetName' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="ion-padding-bottom">
    <ion-col class="ion-no-padding" size="12">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="suburbName"
          [(ngModel)]="manualAddress.suburbName"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.Suburb' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="ion-padding-bottom">
    <ion-col class="ion-no-padding ion-padding-end" size="6">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="postCode"
          [(ngModel)]="manualAddress.postCode"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.PostCode' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
    <ion-col class="ion-no-padding" size="6">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="state"
          [(ngModel)]="manualAddress.state"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.State' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="ion-padding-bottom">
    <ion-col class="ion-no-padding" size="12">
      <ion-item class="md-like-swidin-input">
        <ion-input
          name="country"
          [(ngModel)]="manualAddress.country"
          (ngModelChange)="formatAddressIfAddressSelected()"
          label="{{ 'CreatePortfolioModal.Country' | translate }}"
          labelPlacement="floating"
        ></ion-input>
      </ion-item>
    </ion-col>
  </ion-row>
  <div *ngIf="isUnitAddress">
    <ion-row>
      <div class="ion-padding-bottom">{{ 'CreatePortfolioModal.LatLangDesc' | translate }}</div>
    </ion-row>
    <ion-row class="ion-padding-bottom">
      <ion-col class="ion-no-padding ion-padding-end" size="6">
        <ion-item class="md-like-swidin-input">
          <ion-input
            name="latitude"
            type="number"
            [(ngModel)]="manualAddress.lat"
            (ngModelChange)="latLngInputChange(manualAddress.lat, manualAddress.lang)"
            label="{{ 'CreatePortfolioModal.Latitude' | translate }}"
            labelPlacement="floating"
          ></ion-input>
        </ion-item>
      </ion-col>
      <ion-col class="ion-no-padding" size="6">
        <ion-item class="md-like-swidin-input">
          <ion-input
            name="longitude"
            type="number"
            [(ngModel)]="manualAddress.lang"
            (ngModelChange)="latLngInputChange(manualAddress.lat, manualAddress.lang)"
            label="{{ 'CreatePortfolioModal.Longitude' | translate }}"
            labelPlacement="floating"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <div #map style="height: 300px; width: 100%"></div>
  </div>
</div>
