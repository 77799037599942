import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DropletControllerInventoryService } from '@class/units/droplet/controller-inventory-request/droplet-controller-inventory-request.service';
import { DropletController } from '@class/units/droplet/droplet-controller.model';
import { TranslationsService } from '@service/common/translations.service';
import { BrowserLogger } from '@class/core/browser-logger';
import { PermissionsService } from '@service/permissions/permissions.service';
import { Subscription } from 'rxjs';
import { Event, EventBusService } from '@service/core/event-bus.service';
import { DropletControllerInventoryRequest } from '@class/units/droplet/controller-inventory-request/droplet-controller-inventory-request.model';

enum DropletNotificationIcon {
  WARNING = 'assets/icons/svg/alert-warning-icon.svg',
  ERROR = 'assets/icons/svg/failed-message.svg',
}

enum DropletNotificationCssClass {
  WARNING = 'warning',
  ERROR = 'danger',
}

interface DropletNotificationViewModel {
  hasControllerIssue: boolean;
  ngWrapperDivClass: DropletNotificationCssClass;
  iconSrc: DropletNotificationIcon;
  text: string[];
  secondaryText: string[];
  enableRemoveButton: boolean;
  removeButtonLabel: string;
  enableResyncButton: boolean;
  resyncButtonLabel: string;
}

@Component({
  selector: 'app-droplet-notification',
  templateUrl: './droplet-notification.component.html',
  styleUrls: ['./droplet-notification.component.scss'],
})
export class DropletNotificationComponent implements OnDestroy {
  // Outputs
  @Output() onRemoveClick = new EventEmitter();
  @Output() onResyncClick = new EventEmitter();

  // Input: droplet uuid
  private _uuid: string = '';
  @Input()
  set uuid(value: string) {
    BrowserLogger.log('DropletNotification.set.uuid', { value });
    this._uuid = value;
    this.setViewModel();
  }

  // Input: controller
  private _controller: DropletController = null;
  @Input()
  set controller(value: DropletController) {
    BrowserLogger.log('DropletNotification.set.controller', { value });
    this._controller = value;
    this.setViewModel();
  }
  get controller(): DropletController {
    return this._controller;
  }

  vm: DropletNotificationViewModel = {
    hasControllerIssue: false,
    ngWrapperDivClass: DropletNotificationCssClass.WARNING,
    iconSrc: DropletNotificationIcon.WARNING,
    text: [], // array or messages (each in their own div)
    secondaryText: [], // array or secondary messages shown under the 'text' (each in their own p)
    enableRemoveButton: false,
    removeButtonLabel: '',
    enableResyncButton: false,
    resyncButtonLabel: '',
  };

  private _controllerInventoryReceivedSubscription: Subscription;

  constructor(
    private controllerInventoryService: DropletControllerInventoryService,
    private permissionsService: PermissionsService,
    private translationsService: TranslationsService,
    private _eventBus: EventBusService,
  ) {
    BrowserLogger.log('DropletNotification.constructor');
    // subscribe to Controller Inventory Received event (recheck controller state)
    this._controllerInventoryReceivedSubscription = this._eventBus
      .on(Event.CONTROLLER_INVENTORY_STATE)
      .subscribe((event: DropletControllerInventoryRequest) => {
        this.handleControllerInventoryState(event);
      });
  }

  ngOnDestroy(): void {
    BrowserLogger.log('DropletNotification.ngOnDestroy');
    this._controllerInventoryReceivedSubscription?.unsubscribe();
  }

  private handleControllerInventoryState(controllerInventoryRequest: DropletControllerInventoryRequest) {
    if (controllerInventoryRequest.dropletUuid && controllerInventoryRequest.dropletUuid === this._uuid) {
      BrowserLogger.log('DropletNotification.handleControllerInventoryState', {
        controllerInventoryRequest,
        vm: this.vm,
      });
      this.setViewModel();
    }
  }

  public handleResyncClick(): void {
    BrowserLogger.log('DropletNotification.handleResyncClick');
    this.onResyncClick.emit();
  }

  public handleRemoveClick(): void {
    BrowserLogger.log('DropletNotification.handleRemoveClick');
    this.onRemoveClick.emit();
  }

  private setViewModel(): void {
    this.vm.hasControllerIssue = this.controllerInventoryService.hasDropletInventoryIssue(this._controller, this._uuid);
    // this.vm.hasControllerIssue = true; // testing only, comment out as required
    if (this.vm.hasControllerIssue) {
      this.vm.text = [];
      this.vm.secondaryText = [];
      // this._controller.errorFromDroplet = 'Droplet Error Test'; // testing only, comment out as required
      if (DropletController.hasDropletError(this._controller)) {
        this.vm.iconSrc = DropletNotificationIcon.ERROR;
        this.vm.ngWrapperDivClass = DropletNotificationCssClass.ERROR;
        this.vm.secondaryText.push(
          `${this.translationsService.instant('UnitPage.ErrorFromDroplet')}: ${this._controller.errorFromDroplet}`,
        );
      } else {
        this.vm.iconSrc = DropletNotificationIcon.WARNING;
        this.vm.ngWrapperDivClass = DropletNotificationCssClass.WARNING;
      }
      if (!DropletController.existsOnDroplet(this._controller)) {
        this.vm.text.push(this.translationsService.instant('UnitPage.ControllerNotExist'));
      } else {
        if (!DropletController.isCorrectVersion(this._controller)) {
          this.vm.text.push(this.translationsService.instant('UnitPage.ControllerVersionNotMatched'));
        }
        if (!DropletController.existsOnDatabase(this._controller)) {
          this.vm.text.push(this.translationsService.instant('UnitPage.ControllerNotExistServer'));
        }
      }
      this.vm.enableRemoveButton = false; // no longer using the remove button (leaving here in case we decide to use again later)
      // this.vm.enableRemoveButton = true; // testing only, comment out as required
      if (this.vm.enableRemoveButton) {
        this.vm.removeButtonLabel = this.translationsService.instant('General.Remove');
      }
      this.vm.enableResyncButton = DropletController.canResendConfig(this._controller, this.permissionsService);
      // this.vm.enableResyncButton = true; // testing only, comment out as required
      if (this.vm.enableResyncButton) {
        this.vm.resyncButtonLabel = this.translationsService.instant('UnitPage.ResendConfig');
      }
    }
    BrowserLogger.log('DropletNotification.controller.setViewModel', { vm: this.vm, controller: this._controller });
  }
}
