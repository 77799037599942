import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalyticsMetrics, DeviceControllerMetrics } from '@class/units/unitanalytics/unit-analytics.models';

const SELECTED_METRIC_LIMIT: number = 10; // Only support 10 metrics due to API limitations

@Component({
  selector: 'app-unit-metric-selection-typehead',
  templateUrl: './unit-metric-selection-typehead.component.html',
  styleUrls: ['./unit-metric-selection-typehead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitMetricSelectionTypeheadComponent implements OnInit {
  // this is for unit analytics
  @Input() devicesAndControllersMetrics: Array<DeviceControllerMetrics>;
  @Input() selectedItems: string[] = [];
  @Output() selectionChange = new EventEmitter<string[]>();

  // this is for alert definitions create edit
  @Input() alertMetrics;
  @Input() selectedMetric;
  @Output() selectionChangeAlertMetric = new EventEmitter<any>();

  @Input() title = 'Select metrics - max 10';

  @Output() selectionCancel = new EventEmitter<void>();

  workingSelectedValues: string[] = [];
  filterText = '';

  disableCheckbox = false;

  ngOnInit() {
    if (this.selectedItems) this.workingSelectedValues = [...this.selectedItems];
  }

  trackItems(index: number, item: AnalyticsMetrics) {
    return item.key;
  }

  cancelChanges() {
    this.selectionCancel.emit();
  }

  confirmChanges() {
    this.selectionChange.emit(this.workingSelectedValues);
  }

  isChecked(value: string) {
    return this.workingSelectedValues.find((item) => item === value);
  }

  checkboxChange(ev) {
    const { checked, value } = ev.detail;

    if (checked) {
      this.workingSelectedValues = [...this.workingSelectedValues, value];
    } else {
      this.workingSelectedValues = this.workingSelectedValues.filter((item) => item !== value);
    }
    this.disableCheckbox = this.workingSelectedValues.length >= SELECTED_METRIC_LIMIT;
  }

  checkboxChangeAlertMetric(ev) {
    const { value } = ev.detail;
    this.selectedMetric = value;

    const metric = this.alertMetrics.find((metric) => metric.metricKey === value);

    this.selectionChangeAlertMetric.emit(metric);
  }
  compareWith(a, b) {
    // if a unit have multiple same device types
    // then there is possibility that they will have same device metrics
    // means same pk for a metric in different devices
    // cause an issue, select the metric of one device will automatically select the same metric of other device
    // to avoid that
    if (a && b) {
      if (a.hasOwnProperty('uniqueId') && b.hasOwnProperty('uniqueId')) {
        return a.uniqueId === b.uniqueId;
      }
      return a.id === b.id;
    } else {
      return a === b;
    }
  }
}
