<!-- device detail is in a common component (used by controllers also) -->
<app-droplet-item-detail
  *ngIf="!deviceNameEdit"
  [droplet]="selectedDroplet"
  [device]="selectedDevice"
  (onEditClick)="onEditClicked($event)"
  (onRemoveClick)="onRemoveClicked($event)"
  [isUnitActive]="isUnitActive"
></app-droplet-item-detail>

<!-- device edit is custom for this component  -->
<div *ngIf="deviceNameEdit">
  <!-- Device Name Card -->
  <ion-card class="pt-20 pb-20 m-0 mb-12 max-width-wide">
    <!-- Edit Mode -->
    <div class="fs-13 text-medium ml-20">
      {{ 'General.Name' | translate }}
    </div>
    <div class="input-div bb-1">
      <app-input-with-inline-button
        inputType="string"
        [maxLength]="30"
        [placeholder]="selectedDevice.deviceOverview.fullName"
        [submitButton]="false"
        (valueChangeEvent)="onDeviceNameChanged($event)"
      ></app-input-with-inline-button>
      <div class="fs-13 text-medium">{{ 'UnitPage.DeviceMaxLength' | translate }}</div>
      <div class="mt-5">
        <app-device-detail-message
          [state]="DeviceDetailMessageState.ERROR"
          [title]="deviceNameErrorTitle"
          [subtitles]="deviceNameErrorSubtitle"
        ></app-device-detail-message>
      </div>
    </div>
    <div class="m-0 name-button-row pl-20 pr-20 pt-20">
      <ion-button
        (click)="onCancelEditClicked($event)"
        fill="outline"
        color="button-primary"
        mode="ios"
        class="m-0 cancel-name-button"
      >
        {{ 'General.Cancel' | translate }}
      </ion-button>
      <ion-button
        (click)="onUpdateDeviceNameClicked()"
        mode="ios"
        color="button-primary"
        class="m-0 update-name-button"
      >
        {{ 'General.Update' | translate }}
      </ion-button>
    </div>
  </ion-card>
</div>
