import { Component, Input } from '@angular/core';
import { DeviceHealth, EndpointDTO } from '@class/units/endpoint/endpoint-payload.model';
import { isNotNil } from 'ramda';

export interface DeviceHealthStatus {
  item: string;
  status: string;
  color: string;
  icon: string;
  background: string;
  message: string;
  healthIcon: string;
}

function getViewModel([item, status]: [string, DeviceHealth]) {
  const obj: DeviceHealthStatus = {
    item: '',
    status: '',
    color: '',
    icon: '',
    background: '',
    message: '',
    healthIcon: '',
  };

  switch (item) {
    case 'Battery':
      obj.item = 'Portfolio.Battery';
      obj.icon = 'battery-full';
      obj.background = 'light-brand-primary-div-background';
      break;
    case 'BatteryInverter':
      obj.item = 'Portfolio.BatteryInverter';
      obj.icon = 'inverter';
      obj.background = 'light-secondary-three-div-background';
      break;
    case 'Grid':
      obj.item = 'Portfolio.Grid';
      obj.icon = 'grid';
      obj.background = 'light-text-tertiary-div-background';
      break;
    default:
      // this protects us from any of devices coming through that we do not have an icon for.
      return null;
  }

  switch (status) {
    case DeviceHealth.Ok:
      obj.color = 'success';
      obj.status = 'check';
      obj.message = 'Metrics.Working';
      obj.healthIcon = 'check';
      break;
    case DeviceHealth.Warn:
      obj.color = 'warning';
      obj.status = 'warning';
      obj.message = 'Metrics.Warning';
      obj.healthIcon = 'warning';
      break;
    case DeviceHealth.Fault:
    default:
      obj.color = 'danger';
      obj.status = 'close';
      obj.message = 'Metrics.Fault';
      obj.healthIcon = 'danger';
      break;
  }

  return obj;
}

@Component({
  selector: 'app-system-status',
  templateUrl: './system-status.component.html',
  styleUrls: ['./system-status.component.scss'],
})
export class SystemStatusComponent {
  // input is array of endpoints
  @Input()
  readonly data: Array<EndpointDTO> = [];

  // if we don't do this
  // the data gets changed in the parent component
  // and it cause this component to re-render
  // and that is causing some rendering issues
  // so we cache the first processed result
  // and return that instead
  private firstProcessedResult: DeviceHealthStatus[] | null = null;

  get device_health_status(): DeviceHealthStatus[] {
    if (this.firstProcessedResult !== null) {
      return this.firstProcessedResult;
    }

    const result = this.data
      .flatMap((endpoint) => {
        const devices = endpoint.endpoint_attributes.devices;
        if (devices) {
          return Object.values(devices).flatMap((device) => {
            const health = device.health;
            if (health) {
              return Object.entries(health).map(([item, status]) => getViewModel([item, status as DeviceHealth]));
            }
            return [];
          });
        }
        return [];
      })
      .filter(isNotNil);

    if (result.length > 0) {
      this.firstProcessedResult = result;
    }

    return result;
  }
}
