<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="!editSchedule">{{"VirtualPowerPlant.CreateNewSchedule" | translate}}</ion-title>
    <ion-title *ngIf="editSchedule">{{"VirtualPowerPlant.UpdateSchedule" | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="fh-row">
      <ion-row class="ion-text-wrap">
        <ion-col>
          <ion-list>
            <ion-list-header>
              <h4>{{"VirtualPowerPlant.CreateVPP.Description" | translate}}</h4>
            </ion-list-header>
            <ion-item class="ion-no-margin ion-no-padding sub-text" lines="none">
              <ion-text class="ion-padding-top ion-padding-bottom"
                >{{"VirtualPowerPlant.EnterNameAndShortDescriptionSchedule" | translate}}</ion-text
              >
            </ion-item>
            <ion-item class="input-item ion-margin-bottom" lines="none">
              <ion-input
                [(ngModel)]="complexSchedule.name"
                label="{{'VirtualPowerPlant.CreateVPP.Name' | translate}}*"
                labelPlacement="floating"
              ></ion-input>
            </ion-item>
            <ion-item class="input-item ion-margin-bottom" lines="none">
              <ion-input
                [(ngModel)]="complexSchedule.description"
                label="{{'VirtualPowerPlant.CreateVPP.Description' | translate}}"
                labelPlacement="floating"
              ></ion-input>
            </ion-item>
          </ion-list>
          <ion-list>
            <ion-list-header>
              <h4>{{"VirtualPowerPlant.RecurrenceRange" | translate}}</h4>
            </ion-list-header>
            <div class="sub-text ion-margin-bottom"></div>
            <div>
              <ion-row class="ion-no-margin ion-no-padding">
                <ion-col size="6" class="ion-no-margin ion-no-padding ion-padding-end">
                  <ion-label>{{"VirtualPowerPlant.StartDate" | translate}}</ion-label>
                  <ion-datetime-button datetime="start-datetime" class="width-100-pct"></ion-datetime-button>
                  <ion-popover [keepContentsMounted]="true" [showBackdrop]="false">
                    <ng-template>
                      <ion-datetime
                        id="start-datetime"
                        presentation="date"
                        max="2099"
                        [min]="ionStartMinDate"
                        [value]="complexSchedule.start_date"
                        (ionChange)="startDateChange($event?.detail?.value)"
                        [showDefaultButtons]="true"
                      >
                      </ion-datetime>
                    </ng-template>
                  </ion-popover>
                </ion-col>
                <ion-col size="6" class="ion-no-margin ion-no-padding">
                  <ion-label>{{"VirtualPowerPlant.EndDate" | translate}}</ion-label>
                  <ion-datetime-button
                    datetime="end-datetime"
                    class="width-100-pct"
                    [disabled]="(complexSchedule.start_date == undefined || complexSchedule.start_date == '')"
                  ></ion-datetime-button>
                  <ion-popover [keepContentsMounted]="true" [showBackdrop]="false">
                    <ng-template>
                      <ion-datetime
                        id="end-datetime"
                        presentation="date"
                        max="2099"
                        [min]="ionEndMinDate"
                        [value]="complexSchedule.end_date"
                        [showDefaultButtons]="true"
                        (ionChange)="endDateChange($event?.detail?.value)"
                      >
                      </ion-datetime>
                    </ng-template>
                  </ion-popover>
                </ion-col>
              </ion-row>
            </div>
          </ion-list>
          <ion-list>
            <ion-list-header>
              <h4>{{"VirtualPowerPlant.ScheduleTime" | translate}}</h4>
            </ion-list-header>
            <ion-item class="ion-no-margin ion-no-padding sub-text" lines="none">
              <ion-text class="ion-padding-top ion-padding-bottom"
                >{{"VirtualPowerPlant.ScheduleTimeDesc" | translate}}. {{"VirtualPowerPlant.Timezone"
                |translate}}:</ion-text
              >
              <ion-text class="ion-padding-top ion-padding-bottom text-dark"> {{timezone$ | async}}</ion-text>
            </ion-item>
            <div>
              <ion-row class="ion-no-margin ion-no-padding">
                <ion-col size="6" class="ion-no-margin ion-no-padding ion-padding-end">
                  <ion-label class="ion-padding-start">{{"VirtualPowerPlant.ScheduleStartTime" | translate}}</ion-label>
                  <ion-datetime-button datetime="start-time" class="width-100-pct"></ion-datetime-button>
                  <ion-popover [keepContentsMounted]="true" [showBackdrop]="false">
                    <ng-template>
                      <ion-datetime
                        id="start-time"
                        presentation="time"
                        hourCycle="h23"
                        [min]="startMinTime"
                        [minuteValues]="minutesArray"
                        [value]="complexSchedule.start_time"
                        (ionChange)="startTimeChange($event?.detail?.value)"
                        [showDefaultButtons]="true"
                      >
                      </ion-datetime>
                    </ng-template>
                  </ion-popover>
                </ion-col>
                <ion-col size="6" class="ion-no-margin ion-no-padding">
                  <ion-label class="ion-padding-start">{{"VirtualPowerPlant.ScheduleEndTime" | translate}}</ion-label>
                  <ion-datetime-button
                    datetime="end-time"
                    class="width-100-pct"
                    [disabled]="complexSchedule.endTimeDisable"
                  ></ion-datetime-button>
                  <ion-popover [keepContentsMounted]="true" [showBackdrop]="false">
                    <ng-template>
                      <ion-datetime
                        id="end-time"
                        presentation="time"
                        hourCycle="h23"
                        [min]="endMinTime"
                        [minuteValues]="minutesArray"
                        [value]="complexSchedule.end_time"
                        (ionChange)="endTimeChange(complexSchedule.start_time, $event?.detail?.value)"
                        [showDefaultButtons]="true"
                      >
                      </ion-datetime>
                    </ng-template>
                  </ion-popover>
                  <ion-item lines="none">
                    <ion-checkbox
                      [(ngModel)]="complexSchedule.tillEndOfDay"
                      (ngModelChange)="toggleTillEndOfDay(complexSchedule.tillEndOfDay)"
                      color="button-secondary"
                      labelPlacement="start"
                      justify="space-between"
                    >
                      {{"VirtualPowerPlant.Till2359" | translate}}
                    </ion-checkbox>
                  </ion-item>
                </ion-col>
                <ion-item lines="none">
                  <ion-label
                    >{{"VirtualPowerPlant.Duration" | translate}}: {{complexSchedule.intervalDuration}}
                  </ion-label>
                </ion-item>
              </ion-row>
            </div>
          </ion-list>
          <ion-list>
            <ion-list-header>
              <h4>{{"VirtualPowerPlant.RecurrencePattern" | translate}}</h4>
            </ion-list-header>
            <div class="sub-text"></div>
          </ion-list>

          <app-sub-tabs [tabName]="tabName" (currentTabIndex)="captureTabChange($event)"></app-sub-tabs>

          <ion-row [ngClass]="{ 'ion-hide': selectedTabIndex !== 0 }">
            <ion-col *ngIf=" !editSchedule || complexSchedule.recurrence == VppScheduleRecurrencePattern.DAILY">
              <div class="tabDiv ion-margin-top" style="min-height: 100px">
                <div style="display: flex" class="ion-padding-top">
                  <ion-list>
                    <ion-radio-group value="everyday">
                      <ion-label class="ion-text-wrap"></ion-label>
                      <ion-radio
                        class="ion-margin-end"
                        color="button-secondary"
                        slot="start"
                        value="everyday"
                        labelPlacement="start"
                        justify="space-between"
                      >
                        Every day
                      </ion-radio>
                    </ion-radio-group>
                  </ion-list>
                </div>
              </div>
            </ion-col>
          </ion-row>

          <ion-row [ngClass]="{ 'ion-hide': selectedTabIndex !== 1 }">
            <ion-col *ngIf=" !editSchedule || complexSchedule.recurrence == VppScheduleRecurrencePattern.WEEKLY">
              <div class="tabDiv ion-margin-top" style="min-height: 100px">
                <div>
                  <ion-row class="ion-no-margin ion-no-padding">
                    <ion-col *ngFor="let day of days">
                      <ion-item lines="none">
                        <ion-checkbox
                          [(ngModel)]="day.isChecked"
                          slot="start"
                          color="button-secondary"
                          labelPlacement="end"
                          justify="start"
                        >
                          {{day.name}}
                        </ion-checkbox>
                      </ion-item>
                    </ion-col>
                  </ion-row>
                </div>
              </div>
            </ion-col>
          </ion-row>

          <ion-row [ngClass]="{ 'ion-hide': selectedTabIndex !== 2 }">
            <ion-col *ngIf=" !editSchedule || complexSchedule.recurrence == VppScheduleRecurrencePattern.MONTHLY">
              <div class="tabDiv ion-margin-top" style="min-height: 100px">
                <div style="display: flex" class="ion-padding-top">
                  <ion-item lines="none">
                    <ion-text class="ion-text-wrap">Day &nbsp;</ion-text>
                    <ion-input
                      class="ion-margin-end inputBorder"
                      type="number"
                      [(ngModel)]="complexSchedule.day_number"
                    ></ion-input>
                    <ion-text>of every month(s) </ion-text>
                  </ion-item>
                </div>
              </div>
            </ion-col>
          </ion-row>

          <ion-row [ngClass]="{ 'ion-hide': selectedTabIndex !== 3 }">
            <ion-col *ngIf=" !editSchedule || complexSchedule.recurrence == VppScheduleRecurrencePattern.YEARLY">
              <div class="tabDiv ion-margin-top" style="min-height: 100px">
                <ion-row class="ion-margin-top ion-margin-bottom">
                  <ion-col size="6" class="ion-padding-end">
                    <ion-item class="input-item" lines="none">
                      <ion-select
                        interface="popover"
                        placeholder=' {{ "VirtualPowerPlant.SelectOne" | translate }} '
                        [(ngModel)]="complexSchedule.yearlyStartMonth"
                        (ionChange)="yearlyStartMonthChange(complexSchedule.yearlyStartMonth)"
                        label="{{'VirtualPowerPlant.StartMonth' | translate}}"
                        labelPlacement="floating"
                      >
                        <ion-select-option *ngFor="let year of years" [value]="year.name"
                          >{{year.fullName}}</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                  <ion-col size="6">
                    <ion-item class="input-item" lines="none">
                      <ion-select
                        interface="popover"
                        placeholder=' {{ "VirtualPowerPlant.SelectOne" | translate }} '
                        [(ngModel)]="complexSchedule.yearlyStartMonthDay"
                        label="{{'VirtualPowerPlant.StartDayNumber' | translate}} value: {{complexSchedule.yearlyStartMonthDay}}"
                        labelPlacement="floating"
                      >
                        <ion-select-option *ngFor="let day of selectedYearStartMonthDays" value="{{day}}"
                          >{{day}}
                        </ion-select-option>
                      </ion-select>
                    </ion-item>
                  </ion-col>
                  <ion-col size="6" class="ion-padding-end">
                    <ion-item class="input-item" lines="none">
                      <ion-select
                        interface="popover"
                        placeholder=' {{ "VirtualPowerPlant.SelectOne" | translate }} '
                        [(ngModel)]="complexSchedule.yearlyEndMonth"
                        (ionChange)="yearlyEndMonthChange(complexSchedule.yearlyEndMonth)"
                        label="{{'VirtualPowerPlant.EndMonth' | translate}}"
                        labelPlacement="floating"
                      >
                        <ion-select-option *ngFor="let year of years" value="{{year.name}}"
                          >{{year.fullName}}</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                  <ion-col size="6">
                    <ion-item class="input-item" lines="none">
                      <ion-select
                        interface="popover"
                        placeholder=' {{ "VirtualPowerPlant.SelectOne" | translate }} '
                        [(ngModel)]="complexSchedule.yearlyEndMonthDay"
                        label="{{'VirtualPowerPlant.EndDayNumber' | translate}}"
                        labelPlacement="floating"
                      >
                        <ion-select-option *ngFor="let day of selectedYearEndMonthDays" value="{{day}}"
                          >{{day}}</ion-select-option
                        >
                      </ion-select>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </div></ion-col
            >
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-self-end">
        <ion-col>
          <ion-button
            *ngIf="!editSchedule"
            color="button-primary"
            [attr.disabled]="disableUpdate(complexSchedule.recurrence) ? true : null"
            size="large"
            expand="block"
            (click)="createComplexSchedule(complexSchedule)"
          >
            {{"VirtualPowerPlant.CreateNewSchedule" | translate }}
          </ion-button>
          <ion-button
            *ngIf="editSchedule"
            color="button-primary"
            [attr.disabled]="disableUpdate(complexSchedule.recurrence) ? true : null"
            size="large"
            expand="block"
            (click)="createComplexSchedule(complexSchedule)"
          >
            {{"VirtualPowerPlant.UpdateSchedule" | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-row>
  </ion-grid>
</ion-content>
