import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputsConfig, InputSelectOptions } from '../../../classes/commons/inputs';

@Component({
  selector: 'app-input-select-stacked-label',
  templateUrl: './input-select-stacked-label.component.html',
  styleUrls: ['./input-select-stacked-label.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectStackedLabelComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputSelectStackedLabelComponent),
      multi: true,
    },
  ],
})
export class InputSelectStackedLabelComponent extends InputsConfig {
  @Input() options: Array<any>;
  @Input() interfaceOptions: any = {}; // https://ionicframework.com/docs/api/select#interfaceoptions
  @Input() nameProp: string = 'name';
  @Input() valueProp: string = null; // will use full object when not set
}
