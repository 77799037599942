<ion-card class="ion-no-padding ion-no-margin new-design-card">
  <ion-card-header class="pb-0">
    <ion-grid class="ion-no-padding ion-no-margin">
      <ion-row>
        <ion-col size="6">
          <ion-card-title class="card-title">{{ 'UnitPage.Weather' | translate }}</ion-card-title>
        </ion-col>
        <ion-col size="6">
          <p class="card-small-subheader mt-7">
            {{ (unitWeatherStore$ | async).data.duration }}{{ 'UnitPage.hOfSun' | translate }}
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-header>
  <ion-card-content>
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col size="12">
          <ion-item lines="none">
            <ion-icon
              slot="start"
              class="custom-icon-large ion-no-margin ion-margin-end ml--15"
              [src]="weatherIcon$ | async"
            ></ion-icon>
            <ion-label>
              <p class="light-text ion-text-wrap">
                {{ 'UnitPage.AverageSolarProduction' | translate }}
              </p>
              <p class="text-dark fw-600 fs-22 ion-text-wrap">
                <span>{{ forecastKwh$ | async | number: '1.2-2' }}</span> kWh
              </p>
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="6">
          <ion-grid>
            <ion-row>
              <ion-col size="12">
                <p class="fs-18">
                  <span class="text-dark fw-600">{{ (unitWeatherStore$ | async).data.max?.toFixed(0) }}&#176;C</span>
                  <span class="light-text">/{{ (unitWeatherStore$ | async).data.min?.toFixed(0) }}&#176;C</span>
                </p>
              </ion-col>
              <ion-col size="12">
                <p class="text-dark fw-600 fs-18">
                  {{ 'UnitPage.In' | translate }} {{ (unitWeatherStore$ | async).data.name }}
                </p>
              </ion-col>

              <ion-col size="12" class="text-medium">
                {{ (unitWeatherStore$ | async).data.description | titlecase }}
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
        <ion-col size="6">
          <ion-grid>
            <ion-row>
              <ion-col size="12" class="ion-text-end fs-16 text-dark">
                <ion-grid>
                  <ion-row class="ion-align-items-center">
                    <ion-col
                      size="3"
                      offset="3"
                      size-md="3"
                      offset-md="5"
                      size-lg="3"
                      offset-lg="3"
                      size-xl="3"
                      offset-xl="5"
                    >
                      <ion-icon class="custom-icon-medium" src="assets/icons/svg/sunrise.svg"></ion-icon>
                    </ion-col>
                    <ion-col size="6" size-md="4" size-lg="6" size-xl="4">
                      <p class="ion-text-end pl-5 pb-2 mb--2">{{ (unitWeatherStore$ | async).data.sunrise }}</p>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
              <ion-col size="12" class="ion-text-end fs-16 text-dark">
                <ion-grid>
                  <ion-row class="ion-align-items-center">
                    <ion-col
                      size="3"
                      offset="3"
                      size-md="3"
                      offset-md="5"
                      size-lg="3"
                      offset-lg="3"
                      size-xl="3"
                      offset-xl="5"
                    >
                      <ion-icon class="custom-icon-medium" src="assets/icons/svg/sunset.svg"></ion-icon>
                    </ion-col>
                    <ion-col size="6" size-md="4" size-lg="6" size-xl="4">
                      <p class="ion-text-end pl-5 pb-2 mb--2">{{ (unitWeatherStore$ | async).data.sunset }}</p>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
