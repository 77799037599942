<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="editType == CrudType.UPDATE"
      >{{"VirtualPowerPlant.UpdateControlGroupSchedule" | translate}}</ion-title
    >
    <ion-title *ngIf="editType == CrudType.CREATE"
      >{{"VirtualPowerPlant.CreateControlGroupSchedules" | translate}}</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form (ngSubmit)="submitControlGroupScheduleData(newControlGroupSchedule)" #groupScheduleForm="ngForm">
    <ion-grid>
      <ion-row class="fh-row">
        <ion-row class="ion-text-wrap">
          <ion-col>
            <ion-list>
              <ion-item>
                <ion-select
                  [interfaceOptions]="customAlertOptions"
                  interface="popover"
                  placeholder=' {{ "VirtualPowerPlant.SelectOne" | translate }} '
                  [(ngModel)]="newControlGroupSchedule.selectedScheduleId"
                  name="schedules"
                  #schedules="ngModel"
                  required
                  label="{{ 'VirtualPowerPlant.Schedules' | translate }}"
                >
                  <ion-select-option *ngFor="let schedule of vppComplexSchedule" [value]="schedule.id">
                    {{schedule.name}}<span *ngIf="schedule.description != null && schedule.description.length>0">
                      - {{schedule.description}}</span
                    >
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item>
                <ion-select
                  [interfaceOptions]="customAlertOptions"
                  interface="popover"
                  placeholder=' {{ "VirtualPowerPlant.SelectOne" | translate }} '
                  [(ngModel)]="newControlGroupSchedule.selectedControlGroupId"
                  (ionChange)="grabControlGroupMarkets(newControlGroupSchedule.selectedControlGroupId,newControlGroupSchedule.mode)"
                  name="controlGroupsSelect"
                  #controlGroupsSelect="ngModel"
                  required
                  label="{{ 'VirtualPowerPlant.ControlGroup' | translate }}"
                >
                  <ion-select-option *ngFor="let group of controlGroups" [value]="group.id">
                    {{group.name}}<span *ngIf="group.description != null && group.description.length>0">
                      - {{group.description}}</span
                    >
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item>
                <ion-select
                  name="mode"
                  #mode="ngModel"
                  interface="popover"
                  placeholder=' {{ "VirtualPowerPlant.SelectOne" | translate }} '
                  [(ngModel)]="newControlGroupSchedule.mode"
                  (ionChange)="grabControlGroupMarkets(newControlGroupSchedule.selectedControlGroupId,newControlGroupSchedule.mode)"
                  required
                  label="{{ 'VirtualPowerPlant.Mode' | translate }}"
                >
                  <ion-select-option [value]="VppModes.DRM">
                    {{ "VirtualPowerPlant.DemandResponse" | translate }}
                  </ion-select-option>
                  <ion-select-option [value]="VppModes.FCAS">FCAS</ion-select-option>
                </ion-select>
              </ion-item>
              <div *ngIf="newControlGroupSchedule.mode === VppModes.DRM">
                <ion-item>
                  <ion-label>{{ "VirtualPowerPlant.DispatchType" | translate }}</ion-label>
                  <ion-select
                    name="scheduleType"
                    #scheduleType="ngModel"
                    required
                    interface="popover"
                    placeholder=' {{ "VirtualPowerPlant.SelectOne" | translate }} '
                    [(ngModel)]="newControlGroupSchedule.scheduleType"
                    (ionChange)="scheduleTypeChange(newControlGroupSchedule.scheduleType)"
                    label="{{ 'VirtualPowerPlant.DispatchType' | translate }}"
                  >
                    <ion-select-option [value]="VppDispatchType.AUTO">
                      {{ "VirtualPowerPlant.Auto" | translate }}
                    </ion-select-option>
                    <ion-select-option [value]="VppDispatchType.MANUAL">
                      {{ "VirtualPowerPlant.Manual" | translate }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
                <ion-item *ifHasPermission="[PermissionKey.VPP_VIEW_CONTROLGROUPSCHEDULEPOLICY]">
                  <ion-select
                    name="policy"
                    #policy="ngModel"
                    interface="popover"
                    placeholder=' {{ "VirtualPowerPlant.SelectOne" | translate }} '
                    [(ngModel)]="newControlGroupSchedule.selectedPolicyId"
                    required
                    label="{{ 'VirtualPowerPlant.Policy' | translate }}"
                  >
                    <ion-select-option value="none"> {{ "General.None" | translate}} </ion-select-option>
                    <ng-container *ngFor="let policy of policies">
                      <ion-select-option
                        *ngIf="!(VppDispatchType.AUTO == newControlGroupSchedule.scheduleType && policy.name === 'Staging')"
                        [value]="policy.id"
                      >
                        {{policy.name}}
                      </ion-select-option>
                    </ng-container>
                  </ion-select>
                </ion-item>
                <ion-item>
                  <ion-select
                    name="demandType"
                    #demandType="ngModel"
                    [attr.disabled]=" newControlGroupSchedule.scheduleType === VppDispatchType.AUTO"
                    interface="popover"
                    placeholder=' {{ "VirtualPowerPlant.SelectOne" | translate }} '
                    [(ngModel)]="newControlGroupSchedule.drmDemandType"
                    [required]=" newControlGroupSchedule.scheduleType === VppDispatchType.MANUAL"
                    (ionChange)="resetMeritOrder()"
                    label="{{ 'VirtualPowerPlant.DemandType' | translate }}"
                  >
                    <ion-select-option [value]="VppDemandTypes.DEMAND_REDUCTION">
                      {{"VirtualPowerPlant.DemandReduction"|translate}}
                    </ion-select-option>
                    <ion-select-option [value]="VppDemandTypes.DEMAND_INCREASE">
                      {{"VirtualPowerPlant.DemandIncrease"|translate}}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
                <ion-item>
                  <ion-select
                    name="meritOrder"
                    #meritOrder="ngModel"
                    [attr.disabled]=" newControlGroupSchedule.scheduleType == VppDispatchType.AUTO"
                    interface="popover"
                    [interfaceOptions]="customAlertOptions"
                    placeholder=' {{"VirtualPowerPlant.SelectOne" | translate}} '
                    [(ngModel)]="newControlGroupSchedule.selectedMerit"
                    [compareWith]="compareWith"
                    [required]=" newControlGroupSchedule.scheduleType == VppDispatchType.MANUAL"
                    (ionChange)="setDefaultValueOfControlValueIfBool(newControlGroupSchedule.selectedMerit)"
                    label="{{ 'VirtualPowerPlant.ControlName' | translate }}"
                  >
                    <ng-container *ngIf="newControlGroupSchedule.drmDemandType == VppDemandTypes.DEMAND_REDUCTION">
                      <ion-select-option
                        class="ion-text-wrap"
                        *ngFor="let merit of DRMerits; trackBy: meritTrackBy"
                        [value]="merit"
                      >
                        {{merit.order}} - {{merit.device_class}} - {{merit.control_code}} -
                        {{merit.control_description}}
                      </ion-select-option>
                    </ng-container>
                    <ng-container *ngIf="newControlGroupSchedule.drmDemandType == VppDemandTypes.DEMAND_INCREASE">
                      <ion-select-option *ngFor="let merit of DIMerits; trackBy: meritTrackBy" [value]="merit">
                        {{merit.order}} - {{merit.device_class}} - {{merit.control_code}}
                      </ion-select-option>
                    </ng-container>
                  </ion-select>
                </ion-item>
                <ion-item *ngIf="newControlGroupSchedule.selectedMerit?.control_type">
                  <ion-toggle
                    name="controlType"
                    *ngIf="newControlGroupSchedule.selectedMerit.control_type === MeritControlValueType.BOOLEAN"
                    [(ngModel)]="newControlGroupSchedule.controlValue"
                    required
                    slot="end"
                    color="button-secondary"
                    labelPlacement="start"
                    justify="space-between"
                  >
                    {{'VirtualPowerPlant.ControlValue'|translate}}
                  </ion-toggle>
                  <ion-input
                    name="controlType"
                    class="ion-text-right"
                    *ngIf="newControlGroupSchedule.selectedMerit.control_type === MeritControlValueType.INT || newControlGroupSchedule.selectedMerit.control_type === MeritControlValueType.FLOAT"
                    [(ngModel)]="newControlGroupSchedule.controlValue"
                    type="number"
                    required
                    label="{{'VirtualPowerPlant.ControlValue'|translate}}"
                    labelPlacement="floating"
                  ></ion-input>
                  <ion-input
                    name="controlType"
                    *ngIf="newControlGroupSchedule.selectedMerit.control_type === MeritControlValueType.STRING || newControlGroupSchedule.selectedMerit.control_type === MeritControlValueType.TEXT"
                    class="ion-text-right"
                    [(ngModel)]="newControlGroupSchedule.controlValue"
                    type="text"
                    required
                    label="{{'VirtualPowerPlant.ControlValue'|translate}}"
                    labelPlacement="floating"
                  ></ion-input>
                </ion-item>
              </div>
              <div
                *ngIf="newControlGroupSchedule.mode == VppModes.FCAS && (availableMarkets$ | async)?.data as markets"
              >
                <ion-item>
                  <ion-select
                    name="markets"
                    #markets="ngModel"
                    interface="popover"
                    multiple="true"
                    required
                    (ionChange)="marketChange(markets)"
                    [(ngModel)]="newControlGroupSchedule.selectedMarketUuids"
                    label="{{ 'VirtualPowerPlant.Markets' | translate }}"
                  >
                    <ion-select-option *ngFor="let market of markets" [value]="market.uuid"
                      >{{market.name}}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </div>
              <ion-item lines="full">
                <ion-input
                  name="controlPriority"
                  class="ion-text-right"
                  [(ngModel)]="newControlGroupSchedule.controlPriority"
                  type="number"
                  required
                  label="{{'VirtualPowerPlant.ControlPriority'|translate}}"
                  labelPlacement="floating"
                ></ion-input>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-self-end">
          <ion-col>
            <ion-button
              color="button-primary"
              size="large"
              expand="block"
              type="submit"
              [disabled]="!groupScheduleForm.form.valid"
            >
              {{editType == CrudType.UPDATE ? ("VirtualPowerPlant.UpdateControlGroupSchedule" | translate) :
              ("VirtualPowerPlant.CreateControlGroupSchedules" | translate) }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
