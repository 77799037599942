import { Injectable, OnDestroy } from '@angular/core';
import { Moment } from 'moment';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { UnitsService } from '../units/units.service';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';

export enum StatusFromMqtt {
  UNKNOWN = 0,
  ONLINE = 1,
  OFFLINE = 2,
}
export interface MqttStatusState {
  status: StatusFromMqtt;
  heartbeat: boolean;
  lastSeenAgo: string | null;
}

const OLDEST_VALID_TIMESTAMP_MS = 60000;
const OFFLINE_TIMEOUT_MS = 30000;
const REFRESH_MOMENT_STRING_TIMEOUT_MS = 30000;
@Injectable()
export class MqttStatusService implements OnDestroy {
  private _state: MqttStatusState = {
    status: StatusFromMqtt.UNKNOWN,
    heartbeat: false,
    lastSeenAgo: null,
  };

  private store = new BehaviorSubject<MqttStatusState>(this._state);
  private state$ = this.store.asObservable();
  private statusMqttStreamSubscription: Subscription;
  private lastSeenMoment: Moment;
  private updateMomentTimer: NodeJS.Timeout;
  private offlineTimer: NodeJS.Timeout;

  status$ = this.state$.pipe(map((state) => state.status));
  heartbeat$ = this.state$.pipe(
    map((state) => state.heartbeat),
    distinctUntilChanged(),
  );
  lastSeenAgo$ = this.state$.pipe(
    map((state) => state.lastSeenAgo),
    distinctUntilChanged(),
  );

  vm$: Observable<MqttStatusState> = combineLatest([this.status$, this.heartbeat$, this.lastSeenAgo$]).pipe(
    map(([status, heartbeat, lastSeenAgo]) => {
      const state = { status, heartbeat, lastSeenAgo };
      // console.log('MqttStatusService.vm$', { state: state });
      return state;
    }),
  );

  constructor(
    private unitsService: UnitsService,
    private _ngxMqttWrapper: NgxMqttWrapperService,
  ) {}

  public setInitialLastSeen(lastSeen: string): void {
    this.lastSeenMoment = moment(lastSeen);
    this.updateState({
      ...this._state,
      lastSeenAgo: this.lastSeenMoment.fromNow(),
    });
  }

  public setMqttKeysToWatch(keys: string[], dropletUuid: string): void {
    if (keys == null) {
      return;
    }
    this.resetState();
    this.updateTimers();

    const getValuesFromMeasurementsOrUnitStream = dropletUuid
      ? this._ngxMqttWrapper.observe(`measurementz/${dropletUuid}`).pipe(
          map(() => {
            const timestamp = Math.floor(moment().valueOf() / 1000);
            return { droplet_timestamp: timestamp };
          }),
          startWith(this.unitsService.unitMetricsMqtt.mqttSubject.getValue()),
        )
      : this.unitsService.unitMetricsMqtt.mqttSubject.asObservable();
    this.statusMqttStreamSubscription = getValuesFromMeasurementsOrUnitStream
      .pipe(filter((metrics) => metrics && metrics['droplet_timestamp'] != null))
      .subscribe((mqtt) => {
        // save the latest moment so a timer can use that to update the last seen string (if offline)
        this.lastSeenMoment = moment(mqtt['droplet_timestamp'] * 1000);
        const presentValuesInMqtt = keys.filter((key) => mqtt[key] != null);
        const isValidTimeStamp = this.isTimestampExpired(mqtt['droplet_timestamp']);
        if (presentValuesInMqtt.length > 0 && isValidTimeStamp) {
          const heartbeatFlipped = !this._state.heartbeat;
          this.updateState({
            ...this._state,
            status: StatusFromMqtt.ONLINE,
            heartbeat: heartbeatFlipped,
            lastSeenAgo: this.lastSeenMoment.fromNow(),
          });
          //reset offline timeout
          this.updateTimers();
        } else {
          this.updateState({
            ...this._state,
            status: StatusFromMqtt.OFFLINE,
            lastSeenAgo: this.lastSeenMoment.fromNow(),
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.resetState();
    this.clearTimers();
  }

  private resetState() {
    this.lastSeenMoment = null;
    this.updateState({ ...this._state, status: StatusFromMqtt.OFFLINE, lastSeenAgo: null });
    if (this.statusMqttStreamSubscription) {
      this.statusMqttStreamSubscription.unsubscribe();
    }
  }

  private updateState(state: MqttStatusState) {
    this.store.next((this._state = state));
  }

  private isTimestampExpired(dropletTimestampSec: number): boolean {
    const dropletTimestampMs = dropletTimestampSec * 1000;
    const unixEpochFromNowMs = Date.now();
    const timeDiffMs = unixEpochFromNowMs - dropletTimestampMs;
    return timeDiffMs < OLDEST_VALID_TIMESTAMP_MS;
  }

  private updateTimers() {
    this.clearTimers();

    // interval to continuously update the moment strings when we no longer receieve mqtt messages (offline)
    this.updateMomentTimer = setInterval(() => {
      this.updateState({
        ...this._state,
        lastSeenAgo: this.lastSeenMoment ? this.lastSeenMoment.fromNow() : undefined,
      });
    }, REFRESH_MOMENT_STRING_TIMEOUT_MS);

    // as long as we receive mqtt message (online), this timer will always get cancelled and restarted.
    // however, if we don't receive the message after a while, this timeout callback will execute and set the status to offline.
    this.offlineTimer = setTimeout(() => {
      this.updateState({ ...this._state, status: StatusFromMqtt.OFFLINE });
    }, OFFLINE_TIMEOUT_MS);
  }

  private clearTimers() {
    if (this.updateMomentTimer) {
      clearTimeout(this.updateMomentTimer);
    }
    if (this.offlineTimer) {
      clearInterval(this.offlineTimer);
    }
  }
}
