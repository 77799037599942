<div class="ion-padding bkgWhite">
  <ion-searchbar
    class="base-input mb-10"
    showCancelButton="never"
    placeholder="Filter"
    autocomplete="on"
    [debounce]="300"
    (ionInput)="applyFilter($event.target.value)"
  ></ion-searchbar>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
      <ng-container matColumnDef="group">
        <th class="ion-text-left width-8-pct" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'VirtualPowerPlant.Group' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.group }}</td>
      </ng-container>

      <ng-container matColumnDef="demandType">
        <th class="width-8-pct" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'VirtualPowerPlant.DemandType' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.demandType }}</td>
      </ng-container>

      <ng-container matColumnDef="order">
        <th class="width-8-pct" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'VirtualPowerPlant.Merit' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.order }}</td>
      </ng-container>

      <ng-container matColumnDef="requestTime">
        <th class="ion-text-left" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'VirtualPowerPlant.RequestTime' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.requestDateTime }}</td>
      </ng-container>

      <ng-container matColumnDef="startTime">
        <th class="ion-text-left" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'VirtualPowerPlant.StartTime' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.startTimeEpochSec | vppTimezoneLocale: LOCALE_OPTION | async }} <br />
          {{ element.startTimeFromNow }}
        </td>
      </ng-container>

      <ng-container matColumnDef="endTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'VirtualPowerPlant.EndTime' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.endTimeEpochSec | vppTimezoneLocale: LOCALE_OPTION | async }} <br />
          {{ element.endTimeFromNow }}
        </td>
      </ng-container>

      <ng-container matColumnDef="dispatch">
        <th class="width-8-pct ion-text-center" mat-header-cell *matHeaderCellDef>
          {{ 'VirtualPowerPlant.Dispatch' | translate }}
        </th>
        <td class="ion-text-center" mat-cell *matCellDef="let element">{{ element.dispatch }}</td>
      </ng-container>

      <ng-container matColumnDef="available">
        <th class="width-8-pct ion-text-center" mat-header-cell *matHeaderCellDef>
          {{ 'VirtualPowerPlant.Available' | translate }}
        </th>
        <td class="ion-text-center" mat-cell *matCellDef="let element">
          {{ element.available }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th class="width-8-pct ion-text-center" mat-header-cell *matHeaderCellDef>
          {{ 'General.Status' | translate }}
        </th>
        <td class="ion-text-center" mat-cell *matCellDef="let element">{{ element.action }}</td>
      </ng-container>

      <ng-container matColumnDef="dispatchType">
        <th class="width-8-pct ion-text-center" mat-header-cell *matHeaderCellDef>
          {{ 'VirtualPowerPlant.DispatchType' | translate }}
        </th>
        <td class="ion-text-center" mat-cell *matCellDef="let element">
          <span *ngIf="element.dispatchType === VppDispatchType.MANUAL">
            {{ 'VirtualPowerPlant.Scheduled' | translate }} | {{ 'VirtualPowerPlant.Manual' | translate }}
          </span>
          <span *ngIf="element.dispatchType === VppDispatchType.AUTO">
            {{ 'VirtualPowerPlant.Scheduled' | translate }} | {{ 'VirtualPowerPlant.Auto' | translate }}
          </span>
          <span
            *ngIf="element.dispatchType !== VppDispatchType.MANUAL && element.dispatchType !== VppDispatchType.AUTO"
          >
            {{ element.dispatchType }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'VirtualPowerPlant.User' | translate }}</th>
        <td class="ion-text-center" mat-cell *matCellDef="let element">{{ element.userFullname }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th class="width-8-pct ion-text-center" mat-header-cell *matHeaderCellDef>
          {{ 'VirtualPowerPlant.Action' | translate }}
        </th>
        <td class="ion-text-center" mat-cell *matCellDef="let element">
          <ng-container *ifHasPermission="[PermissionKey.VPP_DISPATCH_VPP]">
            <ion-button
              *ngIf="element.action == 'enable'"
              size="small"
              color="danger"
              (click)="presentCancelModal(element)"
            >
              <ion-icon slot="icon-only" name="remove"></ion-icon>
            </ion-button>
          </ng-container>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div
            *ngIf="element.schedule || (element.device && element.device.length > 0)"
            class="expandRow-element-detail"
          >
            <ion-row style="width: 100%">
              <ion-col>
                <div *ngIf="element.schedule != null" class="ion-margin-top ion-margin-bottom divGrid">
                  <h4 class="ion-text-capitalize">{{ 'VirtualPowerPlant.ScheduleDetail' | translate }}</h4>
                  <ion-label>
                    {{ 'General.Name' | translate }}: <strong>{{ element.schedule.name }}</strong>
                  </ion-label>
                  <ion-label>
                    {{ 'VirtualPowerPlant.Description' | translate }}:
                    <strong>{{ element.schedule.description }}</strong>
                  </ion-label>
                </div>
              </ion-col>
              <ion-col>
                <div
                  *ngIf="element.device != null && element.device.length > 0"
                  class="ion-margin-top ion-margin-bottom divGrid"
                >
                  <h4 class="ion-text-capitalize">{{ 'General.Device' | translate }}</h4>
                  <div class="divGrid">
                    <ion-label>
                      <strong>{{ element.device }}</strong>
                    </ion-label>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="firstRowHeader"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="expandRow-element-row"
        [class.expandRow-expanded-row]="expandedElement === element"
        (click)="rowClicked(element)"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandRow-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
  </div>
</div>
