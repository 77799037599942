import { Injectable } from '@angular/core';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';
import { isEmpty } from 'lodash';
import { IMqttMessage } from 'ngx-mqtt';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, share, switchMap, take, tap } from 'rxjs/operators';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../../classes/commons/request-api.model';
import {
  VppControlGroup,
  VppControlGroupEditForm,
  VppControlGroupGetPayload,
  VppControlGroupPutPayload,
  VppSettingGroupsTabControlGroup,
  VppSettingGroupsTabControlGroupMetricObj,
} from '../../../classes/vpp/vpp-control-group.model';
import { getVppDemandTypeTranslationString, VppDemandTypes } from '../../../classes/vpp/vpp-demand-type-mode.model';
import { VppFcasMarket } from '../../../classes/vpp/vpp-fcas-markets.model';
import { VppUnitMetricsMqttMsg } from '../../../classes/vpp/vpp-unit.model';
import { ApiWrapper } from '../../common/api-wrapper.service';
import { VirtualPowerPlantsService } from '../virtual-power-plants.service';

@Injectable({
  providedIn: 'root',
})
export class VppControlGroupService {
  private refreshControlGroupSubject = new BehaviorSubject<void>(null);
  private controlGroups: VppControlGroup[];
  public controlGroups$ = combineLatest([this.vppService.vppSelected$, this.refreshControlGroupSubject]).pipe(
    switchMap(([vpp]) => {
      return this.getControlGroups(vpp?.id, vpp?.uuid);
    }),
  );
  public settingControlGroups$: Observable<VppSettingGroupsTabControlGroup[]> = this.controlGroups$.pipe(
    map((groups) => this.adaptToSettingControlGroups(groups)),
    share(),
  );
  constructor(
    private api: ApiWrapper,
    private vppService: VirtualPowerPlantsService,
    private _ngxMqttWrapper: NgxMqttWrapperService,
  ) {}

  private async getControlGroups(vppId: string, vppUuid: string) {
    if (vppId == null || vppUuid == null) return [];
    const response: { data: VppControlGroupGetPayload[] } = await this.getVppControlGroupsApi(vppId);
    this.controlGroups = response.data.map((payload) => this.adaptPayloadToControlGroup(payload, vppId, vppUuid));
    return this.controlGroups;
  }

  private adaptPayloadToControlGroup(
    payload: VppControlGroupGetPayload,
    vppId: string,
    vppUuid: string,
  ): VppControlGroup {
    const { id, uuid, name, description, num_devices, num_units } = payload;
    const controlGroup: VppControlGroup = {
      id,
      uuid,
      name,
      description,
      deviceNum: num_devices,
      unitNum: num_units,
      vppUuid,
      vppId,
    };
    return controlGroup;
  }

  private adaptToSettingControlGroups(controlGroups: VppControlGroup[]): VppSettingGroupsTabControlGroup[] {
    if (isEmpty(controlGroups)) return [];
    return controlGroups.map((group) => this.adaptControlGroupToSettingControlGroup(group));
  }

  private adaptControlGroupToSettingControlGroup(controlGroup: VppControlGroup): VppSettingGroupsTabControlGroup {
    const { id, uuid, name, description, deviceNum, unitNum, vppUuid } = controlGroup;

    const metricObjReducer = (
      metricObj: Record<VppDemandTypes, VppSettingGroupsTabControlGroupMetricObj>,
      demandTypeKey: VppDemandTypes,
    ): Record<VppDemandTypes, VppSettingGroupsTabControlGroupMetricObj> => {
      const demandTypeName = getVppDemandTypeTranslationString(demandTypeKey);
      const metricKey = `SWDINPV.DT[${demandTypeKey}].CG[${name}].TotW[Total]`;
      const demandTypeValue$: Observable<number> = this._ngxMqttWrapper.observe(vppUuid + '/vpp_metrics').pipe(
        map((msg: IMqttMessage): number => {
          const msgString: VppUnitMetricsMqttMsg = JSON.parse(msg.payload.toString());
          return msgString.measurements[metricKey];
        }),
      );

      const metricNameValue: VppSettingGroupsTabControlGroupMetricObj = {
        name: demandTypeName,
        value$: demandTypeValue$,
      };
      metricObj[demandTypeKey] = metricNameValue;
      return metricObj;
    };
    const metrics = Object.values(VppDemandTypes).reduce(
      metricObjReducer,
      {} as Record<VppDemandTypes, VppSettingGroupsTabControlGroupMetricObj>,
    );

    const settingTabControlGroup: VppSettingGroupsTabControlGroup = {
      id,
      uuid,
      name,
      description,
      metrics,
      deviceCount: deviceNum,
      unitCount: unitNum,
      unitsDisplayed: true,
    };
    return settingTabControlGroup;
  }

  public updateControlGroupDetails(controlGroup: VppControlGroupPutPayload): Promise<any> {
    if (controlGroup?.id == null) return Promise.resolve();
    return this.updateControlGroupApi(controlGroup);
  }

  public refreshControlGroup(): void {
    this.refreshControlGroupSubject.next();
  }

  public createControlGroup(controlGroupDetails: VppControlGroupEditForm): Observable<any> {
    if (isEmpty(controlGroupDetails)) {
      console.warn('Warning: Attempting to submit empty control group');
      return of();
    }
    const createControlGroup$ = this.vppService.vppSelected$.pipe(
      filter(({ id }) => id != null),
      map(({ id }) => {
        const { name, description } = controlGroupDetails;
        return { name, description, active: true, vpp_id: id, id: null };
      }),
      switchMap((payload) => from(this.createControlGroupApi(payload))),
      catchError((e) => throwError(e)),
      tap((_) => this.refreshControlGroup()),
      take(1),
    );
    return createControlGroup$;
  }

  public deleteControlGroup(controlGroup: VppSettingGroupsTabControlGroup): Observable<any> {
    if (isEmpty(controlGroup)) return throwError('No control group detail submitted');
    const removeControlGroup$ = this.vppService.vppSelected$.pipe(
      filter((vpp) => vpp?.id != null),
      switchMap(({ id }) => from(this.removeControlGroupApi(controlGroup, id))),
      catchError((e) => throwError(e)),
      tap((_) => this.refreshControlGroup()),
      take(1),
    );
    return removeControlGroup$;
  }

  public async getAvailableMarketsWithControlGroupName(groupName: string): Promise<{ data: VppFcasMarket[] }> {
    const controlGroups = await this.controlGroups$.pipe(take(1)).toPromise();
    const matchingGroup = controlGroups.find((group) => group.name === groupName);
    return this.getAvailableFcasMarketsGivenControlGroupId(matchingGroup?.id);
  }

  public getAvailableFcasMarketsGivenControlGroupId(groupId: string): Promise<{ data: VppFcasMarket[] }> {
    return groupId ? this.getFcasMarketsApi(groupId) : Promise.resolve(null);
  }
  private getVppControlGroupsApi(vppId: string): Promise<any> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/control_groups/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  private createControlGroupApi(data: VppControlGroupPutPayload): Promise<any> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/control-groups/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }
  private updateControlGroupApi(controlGroup: VppControlGroupPutPayload): Promise<any> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/control-groups/' + controlGroup.id + '/',
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      controlGroup,
    );
  }
  private removeControlGroupApi(controlGroup: VppSettingGroupsTabControlGroup, vppId: string): Promise<any> {
    const obj =
      controlGroup.id +
      '/?active=' +
      true +
      '&description=' +
      controlGroup.description +
      '&name=' +
      controlGroup.name +
      '&to_delete=true&vpp_id=' +
      vppId;
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/control-groups/' + obj,
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  private getFcasMarketsApi(controlGroupId: string): Promise<any> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/control-groups/' + controlGroupId + '/fcas-markets/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
}
