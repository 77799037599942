import { UnitsService } from './../../services/units/units.service';
import { Component, OnInit, Input } from '@angular/core';
import { TranslationsService } from './../../services/common/translations.service';
import moment from 'moment';

@Component({
  selector: 'switchdin-device-stream',
  templateUrl: './switchdin-device-stream.component.html',
  styleUrls: ['./switchdin-device-stream.component.scss'],
})
export class SwitchdinDeviceStreamComponent implements OnInit {
  @Input() needToResize;
  interval: NodeJS.Timeout; //interval that computes the how long ago strings
  currentStreamFromNow = new Array<Array<string>>(); //displays how long ago the most recent displayed data is from, array is arranged as array[endpoint index][device index]
  firstStreamFromNow = new Array<Array<string>>(); //displays how long ago the oldest displayed data is from, array is arranged as array[endpoint index][device index]
  constructor(
    public unitsService: UnitsService,
    public translationsService: TranslationsService,
  ) {}

  ngOnInit() {
    // must immediately compute last updated from string, otherwise screen will fail to render until first interval
    this.computeStreamUpdateTimeString();
    this.interval = setInterval(() => {
      this.computeStreamUpdateTimeString();
    }, 5000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  //function to grab mqtt timestamps and return a string stating how long it has been since received first and last data point in stream
  computeStreamUpdateTimeString() {
    this.unitsService.unitEndpoints.endpoints.forEach((endpoint, endpointIndex) => {
      const endpointCurrentStreamFromNow = [];
      const endpointFirstStreamFromNow = [];
      endpoint.devices.forEach((device, deviceIndex) => {
        if (device.metrics.length >= 1) {
          if (device.FirstSeen) {
            const timeStampFirst = device.FirstSeen;
            endpointFirstStreamFromNow[deviceIndex] = moment(timeStampFirst).fromNow();
          }
          if (device.lastSeen) {
            const timeStampCurrent = device.lastSeen;
            endpointCurrentStreamFromNow[deviceIndex] = moment(timeStampCurrent).fromNow();
          }
        }
      });
      this.currentStreamFromNow[endpointIndex] = endpointCurrentStreamFromNow;
      this.firstStreamFromNow[endpointIndex] = endpointFirstStreamFromNow;
    });
  }
}
