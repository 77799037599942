<ion-card class="ion-no-padding ion-no-margin new-design-card">
  <ion-card-header>
    <ion-grid class="ion-no-padding ion-no-margin">
      <ion-row>
        <ion-col size="6">
          <ion-row>
            <ion-card-title class="card-title"> {{ 'Carbon.Carbon' | translate }} </ion-card-title>
            <a
              href="https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ion-icon class="custom-icon-small pl-5" src="assets/icons/svg/info.svg"></ion-icon>
            </a>
          </ion-row>
        </ion-col>
        <ion-col size="6">
          <p class="card-small-subheader mt-7">
            {{ 'Carbon.Past7Days' | translate }}
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-header>
  <ion-card-content class="custom-ion-content-height">
    <ion-grid class="ion-no-padding fullHeight pb-15">
      <ion-row class="ion-align-items-stretch width-100-pct fullHeight">
        <ion-col size="4">
          <div class="light-brand-primary-div-background fullHeight b-radius-6 mr-3 vertically-center">
            <ion-icon class="custom-icon-medium pl-5 pt-20" src="assets/icons/svg/carbon.svg"></ion-icon>
            <p class="light-text fw-600 fs-18 text-dark pl-5">
              {{ (unitCarbonSavingStore$ | async)?.data.tonnesOfCarbon ?? '?' }}
            </p>
            <p class="text-light-o pl-5 pr-5">
              {{ 'Carbon.TonnesOfCO2Saved' | translate }}
            </p>
          </div>
        </ion-col>
        <ion-col size="4">
          <div class="light-brand-primary-div-background fullHeight ml-3 mr-3 b-radius-6 vertically-center">
            <ion-icon class="custom-icon-medium pl-5 pt-20" src="assets/icons/svg/produced.svg"></ion-icon>
            <p class="fw-600 fs-18 text-dark pl-5">
              {{ (unitCarbonSavingStore$ | async)?.data.energyProduced ?? '?' }}
            </p>
            <p class="text-light-o pl-5 pr-5">
              {{ 'Carbon.kWhEnergyProduced' | translate }}
            </p>
          </div>
        </ion-col>
        <ion-col size="4">
          <div class="light-brand-primary-div-background fullHeight b-radius-6 ml-3 vertically-center">
            <ion-icon class="custom-icon-medium pl-5 pt-20" src="assets/icons/svg/trees.svg"></ion-icon>
            <p class="fw-600 fs-18 text-dark pl-5">
              {{ (unitCarbonSavingStore$ | async)?.data.treesPlanted ?? '?' }}
            </p>
            <p class="text-light-o pl-5 pr-5">
              <span *ngIf="(unitCarbonSavingStore$ | async)?.data.treesPlanted === 1">{{
                'Carbon.TreePlanted' | translate
              }}</span>
              <span *ngIf="(unitCarbonSavingStore$ | async)?.data.treesPlanted !== 1">{{
                'Carbon.TreesPlanted' | translate
              }}</span>
            </p>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
