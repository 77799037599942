<ion-card class="card-design text-dark">
  <div class="fs-22 fw-600 mt-25 ml-20 mb-16">{{"VirtualPowerPlant.ControlGroup" | translate }}</div>
  <div class="bb-1"></div>
  <div class="mt-17 ml-20">{{"VirtualPowerPlant.Name" | translate}}</div>
  <div class="mt-13 ml-20 mr-20">
    <ion-input
      [autofocus]="true"
      class="ion-padding-start"
      [(ngModel)]="newCG.name"
      maxlength="5"
      type="text"
    ></ion-input>
  </div>
  <div class="mt-13 bb-1"></div>
  <div class="mt-17 ml-20">{{"VirtualPowerPlant.Description" | translate}}</div>
  <div class="mt-13 ml-20 mr-20">
    <ion-input class="ion-padding-start" [(ngModel)]="newCG.description"></ion-input>
  </div>
  <div class="m-0 name-button-row">
    <ion-button fill="outline" color="button-primary" mode="ios" class="m-0 cancel-name-button" (click)="dismiss()">
      {{ 'General.Cancel' | translate }}
    </ion-button>
    <ion-button
      [attr.disabled]="newCG.name == '' "
      mode="ios"
      color="button-primary"
      class="m-0 update-name-button"
      (click)="createNewControlGroup()"
    >
      {{ 'VirtualPowerPlant.CreateControlGroup' | translate }}
    </ion-button>
  </div>
</ion-card>
