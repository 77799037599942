import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { IsPlatformValues } from '@class/commons/is-platform-values';

@Component({
  selector: 'swidin-input',
  templateUrl: './swidin-input.component.html',
  styleUrls: ['./swidin-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwidinInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SwidinInputComponent),
      multi: true,
    },
  ],
})
export class SwidinInputComponent extends IsPlatformValues implements ControlValueAccessor {
  @Input()
  _inputValue = '';

  searchActive = false;
  filterActive = false;

  get inputValue(): string {
    return this._inputValue;
  }
  set inputValue(val: string) {
    this._inputValue = val;
    if (this._inputValue) this.modelValid = this.validateRegex(this.regex, this._inputValue);
    this.propagateChange(this._inputValue);
  }

  @Input('label')
  public _label = '';

  get label(): string {
    return this._label;
  }
  set label(val: string) {
    this._label = val;
    this.propagateChange(this._label);
  }

  @Input('regex')
  public _regex: string[] | string = '';
  get regex(): string[] | string {
    return this._regex;
  }
  set regex(val: string[] | string) {
    this._regex = val;
    this.propagateChange(val);
  }

  @Input('focus')
  public _autofocus = false;
  get focus(): boolean {
    return this._autofocus;
  }
  set focus(val: boolean) {
    this._autofocus = val;
    this.propagateChange(val);
  }

  modelValid = false;

  propagateChange = (_: any) => {};
  onTouched: () => void = () => {};

  writeValue(value: any) {
    this.inputValue = value;
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(): { regexError: { valid: boolean } } | null {
    if (this._inputValue) this.modelValid = this.validateRegex(this.regex, this._inputValue);

    return this.modelValid ? null : { regexError: { valid: false } };
  }
  validateRegex(regex: string | string[], inputValue: string): boolean {
    if (!inputValue) {
      return false;
    }
    const regexList: string[] = typeof regex === 'string' ? [regex] : regex;
    return regexList.find((rg: string) => inputValue.match(rg) && inputValue.match(rg).length !== 0) !== undefined;
  }

  constructor() {
    super();
    if (!this.IS_PLATFORM_IOS) {
      this.focus = true;
    }
  }
}
