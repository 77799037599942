import { Injectable, inject } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { DeviceMqttKey } from '../../classes/units/droplet/droplet-metric.model';
import { States } from '@class/commons/constants';
import { HTTP_ERRORS_TRANSLATION_HEADING, generateHttpErrorMessage } from '@class/error/http-error-types';
import { TranslationsService } from '@service/common/translations.service';
import { ApiWrapper } from '@service/common/api-wrapper.service';
import { UnitStore } from './unit.store';
import { ChartdataApiService } from 'app/api-services/chartdata.api-service';
import { TimePeriodResolution } from '@class/commons/constants-datetime';
import { filterSuccessResult } from '@ngneat/query';

interface UnitChartDataApi {
  [index: string]: {
    time: string;
    value: number;
  }[];
}
interface UnitBatteryStatus {
  totalPowerDelivered: string;
  totalPowerStored: string;
  avgSoC: string;
  batteryIcon: string;
}
export interface UnitBatteryStatusStateData {
  data: UnitBatteryStatus;
  status: States;
  error?: string;
  message?: string;
}
const UNIT_BATTERY_STATUS_INITIAL_STATE: UnitBatteryStatusStateData = {
  status: States.INITIAL_LOADING,
  data: {
    totalPowerDelivered: '...',
    totalPowerStored: '...',
    avgSoC: '...',
    batteryIcon: '/assets/icons/svg/battery-empty.svg',
  },
};

const METRIC_LIST_STRING = `(${DeviceMqttKey.SOC},${DeviceMqttKey.BESS})`;
const METRIC_DATA_RESOLUTION = 'hour';

@Injectable({
  providedIn: 'root',
})
export class UnitBatteryStatusStore {
  private _unitBatteryStatusStore = new BehaviorSubject<UnitBatteryStatusStateData>(UNIT_BATTERY_STATUS_INITIAL_STATE);
  unitBatteryStatusStore$ = this._unitBatteryStatusStore.asObservable();

  private _chartdataApiService = inject(ChartdataApiService);

  constructor(
    private _api: ApiWrapper,
    private _translationsService: TranslationsService,
    private _unitStore: UnitStore,
  ) {
    this.getUnitBatteryStatus();
  }

  // private methods
  private getUnitBatteryStatus() {
    this._unitStore.unit$
      .pipe(
        filter((unitData) => unitData.data !== null),
        distinctUntilKeyChanged('data', (prev, curr) => prev.uuid === curr.uuid),
        switchMap((unit) => this.getUnitChartDataApi(unit.data.uuid)),
        map((unitBatteryStatus) => {
          let avgSoC,
            totalPowerDelivered,
            totalPowerStored = '...';
          let batteryIcon = '/assets/icons/svg/battery-empty.svg';
          //calculate battery average state of charge over 7 day period
          if (unitBatteryStatus[DeviceMqttKey.SOC]) {
            avgSoC = this.calculateBatteryAvgSoC(unitBatteryStatus[DeviceMqttKey.SOC]).toFixed(1);
            batteryIcon = this.chooseBatterySvgFromSoC(avgSoC);
          }
          //calculate battery total charge and discharge over 7 day period
          if (unitBatteryStatus[DeviceMqttKey.BESS]) {
            const returnSum = this.calculateBatteryEnergy(unitBatteryStatus[DeviceMqttKey.BESS]);
            totalPowerDelivered = Math.abs(returnSum.totalPowerDelivered).toFixed(2);
            totalPowerStored = returnSum.totalPowerStored.toFixed(2);
          }

          return {
            data: {
              totalPowerDelivered,
              totalPowerStored,
              avgSoC,
              batteryIcon,
            },
            status: States.INITIAL_DATA_RECEIVED,
          };
        }),
        catchError((error) => this.handleError(error)),
        startWith(UNIT_BATTERY_STATUS_INITIAL_STATE),
        tap((unitBatteryStatusData) => {
          this._unitBatteryStatusStore.next(unitBatteryStatusData);
        }),
      )
      .subscribe();
  }
  private getUnitChartDataApi(unitUuid: string): Observable<UnitChartDataApi> {
    // request battery metrics ranging from now to 7 days ago
    const SevenDaysAgoDate = new Date();
    SevenDaysAgoDate.setDate(SevenDaysAgoDate.getDate() - 7);

    return this._chartdataApiService
      .getChartdata({
        field: 'unit_uuid',
        uuid: unitUuid,
        period: METRIC_DATA_RESOLUTION as TimePeriodResolution,
        metrics: METRIC_LIST_STRING,
        from: SevenDaysAgoDate,
      })
      .result$.pipe(
        filterSuccessResult(),
        map((res) => res.data.data),
      );
  }

  private handleError(err, state: States = States.INITIAL_ERROR): Observable<UnitBatteryStatusStateData> {
    const message = generateHttpErrorMessage(err, this._translationsService.instant(HTTP_ERRORS_TRANSLATION_HEADING));
    return of({
      status: state,
      error: message.message,
      data: UNIT_BATTERY_STATUS_INITIAL_STATE.data,
    });
  }

  // function used by reduce functions to obtain metric sum
  // this should have come from backend instead calculating it on client side
  private sumMetric = ({ totalSum, index }, value: number) => {
    return {
      totalSum: totalSum + value,
      index: index + 1,
    };
  };

  private calculateBatteryAvgSoC(data: Array<{ time: string; value: number }>): number {
    let result = 0;
    if (data?.length) {
      // extract a list of battery soc values from list of soc object
      const socList = data.map((datum) => {
        return datum.value;
      });
      // sum list of soc values
      const socInfo = socList.reduce(this.sumMetric, { totalSum: 0, index: 0 });
      // average list of soc values to obtain 7 day soc average
      result = socInfo.totalSum > 0 && socInfo.index > 0 ? socInfo.totalSum / socInfo.index : 0;
    }
    // console.log('OverviewCardService.calculateBatteryAvgSoC', { result, data });
    return result;
  }

  private calculateBatteryEnergy(data: Array<{ time: string; value: number }>): {
    totalPowerStored: number;
    totalPowerDelivered: number;
  } {
    // extract a list of battery power values from list of battery energy object
    const batteryEnergyValues = data.map((datum) => {
      return datum.value;
    });
    // get list of power stored and delivered
    const batteryDischargeValues = batteryEnergyValues.filter((datum) => datum < 0);
    const batteryChargeValues = batteryEnergyValues.filter((datum) => datum > 0);
    // get sum of power stored and delivered.
    const batteryDischargeInfo = batteryDischargeValues.reduce(this.sumMetric, { totalSum: 0, index: 0 });
    const batteryChargeInfo = batteryChargeValues.reduce(this.sumMetric, { totalSum: 0, index: 0 });

    return { totalPowerStored: batteryChargeInfo.totalSum, totalPowerDelivered: batteryDischargeInfo.totalSum };
  }

  // function to set battery icon svg path property binding,
  // based on current value of state of charge average over 7 days.
  private chooseBatterySvgFromSoC(avgSoC: string): string {
    let batterySvg = '/assets/icons/svg/battery-empty.svg';
    if (parseFloat(avgSoC) > 87.5) {
      batterySvg = 'assets/icons/svg/battery-full.svg';
    } else if (parseFloat(avgSoC) > 62.5) {
      batterySvg = '/assets/icons/svg/battery-3-4.svg';
    } else if (parseFloat(avgSoC) > 37.5) {
      batterySvg = '/assets/icons/svg/battery-2-4.svg';
    } else if (parseFloat(avgSoC) > 12.5) {
      batterySvg = '/assets/icons/svg/battery-1-4.svg';
    } else if (parseFloat(avgSoC) > 5) {
      batterySvg = '/assets/icons/svg/battery-5-percent.svg';
    } else {
      batterySvg = '/assets/icons/svg/battery-empty.svg';
    }
    return batterySvg;
  }
}
