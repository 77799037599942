import { Injectable } from '@angular/core';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';
import { isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { VppControlGroupUnitUpdatePayload } from '../../../classes/vpp/vpp-control-group.model';
import {
  VppUnit,
  VppUnitDeviceTypes,
  VppUnitGetPayload,
  VppUnitMetricsMqttMsg,
} from '../../../classes/vpp/vpp-unit.model';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '../../common/api-wrapper.service';
import { VirtualPowerPlantsService } from '../virtual-power-plants.service';

@Injectable({
  providedIn: 'root',
})
export class VppUnitsService {
  private refreshUnitDetailsSubject = new BehaviorSubject<void>(null);
  public unitsObs = combineLatest([this.vppService.vppSelected$, this.refreshUnitDetailsSubject]).pipe(
    switchMap(([vpp]) => this.fetchVppUnits(vpp?.id)),
    map((res) => this.adaptVppUnitPayloadToVppUnits(res)),
    distinctUntilChanged(),
  );
  public units$ = new BehaviorSubject<VppUnit[]>(null);
  constructor(
    private vppService: VirtualPowerPlantsService,
    private api: ApiWrapper,
    private _ngxMqttWrapper: NgxMqttWrapperService,
  ) {
    this.unitsObs.subscribe(this.units$);
  }

  private fetchVppUnits(vppId: string): Observable<any> {
    if (vppId == null) return of([]);
    return from(this.vppUnitsByVppId(vppId));
  }

  private adaptVppUnitPayloadToVppUnits(response: { data: VppUnitGetPayload[] }): VppUnit[] {
    if (isEmpty(response?.data)) return [];
    return response.data.map((rawUnitData) => this.adaptVppUnitPayload(rawUnitData));
  }

  private adaptVppUnitPayload(payload: VppUnitGetPayload): VppUnit {
    const { id, name, uuid, control_groups, devices, external_identifier, nmi } = payload;
    const unitVppMetrics$ = this.createUnitVppMetricsObservables(uuid);
    const controlGroupNames = Object.keys(control_groups);
    const deviceCounts = this.adaptVppUnitDeviceCount(payload);
    const vppUnits: VppUnit = {
      id,
      name,
      uuid,
      devices,
      deviceCounts,
      unitVppMetrics$,
      controlGroupNames,
      external_identifier,
      nmi,
    };
    return vppUnits;
  }

  private adaptVppUnitDeviceCount(payload: VppUnitGetPayload): Record<VppUnitDeviceTypes, number> {
    const reducer = (deviceCounts: Record<VppUnitDeviceTypes, number>, deviceType: VppUnitDeviceTypes) => {
      deviceCounts[deviceType] = payload?.[deviceType] ?? 0;
      return deviceCounts;
    };
    return Object.values(VppUnitDeviceTypes).reduce(reducer, {} as Record<VppUnitDeviceTypes, number>);
  }

  private createUnitVppMetricsObservables(unitUuid: string): Observable<VppUnitMetricsMqttMsg> {
    return this._ngxMqttWrapper.observe(unitUuid + '/vpp_metrics').pipe(
      map((msg): VppUnitMetricsMqttMsg => {
        return JSON.parse(msg.payload.toString());
      }),
    );
  }

  private vppUnitsByVppId(vppId: string): Promise<any> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/control_groups_units/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  public modifyControlGroupUnits(units: VppControlGroupUnitUpdatePayload, cgUuid: string): Promise<any> {
    if (isEmpty(units) || cgUuid == null) {
      return Promise.resolve();
    }
    return this.updateControlGroupUnitsApi(units, cgUuid);
  }

  public refreshControlGroupUnits(): void {
    this.refreshUnitDetailsSubject.next();
  }

  private updateControlGroupUnitsApi(units: VppControlGroupUnitUpdatePayload, cgUuid: string): Promise<any> {
    // action = add_units, remove_units or update_units
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/control-groups/' + cgUuid + '/units/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      units,
    );
  }
}
