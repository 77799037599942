import { UnitsService } from './../../services/units/units.service';
import { Component, OnInit, ViewChild, SimpleChanges, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-raw-data',
  templateUrl: './raw-data.component.html',
  styleUrls: ['./raw-data.component.scss'],
})
export class RawDataComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() data;
  @Input() toggle;
  @Input() mqttData;
  displayedColumns: string[] = ['status', 'device', 'metric', 'key', 'value', 'time', 'updated'];
  dataSource;
  filterString: string;
  interval;
  search: string = '';
  constructor(public unitsService: UnitsService) {}

  ngOnInit() {
    this.interval = setInterval(() => {
      if (this.data != null && this.data !== undefined && this.data.length > 0) {
        this.dataSource = new MatTableDataSource(this.data);
        if (this.paginator) {
          this.dataSource.paginator = this.paginator;
        }
        if (this.filterString) {
          this.applyFilter(this.filterString);
        }
      }
    }, 5000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    this.dataSource = new MatTableDataSource(this.data);
    if (this.filterString) this.applyFilter(this.filterString);
    if (this.paginator) this.dataSource.paginator = this.paginator;
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    if (this.interval) clearInterval(this.interval);
  }

  applyFilter(filterValue: string) {
    this.filterString = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
