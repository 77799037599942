import { Component, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingController, AlertController, ToastController, ModalController } from '@ionic/angular';
import { TranslationsService } from '../../services/common/translations.service';
import {
  VppControlGroupScheduleFormData,
  VppCreateControlGroupScheduleModalPage,
} from '../../pages/modals/vpp-create-control-group-schedule-modal/vpp-create-control-group-schedule-modal.page';
import { VppControlGroupSchedule } from '../../classes/vpp/vpp-control-group-schedule.model';
import { isVppDemandTypeDrm, isVppModeDrm } from '../../classes/vpp/vpp-demand-type-mode.model';
import { VirtualPowerPlantsService } from '../../services/virtualPowerPlants/virtual-power-plants.service';
import { VppControlGroupSchedulesService } from '../../services/virtualPowerPlants/control-group-schedules/vpp-control-group-schedules.service';
import { Subscription } from 'rxjs';
import { VppControlGroupService } from '../../services/virtualPowerPlants/control-group/vpp-control-group.service';
import { take } from 'rxjs/operators';
import { adaptVppControlGroupToCGSControlGroup } from '../../classes/vpp/vpp-control-group.model';
import { CrudType } from '@class/commons/enums';
import { VppDispatchType, VPP_TABLE_PAGE_SIZE_OPTIONS } from '@class/vpp/vpp-dispatch.model';
import { PermissionKey } from '@class/commons/permissions/permission-constants';

@Component({
  selector: 'app-vpp-control-group-schedules',
  templateUrl: './vpp-control-group-schedules.component.html',
  styleUrls: ['./vpp-control-group-schedules.component.scss'],
})
export class VppControlGroupSchedulesComponent implements OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  readonly VPP_TABLE_PAGE_SIZE_OPTIONS = VPP_TABLE_PAGE_SIZE_OPTIONS;
  readonly columnsToDisplay = [
    'name',
    'description',
    'controlPriority',
    'controlValue',
    'controlName',
    'type',
    'action',
  ];
  readonly PermissionKey = PermissionKey;
  dataSource: MatTableDataSource<VppControlGroupSchedule>;
  expandedElement: VppControlGroupSchedule;
  private serviceSubscription: Subscription;

  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private toastController: ToastController,
    private trans: TranslationsService,
    private modalController: ModalController,
    private vppService: VirtualPowerPlantsService,
    private vppControlGroupSchedulesService: VppControlGroupSchedulesService,
    private controlGroupService: VppControlGroupService,
  ) {
    this.serviceSubscription = this.vppControlGroupSchedulesService.controlGroupSchedules$.subscribe(
      (controlGroupSchedules) => {
        this.dataSource = new MatTableDataSource(controlGroupSchedules);
        if (this.paginator) this.dataSource.paginator = this.paginator;
      },
    );
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  rowClicked(element: VppControlGroupSchedule): void {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  async removeSchedule(schedule: VppControlGroupSchedule): Promise<void> {
    try {
      const loader = await this.loadingController.create();
      await loader.present();
      await this.vppControlGroupSchedulesService.removeVppControlGroupSchedule(schedule.id);
      await loader.dismiss();
      await this.presentToast(this.trans.str.vpp.ScheduleremoveSuccess);
    } catch (error) {
      console.error(error);
      this.presentAlertSimpleOk(
        this.trans.str.vpp.InviteUser.UserFail.Header,
        this.trans.str.vpp.ScheduleremoveFailed,
        '',
      );
    }
  }

  async presentAlertConfirmSchedule(data: VppControlGroupSchedule): Promise<void> {
    let header = '',
      subHeader = '',
      message = '';
    header = this.trans.str.vpp.RemoveUser.Header;
    subHeader = this.trans.str.vpp.RemoveUser.Subheader;
    message =
      this.trans.str.vpp.RemoveControlGroupSchedule +
      ': <strong>' +
      data.controlGroup.name +
      ' - ' +
      data.schedule.name +
      '</strong>';
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: [
        {
          text: this.trans.instant('General.No'),
        },
        {
          text: this.trans.instant('General.Yes'),
          handler: () => {
            this.removeSchedule(data);
          },
        },
      ],
    });
    await alert.present();
  }

  async openVppControlGroupScheduleCreateModalForEdit(element: VppControlGroupSchedule): Promise<void> {
    const prepopulatedFormData = VppControlGroupSchedulesComponent.adaptVppGroupScheduleToFormData(element);
    this.controlGroupService.controlGroups$.pipe(take(1)).subscribe(async (controlGroups) => {
      const adaptedControlGroups = controlGroups.map((controlGroup) =>
        adaptVppControlGroupToCGSControlGroup(controlGroup),
      );

      const modal = await this.modalController.create({
        component: VppCreateControlGroupScheduleModalPage,
        backdropDismiss: false,
        componentProps: {
          vppComplexSchedule: this.vppService.vppSchedules.schedules,
          controlGroups: adaptedControlGroups,
          policies: this.vppService.policiesList.policies,
          DIMerits: this.vppService.demandIncrease.origCopy,
          DRMerits: this.vppService.demandReduction.origCopy,
          newControlGroupSchedule: prepopulatedFormData,
          editType: CrudType.UPDATE,
        },
        showBackdrop: true,
      });
      await modal.present();
    });
  }

  openVppCreateControlGroupModal(): void {
    this.controlGroupService.controlGroups$.pipe(take(1)).subscribe(async (controlGroups) => {
      const adaptedControlGroups = controlGroups.map((controlGroup) =>
        adaptVppControlGroupToCGSControlGroup(controlGroup),
      );
      const createModal = await this.modalController.create({
        component: VppCreateControlGroupScheduleModalPage,
        backdropDismiss: false,
        componentProps: {
          vppComplexSchedule: this.vppService.vppSchedules.schedules,
          controlGroups: adaptedControlGroups,
          policies: this.vppService.policiesList.policies,
          DIMerits: this.vppService.demandIncrease.origCopy,
          DRMerits: this.vppService.demandReduction.origCopy,
          editType: CrudType.CREATE,
        },
        showBackdrop: true,
      });

      await createModal.present();
    });
  }

  private static adaptVppGroupScheduleToFormData(
    groupSchedule: VppControlGroupSchedule,
  ): VppControlGroupScheduleFormData {
    let drmDemandType = null;
    const { auto, controlGroup, id, schedule, mode, meritOrder, controlValue, policies, markets, controlPriority } =
      groupSchedule;
    if (
      isVppModeDrm(groupSchedule.mode) &&
      groupSchedule.meritOrder &&
      isVppDemandTypeDrm(groupSchedule.meritOrder.demand_type)
    ) {
      drmDemandType = groupSchedule.meritOrder.demand_type;
    }

    const selectedMarketUuids = markets.map((market) => market.uuid);

    const formData: VppControlGroupScheduleFormData = {
      scheduleType: auto ? VppDispatchType.AUTO : VppDispatchType.MANUAL,
      selectedPolicyId: policies?.[0]?.id ?? 'none',
      selectedScheduleId: schedule.id,
      selectedControlGroupId: controlGroup.id,
      selectedMerit: meritOrder,
      controlValue: controlValue,
      selectedMarketUuids,
      drmDemandType,
      auto,
      id,
      mode,
      markets,
      controlPriority,
    };

    return formData;
  }

  async presentAlertSimpleOk(header: string, subheader: string, message: string): Promise<void> {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async presentToast(message: string, duration = 3000): Promise<void> {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: 'top',
    });
    await toast.present();
  }

  ngOnDestroy(): void {
    if (this.serviceSubscription) this.serviceSubscription.unsubscribe();
  }
}
