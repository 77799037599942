import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalIdentifier } from '@class/commons/constants';
import { ColBreakpoint } from '@class/commons/enums';
import { BrowserLogger } from '@class/core/browser-logger';
import { ModalController } from '@ionic/angular';
import { CommonService } from '@service/common/common.service';
import { EmitEvent, Event, EventBusService } from '@service/core/event-bus.service';
import { Subscription } from 'rxjs';

export enum ModalAction {
  CREATE = 1,
  EDIT = 2,
  VIEW = 3,
}

class ModalContainerViewModel {
  showBackButton: boolean;
  showCancelButton: boolean;
  showCreateButton: boolean;
  showRemoveButton: boolean;
  showSaveButton: boolean;
  showHeader: boolean;
  ngClassGrid?: string;
  ngClassRow?: string;
  ngClassCard?: string;
}

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
})
export class ModalContainerComponent implements OnInit, OnDestroy {
  @Output() onBack = new EventEmitter();
  @Output() onRemove = new EventEmitter();
  @Output() onCreate = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Input() id: ModalIdentifier = ModalIdentifier.UNKNOWN;
  @Input() action: ModalAction = ModalAction.VIEW;
  @Input() title: string = '';
  @Input() headerTitle: string = '';
  @Input() isSaveDisabled: boolean = false;
  @Input() enableRemoveButton: boolean = true; // set to false to always hide the remove button, otherwise its visibility will be calculated internally
  @Input() autoCloseOnBack: boolean = true;
  @Input() autoCloseOnCancel: boolean = true;
  @Input() autoCloseOnCreate: boolean = false;
  @Input() autoCloseOnSave: boolean = false;
  @Input() autoCloseOnRemove: boolean = false;
  @Input() showFooter: boolean = true;

  vm: ModalContainerViewModel = {
    showBackButton: false,
    showCancelButton: false,
    showCreateButton: false,
    showRemoveButton: false,
    showSaveButton: false,
    showHeader: false,
  };

  private _resizeSubs: Subscription = null;

  constructor(
    private _modalController: ModalController,
    private _commonService: CommonService,
    private _eventBus: EventBusService,
  ) {
    this._resizeSubs = this._commonService.PLATFORM_RESIZED_OCCURRED.subscribe((resizeOccurred: boolean) => {
      BrowserLogger.log('ModalContainer.PLATFORM_RESIZED_OCCURRED', { resizeOccurred, action: this.action });
      if (resizeOccurred) {
        this.setModalConfig();
      }
    });
  }

  ngOnInit(): void {
    this.setModalConfig();
  }

  ngOnDestroy(): void {
    if (this._resizeSubs) {
      this._resizeSubs.unsubscribe();
    }
  }

  handleToolbarCancelClick(): void {
    BrowserLogger.log('ModalContainer.handleToolbarCancelClick');
    this.onCancel.emit();
    if (this.autoCloseOnCancel) {
      this.dismissModal();
    }
  }

  handleToolbarBackClick(): void {
    BrowserLogger.log('ModalContainer.handleToolbarBackClick');
    this.onBack.emit();
    if (this.autoCloseOnBack) {
      this.dismissModal();
    }
  }

  handleRemoveClick(): void {
    BrowserLogger.log('ModalContainer.handleRemoveClick');
    this.onRemove.emit();
    if (this.autoCloseOnRemove) {
      this.dismissModal();
    }
  }

  handleCancelClick(): void {
    BrowserLogger.log('ModalContainer.handleCancelClick');
    this.onCancel.emit();
    if (this.autoCloseOnCancel) {
      this.dismissModal();
    }
  }

  handleCreateClick(): void {
    BrowserLogger.log('ModalContainer.handleCreateClick');
    this.onCreate.emit();
    if (this.autoCloseOnCreate) {
      this.dismissModal();
    }
  }

  handleSaveClick(): void {
    BrowserLogger.log('ModalContainer.handleSaveClick');
    this.onSave.emit();
    if (this.autoCloseOnSave) {
      this.dismissModal();
    }
  }

  private setModalConfig(): void {
    BrowserLogger.log('ModalContainer.setModalConfig', { action: this.action, vm: this.vm });
    if (this.action) {
      switch (this.action) {
        case ModalAction.CREATE:
          this.vm.showBackButton = this._commonService.PLATFORM_WIDTH < ColBreakpoint.MD;
          this.vm.showHeader = this._commonService.PLATFORM_WIDTH < ColBreakpoint.MD;
          this.vm.showCancelButton = true;
          this.vm.showRemoveButton = false;
          this.vm.showCreateButton = true;
          this.vm.showSaveButton = false;
          break;
        case ModalAction.EDIT:
          this.vm.showBackButton = this._commonService.PLATFORM_WIDTH < ColBreakpoint.MD;
          this.vm.showHeader = this._commonService.PLATFORM_WIDTH < ColBreakpoint.MD;
          this.vm.showCancelButton = true;
          this.vm.showRemoveButton = true && this.enableRemoveButton;
          this.vm.showCreateButton = false;
          this.vm.showSaveButton = true;
          break;
        case ModalAction.VIEW:
          this.vm.showBackButton = true;
          this.vm.showHeader = true;
          this.vm.showCancelButton = false;
          this.vm.showRemoveButton = false;
          this.vm.showCreateButton = false;
          this.vm.showSaveButton = false;
          break;
      }
      this.vm.ngClassGrid =
        this.action === ModalAction.VIEW || (this.action === ModalAction.CREATE && this.vm.showHeader)
          ? 'background-color'
          : undefined;
      this.vm.ngClassRow = this.action !== ModalAction.VIEW ? 'fullHeight' : undefined;
      this.vm.ngClassCard = this.action === ModalAction.VIEW ? 'transparent' : undefined;
    }
  }

  private dismissModal(): void {
    BrowserLogger.log('ModalContainer.dismissModal');
    this._modalController.dismiss();
    this._eventBus.emit(new EmitEvent(Event.MODAL_CLOSED, this.id));
  }
}
