<ion-button
  (click)="handleClick()"
  color="button-primary"
  expand="block"
  fill="outline"
  class="cancel-button"
  mode="ios"
  [disabled]="isDisabled"
>
  <span *ngIf="!label" class="fw-600 fs-16 ion-text-capitalize"> {{ 'General.Cancel' | translate }} </span>
  <span *ngIf="label" class="fw-600 fs-16 ion-text-capitalize"> {{ label }} </span>
</ion-button>
