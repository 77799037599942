<ion-button
  (click)="handleClick()"
  expand="block"
  color="warning-lite"
  class="pause-button"
  mode="ios"
  [disabled]="isDisabled"
>
  <ion-icon slot="start" class="icon-left" src="assets/icons/svg/pause.svg"></ion-icon>
  <span class="fs-14 button-text ion-text-capitalize fw-400"> {{ 'Alerts.PauseAlert' | translate }} </span>
</ion-button>
