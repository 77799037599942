import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CheckboxCustomEvent, DatetimeCustomEvent } from '@ionic/angular';
import { UnitDeviceEventsStore } from '@service/units/unit-device-events.store';
import { DateTime } from 'luxon';
import { Subject, combineLatestWith, map, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-unit-device-events',
  templateUrl: './unit-device-events.component.html',
  styleUrls: ['./unit-device-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitDeviceEventsComponent {
  destroy$ = new Subject<void>();

  startDate$ = this._unitDeviceEventsStore.select((state) => state.startDate);
  endDate$ = this._unitDeviceEventsStore.select((state) => state.endDate);
  filterByDate$ = this._unitDeviceEventsStore.select((state) => state.filterByDate);

  vm$ = this._unitDeviceEventsStore
    .select((state) => ({
      unitDeviceEvents: state.data,
      previousPageUrl: state.data?.data?.previous,
      nextPageUrl: state.data?.data?.next,
      filterByDate: state.filterByDate,
      startDate: state.startDate,
      endDate: state.endDate,
      minDate: state.minDate,
      maxDate: state.maxDate,
    }))
    .pipe(
      map(({ startDate, endDate, minDate, maxDate, ...vm }) => ({
        ...vm,
        startDate: this.formatDateForIonic(startDate),
        endDate: this.formatDateForIonic(endDate),
        minDate: this.formatDateForIonic(minDate),
        maxDate: this.formatDateForIonic(maxDate),
      })),
    );

  constructor(private _unitDeviceEventsStore: UnitDeviceEventsStore) {
    this.startDate$
      .pipe(
        combineLatestWith(this.endDate$, this.filterByDate$),
        tap(() => this._unitDeviceEventsStore.getUnitDeviceEventsByUrl()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackItems(_: number, item: { id: string }) {
    return item.id;
  }

  isArray<T = unknown>(value: unknown): value is Array<T> {
    return value instanceof Array;
  }

  pageChange(url?: string) {
    if (url == null) return;

    this._unitDeviceEventsStore.getUnitDeviceEventsByUrl({ url });
  }

  handleFilterByDateChange(event: CheckboxCustomEvent) {
    this._unitDeviceEventsStore.setFilterByDate(event.target.checked);
  }

  handleStartDateChange(event: DatetimeCustomEvent) {
    this._unitDeviceEventsStore.setStartDate(this.parseDateFromIonic(event.target.value as string));
  }

  handleEndDateChange(event: DatetimeCustomEvent) {
    this._unitDeviceEventsStore.setEndDate(this.parseDateFromIonic(event.target.value as string));
  }

  private formatDateForIonic(date: DateTime): string {
    return date.toISO();
  }

  private parseDateFromIonic(date: string): DateTime {
    return DateTime.fromISO(date);
  }
}
