<ion-grid
  class="ion-no-padding ion-no-margin"
  *ngIf="!(vm$ | async).loading && !(vm$ | async).error && (vm$ | async).data as vm"
>
  <ion-row>
    <ion-col class="ion-no-padding" size="12">
      <ng-container *ngFor="let metric of vm.metrics">
        <ion-card *ngIf="metric.values" class="m-0 mb-16 new-design-card autoHeight overflow-x-scroll">
          <div class="fw-600 fs-22 mt-25 ml-24 text-dark">{{ metric.title }}</div>
          <div *ngIf="metric.description" class="mt-15 ml-20">{{ metric.description }}</div>
          <app-unit-costs-subcost-barchart [metrics]="metric"></app-unit-costs-subcost-barchart>
        </ion-card>
      </ng-container>
    </ion-col>
  </ion-row>
</ion-grid>
