<app-modal-container
  [id]="ModalIdentifier.CONTROLLER_ADD"
  [action]="vm.modal.action"
  [title]="vm.modal.title"
  [isSaveDisabled]="!vm.isFormValid"
  [enableRemoveButton]="false"
  (onCreate)="handleModalContainerCreate()"
  (onSave)="handleModalContainerSave()"
>
  <ion-row>
    <ion-col class="ion-text-start ion-no-padding">
      <ng-container *ngIf="!controllerTypeList?.length">
        <ion-item lines="none">
          <ion-label class="text-dark fs-14">
            {{ 'UnitPage.NoControllerTypeAvailable' | translate }}
          </ion-label>
        </ion-item>
      </ng-container>
      <ng-container *ngIf="controllerTypeList?.length">
        <ion-row>
          <ion-col class="ion-text-start ion-no-padding">
            <!-- todo - update to reactive form -->
            <form #controllerForm="ngForm">
              <!-- Droplet Info -->
              <ion-row class="bb-1">
                <ion-col class="pt-10 pb-10" size="12">
                  <ion-item lines="none">
                    <ion-text class="fs-14 text-dark">
                      {{ 'General.Droplet' | translate }}
                    </ion-text>
                    <ion-text class="fs-16 text-dark" slot="end">
                      {{ droplet.dropletDisplay.overview.uuid }}
                    </ion-text>
                  </ion-item>
                </ion-col>
              </ion-row>
              <!-- Controller Type (Disabled in Edit mode)-->
              <ion-row class="bb-1">
                <ion-col class="pt-10 pb-10" size="12">
                  <!-- note: the INPUT_STATUS_INVALID is required to force set the internal INVALID property when input is invalid (a required field)-->
                  <app-input-select-stacked-label
                    label="{{ 'UnitPage.ControllerType' | translate }}"
                    placeholder="{{ 'UnitPage.ControllerType' | translate }}"
                    [(ngModel)]="vm.selectedController.type"
                    (ngModelChange)="handleControllerTypeChange(vm.selectedController.type)"
                    [isDisable]="vm.selectedController.isTypeSelectionDisabled"
                    required
                    name="controllerType"
                    ngControl="controllerType"
                    #controllerType="ngModel"
                    [interfaceOptions]="customAlertOptionsControllerType"
                    [options]="controllerTypeList"
                    [errors]="controllerType.errors"
                    [status]="controllerType.invalid ? INPUT_STATUS_INVALID : ''"
                  ></app-input-select-stacked-label>
                </ion-col>
              </ion-row>
              <!-- Name -->
              <ion-row class="bb-1">
                <ion-col class="pt-10 pb-10" size="12">
                  <!-- note: the INPUT_STATUS_INVALID is required to force set the internal INVALID property when input is invalid (a required field)-->
                  <app-input-stacked-label
                    label="{{ 'General.Name' | translate }}"
                    required
                    name="name"
                    [(ngModel)]="vm.controllerName"
                    (ngModelChange)="handleControllerNameChange($event)"
                    ngControl="name"
                    #name="ngModel"
                    [enableClearIcon]="true"
                    [errors]="name.errors"
                    [status]="name.invalid ? INPUT_STATUS_INVALID : ''"
                  ></app-input-stacked-label>
                </ion-col>
              </ion-row>
              <!-- controller parameters -->
              <ion-row
                class="bb-1"
                *ngIf="vm.selectedController.type && vm.selectedController.type?.controller_type_parameters?.length > 0"
              >
                <ion-col size="12">
                  <ion-item lines="none">
                    <ion-label class="fs-14 text-dark">
                      {{ 'UnitPage.ControllerParameters' | translate }}
                    </ion-label>
                  </ion-item>
                  <div
                    *ngFor="let param of vm.selectedController.params; let lastParam = last"
                    [ngClass]="{ 'bb-1': !lastParam }"
                  >
                    <app-device-control
                      [uuid]="droplet.dropletDisplay.overview.uuid"
                      [deviceControl]="param"
                      [controllerParamOptions]="{ isReadonlyOnly: false, isCreate: vm.isCreate }"
                      (manualControlToggled)="handleManualControlToggled($event)"
                      (controllerParamDeviceControlValueChanged)="
                        handleControllerParamDeviceControlValueChanged($event)
                      "
                    ></app-device-control>
                  </div>
                </ion-col>
              </ion-row>
              <!-- Devices -->
              <ion-row class="bb-1" *ngIf="vm.selectedController.type">
                <ion-col size="12">
                  <ion-item lines="none">
                    <ion-label class="fs-14 text-dark">
                      {{ 'General.Devices' | translate }}<ion-text color="danger">*</ion-text>
                    </ion-label>
                  </ion-item>

                  <ng-container *ngIf="droplet.devices.length && droplet.isControllerCompatible">
                    <!-- info: max devices on controller  -->
                    <ion-item lines="none" class="alert alert-item-info pt-0 pb-0">
                      <ion-icon src="assets/icons/svg/info.svg" slot="start"></ion-icon>
                      <ion-text class="fs-14 text-dark">
                        {{ 'UnitPage.MaximumNumberOfDevices' | translate }}
                        {{ vm.selectedController.type.controller_device_limit }}
                      </ion-text>
                    </ion-item>
                    <ng-container *ngFor="let device of droplet.devices">
                      <ion-item
                        lines="none"
                        *ngIf="device.isControllerCompatible"
                        [disabled]="vm.isMaxSelectedDevicesReached && !device.isSelected"
                      >
                        <!-- TODO: Need to style this according to design -->
                        <ion-checkbox
                          color="button-secondary"
                          slot="start"
                          [ngModel]="device.isSelected"
                          name="device-{{ device.deviceDetails.deviceOverview.id }}"
                          (ngModelChange)="handleDeviceSelection(device)"
                          labelPlacement="end"
                          justify="start"
                        >
                          {{ device.deviceDetails.deviceOverview.fullName }}
                        </ion-checkbox>
                      </ion-item>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="!droplet.devices.length">
                    <ion-item lines="none" class="alert alert-item-warning pt-0 pb-0">
                      <ion-icon src="assets/icons/svg/warning.svg" slot="start"></ion-icon>
                      <ion-text class="fs-14 text-dark">
                        {{ 'UnitPage.NoDevicesAvailable' | translate }}.&nbsp;{{
                          'UnitPage.DevicesAreRequiredInOrderToCreateController' | translate
                        }}
                      </ion-text>
                    </ion-item>
                  </ng-container>
                  <ng-container *ngIf="droplet.devices.length && !droplet.isControllerCompatible">
                    <ion-item lines="none" class="alert alert-item-warning pt-0 pb-0">
                      <ion-icon src="assets/icons/svg/warning.svg" slot="start"></ion-icon>
                      <ion-text class="fs-14 text-dark">
                        {{ 'UnitPage.NoSupportedDevicesAvailable' | translate }}.&nbsp;{{
                          'UnitPage.DevicesAreRequiredInOrderToCreateController' | translate
                        }}
                      </ion-text>
                    </ion-item>
                  </ng-container>
                </ion-col>
              </ion-row>
            </form>
          </ion-col>
        </ion-row>
      </ng-container>
    </ion-col>
  </ion-row>
</app-modal-container>
