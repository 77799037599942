<ion-card class="card-design full-height" [color]="cardColor">
  <ion-card-header (click)="navigateToUnit()">
    <ion-item lines="none" class="pl-0 item-inner-padding-end-0 bkg-transparent">
      <app-status-circle [isCard]="true" [status]="onlineStatus"></app-status-circle>
      <ion-chip *ngIf="operationalStatusText && enableUnitStatusPill" slot="end" [outline]="true" color="light">
        {{ operationalStatusText | translate }}
      </ion-chip>
    </ion-item>
    <ion-card-title [ngClass]="['fs-22 fw-500 mt-0', unitNameAddressAdditionalClass]">{{ name }}</ion-card-title>
    <ion-card-subtitle [ngClass]="['text-sm', unitNameAddressAdditionalClass]">{{ address }}</ion-card-subtitle>
  </ion-card-header>
</ion-card>
