import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';
import { IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { apiStatus } from '../../../classes/commons/common';
import {
  DropletFirmwareUpdateMqtt,
  FirmwareUpdateMqttStatuses,
  FirmwareUpdateMqttVerboseStatuses,
  FirmwareUpdateMqttDropletTypeDTO,
} from '../../../classes/units/dropletFirmware/droplet-firmware-mqtt.model';
import { TranslationsService } from '../../../services/common/translations.service';
import { UnitsService } from '../../../services/units/units.service';

@Component({
  selector: 'app-droplet-firmware-upgrade',
  templateUrl: './droplet-firmware-upgrade.page.html',
  styleUrls: ['./droplet-firmware-upgrade.page.scss'],
})
export class DropletFirmwareUpgradePage implements OnInit, OnDestroy {
  endpointUuid: string;
  mqttSubscription: Subscription;
  firmwareMqttMsg: DropletFirmwareUpdateMqtt;
  msgTimeout: NodeJS.Timeout;
  upgradeRequestToServerStatus = new apiStatus();
  dropletUpdated = false;
  timeoutOccurred = false;

  public readonly FirmwareUpdateMqttStatuses = FirmwareUpdateMqttStatuses;
  public readonly FirmwareUpdateMqttVerboseStatuses = FirmwareUpdateMqttVerboseStatuses;

  private readonly MSG_TIMEOUT_MS = 30000;

  constructor(
    private _ngxMqttWrapper: NgxMqttWrapperService,
    private unitService: UnitsService,
    private viewCtrl: ModalController,
    private trans: TranslationsService,
  ) {}

  ngOnInit(): void {
    this.updateDropletFirmware(this.endpointUuid);
  }

  ngOnDestroy(): void {
    this.reset();
  }

  initialized(): void {
    const initData = {
      status: FirmwareUpdateMqttStatuses.INITIALIZED,
      verbose_status: FirmwareUpdateMqttVerboseStatuses.START,
      progress: 0,
    };
    this.firmwareMqttMsg = FirmwareUpdateMqttDropletTypeDTO.adaptToDropletFirmwareUpdateMqtt(initData);
  }

  reset(): void {
    this.clearMqttSubscription();
    this.clearMsgTimeout();
    this.upgradeRequestToServerStatus.reset();
    this.timeoutOccurred = false;
  }

  clearMsgTimeout(): void {
    if (this.msgTimeout) {
      clearTimeout(this.msgTimeout);
    }
  }

  clearMqttSubscription(): void {
    this._ngxMqttWrapper.unsubscribe(this.mqttSubscription);
  }

  public dismiss(): void {
    this.viewCtrl.dismiss(this.dropletUpdated);
  }

  async updateDropletFirmware(endpointUuid: string): Promise<void> {
    this.initialized();
    this.reset();

    // if don't get any msg over the mqtt for droplet update
    // it'll time out and will display msg on the frontend
    this.msgTimeout = setTimeout(() => {
      this.timeoutOccurred = true;
      this.clearMqttSubscription();
    }, this.MSG_TIMEOUT_MS);

    try {
      // subscribing to the subscription before making the request to the server
      // if for some reasons there are delay in milliseconds from the api
      // and we miss the first msg from the droplet
      // just to avoid that, subscribing to the mqtt here
      this.mqttSubscription = this._ngxMqttWrapper
        .observe('droplet_updates/' + endpointUuid)
        .subscribe((message: IMqttMessage) => {
          const msg: FirmwareUpdateMqttDropletTypeDTO = JSON.parse(message.payload.toString());

          // if got one msg that means the update has been triggered
          // so clear the timeout
          //
          // TODO: have not got into this use case as it's very rare
          // got a msg from the droplet and for some reasons, the droplet is stuck
          // unable to send the msg - means update can not happen
          // so for that, constantly have to create timeout and clear it
          this.clearMsgTimeout();
          this.timeoutOccurred = false;

          this.firmwareMqttMsg = FirmwareUpdateMqttDropletTypeDTO.adaptToDropletFirmwareUpdateMqtt(msg);

          if (this.firmwareMqttMsg.status === FirmwareUpdateMqttStatuses.SUCCESS) {
            this.dropletUpdated = true;
          }
        });

      try {
        await this.unitService.upgradeEndpoint(endpointUuid);
      } catch (error) {
        this.upgradeRequestToServerStatus.$callFailed = true;
        this.upgradeRequestToServerStatus.$error = error;

        if (error.hasOwnProperty('status')) {
          const { CouldNotCompleteUpdateRequest } = this.trans.instant('DropletFirmware');

          if (error.status != 500) {
            this.upgradeRequestToServerStatus.$errorText = `${CouldNotCompleteUpdateRequest} ${error.status} ${error.statusText}. ${error.msg}.`;
          } else {
            this.upgradeRequestToServerStatus.$errorText = `${CouldNotCompleteUpdateRequest} ${error.status} ${error.statusText}.`;
          }
        } else {
          this.upgradeRequestToServerStatus.$errorText = JSON.stringify(error);
        }

        this.clearMsgTimeout();
        this.clearMqttSubscription();
        this.timeoutOccurred = false;
        throw new Error(error);
      } finally {
        this.upgradeRequestToServerStatus.$callMade = true;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
}
