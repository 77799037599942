import { Injectable } from '@angular/core';
import { States } from '@class/commons/constants';
import { AvailableAPI, RequestMethod, UseHeaderType } from '@class/commons/request-api.model';
import { ApiWrapper } from '@service/common/api-wrapper.service';
import {
  Observable,
  Subject,
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  of,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { UnitStore } from './unit.store';
import { HTTP_ERRORS_TRANSLATION_HEADING, generateHttpErrorMessage } from '@class/error/http-error-types';
import { TranslationsService } from '@service/common/translations.service';
import { DateTime } from 'luxon';
import { HttpErrorResponse } from '@angular/common/http';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import qs from 'qs';

/**
 * Sample Api Data
 *
 * Fault History
 * {
    "id": 21154069,
    "extra": {
  "Measured Values": {
      "Internal Temperature": 18.895999908447266,
      "Battery Warning": 0,
      "Terminal Vdc": 48.60000228881836,
      "Battery Fault": 0,
      "Permissive": 18,
      "Battery Alarm": 0,
      "DRM Status Bits": 0,
      "Battery Vdc": 48.70000076293945,
      "Vdc": 48.78697967529297,
      "Battery CFET Code": 3,
      "Active Fault Code": 0,
      "Battery DFET Code": 3,
      "VacGrid Line to Line": 243.06007385253906
  },
  "Fault History": [
      {
    "record": 1,
    "identifier": 1651023388,
    "details": {
        "timestamp": 1651023388,
        "fault_class": "Grid Connected Type",
        "fault_code": {
      "number": 361,
      "name": "Grid Frequency LFRTD  : Offset 1"
        },
        "fault_measurements": {
      "Grid Frequency": 36.32999801635742,
      "Vdc": 48.500701904296875,
      "GFI": 38,
      "Iac Internal": -0.05279812216758728,
      "Grid Vac": 142.3345184326172
        }
    }
      },
      {
    "record": 2,
    "identifier": 1649370041,
    "details": {
        "timestamp": 1649370041,
        "fault_class": "Offgrid Type",
        "fault_code": {
      "number": 1015,
      "name": "Short Circuit Detected"
        },
        "fault_measurements": {
      "Inverter Demand": 1198,
      "Vdc": 48.78697967529297,
      "GFI": 38,
      "Idc": -23.700000762939453,
      "Battery SOC": 0.30000001192092896
        }
    }
      },
      {
    "record": 3,
    "identifier": 1649369876,
    "details": {
        "timestamp": 1649369876,
        "fault_class": "Offgrid Type",
        "fault_code": {
      "number": 1018,
      "name": "Iac Hi - Battery 170%"
        },
        "fault_measurements": {
      "Inverter Demand": 1301,
      "Vdc": 47.594146728515625,
      "GFI": 37,
      "Idc": 87.0999984741211,
      "Battery SOC": 0.30000001192092896
        }
    }
      },
      {
    "record": 4,
    "identifier": 1649369876,
    "details": {
        "timestamp": 1649369876,
        "fault_class": "Offgrid Type",
        "fault_code": {
      "number": 1018,
      "name": "Iac Hi - Battery 170%"
        },
        "fault_measurements": {
      "Inverter Demand": 1302,
      "Vdc": 47.23629379272461,
      "GFI": 38,
      "Idc": 124.0999984741211,
      "Battery SOC": 0.30000001192092896
        }
    }
      },
      {
    "record": 5,
    "identifier": 1649369876,
    "details": {
        "timestamp": 1649369876,
        "fault_class": "Offgrid Type",
        "fault_code": {
      "number": 1018,
      "name": "Iac Hi - Battery 170%"
        },
        "fault_measurements": {
      "Inverter Demand": 1301,
      "Vdc": 47.23629379272461,
      "GFI": 37,
      "Idc": 124.30000305175781,
      "Battery SOC": 0.30000001192092896
        }
    }
      }
  ]
    },
    "time_created": "2022-09-27T11:46:05.984722+10:00",
    "droplet_timestamp": "2022-09-27T11:46:04.167465+10:00",
    "access_level": 70,
    "active": "INACTIVE",
    "importance": "INFO",
    "model": "Eguana",
    "reason": "Button",
    "serial": "ET0000030",
    "system_state": "SleepStandby",
    "user_intervention": "None",
    "uuid": "25b49cce-3e06-11ed-ad1e-b827ebae1ae8",
    "endpoint": 1024
  }

  Grid System
  {
    "id": 20606983,
    "extra": {
      "Battery": {
  "Number of Battery Modules": 2,
  "Ah Capacity": 10,
  "Active kW Rating - Discharge": 5,
  "Max Reserve SOC": 98,
  "Min Reserve SOC": 6,
  "Active kW Rating - Charge": 5,
  "Offgrid Maximum Reserve Percent": 100,
  "Vdc Nominal": 50,
  "Offgrid Minimum Reserve Percent": 0,
  "Battery Type": 5,
  "kWh Rating": 10
      },
      "PCS": {
  "Inverter/DSP Serial Number": 30,
  "Inverter/DSP Firmware Version": 6.220606,
  "Inverter/DSP Manufacturer Code": 17748,
  "Comlink Firmware Version": 4.220617,
  "Comlink Serial Number": 34
      },
      "System": {
  "Baudrate": 115200,
  "Protocol": "Modbus RTU",
  "Serial Number": "ET0000030",
  "Version": "6.220606",
  "Model": "Eguana",
  "Options": "Single Phase",
  "Manufacturer": "Eguana Tech"
      },
      "Grid Connection": {
  "Pre-connect Frequency Minimum": 47.6,
  "Power Factor Default Target": 1,
  "Pre-connect Vac Maximum": 253,
  "Pre-connect Vac Minimum": 205,
  "Grid Validity Timer": 60,
  "Pre-connect Frequency Maximum": 50.15
      }
    },
    "time_created": "2022-09-20T22:37:21.204639+10:00",
    "droplet_timestamp": "2022-09-20T22:37:09.833302+10:00",
    "access_level": 1,
    "active": "RESOLVED",
    "importance": "INFO",
    "model": "Eguana",
    "reason": "Comms Established",
    "serial": "ET0000030",
    "system_state": "NotConnected",
    "user_intervention": "None",
    "uuid": "f1c3df4e-38e0-11ed-ad1e-b827ebae1ae8",
    "endpoint": 1024
  }
 */

interface DeviceEvents {
  id: number;
  extra?: {
    'Measured Values'?: { [index: string]: number | string };
    System?: { [index: string]: number | string };
    Battery?: { [index: string]: number | string };
    PCS?: { [index: string]: number | string };
    'Grid Connection'?: { [index: string]: number | string };
    'Fault History'?: [
      {
        record: number;
        identifier: number;
        details: {
          timestamp: number;
          fault_class: string;
          fault_code: {
            number: number;
            name: string;
          };
          fault_measurements: {
            [index: string]: number | string;
          };
        };
      },
    ];
  };
  time_created: string;
  droplet_timestamp: string;
  access_level: number;
  active: string;
  importance: string;
  model: string;
  reason: string;
  serial: string;
  system_state: string;
  user_intervention: string;
  uuid: string;
  endpoint: number;

  // Custom
  // Added for frontend needs
  time_created_from_now: string;
}

interface UnitDeviceEvents {
  next: string | null;
  previous: string | null;
  results: DeviceEvents[];
}

export interface UnitDeviceEventsStateData {
  data: UnitDeviceEvents;
  status: States;
  error?: string;
  message?: string;
}

export interface UnitDeviceEventsState {
  data: UnitDeviceEventsStateData;
  filterByDate: boolean;
  startDate: DateTime;
  endDate: DateTime;
  maxDate: DateTime;
  minDate: DateTime;
}

const UNIT_DEVICE_EVENTS_INITIAL_STATE: UnitDeviceEventsStateData = {
  status: States.INITIAL_LOADING,
  data: null,
};

interface GetUnitDeviceEventsByIdParams {
  unitUuid: string;
  url?: string;
  startDate?: string;
  endDate?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UnitDeviceEventsStore extends ImmerComponentStore<UnitDeviceEventsState> {
  constructor(
    private _api: ApiWrapper,
    private _unitStore: UnitStore,
    private _translationsService: TranslationsService,
  ) {
    const endDate = DateTime.local().startOf('day');
    const startDate = endDate.minus({ days: 1 });

    super({
      startDate,
      endDate,
      minDate: endDate.minus({ months: 24 }),
      maxDate: endDate,
      filterByDate: false,
      data: UNIT_DEVICE_EVENTS_INITIAL_STATE,
    });
  }

  readonly events$ = this.select((state) => state.data);

  private readonly requestParams$ = this.select((state) => ({
    startDate: state.filterByDate ? state.startDate : undefined,
    endDate: state.filterByDate ? state.endDate : undefined,
  })).pipe(
    withLatestFrom(
      this._unitStore.unit$.pipe(distinctUntilKeyChanged('data', (prev, curr) => prev.uuid === curr.uuid)),
    ),
    filter(([, unitData]) => unitData.data != null),
    map(([filters, unitData]) => ({
      ...filters,
      unitUuid: unitData.data.uuid,
    })),
  );

  private updateEvents = this.updater<UnitDeviceEventsStateData>((state, data) => {
    state.data = data;
  });

  readonly setFilterByDate = this.updater<boolean>((state, value) => {
    state.filterByDate = value;
  });

  readonly setStartDate = this.updater<DateTime>((state, startDate) => {
    state.startDate = startDate;
  });

  readonly setEndDate = this.updater<DateTime>((state, endDate) => {
    state.endDate = endDate;
  });

  private readonly startingRequest$ = new Subject<void>();

  getUnitDeviceEventsByUrl({ url }: Pick<GetUnitDeviceEventsByIdParams, 'url'> = {}): void {
    this.startingRequest$.next();

    this.requestParams$
      .pipe(
        take(1),
        switchMap((params) =>
          this.getUnitDeviceEventsById({
            unitUuid: params.unitUuid,
            startDate: params.startDate?.toFormat('yyyy-MM-dd'),
            endDate: params.endDate?.toFormat('yyyy-MM-dd'),
            url,
          }),
        ),
        takeUntil(this.startingRequest$),
        map((deviceEvents) => {
          const { next, previous, results } = deviceEvents;

          const deviceEventsResult = results.map((event) => {
            const timeCreated = DateTime.fromISO(event.time_created); // new Date(event.time_created);
            const timeCreatedFromNow = timeCreated.toRelative();
            return {
              ...event,
              time_created_from_now: timeCreatedFromNow,
            };
          });

          return {
            data: { next, previous, results: deviceEventsResult },
            status: States.INITIAL_DATA_RECEIVED,
          };
        }),
        catchError((error) => this.handleError(error)),
        startWith(UNIT_DEVICE_EVENTS_INITIAL_STATE),
        tap((deviceEventsData) => {
          this.updateEvents(deviceEventsData);
        }),
      )
      .subscribe();
  }

  private getUnitDeviceEventsById({
    unitUuid,
    url,
    startDate,
    endDate,
  }: GetUnitDeviceEventsByIdParams): Observable<UnitDeviceEvents> {
    const query = qs.stringify({
      unit: unitUuid,
      start_date: startDate,
      end_date: endDate,
    });

    return this._api.handleObservableRequest({
      useAPI: AvailableAPI.SWITCHDIN,
      url: url ?? `/api/v1/events/?${query}`,
      requestMethod: RequestMethod.GET,
      useHeader: UseHeaderType.AUTHORIZED_SWDIN,
      requestData: {
        start_date: startDate,
        end_date: endDate,
      },
      disableURIEncoding: true,
    });
  }

  private handleError(
    err: HttpErrorResponse,
    state: States = States.INITIAL_ERROR,
  ): Observable<UnitDeviceEventsStateData> {
    const message = generateHttpErrorMessage(err, this._translationsService.instant(HTTP_ERRORS_TRANSLATION_HEADING));

    return of({
      status: state,
      error: message.message,
      data: null,
    });
  }
}
