import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalIdentifier } from '@class/commons/constants';
import { INPUT_STATUS_INVALID } from '@class/commons/inputs';
import { BrowserLogger } from '@class/core/browser-logger';
import { DeviceDetails } from '@class/units/droplet/device-details.model';
import { DropletControllerParameter } from '@class/units/droplet/droplet-controller-parameter.model';
import { DropletController, DropletControllerAddUpdateEvent } from '@class/units/droplet/droplet-controller.model';
import { DropletDisplay } from '@class/units/droplet/droplet-display.model';
import {
  EndpointControllerParameterDTO,
  EndpointControllerTypeDTO,
} from '@class/units/endpoint/endpoint-payload.model';
import { EmitEvent, Event, EventBusService } from '@service/core/event-bus.service';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { TranslationsService } from '../../services/common/translations.service';
import { ModalAction } from '../modal/modal-container/modal-container.component';

// We need to wrap the DeviceDetails so we can locally capture track selections/compatibility
interface ControllerCompatibleDevice {
  readonly deviceDetails: DeviceDetails;
  isSelected: boolean;
  isControllerCompatible: boolean;
}
interface ControllerCompatibleDroplet {
  readonly dropletDisplay: DropletDisplay;
  isControllerCompatible: boolean;
  devices: ControllerCompatibleDevice[];
}

type AddNewControllerViewModel = {
  modal: {
    action: ModalAction;
    title: string;
  };
  selectedController: {
    isTypeSelectionDisabled: boolean;
    type: EndpointControllerTypeDTO | null;
    params: DropletControllerParameter[]; // for <app-device-control>
  };
  controllerName: string;
  isMaxSelectedDevicesReached: boolean;
  isCreate: boolean;
  isFormValid: boolean;
};

@Component({
  selector: 'app-add-new-controller',
  templateUrl: './add-new-controller.component.html',
  styleUrls: ['./add-new-controller.component.scss'],
})
export class AddNewControllerComponent implements OnInit, OnDestroy {
  // Data Inputs
  @Input() controllerTypeList: EndpointControllerTypeDTO[];
  // Droplet (parent Droplet for both new and editing)
  droplet: ControllerCompatibleDroplet;
  @Input() set endpoint(value: DropletDisplay) {
    this.initDroplet(value);
  }
  // editing existing controller
  private _editController: DropletController;
  @Input() set controller(value: DropletController) {
    BrowserLogger.log('AddNewController.controller.set', { value });
    this.initEditController(value);
  }

  // View Model (defaults)
  vm: AddNewControllerViewModel = {
    modal: {
      action: ModalAction.CREATE,
      title: this._translationService.instant('UnitPage.Controller'),
    },
    selectedController: {
      isTypeSelectionDisabled: false,
      type: null,
      params: [],
    },
    controllerName: '',
    isMaxSelectedDevicesReached: false,
    isCreate: true,
    isFormValid: false,
  };

  readonly INPUT_STATUS_INVALID = INPUT_STATUS_INVALID;
  readonly ModalIdentifier = ModalIdentifier;

  customAlertOptionsControllerType = {
    header: this._translationService.instant('UnitPage.ControllerType'),
    cssClass: 'ionSelectAlertLabelTextWrap',
  };

  constructor(
    private _translationService: TranslationsService,
    private _eventBus: EventBusService,
  ) {
    BrowserLogger.log('AddNewController.constructor');
    this.setModalForAdd();
  }

  ngOnInit() {
    BrowserLogger.log('AddNewController.ngOnInit');
  }

  ngOnDestroy() {
    BrowserLogger.log('AddNewController.ngOnDestroy');
  }

  private initEditController(controller: DropletController): void {
    BrowserLogger.log('AddNewController.initEditController', {
      controller,
      controllerTypeList: this.controllerTypeList,
    });
    this._editController = cloneDeep(controller);
    if (this._editController) {
      this.setModalForEdit();
      this.vm.selectedController.isTypeSelectionDisabled = true;
      this.vm.controllerName = this._editController.name;
      this.selectControllerTypeById(this._editController.controllerTypeId);
      this.checkSelectedControllerCompatibility();
      this.selectExistingParams();
      this.selectExistingDevices();
    } else {
      this.vm.selectedController.isTypeSelectionDisabled = false;
      this.vm.controllerName = '';
      this.vm.selectedController.type = null;
    }
    this.checkIsFormValid();
  }

  private initDroplet(dropletDisplay: DropletDisplay): void {
    this.droplet = {
      dropletDisplay: dropletDisplay,
      isControllerCompatible: false,
      devices: dropletDisplay.devices.map((deviceDetails) => {
        return {
          deviceDetails: deviceDetails,
          isSelected: false,
          isControllerCompatible: false,
        } as ControllerCompatibleDevice;
      }),
    };
    this.selectExistingDevices();
    BrowserLogger.log('AddNewController.initDroplet', { dropletDisplay, droplet: this.droplet });
  }

  private checkSelectedControllerCompatibility(): void {
    BrowserLogger.log('AddNewController.checkSelectedControllerCompatibility', {
      selectedControllerType: this.vm.selectedController.type,
      controllerTypeList: this.controllerTypeList,
    });
    this.clearDropletCompatibility(this.droplet);
    if (this.vm.selectedController.type) {
      this.setDropletControllerCompatibility(this.droplet, this.vm.selectedController.type);
    }
  }

  private selectExistingParams(): void {
    if (this._editController && this.droplet) {
      // adapt the controller parameters based on the controller type and apply any existing values
      // this is because the dbase only saves previously configured parameters
      // but when editing we need to support all parameters on the controller type (not just existing ones)
      this.vm.selectedController.params = EndpointControllerParameterDTO.adaptToDropletControllerParameters(
        this.vm.selectedController.type?.controller_type_parameters,
        this._translationService,
        this._editController?.parameters,
      );
      BrowserLogger.log('AddNewController.selectExistingParams', {
        selectedControllerType: this.vm.selectedController.type,
        controller: this._editController,
        droplet: this.droplet,
        vm: this.vm,
      });
    }
  }

  private selectExistingDevices(): void {
    if (this._editController && this.droplet) {
      BrowserLogger.log('AddNewController.selectExistingDevices', {
        controller: this._editController,
        droplet: this.droplet,
      });
      this.droplet.devices.forEach((device) => {
        device.isSelected = this._editController.deviceIds.includes(device.deviceDetails.deviceOverview.id);
      });
    }
  }

  private setModalForAdd(): void {
    BrowserLogger.log('AddNewController.setModalForEdit');
    this.vm.modal.action = ModalAction.CREATE;
    this.vm.modal.title = this._translationService.instant('UnitPage.AddController');
    this.vm.isCreate = true;
  }

  private setModalForEdit(): void {
    BrowserLogger.log('AddNewController.setModalForEdit');
    this.vm.modal.action = ModalAction.EDIT;
    this.vm.modal.title = this._translationService.instant('UnitPage.EditController');
    this.vm.isCreate = false;
  }

  handleControllerTypeChange(controllerType: EndpointControllerTypeDTO): void {
    BrowserLogger.log('AddNewController.handleControllerTypeChange', { controllerType });
    if (controllerType) {
      this.selectControllerType(controllerType);
      this.setDefaultControllerName(controllerType);
      this.checkMaximumNumberOfDeviceSelectionReached();
    }
    this.checkIsFormValid();
  }

  handleControllerNameChange(event: any): void {
    BrowserLogger.log('AddNewController.handleControllerTypeChange', { event });
    this.checkIsFormValid();
  }

  private setDefaultControllerName(controllerType: EndpointControllerTypeDTO): void {
    this.vm.controllerName = controllerType?.name || '';
    BrowserLogger.log('AddNewController.setDefaultControllerName', {
      controllerType,
      name: this.vm.controllerName,
    });
  }

  private selectControllerTypeById(controllerTypeId: string): void {
    const controllerType = this.controllerTypeList.find((controllerType) => {
      return controllerType.id === controllerTypeId;
    });
    BrowserLogger.log('AddNewController.selectControllerTypeById', {
      controllerTypeId,
      controllerType,
      controllerTypeList: this.controllerTypeList,
    });
    this.selectControllerType(controllerType);
  }

  private selectControllerType(controllerTypePayload: EndpointControllerTypeDTO): void {
    BrowserLogger.log('AddNewController.selectControllerType', {
      controllerTypePayload,
      _editController: this._editController,
    });
    this.vm.selectedController.type = controllerTypePayload;
    this.resetControllerToggles();
    // reset devices/params when changing controller type
    // however only reset when not editing (as editing cannot change controller type, but it will get set when loading)
    const doReset = this._editController ? false : true;
    this.setDropletControllerCompatibility(this.droplet, this.vm.selectedController.type, doReset);
    if (doReset) {
      // map selected params to DropletControllerParameter for use by <app-device-control>
      this.vm.selectedController.params = EndpointControllerParameterDTO.adaptToDropletControllerParameters(
        controllerTypePayload?.controller_type_parameters,
        this._translationService,
      );
    }
    BrowserLogger.log('AddNewController.selectControllerType.done', {
      droplet: this.droplet,
      selectedController: this.vm.selectedController,
      doReset,
    });
    this.checkIsFormValid();
  }

  private resetControllerToggles(): void {
    if (this.vm.selectedController.type) {
      this.vm.selectedController.type.toggle = true;
      this.controllerTypeList.forEach((controllerType) => {
        if (controllerType.id !== this.vm.selectedController.type.id) {
          controllerType.toggle = false;
        }
      });
    }
    BrowserLogger.log('AddNewController.resetControllerToggles', {
      controllerType: this.vm.selectedController.type,
      controllerTypeList: this.controllerTypeList,
    });
  }

  private clearDropletCompatibility(droplet: ControllerCompatibleDroplet): void {
    BrowserLogger.log('AddNewController.clearDropletCompatibility', { droplet });
    droplet.isControllerCompatible = false;
    droplet.devices.forEach((device) => (device.isControllerCompatible = false));
  }

  private setDeviceIsSelected(device: ControllerCompatibleDevice, reset: boolean = false): void {
    BrowserLogger.log('AddNewController.setDeviceIsSelected', { device, reset });
    if (reset) {
      device.isSelected = false;
    } else {
      device.isSelected = DropletController.containsDeviceId(
        this._editController,
        device.deviceDetails.deviceOverview.id,
      );
    }
  }

  private setDropletControllerCompatibility(
    droplet: ControllerCompatibleDroplet,
    controllerType: EndpointControllerTypeDTO,
    resetDeviceChecked: boolean = false,
  ): void {
    droplet.isControllerCompatible = false;
    if (controllerType) {
      droplet.devices.forEach((device) => {
        // check/set the isSelected property
        this.setDeviceIsSelected(device, resetDeviceChecked);
        // default to not compatible
        device.isControllerCompatible = false;
        // ensure we have a device id (some auto added devices wont)
        if (device.deviceDetails.deviceOverview.id) {
          // ensure we have a valid legacy device type
          if (
            device.deviceDetails.deviceOverview.deviceTypeId &&
            DropletController.hasValidLegacyDeviceTypeId(controllerType, device.deviceDetails)
          ) {
            droplet.isControllerCompatible = true;
            device.isControllerCompatible = true;
          }
          // ensure we have a valid iec61850 device type
          else if (
            device.deviceDetails.deviceOverview.iec61850DeviceTypeId &&
            DropletController.hasValidIec61850DeviceTypeId(controllerType, device.deviceDetails)
          ) {
            droplet.isControllerCompatible = true;
            device.isControllerCompatible = true;
          }
        }
      });
    }
    BrowserLogger.log('AddNewController.setDropletControllerCompatibility', {
      droplet,
      controllerType,
    });
  }

  handleModalContainerCreate() {
    const event: DropletControllerAddUpdateEvent = {
      payload: {
        controller_info: {
          name: this.vm.controllerName,
          controller_type_id: this.vm.selectedController.type.id,
          device_ids: this.getSelectedDeviceIds(this.droplet.devices),
          parameters: DropletControllerParameter.getParamsForAddUpdate(this.vm.selectedController.params),
        },
        endpoint_info: {
          endpoint_id: this.droplet.dropletDisplay.overview.id,
          uuid: this.droplet.dropletDisplay.overview.uuid,
        },
      },
      droplet: this.droplet.dropletDisplay,
    };
    BrowserLogger.log('AddNewController.handleModalContainerCreate', { event });
    this._eventBus.emit(new EmitEvent(Event.CONTROLLER_ADD, event));
  }

  handleModalContainerSave() {
    const event: DropletControllerAddUpdateEvent = {
      payload: {
        id: this._editController.id,
        name: this.vm.controllerName,
        device_ids: this.getSelectedDeviceIds(this.droplet.devices),
        parameters: DropletControllerParameter.getParamsForAddUpdate(this.vm.selectedController.params),
        uuid: this.droplet.dropletDisplay.overview.uuid,
      },
      droplet: this.droplet.dropletDisplay,
    };
    BrowserLogger.log('AddNewController.handleModalContainerSave', { event });
    this._eventBus.emit(new EmitEvent(Event.CONTROLLER_UPDATE, event));
  }

  handleManualControlToggled(value: any): void {
    BrowserLogger.log('AddNewController.handleManualControlToggled', { value });
    this.checkIsFormValid();
  }

  handleControllerParamDeviceControlValueChanged(value: any): void {
    BrowserLogger.log('AddNewController.handleControllerParamDeviceControlValueChanged', { value });
    this.checkIsFormValid();
  }

  private getSelectedDeviceIds(devices: ControllerCompatibleDevice[]): string[] {
    const result = devices
      .filter((device) => {
        return device.isSelected && device.deviceDetails.deviceOverview.id;
      })
      .map((device) => {
        return device.deviceDetails.deviceOverview.id;
      });
    BrowserLogger.log('AddNewController.getSelectedDeviceIds', { result, devices });
    return result;
  }

  handleDeviceSelection(device: ControllerCompatibleDevice): void {
    BrowserLogger.log('AddNewController.handleDeviceSelection', { device });
    this.selectDevice(device);
    this.checkMaximumNumberOfDeviceSelectionReached();
    this.checkIsFormValid();
  }

  private selectDevice(device: ControllerCompatibleDevice): void {
    BrowserLogger.log('AddNewController.selectDevice', { device });
    device.isSelected = !device.isSelected;
  }

  private checkMaximumNumberOfDeviceSelectionReached() {
    this.vm.isMaxSelectedDevicesReached = false;
    if (this.vm.selectedController?.type?.controller_device_limit) {
      const selectedDevices = this.droplet.devices.filter((device) => device.isSelected);
      this.vm.isMaxSelectedDevicesReached =
        selectedDevices.length >= this.vm.selectedController?.type.controller_device_limit;
      BrowserLogger.log('AddNewController.checkMaximumNumberOfDeviceSelectionReached', {
        isMaxSelectedDevicesReached: this.vm.isMaxSelectedDevicesReached,
        droplet: this.droplet,
        selectedDevices,
        controllerType: this.vm.selectedController.type,
      });
    }
  }

  private checkDeviceSelections(): boolean {
    // check we have a device selected
    let result = this.droplet?.devices?.find((device) => device.isSelected) ? true : false;
    BrowserLogger.log('AddNewController.checkDeviceSelections', {
      result,
      devices: this.droplet.devices,
    });
    return result;
  }

  private checkParameterValues(): boolean {
    let result = true;
    // check we have any invalid controller param values (if controller type has parameters)
    if (this.vm.selectedController.params.length) {
      this.vm.selectedController.params.forEach((param) => {
        // if supports manual control and not enabled then no need to validate
        // otherwise check if it has a valid value
        const supportsManualControl = DropletControllerParameter.supportsManualControl(param);
        const requiresValidation = !supportsManualControl || (supportsManualControl && param.isManualControlRequested);
        if (requiresValidation && !param.isValueValid) {
          result = false;
        }
      });
    }
    BrowserLogger.log('AddNewController.checkParameterValues', {
      result,
      controllerParams: this.vm.selectedController?.params,
    });
    return result;
  }

  private checkIsFormValid(): boolean {
    this.vm.isFormValid =
      this.vm.selectedController.type !== null &&
      this.vm.controllerName.length > 0 &&
      this.checkDeviceSelections() &&
      this.checkParameterValues();
    BrowserLogger.log('AddNewController.checkIsFormValid', { result: this.vm.isFormValid, vm: this.vm });
    return this.vm.isFormValid;
  }
}
