import { Component, ElementRef, ViewChild, HostListener, OnDestroy } from '@angular/core';
import { TranslationsService } from '../../services/common/translations.service';
import { default as _rollupMoment } from 'moment-timezone';
import Plotly from 'plotly.js-dist';
import { VppDemandTypes } from '../../classes/vpp/vpp-demand-type-mode.model';
import { VppModes } from '../../classes/vpp/vpp-demand-type-mode.model';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import {
  VppAnalyticsFacadeService,
  VppAnalyticsViewModelMetaData,
} from '../../services/virtualPowerPlants/facades/analytics/vpp-analytics-facade.service';
import { TimePeriodResolution } from '@class/commons/constants-datetime';
import { VppModelStatus } from '@service/virtualPowerPlants/facades/operation-tab/vpp-operation-schedule-modal-facade.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-vpp-operation-history',
  templateUrl: './vpp-operation-history.component.html',
  styleUrls: ['./vpp-operation-history.component.scss'],
})
export class VppOperationHistoryComponent implements OnDestroy {
  ionMaxDate: string;
  ionMinDate: string;
  currentSelectedDate: string;
  readonly TimePeriodResolution = TimePeriodResolution;
  showLoader = true;
  statusText: string;
  readonly VppDemandTypes = VppDemandTypes;
  readonly VppModes = VppModes;
  readonly PermissionKey = PermissionKey;
  demandType: VppModes | VppDemandTypes;
  disableNext = true;
  disablePrevious = false;
  readonly ionDatePickerPresentation = 'date';
  private readonly plotLayout = {
    autosize: true,
    bargap: 0,
    showlegend: true,
    barmode: 'stack',
    width: 1254,
    height: 600,
    margin: {
      l: 50,
      r: 30,
      t: 20,
      b: 100,
      pad: 5,
    },
    xaxis: {
      showgrid: false,
      zeroline: false,
      showticklabels: true,
      zerolinecolor: '#666666',
      zerolinewidth: 2,
      autorange: true,
      rangeslider: { bgcolor: '#F6F6F6' },
    },
    yaxis: {
      fixedrange: false,
      showgrid: true,
      zeroline: false,
      showticklabels: true,
    },
    hovermode: 'x',
    annotations: [
      // timezone annotation
      {
        text: '',
        font: {
          size: 12,
          color: 'rgba(0,0,0,1)',
        },
        height: 16,
        x: 0.5,
        xref: 'paper',
        xanchor: 'left',
        yanchor: 'bottom',
        yref: 'paper',
        y: -0.12,
        yshift: 0,
        showarrow: false,
      },
    ],
    legend: {
      xanchor: 'center',
      yanchor: 'top',
      y: -0.5,
      x: 0.5,
      orientation: 'h',
    },
  };
  private graphDrawn = false;
  private subscription: Subscription;

  @ViewChild('historyChart', { static: false }) historyChart: ElementRef;

  constructor(
    private trans: TranslationsService,
    private facade: VppAnalyticsFacadeService,
  ) {
    this.subscription = facade.vm$.subscribe((vm) => {
      if (isEmpty(vm)) return;
      const { data, message, status } = vm;
      switch (status) {
        case VppModelStatus.LOADING:
          this.showLoader = true;
          this.statusText = message;
          break;
        case VppModelStatus.COMPLETE:
          this.setMetaData(data?.metaData);
          if (isEmpty(data?.chartObjects)) {
            this.statusText = this.trans.instant('VirtualPowerPlant.Analytics.NoData');
            this.showLoader = false;
            return;
          }
          this.statusText = null;
          Plotly.react(this.historyChart.nativeElement, data.chartObjects, this.plotLayout, {
            displayModeBar: false,
          });
          this.graphDrawn = true;
          this.showLoader = false;
          break;
        case VppModelStatus.ERROR:
          this.showLoader = false;
          this.statusText = message;
          break;
        default:
          return;
      }
    });
  }

  private setMetaData(metaData: VppAnalyticsViewModelMetaData): void {
    if (isEmpty(metaData)) return;
    const {
      datePickerMax,
      datePickerMin,
      startTimeEpoch,
      nextPeriodEnable,
      previousPeriodEnable,
      selectedDemandTypeMode,
      timezone,
    } = metaData;
    this.demandType = selectedDemandTypeMode;
    this.ionMaxDate = datePickerMax;
    this.ionMinDate = datePickerMin.toISOString();
    this.currentSelectedDate = DateTime.fromSeconds(startTimeEpoch, { zone: timezone }).toISO();
    this.disableNext = !nextPeriodEnable;
    this.disablePrevious = !previousPeriodEnable;
    this.plotLayout.annotations[0].text = `${this.trans.instant('VirtualPowerPlant.Timezone')}: ${timezone}`;
  }

  @HostListener('window:resize', ['$event'])
  handleResize(event): void {
    this.resize();
  }
  private resize(): void {
    if (!this.graphDrawn) return;
    try {
      const element = this.historyChart.nativeElement;
      if (element.clientWidth <= 0) {
        this.plotLayout['width'] = window.innerWidth * 0.76;
      } else {
        this.plotLayout['width'] = element.clientWidth;
        this.plotLayout['height'] = element.clientHeight;
      }
      Plotly.relayout(element, this.plotLayout);
    } catch (error) {
      console.error(error);
      this.statusText = error;
    }
  }

  dateChangedCalendar(dateString: string): void {
    this.facade.startDateChange(dateString);
  }

  onDemandTypeModeChange(): void {
    this.facade.setDemandTypeMode(this.demandType);
  }

  onPrevious(): void {
    this.facade.previousDate();
  }

  onNext(): void {
    this.facade.nextDate();
  }

  refresh(): void {
    this.facade.refreshChartData();
  }

  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
