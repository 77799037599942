<ion-row class="vertical-composition-bar">
  <ion-col size="12" size-lg="5" class="ion-padding-start">
    <div class="fs-16 text-dark">
      {{ chartData.mainHeading | translate }}
    </div>
    <div class="fs-28 text-light main-heading-value">
      {{ chartData.mainValue }}
      <span class="main-heading-unit">kWh</span>
    </div>
    <br class="ion-hide-md-down" />
    <span class="fw-300 fs-13 text-dark">
      {{ chartData.subHeading | translate }}
    </span>
    <br class="ion-hide-md-down" />
    <span class="text-dark sub-heading-value">
      {{ chartData.subValue }}
      <span> kWh </span>
    </span>
    <div class="pt-10 ion-hide-sm-up"></div>
  </ion-col>
  <ion-col size="12" size-lg="7">
    <ion-row>
      <ion-col offset-lg="2" size="2">
        <div class="side-bar" [style.height]="sidebarHeight">
          <div
            *ngFor="let param of chartParamsArray"
            [style.height]="verticalBarHeights[param.legend]"
            [style.background]="param.color"
          ></div>
        </div>
      </ion-col>
      <ion-col>
        <ion-row *ngFor="let param of chartParamsArray">
          <!--Left grid-->
          <ion-col class="ion-text-center" size="2">
            <div class="dots" [style.background]="param.color"></div>
          </ion-col>
          <ion-col>
            <div class="value">
              {{ param.value }}
              <div class="legend">
                {{ param.legend | translate }}
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
