import { TranslationsService } from './../../services/common/translations.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UnitPowerFlowLiveStream, UnitPowerFlowStreamSvgStore } from '@service/units/unit-power-flow-stream-svg.store';

@Component({
  selector: 'app-unit-overview-svg',
  templateUrl: './unit-overview-svg.component.html',
  styleUrls: ['./unit-overview-svg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitOverviewSvgComponent {
  readonly homeUsageLabel = this.translationsService.instant('Svg.HomeUsage');
  readonly consumedTodayLabel = this.translationsService.instant('Svg.ConsumedToday');
  readonly storedTodayLabel = this.translationsService.instant('Svg.StoredToday');
  readonly generatedTodayLabel = this.translationsService.instant('Svg.GeneratedToday');
  readonly gridLabel = this.translationsService.instant('Svg.Grid');
  readonly generatorLabel = this.translationsService.instant('Svg.Generator');
  readonly generatingLabel = this.translationsService.instant('Svg.Generating');
  readonly storageLabel = this.translationsService.instant('Svg.Storage');
  readonly savingLabel = this.translationsService.instant('Svg.Saving');
  readonly importingLabel = this.translationsService.instant('Svg.Importing');
  readonly solarLabel = this.translationsService.instant('Svg.Solar');
  readonly exportingLabel = this.translationsService.instant('Svg.Exporting');
  readonly idleLabel = this.translationsService.instant('Svg.Idle');

  readonly homeIcon = 'assets/icons/svg/consumption-icon.svg';
  readonly solarIcon = 'assets/icons/svg/solar.svg';
  readonly gridIcon = 'assets/icons/svg/grid.svg';
  readonly generatorIcon = 'assets/icons/svg/generator.svg';

  unitPowerFlowStreamStore$: Observable<UnitPowerFlowLiveStream>;

  constructor(
    private translationsService: TranslationsService,
    private _unitPowerFlowLiveStream: UnitPowerFlowStreamSvgStore,
  ) {
    this.unitPowerFlowStreamStore$ = this._unitPowerFlowLiveStream.unitPowerFlowStreamStore$;
  }
}
