import { Injectable } from '@angular/core';
import { BrowserLogger } from '@class/core/browser-logger';
import { EmitEvent, Event, EventBusService } from '@service/core/event-bus.service';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';
import { IClientPublishOptions } from 'mqtt';
import { IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { ControllerMqttMessageType, DropletController } from '../droplet-controller.model';
import { DropletDisplay } from '../droplet-display.model';
import {
  DropletControllerInventoryRequest,
  DROPLET_CONTROLLER_MQTT_REQUEST_TIMEOUT_MS,
} from './droplet-controller-inventory-request.model';

// Store items contain the data to be emitted, mqtt subscription and a fallback timeout
interface DropletControllerInventoryStoreItem {
  data: DropletControllerInventoryRequest;
  subscription?: Subscription;
  fallbackTimeout?: NodeJS.Timeout;
}

// Store is an object with properties for each 'droplet uuid' of type DropletControllerInventoryStoreItem
type DropletControllerInventoryStore = {
  [index: string]: DropletControllerInventoryStoreItem;
};

@Injectable({
  providedIn: 'root',
})
export class DropletControllerInventoryService {
  private _store: DropletControllerInventoryStore = {};

  constructor(
    private _ngxMqttWrapper: NgxMqttWrapperService,
    private _eventBus: EventBusService,
  ) {
    BrowserLogger.log('DropletControllerInventoryService.constructor');
  }

  public requestControllerInventoryForDroplets(droplets: DropletDisplay[]): void {
    // setup a mqtt controller inventory subscription for each given droplet
    BrowserLogger.log('DropletControllerInventoryService.requestControllerInventoryForDroplets', { droplets });
    droplets.forEach((droplet) => {
      this.requestControllerInventory(droplet);
    });
  }

  public closeRequests(): void {
    // close all mqtt controller inventory subscriptions we have
    BrowserLogger.log('DropletControllerInventoryService.closeRequests');
    for (const key in this._store) {
      this._store[key].subscription?.unsubscribe();
      this._store[key].subscription = undefined;
    }
  }

  private pushState(dropletUuid: string): void {
    const state = this._store[dropletUuid].data;
    BrowserLogger.log('DropletControllerInventoryService.pushState', { dropletUuid, state });
    this._eventBus.emit(new EmitEvent(Event.CONTROLLER_INVENTORY_STATE, state));
  }

  public requestControllerInventory(droplet: DropletDisplay): void {
    // Controller Inventory request/messages requires the following:
    // 1. Setup a MQTT Handler for the inventory messages (handler needs to be configured prior to commencing the request)
    // 2. Request MQTT for the Inventory (will kick off the inventory messages)
    // 3. An initial current inventory message will be sent
    // 4. Additional messages will be sent for CRUD changes
    // NOTE: check 'canUseControllerInventory' to ensure no invalid mqtt listeners are setup
    //       and do not rely on a valid droplet being given
    const canUseControllerInventory = DropletDisplay.canUseControllerInventory(droplet);
    if (canUseControllerInventory) {
      BrowserLogger.log('DropletControllerInventoryService.requestControllerInventory', { droplet });
      this.initDropletState(droplet.overview.uuid);
      this.initMqttHandler(droplet);
      this.mqttPublish(droplet.overview.uuid);
    } else {
      BrowserLogger.log('DropletControllerInventoryService.requestControllerInventory: NOT A VALID DROPLET TYPE!', {
        droplet,
      });
    }
  }

  private initDropletState(dropletUuid: string): void {
    // clear any previous subscription/timeout before we clear any references
    this.clearSubscriptions(dropletUuid);
    // setup new state reference for this droplet
    this._store[dropletUuid] = {
      data: {
        dropletUuid: dropletUuid,
        waiting: true,
        received: false,
        outOfSync: false,
        canBeProcessed: true, // can be processed by default
      },
    };
    BrowserLogger.log('DropletControllerInventoryService.initDropletState', { store: this._store, dropletUuid });
    // emit event for the new state
    this.pushState(dropletUuid);
  }

  public disableProcessing(dropletUuid: string): void {
    // update the 'canBeProcessed' state to false
    const stateObj = this._store[dropletUuid].data;
    stateObj.canBeProcessed = false;
    BrowserLogger.log('DropletControllerInventoryService.disableProcessing', { stateObj, dropletUuid });
  }

  public enableProcessing(droplet: DropletDisplay): void {
    // update the 'canBeProcessed' to true and process any waiting mqtt message
    const dropletUuid = droplet.overview.uuid;
    const stateObj = this._store[dropletUuid].data;
    stateObj.canBeProcessed = true;
    BrowserLogger.log('DropletControllerInventoryService.enableProcessing', {
      stateObj,
      droplet,
    });
    // process any messages if we have some waiting
    this.processControllerInventoryMqttMessage(droplet, stateObj);
  }

  private processControllerInventoryMqttMessage(
    droplet: DropletDisplay,
    stateObj: DropletControllerInventoryRequest,
  ): boolean {
    // if we can be processed and there is an unprocessed message then process it and emit state event as required
    // we need to make sure an inventory msg can be processed before we can process inventory
    let result = false; // was not processed (default)
    if (stateObj.canBeProcessed && stateObj.mqttMsg) {
      BrowserLogger.log('DropletControllerInventoryService.processControllerInventoryMqttMessage', {
        droplet,
        stateObj,
      });
      droplet.processControllerInventoryMqttMessage(stateObj.mqttMsg);
      stateObj.outOfSync = droplet.controllersOutOfSync;
      stateObj.mqttMsg = undefined;
      result = true; // was reprocessed
      this.pushState(droplet.overview.uuid);
    }
    // no mqtt message to process
    else if (stateObj.canBeProcessed && !stateObj.mqttMsg) {
      BrowserLogger.log('DropletControllerInventoryService.processControllerInventoryMqttMessage: No MQTT message.', {
        droplet,
        stateObj,
      });
    }
    // mqtt message but cannot be processed
    else if (!stateObj.canBeProcessed && stateObj.mqttMsg) {
      BrowserLogger.log(
        'DropletControllerInventoryService.processControllerInventoryMqttMessage: Cannot be processed.',
        {
          droplet,
          stateObj,
        },
      );
    }
    return result;
  }

  private initMqttHandler(droplet: DropletDisplay): void {
    // prepare the mqtt listener (needs to be done prior to the mqtt request)
    const dropletUuid = droplet.overview.uuid;
    const url = `config/${dropletUuid}/inventory/controller`;
    BrowserLogger.log('DropletControllerInventoryService.initMqttHandler', { dropletUuid, url });
    this.mqttUnsubscribe(dropletUuid);
    // subscribe to the mqtt service
    this._store[dropletUuid].subscription = this._ngxMqttWrapper.observe(url).subscribe((mqttMsg: IMqttMessage) => {
      BrowserLogger.log('DropletControllerInventoryService.initMqttHandler.subscription');
      this.processMqttMessage(mqttMsg, droplet);
    });
  }

  public mqttUnsubscribe(dropletUuid: string): void {
    // unsubscribe any previous mqtt listener
    BrowserLogger.log('DropletControllerInventoryService.mqttUnsubscribe', { dropletUuid });
    const storeObj = this._store[dropletUuid];
    if (storeObj?.subscription) {
      this._ngxMqttWrapper.unsubscribe(storeObj.subscription);
      storeObj.subscription = null;
    }
  }

  private mqttPublish(dropletUuid: string): void {
    // perform the mqtt publish to kick off its mqtt controller inventory message
    const msg = JSON.stringify({ message_type: ControllerMqttMessageType.INVENTORY });
    const url = `config/${dropletUuid}/controller`;
    const params: IClientPublishOptions = { qos: 0 };
    BrowserLogger.log('DropletControllerInventoryService.mqttPublish', { dropletUuid, url, msg, params });
    this.createMqttMessageNoResponseFallback(dropletUuid);
    this._ngxMqttWrapper.publish(url, msg, params).subscribe({
      complete: () => {
        BrowserLogger.log('DropletControllerInventoryService.mqttPublish.complete');
      },
      error: (error) => {
        BrowserLogger.error('DropletControllerInventoryService.mqttPublish', { error });
      },
    });
  }

  private createMqttMessageNoResponseFallback(dropletUuid: string): void {
    // set a fallback timeout incase we do not receive a response from the inventory request
    this.clearMessageFailureFallback(dropletUuid);
    this._store[dropletUuid].fallbackTimeout = setTimeout(() => {
      BrowserLogger.log('DropletControllerInventoryService.fallback', { dropletUuid });
      const stateData = this._store[dropletUuid].data;
      if (stateData) {
        // update state object
        stateData.waiting = false;
        // emit event
        this.pushState(dropletUuid);
      }
    }, DROPLET_CONTROLLER_MQTT_REQUEST_TIMEOUT_MS);
  }

  private clearMessageFailureFallback(dropletUuid: string): void {
    // set a fallback timeout incase we do not receive a response from the inventory request
    const storeObj = this._store[dropletUuid];
    if (storeObj?.fallbackTimeout) {
      clearTimeout(storeObj.fallbackTimeout);
      storeObj.fallbackTimeout = null;
    }
  }

  private clearSubscriptions(dropletUuid: string): void {
    this.mqttUnsubscribe(dropletUuid);
    this.clearMessageFailureFallback(dropletUuid);
  }

  public processMqttMessage(mqttMessage: IMqttMessage, droplet: DropletDisplay): void {
    const dropletUuid = droplet.overview.uuid;
    const stateObj: DropletControllerInventoryRequest = this._store[dropletUuid].data;
    BrowserLogger.log('DropletControllerInventoryService.processMqttMessage', {
      mqttMessage,
      droplet,
      dropletUuid,
      stateObj,
    });
    // clear any existing failure fallback
    this.clearMessageFailureFallback(dropletUuid);
    // process if not api triggered or was api triggered and api response has been processed
    stateObj.mqttMsg = mqttMessage;
    stateObj.waiting = false;
    stateObj.received = true;
    // process
    this.processControllerInventoryMqttMessage(droplet, stateObj);
  }

  public hasReceivedInventoryForDroplet(dropletUuid: string): boolean {
    const storeObj = this._store[dropletUuid];
    const result = storeObj?.data.received || false;
    BrowserLogger.log('DropletControllerInventoryService.hasReceivedInventoryForDroplet', { result, storeObj });
    return result;
  }

  public getDropletState(dropletUuid: string): DropletControllerInventoryRequest {
    const result = this._store[dropletUuid]?.data;
    BrowserLogger.log('DropletControllerInventoryService.getDropletState', { result, dropletUuid });
    return result;
  }

  public hasDropletInventoryIssue(controller: DropletController, dropletUuid: string): boolean {
    let result = false;
    if (controller && dropletUuid) {
      result =
        this.hasReceivedInventoryForDroplet(dropletUuid) &&
        (!controller.controllerExistOnDroplet ||
          !controller.controllerVersionSync ||
          !controller.controllerExistInDatabase ||
          controller.errorFromDroplet?.length > 0);
    }
    BrowserLogger.log('DropletControllerInventoryService.hasDropletInventoryIssue', {
      result,
      controller,
      dropletUuid,
    });
    return result;
  }
}
