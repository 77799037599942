import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UnitDeviceDTO } from '../../classes/commons/unit.model';
import { UnitsService } from '../../services/units/units.service';

@Pipe({
  name: 'vppGetUnitDevicesGivenUnitId',
})
export class VppGetUnitDevicesGivenUnitIdPipe implements PipeTransform {
  constructor(private unitsService: UnitsService) {}

  transform(unitId: string): Observable<UnitDeviceDTO[]> {
    return this.unitsService?.allUnits$?.pipe(
      filter((units) => units?.length > 0),
      map((units) => units.find((unit) => unit.id === unitId)?.devices ?? []),
    );
  }
}
