<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      ><span *ngIf="!editMode">{{ "Alerts.Add" | translate }}</span
      ><span *ngIf="editMode">{{ "Alerts.Edit" | translate }}</span> {{ "Alerts.AlertDefinition" | translate
      }}</ion-title
    >
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form (ngSubmit)="submit()" #alertDefinitionForm="ngForm">
    <ion-grid>
      <!-- Form Contents -->
      <ion-row class="fh-row ion-text-wrap">
        <!-- Title -->
        <ion-col size="12">
          <ion-item class="md-like-swidin-input ion-margin-bottom">
            <ion-input
              type="text"
              required
              name="name"
              ngControl="name"
              [(ngModel)]="alertDefinition.name"
              label="{{ 'Alerts.AlertTitle' | translate }}"
              labelPlacement="floating"
            ></ion-input>
          </ion-item>
        </ion-col>

        <!-- Category -->
        <ion-col size="12">
          <ion-item class="md-like-swidin-input ion-margin-bottom">
            <ion-select
              name="category"
              required
              interface="popover"
              ngControl="category"
              [(ngModel)]="alertDefinition.category"
              labelPlacement="floating"
            >
              <div slot="label">Category <ion-text color="danger">*</ion-text></div>
              <ion-select-option *ngFor="let category of categories" [value]="category.value"
                >{{ category.translationString | translate }}</ion-select-option
              >
            </ion-select>
          </ion-item>
        </ion-col>

        <!-- Type -->
        <ion-col size="12">
          <ion-item class="md-like-swidin-input ion-margin-bottom">
            <ion-select
              name="criticality"
              required
              interface="popover"
              ngControl="criticality"
              [(ngModel)]="alertDefinition.criticality"
              labelPlacement="floating"
            >
              <div slot="label">{{ "Alerts.AlertType" | translate }} <ion-text color="danger">*</ion-text></div>
              <ion-select-option *ngFor="let alertType of alertTypes" [value]="alertType.name"
                >{{alertType.name}}</ion-select-option
              >
            </ion-select>
          </ion-item>
        </ion-col>

        <!-- Alert Definition Rule -->
        <ion-col size="12">
          <ion-grid>
            <ng-container *ngIf="alertDefinition.category === 'Custom'">
              <p>{{ "Alerts.AlertRule" | translate }}</p>

              <!-- IF Metric -->
              <ion-row>
                <ion-col size="2">
                  <p>{{ "Alerts.If" | translate }}</p>
                </ion-col>
                <ion-col size="10">
                  <ion-item
                    [button]="true"
                    [detail]="false"
                    id="metrics-selection-alert"
                    class="md-like-swidin-input ion-margin-bottom"
                  >
                    <ion-label>{{ "Alerts.Metric" | translate }} <ion-text color="danger">*</ion-text></ion-label>
                    <div class="ml-5" slot="end" id="metrics-selection-alert">{{ alertDefinition.metric?.name }}</div>
                    <ion-icon slot="end" name="caret-down-sharp" class="fs-14"></ion-icon>
                  </ion-item>

                  <ion-modal trigger="metrics-selection-alert" #modal>
                    <ng-template>
                      <app-unit-metric-selection-typehead
                        class="ion-page"
                        [alertMetrics]="metrics"
                        [selectedMetric]="alertDefinition.metric_key"
                        (selectionChangeAlertMetric)="metricSelectionChanged($event, modal)"
                        (selectionCancel)="modal.dismiss()"
                        title="{{'General.SelectAMetric' | translate}}"
                      ></app-unit-metric-selection-typehead>
                    </ng-template>
                  </ion-modal>
                </ion-col>
              </ion-row>

              <!-- IS Expression Value -->
              <ion-row>
                <ion-col size="2">
                  <p>{{ "Alerts.Is" | translate }}</p>
                </ion-col>
                <ion-col size="6">
                  <ion-item class="md-like-swidin-input ion-margin-bottom">
                    <ion-select
                      name="expression"
                      interface="popover"
                      ngControl="expression"
                      required
                      [(ngModel)]="alertDefinition.expression"
                      labelPlacement="floating"
                    >
                      <div slot="label">
                        {{ "Alerts.Expression" | translate }} <ion-text color="danger">*</ion-text>
                      </div>
                      <ion-select-option *ngFor="let expression of alertExpressions" [value]="expression.value"
                        >{{expression.name}}</ion-select-option
                      >
                    </ion-select>
                  </ion-item>
                </ion-col>
                <ion-col size="4">
                  <ion-item class="md-like-swidin-input ion-margin-bottom">
                    <ion-input
                      type="number"
                      required
                      ngControl="value"
                      name="value"
                      [(ngModel)]="alertDefinition.value"
                      label="{{ 'Alerts.Value' | translate }}"
                      labelPlacement="floating"
                    ></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>

              <!-- Value 2 (Optional) -->
              <ion-row *ngIf="alertDefinition.expression === 'BETWEEN'">
                <ion-col size="2">
                  <p>{{ "Alerts.And" | translate }}</p>
                </ion-col>
                <ion-col size="4" *ngIf="alertDefinition.expression === 'BETWEEN'">
                  <ion-item class="md-like-swidin-input ion-margin-bottom">
                    <ion-input
                      type="number"
                      required
                      name="value2"
                      ngControl="value2"
                      [(ngModel)]="alertDefinition.value2"
                      label="{{ 'Alerts.Value' | translate }}"
                      labelPlacement="floating"
                    ></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
            </ng-container>

            <ng-container *ngIf="alertDefinition.category !== 'Custom'">
              <p>{{ "Alerts.RaiseAlertIf" | translate }}</p>
            </ng-container>

            <!-- FOR Period -->
            <ion-row>
              <ion-col size="2">
                <p>{{ "Alerts.For" | translate }}</p>
              </ion-col>
              <ion-col size="4">
                <ion-item class="md-like-swidin-input ion-margin-bottom">
                  <ion-input
                    type="number"
                    required
                    name="period"
                    ngControl="period"
                    [(ngModel)]="alertDefinition.period"
                    label="{{ 'Alerts.Time' | translate }}"
                    labelPlacement="floating"
                  ></ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="6">
                <ion-item class="md-like-swidin-input ion-margin-bottom">
                  <ion-select
                    name="period_unit"
                    interface="popover"
                    required
                    ngControl="period_unit"
                    [(ngModel)]="alertDefinition.period_unit"
                    labelPlacement="floating"
                  >
                    <div slot="label">{{ "Alerts.Period" | translate }} <ion-text color="danger">*</ion-text></div>
                    <ion-select-option *ngFor="let period of alertPeriods" [value]="period.value"
                      >{{period.name}}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>

        <!-- Message -->
        <ion-col size="12">
          <ion-item class="md-like-swidin-input ion-margin-bottom">
            <ion-textarea
              name="remediation"
              required
              ngControl="remediation"
              [(ngModel)]="alertDefinition.remediation"
              label="{{ 'Alerts.Message' | translate }}*"
              labelPlacement="floating"
            >
            </ion-textarea>
          </ion-item>
        </ion-col>

        <!-- Alert Toggle -->
        <ion-col size="12">
          <ion-item class="ion-margin-bottom" lines="none">
            <ion-toggle
              color="button-secondary"
              name="isActive"
              ngControl="isActive"
              [(ngModel)]="alertDefinition.isActive"
              labelPlacement="start"
              justify="space-between"
              mode="ios"
            >
              {{ "Alerts.IsActive" | translate }}
            </ion-toggle>
          </ion-item>
        </ion-col>

        <!-- Recipients -->
        <ion-col size="12">
          <ion-item
            [button]="true"
            [detail]="false"
            id="recipient-selection"
            class="md-like-swidin-input ion-margin-bottom"
          >
            <ion-label>{{ "Alerts.Recipients" | translate }} <ion-text color="danger">*</ion-text></ion-label>
            <div class="ml-5" slot="end" id="recipient-selection">
              {{ alertDefinition.recipients?.length > 0 ? alertDefinition.recipients.length : 0 }} {{
              'UnitPage.Selected' | translate }}
            </div>
            <ion-icon slot="end" name="caret-down-sharp" class="fs-14"></ion-icon>
          </ion-item>

          <ion-modal trigger="recipient-selection" #modal>
            <ng-template>
              <app-unit-alert-recipients-typehead
                class="ion-page"
                [users]="unitUserList$ | async"
                [selectedUsers]="alertDefinition.recipients"
                (selectionChange)="userSelectionChanged($event, modal)"
                (selectionCancel)="modal.dismiss()"
              ></app-unit-alert-recipients-typehead>
            </ng-template>
          </ion-modal>
        </ion-col>
      </ion-row>

      <!-- Submit Button -->
      <ion-row class="ion-align-self-end">
        <ion-col>
          <ion-button
            color="button-primary"
            type="submit"
            size="large"
            expand="block"
            [disabled]="!alertDefinitionForm.valid"
            ><span *ngIf="!editMode">{{ "General.Create" | translate }}</span
            ><span *ngIf="editMode">{{ "General.Update" | translate }}</span></ion-button
          >
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
