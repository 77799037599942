import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, ToastController } from '@ionic/angular';
import { TranslationsService } from '../../../services/common/translations.service';
import {
  MeritControlValueType,
  VppControlGroupSchedule,
  VppControlGroupScheduleMerit,
  VppSchedule,
} from '../../../classes/vpp/vpp-control-group-schedule.model';
import { VppFcasMarket } from '../../../classes/vpp/vpp-fcas-markets.model';
import { isVppModeDrm, isVppModeFcas, VppDemandTypes } from '../../../classes/vpp/vpp-demand-type-mode.model';
import { VppModes } from '../../../classes/vpp/vpp-demand-type-mode.model';
import { VppPolicy } from '../../../classes/vpp/vpp-policy-types.model';
import { VppControlGroupSchedulesService } from '../../../services/virtualPowerPlants/control-group-schedules/vpp-control-group-schedules.service';
import { VppControlGroupService } from '../../../services/virtualPowerPlants/control-group/vpp-control-group.service';
import { VppControlGroupScheduleControlGroup } from '../../../classes/vpp/vpp-control-group.model';
import { CrudType } from '@class/commons/enums';
import { VppDispatchType } from '@class/vpp/vpp-dispatch.model';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
export interface VppControlGroupScheduleFormData {
  auto: boolean;
  id: string;
  mode: VppModes;
  markets: VppFcasMarket[];
  scheduleType: VppDispatchType;
  drmDemandType: VppDemandTypes.DEMAND_INCREASE | VppDemandTypes.DEMAND_REDUCTION;
  selectedScheduleId: string;
  selectedControlGroupId: string;
  selectedPolicyId: string | 'none';
  selectedMerit: VppControlGroupScheduleMerit;
  controlValue: boolean | number | string;
  selectedMarketUuids: string[];
  controlPriority: number | null;
}

@Component({
  selector: 'app-vpp-create-control-group-schedule-modal',
  templateUrl: './vpp-create-control-group-schedule-modal.page.html',
  styleUrls: ['./vpp-create-control-group-schedule-modal.page.scss'],
})
export class VppCreateControlGroupScheduleModalPage implements OnInit {
  //componentProps
  vppComplexSchedule: VppSchedule[];
  controlGroups: VppControlGroupScheduleControlGroup[];
  policies: VppPolicy[];
  DIMerits: VppControlGroupScheduleMerit[];
  DRMerits: VppControlGroupScheduleMerit[];
  editType: CrudType;
  availableMarkets$: Promise<{ data: VppFcasMarket[] }>;

  newControlGroupSchedule: VppControlGroupScheduleFormData = {
    scheduleType: VppDispatchType.MANUAL,
    selectedPolicyId: 'none',
    selectedScheduleId: null,
    selectedControlGroupId: null,
    selectedMerit: null,
    controlValue: null,
    selectedMarketUuids: [],
    drmDemandType: null,
    auto: false,
    id: null,
    mode: null,
    markets: [],
    controlPriority: null,
  };
  readonly CrudType = CrudType;
  readonly VppDispatchType = VppDispatchType;
  readonly VppModes = VppModes;
  readonly VppDemandTypes = VppDemandTypes;
  readonly PermissionKey = PermissionKey;
  readonly MeritControlValueType = MeritControlValueType;

  customAlertOptions: any = {
    cssClass: 'ionSelectAlertLabelTextWrap',
    translucent: true,
  };

  constructor(
    private vppControlGroupSchedulesService: VppControlGroupSchedulesService,
    private viewCtrl: ModalController,
    private trans: TranslationsService,
    private alertController: AlertController,
    private toast: ToastController,
    private loadingController: LoadingController,
    private vppControlGroupService: VppControlGroupService,
  ) {}

  ngOnInit(): void {
    if (this.newControlGroupSchedule) {
      this.grabControlGroupMarkets(
        this.newControlGroupSchedule.selectedControlGroupId,
        this.newControlGroupSchedule.mode,
      );
    }
  }

  async dismiss(data = null): Promise<void> {
    await this.viewCtrl.dismiss(data);
  }

  meritTrackBy(merit: VppControlGroupScheduleMerit): number {
    return merit.order;
  }
  compareWith(meritA: VppControlGroupScheduleMerit, meritB: VppControlGroupScheduleMerit): boolean {
    return meritA && meritB ? meritA.order === meritB.order : meritA === meritB;
  }

  async submitControlGroupScheduleData(formData: VppControlGroupScheduleFormData): Promise<void> {
    const groupSchedule = this.adaptFormData(formData);
    await this.awaitScheduleResponse(groupSchedule, this.editType);
  }

  private adaptFormData(data: VppControlGroupScheduleFormData): VppControlGroupSchedule {
    let isAuto = false;
    let selectedMerit: VppControlGroupScheduleMerit;
    let policies: VppPolicy[];

    if (isVppModeDrm(data.mode)) {
      isAuto = data.scheduleType == VppDispatchType.AUTO;

      if (!isAuto) {
        selectedMerit = data.selectedMerit;
      }
      policies = [];
      if (data.selectedPolicyId !== 'none') {
        policies = this.policies.filter((policy) => policy.id === data.selectedPolicyId);
      }
    }

    const selectedScheduleObjectList = this.vppComplexSchedule.filter(
      (schedule) => schedule.id === data.selectedScheduleId,
    );
    const selectedScheduleObject = selectedScheduleObjectList[0] ?? null;
    if (selectedScheduleObject == null) {
      throw 'Error encountered sending schedule';
    }

    const selectedControlGroupObjectList = this.controlGroups.filter(
      (group) => group.id === data.selectedControlGroupId,
    );
    const selectedControlGroupObject = selectedControlGroupObjectList[0] ?? null;
    if (selectedControlGroupObject == null) {
      throw 'Error encountered sending control group';
    }

    const selectedMarketObjects = data.markets.filter((market) =>
      data.selectedMarketUuids.find((uuid) => uuid === market.uuid),
    );
    if (isVppModeFcas(data.mode) && selectedMarketObjects.length <= 0) {
      throw 'Error, cannot send empty market list if FCAS mode';
    }
    const groupSchedule: VppControlGroupSchedule = {
      auto: isAuto,
      controlGroup: selectedControlGroupObject,
      current: true,
      id: data.id,
      schedule: selectedScheduleObject,
      mode: data.mode,

      policies,
      meritOrder: selectedMerit,
      controlValue: data.controlValue,

      controlPriority: data.controlPriority,

      markets: selectedMarketObjects,
    };
    return groupSchedule;
  }

  private async awaitScheduleResponse(groupSchedule: VppControlGroupSchedule, editType: CrudType): Promise<void> {
    const {
      InviteUser,
      ScheduleUpdateFailed,
      ScheduleCreateFailed,
      ScheduleUpdateSuccess,
      ScheduleCreateSuccess,
      CreatingSchedule,
    } = this.trans.instant('VirtualPowerPlant');
    let loader: HTMLIonLoadingElement;
    try {
      loader = await this.createLoader(CreatingSchedule + ', ' + this.trans.str.general.Wait);
      await loader.present();
      const id = this.newControlGroupSchedule.id;
      await this.vppControlGroupSchedulesService.createOrUpdateVppControlGroupSchedules(id, groupSchedule, editType);
      const toastMessage = editType === CrudType.CREATE ? ScheduleCreateSuccess : ScheduleUpdateSuccess;
      await this.presentToast(toastMessage);
      await loader.dismiss();
      await this.dismiss(true);
    } catch (error) {
      console.error(error);
      if (loader) {
        await loader.dismiss();
      }
      let msg = InviteUser.UserFail.CreateMsg;
      if (error.hasOwnProperty('status')) {
        msg = msg + ' ' + error.status + ' ' + error.statusText + '. ';
      } else {
        msg += JSON.stringify(error);
      }
      const subheader = editType === CrudType.CREATE ? ScheduleCreateFailed : ScheduleUpdateFailed;
      await this.presentAlertSimpleOk(InviteUser.UserFail.Header, subheader, msg);
    }
  }

  marketChange(markets: VppFcasMarket[]): void {
    this.newControlGroupSchedule.markets = markets;
  }

  grabControlGroupMarkets(controlGroupId: string, mode: VppModes): void {
    if (controlGroupId == null || !isVppModeFcas(mode)) {
      console.warn('Attempting to grab markets for non-FCAS control group');
      return;
    }
    this.availableMarkets$ = this.vppControlGroupService.getAvailableFcasMarketsGivenControlGroupId(controlGroupId);
  }

  scheduleTypeChange(type: VppDispatchType): void {
    if (type === VppDispatchType.AUTO) {
      this.newControlGroupSchedule.drmDemandType = null;
    } else {
      this.newControlGroupSchedule.drmDemandType = VppDemandTypes.DEMAND_REDUCTION;
    }
    this.resetMeritOrder();
  }

  resetMeritOrder(): void {
    this.newControlGroupSchedule.selectedMerit = null;
  }

  /**
   * by default, the value will be null.
   * for toggle, even it’s required, it won’t show any error on the form, so make it true.
   * so we have a value true || false in there otherwise it’ll send null there.
   * for number & text, it should be okay as they gonna ask for some input.
   */
  setDefaultValueOfControlValueIfBool(selectedMerit: VppControlGroupScheduleMerit): void {
    if (selectedMerit.control_type === this.MeritControlValueType.BOOLEAN) {
      this.newControlGroupSchedule.controlValue = true;
    }
  }

  createLoader(message: string): Promise<HTMLIonLoadingElement> {
    return this.loadingController.create({
      message: message,
    });
  }

  async presentAlertSimpleOk(header: string, subheader: string, message: string): Promise<void> {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async presentToast(message: string, duration = 3000): Promise<void> {
    const toast = await this.toast.create({
      message: message,
      duration: duration,
      position: 'top',
    });
    await toast.present();
  }
}
