<ion-grid class="pt-0 pl-10 pr-10">
  <ng-container *ngFor="let endpoint of unitsService.unitEndpoints.endpoints; let endpointIndex = index">
    <ng-container *ngFor="let type of ['devices', 'controllers']">
      <div *ngFor="let device of endpoint[type]; let deviceIndex = index">
        <ion-card *ngIf="device.viewInStreamTab" class="new-design-card ion-no-margin mb-10">
          <ion-row class="pl-20 pb-20">
            <ion-col class="header-col p-0" size-lg="3" size="12">
              <ion-text class="card-title mt-20">{{ device.name ? device.name : device.full_name }} </ion-text>
            </ion-col>
            <ion-col class="p-0" size-lg="9" size="12">
              <span
                class="sub-content width-100-pct responsive-align mr-23 conditional-float"
                *ngIf="
                  firstStreamFromNow[endpointIndex][deviceIndex] && currentStreamFromNow[endpointIndex][deviceIndex]
                "
              >
                <span class="sub-content width-100-pct responsive-align conditional-block mr-20">
                  {{ endpoint.type }}: {{ endpoint.uuid }}
                </span>
                {{ 'UnitPage.DisplayDataFrom' | translate }}
                &nbsp;
                <ion-text color="brand-primary">
                  {{ firstStreamFromNow[endpointIndex][deviceIndex] }}
                </ion-text>
                &nbsp;
                {{ 'UnitPage.Till' | translate }}
                &nbsp;
                <ion-text color="brand-primary">
                  {{ currentStreamFromNow[endpointIndex][deviceIndex] }}
                </ion-text>
              </span>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-grid class="p-0 width-100-pct">
              <ion-row>
                <ion-col
                  class="p-0 pt-30 pb-30"
                  size-md="6"
                  size-lg="4"
                  size="12"
                  *ngFor="let metric of device.metricsForStreamTab"
                >
                  <live-metric-graph
                    [metricData]="metric"
                    [valueUpdated]="metric.valueUpdated"
                    [needToResize]="needToResize"
                  ></live-metric-graph>
                  <div class="bottom-line"></div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-row>
        </ion-card>
      </div>
    </ng-container>
  </ng-container>
</ion-grid>
