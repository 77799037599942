<ion-item>
  <ion-icon class="mr-5" [name]="alertIcon"> </ion-icon>
  <ion-label>
    <ion-text class="ion-text-wrap">
      <h1>{{ alertDefinition.name }}</h1>
    </ion-text>
    <ion-text class="ion-text-wrap" color="medium">
      <h3 *ngIf="alertDefinition.metric">{{ alertDefinition.metric.name }}</h3>
      <h3 *ngIf="!alertDefinition.metric">{{ alertCategoryString | translate }}</h3>
    </ion-text>
  </ion-label>

  @if (isUnitActive) {
    <ion-button
      *ifHasPermission="[PermissionKey.UNIT_CHANGE_ALERTDEFINITION]"
      class="ml-3"
      size="small"
      color="warning"
      fill="outline"
      slot="end"
      (click)="edit()"
    >
      <ion-icon slot="icon-only" name="pencil-outline"></ion-icon>
    </ion-button>
    <ion-button
      *ifHasPermission="[PermissionKey.UNIT_DELETE_ALERTDEFINITION]"
      class="ml-3"
      size="small"
      color="danger"
      fill="outline"
      slot="end"
      (click)="delete()"
    >
      <ion-icon slot="icon-only" name="remove"></ion-icon>
    </ion-button>
  }
</ion-item>
