import { Component, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { VppUnitDeviceTypes } from '../../classes/vpp/vpp-unit.model';
import {
  VppStatusFacadeService,
  VppStatusUnitRow,
} from '../../services/virtualPowerPlants/facades/overview/vpp-status-facade.service';
import { Subscription } from 'rxjs';
import { VPP_TABLE_PAGE_SIZE_OPTIONS } from '@class/vpp/vpp-dispatch.model';
import { SkeletonPlaceholder } from '@class/commons/constants';

@Component({
  selector: 'app-vpp-status-units',
  templateUrl: './vpp-status-units.component.html',
  styleUrls: ['./vpp-status-units.component.scss'],
})
export class VppStatusUnitsComponent implements OnDestroy {
  readonly VPP_TABLE_PAGE_SIZE_OPTIONS = VPP_TABLE_PAGE_SIZE_OPTIONS;
  deviceTypes = Object.values(VppUnitDeviceTypes).filter((type) => type !== VppUnitDeviceTypes.HYBRID);
  readonly VppUnitDeviceTypes = VppUnitDeviceTypes;
  readonly SkeletonPlaceholder = SkeletonPlaceholder;
  displayedColumns: string[] = [
    'status',
    'unitName',
    'controlGroupNames',
    'grid',
    this.initialiseDeviceColumnsForRow(1),
    'temp',
  ].flat();
  displayedColumns2: string[] = ['grid_pwr', this.initialiseDeviceColumnsForRow(2)].flat();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.datasource.sort = sort;
  }

  public datasource: MatTableDataSource<VppStatusUnitRow>;
  private sub: Subscription;

  constructor(
    private router: Router,
    public facade: VppStatusFacadeService,
  ) {
    this.sub = this.facade.vm$.subscribe((vm) => {
      if (vm.data) {
        this.datasource = new MatTableDataSource(vm.data);
        this.datasource.paginator = this.paginator;
      }
    });
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.datasource.filter = filterValue;
  }

  navToUnit(unitId: string): Promise<boolean> {
    return this.router.navigateByUrl('/unit/' + unitId);
  }

  private initialiseDeviceColumnsForRow(row: number): string[] {
    return this.deviceTypes.flatMap((type) => {
      const deviceColumns = [`${type}_pwr_${row}`, `${type}_ctrl_${row}`, `${type}_qty_${row}`];
      if (type === VppUnitDeviceTypes.BESS) deviceColumns.unshift(`${type}_soc_${row}`);
      return deviceColumns;
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
