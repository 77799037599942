import { Component } from '@angular/core';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { VirtualPowerPlantsService } from '../../services/virtualPowerPlants/virtual-power-plants.service';

@Component({
  selector: 'app-vpp-operation-nem-prices',
  templateUrl: './vpp-operation-nem-prices.component.html',
  styleUrls: ['./vpp-operation-nem-prices.component.scss'],
})
export class VppOperationNemPricesComponent {
  private readonly pricesMqttKeys = {
    current: 'SWDINPV.SpotPrice.Current',
    interval: 'SWDINPV.SpotPrice.IntervalTS',
    max: 'SWDINPV.SpotPrice.Max',
    min: 'SWDINPV.SpotPrice.Min',
  };
  public prices$: Observable<{
    min: number;
    current: number;
    max: number;
    interval: string;
  }>;

  constructor(
    private _ngxMqttWrapper: NgxMqttWrapperService,
    private vppService: VirtualPowerPlantsService,
  ) {
    this.prices$ = this.createPricesObservable();
  }

  private createPricesObservable(): Observable<{
    min: number;
    current: number;
    max: number;
    interval: string;
  }> {
    return this.vppService.vppSelected$.pipe(
      switchMap(({ uuid }) => this._ngxMqttWrapper.observe(uuid + '/vpp_metrics')),
      map((msg) => {
        if (msg == null) return null;
        const parsedMsg = JSON.parse(msg.payload.toString())?.measurements;
        const prices = {
          min: parsedMsg?.[this.pricesMqttKeys.min] ?? null,
          current: parsedMsg?.[this.pricesMqttKeys.current] ?? null,
          max: parsedMsg?.[this.pricesMqttKeys.max] ?? null,
          interval: this.adaptTimestampSecondsToLocaleTimeString(parsedMsg?.[this.pricesMqttKeys.interval]),
        };
        return prices;
      }),
    );
  }

  private adaptTimestampSecondsToLocaleTimeString(seconds: number): string {
    if (seconds == null) return null;
    const date = new Date(seconds * 1000);
    return date.toLocaleTimeString('en-AU', {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    });
  }
}
