<ng-container *ngrxLet="headerViewModel$ as vm">
  <ion-toolbar [color]="vm.toolbarColor">
    <ion-title class="ion-text-center fw-600 fs-18" color="text-primary">
      {{ vm.headerTitle }}
      <ion-chip
        *ngIf="vm.operationalStatusText && enableUnitStatusPill"
        [outline]="true"
        color="text-primary"
        class="ion-hide-sm-down"
        [ngClass]="{
          'unit-pending-action-status-chip': vm.showActionPendingState,
          'unit-status-chip': !vm.showActionPendingState
        }"
      >
        {{ vm.operationalStatusText | translate }}
      </ion-chip>
      <div class="width-100-pct ion-text-center" *ngIf="vm.operationalStatusText && enableUnitStatusPill">
        <ion-chip
          [outline]="true"
          color="text-primary"
          class="ion-hide-sm-up fw-600"
          [ngClass]="{
            'unit-pending-action-status-chip': vm.showActionPendingState,
            'unit-status-chip': !vm.showActionPendingState
          }"
        >
          {{ vm.operationalStatusText | translate }}
        </ion-chip>
      </div>
    </ion-title>
    <ion-buttons slot="start" *ngIf="vm.showBackButton">
      <ion-back-button
        *ngIf="!vm.showInstallerBackButton"
        class="ml-12"
        [defaultHref]="vm.backDefaultHref"
        [icon]="vm.backButtonIcon"
        color="button-primary"
      ></ion-back-button>
      <ion-button
        (click)="installerBackButtonClickHandler()"
        color="button-primary"
        class="ml-12"
        *ngIf="vm.showInstallerBackButton"
      >
        <ion-icon slot="icon-only" [src]="vm.backButtonIcon"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="start" *ngIf="!vm.showBackButton">
      <ion-menu-toggle>
        <ion-button>
          <ion-icon [src]="vm.sideMenuIcon"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>

    <ion-buttons *ngIf="IS_PLATFORM_DESKTOP" slot="end">
      <ion-button>
        <img [src]="vm.headerLogoPath" [alt]="vm.headerLogoAltName" [ngStyle]="vm.headerLogoStyle" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <app-environment-configs></app-environment-configs>
</ng-container>
