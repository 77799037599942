import { Injectable } from '@angular/core';
import { ApiWrapper } from '../common/api-wrapper.service';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../classes/commons/request-api.model';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Cost } from '../../classes/units/tariff/cost.model';
import { UnitStore } from './unit.store';

export interface CostState {
  unitUuid: string | undefined;
  date: Date;
  data: Cost | undefined;
  loading: boolean;
  error: string | undefined;
}

const defaultStartDate = new Date();
defaultStartDate.setDate(1);
defaultStartDate.setHours(0, 0, 0, 0);

let _state: CostState = {
  unitUuid: undefined,
  date: defaultStartDate,
  data: undefined,
  loading: false,
  error: undefined,
};

@Injectable({
  providedIn: 'root',
})
export class UnitCostService {
  private store = new BehaviorSubject<CostState>(_state);
  private state$ = this.store.asObservable();

  unitUuid$ = this.state$.pipe(
    map((state) => state.unitUuid),
    distinctUntilChanged(),
  );
  date$ = this.state$.pipe(
    map((state) => state.date),
    distinctUntilChanged(),
  );
  data$ = this.state$.pipe(
    map((state) => state.data),
    distinctUntilChanged(),
  );
  loading$ = this.state$.pipe(map((state) => state.loading));
  error$ = this.state$.pipe(map((state) => state.error));

  /**
   * View model that resolves once all the data is ready (or updated)...
   */
  vm$: Observable<CostState> = combineLatest([this.unitUuid$, this.date$, this.data$, this.loading$, this.error$]).pipe(
    map(([unitUuid, date, data, loading, error]) => {
      return { unitUuid, date, data, loading, error };
    }),
  );
  constructor(
    private api: ApiWrapper,
    private _unitStore: UnitStore,
  ) {
    this._unitStore.unit$.pipe(map((unitData) => unitData.data)).subscribe((unit) => {
      this.updateState({
        ..._state,
        data: undefined,
        error: undefined,
        date: new Date(defaultStartDate.getTime()),
        unitUuid: unit?.uuid,
        loading: true,
      });
    });

    //another hack...
    this.date$.subscribe(async (date) => {
      try {
        await this.getAndAdaptTariff(date);
      } catch (err) {
        this.updateState({ ..._state, data: undefined, error: err, loading: false });
      }
    });
  }

  updateCostDate(newDate: Date) {
    this.updateState({ ..._state, date: newDate, error: undefined, loading: true });
  }

  private updateState(state: CostState) {
    this.store.next((_state = state));
  }

  // Allows quick snapshot access to data for ngOnInit() purposes
  getStateSnapshot(): CostState {
    return { ..._state };
  }

  // ------- Private Methods ------------------------

  private async getAndAdaptTariff(date: Date) {
    if (!_state.unitUuid) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res: any = await this.getTariffs(_state.unitUuid, date);
    const processedData = new Cost(res.data);
    if (processedData.hasData) {
      this.updateState({ ..._state, data: processedData, error: undefined, loading: false });
    } else {
      throw new Error('unknown cost structure type');
    }
  }

  private getTariffs(unit_uuid: string, date_start: Date) {
    const endDate = new Date(date_start.getFullYear(), date_start.getMonth() + 1, 0);
    endDate.setHours(23, 59, 59, 100);
    const startDateFormatted = urlFormatter(date_start);
    const endDateFormatted = urlFormatter(endDate);
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/tariffs/tariffcalc/${unit_uuid}/${startDateFormatted}/${endDateFormatted}/`,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
    );
  }
}

export const urlFormatter = (date: Date): string => {
  const y = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const m = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  const d = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const h = date.getHours();
  const i = new Intl.DateTimeFormat('en', { minute: '2-digit' }).format(date);
  const s = new Intl.DateTimeFormat('en', { second: '2-digit' }).format(date);
  const tzOffsetInMinutes = date.getTimezoneOffset();
  const tzOffsetMins = -tzOffsetInMinutes % 60;
  const tzOffsetHours = (-tzOffsetInMinutes - tzOffsetMins) / 60;
  const tzOffsetMinsString = tzOffsetMins < 10 ? `0${tzOffsetMins}` : tzOffsetMins.toString();
  const tzOffsetHoursString = tzOffsetHours < 10 ? `0${tzOffsetHours}` : tzOffsetHours.toString();
  return `${y}-${m}-${d}T${h}:${i}:${s}+${tzOffsetHoursString}:${tzOffsetMinsString}`;
};
