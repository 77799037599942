import { Injectable } from '@angular/core';
import { combineLatest, empty, Observable } from 'rxjs';
import { filter, map, scan, startWith, switchMap } from 'rxjs/operators';
import {
  isVppDemandIncrease,
  isVppDemandReduction,
  VppDemandTypes,
  VppModes,
} from '@class/vpp/vpp-demand-type-mode.model';
import { VppMetricMqtt } from '@class/vpp/vpp-mqtt.model';
import { VirtualPowerPlantsService } from '../../virtual-power-plants.service';
import { VppOverviewFacadeService } from '../overview/vpp-overview-facade.service';
import { isEmpty } from 'lodash';
import { VppThresholdType } from '@class/vpp/vpp.model';
import { Vpp } from '@class/vpp/vpp-types';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';

export type VppDemandTypeModeSelection =
  | VppModes.FCAS
  | VppDemandTypes.DEMAND_INCREASE
  | VppDemandTypes.DEMAND_REDUCTION;

interface MetricMetaData {
  data$: Observable<{ value: number; timestamp: number }>;
  name: string;
  min$?: Observable<number>;
  max$?: Observable<number>;
  total$?: Observable<number>;
}

export interface VppOperationSidebarViewModel {
  demandTypeModes: VppDemandTypeModeSelection;
  activity$: Observable<boolean>;
  metricMetaData: MetricMetaData[];
  isShowNemPrice: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class VppOperationTabSidebarFacadeService {
  private vpp$ = this.vppService.vppSelected$;
  public viewModel$: Observable<VppOperationSidebarViewModel> = combineLatest([
    this.overviewFacade.vppOverviewModeDemandType$,
    this.vpp$,
  ]).pipe(map(([{ combined }, vpp]) => this.formViewModel(combined as VppDemandTypeModeSelection, vpp)));

  constructor(
    private vppService: VirtualPowerPlantsService,
    private overviewFacade: VppOverviewFacadeService,
    private _ngxMqttWrapper: NgxMqttWrapperService,
  ) {}

  private formViewModel(selectedTypeMode: VppDemandTypeModeSelection, vpp: Vpp): VppOperationSidebarViewModel {
    if (selectedTypeMode == null || isEmpty(vpp)) return null;

    const viewModel: VppOperationSidebarViewModel = {
      demandTypeModes: selectedTypeMode,
      isShowNemPrice:
        vpp.thresholdType === VppThresholdType.PRICE &&
        (isVppDemandReduction(selectedTypeMode) || isVppDemandIncrease(selectedTypeMode)),
      activity$: this.createActivityObservable(vpp.uuid),
      metricMetaData: this.createMetricsForDemandTypeMode(selectedTypeMode),
    };
    return viewModel;
  }

  private createMetricsForDemandTypeMode(type: VppDemandTypeModeSelection): MetricMetaData[] {
    switch (type) {
      case VppDemandTypes.DEMAND_INCREASE:
      case VppDemandTypes.DEMAND_REDUCTION:
        return [
          {
            data$: this.getObservableFromMetricsMqtt('SWDINPV.ControlReference'),
            name: 'VirtualPowerPlant.Demand',
          },
          {
            data$: this.getObservableFromMetricsMqtt(`SWDINPV.DT[${type}].TotW[Avail]`),
            name: 'VirtualPowerPlant.Available',
          },
          {
            data$: this.getObservableFromMetricsMqtt(`SWDINPV.DT[${type}].TotW[Disp]`),
            name: 'VirtualPowerPlant.Dispatch',
          },
          {
            data$: this.getObservableFromMetricsMqtt('SWDINPV.Ctrls.Enabled'),
            total$: this.getObservableFromMetricsMqtt('SWDINPV.Devices').pipe(map(({ value }) => value)),
            name: 'VirtualPowerPlant.RespondingDevices',
          },
        ];
      case VppModes.FCAS:
        return [
          {
            data$: this.getObservableFromMetricsMqtt(`SWDINPV.DT[${VppDemandTypes.FCAS_RAISE}].TotW[Avail]`),
            name: 'VirtualPowerPlant.AvailableRaise',
          },
          {
            data$: this.getObservableFromMetricsMqtt(`SWDINPV.DT[${VppDemandTypes.FCAS_LOWER}].TotW[Avail]`),
            name: 'VirtualPowerPlant.AvailableLower',
          },
          {
            data$: this.getObservableFromMetricsMqtt(`SWDINPV.DT[${VppDemandTypes.FCAS_RAISE}].TotW[Disp]`),
            name: 'VirtualPowerPlant.DispatchRaise',
          },
          {
            data$: this.getObservableFromMetricsMqtt(`SWDINPV.DT[${VppDemandTypes.FCAS_LOWER}].TotW[Disp]`),
            name: 'VirtualPowerPlant.DispatchLower',
          },
          {
            data$: this.getObservableFromMetricsMqtt('SWDINPV.Ctrls.Enabled'),
            total$: this.getObservableFromMetricsMqtt('SWDINPV.Devices').pipe(map(({ value }) => value)),
            name: 'VirtualPowerPlant.RespondingDevices',
          },
        ];
      default:
        console.warn('Unknown demand type/mode selection');
        return [];
    }
  }

  private createActivityObservable(vppUuid: string): Observable<boolean> {
    if (vppUuid == null) return empty();
    return this._ngxMqttWrapper.observe(vppUuid + '/vpp_metrics').pipe(
      scan((prev) => !prev, false),
      startWith(false),
    );
  }

  private getObservableFromMetricsMqtt(key: string): Observable<{ value: number; timestamp: number }> {
    return this.vpp$.pipe(
      filter((vpp) => vpp?.uuid != null),
      switchMap(({ uuid }) => this._ngxMqttWrapper.observe(`${uuid}/vpp_metrics`)),
      map((msg) => {
        const parsedMsg: VppMetricMqtt = JSON.parse(msg.payload.toString());
        const value = parsedMsg?.measurements[key];
        const timestamp = parsedMsg?.timestamp;
        return { value, timestamp };
      }),
    );
  }

  public changeDemandTypeSelection(selection: VppDemandTypeModeSelection): void {
    this.overviewFacade.changeOverviewModeAndDemandType(selection);
  }
}
