import { UnitOperationalStates } from '@class/commons/enums';
import { PermissionKey } from '@class/commons/permissions/permission-constants';

export enum SubTabEnums {
  PORTFOLIO_SETTINGS = 'portfolio_settings',
  UNIT_OVERVIEW = 'unit_overview',
  UNIT_EQUIPMENT = 'unit_equipment',
  UNIT_ALERTS = 'unit_alert',
  UNIT_EXTRAS = 'unit_extra',
  UNIT_SETTINGS = 'unit_settings',
  VPP_OVERVIEW = 'vpp_overview',
  VPP_OVERVIEW_OPERATIONS = 'vpp_overview_operations',
  VPP_SETTINGS = 'vpp_settings',
  VPP_SETTING_CREATE_SCHEDULE = 'vpp_settings_create_schedule',
}

export interface SubTab {
  label: string;
  index: number;
  loggingUrl: string;
  permissionKey?: PermissionKey[];
  showForOperationalState?: (operationalState) => boolean;
}

export const showIfStateIsActive = (operationalState: UnitOperationalStates): boolean => {
  if (!operationalState) return true;

  return operationalState === UnitOperationalStates.ACTIVE;
};

export const notShowIfStateIsDeleted = (operationalState: UnitOperationalStates): boolean => {
  if (!operationalState) return true;

  return operationalState !== UnitOperationalStates.DELETED && operationalState !== UnitOperationalStates.PURGED;
};

const PORTFOLIO_SETTINGS_SUB_TABS: Readonly<SubTab>[] = [
  {
    label: 'Portfolio Details',
    index: 0,
    loggingUrl: '/portfolio/placeholderId/settings/portfolio_details',
  },
  {
    label: 'Portfolio Members',
    index: 1,
    loggingUrl: '/portfolio/placeholderId/settings/users',
  },
];

const UNIT_OVERVIEW_SUB_TABS: Readonly<SubTab>[] = [
  {
    label: 'UnitPage.OverviewTabs.NOW',
    index: 0,
    loggingUrl: '/unit/placeholderId/overview/now',
  },
  {
    label: 'UnitPage.OverviewTabs.ENERGY',
    index: 1,
    loggingUrl: '/unit/placeholderId/overview/energy',
    permissionKey: [PermissionKey.UNIT_VIEW_ENERGY],
    showForOperationalState: notShowIfStateIsDeleted,
  },
  {
    label: 'UnitPage.OverviewTabs.ANALYTICS',
    index: 2,
    loggingUrl: '/unit/placeholderId/overview/analytics',
    permissionKey: [PermissionKey.UNIT_VIEW_UNIT_ANALYTICS],
    showForOperationalState: notShowIfStateIsDeleted,
  },
  {
    label: 'UnitPage.OverviewTabs.STREAM',
    index: 3,
    loggingUrl: '/unit/placeholderId/overview/stream',
    permissionKey: [PermissionKey.UNIT_VIEW_STREAM],
    showForOperationalState: showIfStateIsActive,
  },
  {
    label: 'UnitPage.OverviewTabs.RAW_DATA',
    index: 4,
    loggingUrl: '/unit/placeholderId/overview/raw_data',
    permissionKey: [PermissionKey.GLOBAL_STAFF],
    showForOperationalState: showIfStateIsActive,
  },
];

const UNIT_EQUIPMENT_SUB_TABS: Readonly<SubTab>[] = [
  {
    label: 'UnitPage.EquipmentTabs.DEVICES',
    index: 0,
    loggingUrl: '/unit/placeholderId/equipments/devices',
    permissionKey: [PermissionKey.UNIT_VIEW_DEVICE],
  },
  {
    label: 'UnitPage.Controllers',
    index: 1,
    loggingUrl: '/unit/placeholderId/equipments/controllers',
    permissionKey: [PermissionKey.UNIT_VIEW_CONTROLLER],
  },
  {
    label: 'UnitPage.Tunnels',
    index: 2,
    loggingUrl: '/unit/placeholderId/equipments/tunnels',
    permissionKey: [PermissionKey.UNIT_ACCESS_TUNNELING],
  },
];

const UNIT_ALERTS_SUB_TABS: Readonly<SubTab>[] = [
  {
    label: 'Alerts.Alerts',
    index: 0,
    loggingUrl: '/unit/placeholderId/alerts/alerts',
    permissionKey: [PermissionKey.UNIT_VIEW_ALERT],
  },
  {
    label: 'Alerts.Definitions',
    index: 1,
    loggingUrl: '/unit/placeholderId/alerts/definitions',
    permissionKey: [PermissionKey.UNIT_VIEW_ALERTDEFINITION],
  },
];

const UNIT_EXTRAS_SUB_TABS: Readonly<SubTab>[] = [
  {
    label: 'UnitPage.OverviewTabs.COSTS',
    index: 0,
    loggingUrl: '/unit/placeholderId/extras/costs',
    permissionKey: [PermissionKey.UNIT_VIEW_UNIT_TARIFF],
  },
  {
    label: 'UnitPage.ExtrasTabs.Events',
    index: 1,
    loggingUrl: '/unit/placeholderId/extras/events',
    permissionKey: [PermissionKey.UNIT_VIEW_EVENT],
  },
];

const UNIT_SETTINGS_SUB_TABS: Readonly<SubTab>[] = [
  {
    label: 'UnitDetails.UnitDetails',
    index: 0,
    loggingUrl: '/unit/placeholderId/settings/unit_details',
  },
  {
    label: 'General.Users',
    index: 1,
    loggingUrl: '/unit/placeholderId/settings/users',
    permissionKey: [PermissionKey.UNIT_VIEW_UNITMEMBERSHIP],
  },
  {
    label: 'General.InviteUser.PendingInvites',
    index: 2,
    loggingUrl: '/unit/placeholderId/settings/pending_invites',
    permissionKey: [PermissionKey.UNIT_VIEW_UNITMEMBERSHIP],
  },
];

const VPP_OVERVIEW_SUB_TABS: Readonly<SubTab>[] = [
  {
    label: 'Status',
    index: 0,
    loggingUrl: '/virtualPowerPlant/overview/status',
  },
  {
    label: 'Operation',
    index: 1,
    loggingUrl: '/virtualPowerPlant/overview/operation',
  },
  {
    label: 'Analytics',
    index: 2,
    loggingUrl: '/virtualPowerPlant/overview/analytics',
  },
];

const VPP_OVERVIEW_OPERATIONS_SUB_TABS: Readonly<SubTab>[] = [
  {
    label: 'Now',
    index: 0,
    loggingUrl: '/virtualPowerPlant/overview/operation/now',
  },
  {
    label: 'Future',
    index: 1,
    loggingUrl: '/virtualPowerPlant/overview/operation/future',
  },
];

const VPP_SETTINGS_SUB_TABS: Readonly<SubTab>[] = [
  {
    label: 'Automation',
    index: 0,
    loggingUrl: '/virtualPowerPlant/settings/automation',
  },
  {
    label: 'General.General',
    index: 1,
    loggingUrl: '/virtualPowerPlant/settings/general',
  },
  {
    label: 'Group',
    index: 2,
    loggingUrl: '/virtualPowerPlant/settings/group',
  },
  {
    label: 'Merit',
    index: 3,
    loggingUrl: '/virtualPowerPlant/settings/merit',
  },
  {
    label: 'Schedules',
    index: 4,
    loggingUrl: '/virtualPowerPlant/settings/schedules',
  },
  {
    label: 'Policies',
    index: 5,
    loggingUrl: '/virtualPowerPlant/settings/policies',
  },
  {
    label: 'Users',
    index: 6,
    loggingUrl: '/virtualPowerPlant/settings/users',
  },
];

// don't change the names here
// it'll break create schedule in vpp
export const VPP_SETTING_CREATE_SCHEDULE_SUB_TABS: Readonly<SubTab>[] = [
  {
    label: 'Daily',
    index: 0,
    loggingUrl: '',
  },
  {
    label: 'Weekly',
    index: 1,
    loggingUrl: '',
  },
  {
    label: 'Monthly',
    index: 2,
    loggingUrl: '',
  },
  {
    label: 'Yearly',
    index: 3,
    loggingUrl: '',
  },
];

export const SUB_TABS: Record<SubTabEnums, SubTab[]> = {
  [SubTabEnums.PORTFOLIO_SETTINGS]: PORTFOLIO_SETTINGS_SUB_TABS,
  [SubTabEnums.UNIT_OVERVIEW]: UNIT_OVERVIEW_SUB_TABS,
  [SubTabEnums.UNIT_EQUIPMENT]: UNIT_EQUIPMENT_SUB_TABS,
  [SubTabEnums.UNIT_ALERTS]: UNIT_ALERTS_SUB_TABS,
  [SubTabEnums.UNIT_EXTRAS]: UNIT_EXTRAS_SUB_TABS,
  [SubTabEnums.UNIT_SETTINGS]: UNIT_SETTINGS_SUB_TABS,
  [SubTabEnums.VPP_OVERVIEW]: VPP_OVERVIEW_SUB_TABS,
  [SubTabEnums.VPP_OVERVIEW_OPERATIONS]: VPP_OVERVIEW_OPERATIONS_SUB_TABS,
  [SubTabEnums.VPP_SETTINGS]: VPP_SETTINGS_SUB_TABS,
  [SubTabEnums.VPP_SETTING_CREATE_SCHEDULE]: VPP_SETTING_CREATE_SCHEDULE_SUB_TABS,
};
