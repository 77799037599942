import { Component, Input, OnDestroy } from '@angular/core';
import { DropletService } from '../../services/units/endpoints/devices.service';
import { DeviceDetailMessageState } from '../device-detail-message/device-detail-message.component';
import { DropletDisplay } from '@class/units/droplet/droplet-display.model';
import { DeviceDetails } from '@class/units/droplet/device-details.model';
import { BrowserLogger } from '@class/core/browser-logger';
@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss'],
})
export class DeviceDetailComponent implements OnDestroy {
  @Input() isUnitActive: boolean;
  deviceNameEdit = false;
  selectedDevice: DeviceDetails = null;
  selectedDroplet: DropletDisplay;
  readonly DeviceDetailMessageState = DeviceDetailMessageState;
  deviceNameToBeEdited: string;
  deviceNameErrorTitle: string[];
  deviceNameErrorSubtitle: string[];
  isInformationCardExpanded = true;
  isConnectionAttributeCardExpanded = true;
  isControllerCardExpanded = true;
  isLogicalDeviceDetailCardExpanded = true;

  constructor(private dropletService: DropletService) {
    BrowserLogger.log('DeviceDetail.constructor');
    // subscribe to the dropletService State changes
    // updating our device reference
    this.dropletService.vm$.subscribe((state) => {
      if (state.data && state.displayedEndpointDeviceIndex) {
        this.selectedDroplet = null;
        const endpointIdx = state.displayedEndpointDeviceIndex.endpointIndex;
        const deviceIdx = state.displayedEndpointDeviceIndex.deviceIndex;
        if (endpointIdx !== undefined && deviceIdx !== undefined) {
          this.selectedDroplet = state.data[endpointIdx];
          this.selectedDevice = state.data[endpointIdx].devices[deviceIdx];
          BrowserLogger.log('DeviceDetail.dropletService.vm$', {
            selectedDroplet: this.selectedDroplet,
            selectedDevice: this.selectedDevice,
            endpointIdx,
            deviceIdx,
            state: state,
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    BrowserLogger.log('DeviceDetail.ngOnDestroy');
  }

  onEditClicked(deviceDetails: DeviceDetails): void {
    BrowserLogger.log('DeviceDetail.onEditClicked', { deviceDetails });
    this.deviceNameEdit = true;
  }

  onRemoveClicked(deviceDetails: DeviceDetails): void {
    BrowserLogger.log('DeviceDetail.onRemoveClicked', { deviceDetails });
    this.dropletService.removeDevice();
  }

  onCancelEditClicked(): void {
    BrowserLogger.log('DeviceDetail.onCancelEditClicked');
    this.cancelEditDeviceName();
  }

  onUpdateDeviceNameClicked(): void {
    BrowserLogger.log('DeviceDetail.onUpdateDeviceNameClicked');
    this.submitDeviceName();
  }

  onDeviceNameChanged(event: string): void {
    BrowserLogger.log('DeviceDetail.onDeviceNameChanged', { event });
    this.updateDeviceString(event);
  }

  private updateDeviceString(name: string): void {
    this.deviceNameToBeEdited = name;
  }

  public async submitDeviceName(): Promise<void> {
    // note: empty string is supported which will reset the name to the default
    await this.dropletService.updateDeviceName(this.deviceNameToBeEdited);
    this.cancelEditDeviceName();
  }

  private cancelEditDeviceName(): void {
    this.deviceNameEdit = false;
    this.deviceNameToBeEdited = '';
    this.deviceNameErrorTitle = undefined;
    this.deviceNameErrorSubtitle = undefined;
  }
}
