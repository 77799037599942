<ion-card *ngIf="device_health_status.length > 0" class="ion-no-padding ion-no-margin new-design-card">
  <ion-card-header>
    <ion-card-title class="card-title"> {{ 'Metrics.Health' | translate }} </ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ion-grid class="ion-no-padding">
      <ion-row class="fullHeight width-100-pct ion-justify-content-center">
        <ion-col *ngFor="let item of device_health_status" size-xs="12" size-lg="4" class="fullHeight p-2">
          <div class="b-radius-6" [ngClass]="item.background">
            <ion-grid>
              <ion-row class="ion-align-items-center">
                <ion-col size="2">
                  <ion-icon class="custom-icon-medium" src="assets/icons/svg/{{ item.icon }}.svg"></ion-icon>
                </ion-col>
                <ion-col size="9">
                  <ion-grid>
                    <ion-row>
                      <ion-col size-xs="6" size-lg="12" class="text-light-o pr-5" translate>
                        {{ item.item }}
                      </ion-col>
                      <ion-col size-xs="6" size-lg="12" class="text-dark fw-600" translate>
                        {{ item.message }}
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-col>
                <ion-col size="1">
                  <ion-icon class="custom-icon-small" src="assets/icons/svg/{{ item.healthIcon }}.svg"></ion-icon>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
