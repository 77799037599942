<ion-item lines="none" [ngClass]="ionItemExtraCss">
  <ion-select
    interface="popover"
    class="width-100-pct select-input fs-14 fw-600"
    [ngClass]="{ 'input-error': invalid && (dirty || touched) }"
    [placeholder]="placeholder"
    (ionChange)="ionChange()"
    (ionBlur)="ionBlur()"
    [(ngModel)]="value"
    [multiple]="multiple"
  >
    <ion-select-option *ngFor="let option of options" [value]="option.value">{{ option.name }}</ion-select-option>
  </ion-select>
</ion-item>

<app-input-validation-errors
  class="width-100-pct"
  [label]="placeholder"
  [invalid]="invalid"
  [dirty]="dirty"
  [touched]="touched"
  [errors]="errors"
></app-input-validation-errors>
