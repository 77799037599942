<ion-searchbar
  class="base-input"
  showCancelButton="never"
  placeholder="Filter"
  autocomplete="on"
  [debounce]="300"
  (ionInput)="applyFilter($event.target.value)"
  mode="md"
></ion-searchbar>
<div class="mat-elevation-z8 scroll-x-auto no-shadow">
  <table mat-table [dataSource]="datasource" matSort>
    <ng-container matColumnDef="device">
      <th class="ion-text-start" mat-header-cell *matHeaderCellDef>{{ 'General.Device' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <a *ngIf="!historyTunnel" href="http://tunnels.switchdin.com:{{ row.server_port }}" target="_blank"
          >{{ row.device_name }}
        </a>
        <span *ngIf="historyTunnel">{{ row.device_name }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="port">
      <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'General.Port' | translate }}</th>
      <td class="ion-text-center" mat-cell *matCellDef="let row">{{ row.device_port }}</td>
    </ng-container>
    <ng-container matColumnDef="server_port">
      <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'General.ServerPort' | translate }}</th>
      <td class="ion-text-center" mat-cell *matCellDef="let row">{{ row.server_port }}</td>
    </ng-container>
    <ng-container matColumnDef="request_time">
      <th class="ion-text-center" mat-header-cell *matHeaderCellDef>
        {{ 'VirtualPowerPlant.RequestTime' | translate }}
      </th>
      <td class="ion-text-center" mat-cell *matCellDef="let row">{{ row.requestTime }}</td>
    </ng-container>
    <ng-container matColumnDef="closing_time">
      <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'General.ClosingTime' | translate }}</th>
      <td class="ion-text-center" mat-cell *matCellDef="let row">
        {{ row.closingTime }}
      </td>
    </ng-container>
    <ng-container matColumnDef="service">
      <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'Cost.Service' | translate }}</th>
      <td class="ion-text-center" mat-cell *matCellDef="let row">{{ row.service_name }}</td>
    </ng-container>
    <ng-container matColumnDef="protocol">
      <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'General.Protocol' | translate }}</th>
      <td class="ion-text-center" mat-cell *matCellDef="let row">{{ row.protocol }}</td>
    </ng-container>
    <ng-container matColumnDef="control">
      <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'VirtualPowerPlant.Control' | translate }}</th>
      <td class="ion-text-center" mat-cell *matCellDef="let row">{{ row.disable_control }}</td>
    </ng-container>
    <ng-container matColumnDef="comms">
      <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'General.Comms' | translate }}</th>
      <td class="ion-text-center" mat-cell *matCellDef="let row">{{ row.disable_comms }}</td>
    </ng-container>
    <ng-container matColumnDef="multiple">
      <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'General.Multiple' | translate }}</th>
      <td class="ion-text-center" mat-cell *matCellDef="let row">
        {{ row.allow_multiple }}
      </td>
    </ng-container>
    <ng-container matColumnDef="user">
      <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'VirtualPowerPlant.User' | translate }}</th>
      <td class="ion-text-center" mat-cell *matCellDef="let row">{{ row.user_fullname }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'VirtualPowerPlant.Action' | translate }}</th>
      <td class="ion-text-center" mat-cell *matCellDef="let row">
        <ion-button (click)="stopTunnel(row)">
          <ion-icon slot="end" name="stop-circle-outline"></ion-icon>
          Stop
        </ion-button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="firstRowHeader"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
</div>
