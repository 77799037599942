<ng-container *ngIf="vm$ | async as vm$">
  <ion-row class="ion-no-padding ion-no-margin" *ngIf="vm.enableManualControlToggle">
    <ion-col>
      <ion-item lines="none" class="width-100-pct">
        <ion-toggle
          class="ion-align-self-end mb-5"
          (ionChange)="handleManualControlToggle()"
          [(ngModel)]="vm.manualControlToggleValue"
          [disabled]="
            (vm.enableMqttInputs && vm$.isDisableInput) || (!vm.enableMqttInputs && vm.controllerParamIsDisabled)
          "
          mode="ios"
          color="button-primary"
          justify="space-between"
        >
          <!-- Name/Desc -->

          <p class="text-dark ion-no-margin">
            {{ vm$.control.name }}
          </p>
          <p class="fw-500 text-dark ion-no-margin">
            {{ 'UnitPage.ControlManualModeToggle' | translate }}
          </p>
        </ion-toggle>
      </ion-item>
    </ion-col>
  </ion-row>
  <!-- only show inputs/helpers if not in auto mode -->
  <ng-container *ngIf="vm.enableInputs">
    <!-- Description (and Toggle) -->
    <ion-row class="ion-no-padding ion-no-margin" *ngIf="vm.enableToggleInput">
      <ion-col>
        <ion-item lines="none" class="width-100-pct">
          <!-- toggle with mqtt-->
          <ion-toggle
            *ngIf="vm.enableMqttInputs"
            [(ngModel)]="deviceControlService.mqttEditValue"
            (click)="handleToggleInputClick(deviceControlService.mqttEditValue)"
            [disabled]="vm$.isDisableInput"
            color="button-primary"
            justify="space-between"
            mode="ios"
          >
            {{ vm$.control.description }}
          </ion-toggle>
          <!-- toggle with no mqtt (controller param) -->
          <ion-toggle
            *ngIf="!vm.enableMqttInputs"
            [disabled]="vm.controllerParamIsDisabled"
            [(ngModel)]="vm.deviceControl.value"
            (click)="handleControllerParamDeviceControlValueChange()"
            color="button-primary"
            justify="space-between"
            mode="ios"
          >
            {{ vm$.control.description }}
          </ion-toggle>
        </ion-item>
      </ion-col>
    </ion-row>
    <!-- Numeric/String/Nullable: Show an Input with a Button -->
    <ion-row class="ion-no-padding ion-no-margin" *ngIf="vm.enableTextControlInput">
      <!-- MQTT Value -->
      <ng-container *ngIf="vm.enableMqttInputs">
        <ion-col size="12">
          <ion-item lines="none" class="width-100-pct">
            <ion-label>
              {{ vm$.control.description }}
            </ion-label>
          </ion-item>
        </ion-col>
        <ion-col size="3" class="ion-no-padding ion-no-margin width-100-pct">
          <div class="mqtt-value width-100-pct b-radius-6 pl-15 ml-20 mr-10 text-dark fw-600 fs-14 fullHeight">
            <div
              *ngIf="vm$.endpointMqttValue !== undefined && (vm$.endpointMqttValue | async) != undefined; else waiting"
            >
              {{ vm$.endpointMqttValue | async | number: '1.0-3' }}
            </div>
            <!-- no mqtt response (waiting) -->
            <ng-template #waiting>
              <div class="spinner-div fullHeight">
                <!-- Show Spinner for non manual controls (while waiting for mqtt msg), else (manual controllable) assume is in auto mode -->
                <ion-spinner name="dots" *ngIf="!vm.enableManualControlToggle; else autoLabel"></ion-spinner>
                <ng-template #autoLabel>
                  {{ 'UnitPage.AutoMode' | translate }}
                </ng-template>
              </div>
            </ng-template>
          </div>
        </ion-col>
        <!-- Input & Update Button -->
        <ion-col size="9">
          <app-input-with-inline-button
            [inputType]="vm.textControlInputType"
            [submitButton]="true"
            [isDisable]="vm$.isDisableInput"
            (valueChangeEvent)="handleTextInputClick($event)"
            class="width-100-pct mr-20"
          ></app-input-with-inline-button>
        </ion-col>
      </ng-container>
      <!-- Controller Params (Simple Input, not MQTT Input) -->
      <ng-container *ngIf="!vm.enableMqttInputs && vm.deviceControl">
        <ion-item lines="none" class="width-100-pct">
          <ion-label>
            {{ vm$.control.description }}
          </ion-label>
        </ion-item>
        <ion-col size="12">
          <app-input
            class="width-100-pct"
            [isDisable]="vm.controllerParamIsDisabled"
            [(ngModel)]="vm.deviceControl.value"
            (ionChange)="handleControllerParamDeviceControlValueChange()"
          ></app-input>
        </ion-col>
        <ion-col size="12" *ngIf="!vm.deviceControl.isValueValid">
          <div class="alert alert-danger">
            {{ vm.controlValueErrorMsg }}
          </div>
        </ion-col>
      </ng-container>
    </ion-row>
    <!-- placeholder text -->
    <ion-row *ngIf="vm$.control.placeholder" class="mb-15">
      <ion-col>
        <div class="ml-20 mt-5 text-light fs-13">
          {{ vm$.control.placeholder }}
        </div>
      </ion-col>
    </ion-row>
  </ng-container>
  <!-- resync -->
  <div
    class="ml-20 mr-20 ion-margin-vertical"
    *ngIf="
      vm$.control.isSetting &&
      (vm$.isBackendAndMqttNotSynced | async) &&
      vm$.status.state != DeviceDetailMessageState.LOADING
    "
  >
    <app-resync-device-message-button
      [displayLabel]="vm.syncLabel + vm$.control.backendValue"
      [buttonLabel]="'UnitPage.OverrideDropletSetting' | translate"
      (resync)="handleResyncClick(vm$.control.backendValue)"
    ></app-resync-device-message-button>
  </div>
  <!-- control mqtt message status -->
  <div class="ml-20 mr-20 mt-15" *ngIf="vm.deviceControlStatus">
    <app-device-detail-message
      [state]="vm.deviceControlStatus.state"
      [title]="vm.deviceControlStatus.title"
      [subtitles]="vm.deviceControlStatus.subtitle"
      [attempts]="vm.deviceControlStatus.mqttResponseRetries"
    ></app-device-detail-message>
  </div>
</ng-container>
