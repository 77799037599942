import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputsConfig, InputSelectOptions } from '../../../classes/commons/inputs';

@Component({
  selector: 'app-input-select-stacked-label-with-icon',
  templateUrl: './input-select-stacked-label-with-icon.component.html',
  styleUrls: ['./input-select-stacked-label-with-icon.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectStackedLabelWithIconComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputSelectStackedLabelWithIconComponent),
      multi: true,
    },
  ],
})
export class InputSelectStackedLabelWithIconComponent extends InputsConfig {
  @Input() options: Array<InputSelectOptions>;
}
