<ng-container *ngrxLet="vm$ as vm">
  <ion-card class="ion-padding card-design">
    <ion-list>
      <ion-item>
        <ion-checkbox [checked]="vm.filterByDate" (ionChange)="handleFilterByDateChange($event)" translate>
          UnitPage.Events.FilterByDate
        </ion-checkbox>
      </ion-item>
      <ng-container *ngIf="vm.filterByDate">
        <ion-item>
          <ion-label translate>UnitPage.Events.StartDate</ion-label>
          <ion-datetime-button datetime="start-date" />
        </ion-item>

        <ion-item>
          <ion-label translate>UnitPage.Events.EndDate</ion-label>
          <ion-datetime-button datetime="end-date" />
        </ion-item>
      </ng-container>
    </ion-list>
  </ion-card>

  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-button
          color="button-primary"
          [disabled]="vm.previousPageUrl == null"
          (click)="pageChange(vm.previousPageUrl)"
        >
          <span translate>UnitPage.Events.Newer</span>
          <ion-icon name="chevron-back-sharp"></ion-icon>
        </ion-button>
        <ion-button
          color="button-primary"
          class="ml-5"
          [disabled]="vm.nextPageUrl == null"
          (click)="pageChange(vm.nextPageUrl)"
        >
          <ion-icon name="chevron-forward-sharp"></ion-icon>
          <span translate>UnitPage.Events.Older</span>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <app-state-wrapper
    [stateType]="vm.unitDeviceEvents.status"
    [errorMsg]="vm.unitDeviceEvents.error"
    [showNoData]="vm.unitDeviceEvents.data?.results?.length <= 0"
    (onRetry)="retryPortfolioList()"
    showNoDataMsg="{{ 'NoDataMsgs.NoUnitDeviceEvents' | translate }}"
  >
    <ion-card class="ion-padding card-design">
      <ion-accordion-group [value]="['0']" [multiple]="true">
        <ng-container *ngFor="let event of vm.unitDeviceEvents.data?.results; let index = index; trackBy: trackItems">
          <ion-accordion [value]="index">
            <ion-item slot="header" color="light">
              <ion-text>
                <p class="ion-no-margin mt-10 text-primary-lg">{{ event.reason }}</p>
                <p class="ion-no-margin mb-10 text-secondary-md">{{ event.time_created_from_now }}</p>
              </ion-text>
              <ion-text slot="end">
                {{ event.importance }}
              </ion-text>
            </ion-item>
            <div class="ion-padding" slot="content">
              <!-- Event Detail -->
              <ion-row>
                <ion-col>
                  <ion-item lines="full">
                    <ion-text class="text-primary-xl"> {{ 'UnitPage.EventDetail' | translate }} </ion-text>
                  </ion-item>
                  <ion-item>
                    <ion-text class="text-primary-md"> {{ 'UnitPage.SystemState' | translate }} </ion-text>
                    <ion-text slot="end" class="text-primary-lg"> {{ event.system_state }} </ion-text>
                  </ion-item>
                  <ion-item>
                    <ion-text class="text-primary-md"> {{ 'UnitPage.DeviceTimeStamp' | translate }} </ion-text>
                    <ion-text slot="end" class="text-primary-lg"> {{ event.droplet_timestamp }} </ion-text>
                  </ion-item>
                  <ion-item>
                    <ion-text class="text-primary-md"> {{ 'UnitPage.ReceivedTimeStamp' | translate }} </ion-text>
                    <ion-text slot="end" class="text-primary-lg"> {{ event.time_created }} </ion-text>
                  </ion-item>
                  <ion-item>
                    <ion-text class="text-primary-md"> {{ 'UnitPage.Model' | translate }} </ion-text>
                    <ion-text slot="end" class="text-primary-lg"> {{ event.model }} </ion-text>
                  </ion-item>
                  <ion-item>
                    <ion-text class="text-primary-md"> {{ 'UnitPage.Serial' | translate }} </ion-text>
                    <ion-text slot="end" class="text-primary-lg"> {{ event.serial }} </ion-text>
                  </ion-item>
                </ion-col>
              </ion-row>

              <!-- Extras -->
              <ng-container *ngIf="event.extra">
                <ion-row>
                  <ng-container *ngFor="let item of event.extra | keyvalue">
                    <ng-container *ngIf="!isArray(item.value)">
                      <ion-col size="12" size-lg="6">
                        <ion-item lines="full">
                          <ion-text class="text-primary-xl"> {{ item.key }} </ion-text>
                        </ion-item>
                        <ion-item *ngFor="let subItems of item.value | keyvalue">
                          <ion-text class="text-primary-md"> {{ subItems.key }} </ion-text>
                          <ion-text slot="end" class="text-primary-lg"> {{ subItems.value }} </ion-text>
                        </ion-item>
                      </ion-col>
                    </ng-container>

                    <!-- Fault History - it's an array -->
                    <ng-container *ngIf="isArray(item.value) && item.key === 'Fault History'">
                      <ion-col size="12">
                        <ion-item lines="full">
                          <ion-text class="text-primary-xl"> {{ item.key }} </ion-text>
                        </ion-item>
                        <ion-row>
                          <ng-container *ngFor="let faultItems of item.value">
                            <ion-col size="12" size-lg="6">
                              <ion-item lines="full">
                                <ion-text class="text-primary-lg">
                                  {{ faultItems.record }} - {{ faultItems.details.fault_code.name }}
                                </ion-text>
                              </ion-item>
                              <ion-item>
                                <ion-text class="text-primary-md"> {{ 'UnitPage.Code' | translate }} </ion-text>
                                <ion-text slot="end" class="text-primary-lg">
                                  {{ faultItems.details.fault_code.number }}
                                </ion-text>
                              </ion-item>
                              <ion-item>
                                <ion-text class="text-primary-md"> {{ 'UnitPage.Timestamp' | translate }} </ion-text>
                                <ion-text slot="end" class="text-primary-lg">
                                  {{ faultItems.details.timestamp }}
                                </ion-text>
                              </ion-item>

                              <!-- Fault Measurements -->
                              <ng-container
                                *ngFor="let subFaultItems of faultItems.details.fault_measurements | keyvalue"
                              >
                                <ion-item>
                                  <ion-text class="text-primary-md"> {{ subFaultItems.key }} </ion-text>
                                  <ion-text slot="end" class="text-primary-lg"> {{ subFaultItems.value }} </ion-text>
                                </ion-item>
                              </ng-container>
                            </ion-col>
                          </ng-container>
                        </ion-row>
                      </ion-col>
                    </ng-container>
                  </ng-container>
                </ion-row>
              </ng-container>
            </div>
          </ion-accordion>
        </ng-container>
      </ion-accordion-group>
    </ion-card>
  </app-state-wrapper>

  <ion-modal [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime
        id="start-date"
        presentation="date"
        (ionChange)="handleStartDateChange($event)"
        [min]="vm.minDate"
        [max]="vm.endDate"
        [value]="vm.startDate"
      />
    </ng-template>
  </ion-modal>

  <ion-modal [keepContentsMounted]="true">
    <ng-template>
      <ion-datetime
        id="end-date"
        presentation="date"
        (ionChange)="handleEndDateChange($event)"
        [min]="vm.minDate"
        [max]="vm.maxDate"
        [value]="vm.endDate"
      />
    </ng-template>
  </ion-modal>
</ng-container>
