<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{"SerialNoEntryModal.Title" | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="ion-align-items-center fh-row">
      <form class="width-100-pct" (ngSubmit)="confirm()">
        <ion-row class="ion-align-self-end">
          <ion-col class="ion-text-center ion-margin-bottom">
            <ion-img [src]="deviceType?.logicalDeviceTypeImage"></ion-img>
            <h1 class="ion-text-start">{{"SerialNoEntryModal.TitleInstructions" | translate}}</h1>
            <div *ngFor="let formField of requiredFields">
              <p class="ion-margin-bottom ion-text-start" innerHTML="{{formField.label | translate }}"></p>
              <swidin-input
                #macAddr="ngModel"
                ngDefaultControl
                name="formField.name"
                [regex]="formField.input_patterns"
                [(ngModel)]="formField.value"
                label="{{ formField.label | translate }}"
              ></swidin-input>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-self-end">
          <ion-col>
            <ion-button
              [disabled]="!validateSerialNo()"
              (click)="confirm()"
              expand="block"
              size="large"
              color="button-primary"
              >{{"General.Confirm" | translate}}</ion-button
            >
          </ion-col>
        </ion-row>
      </form>
    </ion-row>
  </ion-grid>
</ion-content>
