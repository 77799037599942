import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VppUnitMetricKeyType } from '@class/vpp/vpp-unit.model';
import { isEmpty, sum } from 'lodash';
import { DateTime, DurationObjectUnits } from 'luxon';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { TimePeriodResolution } from '@class/commons/constants-datetime';
import {
  isVppDemandIncrease,
  isVppDemandReduction,
  VppDemandTypes,
  VppModes,
} from '../../../../classes/vpp/vpp-demand-type-mode.model';
import { Vpp } from '../../../../classes/vpp/vpp-types';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '../../../common/api-wrapper.service';
import { TranslationsService } from '../../../common/translations.service';
import { ThemeService } from '../../../themes/theme.service';
import { VppTimezoneService } from '../../timezone/vpp-timezone.service';
import { VirtualPowerPlantsService } from '../../virtual-power-plants.service';
import { VppModelStatus } from '../operation-tab/vpp-operation-schedule-modal-facade.service';
import { VppOverviewFacadeService } from '../overview/vpp-overview-facade.service';
const BAR_BORDER_LINE_WIDTH = 0.1;
const OFFSET = 0.025;

interface VppHistoryGetPayload {
  demand: number[];
  power: {
    data: number[];
    demand_type: VppDemandTypes;
    group: string;
    order: number;
    status: VppUnitMetricKeyType;
  }[];
  ts: string[]; // date time string in UTC, eg '2021-11-04T13:00:00'
}

export interface VppAnalyticsViewModelData {
  metaData: VppAnalyticsViewModelMetaData;
  chartObjects: any[];
}

type VppAnalyticsViewModelChartDataPower = {
  data: number[];
  demandType: VppDemandTypes;
  group: string;
  order: number;
  status: VppUnitMetricKeyType;
};

export interface VppAnalyticsViewModelChartData {
  demand: number[];
  power: VppAnalyticsViewModelChartDataPower[];
  ts: string[]; // date time string in UTC, eg '2021-11-04T13:00:00'
}

export interface VppAnalyticsViewModelMetaData {
  nextPeriodEnable: boolean;
  previousPeriodEnable: boolean;
  datePickerMin: Date;
  datePickerMax: string; // ISO string
  startTimeEpoch: number;
  endTimeEpoch: number;
  timezone: string;
  selectedDemandTypeMode: VppDemandTypes | VppModes;
}
export interface VppAnalyticsViewModel {
  status: VppModelStatus;
  data: VppAnalyticsViewModelData;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class VppAnalyticsFacadeService {
  private displayStrings = this.trans.instant('VirtualPowerPlant.Analytics');
  // meta data subjects/observables
  private userRefreshes = new BehaviorSubject<void>(null);
  private userSelectPeriodStartEpoch = new BehaviorSubject<number>(null);
  private metaData$ = combineLatest([
    this.overviewFacade.vppOverviewModeDemandType$.pipe(distinctUntilChanged()),
    this.userSelectPeriodStartEpoch.pipe(distinctUntilChanged()),
    this.timezoneService.timezone$.pipe(distinctUntilChanged()),
    this.userRefreshes,
  ]).pipe(
    tap(() =>
      this.vmStatus$.next({
        message: this.displayStrings.FuturePeriodError,
        status: VppModelStatus.LOADING,
        data: null,
      }),
    ),
    map(([{ demandType }, periodStart, timezone]) => this.createMetaData(demandType, periodStart, timezone)),
  );

  // create plotly graph objects
  private vmData$: Observable<VppAnalyticsViewModel> = combineLatest([
    this.vppService.vppSelected$, // refresh when new vpp is selected
    this.metaData$, // refresh when date/period/timezone changes
  ]).pipe(
    tap(() =>
      this.vmStatus$.next({
        message: this.displayStrings.RenderingGraph,
        status: VppModelStatus.LOADING,
        data: null,
      }),
    ),
    switchMap(([vpp, metaData]) => this.prepareViewModelData(vpp, metaData)),
    map((data) => {
      return {
        message: null,
        status: VppModelStatus.COMPLETE,
        data,
      };
    }),
    catchError((e) => this.handleError(e)),
  );

  // view model subjects/ observables
  private vmStatus$ = new BehaviorSubject<VppAnalyticsViewModel>({
    message: this.displayStrings.FetchingHistory,
    status: VppModelStatus.LOADING,
    data: null,
  });

  public vm$: Observable<VppAnalyticsViewModel> = merge(this.vmStatus$, this.vmData$);
  constructor(
    private overviewFacade: VppOverviewFacadeService,
    private vppService: VirtualPowerPlantsService,
    private timezoneService: VppTimezoneService,
    private theme: ThemeService,
    private trans: TranslationsService,
    private api: ApiWrapper,
  ) {}

  private createMetaData(
    selectedDemandTypeMode: VppModes | VppDemandTypes,
    startEpochSecond: number,
    timezone: string,
  ): VppAnalyticsViewModelMetaData {
    if (selectedDemandTypeMode == null || timezone == null) return null;

    const epochNow = DateTime.local().setZone(timezone).toSeconds();
    const selectedEpoch = startEpochSecond ?? epochNow;

    const metaData: VppAnalyticsViewModelMetaData = {
      nextPeriodEnable: this.determineIfNextPeriodButtonEnable(selectedEpoch, epochNow, TimePeriodResolution.DAY),
      previousPeriodEnable: this.determineIfPreviousButtonEnable(selectedEpoch, TimePeriodResolution.DAY),
      datePickerMin: new Date(0),
      datePickerMax: DateTime.local().setZone(timezone).toISO(),
      startTimeEpoch: DateTime.fromSeconds(selectedEpoch, { zone: timezone })
        .startOf(TimePeriodResolution.DAY)
        .toSeconds(),
      endTimeEpoch: DateTime.fromSeconds(selectedEpoch, { zone: timezone }).endOf(TimePeriodResolution.DAY).toSeconds(),
      timezone,
      selectedDemandTypeMode,
    };
    return metaData;
  }

  private determineIfNextPeriodButtonEnable(
    startEpoch: number,
    epochNow: number,
    period: TimePeriodResolution | keyof DurationObjectUnits,
  ): boolean {
    if (startEpoch > epochNow) throw this.displayStrings.FuturePeriodError;
    const addedEpoch = this.addPeriodToEpoch(startEpoch, period);
    return epochNow >= addedEpoch;
  }
  private addPeriodToEpoch(epoch: number, period: TimePeriodResolution | keyof DurationObjectUnits) {
    if (epoch == null || period == null) return null;
    return DateTime.fromSeconds(epoch)
      .plus({ [period]: 1 })
      .toSeconds();
  }
  private minusPeriodToEpoch(epoch: number, period: TimePeriodResolution | keyof DurationObjectUnits) {
    if (epoch == null || period == null) return null;
    return DateTime.fromSeconds(epoch)
      .minus({ [period]: 1 })
      .toSeconds();
  }
  private determineIfPreviousButtonEnable(
    startEpoch: number,
    period: TimePeriodResolution | keyof DurationObjectUnits,
  ): boolean {
    return this.minusPeriodToEpoch(startEpoch, period) > 0;
  }

  private async prepareViewModelData(
    vpp: Vpp,
    metaData: VppAnalyticsViewModelMetaData,
  ): Promise<VppAnalyticsViewModelData> {
    if (isEmpty(vpp) || isEmpty(metaData)) return null;
    const { startTimeEpoch, endTimeEpoch } = metaData;
    const response = await this.vppOperationHistoryApi(vpp.id, startTimeEpoch, endTimeEpoch);
    const history = this.mapPayloadToChartData(response.data);
    const chartObjects = this.createChartGraphObjects(history, metaData, vpp);

    return {
      chartObjects,
      metaData,
    };
  }

  private mapPayloadToChartData(payload: VppHistoryGetPayload): VppAnalyticsViewModelChartData {
    if (isEmpty(payload)) return null;
    const { demand, power, ts } = payload;
    const chartPower = power.map(({ data, demand_type, group, order, status }) => {
      return { data, demandType: demand_type, group, order, status };
    });
    const chartData = {
      demand,
      power: chartPower,
      ts,
    };
    return chartData;
  }

  private createChartGraphObjects(
    chartData: VppAnalyticsViewModelChartData,
    metaData: VppAnalyticsViewModelMetaData,
    vpp: Vpp,
  ) {
    if (isEmpty(chartData) || isEmpty(metaData) || isEmpty(vpp)) return [];
    const { selectedDemandTypeMode, timezone } = metaData;
    const { demand, power, ts } = chartData;
    const barColors = this.theme.getVPPStateChartBarColors();

    let graphObjects = [];
    const timeSeq = this.createDatesWithOffsetFromTimezone(ts, timezone);
    graphObjects.push(this.createBaseTimeSeries(demand, power, timeSeq, selectedDemandTypeMode));

    const availObjs = this.createAvailableObjectTimeSeries(power, timeSeq, selectedDemandTypeMode, barColors.avail);
    const dispatchObjs = this.createDispatchObjectTimeSeries(
      power,
      timeSeq,
      selectedDemandTypeMode,
      barColors.dispatch,
    );
    if (isVppDemandIncrease(selectedDemandTypeMode)) {
      graphObjects = graphObjects.concat(availObjs.concat(dispatchObjs));
    } else if (isVppDemandReduction(selectedDemandTypeMode)) {
      graphObjects = graphObjects.concat(dispatchObjs.concat(availObjs));
    }

    graphObjects.push(this.createLiveObjectTimeSeries(timeSeq, demand));
    graphObjects.push(this.createMinObjectTimeSeries(timeSeq, vpp, selectedDemandTypeMode));
    return graphObjects;
  }

  private createDatesWithOffsetFromTimezone(timeSeq: string[], timezone: string): Date[] {
    const utcOffsetMS = DateTime.local().setZone(timezone).offset * 60 * 1000;
    return (
      timeSeq?.map((ts) => {
        const date = new Date(ts);
        date.setTime(date.getTime() + utcOffsetMS);
        return date;
      }) ?? null
    );
  }

  private createBaseTimeSeries(
    demand: number[],
    power: VppAnalyticsViewModelChartDataPower[],
    timeSeq: Date[],
    demandTypeModes: VppDemandTypes | VppModes,
  ) {
    if (isEmpty(power) || isEmpty(timeSeq) || power.length != demand.length || demandTypeModes == null) return [];
    const baseOffsetArray = this.createBaseOffsetArray(power, demandTypeModes);
    return demand.map((base, i) => {
      return {
        name: 'base',
        type: 'bar',
        marker: { color: 'rgba(255,0,0,0)' },
        hovoron: 'fills',
        hoverinfo: 'none',
        x: timeSeq,
        offset: OFFSET,
        y: base - baseOffsetArray[i],
        showlegend: false,
      };
    });
  }

  // need to offset base y value by the available power (reduction demand types) or dispatched power (increase demand types)
  private createBaseOffsetArray(
    power: VppAnalyticsViewModelChartDataPower[],
    demandTypeModes: VppDemandTypes | VppModes,
  ): number[] {
    // fix for FCAS
    let baseOffsetMatrix: number[][]; // [power timeseries index][time index], eg [p1t1 p1t2 p1pt3],[p2t1 p2t2 p2pt3]]
    if (isVppDemandIncrease(demandTypeModes)) {
      baseOffsetMatrix = power
        .filter((x) => isVppDemandIncrease(x.demandType) && x.status === VppUnitMetricKeyType.DISPATCH)
        .map((disp) => disp.data);
    } else if (isVppDemandReduction(demandTypeModes)) {
      baseOffsetMatrix = power
        .filter((x) => isVppDemandReduction(x.demandType) && x.status === VppUnitMetricKeyType.AVAILABLE)
        .map((avail) => avail.data);
    } else {
      return [];
    }
    const baseOffsetMatrixTransposed = this.transposeNumberMatrix(baseOffsetMatrix); // [time index][power timeseries index], eg [[p1t1 p2t1],[p1t2 p2t2], [p1t3 p2t3]]
    return baseOffsetMatrixTransposed.map((powers) => sum(powers)); //[pt1,pt2,pt3]
  }

  private transposeNumberMatrix(matrix: number[][]): number[][] {
    if (isEmpty(matrix) || isEmpty(matrix[0])) return [];
    const colLength = matrix[0].length; //4
    const rowLength = matrix.length; //2
    const transposedMatrix: number[][] = new Array(colLength).fill(undefined);
    for (let i = 0; i < transposedMatrix.length; i++) {
      transposedMatrix[i] = new Array(rowLength).fill(undefined);
    }
    for (let ii = 0; ii < rowLength; ii++) {
      const array = matrix[ii];
      for (let jj = 0; jj < colLength; jj++) {
        transposedMatrix[jj][ii] = array[jj];
      }
    }
    return transposedMatrix;
  }

  private createAvailableObjectTimeSeries(
    power: VppAnalyticsViewModelChartDataPower[],
    timeSeq: Date[],
    demandTypeModes: VppDemandTypes | VppModes,
    color: string,
  ) {
    if (isEmpty(power) || isEmpty(timeSeq) || demandTypeModes == null) return [];

    return power
      .filter((power) => power.status === VppUnitMetricKeyType.AVAILABLE && power.demandType === demandTypeModes)
      .filter((power) => isLengendEntry(power.data))
      .map((avail) => {
        const { data, group, order } = avail;
        const legendEntry = isLengendEntry(data);
        return {
          name: group + '-M' + order + '-Avail',
          showlegend: legendEntry,
          type: 'bar',
          marker: {
            color,
            line: {
              color: 'rgb(255,255,255)',
              width: BAR_BORDER_LINE_WIDTH,
            },
          },
          hovoron: 'fills',
          hoverinfo: 'name+y',
          hoverlabel: {
            bgcolor: color,
            bordercolor: 'black',
            font: {
              size: 18,
            },
          },
          x: timeSeq,
          offset: OFFSET,
          y: data.map((a, i) => {
            const value = parseFloat(Math.abs(a).toFixed(1));
            return value != 0 ? value : null;
          }),
          group_data: power,
        };
      });
  }

  private createDispatchObjectTimeSeries(
    power: VppAnalyticsViewModelChartDataPower[],
    timeSeq: Date[],
    demandTypeModes: VppDemandTypes | VppModes,
    color: string,
  ) {
    if (isEmpty(power) || isEmpty(timeSeq) || demandTypeModes == null) return [];
    return power
      .filter((power) => power.status === VppUnitMetricKeyType.DISPATCH && power.demandType === demandTypeModes)
      .filter((power) => isLengendEntry(power.data))
      .map((disp) => {
        const { data, group, order } = disp;
        const legendEntry = isLengendEntry(data);
        return {
          name: group + '-M' + order + '-Dis',
          showlegend: legendEntry,
          // legendgroup: 'unassociated',
          type: 'bar',
          marker: {
            color: color,
            line: {
              color: 'rgb(255,255,255)',
              width: BAR_BORDER_LINE_WIDTH,
            },
          },
          hovoron: 'fills',
          // hoverinfo: showlegend ? 'name+y' : 'none',
          hoverinfo: 'name+y',
          hoverlabel: {
            bgcolor: color,
            bordercolor: 'black',
            font: {
              size: 18,
            },
          },
          x: timeSeq,
          offset: OFFSET,
          y: data.map((a) => {
            // if (isVppDemandIncrease(this.demandType)) base[i] -= a;
            const value = parseFloat(Math.abs(a).toFixed(1));
            return value != 0 ? value : null;
          }),
          group_data: power,
        };
      });
  }

  private createLiveObjectTimeSeries(timeSeq: Date[], liveObjValues: number[]) {
    const liveObj = {
      name: 'Demand',
      type: 'scatter',
      mode: 'lines',
      line: { shape: 'hv', width: 2, color: 'black' },
      x: timeSeq ?? [],
      y: liveObjValues?.map((value) => parseFloat(value?.toFixed(2))) ?? [],
      hoverinfo: 'y+x+name',
      showlegend: false,
    };
    return liveObj;
  }

  private createMinObjectTimeSeries(timeSeq: Date[], vpp: Vpp, demandType: VppDemandTypes | VppModes) {
    let y: string[];
    const length = timeSeq.length;
    if (isVppDemandIncrease(demandType)) y = Array(length).fill(vpp.admtMinDemand);
    else if (isVppDemandReduction(demandType)) y = Array(length).fill(vpp.admtMaxDemand);
    else return null;

    const minObject = {
      name: 'ADMinD',
      type: 'scatter',
      mode: 'lines',
      line: { shape: 'hv', width: 1, color: 'red' },
      x: timeSeq ?? [],
      y,
      hoverinfo: 'none',
      showlegend: false,
    };
    return minObject;
  }

  // user input functions
  public async startDateChange(dateString: string): Promise<void> {
    const timezone = await this.timezoneService.timezone$.pipe(take(1)).toPromise();
    const epochSeconds = DateTime.fromISO(dateString, { zone: timezone }).toSeconds();
    // console.log(epochSeconds);
    this.userSelectPeriodStartEpoch.next(epochSeconds);
  }
  async nextDate(): Promise<void> {
    const timezone = await this.timezoneService.timezone$.pipe(take(1)).toPromise();

    const epoch = this.userSelectPeriodStartEpoch.value ?? DateTime.local().setZone(timezone).toSeconds();
    const nextPeriodEpoch = DateTime.fromSeconds(epoch, { zone: timezone })
      .plus({ [TimePeriodResolution.DAY]: 1 })
      .toSeconds();
    this.userSelectPeriodStartEpoch.next(nextPeriodEpoch);
  }
  async previousDate(): Promise<void> {
    const timezone = await this.timezoneService.timezone$.pipe(take(1)).toPromise();

    const epoch = this.userSelectPeriodStartEpoch.value ?? DateTime.local().setZone(timezone).toSeconds();
    const nextPeriodEpoch = DateTime.fromSeconds(epoch, { zone: timezone })
      .minus({ [TimePeriodResolution.DAY]: 1 })
      .toSeconds();
    this.userSelectPeriodStartEpoch.next(nextPeriodEpoch);
  }
  public setDemandTypeMode(demandTypeMode: VppDemandTypes | VppModes): void {
    this.overviewFacade.changeOverviewModeAndDemandType(demandTypeMode);
  }
  public refreshChartData(): void {
    this.userRefreshes.next();
  }
  private vppOperationHistoryApi(
    vppId: string,
    startUnixSeconds: number,
    endUnixSeconds: number,
  ): Promise<{ data: VppHistoryGetPayload }> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/vpps/${vppId}/analytics/?fromts=${startUnixSeconds * 1000}&tots=${endUnixSeconds * 1000}`,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    ) as Promise<{ data: VppHistoryGetPayload }>;
  }

  private handleError(err: any): Observable<VppAnalyticsViewModel> {
    const message =
      err instanceof HttpErrorResponse ? `${this.displayStrings.ErrorFetch} ${err.status} ${err.statusText}` : err;
    return of({
      status: VppModelStatus.ERROR,
      message,
      data: null,
    });
  }
}

export const isLengendEntry = function (array: number[]) {
  const someIsNotZero = array.some((item) => item !== 0);
  return someIsNotZero;
};
