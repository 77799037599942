import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { UnitBatteryStatusStateData, UnitBatteryStatusStore } from '../../services/units/unit-battery-status.store';
@Component({
  selector: 'app-battery-status',
  templateUrl: './battery-status.component.html',
  styleUrls: ['./battery-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatteryStatusComponent {
  unitBatteryStatusStore$: Observable<UnitBatteryStatusStateData>;
  constructor(private _unitBatteryStatusStore: UnitBatteryStatusStore) {
    this.unitBatteryStatusStore$ = this._unitBatteryStatusStore.unitBatteryStatusStore$;
  }
}
