import { AfterViewInit, Component, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { IsPlatformValues } from '@class/commons/is-platform-values';
import { IonTabs } from '@ionic/angular';
import { ToolbarInterface } from '../../classes/commons/toolbar-interface';

@Component({
  selector: 'app-bottom-toolbar',
  templateUrl: './bottom-toolbar.component.html',
  styleUrls: ['./bottom-toolbar.component.scss'],
})
export class BottomToolbarComponent extends IsPlatformValues implements AfterViewInit, OnDestroy {
  @Input() tabsData: Array<ToolbarInterface>;
  @Input() isUnitDeleted: boolean;
  @ViewChild('tabs', { static: false }) BottomTabs: IonTabs;

  private oldScrollY = 0;
  public isShowBottomTabBar = true;
  constructor(private renderer: Renderer2) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.IS_PLATFORM_MOBILE) {
      window.addEventListener('touchmove', this.touchEvent);
      this.renderer.addClass(this.BottomTabs.outlet.nativeEl.parentElement, 'static-tab');
    }
  }
  ngOnDestroy(): void {
    if (this.IS_PLATFORM_MOBILE) {
      window.removeEventListener('touchmove', this.touchEvent);
    }
  }
  touchEvent = (event: TouchEvent): void => {
    const newScrollY = event.touches[0].clientY;
    this.isShowBottomTabBar = this.oldScrollY < newScrollY;
    this.oldScrollY = newScrollY;
  };
}
