<ion-grid class="pt-0">
  <ion-row>
    <!-- plot figure area -->
    <ion-col class="pt-0" size-lg="9" size="12">
      <ion-card class="new-design-card m-0 pb-0" height="auto">
        <ion-grid class="p-0">
          <ion-row id="plot-opt-row">
            <div id="period-select">
              <app-input-select
                class="width-100-pct"
                ionItemExtraCss="ion-no-padding item-inner-padding-end-0 fs-12"
                [options]="periodSelection"
                placeholder="{{ 'SiteEnergy.SelectMetric' | translate }}"
                [value]="currentPeriodLength"
                (ionChange)="capturePeriodChange($event?.detail?.value)"
                [disabled]="disableButtons"
              ></app-input-select>
            </div>

            <div id="metrics-select">
              <ion-item
                [button]="true"
                [detail]="false"
                id="metrics-selection"
                lines="none"
                class="base-input width-100-pct"
              >
                <ion-label>{{ 'UnitPage.SelectMetrics' | translate }}</ion-label>
                <div slot="end" id="metrics-selection">
                  {{ selectedMetrics?.length > 0 ? selectedMetrics.length : 0 }} {{ 'UnitPage.Selected' | translate }}
                </div>
                <ion-icon slot="end" name="caret-down-sharp" class="fs-14"></ion-icon>
              </ion-item>

              <ion-modal trigger="metrics-selection" #modal>
                <ng-template>
                  <app-unit-metric-selection-typehead
                    class="ion-page"
                    [devicesAndControllersMetrics]="devicesAndControllersMetrics"
                    [selectedItems]="selectedMetrics"
                    (selectionChange)="metricSelectionChanged($event)"
                    (selectionCancel)="modal.dismiss()"
                  ></app-unit-metric-selection-typehead>
                </ng-template>
              </ion-modal>
            </div>

            <div id="break-div" *ngIf="isMobileView"></div>

            <div id="calendar-buttons-select">
              <ion-button
                *ngIf="!isMobileView"
                fill="clear"
                (click)="debounceOnNextOrPrev(-1)"
                color="button-primary"
                [disabled]="disableButtons || disablePrevious"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </ion-button>
              <div id="calendar-forms">
                <ion-item lines="none" class="ion-item-border-height width-100-pct">
                  <ion-text class="fs-14 fw-600">
                    {{ currentSelectedDate | dateLabel: currentPeriodLength }}
                  </ion-text>
                  <ion-datetime-button class="ml-5" slot="end" datetime="datetime-calendar"></ion-datetime-button>
                  <ion-popover [keepContentsMounted]="true" [showBackdrop]="false">
                    <ng-template>
                      <ion-datetime
                        id="datetime-calendar"
                        [presentation]="ionDatePickerPresentation"
                        [max]="ionMaxDate"
                        [min]="ionMinDate"
                        [isDateEnabled]="isFirstDayOfWeek"
                        [(ngModel)]="currentSelectedDate"
                        (ngModelChange)="dateChangedCalendar($event)"
                        [disabled]="disableButtons"
                        [showDefaultButtons]="true"
                      >
                      </ion-datetime>
                    </ng-template>
                  </ion-popover>
                </ion-item>
              </div>
              <ion-button
                fill="clear"
                *ngIf="!isMobileView"
                class="ion-padding-start"
                (click)="debounceOnNextOrPrev()"
                color="button-primary"
                [disabled]="disableButtons || disableNext"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </ion-button>
            </div>

            <div id="refresh-setting-buttons">
              <!-- TODO: will enable this when this is working -->
              <!-- <ion-button [disabled]="true" fill="outline">
                <ion-icon name="settings-sharp"></ion-icon>
              </ion-button> -->
              <div class="tooltip">
                <span class="tooltiptext">{{ 'UnitPage.Refresh' | translate }}</span>
                <ion-button
                  [disabled]="isDataMetricEmpty || showLoader"
                  id="refresh-button"
                  (click)="refresh()"
                  fill="outline"
                >
                  <ion-icon [ngClass]="{ rotating: rotation }" name="refresh"></ion-icon>
                </ion-button>
              </div>
            </div>
          </ion-row>

          <ion-row #analyticCard>
            <ion-col size="12">
              <ion-card id="plot-inner-card">
                <div [ngClass]="{ loader: showLoader }"></div>
                <div [ngClass]="{ opac: showLoader }" #performanceChart></div>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="isMobileView">
            <app-unit-analytic-legend class="width-100-pct" [info]="legendInfo"> </app-unit-analytic-legend>
          </ion-row>
        </ion-grid>
      </ion-card>
    </ion-col>
    <!-- legend and csv button -->
    <ion-col class="pt-0 pr-0" *ngIf="!isMobileView">
      <div class="legend-csv-div p-0 m-0 fullHeight">
        <ion-card class="new-design-card legend-card">
          <app-unit-analytic-legend [info]="legendInfo"> </app-unit-analytic-legend>
        </ion-card>
        <ion-button
          [disabled]="isDataMetricEmpty"
          class="csv-button p-0 m-0 width-100-pct fs-16 fw-600"
          color="button-primary"
          *ifHasPermission="[PermissionKey.UNIT_EXPORT_UNIT_DATA]"
          (click)="exportCSV()"
        >
          {{ 'UnitPage.DownloadCSV' | translate }}
        </ion-button>
      </div>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="isMobileView" class="p-5">
    <ion-button
      class="csv-button p-0 m-0 width-100-pct fs-16 fw-600"
      color="button-primary"
      *ifHasPermission="[PermissionKey.UNIT_EXPORT_UNIT_DATA]"
      [disabled]="isDataMetricEmpty"
      (click)="exportCSV()"
    >
      {{ 'UnitPage.DownloadCSV' | translate }}
    </ion-button>
  </ion-row>
</ion-grid>
