import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface CompositionBarData {
  mainHeading: string;
  mainValue: number;
  subHeading: string;
  subValue: number;
  chartParameters: {
    [index: string]: ChartParameter;
  };
}

interface ChartParameter {
  color: string;
  legend: string;
  value: number;
}

@Component({
  selector: 'app-vertical-composition-bars',
  templateUrl: './vertical-composition-bars.component.html',
  styleUrls: ['./vertical-composition-bars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalCompositionBars {
  @Input()
  get chartData() {
    return this._chartData;
  }
  set chartData(chartData: CompositionBarData) {
    this._chartData = chartData;
    this.chartParamsArray = Object.values(this.chartData.chartParameters);
    this.sidebarHeight = 50 * this.chartParamsArray.length + 'px';
    this.createVerticalCompositionBar();
  }
  private _chartData: CompositionBarData;

  verticalBarHeights: { [index: string]: string } = {};
  chartParamsArray: ChartParameter[];
  sidebarHeight: string = '100px';

  constructor() {}

  createVerticalCompositionBar(): void {
    let siteEnergyTotal = this.chartParamsArray.map((e) => e.value).reduce((acc, e) => acc + e);
    this.chartParamsArray.forEach((e) => {
      this.verticalBarHeights[e.legend] = (e.value / siteEnergyTotal) * 100 + '%';
    });
  }
}
