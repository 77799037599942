import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DropletService, DeviceState } from '../../../services/units/endpoints/devices.service';
import { Observable } from 'rxjs';
import { BrowserLogger } from '@class/core/browser-logger';
import { EmitEvent, Event, EventBusService } from '@service/core/event-bus.service';
import { ModalIdentifier } from '@class/commons/constants';

@Component({
  selector: 'app-device-detail-modal',
  templateUrl: './device-detail-modal.page.html',
  styleUrls: ['./device-detail-modal.page.scss'],
})
export class DeviceDetailModalPage {
  // component input properties via modal controller
  isUnitActive: boolean = false;
  vm$: Observable<DeviceState> = this._dropletService.vm$;
  constructor(
    private _viewCtrl: ModalController,
    private _dropletService: DropletService,
    private _eventBus: EventBusService,
  ) {}

  handleToolbarBackClick(): void {
    BrowserLogger.log('DeviceDetailModalPage.handleToolbarBackClick');
    this.dismiss();
  }

  private dismiss(): void {
    this._dropletService.acknowledgeError();
    this._viewCtrl.dismiss();
    this._eventBus.emit(new EmitEvent(Event.MODAL_CLOSED, ModalIdentifier.DEVICE_DETAILS));
  }
}
