import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { UnitCarbonSavingStateData, UnitCarbonSavingStore } from '@service/units/unit-carbon-saving.store';

@Component({
  selector: 'app-carbon-savings',
  templateUrl: './carbon-savings.component.html',
  styleUrls: ['./carbon-savings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarbonSavingsComponent {
  unitCarbonSavingStore$: Observable<UnitCarbonSavingStateData>;

  constructor(private _unitCarbonSavingStore: UnitCarbonSavingStore) {
    this.unitCarbonSavingStore$ = this._unitCarbonSavingStore.unitCarbonSavingStore$.pipe(
      filter((unitCarbonSaving) => unitCarbonSaving.data !== null),
    );
  }
}
