<ion-grid class="pt-0">
  <ion-row>
    <ion-col class="pt-0">
      <ion-searchbar
        class="ion-no-padding"
        showCancelButton="never"
        [(ngModel)]="search"
        placeholder="{{ 'General.Search' | translate }}"
        autocomplete="on"
        (keyup)="applyFilter($event.target.value)"
      ></ion-searchbar>
    </ion-col>
  </ion-row>

  <ion-card class="new-design-card ion-no-margin m-5">
    <ion-row class="ion-no-padding ion-no-margin" *ngIf="mqttData != null && mqttData != undefined">
      <ion-col class="ion-no-padding ion-no-margin ion-text-center">
        <ion-item lines="full" class="ion-text-center">
          <ion-label color="text-secondary">
            MQTT {{ 'UnitPage.ReceivedAt' | translate }}: {{ mqttData.momentTime }} -
            {{ unitsService.selectedUnit.timezone }}
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
    <div class="mat-elevation-z8 scroll-x-auto">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="status">
          <th class="width-3-5-pct" mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div [ngClass]="{ activityCircle: element.toggle, activityCircle2: !element.toggle }"></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="device">
          <th class="ion-text-left width-15-pct" mat-header-cell *matHeaderCellDef>
            {{ 'General.Device' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.deviceName }}</td>
        </ng-container>

        <ng-container matColumnDef="metric">
          <th class="ion-text-left width-15-pct" mat-header-cell *matHeaderCellDef>
            {{ 'General.Metric' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.metricName }}</td>
        </ng-container>

        <ng-container matColumnDef="key">
          <th class="ion-text-left width-25-pct" mat-header-cell *matHeaderCellDef>
            {{ 'General.Key' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{ element.key }}</td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th class="width-15-pct ion-text-center" mat-header-cell *matHeaderCellDef>
            {{ 'General.Value' | translate }}
          </th>
          <td class="ion-text-center" mat-cell *matCellDef="let element">{{ element.value }}</td>
        </ng-container>

        <ng-container matColumnDef="time">
          <th class="ion-text-center width-200-px" mat-header-cell *matHeaderCellDef>
            {{ 'General.DropletTime' | translate }}
          </th>
          <td class="ion-text-center" mat-cell *matCellDef="let element">{{ element.dropletTimeStampInMoment }}</td>
        </ng-container>

        <ng-container matColumnDef="updated">
          <th class="ion-text-center width-200-px pr-0" mat-header-cell *matHeaderCellDef>
            {{ 'UnitPage.ValueUpdated' | translate }}
          </th>
          <td class="ion-text-center pr-0" mat-cell *matCellDef="let element">{{ element.valueUpdatedTime }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="firstRowHeader"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ 'row-selected': row.selected }"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" pageSize="25"></mat-paginator>
    </div>
  </ion-card>
</ion-grid>
