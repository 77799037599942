import { Injectable } from '@angular/core';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';
import { isEmpty, uniq } from 'lodash';
import { BehaviorSubject, empty, merge, Observable } from 'rxjs';
import { map, scan, startWith } from 'rxjs/operators';
import { DeviceMqttKey, DEVICE_TYPE_TO_MQTT_KEY } from '../../../../classes/units/droplet/droplet-metric.model';
import { VppMetricMqtt } from '../../../../classes/vpp/vpp-mqtt.model';
import { VppUnit, VppUnitDeviceTypes } from '../../../../classes/vpp/vpp-unit.model';
import { VppUnitsService } from '../../units/vpp-units.service';

export interface VppStatusViewModel {
  loading: boolean;
  error: string;
  data: VppStatusUnitRow[];
}

export interface VppStatusUnitRow {
  activity$: Observable<boolean>;
  unitName: string;
  unitId: string;
  unitUuid: string;
  controlGroupNames: string[];
  gridPower$: Observable<number>;
  deviceInfo: Record<VppUnitDeviceTypes, VppStatusUnitRowDeviceClassInfo>;
  temperature$: Observable<number>;
  external_identifier: string;
  nmi: string;
}

export interface VppStatusUnitRowDeviceClassInfo {
  power$: Observable<number>;
  activeControls$: Observable<string[]>;
  count: number;
  soc$?: Observable<number>;
}
@Injectable({
  providedIn: 'root',
})
export class VppStatusFacadeService {
  public vm$ = new BehaviorSubject<VppStatusViewModel>({
    loading: true,
    error: null,
    data: null,
  });
  constructor(
    private vppUnitService: VppUnitsService,
    private _ngxMqttWrapper: NgxMqttWrapperService,
  ) {
    this.vppUnitService.units$.pipe(map((units) => this.addUnitsToViewMode(units))).subscribe(this.vm$);
  }

  private addUnitsToViewMode(units: VppUnit[]): VppStatusViewModel {
    try {
      const data = units?.map((unit) => this.adaptUnitToRow(unit)) ?? [];
      return {
        data,
        error: null,
        loading: null,
      };
    } catch (error) {
      console.error(error);
      return {
        error,
        loading: null,
        data: null,
      };
    }
  }

  private adaptUnitToRow(unit: VppUnit): VppStatusUnitRow {
    const { name, controlGroupNames, id, uuid, external_identifier, nmi } = unit;
    const gridPower$ = this.createObservableFromUnitMetrics(uuid, DeviceMqttKey.GRID);
    const deviceInfo = this.createDeviceInfo(unit);
    const temperature$ = this.createObservableFromUnitMetrics(uuid, DeviceMqttKey.TEMP);
    const activity$ = this.createActivityObservable(uuid);
    return {
      activity$,
      unitName: name,
      unitId: id,
      unitUuid: uuid,
      controlGroupNames,
      gridPower$,
      deviceInfo,
      temperature$,
      external_identifier,
      nmi,
    };
  }

  private createDeviceInfo(unit: VppUnit): Record<VppUnitDeviceTypes, VppStatusUnitRowDeviceClassInfo> {
    const reducer = (
      deviceInfo: Record<VppUnitDeviceTypes, VppStatusUnitRowDeviceClassInfo>,
      deviceClass: VppUnitDeviceTypes,
    ): Record<VppUnitDeviceTypes, VppStatusUnitRowDeviceClassInfo> => {
      if (deviceClass === VppUnitDeviceTypes.HYBRID) return deviceInfo; //don't create column for hybrid
      const power$ =
        deviceClass == VppUnitDeviceTypes.PV_INV || deviceClass == VppUnitDeviceTypes.BESS
          ? this.createObservableFromUnitMetrics(unit.uuid, DEVICE_TYPE_TO_MQTT_KEY[deviceClass])
          : this.createObservableFromVppUnitMetrics(unit.uuid, deviceClass);
      const activeControls$ = this.createControlStatusObservable(unit, deviceClass);
      const count = unit.deviceCounts?.[deviceClass] ?? 0;
      const soc$ =
        deviceClass == VppUnitDeviceTypes.BESS
          ? this.createObservableFromUnitMetrics(unit.uuid, DeviceMqttKey.SOC)
          : null;

      deviceInfo[deviceClass] = {
        power$,
        activeControls$,
        count,
        soc$,
      };
      return deviceInfo;
    };

    const deviceInfo: Record<VppUnitDeviceTypes, VppStatusUnitRowDeviceClassInfo> = Object.values(
      VppUnitDeviceTypes,
    ).reduce(reducer, {} as Record<VppUnitDeviceTypes, VppStatusUnitRowDeviceClassInfo>);
    return deviceInfo;
  }

  private createControlStatusObservable(unit: VppUnit, deviceType: VppUnitDeviceTypes): Observable<string[]> {
    if (isEmpty(unit)) return empty();
    const { uuid, controlGroupNames, devices, deviceCounts } = unit;
    // no point proceeding if any of the following is missing, or if unit does not contain device type
    if (uuid == null || isEmpty(controlGroupNames) || isEmpty(devices) || deviceCounts[deviceType] < 0) return empty();
    // list of all controls from all devices and their controls
    const unitControlsList = uniq(devices.flatMap((device) => device.controls));
    // list of mqtt keys to check control status
    const controlKeys = unitControlsList.flatMap((control) =>
      controlGroupNames.map((controlGroupName) => {
        return {
          controlName: control,
          mqttKey: `SWDINPV.CG[${controlGroupName}].DC[${deviceType}].${control}.Status`,
        };
      }),
    );
    return this._ngxMqttWrapper.observe(uuid + '/vpp_metrics').pipe(
      map((msg) => {
        const parsed = JSON.parse(msg.payload.toString())?.measurements;
        // grab control status from mqtt using list of keys; if status == 1.0 (active), add to list of active controls;
        const duplicatedKeys = controlKeys
          .filter((controlKey) => parsed?.[controlKey.mqttKey] > 0)
          .map((controlKey) => controlKey.controlName);
        // could contain duplicate active control from different control groups, only need one
        return uniq(duplicatedKeys);
      }),
    );
  }

  private createObservableFromVppUnitMetrics(unitUuid: string, deviceClass: VppUnitDeviceTypes): Observable<number> {
    if (unitUuid == null || deviceClass == null) return empty();
    return this._ngxMqttWrapper.observe(unitUuid + '/vpp_metrics').pipe(
      map((msg) => {
        const key = `SWDINPV.DC[${deviceClass}].TotW[Total]`;
        const parsed: VppMetricMqtt = JSON.parse(msg.payload.toString());
        return (parsed?.measurements?.[key] as number) ?? null;
      }),
    );
  }

  private createObservableFromUnitMetrics(unitUuid: string, key: DeviceMqttKey): Observable<number> {
    if (unitUuid == null || key == null) return empty();
    return this._ngxMqttWrapper.observe(unitUuid + '/metrics').pipe(
      map((msg) => {
        const parsed = JSON.parse(msg.payload.toString());
        return (parsed?.[key] as number) ?? null;
      }),
    );
  }

  private createActivityObservable(unitUuid: string): Observable<boolean> {
    if (unitUuid == null) return empty();
    return merge(
      this._ngxMqttWrapper.observe(unitUuid + '/metrics'),
      this._ngxMqttWrapper.observe(unitUuid + '/vpp_metrics'),
    ).pipe(
      scan((prev) => !prev, false),
      startWith(false),
    );
  }
}
