<ion-grid class="max-width-wide pt-0">
  <ion-row>
    <ion-col size="12" size-lg="4" class="pt-0 pb-10">
      <app-site-energy-card> </app-site-energy-card>
    </ion-col>

    <ion-col size="12" size-lg="8" class="pt-0 pb-10">
      <app-comparison-chart-card> </app-comparison-chart-card>
    </ion-col>
  </ion-row>
</ion-grid>
