import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-unit-alert-recipients-typehead',
  templateUrl: './unit-alert-recipients-typehead.component.html',
  styleUrls: ['./unit-alert-recipients-typehead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitAlertRecipientsTypeheadComponent implements OnInit {
  @Input() users: { email: string; id: string }[] = [];
  @Input() selectedUsers: { email: string; id: string }[] = [];
  @Output() selectionChange = new EventEmitter<{ email: string; id: string }[]>();

  @Input() title = 'Select Recipients';

  @Output() selectionCancel = new EventEmitter<void>();

  workingSelectedValues: { email: string; id: string }[] = [];
  filterText = '';

  ngOnInit() {
    this.workingSelectedValues = [...this.selectedUsers];
  }

  trackItems(index: number, item: { email: string; id: string }) {
    return item.id;
  }

  cancelChanges() {
    this.selectionCancel.emit();
  }

  confirmChanges() {
    this.selectionChange.emit(this.workingSelectedValues);
  }

  isChecked(value: { email: string; id: string }) {
    return this.workingSelectedValues.find((item) => item.id === value.id);
  }

  checkboxChange(ev) {
    const { checked, value } = ev.detail;

    if (checked) {
      this.workingSelectedValues = [...this.workingSelectedValues, value];
    } else {
      this.workingSelectedValues = this.workingSelectedValues.filter((item) => item.id !== value.id);
    }
  }
}
