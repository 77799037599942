import { IMqttMessage } from 'ngx-mqtt';

export const DROPLET_CONTROLLER_MQTT_REQUEST_TIMEOUT_MS = 30 * 1000; // 30 seconds

export interface DropletControllerInventoryRequest {
  dropletUuid: string;
  waiting: boolean;
  received: boolean;
  canBeProcessed: boolean;
  outOfSync: boolean;
  mqttMsg?: IMqttMessage; // keep a copy of the MQTT message once it is received
}
