<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{"CreatePortfolioModal.Title" | translate:titleParam}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="fh-row">
      <ion-row class="ion-margin-top ion-text-wrap">
        <ion-col class="ion-margin-top">
          <p *ngIf="selectedPortfolio">{{selectedPortfolio.name}}</p>
          <ion-item class="md-like-swidin-input ion-margin-bottom">
            <ion-input
              #portName="ngModel"
              name="portfolioName"
              [(ngModel)]="portfolioName"
              required
              label="{{'General.Name' | translate}}"
              labelPlacement="floating"
            >
            </ion-input>
          </ion-item>

          <app-address [isEditUnit]="false" [isUnitAddress]="unitCreation"></app-address>

          <ion-item class="md-like-swidin-input ion-margin-bottom ion-margin-top" *ngIf="isNmiEnabled()">
            <ion-input
              #portName="ngModel"
              name="unitNmi"
              [(ngModel)]="nmi"
              [pattern]="nmiRegex"
              required
              label="{{'UnitDetails.NMI' | translate}}"
              labelPlacement="floating"
              type="number"
            >
            </ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-self-end">
        <ion-col>
          <ion-button
            color="button-primary"
            (click)="handleSubmit()"
            size="large"
            [disabled]="isCreateButtonDisabled()"
            expand="block"
            >{{"CreatePortfolioModal.Title" | translate:titleParam}}</ion-button
          >
        </ion-col>
      </ion-row>
    </ion-row>
  </ion-grid>
</ion-content>
