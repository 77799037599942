import {
  Component,
  OnInit,
  ViewChild,
  Input,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-unit-tunnels',
  templateUrl: './unit-tunnels.component.html',
  styleUrls: ['./unit-tunnels.component.scss'],
})
export class UnitTunnelsComponent implements OnInit, OnChanges, AfterViewInit {
  displayedColumns: string[] = [
    'device',
    'port',
    'server_port',
    'request_time',
    'closing_time',
    'service',
    'protocol',
    'control',
    'comms',
    'multiple',
    'user',
  ];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  @Input() data;
  @Input() currentSubTab; // whether the tab index is on tunnels or not
  @Input() historyTunnel; //  whether the data is for history tunnel or active tunnel
  @Output() stopSelectedTunnel = new EventEmitter();

  datasource;

  constructor() {}

  ngOnInit() {
    if (!this.historyTunnel) {
      this.displayedColumns.push('action');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    if (changes.hasOwnProperty('data') || changes.hasOwnProperty('currentSubTab')) {
      this.datasource = new MatTableDataSource(this.data);
      if (this.currentSubTab === 2) {
        if (this.sort) {
          this.datasource.sort = this.sort;
        }
        if (this.paginator) {
          this.datasource.paginator = this.paginator;
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.currentSubTab === 2) {
      if (this.sort) {
        this.datasource.sort = this.sort;
      }
      if (this.paginator) {
        this.datasource.paginator = this.paginator;
      }
    }
  }

  applyFilter(filterValue: string) {
    this.datasource.filter = filterValue.trim().toLowerCase();
    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

  stopTunnel(tunnelLog) {
    this.stopSelectedTunnel.emit(tunnelLog);
  }
}
