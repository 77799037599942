<ion-item lines="none" [ngClass]="ionItemExtraCss">
  <ion-label position="stacked" class="mb-5 stack-label">{{ label }}</ion-label>
  <ion-textarea
    class="input-textarea"
    [ngClass]="{ 'input-error': invalid && (dirty || touched) }"
    cols="2"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (ionChange)="ionChange()"
    (ionBlur)="ionBlur()"
  ></ion-textarea>
</ion-item>

<app-input-validation-errors
  class="width-100-pct"
  [label]="label"
  [invalid]="invalid"
  [dirty]="dirty"
  [touched]="touched"
  [errors]="errors"
></app-input-validation-errors>
