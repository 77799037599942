export const VPP_OPERATION_GRAPH_PLOT_LAYOUT = {
  // bargap: 0.05,
  showlegend: true,
  barmode: 'stack',
  hovermode: 'closest',
  width: 1254,
  height: 768,
  margin: {
    l: 50,
    r: 30,
    t: 20,
    b: 30,
    pad: 5,
  },
  xaxis: {
    anchor: 'free',
    position: 0.04,
    dtick: 5,
    showgrid: false,
    zeroline: false,
    showticklabels: true,
    zerolinecolor: '#666666',
    zerolinewidth: 2,
  },
  yaxis: {
    showgrid: true,
    zeroline: false,
    showticklabels: true,
  },
  annotations: [
    {
      text: 'VirtualPowerPlant.Past',
      font: {
        size: 12,
        color: 'rgba(0,0,0,0.38)',
      },
      height: 16,
      x: 0,
      xref: 'paper',
      xanchor: 'left',
      xshift: -50,
      yref: 'paper',
      y: 0,
      yanchor: 'bottom',
      yshift: -20,
      showarrow: false,
    },
    {
      text: 'VirtualPowerPlant.Future',
      font: {
        size: 12,
        color: 'rgba(0,0,0,0.38)',
      },
      height: 16,
      x: 1,
      xref: 'paper',
      xanchor: 'left',
      yref: 'paper',
      y: 0,
      yanchor: 'top',
      xshift: -10,
      showarrow: false,
    },
    {
      text: 'kW', // VPPState.vpp_state.VppUnit index 2
      font: {
        size: 12,
        color: 'rgba(0,0,0,0.87)',
      },
      height: 16,
      x: 0,
      xref: 'paper',
      xanchor: 'left',
      xshift: -50,
      yref: 'paper',
      y: 1,
      yanchor: 'top',
      showarrow: false,
    },
    {
      text: 'VirtualPowerPlant.Max',
      font: {
        size: 12,
        color: 'rgba(0,0,0,0.87)',
      },
      height: 16,
      x: 0,
      xref: 'paper',
      xanchor: 'left',
      yref: 'y',
      y: 0,
      xshift: -50,
      showarrow: false,
    },
    {
      text: 'VirtualPowerPlant.Min',
      font: {
        size: 12,
        color: 'rgba(0,0,0,0.87)',
      },
      height: 16,
      x: 0,
      xref: 'paper',
      xanchor: 'left',
      yref: 'y',
      y: 0,
      xshift: -50,
      showarrow: false,
    },
    {
      text: '',
      font: {
        size: 12,
        color: 'rgba(0,0,0,0.87)',
      },
      height: 16,
      xref: 'x',
      x: 0,
      yref: 'paper',
      y: 0,
      yshift: -10,
      yanchor: 'bottom',
      showarrow: false,
    },
    // timezone annotation
    {
      text: '',
      font: {
        size: 12,
        color: 'rgba(0,0,0,1)',
      },
      height: 16,
      x: 0.5,
      xref: 'paper',
      xanchor: 'left',
      yanchor: 'bottom',
      yref: 'paper',
      y: -0.01,
      yshift: -20,
      showarrow: false,
    },
  ],
  shapes: [
    {
      line: {
        color: 'rgba(68, 68, 68, 0.5)',
        width: 2,
      },
      type: 'line',
      x0: 0,
      x1: 0,
      xref: 'x',
      y0: 1.5,
      y1: -0.5,
      yref: 'paper',
    },
  ],
};
