<ion-tabs #tabs [ngClass]="{ 'unit-deleted-state': isUnitDeleted }">
  <ion-row class="powered-by-logo ion-hide-lg-down">
    <ion-col size="12">
      <app-powered-by-footer></app-powered-by-footer>
    </ion-col>
  </ion-row>

  <ion-tab-bar slot="bottom" class="ion-no-border" [ngClass]="{ 'hide-tab-bar': !isShowBottomTabBar }">
    <ng-container *ngFor="let tab of tabsData">
      <ion-tab-button tab="{{ tab.name }}" *ifHasPermission="tab.permissions">
        <ion-icon [name]="tab.icon"></ion-icon>
        <ion-label>{{ tab.label | translate }}</ion-label>
      </ion-tab-button>
    </ng-container>
  </ion-tab-bar>
</ion-tabs>
