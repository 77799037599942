<ng-container *ngIf="endpoint && _endpointIndex != null; else loading">
  <ion-card class="ion-no-margin card-design">
    <!-- droplet inventory indicator -->
    <app-droplet-controller-inventory
      *ngIf="vm.enableInventory && isUnitActive"
      [droplet]="endpoint"
    ></app-droplet-controller-inventory>

    <!-- droplet firmware update button -->
    <app-droplet-firmware-update-button
      *ngIf="vm.firmwareUpdate && endpoint.overview.triggerFirmwareUpdate && isUnitActive"
      [endpointUuid]="endpoint.overview.uuid"
    ></app-droplet-firmware-update-button>

    <ion-card-header class="pb-0">
      <ion-card-title class="fw-700">{{ 'General.Droplet' | translate }} #{{ _endpointIndex + 1 }}</ion-card-title>
    </ion-card-header>

    <ion-list>
      <ion-item lines="inset">
        <ion-label>
          <h4>{{ 'General.EndpointId' | translate }}:</h4>
          <p class="fw-400 fs-11">{{ endpoint.overview.uuid }}</p>
        </ion-label>
      </ion-item>
      <ion-item lines="none">
        <app-mqtt-status
          [mqttKeys]="{ mqttKeys: endpoint.overview.statusKeys, dropletUuid: endpoint.overview.uuid }"
          [initialLastSeen]="endpoint.overview.lastSeenFromServer"
        ></app-mqtt-status>
      </ion-item>
    </ion-list>

    <ion-accordion-group [multiple]="true" class="ion-padding">
      <ng-container *ifHasPermission="[PermissionKey.UNIT_VIEW_ENDPOINT]">
        <ion-accordion *ngIf="vm.showAttributes">
          <ion-item slot="header" color="light">
            <ion-label>
              <h2 class="fw-600">{{ 'General.EndpointAttributes' | translate }}</h2>
            </ion-label>
          </ion-item>
          <div slot="content">
            <ion-list>
              <ng-container *ngFor="let attribute of endpoint.attributes | keyvalue; let lastAttribute = last">
                <ion-item [lines]="lastAttribute ? 'none' : 'inset'">
                  <ion-label class="ion-text-wrap">
                    <p class="fs-14 fw-600 text-dark m-0">{{ attribute.value.label }}:</p>
                    <p class="fs-13 fw-400 text-light m-0">{{ attribute.value.value }}</p>
                  </ion-label>
                </ion-item>
              </ng-container>
            </ion-list>
          </div>
        </ion-accordion>
      </ng-container>

      <ion-accordion>
        <ion-item slot="header" color="light">
          <ion-label *ngIf="mode === DropletItemMode.DEVICE">
            <h2 class="fw-600">{{ endpoint.overview.deviceNumber || 0 }} {{ 'General.Devices' | translate }}</h2>
          </ion-label>
          <ion-label *ngIf="mode === DropletItemMode.CONTROLLER">
            <h2 class="fw-600">{{ endpoint.controllers.length || 0 }} {{ 'UnitPage.Controllers' | translate }}</h2>
          </ion-label>
        </ion-item>

        <div slot="content">
          <!-- droplet devices -->
          <ion-list *ngIf="vm.showDevices">
            <ng-container *ngFor="let device of endpoint.devices; let lastDevice = last; let deviceIndex = index">
              <ion-item
                *ngIf="device.deviceOverview.id"
                [lines]="lastDevice ? 'none' : 'inset'"
                [ngClass]="{ 'selected-item': selectedItemIndex === deviceIndex }"
              >
                <ion-label
                  class="cursor-pointer ion-text-wrap fw-600 fs-14 text-dark"
                  [ngClass]="{ 'text-brand-primary': selectedItemIndex === deviceIndex }"
                  (click)="handleDeviceClick(deviceIndex)"
                >
                  {{ device.deviceOverview.deviceTypeName }}
                  {{
                    device.deviceOverview.deviceTypeName == device.deviceOverview.fullName
                      ? ''
                      : ' - ' + device.deviceOverview.fullName
                  }} </ion-label
                ><ion-icon class="device-item-chevron" name="chevron-forward-outline"></ion-icon>
              </ion-item>
            </ng-container>
          </ion-list>

          <!-- droplet controllers -->
          <ion-list *ngIf="vm.showControllers" class="pt-0">
            <ng-container
              *ngFor="let controller of endpoint.controllers; let lastController = last; let controllerIndex = index"
            >
              <ion-item
                [lines]="lastController ? 'none' : 'inset'"
                [ngClass]="{ 'selected-item': selectedItemIndex === controllerIndex }"
              >
                <ion-icon
                  name="warning-outline"
                  class="mr-5"
                  color="warning"
                  *ngIf="
                    (!controller.controllerExistOnDroplet ||
                      !controller.controllerVersionSync ||
                      !controller.controllerExistInDatabase ||
                      (controller.errorFromDroplet && controller.errorFromDroplet.length > 0)) &&
                    vm.inventoryReceived
                  "
                ></ion-icon>
                <ion-label
                  class="cursor-pointer ion-text-wrap fw-600 fs-14 text-dark"
                  [ngClass]="{ 'text-brand-primary': selectedItemIndex === controllerIndex }"
                  (click)="handleControllerClick(controllerIndex)"
                >
                  {{ controller.name }}
                </ion-label>
                <ion-icon class="device-item-chevron" name="chevron-forward-outline"></ion-icon>
              </ion-item>
            </ng-container>
          </ion-list>
        </div>
      </ion-accordion>
    </ion-accordion-group>

    <!-- Add Button -->
    <div *ngIf="vm.enableAdd && isUnitActive" class="ion-padding">
      <ion-button mode="ios" color="button-primary" class="width-100-pct m-0" (click)="handleAddButtonClick()">
        {{ vm.addLabel }}
      </ion-button>
    </div>
  </ion-card>
</ng-container>

<ng-template #loading>
  <app-skeleton-items [skeletonType]="SkeletonPlaceholder.CARD" [showCard]="true"></app-skeleton-items>
</ng-template>
