import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslationsService } from '../../services/common/translations.service';
import { AlertController, ModalController } from '@ionic/angular';
import { isVppDemandTypeFcas, isVppModeFcas } from '../../classes/vpp/vpp-demand-type-mode.model';
import { VppDispatchService } from '../../services/virtualPowerPlants/dispatch/vpp-dispatch.service';
import {
  adaptVppDispatchHistoryDisplayToModalData,
  VppDispatchHistoryDisplay,
  VppDispatchType,
  VppOperationDispatchModalType,
} from '../../classes/vpp/vpp-dispatch.model';
import { combineLatest, Subscription } from 'rxjs';
import { VppOverviewFacadeService } from '../../services/virtualPowerPlants/facades/overview/vpp-overview-facade.service';
import { VppOperationScheduleModalPage } from '../../pages/modals/vpp-operation-schedule-modal/vpp-operation-schedule-modal.page';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
@Component({
  selector: 'app-vpp-operation-tab-future-table',
  templateUrl: './vpp-operation-tab-future-table.component.html',
  styleUrls: ['./vpp-operation-tab-future-table.component.scss'],
})
export class VppOperationTabFutureTableComponent implements OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public readonly LOCALE_OPTION = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  };
  public readonly PermissionKey = PermissionKey;
  public readonly VppDispatchType = VppDispatchType;
  public readonly displayedColumns: string[] = [
    'group',
    'demandType',
    'order',
    'requestTime',
    'startTime',
    'endTime',
    'dispatch',
    'available',
    'status',
    'dispatchType',
    'user',
    'action',
  ];
  public dataSource: MatTableDataSource<VppDispatchHistoryDisplay>;
  private expandedElement: VppDispatchHistoryDisplay;
  private confirmAlertPopup: HTMLIonAlertElement;
  private subscription: Subscription;

  constructor(
    private trans: TranslationsService,
    private alertController: AlertController,
    private modalController: ModalController,
    private vppOverviewFacade: VppOverviewFacadeService,
    private vppDispatchService: VppDispatchService,
  ) {
    this.subscription = combineLatest([
      this.vppDispatchService.dispatchFuture$,
      this.vppOverviewFacade.vppOverviewModeDemandType$,
    ]).subscribe(([dispatches, modeAndDemandType]) => {
      const dispatchesOfCurrentModeDemandType = dispatches.filter((dispatch) => {
        //currently we want to show both fcas raise and lower demand responses together if fcas mode
        const isFcas = isVppModeFcas(modeAndDemandType.mode) && isVppDemandTypeFcas(dispatch.demandType);
        return isFcas || dispatch.demandType === modeAndDemandType.demandType;
      });
      this.dataSource = new MatTableDataSource(dispatchesOfCurrentModeDemandType);
      if (this.sort) {
        this.dataSource.sort = this.sort;
      }
      if (this.paginator) this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  rowClicked(element: VppDispatchHistoryDisplay): void {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  async closeConfirmAlertAndShowMsgAboutRollover(): Promise<void> {
    await this.confirmAlertPopup.dismiss();
    this.confirmAlertPopup = null;
    await this.presentAlertSimpleOk(this.trans.str.vpp.RollOver, '', this.trans.str.vpp.RollOverMsg);
  }

  async presentAlertSimpleOk(header: string, subheader: string, message: string): Promise<void> {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async presentCancelModal(row: VppDispatchHistoryDisplay): Promise<void> {
    try {
      const dispatch = adaptVppDispatchHistoryDisplayToModalData(row, VppOperationDispatchModalType.CANCEL);
      const modal = await this.modalController.create({
        component: VppOperationScheduleModalPage,
        cssClass: 'transparent-modal',
        backdropDismiss: false,
        componentProps: {
          dispatch,
          isDateReadonly: false,
        },
      });
      await modal.present();
    } catch (err) {
      console.error(err);
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
