<ng-container *ngIf="unitEnergyComparisonStore$ | async as comparisonChartData">
  <ion-card class="new-design-card ion-no-margin ion-no-padding">
    <div [ngClass]="{ loader: comparisonChartData.loader }"></div>
    <div [ngClass]="{ opac: comparisonChartData.loader }">
      <ion-card-header class="pb-0">
        <ion-card-title>
          {{ 'SiteEnergy.ComparePerformance' | translate }}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content class="ion-no-padding">
        <ion-grid class="ion-padding">
          <ion-row>
            <ion-col size="6" size-lg="4">
              <app-input-select
                ionItemExtraCss="ion-no-padding item-inner-padding-end-0 fs-12"
                [options]="availableChartMetrics"
                placeholder="{{ 'SiteEnergy.SelectMetric' | translate }}"
                [value]="comparisonChartData.chartMetrics"
                (ionChange)="metricChanged($event?.detail?.value)"
                [multiple]="true"
              ></app-input-select>
            </ion-col>
            <ion-col size="6" class="pl-10" size-lg="4">
              <app-input-select
                ionItemExtraCss="ion-no-padding item-inner-padding-end-0 fs-12"
                [options]="timePeriods"
                placeholder="{{ 'SiteEnergy.SelectPeriod' | translate }}"
                [value]="comparisonChartData.selectedTimePeriod"
                (ionChange)="periodChanged($event?.detail?.value)"
              ></app-input-select>
            </ion-col>
          </ion-row>
          <app-comparison-chart
            [chartData]="{ chartData: comparisonChartData.data, timePeriod: comparisonChartData.selectedTimePeriod }"
          ></app-comparison-chart>
        </ion-grid>
      </ion-card-content>
    </div>
  </ion-card>
</ng-container>
