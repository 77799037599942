<ion-label class="select-title">{{ label }}</ion-label>

<ion-item lines="none" class="pt-30" [ngClass]="ionItemExtraCss">
  <ion-icon class="select-input-left-icon" src="assets/icons/svg/alert-{{ value }}-icon.svg"></ion-icon>

  <ion-select
    interface="popover"
    class="width-100-pct select-input-with-icon-on-left"
    [placeholder]="placeholder"
    [ngClass]="{ 'input-error': invalid && (dirty || touched) }"
    (ionChange)="ionChange()"
    (ionBlur)="ionBlur()"
    [(ngModel)]="value"
  >
    <ion-select-option *ngFor="let option of options" [value]="option.value">{{ option.name }}</ion-select-option>
  </ion-select>
</ion-item>

<app-input-validation-errors
  class="width-100-pct"
  [label]="label"
  [invalid]="invalid"
  [dirty]="dirty"
  [touched]="touched"
  [errors]="errors"
></app-input-validation-errors>
