import { Component } from '@angular/core';
import { ModalIdentifier } from '@class/commons/constants';
import { BrowserLogger } from '@class/core/browser-logger';
import { ModalAction } from 'app/components/modal/modal-container/modal-container.component';

@Component({
  selector: 'app-controller-detail-modal',
  templateUrl: './controller-detail-modal.page.html',
  styleUrls: ['./controller-detail-modal.page.scss'],
})
export class ControllerDetailModalPage {
  readonly ModalAction = ModalAction;
  readonly ModalIdentifier = ModalIdentifier;
  isUnitActive: boolean = false;

  constructor() {
    BrowserLogger.log('ControllerDetailModalPage.constructor');
  }
}
