import { Component } from '@angular/core';
import {
  VppDemandTypeModeSelection,
  VppOperationTabSidebarFacadeService,
} from 'app/services/virtualPowerPlants/facades/operation-tab/vpp-operation-tab-sidebar-facade.service';
import { VppDemandTypes } from '../../classes/vpp/vpp-demand-type-mode.model';
import { VppModes } from '../../classes/vpp/vpp-demand-type-mode.model';
import { CommonService } from '../../services/common/common.service';
import { TranslationsService } from '../../services/common/translations.service';
import { VppOperationTabFacadeService } from '../../services/virtualPowerPlants/facades/operation-tab/vpp-operation-tab-facade.service';
import { VppOverviewFacadeService } from '../../services/virtualPowerPlants/facades/overview/vpp-overview-facade.service';
import { VirtualPowerPlantsService } from '../../services/virtualPowerPlants/virtual-power-plants.service';

@Component({
  selector: 'app-vpp-operation-sidebar',
  templateUrl: './vpp-operation-sidebar.component.html',
  styleUrls: ['./vpp-operation-sidebar.component.scss'],
})
export class VppOperationSidebarComponent {
  public demandType: VppModes | VppDemandTypes = VppDemandTypes.DEMAND_INCREASE;
  public operationState$ = this.vppOperationTabFacade.vppOperationState$;
  public streamNames = this.trans.str.vpp;
  public currentDemandTypeMode$ = this.vppOverviewFacade.vppOverviewModeDemandType$;
  public readonly demandTypeModeOptions: { readonly name: string; readonly value: VppDemandTypeModeSelection }[] = [
    {
      name: this.trans.instant('VirtualPowerPlant.DemandReduction'),
      value: VppDemandTypes.DEMAND_REDUCTION,
    },
    {
      name: this.trans.instant('VirtualPowerPlant.DemandIncrease'),
      value: VppDemandTypes.DEMAND_INCREASE,
    },
    {
      name: 'FCAS',
      value: VppModes.FCAS,
    },
  ];
  public vm$ = this.facade.viewModel$;
  constructor(
    public vppService: VirtualPowerPlantsService,
    public vppOperationTabFacade: VppOperationTabFacadeService,
    private facade: VppOperationTabSidebarFacadeService,
    public commonService: CommonService,
    private vppOverviewFacade: VppOverviewFacadeService,
    private trans: TranslationsService,
  ) {}

  demandTypeValueChange(value: VppDemandTypeModeSelection): void {
    this.facade.changeDemandTypeSelection(value);
  }
}
