<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirmChanges()">Done</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar [(ngModel)]="filterText"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-item *ngFor="let user of users; trackBy: trackItems | searchFilter: filterText">
    <ion-checkbox
      [value]="user"
      [checked]="isChecked(user)"
      (ionChange)="checkboxChange($event)"
      color="brand-primary"
      labelPlacement="end"
      justify="start"
    >
      {{ user.email }}
    </ion-checkbox>
  </ion-item>
</ion-content>
