import { Component, OnInit, Input, AfterViewInit, Renderer2, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'element-loader',
  templateUrl: './element-loader.component.html',
  styleUrls: ['./element-loader.component.scss'],
})
export class ElementLoaderComponent implements OnInit, AfterViewInit {
  @ViewChild('loaderParent', { static: false }) parent: ElementRef;
  @Input('timeout') _timeout_duration: any;
  @Input() text_msg: string;
  timed_out = false;
  timeout;
  constructor(private renderer: Renderer2) {}

  ngOnInit() {}
  ngAfterViewInit() {}
  resetLoader() {
    this.renderer.removeClass(this.parent.nativeElement, 'hidden');
    this.renderer.addClass(this.parent.nativeElement, 'visible');
  }
  clearLoader() {
    this.renderer.removeClass(this.parent.nativeElement, 'visible');
    this.renderer.addClass(this.parent.nativeElement, 'hidden');
  }
}
