import { Injectable } from '@angular/core';
import { States } from '@class/commons/constants';
import { generateHttpErrorMessage, HTTP_ERRORS_TRANSLATION_HEADING } from '@class/error/http-error-types';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '@service/common/api-wrapper.service';
import { TranslationsService } from '@service/common/translations.service';
import { BehaviorSubject, Observable, shareReplay, map, catchError, startWith, tap, of } from 'rxjs';

interface PortfolioUser {
  uuid: string;
  user_id: string;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  is_self: false;
  role_name: string;
  priority: number;
  level: number;
}
interface PortfolioUserListState {
  data: PortfolioUser[];
  priority: {
    currentUserLevel: number;
    currentUserPriority: number;
  };
  status: States;
  error?: string;
  message?: string;
}
const PORTFOLIO_USER_LIST_INITIAL_STATE: PortfolioUserListState = {
  status: States.INITIAL_LOADING,
  data: null,
  priority: {
    currentUserLevel: 0,
    currentUserPriority: 0,
  },
};

/**
 * sample data
  {
    "value": "9b956cc4-8931-11eb-b365-0242ac110003",
    "level": 1,
    "name": "Portfolio Manager (Basic)",
    "priority": 0
  }
 */
interface PortfolioInvitees {
  value: string;
  level: number;
  name: string;
  priority: number;
}
interface PortfolioInviteesState {
  data: PortfolioInvitees[];
  status: States;
  error?: string;
  message?: string;
}
const PORTFOLIO_INVITEES_INITIAL_STATE: PortfolioInviteesState = {
  status: States.INITIAL_LOADING,
  data: null,
};

interface InviteUser {
  name: string;
  email: string;
  referer_browser_skin: string;
  role: string;
}

@Injectable({
  providedIn: 'root',
})
export class PortfolioUsersStore {
  private _portfolioUserListStore = new BehaviorSubject<PortfolioUserListState>(PORTFOLIO_USER_LIST_INITIAL_STATE);
  portfolioUserList$ = this._portfolioUserListStore.asObservable();

  private _portfolioInviteesStore = new BehaviorSubject<PortfolioInviteesState>(PORTFOLIO_INVITEES_INITIAL_STATE);
  portfolioInvitees$ = this._portfolioInviteesStore.asObservable();

  constructor(
    private _api: ApiWrapper,
    private _translationsService: TranslationsService,
  ) {}

  getPortfolioUsersById(portfolioId: string): void {
    (
      this._api.handleObservableRequest({
        useAPI: AvailableAPI.SWITCHDIN,
        url: `/api/v1/portfolios/${portfolioId}/users/`,
        requestMethod: RequestMethod.GET,
        useHeader: UseHeaderType.AUTHORIZED_SWDIN,
        requestData: {},
      }) as Observable<PortfolioUser[]>
    )
      .pipe(
        shareReplay(),
        map((users) => {
          // have to determine what kind of user level/priority it had
          // our system is so good that it couldn't send this in a separate api or something
          // so has to iterate over users list
          // and have to look for user_self
          // and cherry on top, there can be more than one entries of the self user in user list
          // so have pick the highest level of priority and level
          // have to find this in order to restrict the user to not to add the user with higher perms than them
          // and can not remove user that have higher perms than them
          let priority = 0,
            level = 0;
          users.forEach((user) => {
            if (user.is_self) {
              priority = user.priority > priority ? user.priority : priority;
              level = user.level > level ? user.level : level;
            }
          });

          return {
            data: users,
            priority: {
              currentUserLevel: level,
              currentUserPriority: priority,
            },
            status: States.INITIAL_DATA_RECEIVED,
          };
        }),
        catchError((error) => this.handleError(error)),
        startWith(PORTFOLIO_USER_LIST_INITIAL_STATE),
        tap((usersData) => {
          this._portfolioUserListStore.next(usersData);
        }),
      )
      .subscribe();
  }
  getPortfolioInviteesById(portfolioId: string): void {
    (
      this._api.handleObservableRequest({
        useAPI: AvailableAPI.SWITCHDIN,
        url: `/api/v1/portfolios/${portfolioId}/invitees/`,
        requestMethod: RequestMethod.GET,
        useHeader: UseHeaderType.AUTHORIZED_SWDIN,
        requestData: {},
      }) as Observable<PortfolioInvitees[]>
    )
      .pipe(
        shareReplay(),
        map((invitees) => ({
          data: invitees,
          status: States.INITIAL_DATA_RECEIVED,
        })),
        // catchError((error) => this.handleError(error)),
        startWith(PORTFOLIO_INVITEES_INITIAL_STATE),
        tap((inviteesData) => {
          this._portfolioInviteesStore.next(inviteesData);
        }),
      )
      .subscribe();
  }
  updateUserListAfterRemovingUser(userUuidToBeRemoved: string) {
    const users = this._portfolioUserListStore.value.data.filter((user) => user.uuid !== userUuidToBeRemoved);

    this._portfolioUserListStore.next({
      ...this._portfolioUserListStore.value,
      data: [...users],
    });
  }
  // not using this as didn't wanna get sidetracked with invite users
  inviteUser(portfolioId: string, user: InviteUser) {
    return this._api.handleObservableRequest({
      useAPI: AvailableAPI.SWITCHDIN,
      url: `/api/v1/portfolios/${portfolioId}/invite_user/`,
      requestMethod: RequestMethod.POST,
      useHeader: UseHeaderType.AUTHORIZED_SWDIN,
      requestData: user,
    });
  }

  // Private Methods
  private handleError(err, state: States = States.INITIAL_ERROR): Observable<PortfolioUserListState> {
    const message = generateHttpErrorMessage(err, this._translationsService.instant(HTTP_ERRORS_TRANSLATION_HEADING));
    return of({
      status: state,
      priority: {
        currentUserLevel: 0,
        currentUserPriority: 0,
      },
      error: message.message,
      data: null,
    });
  }
}
