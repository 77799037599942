import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { VppFcasMarket } from '../../../classes/vpp/vpp-fcas-markets.model';
import { isVppDemandTypeDrm, isVppDemandTypeFcas } from '../../../classes/vpp/vpp-demand-type-mode.model';
import { VppDispatchModalData, VppOperationDispatchModalType } from '../../../classes/vpp/vpp-dispatch.model';
import {
  VppOperationScheduleModalFacadeService,
  VppModelStatus,
  VppOperationScheduleModalViewModel,
  ViewModalData,
} from '../../../services/virtualPowerPlants/facades/operation-tab/vpp-operation-schedule-modal-facade.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ROLLOVER_PERIOD_MIN } from '../../../services/virtualPowerPlants/rollover/vpp-roll-over.service';
import { MeritControlValueType } from '@class/vpp/vpp-control-group-schedule.model';

@Component({
  selector: 'app-vpp-operation-schedule-modal',
  templateUrl: './vpp-operation-schedule-modal.page.html',
  styleUrls: ['./vpp-operation-schedule-modal.page.scss'],
  providers: [VppOperationScheduleModalFacadeService],
})
export class VppOperationScheduleModalPage implements OnInit {
  dispatch: VppDispatchModalData;
  vm$: Observable<VppOperationScheduleModalViewModel>;
  readonly MINUTE_ARRAY = Array(12)
    .fill(0)
    .map((val, index) => val + index * ROLLOVER_PERIOD_MIN);
  readonly LOCALE_OPTION = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  };
  dispatchNow = false;

  // enums
  readonly VppModelStatus = VppModelStatus;

  readonly MeritControlValueType = MeritControlValueType;

  // convenience variables
  isDRM = true;
  isFCAS = true;
  isDateReadOnly = true;
  isCancelDispatch = false;

  constructor(
    private viewCtrl: ModalController,
    private modalFacade: VppOperationScheduleModalFacadeService,
  ) {}

  ngOnInit(): void {
    this.vm$ = this.modalFacade.vm$;
    this.modalFacade.setDispatchData(this.dispatch);
    this.isDRM = isVppDemandTypeDrm(this.dispatch.demandType);
    this.isFCAS = isVppDemandTypeFcas(this.dispatch.demandType);
    this.isDateReadOnly = this.dispatch.modalType !== VppOperationDispatchModalType.NOW_DISPATCH;
    this.isCancelDispatch = this.dispatch.modalType === VppOperationDispatchModalType.CANCEL;
    this.modalFacade.dismiss$.pipe(take(1)).subscribe(() => {
      this.dismiss();
    });
  }

  async dismiss(data = null): Promise<void> {
    await this.viewCtrl.dismiss(data);
  }

  onStartDateChange(startDate: string, timezone: string): void {
    if (startDate == null) return;
    this.modalFacade.setStartTime(startDate, timezone);
  }
  onStartDateNowChange(value: boolean, timezone: string): void {
    this.modalFacade.toggleStartTimeNow(value, timezone);
  }
  onEndDateChange(endDate: string, timezone: string): void {
    if (endDate == null) return;
    this.modalFacade.setEndTime(endDate, timezone);
  }
  onFcasMarketChange(fcasMarkets: VppFcasMarket[]): void {
    this.modalFacade.setFcasMarkets(fcasMarkets);
  }
  onControlPriorityChange(controlPriority: number): void {
    this.modalFacade.setControlPriority(controlPriority);
  }
  onControlValueToggleChange(controlValue: boolean): void {
    const controlVal = controlValue ? 'true' : 'false';
    this.modalFacade.setControlValue(controlVal);
  }
  onControlValueNumericStringChange(controlValue: number | string): void {
    this.modalFacade.setControlValue(controlValue);
  }
  scheduleDispatch(): void {
    this.modalFacade.sendDispatch();
  }
  cancelDispatch(): void {
    this.modalFacade.cancelDispatch();
  }
  isScheduleButtonDisable(data: ViewModalData): boolean {
    return (
      !data.startTime ||
      !data.endTime ||
      data.controlPriority === null ||
      (this.isFCAS && data?.fcasMarkets?.selected?.length <= 0) ||
      (data.controlType && data.controlValue === null)
    );
  }
  fcasMarketCompareFunc(marketB: VppFcasMarket, marketList: VppFcasMarket | VppFcasMarket[]): boolean {
    // marketList may be an array, if multiple="true"
    if (Array.isArray(marketList)) {
      return marketList.find((market) => market.uuid === marketB.uuid) != null;
    }
    // fallback to single element comparison
    return marketList.uuid === marketB.uuid;
  }
}
