import { Injectable } from '@angular/core';
import { UnitMqttStore } from './unit-mqtt.store';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { DeviceMqttKey } from '@class/units/droplet/droplet-metric.model';
import { UnitStore } from './unit.store';
import { Unit } from '@class/commons/unit.model';
import { DateTime } from 'luxon';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '@service/common/api-wrapper.service';

const PRECISION = 1;

export interface UnitPowerFlowLiveStream {
  solar: number;
  grid: number;
  storage: number;
  stateOfCharge: number;
  load: number;
  gridToLoad: number;
  solarToLoad: number;
  solarToGrid: number;
  solarToStorage: number;
  storageToLoad: number;
  gridToStorage: number;
  isGridIdle: boolean;
  isSolarIdle: boolean;
  isStorageIdle: boolean;
  batteryIcon: string;
  offGridView: boolean;
  lastUpdated: string;
  homeUsage: number;
  consumedTodayValue: number;
  storedTodayValue: number;
  generatedTodayValue: number;
}

const INITIAL_UNIT_POWER_FLOW_LIVE_STREAM = {
  solar: 0,
  grid: 0,
  storage: 0,
  stateOfCharge: 0,
  load: 0,
  gridToLoad: 0,
  solarToLoad: 0,
  solarToGrid: 0,
  solarToStorage: 0,
  storageToLoad: 0,
  gridToStorage: 0,
  isGridIdle: true,
  isSolarIdle: true,
  isStorageIdle: true,
  batteryIcon: '/assets/icons/svg/battery-5-percent.svg',
  offGridView: false,
  lastUpdated: '',
  homeUsage: 0,
  consumedTodayValue: 0,
  storedTodayValue: 0,
  generatedTodayValue: 0,
};

/**
 * Sample
 * {
    "uuid": "442aa842-5f6b-11e9-b2f3-0242ac110003",
    "name": "Clay's House",
    "history": {
        "solar": 22.89,
        "load": 15.29,
        "grid_import": 3.96,
        "grid_export": -8.15,
        "battery_charging": 3.46,
        "battery_discharging": -0.03,
        "solar2load": 11.37,
        "storage2load": 0.03,
        "solar2storage": 3.37,
        "grid2load": 3.9,
        "solar2grid": -8.15,
        "grid2storage": 0.06,
        "storage2grid": 0
    }
  }
 */
export interface UnitHistory {
  uuid: string;
  name: string;
  history: {
    solar: number;
    load: number;
    grid_import: number;
    grid_export: number;
    battery_charging: number;
    battery_discharging: number;
    solar2load: number;
    storage2load: number;
    solar2storage: number;
    grid2load: number;
    solar2grid: number;
    grid2storage: number;
    storage2grid: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class UnitPowerFlowStreamSvgStore {
  private _unitPowerFlowStreamStore = new BehaviorSubject<UnitPowerFlowLiveStream>(null);
  unitPowerFlowStreamStore$ = this._unitPowerFlowStreamStore.asObservable();

  constructor(
    private _unitMqttStore: UnitMqttStore,
    private _unitStore: UnitStore,
    private _api: ApiWrapper,
  ) {
    const streamValues$ = combineLatest([
      this._unitMqttStore.unitMqttStore$,
      this._unitStore.unit$.pipe(distinctUntilKeyChanged('data', (prev, curr) => prev?.uuid === curr?.uuid)),
    ]).pipe(
      distinctUntilChanged(),
      filter(([unitMqttData, unit]) => unitMqttData !== null && unit.data !== null),
      map(([unitMqttData, unit]) => this.svgCalculation(unitMqttData, unit.data)),
    ) as Observable<UnitPowerFlowLiveStream>;

    const history$ = this._unitStore.unit$.pipe(
      filter((unit) => unit.data !== null),
      distinctUntilKeyChanged('data', (prev, curr) => prev.uuid === curr.uuid),
      switchMap((unit) => this.getUnitTodayValues(unit.data)),
      map((historyData) => {
        const { load, solar2storage, grid2storage, solar } = historyData.history;
        return {
          consumedTodayValue: parseFloat(load?.toFixed(2)),
          storedTodayValue: parseFloat((solar2storage + grid2storage)?.toFixed(2)),
          generatedTodayValue: parseFloat(solar?.toFixed(2)),
        };
      }),
      startWith({
        consumedTodayValue: 0,
        storedTodayValue: 0,
        generatedTodayValue: 0,
      }),
    );

    const streaming$ = combineLatest([streamValues$, history$]).pipe(
      distinctUntilChanged(),
      map(([streamValues, history]) => ({ ...streamValues, ...history })),
    );

    streaming$
      .pipe(
        filter((streamData) => streamData !== null),
        startWith(INITIAL_UNIT_POWER_FLOW_LIVE_STREAM),
        tap((streamData) => {
          this._unitPowerFlowStreamStore.next(streamData);
        }),
      )
      .subscribe();
  }

  // private methods
  private svgCalculation(mqttMessage, unit: Unit): Partial<UnitPowerFlowLiveStream> {
    const offGridView = unit.isOffGrid;

    const timeStamp = (mqttMessage['droplet_timestamp'] as number) * 1000;
    const lastUpdatedDate = DateTime.fromMillis(timeStamp, {
      zone: unit.timezone,
    });
    // get difference between now and lastUpdatedDate time in seconds
    const diff = DateTime.local({
      zone: unit.timezone,
    }).diff(lastUpdatedDate, 'seconds').seconds;

    const lastUpdated = `${lastUpdatedDate.toRelative()} - ${lastUpdatedDate.toLocaleString(
      DateTime.DATETIME_FULL_WITH_SECONDS,
    )}`;

    // var x = 0.2 + 0.1;         // x will be 0.30000000000000004
    // Just to avoid the above problem, have to set the float to max 2 numbers after decimal point

    let storageToLoad = 0.0;
    let solarToStorage = 0.0;
    let gridToStorage = 0.0;
    let solarToGrid = 0.0;

    let solar = 0.0;
    let grid = 0.0;
    let storage = 0.0;
    let stateOfCharge = 0.0;
    let load = 0.0;
    let batteryIcon = this.chooseBatterySvgFromSoC(stateOfCharge);

    let solarToLoad = 0.0;
    let gridToLoad = 0.0;

    let isGridIdle = true,
      isStorageIdle = true,
      isSolarIdle = true;

    // only calculate values if the last update was within the last 5 minutes
    // 300 seconds
    if (diff <= 300) {
      // solar power generated (always nonnegative)
      solar = mqttMessage[DeviceMqttKey.SOLAR] ? parseFloat(mqttMessage[DeviceMqttKey.SOLAR].toFixed(PRECISION)) : 0.0;

      // power from grid, positive value means importing from grid, negative value means we are exporting to grid
      grid = mqttMessage[DeviceMqttKey.GRID] ? parseFloat(mqttMessage[DeviceMqttKey.GRID].toFixed(PRECISION)) : 0.0;

      // load power (always nonnegative)
      load = mqttMessage[DeviceMqttKey.LOAD] ? parseFloat(mqttMessage[DeviceMqttKey.LOAD].toFixed(PRECISION)) : 0.0;

      // between 0 and 100 - not used in the calculations, just needed for the svg
      stateOfCharge = mqttMessage[DeviceMqttKey.SOC] ? mqttMessage[DeviceMqttKey.SOC] : 0;

      // update battery icon percentage
      batteryIcon = this.chooseBatterySvgFromSoC(stateOfCharge);

      // power flow from battery, a positive value means the battery is charging,
      // negative value means we are either using battery power to satisfy load or exporting to the grid
      storage = mqttMessage[DeviceMqttKey.BESS] ? parseFloat(mqttMessage[DeviceMqttKey.BESS].toFixed(PRECISION)) : 0.0;

      // First, PV -> load
      solarToLoad = parseFloat(Math.min(solar, load).toFixed(PRECISION));

      // Second, Battery -> Load
      if (storage < 0) {
        // discharging
        // Battery -> load
        storageToLoad = parseFloat(
          Math.min(-1 * storage, parseFloat((load - solarToLoad).toFixed(PRECISION))).toFixed(PRECISION),
        );
      }

      // Grid -> Load
      gridToLoad = Math.max(
        Math.min(
          grid,
          parseFloat((parseFloat((load - solarToLoad).toFixed(PRECISION)) - storageToLoad).toFixed(PRECISION)),
        ),
        0.0,
      );
      gridToLoad = parseFloat(gridToLoad.toFixed(PRECISION));

      // If PV is generating and battery charging from pv
      if (storage > 0) {
        // charging
        if (solar > load) {
          // first charging from pv
          solarToStorage = parseFloat((solar - solarToLoad).toFixed(PRECISION));
          solarToStorage = Math.min(storage, solarToStorage);
          solarToStorage = parseFloat(Math.max(solarToStorage, 0.0).toFixed(PRECISION));
        }
        // Battery is charging from pv as well as grid or just from grid, then we come here
        if (storage > solarToStorage) {
          // charging from grid
          gridToStorage = parseFloat((grid - gridToLoad).toFixed(PRECISION));
          gridToStorage = Math.max(gridToStorage, 0.0);
        }
      } else {
        // Battery discharging
        if (gridToLoad === 0) {
          // Only if there is no flow from grid -> load
          // Note that batteryTotalPower is negative here
          gridToStorage = parseFloat((storage + storageToLoad).toFixed(PRECISION));
          // Account for errors where it appears as though storage is exporting to the generator

          if (gridToStorage < 0 && offGridView) {
            storageToLoad -= gridToStorage;
            gridToStorage = 0;
          }
        }
      }
      // Only residuals from pv to grid
      solarToGrid = parseFloat(
        (parseFloat((solar - solarToLoad).toFixed(PRECISION)) - solarToStorage).toFixed(PRECISION),
      );
      solarToGrid = Math.max(solarToGrid, 0.0);
      solarToGrid = parseFloat(solarToGrid.toFixed(PRECISION));

      if (gridToLoad !== 0 || gridToStorage !== 0 || solarToGrid !== 0 || Number(grid) !== 0) {
        isGridIdle = false;
      } else {
        isGridIdle = true;
      }
      if (gridToStorage !== 0 || solarToStorage !== 0 || storageToLoad !== 0 || Number(storage) !== 0) {
        isStorageIdle = false;
      } else {
        isStorageIdle = true;
      }
      if (solarToStorage !== 0 || solarToLoad !== 0 || solarToGrid !== 0 || Number(solar) !== 0) {
        isSolarIdle = false;
      } else {
        isSolarIdle = true;
      }
    }

    return {
      solar: parseFloat(solar.toFixed(PRECISION)),
      grid: parseFloat(grid.toFixed(PRECISION)),
      storage: parseFloat(storage.toFixed(PRECISION)),
      stateOfCharge,
      load: parseFloat(load.toFixed(PRECISION)),
      gridToLoad,
      solarToLoad,
      solarToGrid,
      solarToStorage,
      storageToLoad,
      gridToStorage,
      isGridIdle,
      isSolarIdle,
      isStorageIdle,
      batteryIcon,
      offGridView,
      lastUpdated,
      homeUsage: gridToLoad + solarToLoad + storageToLoad,
    };
  }
  private chooseBatterySvgFromSoC(stateOfCharge: number): string {
    let batteryIcon = '/assets/icons/svg/battery-5-percent.svg';
    if (stateOfCharge > 87.5) {
      batteryIcon = 'assets/icons/svg/battery-full.svg';
    } else if (stateOfCharge > 62.5) {
      batteryIcon = '/assets/icons/svg/battery-3-4.svg';
    } else if (stateOfCharge > 37.5) {
      batteryIcon = '/assets/icons/svg/battery-2-4.svg';
    } else if (stateOfCharge > 12.5) {
      batteryIcon = '/assets/icons/svg/battery-1-4.svg';
    } else {
      batteryIcon = '/assets/icons/svg/battery-5-percent.svg';
    }
    return batteryIcon;
  }
  private getUnitTodayValues(unit: Unit): Observable<UnitHistory> {
    const unitTz = unit.timezone;
    const start = DateTime.local().setZone(unitTz).startOf('day').valueOf();
    const end = DateTime.local().setZone(unitTz).endOf('day').valueOf();

    return this._api.handleObservableRequest({
      useAPI: AvailableAPI.SWITCHDIN,
      url: `/api/v1/units/${unit.id}/history/?fromts=${start}&tots=${end}`,
      requestMethod: RequestMethod.GET,
      useHeader: UseHeaderType.AUTHORIZED_SWDIN,
      requestData: {},
    }) as Observable<UnitHistory>;
  }
}
