import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import { UnitsService } from '../../../services/units/units.service';
import {
  AlertDefinition,
  AlertTypes,
  AlertPeriods,
  AlertExpressions,
  NonCustomAlertDefinition,
  CategoryNameValues,
} from '../../unit-view/unit-alerts/alert-definitions-list/alert-definition.model';
import { Observable, Subscription, filter, map } from 'rxjs';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { UnitUserStore } from '@service/units/unit-user.store';

@Component({
  selector: 'app-add-edit-alert-definition-modal',
  templateUrl: './add-edit-alert-definition-modal.page.html',
  styleUrls: ['./add-edit-alert-definition-modal.page.scss'],
})
export class AddEditAlertDefinitionModalPage implements OnInit, OnDestroy {
  alertTypes = AlertTypes;
  alertPeriods = AlertPeriods;
  alertExpressions = AlertExpressions;

  // Providing an input alert definition tells the component you want to edit
  @Input() alertDefinition: AlertDefinition;

  editMode = false;

  unitEndpointsSub: Subscription;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metrics: any;

  categories = CategoryNameValues;

  unitUserList$: Observable<{ email: string; id: string }[]>;

  constructor(
    private modalController: ModalController,
    private unitsService: UnitsService,
    private alertsService: AlertsService,
    private _unitUserStore: UnitUserStore,
  ) {
    this.unitUserList$ = this._unitUserStore.unitUserList$.pipe(
      filter((unitUsersData) => unitUsersData.data !== null),
      map((unitUsersData) => {
        return unitUsersData.data.filter((user) => user.user_email?.length > 0);
      }),
      map((users) => users.map((user) => ({ email: user.user_email, id: user.user_id }))),
      // filter duplicates
      map((users) => users.filter((user, index, self) => self.findIndex((u) => u.email === user.email) === index)),
    );
  }

  ngOnInit() {
    if (this.alertDefinition) {
      this.editMode = true;
    } else {
      this.alertDefinition = new AlertDefinition();
      this.alertDefinition.unit = this.unitsService.selectedUnit.id;
    }

    this.metrics = this.unitsService.unitEndpoints.alertMetrics;
  }

  ngOnDestroy() {
    if (this.unitEndpointsSub) this.unitEndpointsSub.unsubscribe();
  }

  submit(): void {
    const alertDef: AlertDefinition | NonCustomAlertDefinition =
      this.alertDefinition.category !== 'Custom'
        ? AlertDefinition.getNonCustomAlertDefinition(this.alertDefinition)
        : this.alertDefinition;

    if (this.editMode) {
      this.alertsService.updateDefinition(alertDef);
    } else {
      this.alertsService.addDefinition(alertDef);
    }
    this.modalController.dismiss();
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  metricSelectionChanged(selectedMetric, modal: IonModal): void {
    this.alertDefinition.metric = selectedMetric;
    this.alertDefinition.metric_key = this.alertDefinition.metric.metricKey;
    if (this.alertDefinition.metric.is_iec61850) {
      this.alertDefinition.iec61850_metric_id = this.alertDefinition.metric.id;
    } else {
      this.alertDefinition.metric_id = this.alertDefinition.metric.id;
    }
    modal.dismiss();
  }

  userSelectionChanged(selectedUsers: { email: string; id: string }[], modal: IonModal): void {
    this.alertDefinition.recipients = [...selectedUsers];
    modal.dismiss();
  }
}
