import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IsPlatformValues } from '@class/commons/is-platform-values';
import { ModalController, LoadingController, AlertController, IonicSlides } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProgramsWizard, ControllerAction, Slides } from '../../../classes/installer/program-wizards-types';
import { TranslationsService } from '../../../services/common/translations.service';
import { UnitsService } from '../../../services/units/units.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { PermissionsService } from '@service/permissions/permissions.service';

@Component({
  selector: 'app-sapn-wizard',
  templateUrl: './sapn-wizard.page.html',
  styleUrls: ['./sapn-wizard.page.scss'],
})
export class SAPNWizardPage extends IsPlatformValues implements OnInit {
  @Input() program: ProgramsWizard;
  @Input() endpointUUID: string;
  @ViewChild('slides')
  slides: ElementRef | undefined;

  sapnSlides = {
    WELCOME: {
      heading: 'welcome',
      index: 0,
      skipOnBack: false,
      skipToSlide: null,
      showBackButton: false,
      isFirstSlide: true,
      isLastSlide: false,
    },
    AGENT: {
      heading: 'select agent',
      index: 1,
      skipOnBack: false,
      skipToSlide: 0,
      skipToSlideName: 'WELCOME',
      showBackButton: true,
      isFirstSlide: false,
      isLastSlide: false,
    },
  };
  currentSlide: Slides;
  agentUUID: string;
  nmi: string;
  programAgentsOptions = {
    cssClass: 'program-agents',
  };
  deviceMessage: string;
  updateUnitDone: BehaviorSubject<boolean>;
  controllerActionDone: BehaviorSubject<boolean>;
  updateUnitSub: Subscription;
  controllerActionSub: Subscription;

  constructor(
    private permissionsService: PermissionsService,
    private viewCtrl: ModalController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private unitsService: UnitsService,
    private trans: TranslationsService,
  ) {
    super();
    this.currentSlide = this.sapnSlides.WELCOME;

    this.nmi = this.unitsService.selectedUnit['nmi'];
  }

  submit() {
    const updateUnitData = {
      ...this.unitsService.selectedUnit,
      ...{
        program: this.program.uuid,
        nmi: this.nmi,
        agent: this.agentUUID,
      },
    };

    const controllerData = this.prepareControllerData();

    this.alertController
      .create({
        header: 'Smarter Home Program',
        message: controllerData.message,
        buttons: [
          {
            text: this.trans.instant('General.OK'),
            handler: () => {
              this.submitHandler(updateUnitData, controllerData);
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  async submitHandler(updateUnitData, controllerData) {
    // Update unit with program, nmi, and agent
    await this.updateUnit(updateUnitData);

    this.controllerHandler(controllerData);
  }

  controllerHandler(controllerData) {
    const { endpoint_info, controller_info, status, controllerID, updateControllerData } = controllerData;

    const createControllerData = { endpoint_info, controller_info };

    // Controller Actions
    // There are 3 options
    switch (status) {
      // 1. Do nothing, either there are no devices to control or all devices already have a controller
      // need to next the behavior subject with true to close the wizard
      case ControllerAction.NONE:
        this.controllerActionDone.next(true);
        break;
      // 2. Update an existing controller
      case ControllerAction.UPDATE:
        this.updateProgramController(controllerID, updateControllerData);
        break;
      // 3. Create new controller
      case ControllerAction.CREATE:
        this.createProgramController(createControllerData);
        break;
    }
  }

  private async updateUnit(updateUnitData) {
    if (!this.permissionsService.any([PermissionKey.UNIT_CHANGE_UNIT])) {
      return;
    }
    // const loader = await this.loadingController.create({ message: 'Updating your unit details now' });
    // await loader.present();
    await this.unitsService
      .updateUnitDetails(this.unitsService.selectedUnit.id, updateUnitData)
      .then((res) => {
        this.unitsService.selectedUnit = res.data;
        // Test a failed update unit details call by un-commenting the below line
        // throw new Error('Update Unit Failed');
        this.updateUnitDone.next(true);
      })
      .catch(() => {
        this.handleErrorAlert(
          null,
          'Your unit was not updated correctly. You will be returned to the programs screen, please try again. If this issue persists please contact SwitchDin support.',
        );
      });
  }

  private createProgramController(createControllerData) {
    this.loadingController.create({ message: 'Creating your controller' }).then((loader) => {
      loader.present();
      this.unitsService
        .createNewEndpointController(createControllerData)
        .then(() => {
          loader.dismiss();
          // Test a failed create controllers call by un-commenting the below line
          // throw new Error('Create Controller Failed');
          this.controllerActionDone.next(true);

          this.unitsService.getEndpointsOfSelectedUnit();
        })
        .catch(() => {
          this.handleErrorAlert(
            loader,
            'Your controller was not added correctly. You will be returned to the programs screen, please try again. If this issue persists please contact SwitchDin support.',
          );
        });
    });
  }

  private updateProgramController(controllerID, updateControllerData) {
    this.loadingController.create({ message: 'Updating your controller' }).then((loader) => {
      loader.present();
      this.unitsService
        .updateEndpointController(controllerID, updateControllerData)
        .then(() => {
          loader.dismiss();
          // Test a failed create controllers call by un-commenting the below line
          // throw new Error('Update Controller Failed');
          this.controllerActionDone.next(true);

          this.unitsService.getEndpointsOfSelectedUnit();
        })
        .catch(() => {
          this.handleErrorAlert(
            loader,
            'Your controller was not updated correctly. You will be returned to the programs screen, please try again. If this issue persists please contact SwitchDin support.',
          );
        });
    });
  }

  private handleErrorAlert(loader, message) {
    loader.dismiss();
    this.alertController
      .create({
        header: 'Smarter Home Program',
        message,
        buttons: [
          {
            text: this.trans.instant('General.OK'),
            handler: () => {
              this.dismiss();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  ngOnInit() {
    this.updateUnitDone = new BehaviorSubject(false);
    this.controllerActionDone = new BehaviorSubject(false);

    this.updateUnitSub = this.updateUnitDone.subscribe((val) => {
      if (val && this.controllerActionDone.value) {
        this.dismiss();
      }
    });

    this.controllerActionSub = this.controllerActionDone.subscribe((val) => {
      if (val && this.updateUnitDone.value) {
        this.dismiss();
      }
    });
  }

  ngOnDestroy() {
    this.updateUnitSub.unsubscribe();
    this.controllerActionSub.unsubscribe();
  }

  public dismiss(data = null) {
    this.viewCtrl.dismiss(data);
  }

  slideBack() {
    this.slides.nativeElement.swiper.slideTo(this.currentSlide['skipToSlide']);
    this.currentSlide = this.sapnSlides[this.currentSlide['skipToSlideName']];
    if (this.currentSlide['isFirstSlide']) {
    }
  }

  slideToAgentSlide() {
    this.currentSlide = this.sapnSlides.AGENT;
    this.slides.nativeElement.swiper.slideTo(this.currentSlide.index);
  }

  prepareControllerData() {
    const endpoint = this.unitsService.unitEndpoints.endpoints.find((el) => el.uuid === this.endpointUUID);

    const endpoint_info = this.prepareEndpointInfo(endpoint);

    const { controller_info, status, message, controllerID, updateControllerData } =
      this.prepareControllerInfoAndOtherData(endpoint);

    return { endpoint_info, controller_info, status, message, controllerID, updateControllerData };
  }

  prepareEndpointInfo(endpoint) {
    return {
      endpoint_id: endpoint.id,
      uuid: endpoint.long_uuid,
    };
  }

  prepareControllerInfoAndOtherData(endpoint) {
    // there will only be one controller type for the SAPN wizard
    const programControllerType = this.program.controllerTypes[0];

    const existingSAPNController = endpoint.controllers.find(
      (el) => el.controller_type_id === programControllerType.id,
    );

    const allDevicesToControl = this.getAllDevicesToControl(endpoint, programControllerType);

    // at this stage we do not know if we will do nothing, update, or create a new controller
    const { device_ids, status, message } = this.getNewDevicesToControlAndStatusAndMessage(
      allDevicesToControl,
      existingSAPNController,
    );

    if (existingSAPNController) {
      existingSAPNController.device_ids = device_ids;
    }

    return {
      controller_info: {
        device_ids,
        parameters: [],
        controller_type_id: programControllerType.id,
        name: 'SA Smarter Homes Controller',
        description: 'This controller is in place to ensure compliance with the SA Smarter Homes program directives',
      },
      status,
      message,
      controllerID: existingSAPNController ? existingSAPNController.id : null,
      updateControllerData: existingSAPNController,
    };
  }

  getNewDevicesToControlAndStatusAndMessage(allDevicesToControl, existingController) {
    const device_ids = allDevicesToControl.map((el) => el.id);
    let status = ControllerAction.CREATE;
    const newDevicesToControl = [];

    if (device_ids.length === 0) {
      status = ControllerAction.NONE;
    } else if (existingController) {
      const existingControlledDevices = existingController.device_ids;
      if (device_ids.sort().join('') === existingControlledDevices.sort().join('')) {
        // two arrays are the same
        status = ControllerAction.NONE;
      } else {
        // if not, where are they different?
        status = ControllerAction.UPDATE;
        allDevicesToControl.forEach((el) => {
          if (!existingControlledDevices.includes(el.id)) {
            newDevicesToControl.push(el);
          }
        });
      }
    }

    const message = this.devicesMessage(allDevicesToControl, newDevicesToControl, status);

    return { device_ids, status, message };
  }

  devicesMessage(allDevicesToControl: any[], newDevicesToControl: any[], status: ControllerAction) {
    let message = '';
    switch (status) {
      case ControllerAction.NONE:
        if (allDevicesToControl.length === 0) {
          message =
            'We have not found any devices to control connected to this droplet. If this is a mistake please exit this wizard and go back to the devices page to add devices.';
        } else {
          message = 'Your unit already has a controller.';
        }
        break;
      case ControllerAction.UPDATE:
        message = 'We will now update the controller on your unit.';
        break;
      case ControllerAction.CREATE:
        message = 'We will now add a controller to your unit.';
        break;
    }

    return message;
  }

  getAllDevicesToControl(endpoint, programControllerType) {
    // const endpointDevices = ;
    const endpoint61850Devices = [];
    const endpointLegacyDevices = [];
    endpoint.devices.map((el) => {
      if (el['iec61850_device_type_id']) {
        endpoint61850Devices.push(el);
      } else if (el['device_type_id']) {
        endpointLegacyDevices.push(el);
      }
    });

    const controllableLegacyDeviceTypeIDs = programControllerType.valid_legacy_device_type_ids;
    const controllable61850DeviceTypeIDs = programControllerType.valid_iec61850_device_type_ids;

    const devicesToControl = [];

    controllableLegacyDeviceTypeIDs.forEach((controllableDeviceTypeID) => {
      endpointLegacyDevices.forEach((device) => {
        if (device.device_type_id === controllableDeviceTypeID) {
          devicesToControl.push(device);
        }
      });
    });

    controllable61850DeviceTypeIDs.forEach((controllableDeviceTypeID) => {
      endpoint61850Devices.forEach((device) => {
        if (device.iec61850_device_type_id === controllableDeviceTypeID) {
          devicesToControl.push(device);
        }
      });
    });

    return devicesToControl;
  }
}
