<ion-label class="stack-label ion-padding-start">{{ label }}</ion-label>
<ion-item lines="none" [ngClass]="ionItemExtraCss">
  <ion-select
    interface="popover"
    class="width-100-pct select-input"
    [ngClass]="{ 'input-error': invalid && (dirty || touched) }"
    [placeholder]="placeholder"
    [interfaceOptions]="interfaceOptions"
    [disabled]="isDisable"
    (ionChange)="ionChange()"
    (ionBlur)="ionBlur()"
    [(ngModel)]="value"
  >
    <ion-select-option *ngFor="let option of options" [value]="valueProp ? option[valueProp] : option">
      {{ option[nameProp] }}
    </ion-select-option>
  </ion-select>
</ion-item>

<app-input-validation-errors
  class="width-100-pct"
  [label]="label"
  [invalid]="invalid"
  [dirty]="dirty"
  [touched]="touched"
  [errors]="errors"
></app-input-validation-errors>
