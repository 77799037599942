import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { DropletDisplay } from '../../classes/units/droplet/droplet-display.model';
import { DropletItemMode, ModalIdentifier, SkeletonPlaceholder } from '@class/commons/constants';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { PermissionsService } from '@service/permissions/permissions.service';
import { TranslationsService } from '@service/common/translations.service';
import { Subscription } from 'rxjs';
import { DropletControllerInventoryRequest } from '@class/units/droplet/controller-inventory-request/droplet-controller-inventory-request.model';
import { DropletAddItemEvent, DropletItemSelectedEvent } from '@service/units/endpoints/devices.service';
import { BrowserLogger } from '@class/core/browser-logger';
import { DropletController, DropletControllerAddUpdateEvent } from '@class/units/droplet/droplet-controller.model';
import { Event, EventBusService } from '@service/core/event-bus.service';
import { isEmpty } from 'lodash';

interface DropletComponentViewModel {
  firmwareUpdate: boolean;
  showAttributes: boolean;
  showDevices: boolean;
  showControllers: boolean;
  enableAdd: boolean;
  addLabel: string;
  enableInventory: boolean;
  inventoryReceived?: boolean;
}

@Component({
  selector: 'app-droplet',
  templateUrl: './droplet.component.html',
  styleUrls: ['./droplet.component.scss'],
})
export class DropletComponent implements OnChanges, OnDestroy {
  @Output() deviceClick = new EventEmitter<DropletItemSelectedEvent>();
  @Output() controllerClick = new EventEmitter<DropletItemSelectedEvent>();
  @Output() addDeviceClick = new EventEmitter<DropletAddItemEvent>();
  @Output() addControllerClick = new EventEmitter<DropletAddItemEvent>();
  @Input() mode: DropletItemMode;
  @Input() endpoint: DropletDisplay;
  @Input()
  set endpointIndex(index: number) {
    if (index === 0) {
      if (this.mode === DropletItemMode.DEVICE) {
        this.isExpanded = this.endpoint?.devices?.length > 0;
      } else if (this.mode === DropletItemMode.CONTROLLER) {
        this.isExpanded = this.endpoint?.controllers?.length > 0;
      }
    }
    this._endpointIndex = index;
  }
  @Input() isLoading: boolean;
  @Input() enableAdd = false;
  @Input() notification: string = 'Something is loading - todo..';
  @Input() isUnitActive: boolean;
  isExpanded = false;
  isAttribute = false;
  _endpointIndex: number;
  selectedItemIndex: number;
  readonly PermissionKey = PermissionKey;

  vm: DropletComponentViewModel = {
    firmwareUpdate: false,
    showAttributes: false,
    showDevices: false,
    showControllers: false,
    enableAdd: false,
    addLabel: 'Add',
    enableInventory: false,
  };

  readonly DropletItemMode = DropletItemMode;
  readonly SkeletonPlaceholder = SkeletonPlaceholder;

  private _controllerInventoryReceivedSubscription: Subscription;
  private _controllerAddEventSubscription: Subscription;
  private _dropletStateSubscription: Subscription;
  private _controllerDisplayedSubscription: Subscription;
  private _deviceDisplayedSubscription: Subscription;
  private _modalClosedSubscription: Subscription;

  constructor(
    private _permissionsService: PermissionsService,
    private _translationsService: TranslationsService,
    private _eventBus: EventBusService,
  ) {
    BrowserLogger.log('DropletComponent.constructor');
    // subscribe to Controller Inventory Received event (controller CRUD operation checks)
    this._controllerInventoryReceivedSubscription = this._eventBus
      .on(Event.CONTROLLER_INVENTORY_STATE)
      .subscribe((event: DropletControllerInventoryRequest) => {
        this.handleControllerInventoryReceived(event, this.endpoint, this.mode);
      });
    // subscribe to Add Controller event
    this._controllerInventoryReceivedSubscription = this._eventBus
      .on(Event.CONTROLLER_ADD)
      .subscribe((event: DropletControllerAddUpdateEvent) => {
        this.handleAddControllerEvent(event, this.mode);
      });
    // subscribe to Device Displayed event
    this._deviceDisplayedSubscription = this._eventBus
      .on(Event.DISPLAYED_DEVICE)
      .subscribe((event: DropletItemSelectedEvent) => {
        this.handleDeviceDisplayedEvent(event);
      });
    // subscribe to Controller Displayed event
    this._controllerDisplayedSubscription = this._eventBus
      .on(Event.DISPLAYED_CONTROLLER)
      .subscribe((event: DropletItemSelectedEvent) => {
        this.handleControllerDisplayedEvent(event);
      });
    // subscribe to Modal Closed events
    this._modalClosedSubscription = this._eventBus.on(Event.MODAL_CLOSED).subscribe((modalId: ModalIdentifier) => {
      this.handleModalClosedEvent(modalId, this.mode);
    });
  }

  ngOnDestroy(): void {
    BrowserLogger.log('DropletComponent.ngOnDestroy');
    this._controllerInventoryReceivedSubscription?.unsubscribe();
    this._controllerAddEventSubscription?.unsubscribe();
    this._controllerDisplayedSubscription?.unsubscribe();
    this._deviceDisplayedSubscription?.unsubscribe();
    this._dropletStateSubscription?.unsubscribe();
    this._modalClosedSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    BrowserLogger.log('DropletComponent.ngOnChanges', { changes });
    this.setViewModel(this.mode, this.endpoint);
  }

  private handleDeviceDisplayedEvent(event: DropletItemSelectedEvent): void {
    // not applicable for controller mode
    if (this.mode === DropletItemMode.CONTROLLER) {
      return;
    }
    BrowserLogger.log('DropletComponent.handleDeviceDisplayedEvent', { event });
    this.clearSelectionIfDifferentEndpoint(event?.endpointIndex);
  }

  private handleControllerDisplayedEvent(event: DropletItemSelectedEvent): void {
    // not applicable for device mode
    if (this.mode === DropletItemMode.DEVICE) {
      return;
    }
    BrowserLogger.log('DropletComponent.handleControllerDisplayedEvent', { event });
    this.clearSelectionIfDifferentEndpoint(event?.endpointIndex);
  }

  private clearSelectionIfDifferentEndpoint(selectedEndpointIndex: number): void {
    // if a different endpoint was selected then clear our selected item
    // as we do not want it highlighted anymore
    BrowserLogger.log('DropletComponent.clearSelectionIfDifferentEndpoint', {
      selectedItemIndex: this.selectedItemIndex,
      selectedEndpointIndex,
    });
    if (this.selectedItemIndex >= 0 && this._endpointIndex !== selectedEndpointIndex) {
      this.selectedItemIndex = undefined;
    }
  }

  handleAddButtonClick(): void {
    BrowserLogger.log('DropletComponent.handleAddButtonClick', { mode: this.mode });
    if (this.mode === DropletItemMode.DEVICE) {
      this.addDeviceClick.emit({ mode: this.mode, droplet: this.endpoint });
    } else if (this.mode === DropletItemMode.CONTROLLER) {
      this.addControllerClick.emit({ mode: this.mode, droplet: this.endpoint });
    }
  }

  private handleControllerInventoryReceived(
    controllerInventoryRequest: DropletControllerInventoryRequest,
    droplet: DropletDisplay,
    mode: DropletItemMode,
  ) {
    // if in controller mode, need to recheck the view model which uses 'canCRUDController'
    // which is set by the DropletControllerInventoryService
    if (
      mode === DropletItemMode.CONTROLLER &&
      DropletDisplay.isMyControllerInventoryRequest(controllerInventoryRequest, droplet)
    ) {
      BrowserLogger.log('DropletComponent.handleControllerInventoryReceived', { controllerInventoryRequest, mode });
      this.setViewModel(mode, droplet, controllerInventoryRequest);
    }
  }

  private handleAddControllerEvent(event: DropletControllerAddUpdateEvent, mode: DropletItemMode): void {
    // if we are adding a controller, then flag inventory as not received as
    // controller flags will get reset and API will kick off a new inventory request
    // otherwise will we get warning icons on all devices
    if (mode === DropletItemMode.CONTROLLER && event.droplet.overview.id === this.endpoint.overview.id) {
      BrowserLogger.log('DropletComponent.handleAddControllerEvent', { event, mode });
      this.vm.inventoryReceived = false;
    }
  }

  private handleModalClosedEvent(modalId: ModalIdentifier, mode: DropletItemMode): void {
    BrowserLogger.log('DropletComponent.handleModalClosedEvent', { modalId, mode });
    // clear the selected item index on a device/controller details modal close
    if (this.selectedItemIndex >= 0) {
      if (mode === DropletItemMode.DEVICE && modalId === ModalIdentifier.DEVICE_DETAILS) {
        this.selectedItemIndex = undefined;
      } else if (mode === DropletItemMode.CONTROLLER && modalId === ModalIdentifier.CONTROLLER_DETAILS) {
        this.selectedItemIndex = undefined;
      }
    }
  }

  private setViewModel(
    mode: DropletItemMode,
    droplet: DropletDisplay,
    state?: DropletControllerInventoryRequest,
  ): void {
    if (droplet && mode) {
      switch (mode) {
        case DropletItemMode.DEVICE:
          this.vm.firmwareUpdate = true;
          this.vm.showAttributes = !isEmpty(droplet.attributes);
          this.vm.showDevices = Boolean(droplet.devices?.length);
          this.vm.showControllers = false;
          this.vm.enableAdd = DropletDisplay.canAddDevice(droplet);
          this.vm.addLabel = this._translationsService.instant('UnitPage.AddDevice');
          this.vm.enableInventory = false;
          break;
        case DropletItemMode.CONTROLLER:
          this.vm.firmwareUpdate = false;
          this.vm.showAttributes = !isEmpty(droplet.attributes);
          this.vm.showDevices = false;
          this.vm.showControllers = true;
          this.vm.enableAdd = DropletController.canAdd(this._permissionsService, state);
          this.vm.addLabel = this._translationsService.instant('UnitPage.AddController');
          this.vm.enableInventory = DropletDisplay.canUseControllerInventory(droplet);
          this.vm.inventoryReceived = state?.received;
          break;
      }
    } else {
      // no droplet/mode disable everything
      this.vm.firmwareUpdate = false;
      this.vm.showAttributes = false;
      this.vm.showDevices = false;
      this.vm.showControllers = false;
      this.vm.enableAdd = false;
    }
    BrowserLogger.log('DropletComponent.setViewModel', { vm: this.vm, mode, droplet });
  }

  handleDeviceClick(deviceIndex: number): void {
    BrowserLogger.log('DropletComponent.handleDeviceClick', { deviceIndex });
    this.selectedItemIndex = deviceIndex;
    const event: DropletItemSelectedEvent = {
      endpointIndex: this._endpointIndex,
      itemIndex: deviceIndex,
      controllerIndex: undefined,
    };
    this.deviceClick.emit(event);
  }

  handleControllerClick(controllerIndex: number): void {
    BrowserLogger.log('DropletComponent.handleControllerClick', { controllerIndex });
    this.selectedItemIndex = controllerIndex;
    const event: DropletItemSelectedEvent = {
      endpointIndex: this._endpointIndex,
      itemIndex: undefined,
      controllerIndex,
    };
    this.controllerClick.emit(event);
  }
}
