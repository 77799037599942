<ion-card mode="md">
  <ion-card-header [color]="alert.time_deactivated ? 'brand-primary' : 'danger'">
    <ion-card-title> <ion-icon [name]="alertIcon"> </ion-icon> {{ alert.alert_title }} </ion-card-title>
    <ion-card-subtitle>
      {{ alert.time_relative }} (<span *ngIf="alert.duration !== 'ongoing'">{{ 'Alerts.ForAbout' | translate }} </span
      >{{ alert.duration }})
    </ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <ion-text>{{ 'Alerts.Message' | translate }}: {{ alert.alert_text }}</ion-text>
    <br />
    <ion-text>{{ 'Alerts.DeviceName' | translate }}: {{ alert.device }}</ion-text>
    <br />
    <ion-text>{{ 'Alerts.SerialNumber' | translate }}: {{ alert.device_serialNo }}</ion-text>
    <ion-button color="light" style="float: right" (click)="openAlertModal()">{{
      'Alerts.Details' | translate
    }}</ion-button>
  </ion-card-content>
</ion-card>
