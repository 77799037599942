import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ThemeService } from '../../services/themes/theme.service';
import { LoadingController, AlertController, ToastController } from '@ionic/angular';
import { TranslationsService } from '../../services/common/translations.service';
import { ROLLOVER_PERIOD_MIN } from '../../services/virtualPowerPlants/rollover/vpp-roll-over.service';

@Component({
  selector: 'app-vpp-policy-detail',
  templateUrl: './vpp-policy-detail.component.html',
  styleUrls: ['./vpp-policy-detail.component.scss'],
})
export class VppPolicyDetailComponent implements OnInit {
  @Input() selectedPolicy;
  @Input() serviceObj; // virtualPowerPalnt service obj

  policyToEdit;
  timeInterval = 5;
  constructor(
    public theme: ThemeService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private toast: ToastController,
    private trans: TranslationsService,
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.serviceObj != null && this.serviceObj != undefined) {
      this.timeInterval = ROLLOVER_PERIOD_MIN;
    }
    if (this.selectedPolicy != null) {
      this.policyToEdit = Object.assign({}, this.selectedPolicy);
      this.policyToEdit.attributes = [];
      this.selectedPolicy.attributes.forEach((attribute, index) => {
        let newAttribute = {
          attr_type: attribute.attr_type,
          attr_unit: attribute.attr_unit,
          id: attribute.id,
          name: attribute.name,
          policy_id: attribute.policy_id,
          options: [],
        };
        attribute.options.forEach((option) => {
          let newOption = Object.assign({}, option);
          newOption.attributeName = attribute.name;
          newAttribute.options.push(newOption);
        });
        this.policyToEdit.attributes.push(Object.assign({}, newAttribute));
      });
    }

    // console.log('this.policyToEdit: ', this.policyToEdit);
    // console.log('this.selectedPolicy: ', this.selectedPolicy);
  }

  updatePolicy() {
    // console.log(this.policyToEdit);
    let policyAttributeOptionsToUpdate = [];

    // campare the values of the edited policy with the selected one
    this.policyToEdit.attributes.forEach((editAttribute, index) => {
      let selectedAttribute = this.selectedPolicy.attributes[index];
      editAttribute.options.forEach((editOption, index2) => {
        let selectedOption = selectedAttribute.options[index2];
        if (
          selectedOption.id == editOption.id &&
          (selectedOption.value != editOption.value || selectedOption.name != editOption.name)
        ) {
          policyAttributeOptionsToUpdate.push(editOption);
        }
      });
    });

    this.createLoader(this.trans.str.vpp.UpdatingPolicy + ', ' + this.trans.str.general.Wait).then((loader) => {
      loader.present();
      if (policyAttributeOptionsToUpdate.length > 0) {
        policyAttributeOptionsToUpdate.forEach((option, index) => {
          // console.log('OPTION: ', option);
          this.serviceObj.vppUpdatePolicyAttributeOption(option.id, option).then(
            (res) => {
              this.presentToast(this.trans.str.vpp.PolicyUpdateSuccess);
            },
            (error) => {
              loader.dismiss();
              let msg = this.trans.str.vpp.InviteUser.UserFail.CreateMsg;
              if (error.hasOwnProperty('status')) {
                msg = msg + ' ' + error.status + ' ' + error.statusText + '. ';
              } else {
                msg += JSON.stringify(error);
              }
              this.presentAlertSimpleOk(
                this.trans.str.vpp.InviteUser.UserFail.Header,
                this.trans.str.vpp.PolicyUpdateFail +
                  ' Attr: ' +
                  option.attributeName +
                  ', ' +
                  option.device_control_name +
                  ' ' +
                  option.device_control_description,
                msg,
              );
            },
          );
          if (index == policyAttributeOptionsToUpdate.length - 1) {
            setTimeout(() => {
              loader.dismiss();
              this.serviceObj.getVppPolicies();
              this.cancel();
            }, 500);
          }
        });
      } else {
        setTimeout(() => {
          loader.dismiss();
          this.cancel();
        }, 500);
      }
      // this.policyToEdit.attributes.forEach( (attribute, index) => {
      //   attribute.options.forEach(option => {
      //     // console.log('OPTION: ', option);
      //     this.serviceObj.vppUpdatePolicyAttributeOption(option.id, option).then(res => {
      //       this.presentToast(this.trans.str.vpp.PolicyUpdateSuccess);
      //     }, error => {
      //       loader.dismiss();
      //       let msg = this.trans.str.vpp.InviteUser.UserFail.CreateMsg;
      //       if(error.hasOwnProperty('status')){
      //         msg = msg + " " + error.status + " " + error.statusText + ". ";
      //       }else{
      //         msg += JSON.stringify(error);
      //       }
      //       this.presentAlertSimpleOk(this.trans.str.vpp.InviteUser.UserFail.Header, this.trans.str.vpp.PolicyUpdateFail, msg);
      //     })
      //   });
      //   if(index == this.policyToEdit.attributes.length-1){
      //    setTimeout(() => {
      //     loader.dismiss();
      //     this.serviceObj.getVppPolicies();
      //     this.cancel();
      //    }, 500);
      //   }
      // });
    });

    // this.serviceObj.vppUpdatePolicy(this.policyToEdit.id, this.policyToEdit).then( res => {
    //   // console.log(res);
    // }, error => {
    //   // console.log(error);
    // })
  }
  cancel() {
    this.selectedPolicy.edit = false;
  }

  createLoader(message) {
    return this.loadingController.create({
      message: message,
    });
  }
  async presentAlertSimpleOk(header, subheader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }
  presentToast(message, duration = 3000) {
    this.toast
      .create({
        message: message,
        duration: duration,
        position: 'top',
      })
      .then((toast) => {
        toast.present();
      });
  }
}
