<!-- droplet notification -->
<div class="pb-0 pt-16 ion-padding-horizontal" *ngIf="vm">
  <!-- waiting for initial inventory -->
  <ng-container *ngIf="vm.waiting">
    <ion-item lines="none" class="droplet-notification">
      <ion-icon slot="start" src="assets/icons/svg/waiting.svg"></ion-icon>
      <ion-text>{{ 'UnitPage.WaitingForInitialInventoryFromDroplet' | translate }}</ion-text>
    </ion-item>
  </ng-container>

  <!-- inventory not received -->
  <ng-container *ngIf="!vm.waiting && !vm.received">
    <ion-item lines="none" class="droplet-notification danger">
      <ion-icon slot="start" src="assets/icons/svg/failed-message.svg"></ion-icon>
      <ion-text>
        {{ 'UnitPage.InventoryNotReceived' | translate }}
        <p class="ion-no-margin" color="text-tertiary">
          {{ 'UnitPage.YouCanNotModifyControllers' | translate }}
        </p>
      </ion-text>
      <app-retry-button slot="end" (onClick)="handleRetryClick()"></app-retry-button>
    </ion-item>
  </ng-container>

  <!-- Inventory received (and no longer waiting) -->
  <ng-container *ngIf="!vm.waiting && vm.received">
    <ion-item lines="none" class="droplet-notification success">
      <ion-icon slot="start" src="assets/icons/svg/check.svg"></ion-icon>
      <ion-text>{{ 'UnitPage.InventoryReceived' | translate }}</ion-text>
    </ion-item>
    <ion-item *ngIf="vm.outOfSync" lines="none" class="droplet-notification warning">
      <ion-icon slot="start" src="assets/icons/svg/alert-warning-icon.svg"></ion-icon>
      <ion-text>{{ 'UnitPage.ControllerConfigNotSynced' | translate }}</ion-text>
    </ion-item>
  </ng-container>
</div>
