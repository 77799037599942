<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> {{ "DropletFirmware.DropletFirmwareUpgrade" | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="gridSetting width-100-pct p-10 fullHeight">
    <ion-row class="fullHeight m-0 gridRow">
      <ion-col size="12" class="ion-align-self-start">
        <ion-item lines="none" class="ion-text-center">
          <ion-label color="dark" class="ion-text-wrap fs-18 fw-500">
            {{ "DropletFirmware.UpdatingDropletFirmware" | translate}}
          </ion-label>
        </ion-item>
      </ion-col>

      <ion-col size="12" class="ion-align-self-center">
        <!-- API Call Status | waiting for response or done or failed -->
        <ion-item lines="inset">
          <ion-text>{{ "DropletFirmware.InitiatingUpdate" | translate }}</ion-text>
          <ion-spinner
            name="dots"
            slot="end"
            color="brand-primary"
            *ngIf="!upgradeRequestToServerStatus.$callMade; else callMade"
          ></ion-spinner>
          <ng-template #callMade>
            <ion-icon
              name="close-circle"
              slot="end"
              color="danger"
              *ngIf="upgradeRequestToServerStatus.$callFailed"
            ></ion-icon>
            <ion-icon
              name="checkmark-circle"
              slot="end"
              color="brand-primary"
              *ngIf="!upgradeRequestToServerStatus.$callFailed"
            ></ion-icon>
          </ng-template>
        </ion-item>

        <div *ngIf="upgradeRequestToServerStatus.$callMade && !timeoutOccurred">
          <div *ngIf="!upgradeRequestToServerStatus.$callFailed; else apiError">
            <ion-item lines="none">
              <!-- display the status of the mqtt msg -->
              <ion-text slot="start">
                {{ "General.Status" | translate }}:
                <span class="ion-text-capitalize fw-500"> {{firmwareMqttMsg.statusText}} </span>
              </ion-text>
              <!-- display the progress of update process -->
              <ion-text slot="end">
                {{ "General.Progress" | translate }}:
                <span class="fw-500"> {{firmwareMqttMsg.progress}}% </span>
              </ion-text>
            </ion-item>

            <!--
              we don't need the buffer value here, but to show the dotted line, we need to pass the buffer value
              just for that passing the buffer value, otherwise it's not needed
            -->
            <ion-item lines="none" class="bkg-transparent mt--30">
              <ion-progress-bar
                color="brand-primary"
                [value]="firmwareMqttMsg.percentage"
                [buffer]="firmwareMqttMsg.percentage"
              ></ion-progress-bar>
            </ion-item>

            <!--
              When the droplet goes into install mode
              it takes a bit to reboot, just showing this msg to user that wait
              something is going on
            -->
            <ion-item *ngIf="firmwareMqttMsg.status === FirmwareUpdateMqttStatuses.INSTALLING">
              <ion-label color="dark" class="ion-text-wrap">
                {{ "DropletFirmware.DownloadCompletedAndStartInstalling" | translate }}
              </ion-label>
            </ion-item>

            <div *ngIf="firmwareMqttMsg.status === FirmwareUpdateMqttStatuses.FAIL">
              <ion-item lines="none">
                <ion-label
                  color="danger"
                  class="ion-text-wrap"
                  *ngIf="firmwareMqttMsg.verboseStatus === FirmwareUpdateMqttVerboseStatuses.FAIL_UNKNOWN"
                >
                  {{ "DropletFirmware.UnableToCompleteTheUpdate" | translate }}
                </ion-label>
                <ion-label
                  color="danger"
                  class="ion-text-wrap"
                  *ngIf="firmwareMqttMsg.verboseStatus === FirmwareUpdateMqttVerboseStatuses.FAIL_ALREADY_UPDATING"
                >
                  {{ "DropletFirmware.ThereIsAlreadyAnUpdate" | translate }}
                </ion-label>
                <ion-label
                  color="danger"
                  class="ion-text-wrap"
                  *ngIf="firmwareMqttMsg.verboseStatus === FirmwareUpdateMqttVerboseStatuses.FAIL_DROPLET_DOWNLOAD"
                >
                  {{ "DropletFirmware.UnableToCompleteTheUpdate" | translate }}&nbsp;{{
                  "DropletFirmware.FirmwareDownloadFailed" | translate }}
                </ion-label>
                <ion-label
                  color="danger"
                  class="ion-text-wrap"
                  *ngIf="firmwareMqttMsg.verboseStatus === FirmwareUpdateMqttVerboseStatuses.FAIL_VENV_DOWNLOAD"
                >
                  {{ "DropletFirmware.UnableToCompleteTheUpdate" | translate }}&nbsp;{{
                  "DropletFirmware.FirmwareDownloadVenvFailed" | translate }}
                </ion-label>
              </ion-item>
              <ion-item>
                <ion-label color="danger" class="ion-text-wrap fs-12">
                  {{ "DropletFirmware.ExtraInfo" | translate }}: {{firmwareMqttMsg.extraInfoString}}
                </ion-label>
              </ion-item>
            </div>
          </div>

          <ng-template #apiError>
            <ion-item lines="none">
              <ion-label color="danger" class="ion-text-wrap"> {{upgradeRequestToServerStatus.$errorText}} </ion-label>
            </ion-item>
          </ng-template>
        </div>

        <div *ngIf="upgradeRequestToServerStatus.$callMade && timeoutOccurred">
          <ion-item lines="none">
            <ion-label color="danger" class="ion-text-wrap">
              {{ "DropletFirmware.ConnectionTimedOut" | translate }}
            </ion-label>
          </ion-item>
        </div>
      </ion-col>

      <ion-col size="12" class="ion-align-self-end">
        <div
          *ngIf="upgradeRequestToServerStatus.$callFailed || firmwareMqttMsg.status === FirmwareUpdateMqttStatuses.SUCCESS"
        >
          <ion-button
            *ngIf="upgradeRequestToServerStatus.$callFailed && upgradeRequestToServerStatus.$callMade"
            color="button-primary"
            expand="block"
            (click)="updateDropletFirmware(endpointUuid)"
            fill="outline"
            size="large"
          >
            {{ "Droplet.Setup.TryAgain" | translate }}
          </ion-button>
          <ion-button color="button-primary" expand="block" (click)="dismiss()" size="large">
            {{ "General.Done" | translate }}
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
