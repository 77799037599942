import { Injectable } from '@angular/core';
import { UnitsService } from '../units.service';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ApiWrapper } from '../../common/api-wrapper.service';
import { DropletDisplay } from '../../../classes/units/droplet/droplet-display.model';
import { TranslationsService } from '@service/common/translations.service';
import { DropletDevice } from '@class/units/droplet/droplet-device.model';
import { DropletItemMode } from '@class/commons/constants';
import { BrowserLogger } from '@class/core/browser-logger';
import { EndpointDTO } from '@class/units/endpoint/endpoint-payload.model';
import { EmitEvent, Event, EventBusService } from '@service/core/event-bus.service';

export interface DeviceState {
  data: DropletDisplay[] | undefined;
  displayedEndpointDeviceIndex?: {
    endpointIndex: number | undefined; // DeviceState.data[endpointIndex]
    deviceIndex: number | undefined; // DeviceState.data[endpointIndex].devices[deviceIndex]
  };
  displayedEndpointControllerIndex?: {
    endpointIndex: number | undefined; // DeviceState.data[endpointIndex]
    controllerIndex: number | undefined; // DeviceState.data[endpointIndex].controllers[controllerIndex]
  };
  loading: boolean;
  error: string | undefined;
}
let _state: DeviceState = {
  data: null,
  displayedEndpointDeviceIndex: null,
  displayedEndpointControllerIndex: null,
  loading: true,
  error: '',
};

// Event that will end up being used for DeviceState access
export interface DropletItemSelectedEvent {
  endpointIndex: number; // DeviceState.[displayedEndpointDeviceIndex/displayedEndpointControllerIndex].endpointIndex
  itemIndex?: number; // DeviceState.displayedEndpointDeviceIndex.deviceIndex
  controllerIndex?: number; // DeviceState.displayedEndpointControllerIndex.controllerIndex
}

export interface DropletAddItemEvent {
  mode: DropletItemMode;
  droplet: DropletDisplay;
}

@Injectable({
  providedIn: 'root',
})
export class DropletService {
  private store: BehaviorSubject<DeviceState> = new BehaviorSubject(_state);
  private state$ = this.store.asObservable();

  data$ = this.state$.pipe(
    map((state) => state.data),
    distinctUntilChanged(),
  );
  displayedEndpointDeviceIndex$ = this.state$.pipe(
    map((state) => state.displayedEndpointDeviceIndex),
    distinctUntilChanged(),
  );
  displayedEndpointControllerIndex$ = this.state$.pipe(
    map((state) => state.displayedEndpointControllerIndex),
    distinctUntilChanged(),
  );
  error$ = this.state$.pipe(
    map((state) => state.error),
    distinctUntilChanged(),
  );
  loading$ = this.state$.pipe(map((state) => state.loading));

  vm$: Observable<DeviceState> = combineLatest([
    this.data$,
    this.displayedEndpointDeviceIndex$,
    this.displayedEndpointControllerIndex$,
    this.loading$,
    this.error$,
  ]).pipe(
    map(([data, displayedEndpointDeviceIndex, displayedEndpointControllerIndex, loading, error]) => {
      return { data, displayedEndpointDeviceIndex, displayedEndpointControllerIndex, loading, error };
    }),
  );

  constructor(
    private _unitService: UnitsService,
    private _api: ApiWrapper,
    private _translationsService: TranslationsService,
    private _eventBus: EventBusService,
  ) {
    this._unitService.endpointsReady$
      .pipe(
        filter((data) => !!data),
        map((data) => {
          this.initState();
          // map unitService endpoints payload data to DropletDisplay array
          const droplets = EndpointDTO.adaptToDropletDisplayArray(data, this._translationsService);
          // Also emit on global Event Bus
          this._eventBus.emit(new EmitEvent(Event.DROPLETS_LOADED, droplets));

          return droplets;
        }),
      )
      .subscribe(
        (data) => {
          this.updateState({ ..._state, data, loading: false });
        },
        (err) => {
          console.error(err);
          this.updateState({ ..._state, loading: false, error: 'An error has occurred fetching device data' });
        },
      );
  }

  updateDisplayedDevice(endpointIndex: number, deviceIndex: number): void {
    BrowserLogger.log('DropletService.updateDisplayedDevice', { endpointIndex, deviceIndex });
    // Update State
    this.updateState({
      ..._state,
      displayedEndpointDeviceIndex: { endpointIndex, deviceIndex },
      error: null,
    });
    // Also emit on global Event Bus
    this._eventBus.emit(
      new EmitEvent(Event.DISPLAYED_DEVICE, {
        endpointIndex: endpointIndex,
        itemIndex: deviceIndex,
      } as DropletItemSelectedEvent),
    );
  }

  updateDisplayedController(endpointIndex: number, controllerIndex: number): void {
    BrowserLogger.log('DropletService.updateDisplayedController', { endpointIndex, controllerIndex });
    // Update State
    this.updateState({
      ..._state,
      displayedEndpointControllerIndex: { endpointIndex, controllerIndex },
      error: null,
    });
    // Also emit on global Event Bus
    this._eventBus.emit(
      new EmitEvent(Event.DISPLAYED_CONTROLLER, {
        endpointIndex: endpointIndex,
        controllerIndex: controllerIndex,
      } as DropletItemSelectedEvent),
    );
  }

  clearDisplayedEndpointDevice(): void {
    BrowserLogger.log('DropletService.clearDisplayedEndpointDevice');
    // Update State
    this.updateState({
      ..._state,
      displayedEndpointDeviceIndex: { endpointIndex: undefined, deviceIndex: undefined },
      error: null,
    });
    // Also emit on global Event Bus
    this._eventBus.emit(new EmitEvent(Event.DISPLAYED_DEVICE, null)); // displayed event but with null
  }

  clearDisplayedEndpointController(): void {
    BrowserLogger.log('DropletService.clearDisplayedEndpointController');
    // Update State
    this.updateState({
      ..._state,
      displayedEndpointControllerIndex: { endpointIndex: undefined, controllerIndex: undefined },
      error: null,
    });
    // Also emit on global Event Bus
    this._eventBus.emit(new EmitEvent(Event.DISPLAYED_CONTROLLER, null)); // displayed event but with null
  }

  clearDisplayedDeviceAndController(): void {
    BrowserLogger.log('DropletService.clearDisplayedDeviceAndController', { state: _state });
    this.clearDisplayedEndpointDevice();
    this.clearDisplayedEndpointController();
  }

  async removeDevice(): Promise<void> {
    BrowserLogger.log('DropletService.removeDevice', {
      displayedEndpointDeviceIndex: _state.displayedEndpointDeviceIndex,
    });
    this.setStateLoading();
    try {
      const { endpointIndex, deviceIndex } = _state.displayedEndpointDeviceIndex;
      if (endpointIndex === undefined || deviceIndex === undefined) {
        BrowserLogger.warn('Attempting to remove device without an endpoint device in view! Aborting operation');
        return;
      }
      await DropletDevice.sendApiRemoveRequest(
        this._api,
        _state.data[endpointIndex].devices[deviceIndex].deviceOverview.id,
      );
      const _data = _state.data;
      _data[endpointIndex].devices.splice(deviceIndex, 1);
      // Update State
      this.updateState({ ..._state, data: _data, loading: false, displayedEndpointDeviceIndex: undefined });
      // Also emit on global Event Bus
      this._eventBus.emit(new EmitEvent(Event.DEVICE_REMOVED, _data));
      this._eventBus.emit(new EmitEvent(Event.DISPLAYED_DEVICE, null)); // displayed device is also cleared
    } catch (err) {
      BrowserLogger.error(err);
      this.updateState({ ..._state, error: 'An error has occurred while removing device.', loading: false });
    }
  }

  async updateDeviceName(name: string): Promise<void> {
    BrowserLogger.log('DropletService.updateDeviceName', {
      name,
      displayedEndpointDeviceIndex: _state.displayedEndpointDeviceIndex,
    });
    this.setStateLoading();
    try {
      const { endpointIndex, deviceIndex } = _state.displayedEndpointDeviceIndex;
      if (_state.displayedEndpointDeviceIndex == undefined || endpointIndex == undefined || deviceIndex == undefined) {
        BrowserLogger.warn('Attempting to update device name without an endpoint device in view! Aborting operation');
        return;
      }
      if (!_state.data[endpointIndex] || !_state.data[endpointIndex].devices[deviceIndex]) {
        BrowserLogger.warn('Attempting to update device name of undefined! Aborting operation');
        return;
      }
      const endpointPayloadResponse = await DropletDevice.sendApiUpdateRequest(
        this._api,
        _state.data[endpointIndex].devices[deviceIndex].deviceOverview.id,
        name,
      );
      if (!endpointPayloadResponse) {
        throw new Error('unknown server response after request made to update device name');
      }
      const _data = _state.data;
      _data[endpointIndex].devices[deviceIndex].deviceOverview.fullName = endpointPayloadResponse.full_name;
      // Update State
      this.updateState({ ..._state, data: _data, loading: false });
      // Also emit on global Event Bus
      this._eventBus.emit(new EmitEvent(Event.DEVICE_UPDATED, _data));
    } catch (err) {
      BrowserLogger.error(err);
      this.updateState({ ..._state, error: 'An error has occurred while updating device name', loading: false });
    }
  }

  acknowledgeError(): void {
    BrowserLogger.log('DropletService.acknowledgeError', { _state });
    this.updateState({ ..._state, error: undefined });
  }

  // ------- Private Methods ------------------------

  /** Update internal state cache and emit from store... */
  private updateState(state: DeviceState) {
    BrowserLogger.log('DropletService.updateState', { state });
    this.store.next((_state = state));
  }

  private setStateLoading(): void {
    BrowserLogger.log('DropletService.setStateLoading');
    this.updateState({ ..._state, loading: true });
  }

  private initState(): void {
    BrowserLogger.log('DropletService.initState');
    this.updateState({
      ..._state,
      data: undefined,
      displayedEndpointDeviceIndex: {
        endpointIndex: undefined,
        deviceIndex: undefined,
      },
      displayedEndpointControllerIndex: {
        endpointIndex: undefined,
        controllerIndex: undefined,
      },
      error: undefined,
      loading: true,
    });
  }
}
