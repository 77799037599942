import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslationsService } from '../../../services/common/translations.service';
import { CommonService } from '../../../services/common/common.service';
import moment from 'moment';
import { UnitsService } from '../../../services/units/units.service';
import { IsPlatformValues } from '@class/commons/is-platform-values';
import { GenericAlertsToastsService } from '@service/common/generic-alerts-toasts.service';
import { ColBreakpoint } from '@class/commons/enums';
import { PermissionKey } from '@class/commons/permissions/permission-constants';

enum TunnelServiceOpt {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
  MUST_BE_TRUE = 'MUST_BE_TRUE',
}

enum TunnelAction {
  OPEN = 'open',
}

const MUST_BE = 'MUST_BE';
@Component({
  selector: 'app-create-tunnel-modal',
  templateUrl: './create-tunnel-modal.page.html',
  styleUrls: ['./create-tunnel-modal.page.scss'],
})
export class CreateTunnelModalPage extends IsPlatformValues implements OnInit {
  public customAlertOptionsDevice = {
    header: this.trans.instant('General.Device'),
    translucent: true,
    cssClass: 'alert-min-width-30-pct',
  };
  public customAlertOptionsService = {
    header: this.trans.instant('Cost.Service'),
    translucent: true,
    cssClass: 'alert-min-width-30-pct',
  };
  selectedEndpoint; // TODO: type it with endpoint

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tunnelData: any = {}; // TODO: create type for tunnel & a DTO type for server
  servicesAvailableForSelectedDevice = false;
  listOfAvailableServicesForSelectedDevice = []; // endpoint/droplet devices type = EndpointServiceDTO - it should be EndpointService - need to fix unitService
  showManualIpTunnelCreate = false;
  public readonly MUST_BE = MUST_BE;
  public readonly PermissionKey = PermissionKey;

  constructor(
    private unitService: UnitsService,
    private viewCtrl: ModalController,
    private trans: TranslationsService,
    private genericAlertsToastsService: GenericAlertsToastsService,
    private commonService: CommonService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.setIonSelectWidth();
  }
  public dismiss(data = null): void {
    this.viewCtrl.dismiss(data);
  }
  private setIonSelectWidth(): void {
    // set the width of the ion-select modal box
    if (this.commonService.PLATFORM_WIDTH >= ColBreakpoint.XL) {
      this.customAlertOptionsDevice.cssClass = 'alert-min-width-30-pct';
      this.customAlertOptionsService.cssClass = 'alert-min-width-30-pct';
    } else if (this.commonService.PLATFORM_WIDTH >= ColBreakpoint.LG) {
      this.customAlertOptionsDevice.cssClass = 'alert-min-width-50-pct';
      this.customAlertOptionsService.cssClass = 'alert-min-width-50-pct';
    } else {
      this.customAlertOptionsDevice.cssClass = 'alert-min-width-70-pct';
      this.customAlertOptionsService.cssClass = 'alert-min-width-70-pct';
    }
  }
  deviceSelection(device): void {
    this.servicesAvailableForSelectedDevice = false;
    this.listOfAvailableServicesForSelectedDevice = [];
    const deviceTypeId = device.logical_device_type_id ? device.logical_device_type_id : device.device_type_id;
    if (this.selectedEndpoint && this.selectedEndpoint.services && this.selectedEndpoint.services.length > 0) {
      this.listOfAvailableServicesForSelectedDevice = this.selectedEndpoint.services.filter((sv) => {
        if (device.logical_device_type_id) {
          if (sv.logical_device_type_id) {
            return sv.logical_device_type_id.includes(deviceTypeId);
          } else {
            return false;
          }
        } else {
          if (sv.device_type_id) {
            return sv.device_type_id.includes(deviceTypeId);
          } else {
            return false;
          }
        }
      });
      if (this.listOfAvailableServicesForSelectedDevice.length > 0) {
        this.servicesAvailableForSelectedDevice = true;
      }
    }
  }
  serviceSelection(service): void {
    this.tunnelData.allow_multiple =
      service.allow_multiple === TunnelServiceOpt.TRUE || service.allow_multiple === TunnelServiceOpt.MUST_BE_TRUE
        ? true
        : false;
    this.tunnelData.disable_comms =
      service.disable_comms === TunnelServiceOpt.TRUE || service.disable_comms === TunnelServiceOpt.MUST_BE_TRUE
        ? true
        : false;
    this.tunnelData.disable_control =
      service.disable_control === TunnelServiceOpt.TRUE || service.disable_control === TunnelServiceOpt.MUST_BE_TRUE
        ? true
        : false;
    this.tunnelData.duration_value = service.duration_value;
    this.tunnelData.protocol = service.protocol;
    this.tunnelData.port = service.port;
  }
  numberNotValid(number): boolean {
    if (number >= 1 && Number.isInteger(number)) {
      return false;
    } else {
      return true;
    }
  }
  // TODO: need to type it
  async createTunnel(data): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let obj: any = {};
    if (this.showManualIpTunnelCreate) {
      obj = {
        client_ip_address: null,
        device_port: data.port,
        device_id: data.device.id,
        device_ip_address: data.device_ip_address,
        request_time: moment().format(),
      };
    } else {
      obj = {
        client_ip_address: null,
        device_port: data.port,
        device_id: data.device.id,
        device_service_type_id: data.service.id,
        duration_value: data.duration_value,
        action: TunnelAction.OPEN,
        disable_control: data.service.disable_control.includes(this.MUST_BE)
          ? data.service.disable_control
          : data.disable_control
            ? TunnelServiceOpt.TRUE
            : TunnelServiceOpt.FALSE,
        disable_comms: data.service.disable_comms.includes(this.MUST_BE)
          ? data.service.disable_comms
          : data.disable_comms
            ? TunnelServiceOpt.TRUE
            : TunnelServiceOpt.FALSE,
        allow_multiple: data.service.allow_multiple.includes(this.MUST_BE)
          ? data.service.allow_multiple
          : data.allow_multiple
            ? TunnelServiceOpt.TRUE
            : TunnelServiceOpt.FALSE,
        protocol: data.protocol,
        request_time: moment().format(),
      };
    }

    const { CreatingTunnel, VerifyingTunnel, CouldNotVerifyTunnel, UnableToGetUserIp } = this.trans.instant('UnitPage');
    const { Wait } = this.trans.instant('General');
    const { Failed } = this.trans.instant('General.Error');

    const loader = await this.genericAlertsToastsService.createWaitingLoaderWithCustomText(CreatingTunnel);
    await loader.present();

    // tunnel creation process
    // fetch the user ip
    // then create tunnel
    // then verify that tunnel
    // if verified, all good
    // if timeout or not verified, then close that tunnel
    // and refresh the list
    this.unitService
      .getUserIP()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then(async (res: any) => {
        if (res.data.ip) {
          obj.client_ip_address = res.data.ip;
          this.unitService
            .setupEndpointServiceLogsTunnels(data.device.id, obj)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((setup_res: any) => {
              loader.message = `${VerifyingTunnel}, ${Wait}`;
              this.unitService
                .checkEndpointServiceLogsTunnels(data.device.id, {
                  id: data.device.id,
                  client_ip_address: obj.client_ip_address,
                  server_port: setup_res.data.server_port,
                })
                .then(() => {
                  this.dismiss({
                    loader: loader,
                    refreshList: true,
                    endpoint: this.selectedEndpoint,
                  });
                })
                .catch((err) => {
                  loader.message = `${CouldNotVerifyTunnel}, ${err.statusText} ${err.error}, ${Wait}`;
                  this.dismiss({
                    loader: loader,
                    refreshList: true,
                    endpoint: this.selectedEndpoint,
                    cleanupTunnelData: {
                      id: data.device.id,
                      client_ip_address: obj.client_ip_address,
                      server_port: setup_res.data.server_port,
                    },
                  });
                });
            })
            .catch(async (err) => {
              const alert = await this.genericAlertsToastsService.createAlertWithOkButton(
                Failed,
                UnableToGetUserIp,
                `${err.status} ${err.statusText} ${err.error}`,
              );
              await alert.present();
              await loader.dismiss();
            });
        } else {
          const alert = await this.genericAlertsToastsService.createAlertWithOkButton(Failed, UnableToGetUserIp, '');
          await alert.present();
          await loader.dismiss();
        }
      })
      .catch(async (err) => {
        const alert = await this.genericAlertsToastsService.createAlertWithOkButton(
          Failed,
          UnableToGetUserIp,
          `${err.data}`,
        );
        await alert.present();
        await loader.dismiss();
      });
  }
}
