<div class="b-radius-12 fullHeight background">
  <div
    class="icon-text-wrapper p-20"
    *ngIf="(vm$ | async) == undefined || vm$ == undefined || (vm$ | async).loading; else noLoading"
  >
    <ion-icon class="pulsate-fwd" src="assets/icons/svg/select-device.svg"></ion-icon>
    <div [ngClass]="textColourClass" class="fs-20 ion-text-center">{{ 'SiteEnergy.Loading' | translate }}</div>
  </div>

  <ng-template #noLoading>
    <div class="icon-text-wrapper p-20" *ngIf="(vm$ | async).error && (vm$ | async); else noError">
      <ion-icon class="error-icon" src="assets/icons/svg/warning.svg"></ion-icon>
      <div [ngClass]="textColourClass" class="fs-20 ion-text-center">{{ (vm$ | async).error }}</div>
      <ion-button color="button-primary" class="width-100-pct mt-20" mode="ios" (click)="dismissError()">{{
        'General.OK' | translate
      }}</ion-button>
    </div>

    <ng-template #noError>
      <div *ngIf="(vm$ | async).data">
        <div class="icon-text-wrapper p-20" *ngIf="(vm$ | async).data.length <= 0; else endpointsPresent">
          <ion-icon src="assets/icons/svg/select-device.svg"></ion-icon>
          <div [ngClass]="textColourClass" class="fs-20 ion-text-center">
            {{ 'UnitPage.NoDropletsFound' | translate }}
          </div>
          <ion-button
            *ifHasPermission="[PermissionKey.GLOBAL_INSTALLER]"
            (click)="navigateToInstaller()"
            class="width-100-pct mt-20"
            color="button-primary"
            mode="ios"
            >{{ 'UnitPage.ClaimDroplet' | translate }}</ion-button
          >
        </div>
        <ng-template #endpointsPresent>
          <div
            class="icon-text-wrapper p-20"
            *ngIf="
              mode === DropletItemMode.DEVICE &&
              ((vm$ | async).displayedEndpointDeviceIndex == null ||
                (vm$ | async).displayedEndpointDeviceIndex.endpointIndex == null ||
                (vm$ | async).displayedEndpointDeviceIndex.deviceIndex == null)
            "
          >
            <ion-icon src="assets/icons/svg/select-device.svg"></ion-icon>
            <div [ngClass]="textColourClass" class="fs-20 ion-text-center">
              {{ 'UnitPage.SelectDeviceToPreview' | translate }}
            </div>
          </div>
          <div
            class="icon-text-wrapper p-20"
            *ngIf="
              mode === DropletItemMode.CONTROLLER &&
              ((vm$ | async).displayedEndpointControllerIndex == null ||
                (vm$ | async).displayedEndpointControllerIndex.endpointIndex == null ||
                (vm$ | async).displayedEndpointControllerIndex.controllerIndex == null)
            "
          >
            <ion-icon src="assets/icons/svg/select-device.svg"></ion-icon>
            <div class="fs-20 text-medium ion-text-center">{{ 'UnitPage.SelectControllerToPreview' | translate }}</div>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </ng-template>
</div>
