import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ThemeService } from '../../services/themes/theme.service';
import { AppHeaderService } from './../../services/common/app-header.service';
import { IsPlatformValues } from '@class/commons/is-platform-values';
import { HeaderFooterLogosStyle } from 'theme/theme-interface';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { SvgIconNames } from 'theme/theme-map';
import { AnalyticsService } from '@service/common/analytics.service';

interface HeaderViewModel {
  headerTitle: string;
  showBackButton: boolean;
  backDefaultHref: string;
  headerLogoPath: string;
  headerLogoAltName: string;
  headerLogoStyle: HeaderFooterLogosStyle;
  toolbarColor: string;
  backButtonIcon: string;
  sideMenuIcon: string;

  // this is only added for installer process as it behaves differently
  showInstallerBackButton: boolean;

  // this is only added for operational status text
  operationalStatusText: string;
  showActionPendingState: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent extends IsPlatformValues {
  @Output() installerBackButtonClick = new EventEmitter<boolean>();

  @Input()
  set operationalStatusText(value) {
    this.#operationalStatusText.next(value);
  }
  #operationalStatusText = new BehaviorSubject<string>(null);
  operationalStatusText$ = this.#operationalStatusText.asObservable();

  @Input()
  set showActionPendingState(value) {
    this.#showActionPendingState.next(value);
  }
  #showActionPendingState = new BehaviorSubject<boolean>(false);
  showActionPendingState$ = this.#showActionPendingState.asObservable();

  #headerService = inject(AppHeaderService);
  #themeService = inject(ThemeService);

  readonly #analytics = inject(AnalyticsService);
  enableUnitStatusPill = this.#analytics.plugins?.posthog?.isFeatureEnabled('unit-status-pill');

  headerViewModel$ = combineLatest([
    this.#headerService.headerTitle$,
    this.#headerService.showBackButton$,
    this.#headerService.backDefaultHref$,
    this.#headerService.showInstallerBackButton$,
    this.#themeService.userTheme$,
    this.operationalStatusText$,
    this.showActionPendingState$,
  ]).pipe(
    map(
      ([
        headerTitle,
        showBackButton,
        backDefaultHref,
        showInstallerBackButton,
        theme,
        operationalStatusText,
        showActionPendingState,
      ]): HeaderViewModel => ({
        headerTitle,
        showBackButton,
        backDefaultHref,
        headerLogoPath: theme.headerLogo.path,
        headerLogoAltName: theme.name,
        headerLogoStyle: theme.headerLogo.style,
        toolbarColor: this.IS_PLATFORM_DESKTOP ? 'text-tertiary-contrast' : 'background',
        backButtonIcon: `${theme.iconsSvgPath}${SvgIconNames.BACK_ARROW}`,
        sideMenuIcon: `${theme.iconsSvgPath}${SvgIconNames.HAMBURGER_FILL}`,
        showInstallerBackButton,
        operationalStatusText,
        showActionPendingState,
      }),
    ),
  );

  constructor() {
    super();
  }

  installerBackButtonClickHandler(): void {
    this.installerBackButtonClick.emit(true);
  }
}
