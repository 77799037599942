<ion-grid
  class="ion-no-margin ion-no-padding"
  *ngIf="
    !(vm$ | async).loading &&
    !(vm$ | async).error &&
    (vm$ | async).data.payloadType === _DataStructureEnum.COWBOYS &&
    (vm$ | async).data as vm
  "
>
  <ion-row>
    <ion-col class="p-8" size="12">
      <ion-card class="p-0 m-0 new-design-card overflow-x-scroll">
        <div class="fs-22 fw-600 mt-25 mb-15 ml-24 text-dark">
          {{ 'Cost.Tariff.SummaryOfCost' | translate }}
        </div>
        <table class="width-100-pct">
          <tr>
            <td>{{ 'Cost.TariffTitle' | translate }}</td>
            <td>{{ 'Cost.Tariff.Demand' | translate }}</td>
            <td>{{ 'Cost.Tariff.Usage' | translate }}</td>
            <td>{{ 'Cost.Tariff.Service' | translate }}</td>
            <td>{{ 'Cost.Tariff.Total' | translate }}</td>
          </tr>
          <tr *ngFor="let charge of vm.charges" class="content-row">
            <td class="fs-14 fw-600 text-dark">{{ charge.name }}</td>
            <td class="demand-cell">
              ${{ charge.summary.demand | number: '1.2-2' }} {{ charge.summary.demandSuffix }}
            </td>
            <td class="usage-cell">${{ charge.summary.usage | number: '1.2-2' }} {{ charge.summary.usageSuffix }}</td>
            <td class="service-cell">
              ${{ charge.summary.service | number: '1.2-2' }} {{ charge.summary.serviceSuffix }}
            </td>
            <td class="total-cell">${{ charge.summary.total | number: '1.2-2' }} {{ charge.summary.totalSuffix }}</td>
          </tr>
        </table>
        <ion-grid *ngIf="vm.savings" class="pl-24 pt-20 pr-10 pb-10">
          <ion-row>
            <ion-col size-md="9" size="12" class="pl-0 pr-50">
              <ion-text class="lh-20">
                {{ vm.savings.description }}
              </ion-text>
            </ion-col>
            <ion-col size-md="3" size="12" class="pl-0">
              <div class="savings-div">
                <div class="fs-16 fw-500">{{ 'Cost.TotalSavings' | translate }}</div>
                <div class="savings-value">
                  <span class="text-light fs-18 fw-400">$</span>{{ vm.savings.value | number: '1.2-2' }}
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
<ng-container *ngIf="(vm$ | async).loading">
  <app-skeleton-items
    [skeletonType]="SkeletonPlaceholder.TABLE_WITH_HEADER_AND_FOOTER"
    [showCard]="true"
  ></app-skeleton-items>
</ng-container>
