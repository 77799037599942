<div
  *ngIf="vm$ == null || (vm$ | async) == null || (vm$ | async)?.loading; else finishLoading"
  class="icon-text-wrapper"
>
  <ion-icon class="status-icon pulsate-fwd" src="assets/icons/svg/select-device.svg"></ion-icon>
  <div *ngIf="vm$ == null || (vm$ | async) == null" class="m-30 fs-20">
    {{ 'UnitPage.PleaseWait' | translate }}
  </div>
  <div *ngIf="(vm$ | async)?.loading" class="m-30 fs-20">
    {{ (vm$ | async)?.loading }}
  </div>
  <ion-button fill="outline" color="button-primary" mode="ios" class="m-0" (click)="dismiss()">
    {{ 'General.Cancel' | translate }}
  </ion-button>
</div>
<ng-template #finishLoading>
  <ion-content class="ion-padding" *ngIf="vm$ | async as vm">
    <div *ngIf="vm.error; else modalOk" class="icon-text-wrapper p-20">
      <ion-icon class="status-icon" src="assets/icons/svg/warning.svg"></ion-icon>
      <div class="fs-20 text-medium ion-text-center">{{ vm.error }}</div>
    </div>
    <ng-template #modalOk>
      <ion-list *ngIf="vm.data as data">
        <div class="fs-22 fw-500 mb-15">
          {{ 'VirtualPowerPlant.EditControlGroup' | translate }}
        </div>
        <div class="mb-5 fs-13 text-medium">{{ 'VirtualPowerPlant.Name' | translate }}*</div>
        <ion-input
          class="mb-5 ion-padding-start"
          [(ngModel)]="controlGroupEdit.name"
          maxlength="5"
          type="text"
          required
        ></ion-input>
        <div class="mb-5 fs-13 text-medium">
          {{ 'VirtualPowerPlant.Description' | translate }}
        </div>
        <ion-input class="mb-30 ion-padding-start" [(ngModel)]="controlGroupEdit.description"></ion-input>
        <div *ifHasPermission="[PermissionKey.VPP_CHANGE_CONTROLGROUP_UNIT]">
          <div class="fs-18 fw-500 mb-12">{{ 'VirtualPowerPlant.ControlGroupUnitDevices' | translate }}</div>
          <ion-searchbar
            showCancelButton="never"
            placeholder="Filter"
            autocomplete="on"
            [debounce]="300"
            (ionInput)="applyFilter($event.target.value)"
          ></ion-searchbar>
          <div>
            <table mat-table [dataSource]="unitRowsDataSource" class="width-100-pct">
              <ng-container *ngFor="let deviceClass of deviceClasses" [matColumnDef]="deviceClass">
                <th class="p-0 ion-text-center" mat-header-cell *matHeaderCellDef>
                  <ion-checkbox
                    [ngModel]="
                      (data?.overallSelect?.selectClass)[deviceClass] === VppControlGroupEditCheckboxState.CHECKED
                    "
                    color="button-secondary"
                    (ngModelChange)="selectAllDevicesClass($event, deviceClass)"
                  >
                  </ion-checkbox>
                </th>
              </ng-container>
              <!-- Checkbox Column -->
              <!-- Select All Checkbox -->
              <ng-container matColumnDef="select2">
                <th class="width-10-pct p-0" mat-header-cell *matHeaderCellDef rowspan="2">
                  <ion-checkbox
                    [ngModel]="data?.overallSelect?.selectAll === VppControlGroupEditCheckboxState.CHECKED"
                    color="button-secondary"
                    (ngModelChange)="selectAllUnitDevices($event)"
                  >
                  </ion-checkbox>
                </th>
                <td mat-cell *matCellDef="let unit">
                  <!-- Select all units checkbox -->
                  <ion-icon
                    color="danger"
                    *ngIf="unit?.checkboxes?.selectAll === VppControlGroupEditCheckboxState.DISABLED; else notDisabled"
                    class="iconFS"
                    name="close"
                  ></ion-icon>
                  <ng-template #notDisabled>
                    <ion-checkbox
                      [ngModel]="unit?.checkboxes?.selectAll === VppControlGroupEditCheckboxState.CHECKED"
                      color="button-secondary"
                      (ngModelChange)="selectAllDevicesInUnit($event, unit.uuid)"
                    >
                    </ion-checkbox>
                  </ng-template>
                </td>
              </ng-container>

              <ng-container matColumnDef="name2">
                <th class="width-40-pct" mat-header-cell *matHeaderCellDef rowspan="2">
                  {{ 'UnitDetails.UnitName' | translate }}
                </th>
                <td mat-cell *matCellDef="let unit">{{ unit?.unitName }}</td>
              </ng-container>

              <!-- device classes checkbox -->
              <ng-container
                *ngFor="let deviceClass of deviceClasses"
                [matColumnDef]="deviceClassToRow2Map[deviceClass]"
              >
                <th class="ion-text-center p-0" mat-header-cell *matHeaderCellDef>
                  <div class="tooltip">
                    <span class="tooltiptext">{{ deviceIconMap[deviceClass].iconHoverText | translate }}</span>
                    <ion-icon class="iconFS" [name]="deviceIconMap[deviceClass].iconName"></ion-icon>
                  </div>
                </th>
                <td class="ion-text-center p-0" mat-cell *matCellDef="let unit">
                  <ion-icon
                    color="danger"
                    *ngIf="
                      (unit?.checkboxes?.selectClass)[deviceClass] === VppControlGroupEditCheckboxState.DISABLED;
                      else notDisabled
                    "
                    class="iconFS"
                    name="close"
                  ></ion-icon>
                  <ng-template #notDisabled>
                    <ion-checkbox
                      [ngModel]="
                        (unit?.checkboxes?.selectClass)[deviceClass] === VppControlGroupEditCheckboxState.CHECKED
                      "
                      color="button-secondary"
                      (ngModelChange)="selectSingleUnitDevice($event, unit.uuid, deviceClass)"
                    >
                    </ion-checkbox>
                  </ng-template>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedRow2Columns" class="firstRowHeader"></tr>
              <tr mat-header-row *matHeaderRowDef="displayedRow1Columns" class="firstRowHeader"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedRow2Columns"></tr>
            </table>
          </div>
        </div>
      </ion-list>
    </ng-template>
    <mat-paginator [hidden]="!vm?.data || vm.loading || vm.error" [pageSizeOptions]="[5, 10, 25, 50]"></mat-paginator>
  </ion-content>
  <div class="m-0 name-button-row">
    <ion-button fill="outline" color="button-primary" mode="ios" class="m-0 cancel-name-button" (click)="dismiss()">
      {{ 'General.Cancel' | translate }}
    </ion-button>
    <ion-button
      [attr.disabled]="controlGroupEdit?.name == null || (vm$ | async).loading || (vm$ | async).error"
      mode="ios"
      color="button-primary"
      class="m-0 update-name-button"
      (click)="submit()"
    >
      {{ 'VirtualPowerPlant.UpdateControlGroup' | translate }}
    </ion-button>
  </div>
</ng-template>
