import { Component, OnInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { IonModal, ToastController } from '@ionic/angular';
import { UnitsService } from '../../services/units/units.service';
import { ThemeService } from '../../services/themes/theme.service';
import Plotly from 'plotly.js-dist';
import { TranslationsService } from '../../services/common/translations.service';
import { ProfileService } from '../../services/profile/profile.service';
import { DeviceMqttKey } from '@class/units/droplet/droplet-metric.model';
import { DateFormats, TimePeriodResolution } from '@class/commons/constants-datetime';
import { MAX_WIDTH_WIDE, TOTAL_COL } from '@class/commons/constants';
import { ColBreakpoint, PlotlyFillMode, PlotlyTraceModes, PlotlyTraceType } from '@class/commons/enums';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { isEmpty, debounce } from 'lodash';
import { DateTime, DateTimeUnit } from 'luxon';
import { PlotColors, PlotlyTrace } from '@class/commons/plotly.models';
import {
  CachedData,
  ChartDataDTO,
  DeviceControllerMetrics,
  MetricDataDTO,
} from '@class/units/unitanalytics/unit-analytics.models';
import { GenericAlertsToastsService } from '@service/common/generic-alerts-toasts.service';
import { MetricInfoProperties } from '../unit-analytic-legend/unit-analytic-legend.component';
import { IsPlatform } from '@class/commons/is-platform';

type ChartData = { data: ChartDataDTO };
type MetricUnits = {
  name: string;
  unit: string;
};

const getMetricResolutionForSelectedPeriod: Record<
  TimePeriodResolution.DAY | TimePeriodResolution.WEEK | TimePeriodResolution.MONTH | TimePeriodResolution.YEAR,
  TimePeriodResolution.MINUTE | TimePeriodResolution.HOUR | TimePeriodResolution.DAY
> = {
  [TimePeriodResolution.DAY]: TimePeriodResolution.MINUTE,
  [TimePeriodResolution.WEEK]: TimePeriodResolution.HOUR,
  [TimePeriodResolution.MONTH]: TimePeriodResolution.HOUR,
  [TimePeriodResolution.YEAR]: TimePeriodResolution.DAY,
};

const DEBOUNCE_TIME_MS: number = 500; //  Limit click events

@Component({
  selector: 'app-unit-analytic',
  templateUrl: './unit-analytic.component.html',
  styleUrls: ['./unit-analytic.component.scss'],
})
export class UnitAnalyticComponent implements OnInit, OnDestroy {
  private plotLayout = {
    showlegend: false,
    autosize: true,
    xaxis: {
      showgrid: false,
      fixedrange: false,
      linecolor: 'rgba(0, 0, 0, 0)',
      autorange: false,
      rangeslider: { bgcolor: 'rgba(0, 0, 0, 0)' },
      tickfont: { color: '#000000' },
    },
    yaxis: {
      showgrid: false,
      fixedrange: false,
      linecolor: 'rgba(0, 0, 0, 0)',
      tickfont: { color: '#000000' },
      autorange: true,
    },
    margin: {
      l: 40,
      r: 40,
      t: 10,
      b: 40,
    },
    annotations: [],
    plot_bgcolor: 'rgba(0, 0, 0, 0)',
    paper_bgcolor: 'rgba(0, 0, 0, 0)',
    hovermode: 'x',
  };
  private plotStyle = {
    displayModeBar: false,
    // responsive: true
  };
  ionDatePickerPresentation: 'date' | 'month-year' | 'year' = 'date';
  readonly periodSelection = [
    {
      name: this.trans.instant('General.Day'),
      value: TimePeriodResolution.DAY,
    },
    {
      name: this.trans.instant('General.Week'),
      value: TimePeriodResolution.WEEK,
    },
    {
      name: this.trans.instant('General.Month'),
      value: TimePeriodResolution.MONTH,
    },
    {
      name: this.trans.instant('General.Year'),
      value: TimePeriodResolution.YEAR,
    },
  ];

  private plotColors: Array<PlotColors> = [];
  unitTimezone: string;
  private currentPeriodStartTimeInMS: number;
  private currentPeriodEndTimeInMS: number;
  private metricResolution: TimePeriodResolution.MINUTE | TimePeriodResolution.HOUR | TimePeriodResolution.DAY =
    TimePeriodResolution.MINUTE;
  private changeMaxDateTimeout: NodeJS.Timeout; // this will hold the timeout function if someone leave the app open whole night,
  private cachedGraphData: CachedData[] = [];
  private availableColors: Array<PlotColors> = [];
  private unitUuid = '';
  allMetricsKeyNameAndAbbreviation: { [index: string]: MetricUnits } = {};
  readonly ionMinDate: DateTime = DateTime.local(2010, 1, 4);
  ionMaxDate: DateTime;
  currentPeriodLength: DateTimeUnit = TimePeriodResolution.DAY;
  // this is for refresh button icon
  rotation = false;
  // this is for showing loader when the data is getting called from server
  showLoader = false;
  // this is for  to disable buttons if the request in progress
  disableButtons: boolean = false;
  currentSelectedDate: DateTime;
  currentSelectedDateOffsetInMin: number;
  disableNext = true;
  disablePrevious = false;
  legendInfo: Array<MetricInfoProperties>;
  isDataMetricEmpty: boolean;
  readonly isMobileView = IsPlatform.MOBILE || IsPlatform.MOBILE_WEB;
  // then will run this timeout to update the max date
  @ViewChild('performanceChart', { static: false }) performanceChart: ElementRef;
  @ViewChild('modal', { static: true }) modal!: IonModal;

  devicesAndControllersMetrics: Array<DeviceControllerMetrics> = [];
  selectedMetrics: Array<string> = [];

  readonly TimePeriodResolution = TimePeriodResolution;
  readonly PermissionKey = PermissionKey;

  constructor(
    private unitsService: UnitsService,
    private theme: ThemeService,
    private toast: ToastController,
    private trans: TranslationsService,
    private permissionsService: PermissionsService,
    private profileService: ProfileService,
    private _genericAlertsToastsService: GenericAlertsToastsService,
  ) {}

  @HostListener('window:resize', ['$event'])
  handleResize(): void {
    this.resize();
  }

  resize(): void {
    try {
      const element = this.performanceChart.nativeElement;
      const analyticsLegendCompColSize = 3;
      if (element.clientWidth <= 0) {
        if (window.innerWidth >= MAX_WIDTH_WIDE) {
          this.plotLayout['width'] = MAX_WIDTH_WIDE * 0.95 - (MAX_WIDTH_WIDE / TOTAL_COL) * analyticsLegendCompColSize;
        } else if (window.innerWidth >= ColBreakpoint.LG) {
          this.plotLayout['width'] =
            window.innerWidth * 0.95 - (window.innerWidth / TOTAL_COL) * analyticsLegendCompColSize;
        }
      } else {
        this.plotLayout['width'] = element.clientWidth;
        this.plotLayout['height'] = window.innerHeight - 350 < 500 ? 500 : window.innerHeight - 350;
      }
      Plotly.relayout(element, this.plotLayout);
    } catch (error) {
      // error if resized before the graph got plotted
    }
  }

  ngOnInit(): void {
    this.setPlotColors();

    // setup the max date midnight rollover
    // NOTE: requires this.unitTimezone to be set

    /* ** ***
    Leaving these comments in here, because `new Date()` obj will return local timezone
    and `moment.tz` will return the time in timezone we require
    we can get rid of the offset, if we have timezone by not using `new Date()`
    I'll dig into this more when I have time
    */

    // this.unitTimezone = 'Asia/Tokyo';
    // let d = moment();
    // console.log(d.tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss Z'));
    // console.log(d.tz('America/Los_Angeles').set({TimePeriodResolution.HOUR: 0, 'minute': 0, 'second': 0, 'millisecond': 0}).toString());
    // console.log(d.tz('America/Los_Angeles').toLocaleString());
    // console.log((d.tz('America/Los_Angeles')).toISOString());
    // console.log(jun.tz('Australia/Sydney').format('ha z'));
    // console.log(this.unitTimezone);

    // ensure timezone setup
    // Setting up the unit timezone or user browser timezone
    this.setUnitTimezoneAndUuid();
    // setting up the initial Date and offset
    this.setInitialDatesAndOffset();
    // setting the start, end date and metric resolution of selectedDate and periodLength
    this.setStartDateAndEndDateAndMetricResolution(this.currentPeriodLength, this.currentSelectedDate);

    this.setTimeoutForMaxDate();

    // setting the devices and controllers metrics date from endpoints
    this.setDevicesAndControllersMetricsFromEndpoints(this.unitsService.unitEndpoints.endpoints);
    this.initializeSelectedMetricsWithDefaultMetrics();
  }
  isFirstDayOfWeek = (dateString: string) => {
    const date = new Date(dateString);
    const utcDay = date.getUTCDay();
    let showDates = true;
    if (this.currentPeriodLength === TimePeriodResolution.WEEK) {
      /**
       * Date will be enabled if it is
       * first day of the week
       */
      showDates = utcDay === 1;
    }
    return showDates;
  };

  private initializeSelectedMetricsWithDefaultMetrics(): void {
    // set to the default selections
    this.selectedMetrics = this.getDefaultSelectedMetrics();
    // emit the change
    this.onMetricChange(false, this.selectedMetrics);
  }

  private setDevicesAndControllersMetricsFromEndpoints(endpoints): void {
    this.devicesAndControllersMetrics = this.getMetricsFromDevicesAndControllers(endpoints);
  }

  ngOnDestroy(): void {
    if (this.changeMaxDateTimeout) {
      clearTimeout(this.changeMaxDateTimeout);
    }
  }

  //  After setting the unit Timezone  we got the time and offset of that timezone
  //  Then assigned the offset to currentSelectedDateOffsetInMin to get the start and end of the day in MS
  //  sets the MaxDate to current unitTime zone Date

  private setInitialDatesAndOffset(): void {
    this.currentSelectedDate = DateTime.local().setZone(this.unitTimezone);
    this.ionMaxDate = DateTime.local().setZone(this.unitTimezone);
    this.currentSelectedDateOffsetInMin = this.currentSelectedDate.offset;
  }

  private setUnitTimezoneAndUuid(): void {
    this.unitTimezone =
      this.unitsService.selectedUnit.timezone && this.unitsService.selectedUnit.timezone !== 'Etc/UTC'
        ? this.unitsService.selectedUnit.timezone
        : DateTime.local().zoneName;

    // this.unitTimezone = DateTime.local().zoneName;

    this.unitUuid = this.unitsService.selectedUnit.uuid;
  }

  // getting the time diff till end of the day in milliseconds
  private getTimeDiffNowToEndOfDay(): number {
    const timeNow = DateTime.local().setZone(this.unitTimezone);
    const timeTillEndOfDay = timeNow.endOf(TimePeriodResolution.DAY).toMillis();
    return timeTillEndOfDay - timeNow.toMillis();
  }

  private resetMaxDateAndSelectedDate() {
    // resetting the maxDate
    this.ionMaxDate = DateTime.local().setZone(this.unitTimezone).endOf(TimePeriodResolution.DAY);

    // reset/clone the 'current Date' re-setting  this way only to trigger the pipe change so it refreshes 'today' label (uses DateLabelPipe)
    this.currentSelectedDate = DateTime.local().set({
      day: this.currentSelectedDate.day,
      month: this.currentSelectedDate.month,
      year: this.currentSelectedDate.year,
    });
  }

  private setTimeoutForMaxDate(): void {
    // the maximum date on the date picker needs to be reset at midnight (day rollover)
    // in-case someone leaves the app running overnight
    // kick-off a delayed function (timeout until midnight) to reset it
    // NOTE: need to also reset the 'next day arrow navigator' and the 'today' text

    /**
     *  we are not taking the users to next day because we do not know user wants to see the data of next day or not
     *  so, we are resetting the maximum Date on the date picker,'next day arrow navigator' & refresh the 'today' label to yesterday in the setTimeOut()
     *  after execution of the setTimeOut users can see the ( next day arrow navigator is enabled, maximum date on date picker is changed and date label changed to yesterday)
     *  and then user can go to next day by the next arrow or from date picker
     *
     * NOTE: to update the date label without changing the current Date we need to set the Date Label pipe to impure pipe so, pipe can detect the changes
     *       we do not want to use the impure pipe that's why resetting the currentDate to same currentDate without changing it .
     */

    // calc milliseconds until end of day (for timeout)
    let milliSecondsTillEndOfDay: number;

    milliSecondsTillEndOfDay = this.getTimeDiffNowToEndOfDay();
    milliSecondsTillEndOfDay += 5000; // adding 5 more seconds, just for to make sure when the timeout happens it should be in next day

    // clear any existing timeout
    if (this.changeMaxDateTimeout) {
      clearTimeout(this.changeMaxDateTimeout);
    }

    // create the timeout to fire at midnight
    this.changeMaxDateTimeout = setTimeout(() => {
      // resetting the maxDate
      this.resetMaxDateAndSelectedDate();
      //  re-checking the status of prev and next
      this.setPreviousNextButtonsDisableStat(this.currentPeriodLength, this.currentSelectedDate);
      // re-run the trigger for the next day change
      this.setTimeoutForMaxDate();
    }, milliSecondsTillEndOfDay);
  }

  private setPlotColors(): void {
    this.plotColors = this.theme.getChartColorValues();
  }

  /**
   * below func() setting up the DateTime Start Day End Day Status of Prev Next
   * these comments here to understand the flow of func
   * I have rearranged the func for better understanding coz the file is very big
   *
   * onPrevious() => takes the user to previous day | week | month | year
   *
   * onNext()
   * takes the user to next day | week | month | year
   * Only if selected date does not match with Max Date
   *
   * dateChanged(moment)
   * got called every time when Date changed
   * setting up the disable status of Next and Previous button
   * (more info in the function body)
   *
   * setPreviousNextButtonsDisableStat(currentDate,PeriodLength)
   * calls the isNextButtonDisabled() & isPreviousButtonDisabled()
   * to update the status of Next and Previous arrow button
   *
   * setStartDateAndEndDateAndMetricResolution(...) => settings the Start and End of Day in Ms
   * we need start and end of time to show on the x-axis of Plotly
   *
   * OnPeriodChange() calls the onMetricChange(..) to get the Metric Date according to User selected Time
   */

  // By using the debounce func limiting the click events call on prev arrow button

  debounceOnNextOrPrev = debounce(this.onNextOrPrevButtonClick, DEBOUNCE_TIME_MS);
  private onNextOrPrevButtonClick(duration: number = 1): void {
    this.dateChanged(this.currentSelectedDate.plus({ [this.currentPeriodLength]: duration }));
  }

  dateChangedCalendar(dateString: string) {
    const newDate = DateTime.fromISO(dateString);
    this.dateChanged(newDate);
  }
  dateChanged(ev: DateTime): void {
    // console.log('ev ', ev);
    // Here we are setting the year month and date according to the unit timezone
    // if the user timezone is more or less than unitTimeZone than
    // by ev.tz(this.unitTimezone) gives us the previous day or next day (depends on GMT+-)
    // To avoid this issue we are setting the year month and date
    // this.currentSelectedDate = moment(ev).tz(this.unitTimezone).year(ev.year()).month(ev.month()).date(ev.date());
    this.currentSelectedDate = DateTime.local().setZone(this.unitTimezone);
    this.currentSelectedDate = this.currentSelectedDate.set({ year: ev.year, month: ev.month, day: ev.day });
    this.onPeriodChange();
  }

  private setPreviousNextButtonsDisableStat(currentPeriodLength: DateTimeUnit, currentSelectedDate: DateTime): void {
    this.disableNext = this.isNextButtonDisabled(currentPeriodLength, currentSelectedDate);
    this.disablePrevious = this.isPreviousButtonDisabled(currentPeriodLength, currentSelectedDate);
  }
  private isNextButtonDisabled(currentPeriodLength: DateTimeUnit, currentSelectedDate: DateTime): boolean {
    // return !currentSelectedDate.isBefore(
    //   moment
    //     .tz(this.unitTimezone)
    //     .startOf(currentPeriodLength === TimePeriodResolution.WEEK ? 'isoWeek' : currentPeriodLength),
    // );
    return currentSelectedDate.hasSame(this.ionMaxDate, currentPeriodLength);
  }
  private isPreviousButtonDisabled(currentPeriodLength: DateTimeUnit, currentSelectedDate: DateTime): boolean {
    // return !currentSelectedDate.isAfter(
    //   moment(this.ionMinDate).startOf(
    //     currentPeriodLength === TimePeriodResolution.WEEK ? 'isoWeek' : currentPeriodLength,
    //   ),
    // );
    return currentSelectedDate <= this.ionMinDate.startOf(currentPeriodLength);
  }
  private setStartDateAndEndDateAndMetricResolution(
    currentPeriodLength: DateTimeUnit,
    currentSelectedDate: DateTime,
  ): void {
    // console.log('current selected', currentSelectedDate.toString());
    this.currentPeriodEndTimeInMS = currentSelectedDate.endOf(currentPeriodLength).toMillis();
    this.currentSelectedDate = currentSelectedDate.startOf(currentPeriodLength);
    this.currentPeriodStartTimeInMS = currentSelectedDate.startOf(currentPeriodLength).toMillis();
    // console.log('current selected', currentSelectedDate.toString());
    // console.log('current selected start of', currentSelectedDate.startOf(currentPeriodLength).toString());
    this.metricResolution = getMetricResolutionForSelectedPeriod[currentPeriodLength];
  }

  capturePeriodChange(value) {
    this.currentPeriodLength = value;
    this.onPeriodChange();
  }
  onPeriodChange(): void {
    switch (this.currentPeriodLength) {
      case TimePeriodResolution.DAY:
      case TimePeriodResolution.WEEK:
        this.ionDatePickerPresentation = 'date';
        break;
      case TimePeriodResolution.MONTH:
        this.ionDatePickerPresentation = 'month-year';
        break;
      case TimePeriodResolution.YEAR:
        this.ionDatePickerPresentation = 'year';
        break;
      default:
        this.ionDatePickerPresentation = 'date';
    }
    this.setDataTraceToNull();
    this.setStartDateAndEndDateAndMetricResolution(this.currentPeriodLength, this.currentSelectedDate);
    this.setPreviousNextButtonsDisableStat(this.currentPeriodLength, this.currentSelectedDate);
    this.onMetricChange(true, this.selectedMetrics);
  }

  /**
   * OnMetricChange(...) sends the request to server to get the data of Metrics
   * first time we received the data of 4 pre selected metrics
   * Every time when user select the new metrics it sends the request and get the data of that metric
   * saved the selected metrics in cachedGraphDate list and updates it when we receive the new Metric
   * when user deselect the metric we removed it from cachedGraphData list
   * if the metric has no data then only shows the name else draw the graph
   * Calling the getLineTrace getScatter Trace function to display the line or scatter on graph
   * Updating the legend info cached data Metric as well
   */

  private async onMetricChange(updated = false, selectedMetrics: Array<string>): Promise<void> {
    this.setLoaderRotationAndDisabledButton(true);
    // update the cache data to have the data of only those metrics who are currently selected
    this.cachedGraphData = this.getSelectedMetricsDataFromCache(selectedMetrics);
    // update the  available graph colors list
    this.availableColors = this.getAvailableGraphColors();
    // filtering those metrics that already have data in cache with data trace
    const newGraphMetrics = this.getMetricsListOfSelectedMetricsFromCachedData(selectedMetrics);
    if (newGraphMetrics.length > 0) {
      try {
        const newGraphData = await this.getMetricData(newGraphMetrics);
        if (isEmpty(newGraphData.data)) {
          // insert empty Data in cached if selected metric has no data
          this.addEmptyDataInCachedIfSelectedMetricHasNoData(newGraphMetrics);
        } else {
          // if metric has data insert into cached with metric data
          this.addDataInCachedIfMetricHasData(newGraphData);
        }
      } catch (error) {
        this.refreshedToastFail();
        this.setLoaderRotationAndDisabledButton(false);
        const errorMsg = this.trans.instant('Metric.DataNotFound');
        this.metricNotSelectedOrDataNotFound(errorMsg);
        return;
      }
    }
    // updating Graph if we have date in the cache
    if (this.cachedGraphData.length > 0) {
      this.updateGraphAndLegendInfoFromCache(updated);
      this.setLoaderRotationAndDisabledButton(false);
    } else {
      const errorMsg = this.trans.instant('UnitPage.MetricNotSelected');
      this.metricNotSelectedOrDataNotFound(errorMsg);
    }
  }

  private async getMetricData(newGraphMetrics: string[]): Promise<ChartData> {
    // loads the metric data from units service with appropriate params
    const result = (await this.unitsService.getMetricData(
      'unit_uuid',
      this.unitUuid,
      newGraphMetrics,
      this.currentPeriodStartTimeInMS,
      this.currentPeriodEndTimeInMS,
      this.metricResolution,
    )) as { data: ChartDataDTO };
    return result;
  }

  private setLoaderRotationAndDisabledButton(enabled: boolean): void {
    //  the loader/rotation is used when loading data
    this.showLoader = enabled;
    this.disableButtons = enabled;
    this.rotation = enabled;
  }

  private addEmptyDataInCachedIfSelectedMetricHasNoData(newGraphMetrics: Array<string>): void {
    newGraphMetrics.forEach((metricKey) => {
      const indexOfMetric = this.getIndexOfMetricFromCachedData(metricKey);
      const colors = this.getGraphColorsFromCachedData(indexOfMetric);
      const trace = this.getLineTrace([], [], metricKey, colors);
      if (indexOfMetric !== -1) {
        this.updateDataTracesOnCachedData(indexOfMetric, trace);
      } else {
        this.addDataInCachedGraphData(metricKey, trace);
      }
    });
  }

  private addDataInCachedIfMetricHasData(newGraphData: { data: ChartDataDTO }): void {
    for (const metricKey in newGraphData.data) {
      if (newGraphData.data.hasOwnProperty(metricKey)) {
        const metricData = newGraphData.data[metricKey];
        // getting the indexOf Metrics from cached Data so we can update the dataTrace
        const indexOfMetric = this.getIndexOfMetricFromCachedData(metricKey);
        // getting the color of already existed metrics
        const colors = this.getGraphColorsFromCachedData(indexOfMetric);
        const trace = this.getDataForLineTrace(metricData, metricKey, colors);
        const scatter = this.getDataForScatterTrace(trace.x, trace.y, metricKey, trace.line.color);
        // first time we want to add all the data to cachedGraphData so else part will execute
        // if part will execute only onRefresh and on Period change functions and update the traces of cached Data
        if (indexOfMetric !== -1) {
          this.updateDataTracesOnCachedData(indexOfMetric, trace);
        } else {
          this.addDataInCachedGraphData(metricKey, trace);
          if (scatter !== null) {
            this.addDataInCachedGraphData(metricKey, scatter);
          }
        }
      }
    }
  }

  private updateGraphAndLegendInfoFromCache(updated: boolean): void {
    this.updateLegendInfo();
    this.updatePlotlyGraphSettings();
    const graphData = this.getTracesFromCachedData();
    this.renderGraph(updated, graphData);
  }

  private getIndexOfMetricFromCachedData(metricKey: string): number {
    const index = this.cachedGraphData.findIndex((metric) => {
      return metric.metricKey === metricKey;
    });
    return index;
  }

  private getGraphColorsFromCachedData(index: number): PlotColors | null {
    // getting the colors of already existed metric
    const colors = index !== -1 ? this.cachedGraphData[index].dataColor : null;
    return colors;
  }

  private updateDataTracesOnCachedData(index: number, trace: PlotlyTrace): void {
    // updated the dataTrace of already existed metric on a refresh and period change call
    this.cachedGraphData[index].dataTrace = trace;
  }

  private getTracesFromCachedData(): Array<PlotlyTrace> {
    // getting the Data Trace list from the cachedGraphData
    return this.cachedGraphData.map((metric) => metric.dataTrace);
  }

  private async showAlertMessage(): Promise<void> {
    const alert = await this._genericAlertsToastsService.createErrorAlertWithOkButton({
      heading: this.trans.instant('General.Metric'),
      subheading: '',
      message: this.trans.instant('UnitPage.SelectedMetricsLimitError'),
    });
    await alert.present();
  }

  metricSelectionChanged(selectedMetrics: string[]): void {
    this.selectedMetrics = [...selectedMetrics];
    this.modal.dismiss();
    this.onMetricChange(true, selectedMetrics);
  }
  private updateLegendInfo(): void {
    this.legendInfo = [];
    this.cachedGraphData.forEach((trace) => {
      if (trace.dataTrace.mode === PlotlyTraceModes.LINES) {
        const metricName = trace.dataTrace.name;
        const metricColor = trace.dataColor.value;
        const metricAbbreviation = trace.dataTrace.metricAbbr;
        this.legendInfo.push({ metricName, metricColor, metricAbbreviation });
      }
    });
  }

  private updatePlotlyGraphSettings(): void {
    this.plotLayout.annotations.length = 0;
    this.isDataMetricEmpty = this.cachedGraphData.filter((data) => data.dataTrace.y.length !== 0).length <= 0;
    if (this.isDataMetricEmpty) {
      this.plotLayout.annotations.push({
        text: this.trans.instant('Metric.DataNotFound'),
        showarrow: false,
        font: {
          size: 18,
          color: 'medium',
        },
      });
    }

    // setting the zone for the start and end date of selected day
    // if I removed the setZone from here then DataTIme picks the system time zone
    const currentPeriodStartTime = DateTime.fromMillis(this.currentPeriodStartTimeInMS)
      .setZone(this.unitTimezone)
      .toString();
    const currentPeriodEndTime = DateTime.fromMillis(this.currentPeriodEndTimeInMS)
      .setZone(this.unitTimezone)
      .toString();

    this.plotLayout.xaxis['range'] = [currentPeriodStartTime, currentPeriodEndTime];
    this.plotLayout.xaxis.rangeslider['range'] = [currentPeriodStartTime, currentPeriodEndTime];
    this.plotLayout.xaxis['type'] = 'date';
    this.plotLayout.xaxis.autorange = false;
    this.plotLayout.yaxis.tickfont.color = this.profileService.darkThemeSelected() ? '#ffffff' : '#394249';
    this.plotLayout.xaxis.tickfont.color = this.profileService.darkThemeSelected() ? '#ffffff' : '#394249';
    this.plotLayout.plot_bgcolor = 'rgba(0, 0, 0, 0)';
    this.plotLayout.paper_bgcolor = 'rgba(0, 0, 0, 0)';
    this.plotLayout.yaxis.linecolor = 'rgba(0, 0, 0, 0)';
    this.plotLayout.yaxis.autorange = true;
  }

  private renderGraph(updated: boolean, graphData: Array<PlotlyTrace>): void {
    // Plotly.react was efficient, it has same signature as newPlot
    // but newPlot will create new plot every time, as react was updating the plot only not the range
    try {
      Plotly.newPlot(this.performanceChart.nativeElement, graphData, this.plotLayout, this.plotStyle);
    } catch (error) {}
    if (updated) {
      this.handleResize();
    }
  }

  private metricNotSelectedOrDataNotFound(errorMsg: string): void {
    this.legendInfo = [];
    this.cachedGraphData = [];
    this.setLoaderRotationAndDisabledButton(false);
    this.isDataMetricEmpty = true;

    this.plotLayout.annotations.length = 0;
    this.plotLayout.annotations.push({
      text: errorMsg,
      showarrow: false,
      font: {
        size: 18,
        color: 'medium',
      },
    });
    try {
      Plotly.newPlot(this.performanceChart.nativeElement, [], this.plotLayout, this.plotStyle);
      this.handleResize();
    } catch (error) {}
  }

  private getDataForLineTrace(metricData: Array<MetricDataDTO>, key: string, colors: PlotColors | null): PlotlyTrace {
    const dates = [];
    const values = [];
    metricData.forEach((elem) => {
      // dates.push(moment(elem.time).add(this.currentSelectedDateOffsetInMin, 'm').format());
      // dates.push(moment.utc(elem.time).tz(this.unitTimezone).format());
      dates.push(DateTime.fromISO(elem.time, { zone: 'utc' }).setZone(this.unitTimezone).toString());
      values.push(elem.value !== null ? Math.round(elem.value * 100) / 100 : null);
    });
    return this.getLineTrace(dates, values, key, colors);
  }

  private getDataForScatterTrace(
    dates: Array<string>,
    values: Array<number | null>,
    metricKey: string,
    traceColor?: string,
  ): PlotlyTrace | null {
    let hasDots = false;
    const scatterDots = [];
    const scatterDotsDates = [];
    values.forEach((val, index) => {
      if (index > 0) {
        if (values[index - 1] === null && values[index + 1] === null && val !== null) {
          hasDots = true;
          scatterDotsDates.push(dates[index]);
          scatterDots.push(val);
        }
      }
    });
    if (hasDots) {
      return this.getScatterTrace(scatterDotsDates, scatterDots, metricKey, traceColor);
    }
    return null;
  }
  private getSelectedMetricsDataFromCache(selectedMetrics: Array<string>): Array<CachedData> {
    // remove data for metrics that has been deselected
    return this.cachedGraphData.filter((metric) => {
      return selectedMetrics.includes(metric.metricKey);
    });
  }

  // filtering the metrics that exist on a cachedData
  private getMetricsListOfSelectedMetricsFromCachedData(selectedMetrics: Array<string>): Array<string> {
    return selectedMetrics.filter((metric) => {
      const cachedMetrics = this.cachedGraphData.find((cgd) => cgd.metricKey === metric);
      return cachedMetrics ? cachedMetrics.dataTrace === null : true;
    });
  }

  private getGraphColorFromAvailableColorsAndRemoveIt(): PlotColors {
    const color = this.availableColors.splice(0, 1);
    return color[0];
  }

  // x contains all the date time values in string
  // y contains all the metrics data values
  private getLineTrace(
    x: Array<string>,
    y: Array<number | null>,
    metricKey: string,
    useSameColors: PlotColors | null,
  ): PlotlyTrace {
    const color = useSameColors ?? this.getGraphColorFromAvailableColorsAndRemoveIt();
    return {
      mode: PlotlyTraceModes.LINES,
      name: this.allMetricsKeyNameAndAbbreviation[metricKey].name,
      metricAbbr: this.allMetricsKeyNameAndAbbreviation[metricKey].unit,
      x: x,
      y: y,
      fill: PlotlyFillMode.TO_ZERO_Y,
      hoverlabel: { namelength: -1 },
      fillcolor: color.opacValue,
      line: { color: color.value },
    };
  }
  private getScatterTrace(
    x: Array<string>,
    y: Array<number | null>,
    metricKey: string,
    traceColor?: string,
  ): PlotlyTrace {
    const color = traceColor ?? this.getGraphColorFromAvailableColorsAndRemoveIt().value;
    return {
      mode: PlotlyTraceModes.MARKERS,
      type: PlotlyTraceType.SCATTER,
      name: this.allMetricsKeyNameAndAbbreviation[metricKey].name,
      metricAbbr: this.allMetricsKeyNameAndAbbreviation[metricKey].unit,
      x: x,
      y: y,
      showlegend: false,
      marker: {
        color: color,
        line: { color: 'transparent' },
      },
      hoverinfo: 'none',
    };
  }
  private getAvailableGraphColors(): Array<PlotColors> {
    // every time when user select or deselect the metric
    // we do not want to assign the already used color again
    // so, we are filtering the Plot colors to get the unused colors

    return this.plotColors.filter(
      (color) => !this.cachedGraphData.map((metric) => metric.dataColor.value).includes(color.value),
    );
  }

  private addDataInCachedGraphData(metricKey: string, trace: PlotlyTrace): void {
    this.cachedGraphData.push({
      metricKey: metricKey,
      dataTrace: trace,
      dataColor: { value: trace.line?.color ?? trace.marker?.color, opacValue: trace.fillcolor },
    });
  }

  private getMetricsFromDevicesAndControllers(endpoints): Array<DeviceControllerMetrics> {
    const result = [];
    endpoints.forEach((endpoint) => {
      // add device metrics to result array
      endpoint.devices.forEach((device) => {
        const metrics = device.metrics.map((metric) => {
          this.allMetricsKeyNameAndAbbreviation[metric.metricKey] = { name: metric.name, unit: metric.units_abbr };
          return { name: metric.streamName, key: metric.metricKey };
        });
        result.push({ fullName: device.full_name, metrics: metrics });
      });
      // add controller metrics to result array
      endpoint.controllers.forEach((controller) => {
        const metrics = controller.metrics.map((metric) => {
          this.allMetricsKeyNameAndAbbreviation[metric.metricKey] = { name: metric.name, unit: metric.units_abbr };
          return { name: metric.streamName, key: metric.metricKey };
        });
        result.push({ fullName: controller.full_name, metrics: metrics });
      });
    });
    return result;
  }

  // getting the default selected metrics to display on the Plotly Chart
  private getDefaultSelectedMetrics(): Array<string> {
    let defaults = [];
    this.devicesAndControllersMetrics.forEach((devOrCnt) => {
      // this is to preserver the order
      // 1st pv, then load, then grid, and battery
      // so the colors don't mess up
      const solarMetrics = devOrCnt.metrics.filter((metric) => metric.key.indexOf(DeviceMqttKey.SOLAR) === 0);
      const loadMetrics = devOrCnt.metrics.filter((metric) => metric.key.indexOf(DeviceMqttKey.LOAD) === 0);
      const otherMetrics = devOrCnt.metrics.filter(
        (metric) => metric.key.indexOf(DeviceMqttKey.GRID) === 0 || metric.key.indexOf(DeviceMqttKey.BESS) === 0,
      );
      defaults = defaults.concat(solarMetrics, loadMetrics, otherMetrics);
    });
    return defaults.map((metric) => metric.key);
  }

  // setting  the dataTraces to null so we can update them with new dataTraces
  private setDataTraceToNull(): void {
    this.cachedGraphData = this.cachedGraphData.filter((cgd) => cgd.dataTrace.mode !== PlotlyTraceModes.MARKERS);
    this.cachedGraphData.forEach((cgd) => (cgd.dataTrace = null));
  }

  refresh(): void {
    // on refresh we want to keep the same color of selected metrics and only updates the dataTraces of metrics
    this.setDataTraceToNull();
    this.rotation = true;
    this.onMetricChange(true, this.selectedMetrics);
  }

  private async refreshedToastFail(): Promise<void> {
    const refreshToast = await this.toast.create({
      message: this.trans.instant('General.Toaster.RefreshedMetricsFails'),
      duration: 2000,
      position: 'top',
    });
    refreshToast.present();
  }

  exportCSV(): void {
    if (!this.permissionsService.any([PermissionKey.UNIT_EXPORT_UNIT_DATA]) || this.cachedGraphData.length <= 0) {
      return;
    }

    // getting the data of LINES TRACE Metrics from Cached Data
    const linesTracedDataFromCached = this.cachedGraphData.filter(
      (data) => data.dataTrace.mode === PlotlyTraceModes.LINES,
    );
    let csvFile = 'Timestamp,';
    const csvMetricData = {} as { [timestamp: string]: string };

    linesTracedDataFromCached.forEach((cacheData, cacheIndex) => {
      /**
       * push if there is data for a metric
       * not sure should we display an alert for this
       * doesn't look like a good idea as if there are 10 metrics and
       * for 6 metrics data is empty list
       * it'll display 6 alerts
       * or maybe create a string and display at the end
       * not sure about this yet
       * but what was happening is if there is no data for a metric
       * it was throwing error without any alert
       * this way user will be able to download available data for metrics
       * we can surely improve this one
       * but I think for the time being it'll be enough

       * we need data in the below format to successfully write the metric values on the csv file
       *   dataTrace.x              dataTrace.y  dataTrace.y1 dataTrace.y2  dataTrace.y3
       * 2022-07-27T00:00:00+10:00:  1.41,          0.4,        0.6,          1.7,      null, 1.7
       * so we have created an csvMetricData object which holds the timestamp and metric values
       * then we loop through and checks if metric value is undefined assign ' ' null and move to next cols of csv
       * we also use the while loop to add null, if the (length -1 index of csvMetricData) contains nothing/empty data
       * if we skip the while loop step then the csvFile adds the next metric data into the empty spaces of previous metric data
      */
      let headerName = cacheData.dataTrace.name;
      cacheData.dataTrace.x.forEach((timestamp, index) => {
        if (!csvMetricData[timestamp]) {
          csvMetricData[timestamp] = '';
        }
        while (
          csvMetricData[timestamp].split(',').length > 0 &&
          csvMetricData[timestamp].split(',').length - 1 < cacheIndex
        ) {
          csvMetricData[timestamp] += 'null,';
        }
        csvMetricData[timestamp] += `${cacheData.dataTrace.y[index]},`;
      });
      /**
       * For some reasons, the devices have name that contains special characters
       * But when they have special characters in the start of metric name
       * The excel file behaves abnormally
       * specially when they have + and =
       * For excel + means adding two boxes & = means an expression in the cell
       * that's why checking the metrics name
       * if they have any of these characters in the start
       * just removing them
       * only + and = were causing issues
       * just to be on the safe side, I am checking on other characters as well
       */
      while (
        headerName.length > 0 &&
        (headerName[0] === '+' ||
          headerName[0] === '-' ||
          headerName[0] === '=' ||
          headerName[0] === '*' ||
          headerName[0] === '/' ||
          headerName[0] === '%')
      ) {
        headerName = headerName.slice(1);
      }
      csvFile += headerName + ',';
    });

    // remove trailing comma from the last heading name and moves to next line
    csvFile = csvFile.slice(0, -1);
    csvFile += '\n';

    // displaying a metrics data on csvFile, removing the trailing comma  and then add next line
    for (const timestamp in csvMetricData) {
      if (Object.prototype.hasOwnProperty.call(csvMetricData, timestamp)) {
        const csvFileData = csvMetricData[timestamp];
        // using new Date ()  here to get the time in full text string format
        // Wed Jul 27 2022 16:37:35 GMT+1000 (Australian Eastern Standard Time)
        const str = new Date(timestamp) + ',' + csvFileData.slice(0, -1) + '\n';
        csvFile += str;
      }
    }

    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    this.download(this.getCSVFileName(), blob);
  }

  private getCSVFileName(): string {
    //  filename = <UNIT_NAME>_<START_DATE>
    let filename = `${this.unitsService.selectedUnit.name.replace(/\s/g, '_')}_${this.currentSelectedDate.toFormat(
      DateFormats.DAY_MONTH_YEAR_LUXON,
    )}`;
    /* if period is more than one day append <END_DATE> to filename
       we have currentPeriodEndTimeInMS in unix format
       so passing it to moment() to get the time in DAY_MONTH_YEAR format
      */
    if (this.currentPeriodLength !== TimePeriodResolution.DAY) {
      filename += `_to_${DateTime.fromMillis(this.currentPeriodEndTimeInMS).toFormat(
        DateFormats.DAY_MONTH_YEAR_LUXON,
      )}`;
    }
    filename += `.csv`;
    return filename;
  }

  /**
   * currently for desktop only
   * if it is used for apps this should be extended into a download/files service
   * where a modal is populated etc
   */
  private download(filename: string, data: Blob): void {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(data);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    this.modal.dismiss(null, 'confirm');
  }
}
