<ion-header>
  <ion-toolbar color="background-primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ alert.alert_title }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-header color="background-primary">
      <ion-card-title>
        <ion-icon
          [name]="alert.alert_level === 'W' ? 'warning' : alert.alert_level === 'I' ? 'information-circle' : 'bug'"
        >
        </ion-icon>
        {{ alertType | titlecase }}
      </ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <ul>
        <li>{{ "Alerts.Title" | translate }}: {{ alert.alert_title }}</li>
        <li>{{ "Alerts.Text" | translate }}: {{ alert.alert_text }}</li>
        <li>{{ "Alerts.DeviceName" | translate }}: {{ alert.device }}</li>
        <li>{{ "Alerts.SerialNumber" | translate }}: {{ alert.device_serialNo }}</li>
        <li>{{ "Alerts.IsActive" | translate }}: {{ alert.active }}</li>
        <li>{{ "Alerts.TimeStarted" | translate }}: {{ alert.time_registered }}</li>
        <li>{{ "Alerts.TimeEnded" | translate }}: {{ alert.time_deactivated }}</li>
      </ul>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="alert.alert_definition">
    <ion-card-header color="background-primary">
      <ion-card-title>Alert Details</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ul>
        <li>{{ alert.alert_definition.name }}</li>
        <li>{{ alert.alert_definition.remediation }}</li>
        <li>{{ alert.alert_definition.metric.name }}</li>
        <li>{{ alert.alert_definition.expression }}</li>
        <li>{{ alert.alert_definition.value }}</li>
      </ul>
    </ion-card-content>
  </ion-card>
</ion-content>
