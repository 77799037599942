import { Component, Input } from '@angular/core';
import { CustomButtons } from '../../../classes/commons/custom-buttons';

@Component({
  selector: 'app-enroll-in-enrolled-button',
  templateUrl: './enroll-in-enrolled-button.component.html',
  styleUrls: ['./enroll-in-enrolled-button.component.scss'],
})
export class EnrollInEnrolledButtonComponent extends CustomButtons {
  @Input() isEnrolled = false;
}
