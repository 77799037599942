import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalIdentifier, States } from '@class/commons/constants';
import { Portfolio } from '@class/commons/portfolio.model';
import { ModalAction } from 'app/components/modal/modal-container/modal-container.component';
import { FormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { GenericAlertsToastsService } from '@service/common/generic-alerts-toasts.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PortfoliosService } from '@service/portfolios/portfolios.service';
import { TranslationsService } from '@service/common/translations.service';
import { Router } from '@angular/router';
import { AlertButtonRoles } from '@class/alerts-toasts-props/alert-props';
import { PortfolioCreateService, PortfolioStateData } from '@service/portfolios/portfolio-create-and-edit.service';
import { INPUT_STATUS_INVALID } from '@class/commons/inputs';
import { Observable, take } from 'rxjs';
import { ICollectionList } from '@custom-types/collection/collection.model';

type AddNewPortfolioViewModel = {
  modal: {
    action: ModalAction;
    type: ModalIdentifier;
  };
  title: string;
  portfolio: Portfolio | null;
};

interface PortfolioForm {
  name: FormControl<string>;
  mute_alerts: FormControl<boolean>;
}

@Component({
  selector: 'app-portfolio-create-and-edit-modal',
  templateUrl: './portfolio-create-and-edit-modal.component.html',
  styleUrls: ['./portfolio-create-and-edit-modal.component.scss'],
})
export class PortfolioCreateAndEditModalComponent implements OnInit, OnDestroy {
  // Data Inputs
  @Input() set portfolioData(value: Portfolio) {
    // editing existing portfolio
    this.initEditPortfolioModal(value);
    this.setEditFormValues(value);
  }
  @Input() title: string;
  @Output() dismissModalOnCreate: EventEmitter<ICollectionList> = new EventEmitter();

  readonly INPUT_STATUS_INVALID = INPUT_STATUS_INVALID;
  readonly States = States;
  canRemovePortfolio: boolean;
  portfolioForm: FormGroup<PortfolioForm>;
  portfolio$: Observable<PortfolioStateData> = this._portfolioCreateService.portfolioObs$;

  // defaults view modal
  vm: AddNewPortfolioViewModel = {
    modal: {
      action: ModalAction.CREATE,
      type: ModalIdentifier.PORTFOLIO_CREATE,
    },
    title: this._translationsService.instant('CreatePortfolioModal.CreateNew'),
    portfolio: null,
  };

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _alertsToastsService: GenericAlertsToastsService,
    private _permissionsService: PermissionsService,
    private _portfoliosService: PortfoliosService,
    private _translationsService: TranslationsService,
    private _router: Router,
    private _portfolioCreateService: PortfolioCreateService,
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.handlePermissionsApplied();
    this._portfolioCreateService.dismiss$.pipe(take(1)).subscribe((collection) => {
      this.dismiss(collection as unknown as ICollectionList);
    });
  }

  ngOnDestroy() {
    this.resetFormState();
  }

  private resetFormState(): void {
    this._portfolioCreateService.resetState(States.INITIAL_DATA_RECEIVED);
  }

  private dismiss(collection: ICollectionList): void {
    this._alertsToastsService.dismissModal();
    this.dismissModalOnCreate.emit(collection);
  }

  private handlePermissionsApplied(): void {
    this.canRemovePortfolio = this._permissionsService.has(PermissionKey.PORTFOLIO_CHANGE_PORTFOLIO);
  }

  // default form init
  private initForm(): void {
    this.portfolioForm = this._formBuilder.group({
      name: ['', Validators.required],
      mute_alerts: [true],
    });
  }

  // init modal for editing existing portfolio
  private initEditPortfolioModal(value: Portfolio): void {
    this.vm.modal.action = ModalAction.EDIT;
    this.vm.modal.type = ModalIdentifier.PORTFOLIO_EDIT;
    this.vm.title = this._translationsService.instant('CreatePortfolioModal.EditPortfolio');
    this.vm.portfolio = value;
  }

  // editing existing portfolio
  private setEditFormValues(portfolioValue: Portfolio): void {
    this.portfolioForm.setValue({
      name: portfolioValue.name,
      mute_alerts: portfolioValue.muteAlerts,
    });
  }

  private createPortfolio(): void {
    const formValues = this.portfolioForm.value;
    this._portfolioCreateService.addPortfolio({
      name: formValues.name,
      mute_alerts: formValues.mute_alerts,
    });
  }

  private updatePortfolioSettings(): void {
    if (!this._permissionsService.any([PermissionKey.PORTFOLIO_CHANGE_PORTFOLIO])) {
      return;
    }
    // const formValues = this.portfolioForm.value;
    // this._portfolioCreateService.editPortfolio(this.vm.portfolio.id, {
    //   name: formValues.name,
    //   mute_alerts: formValues.mute_alerts,
    // });
  }

  private async presentDeletePortfolioConfirm(): Promise<void> {
    if (
      !this._permissionsService.any([PermissionKey.PORTFOLIO_DELETE_PORTFOLIO, PermissionKey.PORTFOLIO_DELETE_UNIT])
    ) {
      return;
    }
    const { ConfirmDeleteHeader, ConfirmDeleteMessage } = this._translationsService.instant('Portfolio');
    const alert = await this._alertsToastsService.createAlertWithYesNoButton(
      ConfirmDeleteHeader,
      '',
      ConfirmDeleteMessage,
    );
    await alert.present();

    const { role } = await alert.onDidDismiss();
    if (role == AlertButtonRoles.YES) {
      // await this._portfoliosService.deleteSelectedPortfolio();
      this._alertsToastsService.dismissModal();
      this._router.navigateByUrl('/portfolios');
    }
  }

  // to get the form Controls value in the template
  get name() {
    return this.portfolioForm.get('name');
  }

  handleModalContainerSave(): void {
    this.updatePortfolioSettings();
  }

  async handleRemovePortfolio(): Promise<void> {
    await this.presentDeletePortfolioConfirm();
  }

  handleSubmit(): void {
    this.createPortfolio();
  }

  // On a Error Close and Error Retry event resetting the form state to Initial state
  handleErrorCloseAndErrorRetry(): void {
    this._portfolioCreateService.resetState(States.INITIAL_DATA_RECEIVED);
  }
}
