<div>
  <ion-item lines="none">
    <ion-text slot="start" class="text-medium fs-12 m-0 mr-15">{{ 'Cost.Tariff.MonthAvg' | translate }}</ion-text>
    <ion-text slot="end" class="fs-13 m-0 fw-600">-</ion-text>
  </ion-item>
  <ion-item lines="none">
    <ion-text slot="start" class="text-medium fs-12 m-0 mr-15">{{ 'Cost.Tariff.YearToDate' | translate }}</ion-text>
    <ion-text slot="end" class="fs-13 m-0 fw-600">-</ion-text>
  </ion-item>
  <ion-item lines="none">
    <ion-text slot="start" class="text-medium fs-12 m-0 mr-15">{{ 'Cost.Tariff.SPLY' | translate }}</ion-text>
    <ion-text slot="end" class="fs-13 m-0 fw-600">-</ion-text>
  </ion-item>
</div>
