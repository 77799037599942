import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import {
  ControllerAction,
  ProgramNames,
  ProgramsWizard,
  SapnStatus,
  Slides,
  FlexibleExportConnection,
  RegistrationApiResponseStatus,
  Registration,
} from '../../../classes/installer/program-wizards-types';
import { TranslationsService } from '../../../services/common/translations.service';
import { UnitsService } from '../../../services/units/units.service';
import { isEmpty } from 'lodash';
import { ProgramsService } from '../../../services/programs/programs.service';
import { apiStatus } from '../../../classes/commons/common';
import { Droplet } from '../../../classes/units/droplet/droplet.model';
import { IsPlatformValues } from '@class/commons/is-platform-values';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';

/**
 * This wizard accomodate both Flexible Export programs
 * AusNet & SAPN
 * the only difference is text that we display
 * remaining will be same
 * agent & creating controller
 */

const RegisteredText = 'Registered';
const StatusTimeout = 31000; // millisecond
const RegistrationApiRetryTimeout = 10000; // millisecond
const RegistrationRetryMin = 0;
const RegistrationRetryMax = 2; // maximum numbers of retry for registration api

@Component({
  selector: 'app-flexible-export-wizard',
  templateUrl: './flexible-export-wizard.page.html',
  styleUrls: ['./flexible-export-wizard.page.scss'],
})
export class FlexibleExportWizardPage extends IsPlatformValues implements OnInit, OnDestroy {
  @Input() program: ProgramsWizard;
  @Input() endpointUUID: string;
  @ViewChild('slides')
  slides: ElementRef | undefined;

  flexibleExportSlides = {
    WELCOME: {
      heading: this.trans.instant('CommunityBattery.Welcome'),
      index: 0,
      skipOnBack: false,
      skipToSlide: null,
      showBackButton: false,
      isFirstSlide: true,
      isLastSlide: false,
    },
    AGENT: {
      heading: this.trans.instant('UnitDetails.NMI'),
      index: 1,
      skipOnBack: false,
      skipToSlide: 0,
      skipToSlideName: 'WELCOME',
      showBackButton: true,
      isFirstSlide: false,
      isLastSlide: false,
    },
    /**
     * after enrolling into the flexi program and creating the controller
     * the unit (assets i.e., devices) needs to be register with utility server via DNSP server (AusNet / SAPN server)
     *
     * the process is - when we start the registration process, frontend makes request to dJango server
     * then dJango server make a request to AusNet / SAPN DNSP Server
     * and then they add asset to the utility server
     * and then droplet read the values from the utility server via the certificate
     *
     * STEPS:
     * 1. Enroll in the program & save the NMI
     * 2. Create controller
     * 3. Initiate the registration process
     *    a. make request to the server for registration & subscribe to unit metrics to listen for Registered & Connected metrics
     *    b. if registration api response is notReady then make another call after 10 seconds. In the mean time if the Registered metric is 1 over the mqtt then cancel the registration as it's not required.
     *
     * the switchdin server can not initiate unless it gets the LFDI from the droplet
     * so the frontend will ping the server to start the registration
     * then server will check has it got the required items to start the registration or not
     * if yes, then it'll trigger the registration
     * if not, then the frontend will try again after x second
     * the process should take 10 to 30 sec to complete this
     * all the registration process will happen in the background
     *
     * after registration, we need to show the statuses for Connected & Registration
     * CONNECTED: that the droplet has got the controller and all the certificates that it needs to talk to.
     * Initial value is 0, if all okay will change to 1. Listening to this over the mqtt.
     *
     * REGISTRATION: same like connected, listening it over mqtt, and value will change from 0 to 1.
     * That means that unit has been registered on SEP2 or what ever the funky server they have.
     */
    STATUS: {
      heading: this.trans.instant('General.Status'),
      index: 2,
      skipOnBack: false,
      skipToSlide: 1,
      skipToSlideName: 'AGENT',
      showBackButton: true,
      isFirstSlide: false,
      isLastSlide: true,
    },
  };

  public sliderOptions = {
    observer: true,
    allowTouchMove: false,
  };
  currentSlide: Slides;
  nmi: string;

  private updateUnitDone: BehaviorSubject<boolean>;
  private controllerActionDone: BehaviorSubject<boolean>;
  private updateUnitDone$: Observable<boolean>;
  private controllerActionDone$: Observable<boolean>;

  controllerAndUnitSub: Subscription;

  wizardName: string;
  wizardDesc: string;
  controllerName: string;
  controllerDesc: string;

  registrationApiStatus: apiStatus = new apiStatus();
  registrationApiTimeout: NodeJS.Timeout;
  registrationResponseData: Registration;
  registrationCount = RegistrationRetryMin;

  timeout: boolean = false;
  unitMetricMqttSubs: Subscription;
  public readonly SapnStatus = SapnStatus;
  statusTimeout: NodeJS.Timeout;
  timedOut: boolean = false;
  allStatusesFound: boolean = false;

  flexibleExportConnectionStatuses: Array<FlexibleExportConnection> = [
    {
      name: this.trans.instant('FlexibleExports.Connected'),
      shortDesc: '',
      status: SapnStatus.CHECKING,
      error: this.trans.instant('FlexibleExports.ConnectedError'),
      metricKey: '',
      metricKeyFinder: 'Connected.instMag[MX]',
    },
    {
      name: this.trans.instant('FlexibleExports.Registration'),
      shortDesc: '',
      status: SapnStatus.CHECKING,
      error: this.trans.instant('FlexibleExports.RegistrationError'),
      metricKey: '',
      metricKeyFinder: 'Registered.instMag[MX]',
    },
  ];

  constructor(
    private permissionsService: PermissionsService,
    private viewCtrl: ModalController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private unitsService: UnitsService,
    private trans: TranslationsService,
    private programsService: ProgramsService,
  ) {
    super();
    this.currentSlide = this.flexibleExportSlides.WELCOME;
    this.nmi = this.unitsService.selectedUnit['nmi'];
  }
  ngOnInit() {
    this.updateUnitDone = new BehaviorSubject(false);
    this.controllerActionDone = new BehaviorSubject(false);
    this.updateUnitDone$ = this.updateUnitDone.asObservable();
    this.controllerActionDone$ = this.controllerActionDone.asObservable();

    this.controllerAndUnitSub = combineLatest([this.updateUnitDone$, this.controllerActionDone$]).subscribe(
      ([isUpdateDone, isControllerActionDone]) => {
        if (isUpdateDone && isControllerActionDone) {
          this.slideToStatusSlide();
        }
      },
    );
    this.setText(this.program.name.toLowerCase());
  }

  ngOnDestroy() {
    this.controllerAndUnitSub.unsubscribe();
    if (this.unitMetricMqttSubs) this.unitMetricMqttSubs.unsubscribe();
    if (this.statusTimeout) clearTimeout(this.statusTimeout);
    if (this.registrationApiTimeout) {
      clearTimeout(this.registrationApiTimeout);
    }
  }

  setText(programName: string) {
    switch (programName) {
      case ProgramNames.FLEXIBLE_EXPORT_SAPN:
        this.wizardName = this.trans.instant('FlexibleExports.SapnHeading');
        this.wizardDesc = this.trans.instant('FlexibleExports.SapnDescription');
        this.controllerName = this.trans.instant('FlexibleExports.SapnControllerName');
        this.controllerDesc = this.trans.instant('FlexibleExports.SapnControllerDesc');
        break;
      case ProgramNames.FLEXIBLE_EXPORT_AUSNET:
        this.wizardName = this.trans.instant('FlexibleExports.AusnetHeading');
        this.wizardDesc = this.trans.instant('FlexibleExports.AusnetDescription');
        this.controllerName = this.trans.instant('FlexibleExports.AusnetControllerName');
        this.controllerDesc = this.trans.instant('FlexibleExports.AusnetControllerDesc');
        break;
      default:
        this.wizardName = '';
        this.wizardDesc = '';
        this.controllerName = '';
        this.controllerDesc = '';
    }
  }

  public dismiss(data = null) {
    this.viewCtrl.dismiss(data);
  }

  slideBack() {
    this.slides.nativeElement.swiper.slideTo(this.currentSlide['skipToSlide']);
    this.currentSlide = this.flexibleExportSlides[this.currentSlide['skipToSlideName']];
  }

  slideToAgentSlide() {
    this.currentSlide = this.flexibleExportSlides.AGENT;
    this.slides.nativeElement.swiper.slideTo(this.currentSlide.index);
  }
  slideToStatusSlide() {
    this.currentSlide = this.flexibleExportSlides.STATUS;
    this.slides.nativeElement.swiper.slideTo(this.currentSlide.index);
    this.startTheRegistrationAndConnectedTimeout();
  }

  async submit() {
    // there should be an agent and one agent only
    // if no agent, user can't do anything
    // they need to contact SwitchDin
    if (this.program.agents.length < 0) {
      return;
    }
    const agentUuid: string = this.program.agents[0].uuid;
    const updateUnitData = {
      ...this.unitsService.selectedUnit,
      ...{
        program: this.program.uuid,
        nmi: this.nmi,
        agent: agentUuid,
      },
    };

    const controllerData = this.prepareControllerData();

    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: this.wizardName,
      message: controllerData.message,
      buttons: [
        {
          text: this.trans.instant('General.OK'),
          handler: () => {
            this.submitHandler(updateUnitData, controllerData);
          },
        },
      ],
    });
    await alert.present();
  }

  async submitHandler(updateUnitData, controllerData) {
    // Update unit with program, nmi, and agent
    await this.updateUnit(updateUnitData);

    this.controllerHandler(controllerData);
  }

  controllerHandler(controllerData) {
    const { endpoint_info, controller_info, status, controllerID, updateControllerData } = controllerData;

    const createControllerData = { endpoint_info, controller_info };

    // Controller Actions
    // There are 3 options
    switch (status) {
      // 1. Do nothing, either there are no devices to control or all devices already have a controller
      // need to next the behavior subject with true to close the wizard
      case ControllerAction.NONE:
        this.publishControllerState(true);
        break;
      // 2. Update an existing controller
      case ControllerAction.UPDATE:
        this.updateProgramController(controllerID, updateControllerData);
        break;
      // 3. Create new controller
      case ControllerAction.CREATE:
        this.createProgramController(createControllerData);
        break;
    }
  }

  private async updateUnit(updateUnitData) {
    if (!this.permissionsService.any([PermissionKey.UNIT_CHANGE_UNIT])) {
      return;
    }
    // let loader: HTMLIonLoadingElement = await this.loadingController.create({
    //   message: this.trans.instant('CommunityBattery.UpdatingUnitDetails'),
    // });
    // await loader.present();

    await this.unitsService
      .updateUnitDetails(this.unitsService.selectedUnit.id, updateUnitData)
      .then((res) => {
        this.unitsService.selectedUnit = res.data;
        // loader.dismiss();
        // Test a failed update unit details call by un-commenting the below line
        // throw new Error('Update Unit Failed');
        this.publishUnitState(true);
      })
      .catch(() => {
        this.handleErrorAlert(null, this.trans.instant('CommunityBattery.UnitNotUpdated'));
      });
  }

  private async createProgramController(createControllerData) {
    // const loader: HTMLIonLoadingElement = await this.loadingController.create({
    //   message: this.trans.instant('FlexibleExports.CreatingController'),
    // });
    // await loader.present();

    try {
      await this.unitsService.createNewEndpointController(createControllerData);
      await this.unitsService.getEndpointsOfSelectedUnit();
      // loader.dismiss();

      // Test a failed create controllers call by un-commenting the below line
      // throw new Error('Create Controller Failed');
      this.publishControllerState(true);
    } catch (error) {
      this.handleErrorAlert(null, this.trans.instant('FlexibleExports.ControllerAddError'));
      console.error(`Error creating new endpoint controller: ${error}`);
    }
  }

  private async updateProgramController(controllerID, updateControllerData) {
    // const loader: HTMLIonLoadingElement = await this.loadingController.create({
    //   message: this.trans.instant('FlexibleExports.UpdatingYourController'),
    // });
    // await loader.present();

    try {
      this.unitsService.updateEndpointController(controllerID, updateControllerData);
      await this.unitsService.getEndpointsOfSelectedUnit();
      // loader.dismiss();
      // Test a failed create controllers call by un-commenting the below line
      // throw new Error('Update Controller Failed');
      this.publishControllerState(true);
    } catch (error) {
      this.handleErrorAlert(null, this.trans.instant('FlexibleExports.ControllerUpdateError'));
      console.error(`Error creating new endpoint controller: ${error}`);
    }
  }

  private async handleErrorAlert(loader: HTMLIonLoadingElement, message: string) {
    loader.dismiss();

    const alert: HTMLIonAlertElement = await this.alertController.create({
      header: this.wizardName,
      message,
      buttons: [
        {
          text: this.trans.instant('General.OK'),
          handler: () => {
            this.dismiss();
          },
        },
      ],
    });
    await alert.present();
  }

  prepareControllerData() {
    const endpoint = this.unitsService.unitEndpoints.endpoints.find((el) => el.uuid === this.endpointUUID);
    const endpoint_info = this.prepareEndpointInfo(endpoint);

    const { controller_info, status, message, controllerID, updateControllerData } =
      this.prepareControllerInfoAndOtherData(endpoint);

    return { endpoint_info, controller_info, status, message, controllerID, updateControllerData };
  }

  prepareEndpointInfo(endpoint) {
    return {
      endpoint_id: endpoint.id,
      uuid: endpoint.long_uuid,
    };
  }

  prepareControllerInfoAndOtherData(endpoint) {
    // there will only be one controller type for the SAPN wizard
    const programControllerType = this.program.controllerTypes[0];

    const existingFlexibleExportController = this.getExistingController(endpoint, programControllerType.id);

    const allDevicesToControl = this.getAllDevicesToControl(endpoint, programControllerType);

    // at this stage we do not know if we will do nothing, update, or create a new controller
    const { device_ids, status, message } = this.getNewDevicesToControlAndStatusAndMessage(
      allDevicesToControl,
      existingFlexibleExportController,
    );

    if (existingFlexibleExportController) {
      existingFlexibleExportController.device_ids = device_ids;
    }

    return {
      controller_info: {
        device_ids,
        parameters: [],
        controller_type_id: programControllerType.id,
        name: this.controllerName,
        description: this.controllerDesc,
      },
      status,
      message,
      controllerID: existingFlexibleExportController ? existingFlexibleExportController.id : null,
      updateControllerData: existingFlexibleExportController,
    };
  }

  getNewDevicesToControlAndStatusAndMessage(allDevicesToControl, existingController) {
    const device_ids = allDevicesToControl.map((el) => el.id);
    let status = ControllerAction.CREATE;
    const newDevicesToControl = [];

    if (device_ids.length === 0) {
      status = ControllerAction.NONE;
    } else if (existingController) {
      const existingControlledDevices = existingController.device_ids;
      if (device_ids.sort().join('') === existingControlledDevices.sort().join('')) {
        // two arrays are the same
        status = ControllerAction.NONE;
      } else {
        // if not, where are they different?
        status = ControllerAction.UPDATE;
        allDevicesToControl.forEach((el) => {
          if (!existingControlledDevices.includes(el.id)) {
            newDevicesToControl.push(el);
          }
        });
      }
    }

    const message = this.devicesMessage(allDevicesToControl, newDevicesToControl, status);

    return { device_ids, status, message };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  devicesMessage(allDevicesToControl: any[], newDevicesToControl: any[], status: ControllerAction) {
    let message = '';
    switch (status) {
      case ControllerAction.NONE:
        if (allDevicesToControl.length === 0) {
          message = this.trans.instant('AllPrograms.DevicesNotFound');
        } else {
          message = this.trans.instant('AllPrograms.UnitAlreadyHasController');
        }
        break;
      case ControllerAction.UPDATE:
        message = this.trans.instant('AllPrograms.UpdateControllerOnUnit');
        break;
      case ControllerAction.CREATE:
        message = this.trans.instant('AllPrograms.CreateControllerOnUnit');
        break;
    }

    return message;
  }

  getAllDevicesToControl(endpoint, programControllerType) {
    // const endpointDevices = ;
    const endpoint61850Devices = [];
    const endpointLegacyDevices = [];
    endpoint.devices.map((el) => {
      if (el['iec61850_device_type_id']) {
        endpoint61850Devices.push(el);
      } else if (el['device_type_id']) {
        endpointLegacyDevices.push(el);
      }
    });

    const controllableLegacyDeviceTypeIDs = programControllerType.valid_legacy_device_type_ids;
    const controllable61850DeviceTypeIDs = programControllerType.valid_iec61850_device_type_ids;

    const devicesToControl = [];

    controllableLegacyDeviceTypeIDs.forEach((controllableDeviceTypeID) => {
      endpointLegacyDevices.forEach((device) => {
        if (device.device_type_id === controllableDeviceTypeID) {
          devicesToControl.push(device);
        }
      });
    });

    controllable61850DeviceTypeIDs.forEach((controllableDeviceTypeID) => {
      endpoint61850Devices.forEach((device) => {
        if (device.iec61850_device_type_id === controllableDeviceTypeID) {
          devicesToControl.push(device);
        }
      });
    });

    return devicesToControl;
  }

  // This function will subscribe to unit mqtt from unit services
  // then will try to read the values for those controller metrics
  // sample metric: SWDIN.<controller_dev_handler>.Connected.instMag[MX] || SWDIN.<controller_dev_handler>.Registered.instMag[MX]
  // will listen for these, if got it, all good
  // otherwise will timeout after x time, and will provide with try again option
  // on try again, it'll repeat again
  // first get the updated endpoint as we recently dropped a controller
  // then will find the Flexi controller
  // then will get the metrics from the metrics list
  // and then listen for those, and initiate the timeout
  // if the values are 1 for both, clear the timeout
  //
  async startTheRegistrationAndConnectedTimeout() {
    // first find the endpoint on which the controller has been created
    const endpoint: Droplet = this.getEndpoint(this.unitsService.unitEndpoints.endpoints, this.endpointUUID);
    const programControllerType = this.program.controllerTypes[0];

    this.allStatusesFound = false;
    this.timedOut = false;

    // now get the existing flexible export controller
    const existingFlexibleExportController = this.getExistingController(endpoint, programControllerType.id);

    if (!existingFlexibleExportController) {
      // means no controller found so can not proceed. Will display an alert and will dismiss the modal for now
      const FlexibleExportsTrans = this.trans.instant('FlexibleExports');
      const alert: HTMLIonAlertElement = await this.createSimpleAlert(
        FlexibleExportsTrans.NoController,
        FlexibleExportsTrans.NoControllerError,
      );
      await alert.present();
      this.dismiss();
      return;
    }

    // find the connected & register metrics from the controller
    this.setToInitialCheckingStatusAndFindMetricKey(
      this.flexibleExportConnectionStatuses,
      existingFlexibleExportController.metrics,
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.unitMetricMqttSubs = this.unitsService.unitMetricsMqtt.mqttSubject.subscribe((metrics: any) => {
      this.flexibleExportConnectionStatuses.forEach((stat: FlexibleExportConnection) => {
        if (!isEmpty(metrics) && metrics.hasOwnProperty(stat.metricKey) && metrics[stat.metricKey] === 1) {
          stat.status = this.SapnStatus.ONLINE;
          // if the value exist then
          // check if it is a register metric then need to clear the register api timeout
          if (stat.metricKeyFinder.includes(RegisteredText) && this.registrationApiTimeout) {
            clearTimeout(this.registrationApiTimeout);
          }
        }
      });
      if (
        this.flexibleExportConnectionStatuses.filter((st) => st.status !== this.SapnStatus.ONLINE).length <= 0 &&
        this.statusTimeout
      ) {
        clearTimeout(this.statusTimeout);
        this.timedOut = false;
        this.allStatusesFound = true;
      }
    });

    this.statusTimeout = this.createTimeoutForStatuses(
      this.flexibleExportConnectionStatuses,
      this.SapnStatus,
      this.unitMetricMqttSubs,
    );
  }

  // todo - fix this to return 'Droplet' but need to fix the Test first as its data is not correct
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getEndpoint(endpoints, endptId: string): any {
    return endpoints.find((el) => el.uuid === endptId);
  }
  getExistingController(endpoint, programControllerTypeId: string) {
    return endpoint.controllers.find((el) => el.controller_type_id === programControllerTypeId);
  }
  setToInitialCheckingStatusAndFindMetricKey(
    flexExpStatuses: Array<FlexibleExportConnection>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metricsList: Array<any>,
  ): void {
    flexExpStatuses.forEach((stat: FlexibleExportConnection) => {
      if (stat.status !== this.SapnStatus.ONLINE) {
        stat.status = this.SapnStatus.CHECKING;

        // check if there is any register metric then need to call the register api for that
        if (stat.metricKeyFinder.includes(RegisteredText)) {
          this.registrationCount = RegistrationRetryMin;
          if (this.registrationApiTimeout) {
            clearTimeout(this.registrationApiTimeout);
          }
          stat.error = this.trans.instant('FlexibleExports.RegistrationError');
          this.startRegistration(stat);
        }
      }
      const metric = metricsList.find((mt) => mt.metricKey.toLowerCase().includes(stat.metricKeyFinder.toLowerCase()));
      stat.metricKey = metric.metricKey;
    });
  }
  createTimeoutForStatuses(statList: Array<FlexibleExportConnection>, status, mqttSub): NodeJS.Timeout {
    return setTimeout(() => {
      statList.forEach((stat: FlexibleExportConnection) => {
        if (stat.status === status.checking) {
          stat.status = status.offline;
          this.allStatusesFound = false;
        }
      });
      this.timedOut = true;
      if (mqttSub) mqttSub.unsubscribe();
    }, StatusTimeout);
  }
  private publishUnitState(value: boolean) {
    this.updateUnitDone.next(value);
  }
  private publishControllerState(value: boolean) {
    this.controllerActionDone.next(value);
  }
  createSimpleAlert(header: string, message: string): Promise<HTMLIonAlertElement> {
    return this.alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: this.trans.instant('General.OK'),
          handler: () => {},
        },
      ],
    });
  }
  async startRegistration(stat: FlexibleExportConnection) {
    this.registrationApiStatus.reset();
    try {
      const resData = await this.programsService.programRegistration(this.unitsService.selectedUnit.id, {
        program_name: this.program.name,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.registrationResponseData = new Registration(resData['data'] as any);
      if (
        this.registrationResponseData.status === RegistrationApiResponseStatus.NOT_READY &&
        this.registrationCount < RegistrationRetryMax
      ) {
        this.registrationApiTimeout = setTimeout(() => {
          this.startRegistration(stat);
        }, RegistrationApiRetryTimeout);
      }
    } catch (error) {
      // this is for if the error is something else then 400...
      // have to do these all if's, the response from the backend is not consistent
      // the response for 400 is different then 500
      if (error && error.error && typeof error.error === 'string') {
        this.registrationApiStatus.$error = {
          code: error.status,
          data: {},
          msg: error.status >= 500 ? this.trans.instant('General.Error.ServerUnavailable') : error.message,
          status: error.statusText,
        };
        stat.error += ' ' + this.registrationApiStatus.$error['msg'];
        // for 400 error
      } else if (error && error.error && typeof error.error === 'object') {
        this.registrationApiStatus.$error = error.error;
        stat.error += ' ' + this.registrationApiStatus.$error['msg'];
      }
      this.registrationApiStatus.$callFailed = true;
      console.error(error);
    } finally {
      this.registrationApiStatus.$callMade = true;
      this.registrationCount++;
    }
  }
}
