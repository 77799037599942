import { VppDemandTypes } from './vpp-demand-type-mode.model';

export interface VppFcasMarket {
  uuid: string;
  code: string;
  name: string;
  market_type: VppDemandTypes.FCAS_RAISE | VppDemandTypes.FCAS_LOWER;
  country: string;
}

export const getRaiseMarketUuidsFromMarketObjects = (markets: VppFcasMarket[]): string[] => {
  const selectedRaiseMarkets = markets.filter((market) => market.market_type === VppDemandTypes.FCAS_RAISE);
  const selectedRaiseMarketUuids = selectedRaiseMarkets.map((market) => market.uuid);
  return selectedRaiseMarketUuids;
};

export const getLowerMarketUuidsFromMarketObjects = (markets: VppFcasMarket[]): string[] => {
  const selectedLowerMarkets = markets.filter((market) => market.market_type === VppDemandTypes.FCAS_LOWER);
  const selectedLowerMarketUuids = selectedLowerMarkets.map((market) => market.uuid);
  return selectedLowerMarketUuids;
};
