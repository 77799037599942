<ion-row *ngIf="vm$ | async as vm">
  <ion-col class="ion-no-padding pb-10" size="12">
    <ion-card class="ion-no-margin card-design">
      <ion-item>
        <div [ngClass]="(vm.activity$ | async) ? 'online-pulse-circle' : 'online-pulse-circle2'"></div>
        <ion-label class="ion-padding-start">{{ 'VirtualPowerPlant.DemandType' | translate }}</ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-select
          class="width-100-pct"
          [placeholder]="streamNames.CreateVPP.SelectOne"
          interface="popover"
          [ngModel]="vm.demandTypeModes"
          (ngModelChange)="demandTypeValueChange($event)"
        >
          <ion-select-option *ngFor="let option of demandTypeModeOptions" [value]="option.value">{{
            option.name
          }}</ion-select-option>
        </ion-select>
      </ion-item>
    </ion-card>
  </ion-col>
  <ion-col class="ion-no-padding pb-10" size="12" *ngIf="vm.isShowNemPrice">
    <app-vpp-operation-nem-prices></app-vpp-operation-nem-prices>
  </ion-col>
  <ion-col
    *ngFor="let metric of vm.metricMetaData; let last = last"
    size="12"
    class="p-0"
    [ngClass]="{ 'pb-10': !last }"
  >
    <app-vpp-operation-tab-streaming-widget
      [name]="metric.name"
      [data$]="metric.data$"
      [total$]="metric.total$"
    ></app-vpp-operation-tab-streaming-widget>
  </ion-col>
</ion-row>
