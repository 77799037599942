import { Component, OnInit, Input } from '@angular/core';
import { Alert } from './alert.model';
import { ModalController } from '@ionic/angular';
import { AlertModalPage } from '../../../modals/alert-modal/alert-modal.page';
import { ThemeService } from '../../../../services/themes/theme.service';
import { AlertTypes } from '../alert-definitions-list/alert-definition.model';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  @Input() alert: Alert;
  alertIcon: string;

  constructor(
    private modalController: ModalController,
    public themeService: ThemeService,
  ) {}

  ngOnInit() {
    AlertTypes.forEach((el) => {
      if (this.alert.alert_level === el.value) {
        this.alertIcon = el.icon;
      }
    });
  }

  openAlertModal(): void {
    this.modalController
      .create({
        component: AlertModalPage,
        showBackdrop: true,
        componentProps: { alert: this.alert },
      })
      .then((modal) => {
        modal.present();
        modal.style.display = 'flex';
      });
  }
}
