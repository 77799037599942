import { inject, Injectable } from '@angular/core';
import { States } from '@class/commons/constants';
import { Mode } from '@class/commons/enums';
import { PortfolioDTO } from '@class/commons/portfolio.model';
import { generateHttpErrorMessage, HTTP_ERRORS_TRANSLATION_HEADING } from '@class/error/http-error-types';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '@service/common/api-wrapper.service';
import { AppHeaderService } from '@service/common/app-header.service';
import { FavKeys, FavouritesService } from '@service/common/favourites.service';
import { TranslationsService } from '@service/common/translations.service';
import { BehaviorSubject, catchError, delay, from, map, Observable, of, startWith, Subject, tap } from 'rxjs';
import { PortfoliosService } from './portfolios.service';
import { PortfolioListStore } from './portfolio-list.store';
import { CollectionApiService } from 'app/api-services/collection/collection.api-service';

interface AddNewPortfolio {
  name: string;
  mute_alerts: boolean;
}

export interface PortfolioStateData {
  data?: PortfolioDTO;
  status: States;
  error?: string;
  message?: string;
  mode: Mode;
}

const DELAY_TIME_MS: number = 1000;
// initial state
const _initialState: PortfolioStateData = {
  status: States.INITIAL_DATA_RECEIVED,
  mode: Mode.VIEW,
};

@Injectable({
  providedIn: 'root',
})
export class PortfolioCreateService {
  private store = new BehaviorSubject<PortfolioStateData>(_initialState);
  portfolioObs$ = this.store.asObservable();
  // using this subject to dismiss modal after the success state
  public dismiss$ = new Subject<PortfolioDTO>();
  #collectionApiService = inject(CollectionApiService);

  constructor(
    private _api: ApiWrapper,
    private _portfoliosService: PortfoliosService,
    private _headerService: AppHeaderService,
    private _favouritesService: FavouritesService,
    private _translationsService: TranslationsService,
    private _portfolioListStore: PortfolioListStore,
  ) {}

  addPortfolio(newPortfolioDetail: AddNewPortfolio): void {
    const newPortfolio$ = from(this.#collectionApiService.createNewCollection().mutateAsync(newPortfolioDetail));
    newPortfolio$
      .pipe(
        map((data) => {
          return {
            data,
            status: States.REQUEST_SUCCESS,
            mode: Mode.CREATE,
            message: this._translationsService.instant('CreatePortfolioModal.PortfolioAddedSuccess'),
          };
        }),
        delay(DELAY_TIME_MS),
        startWith({ status: States.REQUEST_IN_PROCESS }),
        catchError((error) => this.handleError(error)),
        tap((response: PortfolioStateData) => {
          this.setState(response);
          // On a request success add the new portfolio into list
          if (this.store.value.data && response.status === States.REQUEST_SUCCESS) {
            this.addToPortfolioList();
          }
        }),
      )
      .subscribe();
  }

  // editPortfolio(portfolioId: string, data: AddNewPortfolio): void {
  // const patch$ = from(this.updatePortfolio(portfolioId, data));
  // patch$
  //   .pipe(
  //     map(({ data }) => {
  //       return {
  //         data,
  //         status: States.REQUEST_SUCCESS,
  //         mode: Mode.EDIT,
  //         message: this._translationsService.instant('CreatePortfolioModal.PortfolioUpdatedSuccess'),
  //       };
  //     }),
  //     delay(DELAY_TIME_MS),
  //     startWith({ status: States.REQUEST_IN_PROCESS }),
  //     catchError((error) => this.handleError(error)),
  //     tap((response: PortfolioStateData) => {
  //       this.setState(response);
  //       this.updatingPortfolioFavouriteAndHeaderService();
  //     }),
  //   )
  //   .subscribe();
  // }

  private setState(nextState: PortfolioStateData): void {
    this.store.next(nextState);
  }

  // dismissing the modal and adding the new created portfolio into the portfolio list
  private addToPortfolioList(): void {
    this._portfolioListStore.addToPortfolioListStore(this.store.value.data);
    setTimeout(() => {
      this.dismiss$.next(this.store.value.data);
    }, DELAY_TIME_MS);
  }

  // dismissing the modal and updating the portfolio data in the portfolio list, favorites list and the header title
  private updatingPortfolioFavouriteAndHeaderService(): void {
    const portfolioDataToUpdate = PortfolioDTO.adaptToPortfolio(this.store.value.data);
    this._portfolioListStore.updateDataOnPortfolioIndex(portfolioDataToUpdate);
    // updating  portfolio favourites list
    this._favouritesService.add(portfolioDataToUpdate, FavKeys.PORTFOLIOS);
    // updating header title
    this._headerService.setHeaderTitle(this.store.value.data?.name);
    // updating selected portfolio

    setTimeout(() => {
      this.dismiss$.next(this.store.value.data);
    }, DELAY_TIME_MS);
  }

  // updating only the UI States not the form control values
  private updateState(stateProp: States): void {
    this.store.next({
      ...this.store.value,
      status: stateProp,
    });
  }

  resetState(loadingState: States): void {
    this.updateState(loadingState);
  }

  //Todo: after adding the editPortfolio functionality, will add the return type
  private updatePortfolio(portfolioId: string, data: AddNewPortfolio) {
    return this._api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/portfolios/${portfolioId}/`,
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  private handleError(err, state: States = States.INITIAL_ERROR): Observable<PortfolioStateData> {
    const message = generateHttpErrorMessage(err, this._translationsService.instant(HTTP_ERRORS_TRANSLATION_HEADING));
    return of({
      data: this.store.value.data,
      status: state,
      error: message.message,
      mode: Mode.VIEW,
    });
  }
}
