import { Component, ViewChild, Input, SimpleChanges, OnDestroy, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingController, AlertController, ToastController, ModalController } from '@ionic/angular';
import { TranslationsService } from '../../services/common/translations.service';
import { VppCreateComplexScheduleModalPage } from '../../pages/modals/vpp-create-complex-schedule-modal/vpp-create-complex-schedule-modal.page';
import { VirtualPowerPlantsService } from '../../services/virtualPowerPlants/virtual-power-plants.service';
import { VppControlGroupSchedulesService } from '../../services/virtualPowerPlants/control-group-schedules/vpp-control-group-schedules.service';
import {
  VppControlGroupSchedule,
  VppScheduleRecurrencePattern,
} from '../../classes/vpp/vpp-control-group-schedule.model';
import { Subscription } from 'rxjs';
import { VppTimezoneService } from '../../services/virtualPowerPlants/timezone/vpp-timezone.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { VPP_TABLE_PAGE_SIZE_OPTIONS } from '@class/vpp/vpp-dispatch.model';

@Component({
  selector: 'app-vpp-complex-schedules',
  templateUrl: './vpp-complex-schedules.component.html',
  styleUrls: ['./vpp-complex-schedules.component.scss'],
})
export class VppComplexSchedulesComponent implements OnChanges, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @Input() schedulesList;

  readonly VPP_TABLE_PAGE_SIZE_OPTIONS = VPP_TABLE_PAGE_SIZE_OPTIONS;
  readonly VppScheduleRecurrencePattern = VppScheduleRecurrencePattern;
  readonly PermissionKey = PermissionKey;

  dataSource;
  columnsToDisplay = ['name', 'description', 'action'];
  expandedElement;
  currentControlGroupSchedules: VppControlGroupSchedule[]; //TEMP: remove with schedule service
  controlGroupScheduleSubscription: Subscription;
  public timezoneOffset$ = this.timezoneService.timezoneOffsetNameShort$;

  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private toast: ToastController,
    private trans: TranslationsService,
    private modal: ModalController,
    private vppControlGroupSchedulesService: VppControlGroupSchedulesService,
    private vppService: VirtualPowerPlantsService,
    private timezoneService: VppTimezoneService,
  ) {
    this.controlGroupScheduleSubscription = this.vppControlGroupSchedulesService.controlGroupSchedules$.subscribe(
      (controlGroupSchedules) => {
        this.currentControlGroupSchedules = controlGroupSchedules;
      },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.hasOwnProperty('schedulesList')) {
      this.dataSource = new MatTableDataSource(this.schedulesList);
      if (this.sort) this.dataSource.sort = this.sort;
      if (this.paginator) this.dataSource.paginator = this.paginator;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  rowClicked(element) {
    this.expandedElement = this.expandedElement === element ? null : element;
    // this.selectedSchedule.emit(this.expandedElement);
  }

  openVppCreateScheduleModal(schedule) {
    this.modal
      .create({
        component: VppCreateComplexScheduleModalPage,
        backdropDismiss: false,
        componentProps: {
          editSchedule: true,
          scheduleToUpdate: Object.assign({}, schedule),
        },
        showBackdrop: true,
      })
      .then((modal) => {
        modal.present();
        modal.onDidDismiss().then((data) => {
          // console.log('data: ', data);
          if (data.data) {
            this.vppService.getVppSchedules();
            this.vppControlGroupSchedulesService.getVppControlGroupSchedules(this.vppService.selectedVpp.id);
          }
        });
      });
  }

  openVppCreateScheduleModalForCreate() {
    this.modal
      .create({
        component: VppCreateComplexScheduleModalPage,
        backdropDismiss: false,
        showBackdrop: true,
      })
      .then((modal) => {
        modal.present();
        modal.onDidDismiss().then((data) => {
          // console.log('data: ', data);
          if (data.data) {
            this.vppService.getVppSchedules();
          }
        });
      });
  }

  removeSchedule(schedule) {
    this.createLoader('').then((loader) => {
      loader.present();
      this.vppService.removeSchedule(schedule.id).then(
        (response) => {
          setTimeout(() => {
            loader.dismiss();
            this.presentToast(this.trans.str.vpp.ScheduleremoveSuccess);
            this.vppService.getVppSchedules();
            this.vppControlGroupSchedulesService.getVppControlGroupSchedules(this.vppService.selectedVpp.id);
          }, 500);
        },
        (error) => {
          loader.dismiss();
          let msg = this.trans.str.vpp.InviteUser.UserFail.CreateMsg;
          if (error.hasOwnProperty('status')) {
            msg = msg + ' ' + error.status + ' ' + error.statusText + '. ';
          } else {
            msg += JSON.stringify(error);
          }
          this.presentAlertSimpleOk(
            this.trans.str.vpp.InviteUser.UserFail.Header,
            this.trans.str.vpp.ScheduleremoveFailed,
            msg,
          );
        },
      );
    });
  }

  async presentAlertConfirmSchedule(schedule) {
    // console.log("Schedule: ", schedule);
    let found = false;
    this.currentControlGroupSchedules.forEach((CGS) => {
      if (schedule.id == CGS.schedule.id) found = true;
    });
    if (found) {
      this.presentAlertSimpleOk(
        this.trans.str.vpp.CannotRemoveSchedule,
        this.trans.str.vpp.ScheduleIsAssociatedWithControlGroup,
        '',
      );
      return;
    }
    let header = '',
      subHeader = '',
      message = '';
    header = this.trans.str.vpp.RemoveUser.Header;
    subHeader = this.trans.str.vpp.RemoveUser.Subheader;
    message =
      this.trans.str.vpp.RemoveSchedule + ': <strong>' + schedule.name + ' - ' + schedule.description + '</strong>';
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: [
        {
          text: this.trans.instant('General.No'),
        },
        {
          text: this.trans.instant('General.Yes'),
          handler: () => {
            this.removeSchedule(schedule);
          },
        },
      ],
    });
    await alert.present();
  }

  createLoader(message) {
    return this.loadingController.create({
      message: message,
    });
  }

  async presentAlertSimpleOk(header, subheader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  presentToast(message, duration = 3000) {
    this.toast
      .create({
        message: message,
        duration: duration,
        position: 'top',
      })
      .then((toast) => {
        toast.present();
      });
  }

  ngOnDestroy(): void {
    if (this.controlGroupScheduleSubscription) {
      this.controlGroupScheduleSubscription.unsubscribe();
    }
  }
}
