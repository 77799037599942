import { Component, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { DropletFirmwareUpgradePage } from '../../pages/modals/droplet-firmware-upgrade/droplet-firmware-upgrade.page';
import { UnitsService } from '../../services/units/units.service';

@Component({
  selector: 'app-droplet-firmware-update-button',
  templateUrl: './droplet-firmware-update-button.component.html',
  styleUrls: ['./droplet-firmware-update-button.component.scss'],
})
export class DropletFirmwareUpdateButtonComponent implements OnDestroy {
  @Input() endpointUuid: string;
  triggerSubscription: Subscription;

  constructor(
    private modal: ModalController,
    private unitService: UnitsService,
  ) {
    this.triggerSubscription = this.unitService.triggerDropletUpdate$.subscribe((endpointUuid: string) => {
      if (endpointUuid) {
        this.clickUpdate(endpointUuid);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.triggerSubscription) {
      this.triggerSubscription.unsubscribe();
    }
  }

  async clickUpdate(endpointUuid: string): Promise<void> {
    try {
      const modal: HTMLIonModalElement = await this.modal.create({
        component: DropletFirmwareUpgradePage,
        backdropDismiss: false,
        componentProps: {
          endpointUuid: endpointUuid,
        },
      });
      await modal.present();
      modal.onDidDismiss().then(async (data) => {
        if (data) {
          // just to clear the observable value
          // otherwise this component will be re-render again after getting the endpoints
          // and will subscribe to the observable again
          // and that will hold the last value and will trigger the update again and again if triggerDroplet update is true
          this.unitService.updateDroplet(null);
          await this.unitService.getEndpointsOfSelectedUnit();
        }
      });
    } catch (error) {
      throw new Error(error);
    }
  }
}
