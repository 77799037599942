<ion-toolbar *ngIf="isEnabled">
  <ion-title size="small" slot="start">
    Environment: <b>{{ environmentName }}</b>
  </ion-title>
  <ion-title size="small" slot="end">
    <ion-text>
      <p class="ion-no-margin">
        Api Url: <b>{{ apiUrl }}</b>
      </p>
      <p class="ion-no-margin">
        Rabbit: <b>{{ mqttConfigUrl }}</b>
      </p>
    </ion-text>
  </ion-title>
</ion-toolbar>
