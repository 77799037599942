<ion-grid
  class="ion-no-margin ion-no-padding"
  *ngIf="!(vm$ | async).loading && !(vm$ | async).error && (vm$ | async).data as vm"
>
  <ion-row>
    <ion-col class="ion-no-padding pt-10">
      <ion-grid class="p-0">
        <ion-row>
          <ion-col size="6" size-md="3" class="p-8">
            <ion-card class="new-design-card">
              <div class="fs-16 fw-500 text-dark">
                {{ 'Cost.Tariff.Demand' | translate }}
              </div>
              <div>
                <span class="fs-18 dollar-sign">$ </span>
                <span class="demand-span fw-600 fs-30 text-dark">{{ vm.aggregate.demand | number: '1.2-2' }}</span>
                <span class="demand-span fw-600 fs-30 ml-5">{{ vm.aggregate.demandSuffix }}</span>
              </div>
            </ion-card>
          </ion-col>
          <ion-col size="6" size-md="3" class="p-8">
            <ion-card class="new-design-card">
              <div class="fs-16 fw-500 text-dark">
                {{ 'Cost.Tariff.Usage' | translate }}
              </div>
              <div>
                <span class="fs-18 dollar-sign">$ </span>
                <span class="usage-span fw-600 fs-30 text-dark">{{ vm.aggregate.usage | number: '1.2-2' }}</span>
                <span class="demand-span fw-600 fs-30 ml-5">{{ vm.aggregate.usageSuffix }}</span>
              </div>
            </ion-card>
          </ion-col>
          <ion-col size="6" size-md="3" class="p-8">
            <ion-card class="new-design-card">
              <div class="fs-16 fw-500 text-dark">
                {{ 'Cost.Tariff.Service' | translate }}
              </div>
              <div>
                <span class="fs-18 dollar-sign">$ </span>
                <span class="service-span fw-600 fs-30 text-dark">{{ vm.aggregate.service | number: '1.2-2' }}</span>
                <span class="demand-span fw-600 fs-30 ml-5">{{ vm.aggregate.serviceSuffix }}</span>
              </div>
            </ion-card>
          </ion-col>
          <ion-col size="6" size-md="3" class="p-8">
            <ion-card class="new-design-card">
              <div class="fs-16 fw-500 text-dark">
                {{ 'Cost.Tariff.TotalCharges' | translate }}
              </div>
              <div>
                <span class="fs-18 dollar-sign">$ </span>
                <span class="total-span fw-600 fs-30 text-dark">{{ vm.aggregate.total | number: '1.2-2' }}</span>
                <span class="demand-span fw-600 fs-30 ml-5">{{ vm.aggregate.totalSuffix }}</span>
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
<ng-container *ngIf="(vm$ | async).loading">
  <app-skeleton-items
    [skeletonType]="SkeletonPlaceholder.TABLE_HEADER_FOUR_COLUMN"
    [showCard]="true"
  ></app-skeleton-items>
</ng-container>
