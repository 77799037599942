import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unit-costs-aggregate-historical',
  templateUrl: './unit-costs-aggregate-historical.component.html',
  styleUrls: ['./unit-costs-aggregate-historical.component.scss'],
})
export class UnitCostsAggregateHistoricalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
