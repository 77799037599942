import { Injectable } from '@angular/core';
import { States } from '@class/commons/constants';
import { AvailableAPI, RequestMethod, UseHeaderType } from '@class/commons/request-api.model';
import { HTTP_ERRORS_TRANSLATION_HEADING, generateHttpErrorMessage } from '@class/error/http-error-types';
import { ApiWrapper } from '@service/common/api-wrapper.service';
import { TranslationsService } from '@service/common/translations.service';
import {
  BehaviorSubject,
  Observable,
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { UnitStore } from './unit.store';

/**
 * Sample Api Response
 * {
    "unit_name": "Clay's House",
    "duration_days": 7,
    "metric": "SWDINPV.MMXN1.TotW.instMag[MX]",
    "forecast_kwh": 17.9708125
  }
 */
interface UnitForecast {
  unit_name: string;
  duration_days: number;
  metric: string;
  forecast_kwh: number;
}
interface UnitCarbonSaving {
  tonnesOfCarbon: number;
  energyProduced: number;
  treesPlanted: number;
  forecastKwh: number;
}
export interface UnitCarbonSavingStateData {
  data: UnitCarbonSaving;
  status: States;
  error?: string;
  message?: string;
}
const UNIT_CARBON_SAVING_INITIAL_STATE: UnitCarbonSavingStateData = {
  status: States.INITIAL_LOADING,
  data: null,
};

@Injectable({
  providedIn: 'root',
})
export class UnitCarbonSavingStore {
  private _unitCarbonSavingStore = new BehaviorSubject<UnitCarbonSavingStateData>(UNIT_CARBON_SAVING_INITIAL_STATE);
  unitCarbonSavingStore$ = this._unitCarbonSavingStore.asObservable();

  constructor(
    private _api: ApiWrapper,
    private _translationsService: TranslationsService,
    private _unitStore: UnitStore,
  ) {
    this.getUnitForecast();
  }

  retryUnitForecast(): void {
    this.getUnitForecast();
  }

  // private methods
  private getUnitForecast() {
    this._unitStore.unit$
      .pipe(
        filter((unitData) => unitData.data !== null),
        distinctUntilKeyChanged('data', (prev, curr) => prev.uuid === curr.uuid),
        switchMap((unit) => this.getUnitForecastApi(unit.data.id)),
        map((unitForecast) => {
          /**
           * As a result of the history api call being terribly slow for long time periods, we are
           * temporarily extrapolating the day forecast to a week
           */
          const energyProduced = 7 * Math.round(unitForecast.forecast_kwh);
          /**
           * Conversion constants found here:
           * https://www.epa.gov/energy/greenhouse-gases-equivalencies-calculator-calculations-and-references
           * 7.07 × 10-4 metric tons CO2/kWh
           * 0.060 metric ton CO2 per urban tree planted
           */
          const tonnesOfCarbon = Number((0.000707 * energyProduced).toPrecision(2));
          const treesPlanted = Math.round(tonnesOfCarbon / 0.06);
          const forecastKwh = unitForecast.forecast_kwh;
          return {
            data: { energyProduced, tonnesOfCarbon, treesPlanted, forecastKwh },
            status: States.INITIAL_DATA_RECEIVED,
          };
        }),
        catchError((error) => this.handleError(error)),
        startWith(UNIT_CARBON_SAVING_INITIAL_STATE),
        tap((unitForecastData) => {
          this._unitCarbonSavingStore.next(unitForecastData);
        }),
      )
      .subscribe();
  }
  private getUnitForecastApi(unitId: string): Observable<UnitForecast> {
    return this._api.handleObservableRequest({
      useAPI: AvailableAPI.SWITCHDIN,
      url: `/api/v1/units/${unitId}/forecast/`,
      requestMethod: RequestMethod.GET,
      useHeader: UseHeaderType.AUTHORIZED_SWDIN,
      requestData: {},
    }) as Observable<UnitForecast>;
  }
  private handleError(err, state: States = States.INITIAL_ERROR): Observable<UnitCarbonSavingStateData> {
    const message = generateHttpErrorMessage(err, this._translationsService.instant(HTTP_ERRORS_TRANSLATION_HEADING));
    return of({
      status: state,
      error: message.message,
      data: null,
    });
  }
}
