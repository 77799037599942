import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { VPP_TABLE_PAGE_SIZE_OPTIONS } from '@class/vpp/vpp-dispatch.model';
import { Subscription } from 'rxjs';
import { VppModes } from '../../classes/vpp/vpp-demand-type-mode.model';
import {
  VppSettingUnitsDisplayRow,
  VppUnitDeviceTypes,
  VPP_CONTROL_GROUP_UNIT_TABLE_DEVICE_TYPE_INFO,
} from '../../classes/vpp/vpp-unit.model';
import { VppSettingsGroupFacadeService } from '../../services/virtualPowerPlants/facades/setting/group/vpp-settings-group-facade.service';

@Component({
  selector: 'app-control-group-units',
  templateUrl: './control-group-units.component.html',
  styleUrls: ['./control-group-units.component.scss'],
})
export class ControlGroupUnitsComponent implements OnDestroy {
  displayedColumns2: string[] = ['di_avail_head', 'di_disp_head', 'dr_avail_head', 'dr_disp_head'];
  displayedColumns: string[] = ['name']
    .concat(Object.values(VppUnitDeviceTypes))
    .concat(['di_avail', 'di_disp', 'dr_avail', 'dr_disp']);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  readonly VPP_TABLE_PAGE_SIZE_OPTIONS = VPP_TABLE_PAGE_SIZE_OPTIONS;

  dataSource: MatTableDataSource<VppSettingUnitsDisplayRow>;
  deviceClasses = Object.values(VppUnitDeviceTypes);
  deviceIconMap = VPP_CONTROL_GROUP_UNIT_TABLE_DEVICE_TYPE_INFO;
  vppMode$ = this.settingGroupFacade.vppModeSelected$;
  readonly VppModes = VppModes;
  rowCount: number;
  private rowSubscription: Subscription;
  constructor(private settingGroupFacade: VppSettingsGroupFacadeService) {
    this.rowSubscription = this.settingGroupFacade.vppSettingUnitsDisplayRows$.subscribe((rows) => {
      this.rowCount = rows.length;
      this.dataSource = new MatTableDataSource(rows);
      this.dataSource.paginator = this.paginator;
    });
  }

  public vppModeSelected(mode: VppModes): void {
    this.settingGroupFacade.selectModeToDisplayUnits(mode);
  }
  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  ngOnDestroy(): void {
    if (this.rowSubscription) this.rowSubscription.unsubscribe();
  }
}
