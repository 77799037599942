<ion-row class="ion-padding">
  <ion-col size="3" class="ion-align-self-center">
    <ion-item lines="none">
      <ion-select
        interface="popover"
        [disabled]="showLoader"
        [(ngModel)]="demandType"
        (ngModelChange)="onDemandTypeModeChange()"
        class="base-input"
      >
        <ion-select-option [value]="VppDemandTypes.DEMAND_INCREASE">
          {{ 'VirtualPowerPlant.DemandIncrease' | translate }}
        </ion-select-option>
        <ion-select-option [value]="VppDemandTypes.DEMAND_REDUCTION">
          {{ 'VirtualPowerPlant.DemandReduction' | translate }}
        </ion-select-option>
        <ion-select-option [value]="VppModes.FCAS">FCAS</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-col>
  <ion-col class="ion-align-self-center">
    <ion-item lines="none">
      <ion-select interface="popover" [disabled]="true" [value]="TimePeriodResolution.DAY" class="base-input">
        <ion-select-option [value]="TimePeriodResolution.DAY"> {{ 'General.Day' | translate }} </ion-select-option>
      </ion-select>
    </ion-item>
  </ion-col>
  <ion-col class="ion-align-self-center">
    <ion-item lines="none">
      <ion-button
        slot="start"
        fill="outline"
        (click)="onPrevious()"
        color="button-primary"
        [disabled]="disablePrevious || showLoader"
      >
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
      <ion-datetime-button datetime="datetime" class="width-100-pct"></ion-datetime-button>
      <ion-popover [keepContentsMounted]="true" [showBackdrop]="false">
        <ng-template>
          <ion-datetime
            id="datetime"
            [presentation]="ionDatePickerPresentation"
            [max]="ionMaxDate"
            [min]="ionMinDate"
            [(ngModel)]="currentSelectedDate"
            (ngModelChange)="dateChangedCalendar($event)"
            [disabled]="showLoader"
            [showDefaultButtons]="true"
          >
          </ion-datetime>
        </ng-template>
      </ion-popover>
      <ion-button
        slot="end"
        fill="outline"
        (click)="onNext()"
        color="button-primary"
        [disabled]="disableNext || showLoader"
      >
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-col>
  <ion-col size="1" class="ion-align-self-center">
    <ion-button (click)="refresh()" [disabled]="showLoader || showLoader" fill="outline" color="button-primary">
      <ion-icon slot="icon-only" name="refresh"></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>
<ion-row class="analytic-card-size" #analyticCard>
  <ion-col>
    <div [ngClass]="{ loader: showLoader }"></div>
    <div [ngClass]="{ 'message-overlay': statusText }">{{ statusText }}</div>
    <div>
      <div [ngClass]="{ opac: showLoader || statusText }" #historyChart></div>
    </div>
  </ion-col>
</ion-row>
