<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> {{"UnitPage.CreateNewTunnel" | translate}} </ion-title>
  </ion-toolbar>
</ion-header>
<!-- TUNNEL FORM -->
<ion-content>
  <ion-grid class="card-slide-layout">
    <ion-row>
      <ion-col>
        <ion-card class="ion-no-margin">
          <div
            class="ion-margin"
            *ngIf="selectedEndpoint && selectedEndpoint.services && selectedEndpoint.services.length <= 0"
          >
            <ion-icon name="close-circle" color="danger"></ion-icon>
            <ion-text color="danger"> {{"UnitPage.EndpointHasNoServices" | translate}} </ion-text>
          </div>
          <div
            class="ion-margin"
            *ngIf="selectedEndpoint && selectedEndpoint.devices && selectedEndpoint.devices.length <= 0"
          >
            <ion-icon name="close-circle" color="danger"></ion-icon>
            <ion-text color="danger"> {{"UnitPage.NoDevicesAvailableToCreateTunnel" | translate}} </ion-text>
          </div>
          <div
            *ngIf="selectedEndpoint && selectedEndpoint.services && selectedEndpoint.devices && selectedEndpoint.services.length > 0 && selectedEndpoint.devices.length > 0"
          >
            <form (ngSubmit)="createTunnel(tunnelData)" #tunnelForm="ngForm">
              <ion-row class="ion-margin-bottom">
                <ion-col class="ion-padding" size="12">
                  <ion-text color="primary"> {{"UnitPage.CreateTunnelNote" | translate}} </ion-text>
                </ion-col>
                <ion-col class="ion-padding" size="12">
                  <ion-text> {{ 'General.Droplet' | translate }} - {{ selectedEndpoint.uuid }} </ion-text>
                </ion-col>

                <ion-col class="ion-no-padding" size="12">
                  <ion-item class="md-like-swidin-input ion-margin">
                    <ion-select
                      [(ngModel)]="tunnelData.device"
                      (ngModelChange)="deviceSelection(tunnelData.device)"
                      required
                      name="device"
                      #device="ngModel"
                      [interfaceOptions]="customAlertOptionsDevice"
                      labelPlacement="floating"
                      interface="popover"
                    >
                      <div slot="label">{{ "General.Device" | translate }} <ion-text color="danger">*</ion-text></div>
                      <ion-select-option *ngFor="let device of selectedEndpoint.devices" [value]="device"
                        >{{ device.full_name }}</ion-select-option
                      >
                    </ion-select>
                  </ion-item>
                  <ion-text
                    class="ion-padding-start"
                    *ngIf="device.invalid && (device.dirty || device.touched)"
                    color="danger"
                  >
                    {{ "UnitPage.DeviceIsRequired" | translate }}
                  </ion-text>
                </ion-col>
                <!-- This provide option to switch to manually enter ip to create tunnel to that device
                           Not sure this is needed but the staff would love to have it
                           Not sure will be needed by anyone outside staff
                           At the moment only displaying this to staff
                           But can change if needed by any other user -->
                <ng-container *ifHasPermission="[PermissionKey.GLOBAL_STAFF]">
                  <div class="fullWidth ion-text-end" *ngIf="tunnelData.device != null">
                    <ion-text
                      color="primary"
                      class="ion-padding cursor-pointer ion-text-uppercase text-deco-under"
                      (click)="showManualIpTunnelCreate = !showManualIpTunnelCreate"
                    >
                      {{!showManualIpTunnelCreate ? ("UnitPage.SwitchToManualIp" | translate) :
                      ("UnitPage.SwitchToAutoIp" | translate)}}
                    </ion-text>
                  </div>
                </ng-container>
                <div class="fullWidth" *ngIf="showManualIpTunnelCreate && tunnelData.device != null">
                  <!-- MANUAL IP FOR CLAY ONLY-->
                  <ion-col class="ion-no-padding" size="12">
                    <ion-item class="md-like-swidin-input ion-margin">
                      <ion-input
                        type="text"
                        required
                        name="device_ip_address"
                        [(ngModel)]="tunnelData.device_ip_address"
                        #device_ip_address="ngModel"
                        label="{{ 'UnitPage.TargetIPAddressonLAN' | translate }}"
                        labelPlacement="floating"
                      ></ion-input>
                    </ion-item>
                    <ion-text
                      *ngIf="device_ip_address.invalid && (device_ip_address.dirty || device_ip_address.touched)"
                      color="danger"
                    >
                      {{ "UnitPage.IPIsRequired" | translate }}
                    </ion-text>
                  </ion-col>
                  <ion-col class="ion-no-padding" size="12">
                    <ion-item class="md-like-swidin-input ion-margin">
                      <ion-input
                        type="number"
                        min="1"
                        required
                        autofocus="true"
                        name="port"
                        [(ngModel)]="tunnelData.port"
                        #port="ngModel"
                        label="{{ 'General.Port' | translate }}"
                        labelPlacement="floating"
                      ></ion-input>
                    </ion-item>
                    <ion-text *ngIf="port.invalid && (port.dirty || port.touched)" color="danger">
                      {{ "UnitPage.PortRequired" | translate }}
                    </ion-text>
                    <ion-text
                      *ngIf="!(port.invalid && (port.dirty || port.touched)) && numberNotValid(tunnelData.port)"
                      color="danger"
                    >
                      {{ "UnitPage.ValueNotValid" | translate }}
                    </ion-text>
                  </ion-col>
                </div>
                <div class="width-100-pct ion-padding-top" *ngIf="!showManualIpTunnelCreate">
                  <!-- NON-MANUAL TUNNEL -->
                  <ion-col class="ion-no-padding" size="12">
                    <div *ngIf="servicesAvailableForSelectedDevice && tunnelData.device != null">
                      <ion-text color="primary"> {{"UnitPage.ServiceWillBeDisabled" | translate}} </ion-text>
                      <ion-item class="md-like-swidin-input ion-margin">
                        <ion-select
                          [(ngModel)]="tunnelData.service"
                          (ngModelChange)="serviceSelection(tunnelData.service)"
                          required
                          name="service"
                          #service="ngModel"
                          [interfaceOptions]="customAlertOptionsService"
                          interface="popover"
                          labelPlacement="floating"
                        >
                          <div slot="label">{{ "Cost.Service" | translate }} <ion-text color="danger">*</ion-text></div>
                          <ion-select-option
                            *ngFor="let service of listOfAvailableServicesForSelectedDevice"
                            [disabled]="service.canNotCreateOtherTunnel"
                            [value]="service"
                            >{{ service.name }}</ion-select-option
                          >
                        </ion-select>
                      </ion-item>
                      <ion-text *ngIf="service.invalid && (service.dirty || service.touched)" color="danger">
                        {{ "UnitPage.ServiceIsRequired" | translate }}
                      </ion-text>
                    </div>
                    <div
                      *ngIf="!servicesAvailableForSelectedDevice && tunnelData.device != null"
                      class="ion-padding-start"
                    >
                      <ion-icon name="close-circle" color="danger"></ion-icon>
                      <ion-text color="danger"> {{"UnitPage.DeviceHasNoService" | translate}} </ion-text>
                    </div>
                  </ion-col>
                  <div
                    *ngIf="servicesAvailableForSelectedDevice && tunnelData.device != null && tunnelData.service != null"
                    class="width-100-pct"
                  >
                    <ion-col class="ion-no-padding" size="12">
                      <ion-item lines="none">
                        <ion-toggle
                          color="button-secondary"
                          [(ngModel)]="tunnelData.disable_control"
                          name="disableControl"
                          #disableControl="ngModel"
                          [disabled]="tunnelData.service.disable_control.includes(MUST_BE)"
                          labelPlacement="start"
                          justify="space-between"
                        >
                          {{"UnitPage.DisableControl" | translate}}
                          {{tunnelData.service.disable_control.includes(MUST_BE) ? ("| " +
                          tunnelData.service.disable_control) : ''}}
                        </ion-toggle>
                      </ion-item>
                    </ion-col>
                    <ion-col class="ion-no-padding" size="12">
                      <ion-item lines="none">
                        <ion-toggle
                          color="button-secondary"
                          [(ngModel)]="tunnelData.disable_comms"
                          name="disableComms"
                          #disableComms="ngModel"
                          [disabled]="tunnelData.service.disable_comms.includes(MUST_BE)"
                          labelPlacement="start"
                          justify="space-between"
                        >
                          {{"UnitPage.DisableComms" | translate}} {{tunnelData.service.disable_comms.includes(MUST_BE) ?
                          ("| " + tunnelData.service.disable_comms) : ''}}
                        </ion-toggle>
                      </ion-item>
                    </ion-col>
                    <ion-col class="ion-no-padding" size="12">
                      <ion-item lines="none">
                        <ion-toggle
                          color="button-secondary"
                          [(ngModel)]="tunnelData.allow_multiple"
                          name="allowMultiple"
                          #allowMultiple="ngModel"
                          [disabled]="tunnelData.service.allow_multiple.includes(MUST_BE)"
                          labelPlacement="start"
                          justify="space-between"
                        >
                          {{"UnitPage.AllowMultiple" | translate}} {{tunnelData.service.allow_multiple.includes(MUST_BE)
                          ? ("|" + tunnelData.service.allow_multiple) : ''}}
                        </ion-toggle>
                      </ion-item>
                    </ion-col>
                    <ion-col class="ion-no-padding" size="12">
                      <ion-item class="md-like-swidin-input ion-margin">
                        <ion-input
                          type="text"
                          required
                          name="protocol"
                          [(ngModel)]="tunnelData.protocol"
                          #protocol="ngModel"
                          disabled
                          label="{{ 'General.Protocol' | translate }}"
                          labelPlacement="floating"
                        ></ion-input>
                      </ion-item>
                      <ion-text color="medium"> {{ "UnitPage.OnlySupportingTcp" | translate }} </ion-text>
                    </ion-col>
                    <ion-col class="ion-no-padding" size="12">
                      <ion-item class="md-like-swidin-input ion-margin">
                        <ion-input
                          type="number"
                          required
                          min="1"
                          name="durationValue"
                          [(ngModel)]="tunnelData.duration_value"
                          #durationValue="ngModel"
                          label="{{ 'UnitPage.DurationInMinutes' | translate }}"
                          labelPlacement="floating"
                        ></ion-input>
                      </ion-item>
                      <ion-text
                        *ngIf="durationValue.invalid && (durationValue.dirty || durationValue.touched)"
                        color="danger"
                      >
                        {{ "UnitPage.DurationRequired" | translate }}
                      </ion-text>
                      <ion-text
                        *ngIf="!(durationValue.invalid && (durationValue.dirty || durationValue.touched)) && numberNotValid(tunnelData.duration_value)"
                        color="danger"
                      >
                        {{ "UnitPage.ValueNotValid" | translate }}
                      </ion-text>
                    </ion-col>
                    <ion-col class="ion-no-padding" size="12">
                      <ion-item class="md-like-swidin-input ion-margin">
                        <ion-input
                          type="number"
                          min="1"
                          required
                          autofocus="true"
                          name="port"
                          [(ngModel)]="tunnelData.port"
                          #port="ngModel"
                          label="{{ 'General.Port' | translate }}"
                          labelPlacement="floating"
                        ></ion-input>
                      </ion-item>
                      <ion-text *ngIf="port.invalid && (port.dirty || port.touched)" color="danger">
                        {{ "UnitPage.PortRequired" | translate }}
                      </ion-text>
                      <ion-text
                        *ngIf="!(port.invalid && (port.dirty || port.touched)) && numberNotValid(tunnelData.port)"
                        color="danger"
                      >
                        {{ "UnitPage.ValueNotValid" | translate }}
                      </ion-text>
                    </ion-col>
                  </div>
                </div>
              </ion-row>
              <ion-row class="ion-align-self-end">
                <ion-col>
                  <ion-button
                    color="button-primary"
                    size="large"
                    expand="block"
                    type="submit"
                    [disabled]="!showManualIpTunnelCreate ? !tunnelForm.form.valid || !servicesAvailableForSelectedDevice || numberNotValid(tunnelData.duration_value)
                            || numberNotValid(tunnelData.port) : !tunnelForm.form.valid || numberNotValid(tunnelData.port)"
                  >
                    {{"UnitPage.CreateNewTunnel" | translate }}
                  </ion-button>
                </ion-col>
              </ion-row>
            </form>
          </div>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
