import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DropletController, DropletControllerAddUpdateEvent } from '@class/units/droplet/droplet-controller.model';
import { DeviceState, DropletService } from '@service/units/endpoints/devices.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { DropletDisplay } from '@class/units/droplet/droplet-display.model';
import { TranslationsService } from '@service/common/translations.service';
import { ApiWrapper } from '@service/common/api-wrapper.service';
import {
  DropletControllerInventoryRequest,
  DROPLET_CONTROLLER_MQTT_REQUEST_TIMEOUT_MS,
} from '@class/units/droplet/controller-inventory-request/droplet-controller-inventory-request.model';
import {
  EndpointControllerDTO,
  EndpointControllerResponseDTO,
  EndpointControllerUpdateDTO,
} from '@class/units/endpoint/endpoint-payload.model';
import { DropletControllerInventoryService } from '@class/units/droplet/controller-inventory-request/droplet-controller-inventory-request.service';
import { Subscription } from 'rxjs';
import { BrowserLogger } from '@class/core/browser-logger';
import { GenericAlertsToastsService } from '@service/common/generic-alerts-toasts.service';
import { AddNewControllerComponent } from '../add-new-controller/add-new-controller.component';
import { UnitsService } from '@service/units/units.service';
import { EmitEvent, Event, EventBusService } from '@service/core/event-bus.service';
import { cloneDeep } from 'lodash';

interface ControllerDetailViewModel {
  controller: DropletController;
  droplet: DropletDisplay;
}

@Component({
  selector: 'app-controller-detail',
  templateUrl: './controller-detail.component.html',
  styleUrls: ['./controller-detail.component.scss'],
})
export class ControllerDetailComponent implements OnInit, OnDestroy {
  vm: ControllerDetailViewModel = {
    controller: null,
    droplet: null,
  };
  @Input() isUnitActive: boolean;

  private _droplets: DropletDisplay[];
  private _dropletStateSubscription: Subscription;
  private _controllerInventoryReceivedSubscription: Subscription;
  private _editControllerSubscription: Subscription;
  private _editControllerModal: HTMLIonModalElement;

  // this variable will hold the value for unique id & version number
  // that we CREATED, UPDATED or REMOVED
  private _controllerCRUD = {
    uniqueId: '',
    versionNumber: null as number,
    loader: null as HTMLIonLoadingElement, // for every request we will create a loader that will be displayed until we get confirmation or timeout occurred
    timeout: null as NodeJS.Timeout,
  };

  readonly PermissionKey = PermissionKey;

  constructor(
    private _dropletService: DropletService,
    private _translationsService: TranslationsService,
    private _controllerInventoryService: DropletControllerInventoryService,
    private _api: ApiWrapper,
    private _unitsService: UnitsService,
    private _alertsToastsService: GenericAlertsToastsService,
    private _eventBus: EventBusService,
  ) {
    // subscribe to the dropletService State changes
    this._dropletStateSubscription = this._dropletService.vm$.subscribe((state) => {
      this.handleSelectedControllerState(state);
    });
    // subscribe to Controller Inventory Received event (controller CRUD operation checks)
    this._controllerInventoryReceivedSubscription = this._eventBus
      .on(Event.CONTROLLER_INVENTORY_STATE)
      .subscribe((event: DropletControllerInventoryRequest) => {
        this.handleControllerInventoryState(event);
      });
    // subscribe to Edit Controller event
    this._editControllerSubscription = this._eventBus.on(Event.CONTROLLER_UPDATE).subscribe((data) => {
      this.handleEditControllerEvent(data);
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.finaliseCRUD(); // incase a CRUD operation/loader is being finalised for the GUI
    this._dropletStateSubscription?.unsubscribe();
    this._controllerInventoryReceivedSubscription?.unsubscribe();
    this._editControllerSubscription?.unsubscribe();
  }

  private handleSelectedControllerState(state: DeviceState): void {
    // keep a reference to the droplets
    this._droplets = state?.data || null;

    // check state
    if (this._droplets && state.displayedEndpointControllerIndex) {
      const endpointIdx = state.displayedEndpointControllerIndex.endpointIndex;
      const controllerIdx = state.displayedEndpointControllerIndex.controllerIndex;
      if (endpointIdx !== undefined && controllerIdx !== undefined) {
        this.vm.droplet = this._droplets[endpointIdx];
        this.vm.controller = this.vm.droplet.controllers[controllerIdx];
        // apply any devices assigned to this controller
        this.vm.controller.setDeviceOverviews(this.vm.droplet.devices);
      }
    }

    BrowserLogger.log('ControllerDetail.handleSelectedControllerState', { state, vm: this.vm });
  }

  private handleControllerInventoryState(controllerInventoryRequest: DropletControllerInventoryRequest) {
    if (
      controllerInventoryRequest.dropletUuid &&
      controllerInventoryRequest.dropletUuid === this.vm.droplet?.overview?.uuid
    ) {
      BrowserLogger.log('ControllerDetail.handleControllerInventoryState', {
        controllerInventoryRequest,
        vm: this.vm,
      });
      this.checkSelectedControllerAfterInventoryUpdate(controllerInventoryRequest.dropletUuid);
      this.finaliseCRUD();
    }
  }

  public handleEditClick(event): void {
    BrowserLogger.log('ControllerDetail.handleEditClick', { event });
    // clone the controller prior to editing so input binding references different object
    this.presentEditControllerModal(cloneDeep(this.vm.controller), this.vm.droplet);
  }

  public handleRemoveClick(event): void {
    BrowserLogger.log('ControllerDetail.handleRemoveClick', { event });
    this.removeController(this.vm.controller, this.vm.droplet);
  }

  public handleNotificationResyncConfigClick(): void {
    BrowserLogger.log('ControllerDetail.handleNotificationResyncConfigClick');
    this.resendControllerConfig(this.vm.controller, this.vm.droplet);
  }

  private async resendControllerConfig(controller: DropletController, controllerDroplet: DropletDisplay) {
    BrowserLogger.log('ControllerDetail.resendControllerConfig', { controller, controllerDroplet });
    const message = `${this._translationsService.str.unitPage.ResendingConfig}, ${this._translationsService.str.general.Wait}`;
    const loader = await this._alertsToastsService.createLoaderWithCustomText(message);
    await loader.present();

    // reset the inventory request state as not being able to be processed (new message will be incoming but need to process API first)
    this._controllerInventoryService.disableProcessing(controllerDroplet.overview.uuid);

    DropletController.sendApiSyncRequest(this._api, controller.id).then(
      (res: EndpointControllerResponseDTO) => {
        this.initCRUD(
          controllerDroplet,
          loader,
          `${res.data.Msg} ${this._translationsService.str.unitPage.WaitingConfirmationFromDroplet}, ${this._translationsService.str.general.Wait}`,
          res.data.Data.controller_unique_id,
          res.data.Data.controller_version_number,
        );

        // apply the controllers received from the sync to the droplet
        controllerDroplet.applyControllersPayload(res.data.Data.controllers, this._translationsService);

        // update our droplet controller model to the new one returned from the API
        const updateController = controllerDroplet.controllers.find((c) => c.id === controller.id);
        if (updateController) {
          this.vm.controller = updateController;
          // enable the inventory request message to be processed
          this._controllerInventoryService.enableProcessing(controllerDroplet);
        }
      },
      async (error) => {
        loader.dismiss();
        await this.displayApiFailedError(error, this._translationsService.str.unitPage.SendingConfigFailed);
      },
    );
  }

  private initCRUD(
    droplet: DropletDisplay,
    loader: HTMLIonLoadingElement,
    message: string,
    uniqueId: string,
    versionNumber?: number,
  ): void {
    BrowserLogger.log('ControllerDetail.initCRUD', { droplet, controllerCRUD: this._controllerCRUD });
    loader.message = message;
    this._controllerCRUD.uniqueId = uniqueId;
    this._controllerCRUD.loader = loader;
    this.createMqttControllerCRUDConfirmationFallback(droplet);
    if (versionNumber !== undefined) {
      this._controllerCRUD.versionNumber = versionNumber;
    }
  }

  private finaliseCRUD(): void {
    BrowserLogger.log('ControllerDetail.finaliseCRUD', { controllerCRUD: this._controllerCRUD });
    if (this._controllerCRUD.uniqueId) {
      if (this._controllerCRUD.timeout) {
        clearTimeout(this._controllerCRUD.timeout);
      }
      if (this._controllerCRUD.loader) {
        this._controllerCRUD.loader.dismiss();
      }
      // Found or not, clear the uniqueId/versionNo
      this._controllerCRUD.uniqueId = null;
      this._controllerCRUD.versionNumber = null;
    }
  }

  private createMqttControllerCRUDConfirmationFallback(droplet: DropletDisplay): void {
    BrowserLogger.log('ControllerDetail.createMqttControllerCRUDConfirmationFallback', { droplet });
    // set controller crud operations fallback timeout
    // ie if we do not hear back from the droplet/api then display alert
    this._controllerCRUD.timeout = setTimeout(() => {
      if (this._controllerCRUD.loader) {
        this._controllerCRUD.loader.dismiss();
      }
      // enable the inventory request message to be processed
      this._controllerInventoryService.enableProcessing(droplet);
      // alert
      this._alertsToastsService.createErrorAlertWithOkButton({
        heading: this._translationsService.str.general.Timeout,
        subheading: '',
        message: this._translationsService.str.unitPage.TimeoutForControllerConfirmation,
      });
    }, DROPLET_CONTROLLER_MQTT_REQUEST_TIMEOUT_MS);
  }

  private checkSelectedControllerAfterInventoryUpdate(dropletUuid: string): void {
    BrowserLogger.log('ControllerDetail.checkSelectedControllerAfterInventoryUpdate', { dropletUuid });
    // selected controller
    // - check if the user has selected any controller
    // - if that controller doesn't exist on the droplet then clear the selected controller
    if (this.vm.controller?.controllerUniqueId) {
      const controllerStillExist = DropletController.findDropletControllerByUniqueId(
        this._droplets,
        dropletUuid,
        this.vm.controller?.controllerUniqueId,
      );
      // does not exist, clear any selections
      if (!controllerStillExist) {
        this.vm.controller = null;
      }
    }
  }

  private async removeController(controller: DropletController, controllerDroplet: DropletDisplay) {
    BrowserLogger.log('ControllerDetail.removeController', { controller, controllerDroplet });
    const message =
      this._translationsService.str.unitPage.RemovingController + ', ' + this._translationsService.str.general.Wait;
    const loader = await this._alertsToastsService.createLoaderWithCustomText(message);
    await loader.present();

    // reset the inventory request state as not being able to be processed (new message will be incoming but need to process API first)
    this._controllerInventoryService.disableProcessing(controllerDroplet.overview.uuid);

    //
    // this condition here checking if the controller doesn't have any pk
    // that means it doesn't exist in database
    // so have to call re-delete instead of delete
    // re-delete is a POST call that takes endpoint id and unique id of controller
    // it'll send a mqtt msg to droplet to remove this controller from droplet config
    if ((controller.id === null || controller.id === undefined) && !controller.controllerExistInDatabase) {
      DropletController.sendApiRemoveFromDropletRequest(this._api, {
        endpoint_id: controllerDroplet.overview.id,
        controller_unique_id: controller.controllerUniqueId,
      }).then(
        () => {
          this.handleControllerRemoved(loader, controller, controllerDroplet);
        },
        (error) => {
          loader.dismiss();
          this.displayApiFailedError(error, this._translationsService.str.unitPage.RemovingControllerFailed);
        },
      );
    } else {
      DropletController.sendApiRemoveRequest(this._api, controller.id).then(
        () => {
          this.handleControllerRemoved(loader, controller, controllerDroplet);
        },
        (error) => {
          loader.dismiss();
          this.displayApiFailedError(error, this._translationsService.str.unitPage.RemovingControllerFailed);
        },
      );
    }
  }

  private handleControllerRemoved(
    loader: HTMLIonLoadingElement,
    controller: DropletController,
    controllerDroplet: DropletDisplay,
  ) {
    BrowserLogger.log('ControllerDetail.handleControllerRemoved', { controller, controllerDroplet });

    this.initCRUD(
      controllerDroplet,
      loader,
      this._translationsService.str.unitPage.WaitingConfirmationFromDroplet +
        ', ' +
        this._translationsService.str.general.Wait,
      controller.controllerUniqueId,
    );

    DropletController.removeFromArray(this.vm.droplet.controllers, this._controllerCRUD.uniqueId);

    // enable the inventory request message to be processed
    this._controllerInventoryService.enableProcessing(controllerDroplet);

    // clear the selected controller
    this.vm.controller = null;
    this._dropletService.clearDisplayedEndpointController();

    // emit a controller removed event
    this._eventBus.emit(new EmitEvent(Event.CONTROLLER_REMOVED));
  }

  private async presentEditControllerModal(controller: DropletController, droplet: DropletDisplay): Promise<void> {
    BrowserLogger.log('ControllerDetail.presentEditControllerModal', { controller, droplet });
    this._editControllerModal = await this._alertsToastsService.presentModalContainerComponent(
      AddNewControllerComponent,
      {
        controllerTypeList: this._unitsService.unitSiteControllers.controllers,
        endpoint: droplet,
        controller: controller,
      },
    );
    // NOTE: the modal Save is handled by the event bus handler handleEditControllerEvent()
  }

  private handleEditControllerEvent(event: DropletControllerAddUpdateEvent) {
    BrowserLogger.log('DropletList.handleEditControllerEvent', { event });
    this.updateController(this.vm.controller, event.droplet, event.payload as EndpointControllerUpdateDTO);
  }

  private async updateController(
    controller: DropletController,
    controllerDroplet: DropletDisplay,
    payload: EndpointControllerUpdateDTO,
  ): Promise<void> {
    BrowserLogger.log('DropletList.updateController', { payload, controller, droplet: controllerDroplet });
    // create a loader popup while processing the add controller
    const loader = await this._alertsToastsService.createLoaderWithCustomText(
      `${this._translationsService.str.unitPage.UpdatingController}, ${this._translationsService.str.general.Wait}`,
    );
    await loader.present();

    // reset the inventory request state as not being able to be processed (new message will be incoming but need to process API first)
    this._controllerInventoryService.disableProcessing(controllerDroplet.overview.uuid);

    DropletController.sendApiUpdateRequest(this._api, controller, payload).then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (res: any) => {
        // controller updated successfully
        loader.message = `${this._translationsService.str.unitPage.UpdatingControllerSuccess}. ${res.data.Msg}.`;

        // initialise CRUD setting for when the inventory response comes back
        this.initCRUD(
          controllerDroplet,
          loader,
          this._translationsService.str.unitPage.WaitingConfirmationFromDroplet +
            ', ' +
            this._translationsService.str.general.Wait,
          controller.controllerUniqueId,
        );

        // response contains the updated controller so need to apply it to in-memory controller
        this.updateViewModelControllerFromPayload(controllerDroplet, res.data);

        // enable the inventory request message to be processed
        this._controllerInventoryService.enableProcessing(controllerDroplet);

        // emit a controller updated event
        this._eventBus.emit(new EmitEvent(Event.CONTROLLER_UPDATED));

        // close the modal
        loader.dismiss();
        this._editControllerModal?.dismiss();
      },
      async (error) => {
        // error updating controller
        loader.dismiss();
        await this.displayApiFailedError(error, this._translationsService.str.unitPage.UpdatingControllerFailed);
      },
    );
  }

  private updateViewModelControllerFromPayload(
    droplet: DropletDisplay,
    endpointControllerDTO: EndpointControllerDTO,
  ): void {
    // update our controller used for the view model from the api response payload
    const updatedController = droplet.applyEndpointControllerDTO(endpointControllerDTO, this._translationsService);

    // apply the controller to our view model so it will refresh
    // note: cannot re-use the same object as needs to be a new object pointer ref
    this.vm.controller = cloneDeep(updatedController);

    // reapply any devices assigned to this controller
    this.vm.controller.setDeviceOverviews(this.vm.droplet.devices);

    BrowserLogger.log('DropletList.updateViewModelControllerFromPayload', {
      droplet,
      updatedController,
      endpointControllerDTO,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async displayApiFailedError(error: any, subHeading: string): Promise<void> {
    BrowserLogger.log('DropletList.displayApiFailedError', { error });
    // display an alert with the error
    const msg = `${error.error.Msg} [${error.error.Code}: ${error.error.Status}]`;
    const alert = await this._alertsToastsService.createAlertWithOkButton(
      this._translationsService.str.general.Failed,
      subHeading,
      msg,
    );
    await alert.present();
  }
}
