import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CostState, UnitCostService } from '../../services/units/unit-cost.service';

@Component({
  selector: 'app-unit-costs-metrics',
  templateUrl: './unit-costs-metrics.component.html',
  styleUrls: ['./unit-costs-metrics.component.scss'],
})
export class UnitCostsMetricsComponent {
  vm$: Observable<CostState> = this.unitCostService.vm$;
  constructor(public unitCostService: UnitCostService) {}
}
