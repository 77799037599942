import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { BrowserLogger } from '@class/core/browser-logger';
import { ThemeService } from '@service/themes/theme.service';
import { ThemeInterface } from '@theme/theme-interface';
import { SvgIconNames } from '@theme/theme-map';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-toolbar',
  templateUrl: './modal-toolbar.component.html',
  styleUrls: ['./modal-toolbar.component.scss'],
})
export class ModalToolbarComponent implements OnDestroy {
  @Input() title: string;
  @Input() showBackButton: boolean;
  @Input() showCancelButton: boolean;

  @Output() onBack = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  private themeSubscription: Subscription;
  backButtonIcon = '';

  constructor(private theme: ThemeService) {
    this.themeSubscription = this.getThemeSubscription();
  }
  ngOnDestroy(): void {
    if (this.themeSubscription) this.themeSubscription.unsubscribe();
  }

  private getThemeSubscription(): Subscription {
    return this.theme.userTheme$.subscribe((theme: ThemeInterface | null) => {
      if (theme) {
        this.setBackButtonIcon(theme);
      }
    });
  }
  private setBackButtonIcon(theme: ThemeInterface): void {
    this.backButtonIcon = `${theme.iconsSvgPath}${SvgIconNames.BACK_ARROW}`;
  }

  handleBackClick(): void {
    BrowserLogger.log('ModalToolbar.handleBackClick');
    this.onBack.emit();
  }
  handleCancelClick(): void {
    BrowserLogger.log('ModalToolbar.handleCancelClick');
    this.onCancel.emit();
  }
}
