<ion-row>
  <ion-col>
    <ion-item lines="none">
      <ion-text>
        <h2>{{ 'VirtualPowerPlant.Dispatches' | translate }}</h2>
        <p>{{ 'VirtualPowerPlant.ClickOnARowToSeeDetails' | translate }}</p>
      </ion-text>
    </ion-item>
    <div class="ion-padding-horizontal" *ngIf="dataSource != undefined">
      <ion-searchbar
        class="base-input ion-margin-vertical"
        showCancelButton="never"
        placeholder="Filter"
        autocomplete="on"
        [debounce]="300"
        (ionInput)="applyFilter($event.target.value)"
        [(ngModel)]="searchValue"
      ></ion-searchbar>
      <table
        mat-table
        [dataSource]="dataSource"
        multiTemplateDataRows
        matSort
        class="mat-elevation-z8 ion-margin-bottom"
      >
        <ng-container matColumnDef="group">
          <th class="ion-text-left" mat-header-cell *matHeaderCellDef>{{ 'VirtualPowerPlant.Group' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.group }}</td>
        </ng-container>
        <ng-container matColumnDef="order">
          <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'VirtualPowerPlant.Merit' | translate }}</th>
          <td class="ion-text-center" mat-cell *matCellDef="let element">{{ element.order }}</td>
        </ng-container>
        <ng-container matColumnDef="controlDescription">
          <th class="ion-text-center" mat-header-cell *matHeaderCellDef>
            {{ 'VirtualPowerPlant.ControlDescription' | translate }}
          </th>
          <td class="ion-text-center" mat-cell *matCellDef="let element">{{ element.controlDescription }}</td>
        </ng-container>
        <ng-container matColumnDef="dispatchType">
          <th class="ion-text-center" mat-header-cell *matHeaderCellDef>
            {{ 'VirtualPowerPlant.DispatchType' | translate }}
          </th>
          <td class="ion-text-center" mat-cell *matCellDef="let element">
            <span *ngIf="element.dispatchType === VppDispatchType.MANUAL">
              {{ 'VirtualPowerPlant.Scheduled' | translate }} | {{ 'VirtualPowerPlant.Manual' | translate }}
            </span>
            <span *ngIf="element.dispatchType === VppDispatchType.AUTO">
              {{ 'VirtualPowerPlant.Scheduled' | translate }} | {{ 'VirtualPowerPlant.Auto' | translate }}
            </span>
            <span
              *ngIf="element.dispatchType !== VppDispatchType.MANUAL && element.dispatchType !== VppDispatchType.AUTO"
            >
              {{ element.dispatchType }}
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="startTime">
          <th class="ion-text-center" mat-header-cell *matHeaderCellDef>
            {{ 'VirtualPowerPlant.StartTime' | translate }}
          </th>
          <td class="ion-text-center" mat-cell *matCellDef="let element">
            {{ element.startTimeEpochSec | vppTimezoneLocale: LOCALE_OPTION | async }}
          </td>
        </ng-container>
        <ng-container matColumnDef="endTime">
          <th class="ion-text-center" mat-header-cell *matHeaderCellDef>
            {{ 'VirtualPowerPlant.EndTime' | translate }}
          </th>
          <td class="ion-text-center" mat-cell *matCellDef="let element">
            {{ element.endTimeEpochSec | vppTimezoneLocale: LOCALE_OPTION | async }}
          </td>
        </ng-container>
        <ng-container matColumnDef="policy">
          <th class="ion-text-center" mat-header-cell *matHeaderCellDef>
            {{ 'VirtualPowerPlant.Policies' | translate }}
          </th>
          <td class="ion-text-center" mat-cell *matCellDef="let element">{{ element.policiesName }}</td>
        </ng-container>
        <ng-container matColumnDef="schedule">
          <th class="ion-text-center" mat-header-cell *matHeaderCellDef>
            {{ 'VirtualPowerPlant.Schedule' | translate }}
          </th>
          <td class="ion-text-center" mat-cell *matCellDef="let element">{{ element.schedule?.name }}</td>
        </ng-container>
        <ng-container matColumnDef="device">
          <th class="ion-text-left" mat-header-cell *matHeaderCellDef>{{ 'General.Device' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.deviceName }}</td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th class="ion-text-center" mat-header-cell *matHeaderCellDef>{{ 'VirtualPowerPlant.User' | translate }}</th>
          <td class="ion-text-center" mat-cell *matCellDef="let element">{{ element.userFullname }}</td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <div class="expandRow-element-detail">
              <ion-row style="width: 100%">
                <ion-col>
                  <div class="ion-margin-top ion-margin-bottom divGrid">
                    <h4 class="ion-text-capitalize">{{ 'VirtualPowerPlant.DispatchDetail' | translate }}</h4>
                    <ion-label>
                      {{ 'VirtualPowerPlant.DemandType' | translate }}: <strong>{{ element.demandType }}</strong>
                    </ion-label>
                    <ion-label>
                      {{ 'VirtualPowerPlant.Action' | translate }}: <strong>{{ element.action }}</strong>
                    </ion-label>
                    <ion-label>
                      {{ 'VirtualPowerPlant.ControlValue' | translate }}: <strong>{{ element.controlValue }}</strong>
                    </ion-label>
                    <ion-label>
                      {{ 'VirtualPowerPlant.RequestTime' | translate }}: <strong>{{ element.requestTime }}</strong>
                    </ion-label>
                  </div>
                </ion-col>
                <ion-col>
                  <div
                    class="ion-margin-top ion-margin-bottom divGrid"
                    *ngIf="element.schedule != null && element.schedule != undefined"
                  >
                    <h4 class="ion-text-capitalize">{{ 'VirtualPowerPlant.ScheduleDetail' | translate }}</h4>
                    <ion-label>
                      {{ 'General.Name' | translate }}: <strong>{{ element.schedule.name }}</strong>
                    </ion-label>
                    <ion-label>
                      {{ 'VirtualPowerPlant.Description' | translate }}:
                      <strong>{{ element.schedule.description }}</strong>
                    </ion-label>
                  </div>
                  <div class="ion-margin-top ion-margin-bottom divGrid" *ngIf="element.policiesName?.length > 0">
                    <h4 class="ion-text-capitalize">{{ 'VirtualPowerPlant.Policies' | translate }}</h4>
                    <div class="divGrid">
                      <ion-label>
                        <strong>{{ element.policiesName }}</strong>
                      </ion-label>
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="firstRowHeader"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: columnsToDisplay"
          class="expandRow-element-row"
          [class.expandRow-expanded-row]="expandedElement === element"
          (click)="rowClicked(element)"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandRow-detail-row"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 50]" pageSize="25" class="ion-margin-top"></mat-paginator>
    </div>
  </ion-col>
</ion-row>
