import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { MAX_WIDTH_WIDE, TOTAL_COL } from '@class/commons/constants';
import { ColBreakpoint } from '@class/commons/enums';
import { TranslationsService } from '@service/common/translations.service';
import { CharBarTrace, ComparisonChartPeriod } from '@service/units/unit-energy-comparison.store';
import Plotly from 'plotly.js-dist';

@Component({
  selector: 'app-comparison-chart',
  templateUrl: './comparison-chart.component.html',
  styleUrls: ['./comparison-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonChartComponent implements AfterViewInit {
  @ViewChild('comparePerformanceChart', { static: false }) comparePerformanceChart: ElementRef;

  @Input()
  set chartData(data: { chartData: CharBarTrace[]; timePeriod: ComparisonChartPeriod }) {
    this._chartData = data;
    this.updateComparisonChart(data.chartData, data.timePeriod);
  }
  private _chartData: { chartData: CharBarTrace[]; timePeriod: ComparisonChartPeriod };

  colours = {
    background: 'rgba(255, 255, 255, 0)',
    ticks: 'rgba(92, 100, 110, 100)',
  };

  plotLayout = {
    width: null,
    showlegend: true,
    autosize: true,
    legend: {
      orientation: 'h',
      xanchor: 'center',
      x: '0.5',
    },
    paper_bgcolor: this.colours.background,
    plot_bgcolor: this.colours.background,
    annotations: [],
    margin: { l: 50, r: 20, b: 0, t: 50, pad: 5 },
    yaxis: {
      showgrid: true,
      title: {
        text: 'kWh',
        color: this.colours.ticks,
      },
      zeroline: false,
      showline: false,
      tickfont: {
        color: this.colours.ticks,
      },
    },

    xaxis: {
      showgrid: false,
      type: 'date',
      zeroline: false,
      showline: false,
      // tickformat: '%b %d %Y',
      tickfont: {
        color: this.colours.ticks,
      },
    },
    bargap: 0.4,
    bargroupgap: 0.25,
    hovermode: 'x',
  };

  plotStyle = {
    displayModeBar: false,
  };

  @HostListener('window:resize', ['$event'])
  handleResize() {
    this.resize();
  }

  constructor(private _translationService: TranslationsService) {}

  ngAfterViewInit() {
    this.updateComparisonChart(this._chartData?.chartData, this._chartData?.timePeriod);
  }

  resize(): void {
    try {
      const element = this.comparePerformanceChart.nativeElement;
      const siteEnergyCardCompColSize = 4;
      if (element.clientWidth <= 0) {
        if (window.innerWidth >= MAX_WIDTH_WIDE) {
          this.plotLayout.width = MAX_WIDTH_WIDE * 0.95 - (MAX_WIDTH_WIDE / TOTAL_COL) * siteEnergyCardCompColSize;
        } else if (window.innerWidth >= ColBreakpoint.LG) {
          this.plotLayout.width =
            window.innerWidth * 0.95 - (window.innerWidth / TOTAL_COL) * siteEnergyCardCompColSize;
        } else {
          this.plotLayout.width = window.innerWidth * 0.85;
        }
      } else {
        this.plotLayout.width = element.clientWidth;
      }
      Plotly.relayout(element, this.plotLayout as any);
    } catch (error) {
      // error if resized before the graph got plotted
      console.warn('resized before the graph got plotted');
    }
  }

  private updateComparisonChart(chartData: CharBarTrace[], currentTimePeriod: ComparisonChartPeriod): void {
    if (!chartData || !this.comparePerformanceChart) {
      console.warn('Comparison chart attempted to render with no data!');
      return;
    }

    if (currentTimePeriod === ComparisonChartPeriod.LAST_30_DAYS) {
      this.plotLayout.bargap = 0.5;
    }
    if (chartData.length > 0) {
      this.plotLayout.annotations.length = 0;
    } else if (this.plotLayout.annotations.length === 0) {
      this.plotLayout.annotations.push({
        text: this._translationService.instant('SiteEnergy.SelectMetric'),
        showarrow: false,
        font: {
          size: '1.4em',
          color: 'medium',
        },
      });
    }
    if (Plotly) {
      try {
        Plotly.newPlot(this.comparePerformanceChart.nativeElement, chartData, this.plotLayout as any, this.plotStyle);
        this.handleResize();
      } catch (error) {
        console.error(error);
      }
    }
  }
}
