import { Component, Input } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ThemeService } from '../../../services/themes/theme.service';
import { TranslationsService } from '../../../services/common/translations.service';
import { CloudDeviceType, CloudDeviceOnboardingField } from '../cloud-device-list/cloud-device-list.component';

export interface CloudDeviceOnboardingInput extends CloudDeviceOnboardingField {
  value?: string;
}

@Component({
  selector: 'app-serial-no-entry-modal-page',
  templateUrl: './serial-no-entry-modal.page.html',
  styleUrls: ['./serial-no-entry-modal.page.scss'],
})
export class SerialNoEntryModalPage {
  @Input() deviceType: CloudDeviceType;
  @Input() enteredSN: string;
  requiredFields: CloudDeviceOnboardingField[];

  constructor(
    public theme: ThemeService,
    public viewCtrl: ModalController,
    public alert: AlertController,
    public translationsService: TranslationsService,
  ) {}
  ngOnInit(): void {
    this.deviceType && (this.requiredFields = this.deviceType.onboardingInformation.filter(this.inputFieldsFilter));
  }

  inputFieldsFilter = (field: CloudDeviceOnboardingField) => {
    if (this.enteredSN) {
      return field.requires_field && !field.is_serial_number;
    } else {
      return field.requires_field;
    }
  };

  confirm(): void {
    if (this.validateSerialNo()) {
      this.viewCtrl.dismiss(this.deviceType);
    } else {
      this.alert
        .create({
          message: this.translationsService.instant('SerialNoEntryModal.InvalidSerialNo'),
          buttons: [this.translationsService.instant('General.OK')],
        })
        .then((alert) => {
          alert.present();
        });
    }
  }
  close(): void {
    this.viewCtrl.dismiss();
  }

  validateSerialNo(): boolean {
    if (this.deviceType) {
      const validationArray = this.deviceType.onboardingInformation
        .filter(this.inputFieldsFilter)
        .map(({ value, input_patterns }: CloudDeviceOnboardingInput) => {
          return value && input_patterns.find((regex) => value.match(regex));
        });

      return validationArray.every(Boolean);
    }
    return false;
  }
}
