import { Injectable } from '@angular/core';
import { AvailableAPI, RequestMethod, UseHeaderType } from '@class/commons/request-api.model';
import { Unit } from '@class/commons/unit.model';
import { ApiWrapper } from '@service/common/api-wrapper.service';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  Observable,
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { UnitStore } from './unit.store';
import { TranslationsService } from '@service/common/translations.service';
import { ThemeService } from '@service/themes/theme.service';

/**
 * Sample Api Response
 * {
    "uuid": "c3dc714e-a3d7-11eb-9aad-0242ac110002",
    "name": "Eguana Warehouse - EVOLVE ESS AU",
    "history": {
        "t": [
            "2023-07-27T14:30:00.000Z",
            "2023-07-28T14:30:00.000Z",
            "2023-07-29T14:30:00.000Z",
            "2023-07-30T14:30:00.000Z",
            "2023-07-31T14:30:00.000Z",
            "2023-08-01T14:30:00.000Z",
            "2023-08-02T14:30:00.000Z",
            "2023-08-03T14:30:00.000Z"
        ],
        "solar": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "load": [
            50.42,
            13.92,
            11.99,
            66.38,
            65.97,
            49.09,
            58.77,
            47.57
        ],
        "grid_import": [
            53.35,
            8.82,
            1.81,
            62.17,
            65.83,
            48.86,
            54.76,
            47.58
        ],
        "grid_export": [
            -0.29,
            -12.08,
            -9.69,
            -0.48,
            -0.1,
            -0.05,
            -0.14,
            0
        ],
        "battery_charging": [
            12.65,
            12.06,
            11.73,
            0.18,
            2.44,
            4.73,
            5.4,
            0
        ],
        "battery_discharging": [
            -9.61,
            -5.18,
            -10.34,
            -4.33,
            -2.2,
            -2.21,
            -4.29,
            0
        ],
        "solar2load": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "storage2load": [
            9.59,
            5.11,
            10.22,
            4.28,
            2.19,
            2.19,
            4.24,
            0
        ],
        "solar2storage": [
            -0.27,
            -4.66,
            -4.49,
            -0.43,
            -0.1,
            -0.04,
            -0.12,
            0
        ],
        "grid2load": [
            40.83,
            8.8,
            1.76,
            62.11,
            63.77,
            46.91,
            54.53,
            47.57
        ],
        "solar2grid": [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        "grid2storage": [
            12.52,
            0.02,
            0.05,
            0.06,
            2.06,
            1.96,
            0.23,
            0
        ],
        "storage2grid": [
            -0.29,
            -12.08,
            -9.69,
            -0.48,
            -0.1,
            -0.05,
            -0.14,
            0
        ]
    }
}
 */
type ComparisonChartDataApi = {
  uuid: string;
  name: string;
  history: {
    t: string[];
    solar: number[];
    load: number[];
    grid_import: number[];
    grid_export: number[];
    battery_charging: number[];
    battery_discharging: number[];
    solar2load: number[];
    storage2load: number[];
    solar2storage: number[];
    grid2load: number[];
    solar2grid: number[];
    grid2storage: number[];
    storage2grid: number[];
  };
};
export enum ComparisonChartPeriod {
  TODAY = 'TODAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
}
enum HistoryGranularity {
  hour = 'PT1H',
  day = 'P1D',
}

export type CharBarTrace = {
  x: string[];
  y: number[];
  name: string;
  type: 'bar';
  tickwidth: number;
  fill: 'tozeroy';
  marker: { color: string };
};
type ComparisonCharDuration = 0 | 7 | 29;

const ComparisonChartPeriodDurationGranularity: Record<
  ComparisonChartPeriod,
  { granularity: HistoryGranularity; duration: ComparisonCharDuration }
> = {
  [ComparisonChartPeriod.TODAY]: { granularity: HistoryGranularity.hour, duration: 0 },
  [ComparisonChartPeriod.LAST_7_DAYS]: { granularity: HistoryGranularity.day, duration: 7 },
  [ComparisonChartPeriod.LAST_30_DAYS]: { granularity: HistoryGranularity.day, duration: 29 },
};

export enum ChartMetricKeys {
  SOLAR = 'solar',
  GRID = 'grid_import',
  LOAD = 'load',
  STORAGE = 'battery_charging', // battery total power
}

export interface UnitComparisonChartStateData {
  data: CharBarTrace[];
  loader: boolean;
  chartMetrics: ChartMetricKeys[];
  selectedTimePeriod: ComparisonChartPeriod;
}

const EMPTY_UNIT_COMPARISON_CHART_DATA: UnitComparisonChartStateData = {
  data: [],
  loader: true,
  chartMetrics: [],
  selectedTimePeriod: ComparisonChartPeriod.TODAY,
};

@Injectable({
  providedIn: 'root',
})
export class UnitEnergyComparisonStore {
  private _unitComparisonChartStore = new BehaviorSubject<UnitComparisonChartStateData>(
    EMPTY_UNIT_COMPARISON_CHART_DATA,
  );
  unitComparisonChartStore$ = this._unitComparisonChartStore.asObservable();

  private ChartMetricKeyNameColor: Record<ChartMetricKeys, { name: string; color: string }> = {
    [ChartMetricKeys.SOLAR]: {
      name: this._translationsService.instant('Svg.Solar'),
      color: this._themeService.$userTheme.styles.solar[0].value,
    },
    [ChartMetricKeys.GRID]: {
      name: this._translationsService.instant('Svg.Grid'),
      color: this._themeService.$userTheme.styles.consumption[0].value,
    },
    [ChartMetricKeys.LOAD]: {
      name: this._translationsService.instant('SiteEnergy.Load'),
      color: this._themeService.$userTheme.styles.consumed[0].value,
    },
    [ChartMetricKeys.STORAGE]: {
      name: this._translationsService.instant('Svg.Storage'),
      color: this._themeService.$userTheme.styles.battery[0].value,
    },
  };

  // creating this store to store the values of traces so we don't have to call the api
  // only if the metric keys changes as api returns the values for all the metrics at once
  // so only should update the chart
  // no need to call the api..
  private _tracesStore: { [index: string]: CharBarTrace } = {};

  constructor(
    private _api: ApiWrapper,
    private _unitStore: UnitStore,
    private _translationsService: TranslationsService,
    private _themeService: ThemeService,
  ) {
    this.getCompositionBarChartData(
      HistoryGranularity.hour,
      0,
      [ChartMetricKeys.SOLAR, ChartMetricKeys.LOAD],
      ComparisonChartPeriod.TODAY,
    );
  }

  comparisonChartMetricChange(metrics: ChartMetricKeys[]): void {
    const traces = [];
    metrics.forEach((key) => {
      traces.push(this._tracesStore[key]);
    });
    this._unitComparisonChartStore.next({
      ...this._unitComparisonChartStore.getValue(),
      data: traces,
      chartMetrics: metrics,
    });
  }
  comparisonChartPeriodChange(period: ComparisonChartPeriod): void {
    const { chartMetrics } = this._unitComparisonChartStore.getValue();
    const { granularity, duration } = ComparisonChartPeriodDurationGranularity[period];
    this.getCompositionBarChartData(granularity, duration, chartMetrics, period);
  }

  private getCompositionBarChartData(
    granularity: HistoryGranularity,
    duration: ComparisonCharDuration,
    selectedKeys: ChartMetricKeys[],
    selectedTimePeriod: ComparisonChartPeriod,
  ): void {
    this._unitStore.unit$
      .pipe(
        filter((unitData) => unitData.data !== null),
        distinctUntilKeyChanged('data', (prev, curr) => prev.uuid === curr.uuid),
        shareReplay(),
        switchMap((unitData) => this.getUnitSiteEnergyValues(unitData.data, granularity, duration)),
        map((unitHistory) => {
          const { history } = unitHistory.data;
          const { unitTz } = unitHistory;
          const traces = [];
          const timeList = history.t?.map((time) =>
            DateTime.fromISO(time, { zone: unitTz }).setZone(unitTz).toString(),
          );

          for (const key in ChartMetricKeys) {
            if (Object.prototype.hasOwnProperty.call(ChartMetricKeys, key)) {
              const metricKey = ChartMetricKeys[key];
              const values = history[metricKey] ?? [];
              this._tracesStore[metricKey] = {
                x: timeList,
                y: values,
                name: this.ChartMetricKeyNameColor[metricKey].name,
                type: 'bar',
                tickwidth: 0.3,
                fill: 'tozeroy',
                marker: { color: this.ChartMetricKeyNameColor[metricKey].color },
              };
            }
          }

          selectedKeys.forEach((key) => {
            traces.push(this._tracesStore[key]);
          });

          return {
            data: traces,
            loader: false,
            chartMetrics: selectedKeys,
            selectedTimePeriod,
          };
        }),
        catchError((error) => {
          console.error(error);
          return of({ ...EMPTY_UNIT_COMPARISON_CHART_DATA, loader: false });
        }),
        startWith(EMPTY_UNIT_COMPARISON_CHART_DATA),
        tap((compositionChartData) => {
          this._unitComparisonChartStore.next(compositionChartData);
        }),
      )
      .subscribe();
  }

  private getUnitSiteEnergyValues(
    unit: Unit,
    granularity: string,
    duration: ComparisonCharDuration,
  ): Observable<{ data: ComparisonChartDataApi; unitTz: string }> {
    const unitTz = unit.timezone;
    const date = DateTime.local({ zone: unitTz });
    const end = date.valueOf();
    const start = date.minus({ day: duration }).startOf('day').valueOf();

    return (
      this._api.handleObservableRequest({
        useAPI: AvailableAPI.SWITCHDIN,
        url: `/api/v1/units/${unit.id}/history/?period=${granularity}&fromts=${start}&tots=${end}`,
        requestMethod: RequestMethod.GET,
        useHeader: UseHeaderType.AUTHORIZED_SWDIN,
        requestData: {},
      }) as Observable<ComparisonChartDataApi>
    ).pipe(
      map((history) => {
        return { data: history, unitTz };
      }),
    );
  }
}
