import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';

@Component({
  selector: 'app-vpp-policies',
  templateUrl: './vpp-policies.component.html',
  styleUrls: ['./vpp-policies.component.scss'],
})
export class VppPoliciesComponent implements OnChanges {
  @Input() policies;
  @Output() selectedPolicy = new EventEmitter<any>();
  Policy;

  readonly PermissionKey = PermissionKey;

  constructor(private permissionsService: PermissionsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // console.log('selectedPolicy: ', this.selectedPolicy);
    if (this.Policy != null) {
      this.policies.forEach((policy) => {
        if (policy.id == this.Policy.id) this.policySelectionChange(policy);
      });
    }
  }

  policySelectionChange(policy, edit = false) {
    if (edit) {
      if (!this.permissionsService.any([PermissionKey.VPP_CHANGE_POLICYATTRIBUTEOPTION])) {
        return;
      }
    } else {
      if (!this.permissionsService.any([PermissionKey.VPP_VIEW_POLICYATTRIBUTEOPTION])) {
        return;
      }
    }
    policy.edit = edit;
    this.policies.forEach((pol) => {
      if (pol.id == policy.id) {
        if (edit && !pol.toggle) pol.toggle = !pol.toggle;
        else if (!edit) pol.toggle = !pol.toggle;
        if (pol.toggle) {
          this.selectedPolicy.emit(policy);
          this.Policy = policy;
        } else {
          this.selectedPolicy.emit(null);
          this.Policy = null;
        }
      } else {
        pol.toggle = false;
      }
    });
  }
}
