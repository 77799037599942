import { Injectable } from '@angular/core';
import {
  VppControlGroupSchedule,
  VppControlGroupScheduleGetPayload,
  VppControlGroupScheduleMeritPostPayload,
  VppControlGroupSchedulePostPayload,
} from '../../../classes/vpp/vpp-control-group-schedule.model';
import {
  getLowerMarketUuidsFromMarketObjects,
  getRaiseMarketUuidsFromMarketObjects,
} from '../../../classes/vpp/vpp-fcas-markets.model';
import { isVppModeDrm, isVppModeFcas } from '../../../classes/vpp/vpp-demand-type-mode.model';
import { VirtualPowerPlantsService } from '../virtual-power-plants.service';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '../../common/api-wrapper.service';
import { BehaviorSubject } from 'rxjs';
import { CrudType } from '@class/commons/enums';

@Injectable({
  providedIn: 'root',
})
export class VppControlGroupSchedulesService {
  currentVppId: string;
  private readonly controlGroupSchedulesSubject$ = new BehaviorSubject<VppControlGroupSchedule[]>([]);
  public controlGroupSchedules$ = this.controlGroupSchedulesSubject$.asObservable();

  constructor(
    private vppService: VirtualPowerPlantsService,
    private api: ApiWrapper,
  ) {
    this.vppService.vppSelected$.subscribe((vpp) => {
      if (vpp?.id == null) return this.controlGroupSchedulesSubject$.next([]);
      this.getVppControlGroupSchedules(vpp.id);
      this.currentVppId = vpp.id;
    });
  }

  async getVppControlGroupSchedules(vppId: string): Promise<void> {
    try {
      const response: { data: VppControlGroupScheduleGetPayload[] } = await this.getVppControlGroupSchedulesApi(vppId);
      const adaptedControlGroupSchedule = response.data.map((cgs) => this.adaptVppControlGroupScheduleGetApi(cgs));
      this.controlGroupSchedulesSubject$.next(adaptedControlGroupSchedule);
    } catch (error) {
      console.error(error);
    }
  }

  async createOrUpdateVppControlGroupSchedules(
    id: string,
    controlGroupSchedule: VppControlGroupSchedule,
    editType: CrudType,
  ): Promise<void> {
    const adaptedPostPayloads = this.adaptVppControlGroupSchedulesPostApi(controlGroupSchedule);

    const apiPromises = adaptedPostPayloads.map((payload): Promise<unknown> => {
      if (editType === CrudType.CREATE) {
        return this.createVppControlGroupSchedulesApi(payload);
      } else if (editType === CrudType.UPDATE) {
        return this.updateVppControlGroupSchedulesApi(id, payload);
      }
      return null;
    });

    await Promise.all(apiPromises);
    await this.getVppControlGroupSchedules(this.currentVppId);
  }

  async removeVppControlGroupSchedule(controlGroupScheduleId: string): Promise<void> {
    await this.removeVppControlGroupScheduleApi(controlGroupScheduleId);
    await this.getVppControlGroupSchedules(this.currentVppId);
  }
  private adaptVppControlGroupScheduleGetApi(payload: VppControlGroupScheduleGetPayload): VppControlGroupSchedule {
    const {
      auto,
      control_group,
      current,
      id,
      schedule,
      mode,
      merit_order,
      control_value,
      policies,
      markets,
      control_priority,
    } = payload;
    const adaptedPolicies = policies.map((policy) => {
      return { id: policy.policy_id, name: policy.policy_name };
    });

    const controlGroupSchedule: VppControlGroupSchedule = {
      auto,
      controlGroup: control_group,
      current,
      id,
      schedule,
      mode,
      meritOrder: merit_order,
      controlValue: control_value,
      policies: adaptedPolicies,
      markets,
      controlPriority: control_priority,
    };
    return controlGroupSchedule;
  }

  private adaptVppControlGroupSchedulesPostApi(data: VppControlGroupSchedule): VppControlGroupSchedulePostPayload[] {
    // for FCAS, we will send schedules with raise and lower markets separately, hence it will be an array
    const adaptedPayloads: VppControlGroupSchedulePostPayload[] = [];
    const adaptedPayload: VppControlGroupSchedulePostPayload = {
      auto: data.auto,
      control_group: data.controlGroup.id,
      schedule: data.schedule.id,
      mode: data.mode,
      control_priority: data.controlPriority,
    };
    if (isVppModeFcas(data.mode)) {
      const selectedMarkets = data.markets;
      const selectedRaiseMarketUuids = getRaiseMarketUuidsFromMarketObjects(selectedMarkets);
      const selectedLowerMarketUuids = getLowerMarketUuidsFromMarketObjects(selectedMarkets);

      if (selectedRaiseMarketUuids.length <= 0 && selectedLowerMarketUuids.length <= 0) {
        console.error('Fetched markets have no valid market type');
        throw new Error();
      }
      if (selectedRaiseMarketUuids.length > 0) {
        adaptedPayloads.push({ ...adaptedPayload, markets: selectedRaiseMarketUuids });
      }
      if (selectedLowerMarketUuids.length > 0) {
        adaptedPayloads.push({ ...adaptedPayload, markets: selectedLowerMarketUuids });
      }
    } else if (isVppModeDrm(data.mode)) {
      let policies: string[];
      if (data.policies) {
        policies = data.policies.map((policy) => policy.id);
      }

      let meritOrder: VppControlGroupScheduleMeritPostPayload;
      if (data.meritOrder) {
        const { control_code, device_class, order, vpp_id, control_type } = data.meritOrder;
        meritOrder = {
          control_code,
          device_class,
          order,
          vpp_id,
          control_type,
        };
      }
      adaptedPayloads.push({ ...adaptedPayload, merit_order: meritOrder, control_value: data.controlValue, policies });
    }

    return adaptedPayloads;
  }
  public getVppControlGroupSchedulesApi(vppId: string): Promise<any> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/vpps/' + vppId + '/control_group_schedules/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  private createVppControlGroupSchedulesApi(data: VppControlGroupSchedulePostPayload): Promise<unknown> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/control-group-schedules/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }
  private updateVppControlGroupSchedulesApi(controlGroupScheduleId: string, data): Promise<unknown> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/control-group-schedules/' + controlGroupScheduleId + '/',
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }
  private removeVppControlGroupScheduleApi(controlGroupScheduleId: string): Promise<unknown> {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/control-group-schedules/' + controlGroupScheduleId + '/',
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
}
