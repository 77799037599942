import { Component, Input, OnDestroy } from '@angular/core';
import { DropletControllerInventoryRequest } from '@class/units/droplet/controller-inventory-request/droplet-controller-inventory-request.model';
import { DropletControllerInventoryService } from '@class/units/droplet/controller-inventory-request/droplet-controller-inventory-request.service';
import { DropletDisplay } from '@class/units/droplet/droplet-display.model';
import { BrowserLogger } from '@class/core/browser-logger';
import { Subscription } from 'rxjs';
import { Event, EventBusService } from '@service/core/event-bus.service';

@Component({
  selector: 'app-droplet-controller-inventory',
  templateUrl: './droplet-controller-inventory.component.html',
  styleUrls: ['./droplet-controller-inventory.component.scss'],
})
export class DropletControllerInventoryComponent implements OnDestroy {
  // Input: droplet
  private _droplet: DropletDisplay;
  @Input() set droplet(value: DropletDisplay) {
    this._droplet = value;
  }

  private _controllerInventoryReceivedSubscription: Subscription;
  public vm: DropletControllerInventoryRequest = null;

  constructor(
    private _controllerInventoryService: DropletControllerInventoryService,
    private _eventBus: EventBusService,
  ) {
    BrowserLogger.log('DropletInventoryComponent.constructor');
    // subscribe to Controller Inventory Received event (controller CRUD operation checks)
    this._controllerInventoryReceivedSubscription = this._eventBus
      .on(Event.CONTROLLER_INVENTORY_STATE)
      .subscribe((event: DropletControllerInventoryRequest) => {
        this.handleControllerInventoryReceived(event);
      });
  }

  ngOnDestroy() {
    BrowserLogger.log('DropletInventoryComponent.ngOnDestroy');
    this._controllerInventoryReceivedSubscription.unsubscribe();
  }

  private handleControllerInventoryReceived(controllerInventoryRequest: DropletControllerInventoryRequest) {
    BrowserLogger.log('DropletInventoryComponent.handleControllerInventoryReceived', { controllerInventoryRequest });
    if (DropletDisplay.isMyControllerInventoryRequest(controllerInventoryRequest, this._droplet)) {
      BrowserLogger.log('DropletInventoryComponent.handleControllerInventoryReceived.mine', {
        controllerInventoryRequest,
      });
      this.vm = controllerInventoryRequest;
    }
  }

  handleRetryClick(): void {
    BrowserLogger.log('DropletInventoryComponent.handleRetryClick', { droplet: this._droplet });
    this._controllerInventoryService.requestControllerInventory(this._droplet);
  }
}
