import { Observable } from 'rxjs';
import { VppDemandTypes } from './vpp-demand-type-mode.model';
import { VppUnitDeviceTypes } from './vpp-unit.model';

interface VppControlGroupBasicInfo {
  id: string;
  uuid: string;
  name: string;
  description: string;
}

export interface VppControlGroupGetPayload extends VppControlGroupBasicInfo {
  vpp: string;
  num_devices: number;
  num_units: number;
}

export interface VppControlGroup extends VppControlGroupBasicInfo {
  deviceNum: number;
  unitNum: number;
  vppUuid: string;
  vppId: string;
}

export interface VppSettingGroupsTabControlGroup extends VppControlGroupBasicInfo {
  metrics: Record<VppDemandTypes, VppSettingGroupsTabControlGroupMetricObj>;
  deviceCount: number;
  unitCount: number;
  unitsDisplayed: boolean;
}

export type VppSettingGroupsTabControlGroupMetricObj = { name: string; value$: Observable<number> };

export interface VppControlGroupEditForm {
  uuid?: string;
  id: string;
  name: string;
  description: string;
}
export interface VppControlGroupPutPayload extends VppControlGroupEditForm {
  vpp_id: string;
  active: boolean;
}

export type VppControlGroupUnitUpdatePayloadUnitDetail = {
  uuid: string;
  device_classes: VppUnitDeviceTypes[];
}[];
export type VppControlGroupUnitUpdatePayload = {
  add: VppControlGroupUnitUpdatePayloadUnitDetail;
  remove: VppControlGroupUnitUpdatePayloadUnitDetail;
};

export interface VppControlGroupScheduleControlGroup {
  description: string;
  id: string;
  name: string;
  vpp: string;
}

export const adaptVppControlGroupToCGSControlGroup = (
  controlGroup: VppControlGroup,
): VppControlGroupScheduleControlGroup => {
  const { id, description, name, vppId } = controlGroup;
  const formControlGroup: VppControlGroupScheduleControlGroup = { id, description, name, vpp: vppId };
  return formControlGroup;
};
