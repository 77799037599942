<div class="flex-100 pl-20 pr-20" *ngIf="metricData.viewInStreamTab">
  <element-loader></element-loader>
  <ion-grid class="p-0">
    <ion-row>
      <ion-col size="3" class="p-0 text-vertical-align">
        <div>
          <ion-text color="text-primary" class="fs-13">{{ metricData.streamName | translate }}</ion-text>
        </div>
        <div>
          <ion-text color="text-secondary" class="fs-16"
            >{{ metricData.lastValue }} {{ metricData.units_abbr | translate }}</ion-text
          >
        </div>
      </ion-col>
      <ion-col size="9">
        <div #streamGraph></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
