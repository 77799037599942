<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{"MacEntryModal.Title" | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="ion-align-items-center fh-row">
      <form style="width: 100%" (ngSubmit)="macAddr.invalid ? doNothing() : dismiss()">
        <ion-row class="ion-align-self-end">
          <ion-col class="ion-text-center ion-margin-bottom">
            <ion-img class="droplet-img" src="../../../assets/switchdin-droplet.jpg"></ion-img>
            <h1 class="ion-text-start">{{"MacEntryModal.TitleInstructions" | translate}}</h1>
            <p class="ion-margin-bottom ion-text-start" [innerHTML]="'MacEntryModal.Instructions' | translate"></p>
            <swidin-input
              #macAddr="ngModel"
              ngDefaultControl
              regex="^([0-9A-Fa-f]{2}){5}([0-9A-Fa-f]{2})$"
              [(ngModel)]="mac"
              name="mac"
              label="{{'MacEntryModal.SerialNo' | translate}}"
            ></swidin-input>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-self-end">
          <ion-col>
            <ion-button
              [disabled]="macAddr.invalid ? true : null"
              (click)="dismiss()"
              expand="block"
              size="large"
              color="button-primary"
              >{{"General.Confirm" | translate}}</ion-button
            >
          </ion-col>
        </ion-row>
      </form>
    </ion-row>
  </ion-grid>
</ion-content>
