<ion-card class="ion-no-margin new-design-card">
  <ion-list *ngIf="controlGroup$ | async as groups">
    <ion-item lines="none">
      <ion-text>
        <h2 class="fs-22 fw-500">{{ 'VirtualPowerPlant.ControlGroups' | translate }}</h2>
        <p>{{ 'VirtualPowerPlant.SelectControlGroup' | translate }}</p>
      </ion-text>
      <app-create-button
        slot="end"
        *ifHasPermission="[PermissionKey.VPP_ADD_CONTROLGROUP]"
        (onClick)="presentCreateControlGroupModal()"
      ></app-create-button>
    </ion-item>
    <ion-item *ngFor="let group of groups">
      <ion-label
        class="cursor-pointer ion-text-wrap p-10"
        [ngClass]="{ backgroudSelected: group.unitsDisplayed }"
        (click)="toggleControlGroup(group)"
      >
        <div class="fw-500 fs-24">
          <span class="text-dark">{{ group.name }}</span> -
          <span>{{ group.description }}</span>
        </div>
        <p>
          {{ 'VirtualPowerPlant.Units' | translate }}: <strong class="pr-5">{{ group.unitCount }}</strong>
          {{ 'VirtualPowerPlant.Devices' | translate }}: <strong class="pr-5">{{ group.deviceCount }}</strong>
        </p>

        <ion-text color="medium">
          <ion-grid class="p-0">
            <ion-row class="p-0">
              <ion-col class="p-0" size="6" *ngFor="let demandType of group.metrics | keyvalue">
                <div class="fs-13">
                  {{ demandType.value.name | translate }}:
                  <strong class="pr-5">{{ demandType.value.value$ | async | number: '1.2-2' }}</strong>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-text>
      </ion-label>
      <ion-button
        *ifHasPermission="[PermissionKey.VPP_DELETE_CONTROLGROUP]"
        (click)="presentDeleteConfirmAlert(group)"
        color="danger"
        fill="outline"
        slot="end"
      >
        <ion-icon slot="icon-only" name="remove"></ion-icon>
      </ion-button>
      <ion-button
        *ifHasPermission="[PermissionKey.VPP_CHANGE_CONTROLGROUP]"
        class="ml-5"
        color="button-primary"
        fill="outline"
        slot="end"
        (click)="editButtonHandler(group)"
      >
        <ion-icon slot="icon-only" name="create"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
  <div *ngIf="!(controlGroup$ | async)">
    <ion-grid>
      <ion-row *ngFor="let i of [1, 2, 3]">
        <ion-col size="11" class="flex-col">
          <ion-card class="p-20 backgroudSelected">
            <ion-grid>
              <ion-row>
                <ion-col>
                  <ion-skeleton-text animated class="width-25-pct"></ion-skeleton-text>
                </ion-col>
                <ion-col>
                  <ion-skeleton-text animated class="width-25-pct"></ion-skeleton-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-skeleton-text animated class="width-50-pct"></ion-skeleton-text>
                </ion-col>
                <ion-col>
                  <ion-skeleton-text animated class="width-50-pct"></ion-skeleton-text>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col>
                  <ion-skeleton-text animated class="width-50-pct"></ion-skeleton-text>
                </ion-col>
                <ion-col>
                  <ion-skeleton-text animated class="width-25-pct"></ion-skeleton-text>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card>
        </ion-col>
        <ion-col class="flex-col">
          <div class="skeleton-circle"></div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-card>
