<ion-card class="ion-no-padding ion-no-margin new-design-card">
  <ion-card-header class="pb-0">
    <ion-grid class="ion-no-padding ion-no-margin">
      <ion-row>
        <ion-col size="6">
          <ion-card-title class="card-title">{{ 'BatteryComponent.Battery' | translate }}</ion-card-title>
        </ion-col>
        <ion-col size="6">
          <p class="card-small-subheader mt-7">
            {{ 'BatteryComponent.Past7Days' | translate }}
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-header>
  <ion-card-content>
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-align-items-center">
        <ion-col size="8">
          <ion-item lines="none">
            <ion-icon
              class="custom-icon-large mr-0"
              [src]="(unitBatteryStatusStore$ | async).data.batteryIcon"
              slot="start"
            ></ion-icon>
            <p class="text-light-o fs-13">
              {{ 'BatteryComponent.AverageSoC' | translate }}
            </p>
          </ion-item>
        </ion-col>
        <ion-col size="4">
          <p class="fw-600 fs-30 text-dark ion-text-end">
            {{ (unitBatteryStatusStore$ | async).data.avgSoC }}<span class="fs-18">%</span>
          </p>
        </ion-col>
      </ion-row>
      <ion-row class="fullHeight width-100-pct">
        <ion-col size="6" class="fullHeight">
          <div class="mr-5 b-radius-6 fullHeight light-brand-primary-div-background pt-5 pb-5">
            <p class="fw-600 fs-20 text-dark pl-10">
              {{ (unitBatteryStatusStore$ | async).data.totalPowerDelivered }} kWh
            </p>
            <p class="text-light-o fs-13 pl-10">
              {{ 'BatteryComponent.TotalDelivered' | translate }}
            </p>
          </div>
        </ion-col>
        <ion-col size="6" class="fullHeight">
          <div class="ml-5 fullHeight b-radius-6 light-brand-primary-div-background pt-5 pb-5">
            <p class="fw-600 fs-20 text-dark pl-10">
              {{ (unitBatteryStatusStore$ | async).data.totalPowerStored }} kWh
            </p>
            <p class="text-light-o fs-13 pl-10">
              {{ 'BatteryComponent.TotalStored' | translate }}
            </p>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
