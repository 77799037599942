import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { DropletControllerParameter } from '@class/units/droplet/droplet-controller-parameter.model';
import { DropletController } from '@class/units/droplet/droplet-controller.model';
import { DropletDeviceControl } from '@class/units/droplet/droplet-device-control.model';
import { DropletDisplay } from '@class/units/droplet/droplet-display.model';
import { DeviceDetails, DeviceOverview, IEC61850Details } from '@class/units/droplet/device-details.model';
import { AlertController } from '@ionic/angular';
import { TranslationsService } from '@service/common/translations.service';
import { BrowserLogger } from '@class/core/browser-logger';
import { PermissionsService } from '@service/permissions/permissions.service';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { DropletControllerInventoryService } from '@class/units/droplet/controller-inventory-request/droplet-controller-inventory-request.service';
import { DropletControllerInventoryRequest } from '@class/units/droplet/controller-inventory-request/droplet-controller-inventory-request.model';
import { Event, EventBusService } from '@service/core/event-bus.service';
import { DropletControllerControlKey } from '@class/units/droplet/droplet-controller-control-key.model';
import { CustomButtonSize } from '@class/commons/custom-buttons';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { DropletDeviceConnectionAttribute } from '@class/units/droplet/droplet-device-connection-attribute.model';
import { DeviceInfo } from '@class/units/endpoint/endpoint-payload.model';
import { isEmpty } from 'lodash';

type DropletItemDetailViewModel = {
  dropletUuid: string;
  id: string;
  name: string;
  type: string;
  number: number;
  numberLabel: string;
  enableEdit: boolean;
  editLabel: string;
  enableRemove: boolean;
  isEditDisabled: boolean;
  removeLabel: string;
  removePromptHeading: string;
  removePromptMessage: string;
  isRemoveDisabled: boolean;
  enableMqttStatus: boolean;
  mqttStatusKeys: string[];
  enableLogicalDetails: boolean;
  logicalDetails: IEC61850Details;
  enableConnectionAttributes: boolean;
  connectionAttributes: DropletDeviceConnectionAttribute[];
  enableControls: boolean;
  controls: DropletDeviceControl[];
  enableSettings: boolean;
  settings: DropletDeviceControl[];
  enableControllers: boolean;
  controllers: DropletController[];
  enableInformation: boolean;
  information?: DeviceInfo;
  enableParameters: boolean;
  parameters: DropletControllerParameter[];
  enableDevices: boolean;
  devices: DeviceOverview[];
  enableDeviceAttributes: boolean;
  deviceAttributes: {
    name: string;
    value: number | string | boolean;
  }[];
};

@Component({
  selector: 'app-droplet-item-detail',
  templateUrl: './droplet-item-detail.component.html',
  styleUrls: ['./droplet-item-detail.component.scss'],
})
export class DropletItemDetailComponent implements OnChanges, OnDestroy {
  readonly PermissionKey = PermissionKey;

  @Input() droplet: DropletDisplay;
  @Input() device: DeviceDetails = null;
  @Input() controller: DropletController = null;
  @Input() isUnitActive: boolean;
  @Output() onEditClick = new EventEmitter<DeviceDetails | DropletController>();
  @Output() onRemoveClick = new EventEmitter<DeviceDetails | DropletController>();

  vm$ = new BehaviorSubject<DropletItemDetailViewModel>(null);

  isInformationCardExpanded = true;
  isConnectionAttributeCardExpanded = true;
  isControllerCardExpanded = true;
  isLogicalDeviceDetailCardExpanded = true;
  isParametersCardExpanded = true;
  isDevicesCardExpanded = true;
  isDeviceAttributesCardExpanded = true;

  readonly CustomButtonSize = CustomButtonSize;

  readonly destroy$ = new Subject<void>();

  constructor(
    private alertController: AlertController,
    private translations: TranslationsService,
    private permissions: PermissionsService,
    private _controllerInventoryService: DropletControllerInventoryService,
    private _eventBus: EventBusService,
  ) {
    this._eventBus
      .on(Event.CONTROLLER_INVENTORY_STATE)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: DropletControllerInventoryRequest) => {
        this.handleControllerInventoryReceived(event, this.controller, this.droplet);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    BrowserLogger.log('DropletItemDetail.ngOnChanges', { changes });
    if (changes.droplet || changes.device || changes.controller) {
      this.setPageViewModelFromDeviceDetails(this.device, this.droplet);
      this.setPageViewModelFromController(this.controller, this.droplet);
    }
  }

  private handleControllerInventoryReceived(
    controllerInventoryRequest: DropletControllerInventoryRequest,
    controller: DropletController,
    droplet: DropletDisplay,
  ) {
    // if in controller mode, check if mqtt is waiting and if so disable some buttons
    if (controller && DropletDisplay.isMyControllerInventoryRequest(controllerInventoryRequest, droplet)) {
      const enableEdit = this.canEditController(controller, controllerInventoryRequest);
      const enableRemove = this.canRemoveController(controllerInventoryRequest);

      this.vm$.next({
        ...this.vm$.value,
        enableEdit,
        enableRemove,
        isEditDisabled: enableEdit && controllerInventoryRequest.waiting,
        isRemoveDisabled: enableRemove && controllerInventoryRequest.waiting,
      });

      BrowserLogger.log('DropletItemDetail.handleControllerInventoryReceived', {
        controllerInventoryRequest,
        vm: this.vm$.value,
        controller,
        droplet,
      });
    }
  }

  onEditClicked(): void {
    BrowserLogger.log('DropletItemDetail.onEditClicked', { device: this.device, controller: this.controller });
    this.onEditClick.emit(this.device || this.controller);
  }

  onRemoveClicked(): void {
    BrowserLogger.log('DropletItemDetail.onRemoveClicked');
    this.presentRemoveConfirmation();
  }

  async presentRemoveConfirmation(): Promise<void> {
    BrowserLogger.log('DropletItemDetail.presentRemoveConfirmation');
    try {
      const { Confirm, Cancel } = this.translations.instant('General');
      const alert = this.alertController.create({
        header: this.vm$.value.removePromptHeading,
        message: this.vm$.value.removePromptMessage,
        buttons: [
          {
            text: Cancel,
            role: 'cancel',
          },
          {
            text: Confirm,
            handler: () => {
              BrowserLogger.log('DropletItemDetail.presentRemoveConfirmation.confirmed');
              this.onRemoveClick.emit(this.device || this.controller);
            },
          },
        ],
      });
      (await alert).present();
    } catch (err) {
      console.error(err);
    }
  }

  private setPageViewModelFromDeviceDetails(deviceDetails: DeviceDetails, droplet: DropletDisplay): void {
    // map device (DeviceDetails) to DropletItemDetailViewModel
    // (if we have both device and droplet)
    if (deviceDetails && droplet) {
      this.controller = null;
      this.vm$.next({
        ...this.vm$.value,
        dropletUuid: droplet.overview.uuid,
        id: deviceDetails.deviceOverview.id,
        name: deviceDetails.deviceOverview.fullName,
        type: null, // no type for devices
        number: deviceDetails.deviceOverview.number,
        numberLabel: this.translations.instant('General.DeviceNumber'),
        enableEdit: this.canEditDevice(deviceDetails),
        editLabel: this.translations.instant('UnitPage.EditName'),
        isEditDisabled: false,
        enableRemove: this.canRemoveDevice(deviceDetails),
        removeLabel: this.translations.instant('UnitPage.RemoveDevice'),
        removePromptHeading: this.translations.instant('UnitPage.RemoveDevice'),
        removePromptMessage: this.translations.instant('UnitPage.RemoveDeviceMsg'),
        isRemoveDisabled: false,
        enableMqttStatus: true,
        mqttStatusKeys: deviceDetails.deviceOverview.statusKeys,
        enableLogicalDetails: this.canViewLogicalDetails(deviceDetails),
        logicalDetails: deviceDetails.logicalDetails,
        enableConnectionAttributes: this.canViewConnectionAttributes(deviceDetails),
        connectionAttributes: deviceDetails.connectionAttributes,
        enableControls: this.canViewDeviceControls(deviceDetails),
        controls: deviceDetails.controls,
        enableSettings: this.canViewDeviceSettings(deviceDetails),
        settings: deviceDetails.settings,
        enableControllers: this.canViewControllers(deviceDetails),
        controllers: deviceDetails.controllers,
        enableInformation: this.canViewInformation(deviceDetails),
        information: deviceDetails.information,
        enableParameters: false,
        parameters: null,
        enableDevices: false,
        devices: null,
        enableDeviceAttributes: deviceDetails.deviceAttributes?.length > 0,
        deviceAttributes: deviceDetails.deviceAttributes,
      });
      BrowserLogger.log('DropletItemDetail.setPageViewModelFromDeviceDetails', { vm: this.vm$, deviceDetails });
    }
  }

  private setPageViewModelFromController(controller: DropletController, droplet: DropletDisplay): void {
    // map controller (DropletController) to DropletItemDetailModel
    // (if we have both controller and droplet)
    if (controller && droplet) {
      this.device = null;
      const dropletState = this._controllerInventoryService.getDropletState(droplet.overview.uuid);
      this.vm$.next({
        ...this.vm$.value,
        dropletUuid: droplet.overview.uuid,
        id: controller.id,
        name: controller.name,
        type: controller.controllerType,
        number: controller.controllerVersionNumber,
        numberLabel: this.translations.instant('UnitPage.Version'),
        enableEdit: this.canEditController(controller, dropletState), // also set via handleControllerInventoryReceived (if we were opened before inventory received)
        editLabel: this.translations.instant('General.Edit'),
        isEditDisabled: false,
        enableRemove: this.canRemoveController(dropletState), // also set via handleControllerInventoryReceived (if we were opened before inventory received)
        removeLabel: this.translations.instant('UnitPage.RemoveController'),
        removePromptHeading: this.translations.instant('UnitPage.RemoveController'),
        removePromptMessage: this.translations.instant('UnitPage.RemoveControllerMsg'),
        isRemoveDisabled: false,
        enableMqttStatus: false, // not all controllers have controller metrics, so just hide for all rather than only show sometimes which may be confusing
        mqttStatusKeys: null,
        enableLogicalDetails: false,
        logicalDetails: null,
        enableConnectionAttributes: false,
        connectionAttributes: null,
        enableControls: DropletController.canViewControls(controller, this.permissions),
        controls: DropletControllerControlKey.adaptToDropletDeviceControls(controller.controlKeys, this.translations),
        enableSettings: false,
        settings: null,
        enableControllers: false,
        controllers: null,
        enableInformation: false,
        information: undefined,
        enableParameters: true,
        parameters: controller.parameters,
        enableDevices: true,
        devices: controller.devices,
        enableDeviceAttributes: false,
      });

      BrowserLogger.log('DropletItemDetail.setPageViewModelFromController', { vm: this.vm$, controller });
    }
  }

  private canEditDevice(deviceDetails: DeviceDetails): boolean {
    const result = deviceDetails?.deviceOverview?.id && this.permissions.has(PermissionKey.UNIT_CHANGE_DEVICE);
    BrowserLogger.log('DropletItemDetailComponent.canEditDevice', { result, deviceDetails });
    return result;
  }

  private canRemoveDevice(deviceDetails: DeviceDetails): boolean {
    const result = deviceDetails?.deviceOverview?.id && this.permissions.has(PermissionKey.UNIT_DELETE_DEVICE);
    BrowserLogger.log('DropletItemDetailComponent.canRemoveDevice', { result, deviceDetails });
    return result;
  }

  private canViewControllers(deviceDetails: DeviceDetails): boolean {
    const result = deviceDetails?.controllers?.length && this.permissions.has(PermissionKey.UNIT_VIEW_CONTROLLER);
    BrowserLogger.log('DropletItemDetailComponent.canViewControllers', { result, deviceDetails });
    return result;
  }

  private canViewLogicalDetails(deviceDetails: DeviceDetails): boolean {
    const result = deviceDetails?.logicalDetails && this.permissions.has(PermissionKey.UNIT_VIEW_LOGICALDEVICE);
    BrowserLogger.log('DropletItemDetailComponent.canViewLogicalDetails', { result, deviceDetails });
    return result;
  }

  private canViewInformation(deviceDetails: DeviceDetails): boolean {
    const result =
      !isEmpty(deviceDetails.information) && this.permissions.has(PermissionKey.UNIT_VIEW_DEVICEINFORMATION);
    BrowserLogger.log('DropletItemDetailComponent.canViewInformation', { result, deviceDetails });
    return result;
  }

  private canViewConnectionAttributes(deviceDetails: DeviceDetails): boolean {
    const result =
      deviceDetails?.connectionAttributes?.length &&
      this.permissions.has(PermissionKey.UNIT_VIEW_DEVICECONNECTIONATTRIBUTE);
    BrowserLogger.log('DropletItemDetailComponent.canViewConnectionAttributes', { result, deviceDetails });
    return result;
  }

  private canViewDeviceControls(deviceDetails: DeviceDetails): boolean {
    const result = deviceDetails?.controls?.length && this.permissions.has(PermissionKey.UNIT_VIEW_DEVICECONTROL);
    BrowserLogger.log('DropletItemDetailComponent.canViewDeviceControls', { result, deviceDetails });
    return result;
  }

  private canViewDeviceSettings(deviceDetails: DeviceDetails): boolean {
    const result = deviceDetails?.settings?.length && this.permissions.has(PermissionKey.UNIT_VIEW_DEVICESETTINGVALUE);
    BrowserLogger.log('DropletItemDetailComponent.canViewDeviceSettings', { result, deviceDetails });
    return result;
  }

  private canEditController(
    controller: DropletController,
    controllerInventoryRequest: DropletControllerInventoryRequest,
  ): boolean {
    // Can EDIT controller if (1) Have Permission, (2) Controller Exists in Dbase, (3) Controller Inventory Received
    // this is intended to be called from a DropletControllerInventoryService.state handler
    const result =
      controller?.controllerExistInDatabase &&
      controllerInventoryRequest?.received &&
      this.permissions?.has(PermissionKey.UNIT_CHANGE_CONTROLLER);
    BrowserLogger.log('DropletItemDetailComponent.canEditController', { result });
    return result;
  }

  private canRemoveController(controllerInventoryRequest: DropletControllerInventoryRequest): boolean {
    // can REMOVE controller if (1) Have Permission, (2) Controller Inventory Received
    // this is intended to be called from a DropletControllerInventoryService.state handler

    // permission check
    const result = this.permissions.has(PermissionKey.UNIT_DELETE_CONTROLLER) && controllerInventoryRequest?.received;
    BrowserLogger.log('DropletItemDetailComponent.canRemoveController', { result, controllerInventoryRequest });
    return result;
  }
}
