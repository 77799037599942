<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="confirmChanges()">Done</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar [(ngModel)]="filterText"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" *ngIf="devicesAndControllersMetrics">
  <ng-container *ngFor="let deviceOrController of devicesAndControllersMetrics | searchFilter: filterText">
    <ion-list>
      <ion-list-header>
        <ion-label>{{ deviceOrController.fullName }}</ion-label>
      </ion-list-header>
      <ion-item *ngFor="let metric of deviceOrController.metrics; trackBy: trackItems | searchFilter: filterText">
        <ion-checkbox
          [value]="metric.key"
          [checked]="isChecked(metric.key)"
          (ionChange)="checkboxChange($event)"
          color="brand-primary"
          labelPlacement="end"
          justify="start"
          [disabled]="disableCheckbox && !isChecked(metric.key)"
        >
          {{ metric.name }}
        </ion-checkbox>
      </ion-item>
    </ion-list>
  </ng-container>
</ion-content>
<ion-content class="ion-padding" *ngIf="alertMetrics">
  <ion-radio-group [value]="selectedMetric" (ionChange)="checkboxChangeAlertMetric($event)">
    <ion-item *ngFor="let metric of alertMetrics; trackBy: compareWith | searchFilter: filterText">
      <ion-radio [value]="metric.metricKey" labelPlacement="start">{{ metric.name }}</ion-radio>
    </ion-item>
  </ion-radio-group>
</ion-content>
