<!-- controller issue -->
<ion-item
  lines="none"
  class="droplet-notification large mb-10"
  [ngClass]="vm.ngWrapperDivClass"
  *ngIf="vm.hasControllerIssue"
>
  <ion-icon slot="start" [src]="vm.iconSrc"></ion-icon>
  <ion-text>
    <div *ngFor="let text of vm.text">
      {{ text }}
    </div>
    <p class="ion-no-margin" color="text-tertiary" *ngFor="let secondaryText of vm.secondaryText">
      {{ secondaryText }}
    </p>
  </ion-text>
  <ion-button
    *ngIf="vm.enableResyncButton"
    slot="end"
    color="warning"
    class="droplet-notification-button"
    (click)="handleResyncClick()"
  >
    {{ vm.resyncButtonLabel }}
  </ion-button>
  <ion-button
    *ngIf="vm.enableRemoveButton"
    slot="end"
    color="danger"
    class="droplet-notification-button"
    (click)="handleRemoveClick()"
  >
    {{ vm.removeButtonLabel }}
  </ion-button>
</ion-item>
