import { Component, OnInit, Input } from '@angular/core';
import { ThemeService } from '../../../services/themes/theme.service';
import { ModalController } from '@ionic/angular';
import { Alert } from '../../unit-view/unit-alerts/alert/alert.model';
import { AlertTypes } from '../../unit-view/unit-alerts/alert-definitions-list/alert-definition.model';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.page.html',
  styleUrls: ['./alert-modal.page.scss'],
})
export class AlertModalPage implements OnInit {
  @Input() alert: Alert;
  alertType = 'Info';

  constructor(
    public themeService: ThemeService,
    private modalController: ModalController,
  ) {}

  ngOnInit() {
    this.getAlertType();
  }

  getAlertType() {
    AlertTypes.forEach((el) => {
      if (el.value === this.alert.alert_level) {
        this.alertType = el.name;
      }
    });
  }

  dismiss(): void {
    this.modalController.dismiss();
  }
}
