<div
  *ngIf="title && subtitles && subtitles.length > 0"
  class="b-radius-6 pt-12 pb-10 mb-4 width-100-pct"
  [ngClass]="{
    load: DeviceDetailMessageState.LOADING === state,
    success: DeviceDetailMessageState.SUCCESS === state,
    error: DeviceDetailMessageState.ERROR === state
  }"
>
  <div class="title-row">
    <div [ngSwitch]="state" class="center-icon-div">
      <ion-icon
        *ngSwitchCase="DeviceDetailMessageState.LOADING"
        class="ml-15 rotate-center"
        name="hourglass"
      ></ion-icon>
      <ion-icon
        *ngSwitchCase="DeviceDetailMessageState.ERROR"
        class="ml-15 shake-horizontal"
        src="assets/icons/svg/failed-message.svg"
      ></ion-icon>
      <ion-icon
        *ngSwitchCase="DeviceDetailMessageState.SUCCESS"
        class="ml-15 puff-in-center"
        name="checkmark-circle"
        color="brand-primary"
      ></ion-icon>
      <ion-icon *ngSwitchDefault class="ml-15 puff-in-center" name="information-circle-outline"></ion-icon>
    </div>
    <span class="text-dark ml-9">{{ title | translate }}</span>
    <span *ngIf="attempts != null" class="text-medium mr-12 attempt"
      >{{ 'UnitPage.Attempts' | translate }}: {{ attempts }}</span
    >
  </div>
  <div *ngFor="let subtitle of subtitles" class="text-medium ml-40">
    {{ subtitle | translate }}
  </div>
</div>
