<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> {{ program.name }} </ion-title>
  </ion-toolbar>
  <ion-row class="bb-1 width-100-pct">
    <ion-col class="slide-nav-center ion-no-padding width-100-pct">
      <div id="slide-nav">
        <div class="linear-header">
          <ion-button (click)="slideBack()" [ngClass]="{'ion-hide': !currentSlide.showBackButton}" fill="clear">
            <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
          </ion-button>
          <h4>{{currentSlide.heading | translate}}</h4>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-header>

<ion-content>
  <swiper-container #slides [allowTouchMove]="false">
    <swiper-slide>
      <!-- WELCOME -->
      <ion-content>
        <ion-grid class="gridSetting width-100-pct p-10 fullHeight">
          <ion-row class="ion-align-items-center fullHeight m-0 gridRow width-100-pct">
            <ion-row class="ion-text-start ion-align-self-end width-100-pct">
              <ion-col size="12" class="width-100-pct">
                <h1 [ngClass]="{'CORDOVA_h1': IS_PLATFORM_CORDOVA}" class="ion-no-margin">
                  {{ wizardName | translate }}
                </h1>
                <p class="fw-400">{{ wizardDesc | translate}}</p>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-self-end width-100-pct">
              <ion-col class="width-100-pct">
                <ion-button color="button-primary" expand="block" (click)="slideToAgentSlide()" size="large">
                  {{ "CommunityBattery.Start" | translate }}
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-row>
        </ion-grid>
      </ion-content>
    </swiper-slide>
    <swiper-slide>
      <!-- NMI -->
      <ion-content>
        <ion-grid class="gridSetting width-100-pct p-10 fullHeight">
          <ion-row class="ion-align-items-center fullHeight m-0 gridRow width-100-pct">
            <ion-row class="ion-text-start ion-align-self-end width-100-pct">
              <ion-col size="12" class="width-100-pct">
                <ion-row class="ion-margin-bottom width-100-pct">
                  <ion-col size="12" class="width-100-pct">
                    <!-- TODO: need to remove this color="dark" when it supports the dark mode - need to fix this-->
                    <ion-text color="dark"> {{ "CommunityBattery.EnterNMI" | translate }} </ion-text>
                  </ion-col>

                  <ion-col class="ion-no-padding width-100-pct" size="12">
                    <ion-item class="md-like-swidin-input ion-margin">
                      <ion-input
                        type="text"
                        required
                        name="program_nmi"
                        [(ngModel)]="nmi"
                        ngControl="program_nmi"
                        #program_nmi="ngModel"
                        minlength="10"
                        label="{{ 'UnitDetails.NMI' | translate }}"
                        labelPlacement="floating"
                      ></ion-input>
                    </ion-item>
                    <ion-text
                      class="pl-15 fs-13"
                      *ngIf="program_nmi.invalid && (program_nmi.dirty || program_nmi.touched)"
                      color="danger"
                    >
                      {{ "CommunityBattery.NMIError" | translate }}
                    </ion-text>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-self-end width-100-pct">
              <ion-col class="width-100-pct">
                <ion-button color="button-primary" expand="block" (click)="submit()" size="large">
                  {{ "General.Next" | translate }}
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-row>
        </ion-grid>
      </ion-content>
    </swiper-slide>
    <swiper-slide>
      <!-- STATUS -->
      <ion-content>
        <ion-grid class="gridSetting width-100-pct p-10 fullHeight">
          <ion-row class="ion-align-items-center fullHeight m-0 gridRow width-100-pct">
            <ion-row class="ion-text-start ion-align-self-end width-100-pct">
              <ion-col size="12">
                <ion-row class="ion-margin-bottom width-100-pct">
                  <ion-col class="ion-no-padding width-100-pct" size="12">
                    <ion-item lines="none" class="ion-text-center">
                      <!-- TODO: need to remove this color="dark" when it supports the dark mode - need to fix this-->
                      <ion-label color="dark" class="ion-text-wrap fs-18">
                        {{ "FlexibleExports.ConnectingAndRegistering" | translate }}
                      </ion-label>
                    </ion-item>
                  </ion-col>
                  <ion-col class="ion-no-padding width-100-pct" size="12">
                    <ng-container *ngFor="let item of flexibleExportConnectionStatuses">
                      <ion-item [lines]="item.status === SapnStatus.OFFLINE ? 'none' : 'full'">
                        <!-- TODO: need to remove this color="dark" when it supports the dark mode - need to fix this-->
                        <ion-label color="dark" slot="start">
                          <p class="fs-16">{{item.name}}</p>
                          <p class="text-light-o">{{item.shortDesc}}</p>
                        </ion-label>
                        <ion-icon
                          name="close-circle"
                          slot="end"
                          color="danger"
                          *ngIf="item.status === SapnStatus.OFFLINE"
                        ></ion-icon>
                        <ion-spinner
                          name="dots"
                          slot="end"
                          color="brand-primary"
                          *ngIf="item.status === SapnStatus.CHECKING"
                        ></ion-spinner>
                        <ion-icon
                          name="checkmark-circle"
                          slot="end"
                          color="brand-primary"
                          *ngIf="item.status === SapnStatus.ONLINE"
                        ></ion-icon>
                      </ion-item>
                      <ion-item
                        lines="full"
                        class="ion-text-center width-100-pct"
                        *ngIf="item.status === SapnStatus.OFFLINE"
                      >
                        <ion-text color="danger" slot="start" class="ion-text-wrap fs-12 width-100-pct">
                          {{item.error}}
                        </ion-text>
                      </ion-item>
                    </ng-container>
                  </ion-col>
                  <ion-col class="ion-no-padding width-100-pct mt-20" size="12" *ngIf="timedOut">
                    <ion-item lines="none" class="ion-text-center">
                      <ion-text color="danger" slot="start" class="ion-text-wrap fs-16">
                        {{ "FlexibleExports.StatusError" | translate }}
                      </ion-text>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-self-end width-100-pct">
              <ion-col class="width-100-pct pt-0 pb-0" *ngIf="timedOut" size="12">
                <ion-button
                  color="button-primary"
                  expand="block"
                  fill="outline"
                  (click)="startTheRegistrationAndConnectedTimeout()"
                  size="large"
                >
                  {{ "Droplet.Setup.TryAgain" | translate }}
                </ion-button>
              </ion-col>
              <ion-col class="width-100-pct pt-0" size="12">
                <ion-button color="button-primary" expand="block" (click)="dismiss()" size="large">
                  {{ "General.Done" | translate }}
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-row>
        </ion-grid>
      </ion-content>
    </swiper-slide>
  </swiper-container>
</ion-content>
