import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { map } from 'rxjs/operators';
import { VirtualPowerPlantsService } from '../virtual-power-plants.service';
import { timezoneNames } from './timezone-names';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class VppTimezoneService {
  public timezoneOptions = moment?.tz?.names() ?? timezoneNames;
  public timezone$ = this.vppService.vppSelected$.pipe(
    map((vpp) => vpp?.['timezone'] ?? Intl.DateTimeFormat().resolvedOptions().timeZone),
  );
  public timezoneOffsetNameShort$ = this.timezone$.pipe(map((tz) => DateTime.local().setZone(tz).offsetNameShort));
  constructor(private vppService: VirtualPowerPlantsService) {}
}
