import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

export function matchValues(string1: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const matched = string1 !== control.value;
    return matched ? { passwordNotMatch: { value: control.value } } : null;
  };
}

@Directive({
  selector: '[appMustMatch]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MustMatchDirective, multi: true }],
})
export class MustMatchDirective implements Validator {
  @Input() pass: string;
  validate(control: AbstractControl): { [key: string]: any } | null {
    return this.pass ? matchValues(this.pass)(control) : null;
  }
}
