import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

export function valueExistInString(stringToBeChecked: string[], stringToBeCheckedFrom: string[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    // there will be at least one value present there either toBeChecked or toBeCheckedFrom
    const toBeChecked = stringToBeChecked ?? [control.value];
    const toBeCheckedFrom = stringToBeCheckedFrom ?? [control.value];
    let stringExist = false;
    toBeCheckedFrom.every((str) => {
      if (toBeChecked.filter((val) => str.toLowerCase().includes(val.toLowerCase())).length > 0) {
        stringExist = true;
        return false;
      }
      return true;
    });
    return stringExist ? { stringHaveTheValue: { value: control.value } } : null;
  };
}

//
// Basically created this validator to check does the user personal detail
// like name exist in the password or not
// but i think this can be use globally, don't know it is going to use somewhere else
// but...
// stringToBeChecked is the value that we wanted to check in the control.value(STRING)
//

@Directive({
  selector: '[appCheckStringsExist]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CheckSubStringExistDirective, multi: true }],
})
export class CheckSubStringExistDirective implements Validator {
  @Input() stringsToBeChecked: string[];
  @Input() stringsToBeCheckedFrom: string[];

  validate(control: AbstractControl): { [key: string]: any } | null {
    return control.value && (this.stringsToBeChecked || this.stringsToBeCheckedFrom)
      ? valueExistInString(this.stringsToBeChecked, this.stringsToBeCheckedFrom)(control)
      : null;
  }
}
