import { Injectable } from '@angular/core';
import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, merge, of } from 'rxjs';
import { delay, filter, map, switchMap, take } from 'rxjs/operators';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../../classes/commons/request-api.model';
import {
  isVppDemandTypeDrm,
  isVppDemandTypeFcas,
  VppDemandTypes,
} from '../../../classes/vpp/vpp-demand-type-mode.model';
import {
  adaptVppDispatchHistGetPayloadToVppDispatchHistDisplay,
  adaptVppDispatchModalDataToPostPayload,
  VppDispatchAction,
  VppDispatchHistoryDisplay,
  VppDispatchHistoryGetPayload,
  VppDispatchHistoryPostPayload,
  VppDispatchModalData,
} from '../../../classes/vpp/vpp-dispatch.model';
import {
  getLowerMarketUuidsFromMarketObjects,
  getRaiseMarketUuidsFromMarketObjects,
} from '../../../classes/vpp/vpp-fcas-markets.model';
import { VppUserActionMqtt } from '../../../classes/vpp/vpp-mqtt.model';
import { ApiWrapper } from '../../common/api-wrapper.service';
import { VppControlGroupSchedulesService } from '../control-group-schedules/vpp-control-group-schedules.service';
import { VppRollOverService } from '../rollover/vpp-roll-over.service';
import { VirtualPowerPlantsService } from '../virtual-power-plants.service';

@Injectable({
  providedIn: 'root',
})
export class VppDispatchService {
  private selectedVppUuid: string; //TEMP?
  private dispatchFutureSubject: BehaviorSubject<VppDispatchHistoryDisplay[]> = new BehaviorSubject([]);
  private dispatchPastSubject: BehaviorSubject<VppDispatchHistoryDisplay[]> = new BehaviorSubject([]);

  public futureDispatches: VppDispatchHistoryDisplay[];
  public pastDispatches: VppDispatchHistoryDisplay[];
  public dispatchFuture$ = this.dispatchFutureSubject.asObservable();
  public dispatchPast$ = this.dispatchPastSubject.asObservable();

  constructor(
    private api: ApiWrapper,
    private vppService: VirtualPowerPlantsService,
    private vppRolloverService: VppRollOverService,
    private vppControlGroupScheduleService: VppControlGroupSchedulesService,
    private _ngxMqttWrapper: NgxMqttWrapperService,
  ) {
    combineLatest([
      this.vppService.vppSelected$,
      merge(
        //hack to allow the value of vppSelected$ to flow thru before rollOver$ or controlGroupSchedules$ is emitted
        of(1),
        //update dispatches when rollover occurs
        this.vppRolloverService.rollOver$,
        //when cgs is created/modified, backend need max 1 min to convert cgs to dispatch
        this.vppControlGroupScheduleService.controlGroupSchedules$.pipe(delay(60000)),
      ),
    ]).subscribe(([vpp]) => {
      this.selectedVppUuid = vpp?.uuid; //TEMP?
      this.getDispatchHistoryByStartTime();
      this.getVppDispatchHistoryPast(
        DateTime.local().minus({ hour: 24 }).toSeconds(),
        DateTime.local().toSeconds(),
        vpp?.uuid,
      );
    });
    this.vppService.vppSelected$
      .pipe(
        filter((vpp) => vpp?.id != null),
        switchMap((vpp) => {
          return this._ngxMqttWrapper.observe('vpp_user_action/' + vpp.id);
        }),
        map((message): VppUserActionMqtt => {
          return JSON.parse(message.payload.toString());
        }),
        filter((message) => message.type && message.type.includes('state_change_command')),
      )
      .subscribe(() => {
        this.getDispatchHistoryByStartTime();
      });
  }

  async sendUnscheduledDispatches(dispatch: VppDispatchModalData): Promise<void> {
    const { uuid } = await this.vppService.vppSelected$.pipe(take(1)).toPromise();
    const postPayload = adaptVppDispatchModalDataToPostPayload({
      ...dispatch,
      vpp: uuid,
      action: VppDispatchAction.ENABLE,
    });
    const dispatchPromises: Promise<{ data: VppDispatchHistoryGetPayload[] }>[] = [];
    if (isVppDemandTypeDrm(postPayload.demand_type)) {
      dispatchPromises.push(this.manageDispatches(postPayload));
    } else if (isVppDemandTypeFcas(postPayload.demand_type)) {
      const selectedRaiseMarketUuids = getRaiseMarketUuidsFromMarketObjects(dispatch.markets);
      const selectedLowerMarketUuids = getLowerMarketUuidsFromMarketObjects(dispatch.markets);
      if (selectedRaiseMarketUuids.length <= 0 && selectedLowerMarketUuids.length <= 0) {
        console.error('Fetched markets have no valid market type');
        throw new Error();
      }

      if (selectedRaiseMarketUuids.length > 0) {
        dispatchPromises.push(
          this.manageDispatches({
            ...postPayload,
            demand_type: VppDemandTypes.FCAS_RAISE,
            markets: selectedRaiseMarketUuids,
          }),
        );
      }
      if (selectedLowerMarketUuids.length > 0) {
        dispatchPromises.push(
          this.manageDispatches({
            ...postPayload,
            demand_type: VppDemandTypes.FCAS_LOWER,
            markets: selectedLowerMarketUuids,
          }),
        );
      }
    }

    await Promise.all(dispatchPromises);
    await this.getDispatchHistoryByStartTime();
  }

  async cancelDispatch(dispatch: VppDispatchModalData): Promise<void> {
    if (dispatch.action === VppDispatchAction.DISABLE) {
      throw new Error('Attempting to cancel a cancelled dispatch');
    }
    const { uuid } = await this.vppService.vppSelected$.pipe(take(1)).toPromise();
    const postPayload = adaptVppDispatchModalDataToPostPayload({
      ...dispatch,
      vpp: uuid,
      action: VppDispatchAction.DISABLE,
    });
    await this.manageDispatches(postPayload);
    await this.getDispatchHistoryByStartTime();
  }

  async getDispatchHistoryByStartTime(): Promise<void> {
    // we need to change the time now to exact 5 min of interval e.g; if the time_now is 11:42, i need to pass 11:45
    // for that
    // change the strategy to send the time now as we are capable of dispatching NOW
    const timeNow = Math.ceil(new Date().getTime() / 1000);
    this.futureDispatches = [];
    try {
      if (this.selectedVppUuid == null) {
        console.warn('Warning, no selected vpp uuid. cannot call dispatch history api');
        return;
      }

      const response = await this.vppDispatchHistoryByStartTime(this.selectedVppUuid, timeNow);

      if (response.data == null || response.data.length <= 0) {
        console.warn('no dispatch history received');
        return;
      }
      const dispatcheGetPayload = response.data;

      this.futureDispatches = dispatcheGetPayload.map((dispatch) =>
        adaptVppDispatchHistGetPayloadToVppDispatchHistDisplay(dispatch),
      );
    } catch (err) {
      console.error(err);
    }
    this.dispatchFutureSubject.next(this.futureDispatches);
  }

  async getVppDispatchHistoryPast(start: number, end: number, vppUuid: string): Promise<void> {
    this.pastDispatches = [];
    if (vppUuid == null) {
      console.warn('Warning, no selected vpp uuid. cannot call dispatch history api');
      return;
    }
    try {
      const pastDispatchesPayload = await this.vppDispatchHistoryPastApi(vppUuid, start, end);
      this.pastDispatches = pastDispatchesPayload.data.map((dispatch) =>
        adaptVppDispatchHistGetPayloadToVppDispatchHistDisplay(dispatch),
      );
      this.dispatchPastSubject.next(this.pastDispatches);
    } catch (err) {
      console.error(err);
    }
  }

  private manageDispatches(data: VppDispatchHistoryPostPayload) {
    return this.api.handleRequest<VppDispatchHistoryGetPayload[]>(
      AvailableAPI.SWITCHDIN,
      '/api/v1/dispatch-history/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  private vppDispatchHistoryByStartTime(vppUuid: string, startTime: number) {
    return this.api.handleRequest<VppDispatchHistoryGetPayload[]>(
      AvailableAPI.SWITCHDIN,
      `/api/v1/vpps/${vppUuid}/dispatch-history/?start_time=${startTime}`,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  private vppDispatchHistoryPastApi(vppUuid: string, start: number, end: number) {
    // make sure start <= end
    if (start > end) {
      const temp = start;
      start = end;
      end = temp;
    }

    return this.api.handleRequest<VppDispatchHistoryGetPayload[]>(
      AvailableAPI.SWITCHDIN,
      `/api/v1/vpps/${vppUuid}/dispatch-history/?analytics=1&end_time=${end}&start_time=${start}`,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
}
