import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Portfolio } from '@class/commons/portfolio.model';

@Component({
  selector: 'app-portfolio-card',
  templateUrl: './portfolio-card.component.html',
  styleUrls: ['./portfolio-card.component.scss'],
})
export class PortfolioCardComponent {
  @Input() data: Portfolio;
  @Output() onClick = new EventEmitter<Portfolio>();

  constructor() {}

  NameClick(): void {
    this.onClick.emit(this.data);
  }
}
