import { Component } from '@angular/core';

@Component({
  selector: 'app-site-energy',
  templateUrl: './site-energy.component.html',
  styleUrls: ['./site-energy.component.scss'],
})
export class SiteEnergyComponent {
  constructor() {}
}
