import { UnitDetails } from '../../pages/unit-view/settings/unit-details-form.model';

export class StoredAddress {
  public address: string;
  public city: string;
  public state: string;
  public country: string;
  public postcode: string;
  public latitude: number;
  public longitude: number;

  constructor(address = '', city = '', state = '', country = '', postcode = '', latitude = 0, longitude = 0) {
    this.address = address;
    this.city = city;
    this.state = state;
    this.country = country;
    this.postcode = postcode;
    this.latitude = latitude;
    this.longitude = longitude;
  }

  static addressFromUnitDetails(unitDetails: UnitDetails): StoredAddress {
    return new StoredAddress(
      unitDetails.address,
      unitDetails.city,
      unitDetails.state,
      unitDetails.country,
      unitDetails.postcode,
      unitDetails.latitude,
      unitDetails.longitude,
    );
  }

  static equal(addr1: StoredAddress, addr2: StoredAddress) {
    return (
      addr1.address === addr2.address &&
      addr1.city === addr2.city &&
      addr1.state === addr2.state &&
      addr1.country === addr2.country &&
      addr1.postcode === addr2.postcode &&
      addr1.latitude === addr2.latitude &&
      addr1.longitude === addr2.longitude
    );
  }
}
