import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { DropletItemMode } from '@class/commons/constants';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { ModalController } from '@ionic/angular';
import { BrowserLogger } from '@class/core/browser-logger';
import { PermissionsService } from '@service/permissions/permissions.service';
import { UnitsService } from '@service/units/units.service';
import { Observable, Subscription } from 'rxjs';
import { DeviceDetailModalPage } from '../../pages/modals/device-detail-modal/device-detail-modal.page';
import { CommonService } from '../../services/common/common.service';
import {
  DropletService,
  DeviceState,
  DropletAddItemEvent,
  DropletItemSelectedEvent,
} from '../../services/units/endpoints/devices.service';
import { AddNewControllerComponent } from '../add-new-controller/add-new-controller.component';
import { GenericAlertsToastsService } from '@service/common/generic-alerts-toasts.service';
import { ControllerDetailModalPage } from 'app/pages/modals/controller-detail-modal/controller-detail-modal.page';
import { DropletDisplay } from '@class/units/droplet/droplet-display.model';
import { EmitEvent, Event, EventBusService } from '@service/core/event-bus.service';
import { TranslationsService } from '@service/common/translations.service';
import { DropletController, DropletControllerAddUpdateEvent } from '@class/units/droplet/droplet-controller.model';
import { ApiWrapper } from '@service/common/api-wrapper.service';
import { EndpointControllerAddDTO } from '@class/units/endpoint/endpoint-payload.model';
import { DropletControllerInventoryService } from '@class/units/droplet/controller-inventory-request/droplet-controller-inventory-request.service';
import { AddDeviceModalPage } from 'app/pages/modals/add-device-configuration/add-device-modal/add-device-modal.page';

type DropletListViewModel = {
  hasSelectedDevice: boolean;
  hasSelectedController: boolean;
};

@Component({
  selector: 'app-droplet-list',
  templateUrl: './droplet-list.component.html',
  styleUrls: ['./droplet-list.component.scss'],
})
export class DropletListComponent implements OnDestroy, AfterViewInit {
  @Input() mode: DropletItemMode;
  @Input() isUnitActive: boolean;

  vm: DropletListViewModel = {
    hasSelectedDevice: false,
    hasSelectedController: false,
  };

  vm$: Observable<DeviceState> = this._dropletService.vm$;

  private _dropletStateSubscription: Subscription;
  private _dropletAddItemSubscription: Subscription;
  private _dropletItemSelectedSubscription: Subscription;
  // private _platformResizeSubscription: Subscription;
  private _addControllerSubscription: Subscription;
  private _controllerDisplayedSubscription: Subscription;
  private _controllerRemovedSubscription: Subscription;
  private _deviceDisplayedSubscription: Subscription;
  private _deviceRemovedSubscription: Subscription;
  private _dropletsLoadedSubscription: Subscription;
  private _modalRefControllerDetails: HTMLIonModalElement = null; // track modal in case we resize screen and need to close it
  private _modalRefDeviceDetails: HTMLIonModalElement = null; // track modal in case we resize screen and need to close it
  private _addControllerModal: HTMLIonModalElement;

  readonly DropletItemMode = DropletItemMode;

  private _controllerInventoryInitialised = false;

  constructor(
    private _dropletService: DropletService,
    public commonService: CommonService,
    private _api: ApiWrapper,
    private _unitsService: UnitsService,
    private _permissionsService: PermissionsService,
    private _modalController: ModalController,
    private _dropletControllerInventoryService: DropletControllerInventoryService,
    private _controllerInventoryService: DropletControllerInventoryService,
    private _alertsToastsService: GenericAlertsToastsService,
    private _translationsService: TranslationsService,
    private _eventBus: EventBusService,
  ) {
    BrowserLogger.log('DropletList.constructor');
    // subscribe to screen resize (RHS content switch to modal)
    //
    // Commented this one out as it was causing issues
    // it was triggering whenever the platform gets resizes and that is fine
    // but it was creating modal again and again even if there is a modal there
    //
    // this._platformResizeSubscription = this.commonService.PLATFORM_RESIZED_OCCURRED.subscribe((value: boolean) => {
    //   if (value) {
    //     this.handlePlatformResize();
    //   }
    // });
    // subscribe to Add Controller event
    this._addControllerSubscription = this._eventBus
      .on(Event.CONTROLLER_ADD)
      .subscribe((event: DropletControllerAddUpdateEvent) => {
        this.handleAddControllerEvent(event);
      });
    // subscribe to Droplets Loaded event
    this._dropletsLoadedSubscription = this._eventBus
      .on(Event.DROPLETS_LOADED)
      .subscribe((droplets: DropletDisplay[]) => {
        this.handleDropletsLoadedEvent(droplets);
      });

    // subscribe to Device Removed event
    this._deviceRemovedSubscription = this._eventBus
      .on(Event.DEVICE_REMOVED)
      .subscribe((droplets: DropletDisplay[]) => {
        this.handleDeviceRemovedEvent(droplets);
      });
    // subscribe to Device Displayed event
    this._deviceDisplayedSubscription = this._eventBus
      .on(Event.DISPLAYED_DEVICE)
      .subscribe((event: DropletItemSelectedEvent) => {
        this.handleDeviceDisplayedEvent(event);
      });
    // subscribe to Controller Displayed event
    this._controllerDisplayedSubscription = this._eventBus
      .on(Event.DISPLAYED_CONTROLLER)
      .subscribe((event: DropletItemSelectedEvent) => {
        this.handleControllerDisplayedEvent(event);
      });
  }

  ngAfterViewInit(): void {
    BrowserLogger.log('DropletList.ngAfterViewInit', { mode: this.mode });
    // subscribe to droplet items state (needs to be after init as we need to access [mode])
    this._dropletStateSubscription = this._dropletService.vm$.subscribe((deviceState: DeviceState) => {
      this.handleDeviceStateChange(deviceState);
    });
  }

  ngOnDestroy(): void {
    BrowserLogger.log('DropletList.ngOnDestroy');
    this._dropletService.clearDisplayedDeviceAndController();
    this._dropletControllerInventoryService.closeRequests();
    this._dropletStateSubscription?.unsubscribe();
    this._dropletAddItemSubscription?.unsubscribe();
    this._dropletItemSelectedSubscription?.unsubscribe();
    // this._platformResizeSubscription?.unsubscribe();
    this._addControllerSubscription?.unsubscribe();
    this._controllerRemovedSubscription?.unsubscribe();
    this._controllerDisplayedSubscription?.unsubscribe();
    this._deviceDisplayedSubscription?.unsubscribe();
    this._deviceRemovedSubscription?.unsubscribe();
    this._dropletsLoadedSubscription?.unsubscribe();
  }

  private handleDeviceStateChange(deviceState: DeviceState): void {
    BrowserLogger.log('DropletList.handleDeviceStateChange', { deviceState });
    if (deviceState.data?.length > 0 && !deviceState.error && !deviceState.loading) {
      this.initControllerInventoryRequestIfNotInitialised(deviceState?.data);
    }
  }

  private handlePlatformResize() {
    BrowserLogger.log('DropletList.handlePlatformResize');
    this.checkPlatformResizeDetailsModal();
  }

  private initControllerInventoryRequests(droplets: DropletDisplay[]): void {
    // kick off all inventory requests for the unit (which will re-apply to the new droplets within the DeviceState droplets)
    if (this.mode === DropletItemMode.CONTROLLER && droplets?.length) {
      BrowserLogger.log('DropletList.initControllerInventoryRequests', { droplets });
      // close any prev requests
      this._dropletControllerInventoryService.closeRequests();
      // get controller inventory droplets
      const controllerInventoryDroplets = DropletDisplay.filterControllerInventoryDroplets(droplets);
      // init controller inventory requests
      this._dropletControllerInventoryService.requestControllerInventoryForDroplets(controllerInventoryDroplets);
    }
  }

  private initControllerInventoryRequestIfNotInitialised(droplets: DropletDisplay[]): void {
    if (this.mode === DropletItemMode.CONTROLLER && droplets?.length) {
      BrowserLogger.log('DropletList.initControllerInventoryRequestIfNotInitialised', {
        initialised: this._controllerInventoryInitialised,
        droplets,
      });
      // if not initialised, then load inventory for the droplets
      if (!this._controllerInventoryInitialised) {
        this._controllerInventoryInitialised = true;
        this.initControllerInventoryRequests(droplets);
      }
    }
  }

  private checkPlatformResizeDetailsModal() {
    // display/hide the details modal as required on page resizes
    // i.e if no RHS area then we need to show in a modal (and vice-versa)
    BrowserLogger.log('DropletList.checkSelectedControllerModal', { mode: this.mode, vm: this.vm });

    // if selected device then display/hide the modal
    if (this.mode === DropletItemMode.DEVICE && this.vm.hasSelectedDevice) {
      if (this.commonService.OPEN_DEVICE_CONTROLLER_IN_MODAL) {
        this.openSelectedDeviceInModal();
      } else {
        this.closeSelectedDeviceModal();
      }
    }
    // if selected controller then display/hide the modal
    else if (this.mode === DropletItemMode.CONTROLLER && this.vm.hasSelectedController) {
      if (this.commonService.OPEN_DEVICE_CONTROLLER_IN_MODAL) {
        this.openSelectedControllerInModal();
      } else {
        this.closeSelectedControllerModal();
      }
    }
  }

  handleAddDeviceClick(event: DropletAddItemEvent): void {
    BrowserLogger.log('DropletList.handleAddDeviceClick', { event });
    this.presentAddDeviceModal(event.droplet.overview.uuid);
  }

  handleAddControllerClick(event: DropletAddItemEvent): void {
    BrowserLogger.log('DropletList.handleAddControllerClick', { event });
    this.presentAddControllerModal(event.droplet);
  }

  handleDeviceSelected(event: DropletItemSelectedEvent): void {
    BrowserLogger.log('DropletList.handleDeviceSelected', { event, mode: this.mode });
    if (!this._permissionsService.has(PermissionKey.UNIT_VIEW_DEVICE_DETAIL)) {
      return;
    }
    this.handleDropletItemSelected(event);
  }

  handleControllerSelected(event: DropletItemSelectedEvent): void {
    BrowserLogger.log('DropletList.handleControllerSelected', { event, mode: this.mode });
    this.handleDropletItemSelected(event);
  }

  private handleDropletItemSelected(event: DropletItemSelectedEvent): void {
    BrowserLogger.log('DropletList.handleDropletItemSelected', { event, mode: this.mode });

    if (this.mode === DropletItemMode.DEVICE) {
      this._dropletService.updateDisplayedDevice(event.endpointIndex, event.itemIndex);
    } else if (this.mode === DropletItemMode.CONTROLLER) {
      this._dropletService.updateDisplayedController(event.endpointIndex, event.controllerIndex);
    }

    // controller will be handled by controller-detail

    // Check if need to open Device Item in modal (if in modal mode and not one already open)
    if (this.commonService.OPEN_DEVICE_CONTROLLER_IN_MODAL) {
      if (this.mode === DropletItemMode.DEVICE && event.itemIndex !== undefined) {
        this.openSelectedDeviceInModal();
      } else if (this.mode === DropletItemMode.CONTROLLER && event.controllerIndex !== undefined) {
        this.openSelectedControllerInModal();
      }
    }
  }

  private async openSelectedControllerInModal(): Promise<void> {
    BrowserLogger.log('DropletList.openSelectedControllerInModal', { modalRef: this._modalRefControllerDetails });
    this._alertsToastsService
      .presentModalContainerComponent(ControllerDetailModalPage, { isUnitActive: this.isUnitActive })
      .then((modal) => {
        this._modalRefControllerDetails = modal; // track the modal ref so we can close on resizing
        BrowserLogger.log('DropletList.openSelectedControllerInModal.opened', { modal });
      });
  }

  private closeSelectedControllerModal(): void {
    BrowserLogger.log('DropletList.closeSelectedControllerModal', { modalRef: this._modalRefControllerDetails });
    if (this._modalRefControllerDetails) {
      this._modalRefControllerDetails.dismiss();
      this._modalRefControllerDetails = null;
    }
  }

  async openSelectedDeviceInModal(): Promise<void> {
    BrowserLogger.log('DropletList.openSelectedDeviceInModal', { modalRef: this._modalRefDeviceDetails });
    try {
      this._modalRefDeviceDetails = await this._modalController.create({
        component: DeviceDetailModalPage,
        componentProps: {
          isUnitActive: this.isUnitActive,
        },
      }); // track the modal ref so we can close on resizing
      await this._modalRefDeviceDetails.present();
      await this._modalRefDeviceDetails.onDidDismiss();
    } catch (err) {
      console.error(err);
    }
  }

  private closeSelectedDeviceModal(): void {
    BrowserLogger.log('DropletList.closeSelectedDeviceModal', { modalRef: this._modalRefDeviceDetails });
    if (this._modalRefDeviceDetails) {
      this._modalRefDeviceDetails.dismiss();
      this._modalRefDeviceDetails = null;
    }
  }

  private async presentAddDeviceModal(dropletUuid: string): Promise<void> {
    BrowserLogger.log('DropletList.presentAddDeviceModal', { dropletUuid });
    try {
      const modal = await this._modalController.create({
        component: AddDeviceModalPage,
        componentProps: {
          preSelectedEndpointMAC: dropletUuid,
        },
      });
      modal.backdropDismiss = false;

      await modal.present();
    } catch (err) {
      console.error(err);
    }
  }

  private async presentAddControllerModal(droplet: DropletDisplay): Promise<void> {
    BrowserLogger.log('DropletList.presentAddControllerModal', { droplet });
    this._addControllerModal = await this._alertsToastsService.presentModalContainerComponent(
      AddNewControllerComponent,
      {
        controllerTypeList: this._unitsService.unitSiteControllers.controllers,
        endpoint: droplet,
      },
    );
    // NOTE: the modal Create is handled by the event bus handler handleAddControllerEvent()
  }

  private handleAddControllerEvent(event: DropletControllerAddUpdateEvent): void {
    // controller add is not applicable for device mode
    if (this.mode === DropletItemMode.DEVICE) {
      return;
    }

    BrowserLogger.log('DropletList.handleAddControllerEvent', { event });
    this.saveNewController(event.payload as EndpointControllerAddDTO, event.droplet);
  }

  private handleDropletsLoadedEvent(droplets: DropletDisplay[]): void {
    // when droplets are loaded, we need to reset the inventory list
    BrowserLogger.log('DropletList.handleDropletsLoadedEvent', { droplets });
    this.initControllerInventoryRequests(droplets);
  }

  private handleDeviceRemovedEvent(droplets: DropletDisplay[]): void {
    // when a device is removed, we need to reset the inventory list (droplets are not reloaded so need to handle separately)
    BrowserLogger.log('DropletList.handleDeviceRemovedEvent', { droplets });
    this.initControllerInventoryRequests(droplets);
  }

  private handleDeviceDisplayedEvent(event: DropletItemSelectedEvent): void {
    // not applicable for controller mode
    if (this.mode === DropletItemMode.CONTROLLER) {
      return;
    }
    BrowserLogger.log('DropletList.handleDeviceDisplayedEvent', { event });
    this.vm.hasSelectedDevice = event?.itemIndex >= 0;
    if (!this.vm.hasSelectedDevice) {
      this.closeSelectedDeviceModal();
    }
  }

  private handleControllerDisplayedEvent(event: DropletItemSelectedEvent): void {
    // not applicable for device mode
    if (this.mode === DropletItemMode.DEVICE) {
      return;
    }
    BrowserLogger.log('DropletList.handleControllerDisplayedEvent', { event });
    this.vm.hasSelectedController = event?.controllerIndex >= 0;
    if (!this.vm.hasSelectedController) {
      this.closeSelectedControllerModal();
    }
  }

  private async saveNewController(payload: EndpointControllerAddDTO, controllerDroplet: DropletDisplay): Promise<void> {
    BrowserLogger.log('DropletList.saveNewController', { payload, droplet: controllerDroplet });
    // create a loader popup while processing the add controller
    const loader = await this._alertsToastsService.createLoaderWithCustomText(
      `${this._translationsService.instant('UnitPage.CreatingController')},
       ${this._translationsService.instant('General.Wait')}`,
    );
    await loader.present();

    // reset the inventory request state as not being able to be processed (new message will be incoming but need to process API first)
    this._controllerInventoryService.disableProcessing(controllerDroplet.overview.uuid);

    // call the API to create the controller
    try {
      const response = await DropletController.sendApiCreateRequest(this._api, payload);

      // controller created successfully
      loader.message = `${this._translationsService.instant('UnitPage.CreatingControllerSuccess')}.
        ${response.data.Msg}.`;

      // response contains the complete controllers list (including new controller) so apply them
      // NOTE: controller inventory will also get processed (after via DropletControllerInventoryService) as the API call kicks off a MQTT message
      controllerDroplet.applyControllersPayload(response.data.Data.controllers, this._translationsService);

      // flag inventory request as being api processed (inventory ready to be processed)
      this._controllerInventoryService.enableProcessing(controllerDroplet);

      // auto close the loader/modal
      loader.dismiss();
      this._addControllerModal?.dismiss();

      // on save, unselect the controller as the selected highlight will be out-of-sync
      // once we fix the selected highlight issue we can remove this
      this._dropletService.clearDisplayedEndpointController();

      // emit a controller added event
      this._eventBus.emit(new EmitEvent(Event.CONTROLLER_ADDED));
    } catch (error) {
      // hide the loader
      loader.dismiss();

      // display an alert with the error
      const msg = `${error.error.Msg} [${error.error.Code}: ${error.error.Status}]`;
      const alert = await this._alertsToastsService.createAlertWithOkButton(
        this._translationsService.instant('General.Error.Failed'),
        this._translationsService.instant('UnitPage.CreatingControllerFailed'),
        msg,
      );
      await alert.present();
    }
  }
}
