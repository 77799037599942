import { DropletController } from './droplet-controller.model';
import { DropletDeviceConnectionAttribute } from './droplet-device-connection-attribute.model';
import { DropletDeviceControl } from './droplet-device-control.model';
import { DropletMetric } from './droplet-metric.model';
import { EndpointDeviceDTO, DeviceInfo } from './../endpoint/endpoint-payload.model';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '@service/common/api-wrapper.service';
import { BrowserLogger } from '@class/core/browser-logger';
import { DeviceDetails, DeviceOverview, IEC61850Details } from './device-details.model';

export abstract class DropletItemBase {
  id?: string;
  firstSeen: Date; // need to ensure dates are in unit timezone
  lastSeen: Date; // perhaps a unit/vpp service to give us the date-time back in unit/vpp timezone
  name: string;
  number: number;
  metrics: string[] | DropletMetric[];
  metricsForStreamTab: string[] | DropletMetric[];
  viewInStreamTab: boolean;
}

export class DropletDevice extends DropletItemBase {
  uuid: string;
  connectionAttributes: DropletDeviceConnectionAttribute[];
  controls?: DropletDeviceControl[];
  deviceTypeName: string;
  fullName: string;
  settings: DropletDeviceControl[];
  shortName: string;
  serialNo: string;
  genericDeviceTypeName?: string;
  deviceTypeId?: string;
  deviceTypeCategory?: string;
  deviceInfo?: DeviceInfo;
  dropletDeviceUniqueId?: string;
  logicalDeviceTypeId?: string;
  iec61850DeviceTypeId?: string;
  manufacturerName?: string;
  modelName?: string;
  modelNumber?: number;
  protocol?: string;
  slaveId?: number;
  controllers?: DropletController[];
  statusKeys?: string[];
  deviceAttributes?: {
    name: string;
    value: number | string | boolean;
  }[];

  public static adaptToDeviceDetails(device: DropletDevice): DeviceDetails {
    const result: DeviceDetails = {
      controls: device.controls,
      settings: device.settings,
      deviceOverview: DropletDevice.adaptToDeviceOverview(device),
      logicalDetails: DropletDevice.adaptToIEC61850Details(device),
      connectionAttributes: device.connectionAttributes,
      controllers: device.controllers,
      information: device.deviceInfo,
      deviceAttributes: device.deviceAttributes,
    };
    BrowserLogger.log('DropletDevice.adaptToDeviceDetails', { result, device });
    return result;
  }

  public static adaptToDeviceDetailsArray(dropletDevices: DropletDevice[]): DeviceDetails[] {
    return dropletDevices.map((device) => DropletDevice.adaptToDeviceDetails(device));
  }

  public static async sendApiUpdateRequest(api: ApiWrapper, id: string, name: string): Promise<EndpointDeviceDTO> {
    // https://integration-stormcloud-api.switchdin.com/api/v2/devices/b1c9d372/
    // note: response is not exactly an EndpointDeviceDTO but its similar and we are only using the full_name
    BrowserLogger.log('DropletDevice.sendApiUpdateRequest', { id, name });
    const res: { data: EndpointDeviceDTO } = (await api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v2/devices/${id}/`,
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      { name: name },
    )) as { data: EndpointDeviceDTO };
    return res.data as EndpointDeviceDTO;
  }

  public static async sendApiRemoveRequest(api: ApiWrapper, deviceID): Promise<void> {
    // note: response data is returned but we do not use it so returning void
    BrowserLogger.log('DropletDevice.sendApiRemoveRequest', { deviceID });
    await api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v2/devices/${deviceID}/`,
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  public static adaptToDeviceOverview(device: DropletDevice): DeviceOverview {
    const { fullName, number, statusKeys, id, uuid, deviceTypeId, deviceTypeName, iec61850DeviceTypeId } = device;
    const result = { fullName, number, statusKeys, id, uuid, deviceTypeId, deviceTypeName, iec61850DeviceTypeId };
    BrowserLogger.log('DropletDevice.adaptToDeviceOverview', { result, device });
    return result;
  }

  public static adaptToIEC61850Details(device: DropletDevice): IEC61850Details | undefined {
    let result: IEC61850Details = undefined;
    if (device.iec61850DeviceTypeId) {
      result = {
        manufacturerName: device.manufacturerName,
        modelName: device.modelName,
        modelNumber: device.modelNumber,
        serialNo: device.serialNo,
        genericDeviceTypeName: device.genericDeviceTypeName,
        protocol: device.protocol,
        dropletDeviceUniqueId: device.dropletDeviceUniqueId,
        slaveId: device.slaveId,
      };
    }
    BrowserLogger.log('DropletDevice.adaptToIEC61850Details', { result, device });
    return result;
  }
}
