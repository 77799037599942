<ion-card
  class="new-design-card ion-no-margin ion-no-padding"
  id="site-energy-summary"
  *ngIf="unitCompositionBarChartStore$ | async as unitCompositionBarChartStore"
>
  <div [ngClass]="{ loader: unitCompositionBarChartStore.loader }"></div>
  <div [ngClass]="{ opac: unitCompositionBarChartStore.loader }">
    <ion-card-header>
      <ion-card-title>
        {{ 'SiteEnergy.MainTitle' | translate }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content class="ion-no-padding ion-no-marging">
      <ion-grid class="ion-padding pt-0">
        <ion-row>
          <ion-col size="5">
            <app-input-select
              ionItemExtraCss="ion-no-padding item-inner-padding-end-0 fs-12"
              [options]="timePeriods"
              placeholder="{{ 'SiteEnergy.DayView' | translate }}"
              [(ngModel)]="unitCompositionBarChartStore.currentSelectedPeriod"
              (ionChange)="periodChanged($event?.detail?.value)"
            ></app-input-select>
          </ion-col>
          <ion-col size="7" class="ion-align-self-center">
            <ion-item lines="none" class="ion-item-border-height">
              <ion-text class="fs-14 fw-600">
                {{
                  unitCompositionBarChartStore.currentPeriod
                    | dateLabel: unitCompositionBarChartStore.currentSelectedPeriod
                }}
              </ion-text>
              <ion-datetime-button class="ml-5" slot="end" datetime="datetime"></ion-datetime-button>
              <ion-popover [keepContentsMounted]="true" [showBackdrop]="false">
                <ng-template>
                  <ion-datetime
                    id="datetime"
                    [presentation]="unitCompositionBarChartStore.ionDatePickerPresentation"
                    [max]="unitCompositionBarChartStore.ionMaxDate"
                    [isDateEnabled]="unitCompositionBarChartStore.isFirstDayOfWeek"
                    [value]="unitCompositionBarChartStore.currentPeriod"
                    [showDefaultButtons]="true"
                    (ionChange)="dateChanged($event?.detail?.value)"
                  >
                  </ion-datetime>
                </ng-template>
              </ion-popover>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid>
        <ion-row size="12">
          <ion-col size="6" size-lg="12">
            <app-vertical-composition-bars
              [chartData]="unitCompositionBarChartStore.data.production"
              #productionChart
            ></app-vertical-composition-bars>
          </ion-col>
          <ion-item-divider
            class="ion-hide-lg-down text-light ion-no-padding ion-margin-horizontal mt--10 ion-margin-bottom"
          ></ion-item-divider>
          <ion-col size="6" size-lg="12" class="pt-30-lg">
            <app-vertical-composition-bars
              [chartData]="unitCompositionBarChartStore.data.consumption"
              #consumptionChart
            ></app-vertical-composition-bars>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </div>
</ion-card>
