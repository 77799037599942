import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-environment-configs',
  templateUrl: './environment-configs.component.html',
  styleUrls: ['./environment-configs.component.scss'],
})
export class EnvironmentConfigsComponent implements OnInit {
  readonly isEnabled = environment.features.environmentInfo.enabled;
  readonly environmentName = environment.name;
  readonly apiUrl = environment.apiUrl;
  readonly mqttConfigUrl = environment.mqttConfig.url;

  constructor() {}

  ngOnInit() {}
}
