import { Environment } from './environment.type';
import base from './environment.base';

export const environment: Environment = {
  ...base,
  name: 'Staging - Devops',
  production: true,
  serviceWorker: true,
  apiUrl: 'https://integration-stormcloud-api.switchdin.com',
  mqttConfig: {
    url: 'wss://integration-stormcloud-api.switchdin.com:443/ws/',
    connectTimeout: 300 * 1000,
    keepalive: 30,
    clientId: 'CX-PWA-Stage-' + Date.now(),
  },
  features: {
    analytics: {
      pageViews: false,
      errors: false,
    },
    environmentInfo: {
      enabled: true,
    },
    browserLogger: {
      enabled: true,
    },
  },
};
