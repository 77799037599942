import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import Plotly from 'plotly.js-dist';
import { TranslationsService } from '../../services/common/translations.service';
import { AlertController, ModalController } from '@ionic/angular';
import { CommonService } from '../../services/common/common.service';
import { ThemeService } from '../../services/themes/theme.service';
import { VPPChartColors } from '../../../theme/theme-interface';
import { VppDemandResponse } from '../../classes/vpp/vpp-demand-response.model';
import {
  isVppDemandIncrease,
  isVppDemandReduction,
  isVppDemandTypeFcas,
  isVppFcasLower,
  isVppFcasRaise,
  isVppModeDrm,
  isVppModeFcas,
  VppDemandTypes,
  VppModes,
} from '../../classes/vpp/vpp-demand-type-mode.model';
import { VppDispatchService } from '../../services/virtualPowerPlants/dispatch/vpp-dispatch.service';
import {
  adaptVppDemandResponseToDispatchModalData,
  VppDispatchAction,
  VppDispatchModalData,
  VppOperationDispatchModalType,
} from '../../classes/vpp/vpp-dispatch.model';
import {
  VppOperationState,
  VppOperationTabFacadeService,
} from '../../services/virtualPowerPlants/facades/operation-tab/vpp-operation-tab-facade.service';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { VPP_OPERATION_GRAPH_PLOT_LAYOUT } from './vpp-state-operation-graph-constants';
import { ROLLOVER_PERIOD_MIN } from '../../services/virtualPowerPlants/rollover/vpp-roll-over.service';
import { VppOverviewFacadeService } from '../../services/virtualPowerPlants/facades/overview/vpp-overview-facade.service';
import { isEmpty } from 'lodash';
import { VppOperationScheduleModalPage } from '../../pages/modals/vpp-operation-schedule-modal/vpp-operation-schedule-modal.page';
import { isLengendEntry } from '../../services/virtualPowerPlants/facades/analytics/vpp-analytics-facade.service';
import { appendUtcOffsetToTimezoneName } from '@service/virtualPowerPlants/timezone/timezone-names';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
interface VppOperationChartStateBases {
  di: number[];
  dr: number[];
  fcas: number[];
}
enum BarTypes {
  Available = 'Available',
  AutomaticDispatch = 'Automatic dispatch',
  ManualDispatch = 'Manual dispatch',
  UnscheduledDispatch = 'Unscheduled dispatch',
  UnassociatedDispatch = 'Unassociated dispatch',
  ManualEnable = 'Manual enable',
  UnscheduledEnable = 'Unscheduled enable',
  UnassociatedEnable = 'Unassociated enable',
  FcasDispatch = 'FCAS dispatch',
}
interface BarClickData {
  points: BarClickDataPoint[];
}

interface BarClickDataPoint {
  data: { marker: { color: string }; group_data: VppDemandResponse; name: string; legendgroup: BarTypes };
  pointIndex: number;
}
@Component({
  selector: 'app-vpp-state-operation-graph',
  templateUrl: './vpp-state-operation-graph.component.html',
  styleUrls: ['./vpp-state-operation-graph.component.scss'],
})
export class VppStateOperationGraphComponent implements OnDestroy {
  @ViewChild('operationGraph', { static: false }) operationGraph: ElementRef;

  private componentWidth = this.commonService.PLATFORM_WIDTH;
  private eventAttachedOnChart = false;
  private chartState: VppOperationState;
  private subscription: Subscription;

  constructor(
    private trans: TranslationsService,
    private alertController: AlertController,
    private modalController: ModalController,
    private vppOperationTabFacade: VppOperationTabFacadeService,
    private vppOverviewFacade: VppOverviewFacadeService,
    private vppDispatchService: VppDispatchService,
    private commonService: CommonService,
    private permissionsService: PermissionsService,
    private themeService: ThemeService,
  ) {
    this.subscription = combineLatest([
      this.vppOperationTabFacade.vppOperationState$,
      this.vppOverviewFacade.vppOverviewModeDemandType$,
      this.commonService.PLATFORM_RESIZED_OCCURRED,
    ])
      .pipe(
        filter(
          ([state, modeAndDemandType, _]) =>
            state && (modeAndDemandType.demandType != null || modeAndDemandType.mode != null),
        ),
      )
      .subscribe(([state, modeAndDemandType]) => {
        this.chartState = state;
        this.drawVppStateChart(state, modeAndDemandType.mode, modeAndDemandType.demandType);
      });
  }

  drawVppStateChart(st: VppOperationState, currentMode: VppModes, currentDemandType: VppDemandTypes): void {
    const state = Object.assign({}, st);
    state.demand_responses = [];
    st.demand_responses.forEach((element) => {
      state.demand_responses.push(Object.assign({}, element));
    });
    state.demand_responses.sort((a, b) => {
      return b.merit_order - a.merit_order;
    });
    if (state.referenceMetricUnit) {
      VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[2].text = state.referenceMetricUnit;
    }
    if (this.operationGraph !== undefined) {
      this.componentWidth =
        this.operationGraph.nativeElement.offsetWidth === 0 && this.componentWidth === this.commonService.PLATFORM_WIDTH
          ? this.commonService.PLATFORM_WIDTH
          : this.operationGraph.nativeElement.offsetWidth;
    }
    VPP_OPERATION_GRAPH_PLOT_LAYOUT.width = this.componentWidth;
    VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[3].y = state.control_target.max[0]; // max position
    VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[4].y = state.control_target.min[0]; // min position
    VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[5].text = state.currentTime; // current time text
    VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[5].x = state.realTimeIndex; // current time position in x axis
    VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[6].text = appendUtcOffsetToTimezoneName(st.timezone);
    VPP_OPERATION_GRAPH_PLOT_LAYOUT.shapes[0].x0 = state.realTimeIndex;
    VPP_OPERATION_GRAPH_PLOT_LAYOUT.shapes[0].x1 = state.realTimeIndex;
    if (isVppModeDrm(currentMode) && isVppDemandIncrease(currentDemandType)) {
      VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[3].font.color = 'rgba(0,0,0,0)'; // min
      VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[4].font.color = 'rgba(0,0,0,1)'; // max
    } else if (isVppModeDrm(currentMode) && isVppDemandReduction(currentDemandType)) {
      VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[4].font.color = 'rgba(0,0,0,0)'; // max
      VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[3].font.color = 'rgba(0,0,0,1)'; // min
    } else if (isVppModeFcas(currentMode)) {
      VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[4].font.color = 'rgba(0,0,0,0)'; // max
      VPP_OPERATION_GRAPH_PLOT_LAYOUT.annotations[3].font.color = 'rgba(0,0,0,0)'; // min
    }
    const liveObj = {
      name: 'Demand',
      type: 'scatter',
      mode: 'lines',
      line: { shape: 'hv', width: 3, color: 'black' },
      x: state.time_seq,
      y: state.control_target.live,
      hoverinfo: 'none',
      showlegend: false,
    };
    if (!state.control_target['valid']) {
      liveObj.line['dash'] = 'dash';
    } else {
      liveObj.line['dash'] = 'solid';
    }
    let dataForState: any[] = [liveObj];
    if (isVppModeDrm(currentMode) && isVppDemandIncrease(currentDemandType)) {
      const ADMin = {
        name: 'ADMinD',
        type: 'scatter',
        mode: 'lines',
        line: { shape: 'hv', width: 2, color: 'red' },
        x: state.time_seq,
        y: state.control_target.min,
        hoverinfo: 'none',
        showlegend: false,
      };
      dataForState.push(ADMin);
    } else if (isVppModeDrm(currentMode) && isVppDemandReduction(currentDemandType)) {
      const ADMD = {
        name: 'ADMD',
        type: 'scatter',
        mode: 'lines',
        line: { shape: 'hv', width: 2, color: 'red' },
        x: state.time_seq,
        y: state.control_target.max,
        hoverinfo: 'none',
        showlegend: false,
      };
      dataForState.push(ADMD);
    }

    const bar_colors = {
      ...this.themeService.getVPPStateChartBarColors(),
    };

    const legends = this.createLegendGroups(bar_colors, currentMode);
    dataForState = dataForState.concat(legends).flat();

    if (state.demand_responses?.length > 0) {
      // Create a trace for every demand_response data series available first for DI & dispatch first for DR
      if (isVppModeDrm(currentMode) && isVppDemandIncrease(currentDemandType)) {
        const demandIncreases = state.demand_responses.filter((res) => isVppDemandIncrease(res.demand_type));
        demandIncreases.forEach((elt) => {
          // Create a plot for undispatched assets
          const available = this.createAvailabilityGraphObject(elt, bar_colors, state.time_seq);
          dataForState.unshift(available);
        });

        demandIncreases.forEach((elt) => {
          const dispatches = this.createDrmDispatchGraphObjects(elt, bar_colors, state.time_seq);
          dispatches.forEach((dispatch) => {
            dataForState.unshift(dispatch);
          });
        });
      } else if (isVppModeDrm(currentMode) && isVppDemandReduction(currentDemandType)) {
        const demandReductions = state.demand_responses.filter((res) => isVppDemandReduction(res.demand_type));
        demandReductions.forEach((elt) => {
          const dispatches = this.createDrmDispatchGraphObjects(elt, bar_colors, state.time_seq);
          dispatches.forEach((dispatch) => {
            dataForState.unshift(dispatch);
          });
        });

        demandReductions.forEach((elt) => {
          const available = this.createAvailabilityGraphObject(elt, bar_colors, state.time_seq);
          dataForState.unshift(available);
        });
      } else if (isVppModeFcas(currentMode)) {
        //FCAS raise
        const fcasRaiseResponses = state.demand_responses.filter((res) => isVppFcasRaise(res.demand_type));
        fcasRaiseResponses.forEach((fcas) => {
          const available = this.createAvailabilityGraphObject(fcas, bar_colors, state.time_seq);
          dataForState.unshift(available);
          const enablements = this.createFcasEnableGraphObjects(fcas, bar_colors, state.time_seq);
          enablements.forEach((enablement) => dataForState.unshift(enablement));
          if (!isEmpty(fcas.resp_seq)) {
            const dispatch = this.createFcasDispatchGraphObject(fcas, bar_colors, state.time_seq);
            dataForState.unshift(dispatch);
          }
        });
        //FCAS lower
        const fcasLowerResponses: VppDemandResponse[] = state.demand_responses.filter((res) =>
          isVppFcasLower(res.demand_type),
        );
        fcasLowerResponses.forEach((fcas) => {
          if (!isEmpty(fcas.resp_seq)) {
            const dispatch = this.createFcasDispatchGraphObject(fcas, bar_colors, state.time_seq);
            dataForState.unshift(dispatch);
          }
          const enablements = this.createFcasEnableGraphObjects(fcas, bar_colors, state.time_seq);
          enablements.forEach((enablement) => dataForState.unshift(enablement));
          const available = this.createAvailabilityGraphObject(fcas, bar_colors, state.time_seq);
          dataForState.unshift(available);
        });
      }

      let topLineDR = state.control_target.live.slice();
      let topLineDI = state.control_target.live.slice();
      let bottomLineDR = state.control_target.live.slice();
      let bottomLineDI = state.control_target.live.slice();

      // Create an invisible bar in each stacking group. This positions each
      // stack vertically into the correct location.
      const invisibleOffsetBases: VppOperationChartStateBases = {
        di: state.control_target.live.slice(),
        dr: state.control_target.live.slice(),
        fcas: state.control_target.live.slice(),
      };

      state.demand_responses.forEach(function (elt) {
        if (isVppDemandReduction(elt.demand_type)) {
          elt.avail_seq.forEach((avail, i) => {
            invisibleOffsetBases.dr[i] -= elt.avail_to_display[i];
            bottomLineDR[i] -= avail;
          });
          elt.dptch_seq.forEach((dptch, i) => {
            topLineDR[i] += dptch;
          });
        }
        if (isVppDemandIncrease(elt.demand_type)) {
          elt.dptch_seq.forEach(function (dptch, i) {
            invisibleOffsetBases.di[i] -= dptch;
            bottomLineDI[i] -= dptch;
          });
        }
        if (isVppFcasLower(elt.demand_type)) {
          invisibleOffsetBases.fcas = invisibleOffsetBases.fcas.map(
            (base, i) => base - elt.avail_to_display[i] - elt.dptch_seq[i] - (elt?.resp_seq?.[i] ?? 0),
          );
        }
      });
      invisibleOffsetBases.di.pop();
      invisibleOffsetBases.dr.pop();
      invisibleOffsetBases.fcas.pop();

      //DI biggest and smallest values line to draw that greyed out area
      topLineDI = this.getGreyedLine('top', topLineDI);
      bottomLineDI = this.getGreyedLine('bottom', bottomLineDI);

      //DR biggest and smallest values line to draw that greyed out area
      topLineDR = this.getGreyedLine('top', topLineDR);
      bottomLineDR = this.getGreyedLine('bottom', bottomLineDR);

      if (isVppModeDrm(currentMode) && isVppDemandIncrease(currentDemandType)) {
        if (bottomLineDI.length > 0) {
          const bottomLine = this.createTopOrBottomLine(state.time_seq, bottomLineDI);
          dataForState.unshift(bottomLine);
        }

        const topLine = this.createTopOrBottomLine(state.time_seq, topLineDI);
        dataForState.unshift(topLine);

        const bases = this.createInvisibleOffsetBases(state.time_seq, invisibleOffsetBases.di);
        dataForState.unshift(bases);
      } else if (isVppModeDrm(currentMode) && isVppDemandReduction(currentDemandType)) {
        if (bottomLineDR.length > 0) {
          const bottomLine = this.createTopOrBottomLine(state.time_seq, bottomLineDR);
          dataForState.unshift(bottomLine);
        }

        const topLine = this.createTopOrBottomLine(state.time_seq, topLineDR);
        dataForState.unshift(topLine);

        const bases = this.createInvisibleOffsetBases(state.time_seq, invisibleOffsetBases.dr);
        dataForState.unshift(bases);
      } else if (isVppModeFcas(currentMode)) {
        const bases = this.createInvisibleOffsetBases(state.time_seq, invisibleOffsetBases.fcas);
        dataForState.unshift(bases);
      }
    }
    if (this.operationGraph != null) {
      const layoutObjectWithTranslatedAnnotation = this.translateLayoutConstantAnnotationText(
        VPP_OPERATION_GRAPH_PLOT_LAYOUT,
      );
      Plotly.react(this.operationGraph.nativeElement, dataForState, layoutObjectWithTranslatedAnnotation, {
        displayModeBar: false,
      });
      if (!this.eventAttachedOnChart) {
        this.eventAttachedOnChart = true;
        if (this.permissionsService.any([PermissionKey.VPP_DISPATCH_VPP])) {
          this.operationGraph.nativeElement.on('plotly_click', (data: BarClickData) => {
            this.handleClick(data);
          });
        }
      }
    }
  }

  createLegendGroups(barColors: VPPChartColors, mode: VppModes): any[] {
    const { FCAS } = VppModes;
    const legendGroups = [];
    let groupName = BarTypes.Available;
    const available = {
      name: groupName,
      type: 'scatter',
      mode: 'lines',
      line: { shape: 'hv', width: 2, color: barColors.avail },
      x: [0],
      y: [0],
      hoverinfo: 'none',
      showlegend: true,
      legendgroup: groupName,
      visible: 'legendonly',
    };
    legendGroups.push(available);

    groupName = isVppModeFcas(mode) ? BarTypes.UnscheduledEnable : BarTypes.UnscheduledDispatch;
    const unscheduled = {
      name: groupName,
      type: 'scatter',
      mode: 'lines',
      line: { shape: 'hv', width: 2, color: barColors.unscheduled_dispatch },
      x: [0],
      y: [0],
      hoverinfo: 'none',
      showlegend: true,
      legendgroup: groupName,
      visible: 'legendonly',
    };
    legendGroups.push(unscheduled);

    groupName = isVppModeFcas(mode) ? BarTypes.ManualEnable : BarTypes.ManualDispatch;
    const manual = {
      name: groupName,
      type: 'scatter',
      mode: 'lines',
      line: { shape: 'hv', width: 2, color: barColors.manual_dispatch },
      x: [0],
      y: [0],
      hoverinfo: 'none',
      showlegend: true,
      legendgroup: groupName,
      visible: 'legendonly',
    };
    legendGroups.push(manual);

    groupName = BarTypes.AutomaticDispatch;
    if (isVppModeDrm(mode)) {
      const auto = {
        name: groupName,
        type: 'scatter',
        mode: 'lines',
        line: { shape: 'hv', width: 2, color: barColors.auto_dispatch },
        x: [0],
        y: [0],
        hoverinfo: 'none',
        showlegend: true,
        legendgroup: groupName,
        visible: 'legendonly',
      };
      legendGroups.push(auto);
    }
    groupName = isVppModeFcas(mode) ? BarTypes.UnassociatedEnable : BarTypes.UnassociatedDispatch;
    const unassociated = {
      name: groupName,
      type: 'scatter',
      mode: 'lines',
      line: { shape: 'hv', width: 2, color: barColors.unassociated_dispatch },
      x: [0],
      y: [0],
      hoverinfo: 'none',
      showlegend: true,
      legendgroup: groupName,
      visible: 'legendonly',
    };
    legendGroups.push(unassociated);

    groupName = BarTypes.FcasDispatch;
    if (isVppModeFcas(mode)) {
      const fcasDispatch = {
        name: groupName,
        type: 'scatter',
        mode: 'lines',
        line: { shape: 'hv', width: 2, color: barColors.dispatch },
        x: [0],
        y: [0],
        hoverinfo: 'none',
        showlegend: true,
        legendgroup: groupName,
        visible: 'legendonly',
      };
      legendGroups.push(fcasDispatch);
    }
    return legendGroups;
  }

  isFcasSeqAllZero(response: VppDemandResponse): boolean {
    const availNonZeroLength = response.avail_seq.filter((val) => val != 0).length;
    const dptchNonZeroLength = response.resp_seq.filter((val) => val != 0).length;
    const enNonZeroLength = response.dptch_seq.filter((val) => val != 0).length;

    return !dptchNonZeroLength && !availNonZeroLength && !enNonZeroLength;
  }

  createBarObject(
    data: number[],
    elt: VppDemandResponse,
    barColor: string,
    barColorSelected: string,
    timeSeq: string[],
    nameSuffix: string,
    legendGroup: string,
  ): any {
    const showLegend = isLengendEntry(data);
    const offset = 0.1;
    const isFCAS = isVppDemandTypeFcas(elt.demand_type);
    let name: string;
    if (isVppFcasRaise(elt.demand_type)) {
      name = elt.group + '-R' + nameSuffix;
    } else if (isVppFcasLower(elt.demand_type)) {
      name = elt.group + '-L' + nameSuffix;
    } else {
      name = elt.group + '-M' + elt.merit_order + nameSuffix;
    }
    const bars = {
      name,
      type: 'bar',
      marker: {
        color: barColor,
        line: {
          color: 'rgb(255,255,255)',
          width: 0.2,
        },
      },
      hoveron: 'fills',
      hoverinfo: showLegend ? 'y+name' : 'none',
      hoverlabel: {
        bgcolor: barColor,
        bordercolor: 'black',
        font: {
          size: 24,
        },
      },
      x: timeSeq.map((a) => a),
      offset: offset,
      y: isFCAS ? data : data.map((a) => parseFloat(Math.abs(a).toFixed(1))),
      showlegend: showLegend,
      legendgroup: legendGroup,
      group_data: elt,
    };
    if (elt.selected) {
      bars.marker.color = barColorSelected;
    }

    return bars;
  }

  createAvailabilityGraphObject(elt: VppDemandResponse, barColor: VPPChartColors, timeSeq: string[]): any {
    const data = elt.avail_to_display;
    const barColorNormal = barColor.avail;
    const barColorSelected = barColor.selected_avail;
    const nameSuffix = '-AV';
    const legendGroup = BarTypes.Available;
    return this.createBarObject(data, elt, barColorNormal, barColorSelected, timeSeq, nameSuffix, legendGroup);
  }

  createUnscheduledGraphObject(
    elt: VppDemandResponse,
    barColor: VPPChartColors,
    timeSeq: string[],
    mode: VppModes,
  ): any {
    const data = elt.unscheduled_dptch_seq; // DRM dispatch, FCAS enable (NOT dispatch)
    const barColorNormal = barColor.unscheduled_dispatch;
    const barColorSelected = barColor.selected_dispatch;
    const nameSuffix = isVppModeFcas(mode) ? '-EN' : '-DIS';
    const legendGroup = isVppModeFcas(mode) ? BarTypes.UnscheduledEnable : BarTypes.UnscheduledDispatch;
    return this.createBarObject(data, elt, barColorNormal, barColorSelected, timeSeq, nameSuffix, legendGroup);
  }

  createUnassociatedGraphObject(
    elt: VppDemandResponse,
    barColor: VPPChartColors,
    timeSeq: string[],
    mode: VppModes,
  ): any {
    const data = elt.unassociated_dptch_seq; // DRM dispatch, FCAS enable (NOT dispatch)
    const barColorNormal = barColor.unassociated_dispatch;
    const barColorSelected = barColor.selected_constraint;
    const nameSuffix = isVppModeFcas(mode) ? '-EN' : '-DIS';
    const legendGroup = isVppModeFcas(mode) ? BarTypes.UnassociatedEnable : BarTypes.UnassociatedDispatch;
    return this.createBarObject(data, elt, barColorNormal, barColorSelected, timeSeq, nameSuffix, legendGroup);
  }

  createAutomaticGraphObject(elt: VppDemandResponse, barColor: VPPChartColors, timeSeq: string[]): any {
    const data = elt.auto_dptch_seq;
    const barColorNormal = barColor.auto_dispatch;
    const barColorSelected = barColor.selected_dispatch;
    const nameSuffix = '-DIS';
    const legendGroup = BarTypes.AutomaticDispatch;
    return this.createBarObject(data, elt, barColorNormal, barColorSelected, timeSeq, nameSuffix, legendGroup);
  }
  createManualGraphObject(elt: VppDemandResponse, barColor: VPPChartColors, timeSeq: string[], mode: VppModes): any {
    const data = elt.manual_dptch_seq; // DRM dispatch, FCAS enable (NOT dispatch)
    const barColorNormal = barColor.manual_dispatch;
    const barColorSelected = barColor.selected_dispatch;
    const nameSuffix = isVppModeFcas(mode) ? '-EN' : '-DIS';
    const legendGroup = isVppModeFcas(mode) ? BarTypes.ManualEnable : BarTypes.ManualDispatch;
    return this.createBarObject(data, elt, barColorNormal, barColorSelected, timeSeq, nameSuffix, legendGroup);
  }

  createDrmDispatchGraphObjects(elt: VppDemandResponse, barColors: VPPChartColors, timeSeq: string[]): any[] {
    const graphObjects = [];

    let dataObj = this.createUnscheduledGraphObject(elt, barColors, timeSeq, VppModes.DRM);
    graphObjects.push(dataObj);

    dataObj = this.createUnassociatedGraphObject(elt, barColors, timeSeq, VppModes.DRM);
    graphObjects.push(dataObj);

    dataObj = this.createAutomaticGraphObject(elt, barColors, timeSeq);
    graphObjects.push(dataObj);

    dataObj = this.createManualGraphObject(elt, barColors, timeSeq, VppModes.DRM);
    graphObjects.push(dataObj);

    return graphObjects;
  }

  createFcasEnableGraphObjects(elt: VppDemandResponse, barColors: VPPChartColors, timeSeq: string[]): any[] {
    const graphObjects = [];

    let dataObj = this.createUnscheduledGraphObject(elt, barColors, timeSeq, VppModes.FCAS);
    graphObjects.push(dataObj);

    dataObj = this.createUnassociatedGraphObject(elt, barColors, timeSeq, VppModes.FCAS);
    graphObjects.push(dataObj);

    dataObj = this.createManualGraphObject(elt, barColors, timeSeq, VppModes.FCAS);
    graphObjects.push(dataObj);

    return graphObjects;
  }

  createFcasDispatchGraphObject(elt: VppDemandResponse, barColor: VPPChartColors, timeSeq: string[]): any {
    const data = elt.resp_seq;
    const barColorNormal = barColor.dispatch;
    const barColorSelected = barColor.selected_dispatch;
    const nameSuffix = '-DIS';
    const legendGroup = BarTypes.FcasDispatch;
    return this.createBarObject(data, elt, barColorNormal, barColorSelected, timeSeq, nameSuffix, legendGroup);
  }

  createTopOrBottomLine(timeSeq: string[], lineData: number[]): any {
    return {
      name: 'line',
      type: 'scatter',
      mode: 'none',
      x: timeSeq,
      y: lineData,
      hoverinfo: 'none',
      fillcolor: 'rgba(192,192,192,0.5)',
      fill: 'tozeroy',
      showlegend: false,
    };
  }

  createInvisibleOffsetBases(timeSeq: string[], baseHeights: number[]): any {
    return {
      name: 'base',
      type: 'bar',
      marker: { color: 'rgba(0,0,0,0)' },
      hovoron: 'fills',
      hoverinfo: 'none',
      x: timeSeq.map((a) => a),
      offset: 0.025,
      y: baseHeights,
      showlegend: false,
    };
  }

  async handleClick(clickData: BarClickData): Promise<void> {
    const point = clickData.points[0];
    if (point.pointIndex < this.chartState.presentIndex - 1) {
      return;
    } else {
      if (point.data.group_data === undefined) {
        return;
      }
      if (this.checkCancelDispatchBelongsToAnyLongDispatchHistory(point)) {
        const selectedDemandResponse = point.data.group_data;
        const selectedPointIndex = point.pointIndex;
        const selectedTraceName = point.data.name;
        const selectedBarType = point.data.legendgroup;
        await this.presentAlertConfirm(selectedDemandResponse, selectedTraceName, selectedPointIndex, selectedBarType);
      }
    }
  }
  // when we are cancelling a dispatch from the chart
  // has to check whether the cancel dispatch interval belongs to any dispatch
  // that is longer then the rollover interval, i.e. 5 min
  // if the cancel dispatch belongs to any dispatch history and the dispatch history
  // start time and end time is more then roll over period then prevent the user from cancelling
  // the chart, user has to cancel it from the future tab
  // for example there is a dispatch for 30 min, and user try to cancel an interval of that dispatch, that'll be 5 min
  // then prevent user to not to perform that operation as it belongs to the whole 30 min of dispatch
  private checkCancelDispatchBelongsToAnyLongDispatchHistory(pointData: BarClickDataPoint): boolean {
    // first need to check the asset been clicked, whether it is available
    // or already dispatch
    let returnValue = false;
    const plotInfo = pointData.data;
    if (plotInfo.group_data === undefined) {
      return returnValue;
    }
    const bar_colors = this.themeService.getVPPStateChartBarColors();
    // startTime: checking if the clicked bar is the current or not
    // if current then pick the time_now due to dispatch now thingo, otherwise pick the time from the list
    //
    // endTime: checking if the clicked bar is the current or not
    // if current then pick the time of clicked index + 1, for current the start time will be time_now
    // and we can not just add (60 * ROLL_OVER_PERIOD) into the start time, .e.g., start time = 10:03, then the end time should be 10:05 in case of current index
    // if it's not current index, then it's sure it's an exact interval of roll over period and we can add that period in start time to make
    // end time
    //
    const startTime =
      pointData.pointIndex + 1 === this.chartState.presentIndex
        ? this.chartState.time_now_epoch
        : this.chartState.epochTimeSeries[pointData.pointIndex];
    const endTime =
      pointData.pointIndex + 1 === this.chartState.presentIndex
        ? this.chartState.epochTimeSeries[pointData.pointIndex + 1]
        : startTime + 60 * ROLLOVER_PERIOD_MIN;
    if (
      plotInfo.marker.color == bar_colors.unscheduled_dispatch ||
      plotInfo.marker.color == bar_colors.manual_dispatch ||
      plotInfo.marker.color == bar_colors.auto_dispatch ||
      plotInfo.marker.color == bar_colors.selected_dispatch
    ) {
      //
      // Why change of strategy?
      // I was under the impression that there will be only one match for dispatch
      // but i was wrong
      // so, first filtering out the list of the matched dispatches
      // then checking does it belong to any dispatch that has only one interval?
      // and that is not in current time, i.e., current index (it's a bit complicated to cancel the current index)
      // and if it doesn't found in the single interval thing
      // means it's a part of continuous dispatch, and can not cancel it
      //
      // check is there any dispatch(s) history available for the clicked data
      const dispatches = this.vppDispatchService.futureDispatches.filter(
        (x) =>
          plotInfo.group_data.demand_type == x.demandType &&
          plotInfo.group_data.group == x.group &&
          plotInfo.group_data.merit_order == x.order &&
          x.action == VppDispatchAction.ENABLE,
      );
      if (dispatches.length > 0) {
        let found = false;
        for (let index = 0; index < dispatches.length; index++) {
          const dispatch = dispatches[index];
          // check whether the starttime & endtime difference is more or equal to rollover period
          //
          // what's happening?
          // it'll check the difference, if it's more than rollover period (in this case, it's 300)
          // then it won't let the user to cancel it as it's part of a continuous dispatch, means dispatch is a longer period
          // if the difference is less than or equal to 300
          // then it'll check whether the index is current time, if yes then prevent the user to not to cancel it
          if (dispatch.endTimeEpochSec - dispatch.startTimeEpochSec <= ROLLOVER_PERIOD_MIN * 60) {
            if (pointData.pointIndex + 1 === this.chartState.presentIndex) {
              this.presentAlertSimpleOk(
                this.trans.instant('VirtualPowerPlant.CanNotCancelDispatch'),
                '',
                this.trans.instant('VirtualPowerPlant.DispatchIsInTheCurrentInterval'),
              );
              found = true;
              break;
              // checking the start time & end time to make sure the interval is valid
              // just an extra check
            } else if (dispatch.endTimeEpochSec == endTime && dispatch.startTimeEpochSec == startTime) {
              returnValue = true;
              found = true;
              break;
            }
          }
        }
        if (!found) {
          this.presentAlertSimpleOk(
            this.trans.instant('VirtualPowerPlant.CanNotCancelDispatch'),
            '',
            this.trans.instant('VirtualPowerPlant.DispatchSpansMultipleIntervals'),
          );
        }
      }
      return returnValue;
    }
    return !returnValue;
  }
  getGreyedLine(value: 'top' | 'bottom', list: number[]): number[] {
    if (value == 'top') {
      const maxValue = Math.max.apply(null, list);
      list = list.slice(0, this.chartState.presentIndex);
      list = list.fill(maxValue + 15, 0);
      return list;
    } else if (value == 'bottom') {
      const minValue = Math.min.apply(null, list);
      if (minValue < 0) {
        list = list.slice(0, this.chartState.presentIndex);
        list = list.fill(minValue - 5, 0);
        return list;
      } else {
        return [];
      }
    }
    return [];
  }

  private translateLayoutConstantAnnotationText(layoutObject: { annotations: { text: string }[] }): any {
    const annotations = layoutObject.annotations;
    const translatedAnnotations = annotations.map((annotation) => {
      const translatedString = annotation.text ? this.trans.instant(annotation.text) : null;
      return { ...annotation, text: translatedString };
    });
    return { ...layoutObject, annotations: translatedAnnotations };
  }

  async presentAlertSimpleOk(header: string, subheader: string, message: string): Promise<void> {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }
  async presentAlertConfirm(
    selectedDemandResponseData: VppDemandResponse,
    traceName: string,
    index: number,
    barType: BarTypes,
  ): Promise<void> {
    try {
      const startTime = this.chartState.epochTimeSeries[index];
      const endTime = this.chartState.epochTimeSeries[index + 1];
      let modalType: VppOperationDispatchModalType;
      if (this.isDispatchTypeBar(barType)) modalType = VppOperationDispatchModalType.CANCEL;
      else if (barType === BarTypes.Available) modalType = VppOperationDispatchModalType.GRAPH_DISPATCH;
      else return;
      const dispatch: VppDispatchModalData = adaptVppDemandResponseToDispatchModalData(
        selectedDemandResponseData,
        modalType,
        startTime,
        endTime,
      );

      const modal = await this.modalController.create({
        component: VppOperationScheduleModalPage,
        cssClass: 'transparent-modal',
        backdropDismiss: false,
        componentProps: {
          dispatch,
          isDateReadonly: true,
        },
      });
      await modal.present();
    } catch (err) {
      console.error(err);
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private isDispatchTypeBar(bar: BarTypes): boolean {
    return (
      bar === BarTypes.UnscheduledDispatch ||
      bar === BarTypes.AutomaticDispatch ||
      bar === BarTypes.ManualDispatch ||
      bar === BarTypes.UnassociatedDispatch ||
      bar === BarTypes.UnscheduledEnable ||
      bar === BarTypes.ManualEnable ||
      bar === BarTypes.UnassociatedEnable
    );
  }
}
