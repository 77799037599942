<ion-button
  (click)="handleClick()"
  color="button-primary"
  class="edit-name-button-rounded"
  mode="ios"
  [disabled]="isDisabled"
>
  <span *ngIf="!label" class="ml-15 fw-600 fs-14"> {{ 'Alerts.Edit' | translate }} </span>
  <span *ngIf="label" class="ml-15 fw-600 fs-14"> {{ label }} </span>
  <ion-icon class="ml-7 mr-10" src="assets/icons/svg/edit.svg"></ion-icon>
</ion-button>
