import { AfterViewInit, Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  DeviceControlOrParam,
  DropletControlType,
  DropletDeviceControl,
} from '../../classes/units/droplet/droplet-device-control.model';
import {
  DeviceControlService,
  DeviceControlState,
  DeviceControlStatus,
} from '../../services/units/endpoints/device-control.service';
import { DeviceDetailMessageState } from '../device-detail-message/device-detail-message.component';
import { TranslationsService } from '../../services/common/translations.service';
import { BrowserLogger } from '@class/core/browser-logger';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DropletControllerParameter } from '@class/units/droplet/droplet-controller-parameter.model';

export interface ControllerParameterOptions {
  isReadOnly?: boolean;
  isCreate?: boolean;
}

class DeviceControlViewModel {
  enableMqttInputs: boolean;
  syncLabel: string;
  // a reference to the [deviceControl] used for direct input value binding when [controllerParam] is set
  // this bypasses MQTT/DeviceControlService/State and the values are set against the model only
  deviceControl: DeviceControlOrParam; // reference to the DeviceControl
  controlValueErrorMsg?: string;
  controllerParamIsDisabled?: boolean;
  enableInputs: boolean; // are any of the input shown
  enableToggleInput: boolean; // is the toggle input shown
  enableTextControlInput: boolean; // is the text control input shown
  textControlInputType?: string; // what is the type of text control input (number or string)
  enableManualControlToggle: boolean; // is the manual control toggle shown
  manualControlToggleValue: boolean; // the manual control toggle value
  deviceControlStatus?: DeviceControlStatus; // app-device-detail-message
}
@Component({
  selector: 'app-device-control',
  templateUrl: './device-control.component.html',
  styleUrls: ['./device-control.component.scss'],
  providers: [DeviceControlService],
})
export class DeviceControlComponent implements OnDestroy, AfterViewInit {
  @Input()
  set deviceControl(deviceControl: DeviceControlOrParam) {
    BrowserLogger.log('DeviceControl.deviceControl.set', { deviceControl });
    this.setDeviceControl(deviceControl);
    this.initInputControls();
    this.setManualControlMode(false); // default to auto (non manual) mode
    this.checkControllerParamDeviceControlValue(this.vm.deviceControl.value);
  }
  @Input()
  set uuid(uuid: string) {
    BrowserLogger.log('DeviceControl.uuid.set', { uuid });
    this.deviceControlService.setEndpointUUID(uuid);
  }
  @Input() set controllerParamOptions(value: ControllerParameterOptions | null) {
    if (value) {
      // we are a controller parameter
      this.vm.enableMqttInputs = false;
      this.vm.controllerParamIsDisabled = value.isReadOnly;
      // check if we are in manual control mode
      // but if creating then start without manual control
      if (this.supportsManualControl()) {
        const isInManualControl =
          value.isCreate !== true &&
          DropletDeviceControl.isValueEnabled(this.vm.deviceControl, this.vm.deviceControl.value);
        this.setManualControlMode(isInManualControl);
      }
    } else {
      // not a controller parameter
      this.vm.enableMqttInputs = true;
      this.vm.controllerParamIsDisabled = undefined;
    }
    BrowserLogger.log('DeviceControl.controllerParamOptions.set', { value, vm: this.vm });
    this.initControllerParamDeviceControlValueHandler();
  }
  @Output() manualControlToggled = new EventEmitter();
  @Output() controllerParamDeviceControlValueChanged = new EventEmitter();

  // View Models (page/observable)
  vm: DeviceControlViewModel = {
    enableMqttInputs: true,
    syncLabel: 'Sync',
    deviceControl: null,
    enableInputs: true,
    enableToggleInput: false,
    enableTextControlInput: false,
    enableManualControlToggle: false,
    manualControlToggleValue: false, // default to not manual mode (in auto mode). Will get set by incoming mqtt control values
  };
  vm$: Observable<DeviceControlState> = this.deviceControlService.vm$;

  readonly DeviceDetailMessageState = DeviceDetailMessageState;
  readonly DropletControlType = DropletControlType;

  private _controllerParamDeviceControlValueChanged: Subject<string> = new Subject<string>();
  private _controllerParamDeviceControlValueSubscription: Subscription;
  private _deviceControlStateSubscription: Subscription;
  private _mqttValueSubscription: Subscription;
  private _mqttValueDataSubscription: Subscription;
  private _isSendingAutoMode: boolean = false; // track when setting the control to auto mode

  constructor(
    public deviceControlService: DeviceControlService,
    private _translationsService: TranslationsService,
  ) {
    BrowserLogger.log('DeviceControl.constructor');
    this.setSyncLabel();
  }

  ngAfterViewInit(): void {
    this.initMqttValueSubscription();
    this.initDeviceControlStateSubscription();
  }

  ngOnDestroy(): void {
    BrowserLogger.log('DeviceControl.ngOnDestroy');
    this._controllerParamDeviceControlValueSubscription?.unsubscribe();
    this._deviceControlStateSubscription?.unsubscribe();
    this._mqttValueSubscription?.unsubscribe();
    this._mqttValueDataSubscription?.unsubscribe();
  }

  private initMqttValueSubscription(): void {
    // subscribe to the endpointMqttValue data
    // and if we receive some then we are in manual control mode
    // so need to ensure the manual control is turned on
    this._mqttValueSubscription?.unsubscribe(); // unsubscribe, protection in case this gets called multiple times
    this._mqttValueSubscription = this.deviceControlService.endpointMqttValue$.subscribe((mqttValue$) => {
      if (mqttValue$) {
        this._mqttValueDataSubscription?.unsubscribe(); // unsubscribe, protection in case this gets called multiple times
        this._mqttValueDataSubscription = mqttValue$.subscribe((mqttValue) => {
          // if got an mqtt control value then make sure manual control is set (if not already set and not sending auto mode)
          if (mqttValue && !this._isSendingAutoMode && !this.vm.manualControlToggleValue) {
            setTimeout(() => {
              BrowserLogger.log('DeviceControl.endpointMqttValue.mqttValue', { mqttValue });
              this.setManualControlMode(true); // set to manual control mode
            }); // wait for the next digest cycle, otherwise will get lifecycle issues overwriting existing values
          }
        });
      }
    });
  }

  private initDeviceControlStateSubscription(): void {
    this._deviceControlStateSubscription?.unsubscribe(); // unsubscribe, protection in case this gets called multiple times
    // subscribe to device control state change so can check if auto mode worked
    this._deviceControlStateSubscription = this.deviceControlService.vm$.subscribe((state: DeviceControlState) => {
      BrowserLogger.log('DeviceControl.DeviceControlStateSubscription', { state });
      this.checkControlModeCompletion(state.status);
      this.setDeviceControlStatus(state.status);
    });
  }

  private setDeviceControlStatus(deviceControlStatus: DeviceControlStatus): void {
    // deviceControlStatus use for app-device-detail-message
    // only want if we have a title/subtitle
    this.vm.deviceControlStatus =
      deviceControlStatus?.title && deviceControlStatus?.subtitle?.length ? deviceControlStatus : undefined;
    BrowserLogger.log('DeviceControl.setDeviceControlStatus', { vm: this.vm.deviceControlStatus, deviceControlStatus });
  }

  private setDeviceControl(deviceControl: DeviceControlOrParam): void {
    BrowserLogger.log('DeviceControl.setDeviceControl', { deviceControl });
    this.vm.deviceControl = deviceControl;
    delete this.vm.deviceControl.isManualControlRequested; // clear any previous manual control
    this.deviceControlService.setDeviceControl(deviceControl); // trigger state/observable change
  }

  submitControlToggle(value: boolean): void {
    BrowserLogger.log('DeviceControl.submitControlToggle', { value });
    const v = !value ? 1 : 0;
    setTimeout(() => {
      this.submitControlValue(v);
    }, 0);
  }

  private setSyncLabel(): void {
    const { ServerValue, DeviceSettingValueNotInSync } = this._translationsService.instant('UnitPage');
    this.vm.syncLabel = `${DeviceSettingValueNotInSync}. ${ServerValue}: `;
  }

  private submitControlValue(value: number): void {
    BrowserLogger.log('DeviceControl.submitControlValue', { value });
    this.deviceControlService.sendControlToDroplet(value);
  }

  handleToggleInputClick(value: any): void {
    BrowserLogger.log('DeviceControl.handleToggleInputClick', { value });
    this.submitControlToggle(value);
  }

  handleTextInputClick(value: any): void {
    BrowserLogger.log('DeviceControl.handleTextInputClick', { value });
    this.submitControlValue(value);
  }

  handleResyncClick(value: any): void {
    BrowserLogger.log('DeviceControl.handleResyncClick', { value });
    this.submitControlValue(value);
  }

  handleControllerParamDeviceControlValueChange(): void {
    if (this.vm.deviceControl) {
      const modelValue = this.vm.deviceControl.value as string;
      BrowserLogger.log('DeviceControl.handleControllerParamDeviceControlValueChange', { modelValue });
      this._controllerParamDeviceControlValueChanged.next(modelValue);
      this.controllerParamDeviceControlValueChanged.emit(modelValue);
    }
  }

  private initControllerParamDeviceControlValueHandler(): void {
    // handle controller param device control input (once stopped typing, allowing 500ms for between keypress)
    this._controllerParamDeviceControlValueSubscription?.unsubscribe();
    this._controllerParamDeviceControlValueSubscription = this._controllerParamDeviceControlValueChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.checkControllerParamDeviceControlValue(value);
      });
  }

  private checkControllerParamDeviceControlValue(value: string | number | boolean): void {
    BrowserLogger.log('DeviceControl.checkControllerParamDeviceControlValue', {
      value: value,
      displayConfigJson: this.vm.deviceControl?.displayConfigJson,
    });
    if (this.vm.deviceControl) {
      const valueCheckResult = DropletDeviceControl.checkValue(this.vm.deviceControl, value);
      this.vm.deviceControl.isValueValid = valueCheckResult.isValid;
      this.vm.controlValueErrorMsg = valueCheckResult.message;
    }
  }

  private initInputControls(): void {
    // setup the view model properties for the inputs
    BrowserLogger.log('DeviceControl.initInputControls', { vm: this.vm });
    this.vm.enableToggleInput = false;
    this.vm.enableTextControlInput = false;
    // numeric control
    if (DropletDeviceControl.isNumericType(this.vm.deviceControl.type)) {
      this.vm.enableTextControlInput = true;
      this.vm.textControlInputType = 'number';
    }
    // string control
    else if (DropletDeviceControl.isStringType(this.vm.deviceControl.type)) {
      this.vm.enableTextControlInput = true;
      this.vm.textControlInputType = 'string';
    }
    // toggle control
    else if (DropletDeviceControl.isToggleType(this.vm.deviceControl.type)) {
      this.vm.enableToggleInput = true;
    }
  }

  private setManualControlMode(value: boolean): void {
    this.vm.manualControlToggleValue = value;
    this.vm.deviceControl.isManualControlRequested = value;
    BrowserLogger.log('DeviceControl.setManualControlMode', { value, vm: this.vm });
    // check if control supports manual control
    this.vm.enableManualControlToggle = this.supportsManualControl();
    this.setInputVisibility();
  }

  private supportsManualControl(): boolean {
    let result = false;
    if (this.vm.deviceControl instanceof DropletControllerParameter) {
      result = DropletControllerParameter.supportsManualControl(this.vm.deviceControl as DropletControllerParameter);
    } else if (this.vm.deviceControl instanceof DropletDeviceControl) {
      result = DropletDeviceControl.supportsManualControl(this.vm.deviceControl.type);
    }
    BrowserLogger.log('DeviceControl.supportsManualControl', { result });
    return result;
  }

  private setInputVisibility(): void {
    // can view inputs if no manual control support for control or manual control is enabled
    BrowserLogger.log('DeviceControl.setInputVisibility', { vm: this.vm });
    this.vm.enableInputs = !this.vm.enableManualControlToggle || this.vm.manualControlToggleValue === true;
  }

  handleManualControlToggle(): void {
    BrowserLogger.log('DeviceControl.handleManualControlToggle', {
      manualControlToggleValue: this.vm.manualControlToggleValue,
    });
    this.checkAndSetControlMode(this.vm.manualControlToggleValue);
    this.checkAndSetControllerParameter(this.vm.manualControlToggleValue);
    this.setInputVisibility();
    this.manualControlToggled.emit(this.vm.manualControlToggleValue);
  }

  private checkAndSetControlMode(manualControlToggle: boolean): void {
    BrowserLogger.log('DeviceControl.checkAndSetControlMode', { manualControlToggle });
    // track if we are requesting manual control
    if (this.supportsManualControl()) {
      this.vm.deviceControl.isManualControlRequested = manualControlToggle;
    }
    if (this.vm.enableMqttInputs) {
      // If disabling manual mode and going into auto mode then send the auto mode command.
      // If going into manual mode we only need to show controls (and not send a control value)
      // as the update of the manual setting will set into manual mode

      // set auto mode flag so update subscription can move toggle on success
      // or ensure we clear any auto mode flag when not sending
      // as a toggle to auto when the droplet is already auto wont actually get a subscription event back and hence wont clear it
      this._isSendingAutoMode = !manualControlToggle;

      // auto mode, send command
      if (this._isSendingAutoMode) {
        // submit the value
        let controlAutoModeValue = DropletDeviceControl.getAutoModeValue(this.vm.deviceControl.type);
        this.submitControlValue(controlAutoModeValue);
        // note: subscriber for deviceControlService.vm$ will determine if setting is successful
      }
    }
  }

  private checkAndSetControllerParameter(manualControlToggle: boolean): void {
    if (this.vm.deviceControl instanceof DropletControllerParameter) {
      // if controller parameter going into manual control, set any defaults
      if (manualControlToggle) {
        this.vm.deviceControl.value = this.vm.deviceControl.defaultValue;
      }
      // if controller parameter going out of manual control, clear any values
      else {
        this.vm.deviceControl.value = undefined;
      }
      BrowserLogger.log('DeviceControl.checkAndSetControllerParameter', { manualControlToggle, vm: this.vm });
      // check the Controller Param value
      this.checkControllerParamDeviceControlValue(this.vm.deviceControl.value);
      // trigger the Controller Param value change
      if (this.vm.deviceControl.isValueValid) {
        this.handleControllerParamDeviceControlValueChange();
      }
    }
  }

  private checkControlModeCompletion(deviceControlStatus: DeviceControlStatus): void {
    BrowserLogger.log('DeviceControl.checkControlModeCompletion', { deviceControlStatus });
    if (this._isSendingAutoMode) {
      if (deviceControlStatus.state === DeviceDetailMessageState.SUCCESS) {
        this._isSendingAutoMode = false;
        // ensure controls are in AUTO mode
        // note: need to wrap in a timeout so ui control gets updated on next digest cycle (without this it wont update the toggle control UI)
        setTimeout(() => {
          this.setManualControlMode(false);
        });
      } else if (deviceControlStatus.state === DeviceDetailMessageState.ERROR) {
        this._isSendingAutoMode = false;
        // ensure controls are in MANUAL CONTROL mode
        // note: need to wrap in a timeout so ui control gets updated on next digest cycle (without this it wont update the toggle control UI)
        setTimeout(() => {
          this.setManualControlMode(true);
        });
      }
    }
  }
}
