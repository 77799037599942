<ion-item class="switchdin-input" color="brand-primary" lines="none">
  <ion-input
    #swidin="ngModel"
    ngDefaultControl
    name="inputValue"
    [(ngModel)]="inputValue"
    [autofocus]="focus"
    [label]="label"
    labelPlacement="floating"
  ></ion-input>
  <div *ngIf="modelValid; then valid; else invalid"></div>
  <ng-template #valid>
    <ion-text color="brand-primary" slot="end" horizontal="end" class="ion-no-margin ion-text-end">
      <ion-icon name="checkmark-circle-outline"></ion-icon>
    </ion-text>
  </ng-template>
  <ng-template #invalid>
    <ion-icon #invalid color="brand-primary" slot="end" horizontal="end" name="alert"></ion-icon>
  </ng-template>
</ion-item>
