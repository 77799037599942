import { VppMarketGetPayload } from './vpp-markets.model';
import { Vpp } from './vpp-types';
import { isEmpty } from 'lodash';

export interface VppGetPayloadScada {
  device_id: string;
  device_name?: string;
  id?: string;
  unit_id: string;
  vpp_id?: string;
}

export interface VppGetPayload {
  admt_max_demand: number;
  admt_min_demand: number;
  description: string;
  id: string;
  name: string;
  reference_device: string | null;
  reference_metric: string | null;
  reference_metric_unit: string | null;
  reference_unit: string | null;
  num_devices: number;
  num_portfolios: number;
  num_units: number;
  permissions: [string, string][];
  scada: Array<VppGetPayloadScada>;
  users: Array<number>;
  uuid: string;
  threshold_type: VppThresholdType;
  spot_max_price: number;
  spot_min_price: number;
  market_region: VppMarketGetPayload;
}

export interface VppPostPutPayload {
  timezone: string;
  threshold_type: VppThresholdType;
  reference_device: string;
  reference_metric: string;
  reference_unit: string;
  name: string;
  description?: string;
  admt_max_demand?: number;
  admt_min_demand?: number;
  spot_max_price?: number;
  spot_min_price?: number;
  market_region?: string;
  scada?: { unit_id: string; device_id: string }[];
}

export type VppPatchPayload = Partial<VppPostPutPayload>;

export interface VppEditFormData {
  id: string;
  thresholdType: VppThresholdType;
  name: string;
  description?: string;
  scada?: { unitId: string; deviceId: string }[];
  permissions?: [string, string][];
  timezone: string;

  // Power threshold fields
  deviceMetricIdCombination?: string; // `${reference_device}_${reference_metric}`
  referenceDevice?: string;
  referenceMetric?: string;
  referenceUnit?: string;
  maxDemand?: number;
  minDemand?: number;

  // Price threshold fields
  maxPrice?: number;
  minPrice?: number;
  marketUuid?: string;
}

// The values has to be unique otherwise it'll break the FE
// we are using the values as keys as BackEnd is expecting 'Power' | 'Price' | 'Scada'
export enum VppThresholdType {
  POWER = 'Power',
  PRICE = 'Price',
  SCADA = 'Scada',
}

export const adaptVppsEditToPutPayload = (edit: VppEditFormData): VppPostPutPayload => {
  const {
    thresholdType,
    referenceDevice,
    referenceMetric,
    referenceUnit,
    name,
    description,
    maxDemand,
    minDemand,
    maxPrice,
    minPrice,
    marketUuid,
    scada,
    timezone,
  } = edit;

  const adaptedScada =
    scada?.map((takeOff) => {
      return { unit_id: takeOff.unitId, device_id: takeOff.deviceId };
    }) ?? [];

  const payload = {
    timezone,
    threshold_type: thresholdType,
    reference_device: referenceDevice,
    reference_metric: referenceMetric,
    reference_unit: referenceUnit,
    name,
    description,
    admt_max_demand: maxDemand,
    admt_min_demand: minDemand,
    spot_max_price: maxPrice,
    spot_min_price: minPrice,
    market_region: marketUuid,
    scada: adaptedScada,
  };
  return payload;
};

export const adaptVppGetPayloadToEditData = (payload: VppGetPayload): VppEditFormData => {
  const {
    admt_max_demand,
    admt_min_demand,
    description,
    name,
    reference_device,
    reference_metric,
    reference_unit,
    permissions,
    scada,
    threshold_type,
    spot_max_price,
    spot_min_price,
    market_region,
    id,
  } = payload;

  const formScada =
    scada?.map((takeoff) => {
      return {
        unitId: takeoff.unit_id,
        deviceId: takeoff.device_id,
      };
    }) ?? null;

  const edit: VppEditFormData = {
    id,
    timezone: null,
    thresholdType: threshold_type,
    referenceDevice: reference_device,
    referenceMetric: reference_metric,
    referenceUnit: reference_unit,
    name,
    description,
    maxDemand: admt_max_demand,
    minDemand: admt_min_demand,
    maxPrice: spot_max_price,
    minPrice: spot_min_price,
    marketUuid: market_region?.uuid,
    scada: formScada,
    permissions,
  };
  return edit;
};

export const adaptVppToEditData = (vpp: Vpp): VppEditFormData => {
  if (isEmpty(vpp)) return initialiseEmptyVppEditForm();
  const {
    timezone,
    thresholdType,
    referenceDevice,
    referenceMetric,
    referenceUnit,
    name,
    description,
    admtMinDemand,
    admtMaxDemand,
    maxPrice,
    minPrice,
    market,
    scada,
    permissions,
    id,
  } = vpp;

  const edit: VppEditFormData = {
    id,
    timezone,
    thresholdType,
    referenceDevice,
    referenceMetric,
    referenceUnit,
    name,
    description,
    maxDemand: admtMaxDemand,
    minDemand: admtMinDemand,
    maxPrice: maxPrice,
    minPrice: minPrice,
    marketUuid: market?.uuid,
    scada,
    permissions,
  };
  return edit;
};

export const initialiseEmptyVppEditForm = (): VppEditFormData => {
  return {
    timezone: null,
    thresholdType: null,
    referenceDevice: null,
    referenceMetric: null,
    referenceUnit: null,
    name: '',
    description: '',
    maxDemand: null,
    minDemand: null,
    maxPrice: null,
    minPrice: null,
    marketUuid: null,
    scada: [],
    permissions: [],
    id: null,
  };
};
