<ion-button
  (click)="handleClick()"
  color="button-primary"
  class="update-name-button-rounded"
  mode="ios"
  [disabled]="isDisabled"
>
  <span class="ml-15 fw-600 fs-14"> {{ 'General.Update' | translate }} </span>
  <ion-icon class="ml-7 mr-10" name="sync"></ion-icon>
</ion-button>
