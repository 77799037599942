<ion-card class="m-0 p-10 new-design-card">
  <ion-item>{{ 'VirtualPowerPlant.NEM.SpotPrices' | translate }}</ion-item>
  <div *ngIf="prices$ | async as prices">
    <div class="ml-5 mt-10">
      {{ 'VirtualPowerPlant.NEM.Interval' | translate }}: <span class="fw-600">{{ prices.interval }}</span>
    </div>
    <div class="prices-span">
      <div class="price-div">
        <div>
          {{ 'VirtualPowerPlant.Min' | translate }}
        </div>
        <div class="fw-600">${{ prices.min | number: '1.0-3' }}</div>
      </div>
      <div class="price-div">
        <div>
          {{ 'VirtualPowerPlant.Now' | translate }}
        </div>
        <div class="fw-600">${{ prices.current | number: '1.0-3' }}</div>
      </div>
      <div class="price-div">
        <div>
          {{ 'VirtualPowerPlant.Max' | translate }}
        </div>
        <div class="fw-600">${{ prices.max | number: '1.0-3' }}</div>
      </div>
    </div>
  </div>
</ion-card>
