import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProfileService } from '../../services/profile/profile.service';
import { UnitWeatherStateData, UnitWeatherStore } from '@service/units/unit-weather.store';
import { Observable, combineLatest, filter, map } from 'rxjs';
import { UnitCarbonSavingStore } from '@service/units/unit-carbon-saving.store';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeatherComponent {
  unitWeatherStore$: Observable<UnitWeatherStateData>;
  forecastKwh$: Observable<number>;
  weatherIcon$: Observable<string>;

  constructor(
    private _profileService: ProfileService, // TODO - Joe remove this when dark theme is added as backend option
    private _unitWeatherStore: UnitWeatherStore,
    private _unitCarbonSavingStore: UnitCarbonSavingStore,
  ) {
    this.unitWeatherStore$ = this._unitWeatherStore.unitWeatherStore$;
    this.forecastKwh$ = this._unitCarbonSavingStore.unitCarbonSavingStore$.pipe(
      filter((unitCarbonSaving) => unitCarbonSaving.data !== null && unitCarbonSaving.data !== undefined),
      map((unitCarbonSaving) => unitCarbonSaving.data.forecastKwh),
    );
    this.weatherIcon$ = combineLatest([this._unitWeatherStore.unitWeatherStore$, this._profileService.profile$]).pipe(
      filter(
        ([unitWeather, profile]) =>
          unitWeather.data !== null &&
          unitWeather.data !== undefined &&
          profile !== null &&
          profile !== undefined &&
          unitWeather.data.icon !== '',
      ),
      map(([unitWeather, profile]) => {
        const weatherIcon = unitWeather.data.icon;
        const darkThemeEnabled = profile.profile.dark_theme;
        const iconName = darkThemeEnabled ? weatherIcon + '-dark' : weatherIcon;
        return `assets/icons/svg/weather-icons/${iconName}.svg`;
      }),
    );
  }
}
