import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { States } from '@class/commons/constants';
import { Portfolio, PortfolioDTO } from '@class/commons/portfolio.model';
import { Unit } from '@class/commons/unit.model';
import { HTTP_ERRORS_TRANSLATION_HEADING, generateHttpErrorMessage } from '@class/error/http-error-types';
import { filterSuccessResult } from '@ngneat/query';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '@service/common/api-wrapper.service';
import { TranslationsService } from '@service/common/translations.service';
import { CollectionApiService } from 'app/api-services/collection/collection.api-service';
import { BehaviorSubject, Observable, shareReplay, map, startWith, tap, of, catchError } from 'rxjs';

interface CorePortfolioListState {
  status: States;
  error?: string;
  message?: string;
}
interface PortfolioListStateData extends CorePortfolioListState {
  data?: Portfolio[];
}
const PORTFOLIO_LIST_INITIAL_STATE: PortfolioListStateData = {
  status: States.INITIAL_LOADING,
};
interface DetachedUnitListStateData extends CorePortfolioListState {
  data?: Unit[];
}
const UNIT_DETACHED_LIST_INITIAL_STATE: DetachedUnitListStateData = {
  status: States.INITIAL_LOADING,
};

@Injectable({
  providedIn: 'root',
})
export class PortfolioListStore {
  private _portfolioListStore = new BehaviorSubject<PortfolioListStateData>(PORTFOLIO_LIST_INITIAL_STATE);
  portfolioList$ = this._portfolioListStore.asObservable();

  #collectionApiService = inject(CollectionApiService);

  detachedUnitList$: Observable<DetachedUnitListStateData> = this.getDetachedUnitList().pipe(
    shareReplay(),
    map((data) => {
      return {
        data: data.map((unit) => new Unit(unit)),
        status: States.INITIAL_DATA_RECEIVED,
      };
    }),
    startWith(UNIT_DETACHED_LIST_INITIAL_STATE),
  );

  constructor(
    private _api: ApiWrapper,
    private _translationsService: TranslationsService,
  ) {}

  addToPortfolioListStore(data: PortfolioDTO): void {
    if (
      this._portfolioListStore.value?.data &&
      !this._portfolioListStore.value.data.find((portfolio) => portfolio.id === data.id)
    ) {
      this._portfolioListStore.next({
        data: [PortfolioDTO.adaptToPortfolio(data), ...this._portfolioListStore.value.data],
        status: States.INITIAL_DATA_RECEIVED,
      });
    }
  }
  // updating portfolio data in the portfolio list
  updateDataOnPortfolioIndex(portfolioDataToUpdate: Portfolio): void {
    const portfolios = this._portfolioListStore.value.data;
    const index = portfolios.findIndex((portfolio) => portfolio.id === portfolioDataToUpdate.id);

    // updating portfolio in the list
    if (index >= 0) {
      portfolios[index] = portfolioDataToUpdate;
      this._portfolioListStore.next({
        data: [...portfolios],
        status: States.INITIAL_DATA_RECEIVED,
      });
    }
  }
  updatePortfolioListAfterPortfolioRemove(portfolio: Portfolio) {
    const portfolios = this._portfolioListStore.value.data;
    const index = portfolios.findIndex((portfolioData) => portfolioData.id === portfolio.id);

    if (index >= 0) {
      portfolios.splice(index, 1);
      this._portfolioListStore.next({
        data: [...portfolios],
        status: States.INITIAL_DATA_RECEIVED,
      });
    }
  }

  retryPortfolioList(): void {
    this.getPortfolioList();
  }
  getPortfolioList(): void {
    this.#collectionApiService
      .collectionsList()
      .result$.pipe(
        shareReplay(),
        filterSuccessResult(),
        map(({ data }) => {
          return {
            data: data.map((portfolios) => PortfolioDTO.adaptToPortfolio(portfolios as unknown as PortfolioDTO)),
            status: States.INITIAL_DATA_RECEIVED,
          };
        }),
        catchError((error) => this.handleError(error)),
        startWith(PORTFOLIO_LIST_INITIAL_STATE),
        tap((portfolioData) => {
          this._portfolioListStore.next(portfolioData);
        }),
      )
      .subscribe();
  }

  // Private Methods
  private getDetachedUnitList(): Observable<[]> {
    return this._api.handleObservableRequest({
      useAPI: AvailableAPI.SWITCHDIN,
      url: '/api/v1/units-excluding-portfolios/',
      requestMethod: RequestMethod.GET,
      useHeader: UseHeaderType.AUTHORIZED_SWDIN,
      requestData: {},
    }) as Observable<[]>;
  }
  private handleError(err: unknown, state: States = States.INITIAL_ERROR): Observable<CorePortfolioListState> {
    const message = generateHttpErrorMessage(
      err as HttpErrorResponse,
      this._translationsService.instant(HTTP_ERRORS_TRANSLATION_HEADING),
    );
    return of({
      status: state,
      error: message.message,
    });
  }
}
