import { Component, Input } from '@angular/core';
import { BrowserLogger } from '@class/core/browser-logger';
import { Observable } from 'rxjs';
import { MqttStatusService, MqttStatusState, StatusFromMqtt } from '../../services/common/mqtt-status.service';

@Component({
  selector: 'app-mqtt-status',
  templateUrl: './mqtt-status.component.html',
  styleUrls: ['./mqtt-status.component.scss'],
  providers: [MqttStatusService],
})
export class MqttStatusComponent {
  @Input()
  set mqttKeys(value: { mqttKeys: string[]; dropletUuid: string }) {
    BrowserLogger.log('MqttStatus.mqttKeys', { value });
    this.mqttStatusService.setMqttKeysToWatch(value.mqttKeys, value.dropletUuid);
  }
  @Input()
  set initialLastSeen(value: string) {
    BrowserLogger.log('MqttStatus.initialLastSeen', { value });
    this.mqttStatusService.setInitialLastSeen(value);
  }
  public readonly StatusFromMqtt = StatusFromMqtt;
  vm$: Observable<MqttStatusState> = this.mqttStatusService.vm$;

  constructor(private mqttStatusService: MqttStatusService) {}
}
