import { Component, Input } from '@angular/core';
import { CustomButtons } from '../../../classes/commons/custom-buttons';

@Component({
  selector: 'app-create-rect-button',
  templateUrl: './create-rect-button.component.html',
  styleUrls: ['./create-rect-button.component.scss'],
})
export class CreateRectButtonComponent extends CustomButtons {
  @Input() showIcon: boolean;
}
