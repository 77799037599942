<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{"General.InviteUser.Title" | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid>
    <ion-row class="fh-row">
      <ion-row>
        <ion-col>
          <ion-list>
            <ion-list-header>
              <h4>{{"General.UserDetails" | translate}}</h4>
            </ion-list-header>

            <ion-item class="ion-no-margin ion-no-padding sub-text" lines="none">
              <ion-text class="ion-padding-top ion-padding-bottom">
                {{textMessages[type].description | translate}}
              </ion-text>
            </ion-item>
            <ion-item class="input-item ion-margin-bottom" lines="none">
              <ion-input
                [(ngModel)]="User.name"
                label="{{'General.Name' | translate}}*"
                labelPlacement="floating"
              ></ion-input>
            </ion-item>
            <ion-item class="input-item ion-margin-bottom" lines="none">
              <ion-input
                [(ngModel)]="User.email"
                type="email"
                label="{{'General.Email' | translate}}*"
                labelPlacement="floating"
              ></ion-input>
            </ion-item>
            <ion-item class="input-item ion-margin-bottom" lines="none">
              <ion-select
                class="max-width-100-pct"
                [interfaceOptions]="customAlertOptions"
                [(ngModel)]="User.role"
                label="{{'General.AccessLevel' | translate }}*"
                labelPlacement="floating"
                interface="popover"
              >
                <ion-select-option *ngFor="let role of roles" value="{{role.value}}"> {{role.name}} </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item
              *ngIf="profileService.getPersistedUserProfile().is_staff"
              class="input-item ion-margin-bottom"
              lines="none"
            >
              <ion-select
                class="max-width-100-pct"
                [interfaceOptions]="customAlertOptions"
                [(ngModel)]="User.referer_browser_skin"
                label="{{'General.ProfileTheme' | translate }}*"
                labelPlacement="floating"
                interface="popover"
              >
                <ion-select-option *ngFor="let theme of THEMES" value="{{theme.value}}">
                  {{ 'Themes.' + theme.name | translate }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-self-end">
        <ion-col>
          <ion-button
            [attr.disabled]='(User.name === "" || User.email === "" || User.role == null || User.referer_browser_skin == null ) ? true : null'
            color="button-primary"
            size="large"
            expand="block"
            (click)="inviteUser(User)"
          >
            {{"General.InviteUser.Title" | translate }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-row>
  </ion-grid>
</ion-content>
