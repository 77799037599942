import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationsService } from '../../services/common/translations.service';
import { VppTimezoneService } from '../../services/virtualPowerPlants/timezone/vpp-timezone.service';

@Pipe({
  name: 'vppTimezoneLocale',
})
export class VppTimezoneLocalePipe implements PipeTransform {
  constructor(
    private timezoneService: VppTimezoneService,
    private translationService: TranslationsService,
  ) {}

  transform(date: Date | number, opt?: any): Observable<string> {
    if (date == null) return null;
    const dateTime = date instanceof Date ? DateTime.fromJSDate(date) : DateTime.fromSeconds(date);
    return this.timezoneService.timezone$.pipe(
      map((tz) => dateTime.setLocale(this.translationService.getBrowserCultureLang()).setZone(tz)),
      map((dt) => (dt.isValid ? (opt === 'ISO' ? dt.toISO() : dt.toLocaleString(opt)) : dt.invalidReason)),
    );
  }
}
