<ion-row class="min-height-200">
  <ion-col>
    <div [ngClass]="{ loader: loading }"></div>
    <div #portfolioOverviewChart [ngClass]="{ 'opac-chart': loading }"></div>
    <span class="portfolio-chart-icons" *ngIf="hasData">
      <div *ngFor="let button of graphButtons" class="tooltip">
        <span class="tooltiptext">{{ button.label | translate }}</span>
        <ion-button
          class="cursor-pointer"
          fill="clear"
          color="button-primary"
          (click)="barDataIconClick(button.metric, button.order)"
          [disabled]="loading"
        >
          <ion-icon
            [ngClass]="{ 'icon-inactive': chartIndex !== button.order }"
            [name]="button.icon"
            size="small"
          ></ion-icon>
        </ion-button>
      </div>
    </span>
  </ion-col>
</ion-row>
