// A simple app event service bus - used to emit app events rather than pass through component tree.
// Purpose is to gradually convert existing app events into this service for potential replacement by NgRx.
// References:
//   https://blog.codewithdan.com/ng-conf-talk-mastering-the-subject-communication-options-in-rxjs/
//   https://www.youtube.com/watch?v=_q-HL9YX_pk
//   https://github.com/danwahlin/angular-architecture
//   https://github.com/DanWahlin/angular-architecture/blob/11f5cf671f543071c93aff777b7d3447bbb5618d/demos/src/app/core/services/event-bus.service.ts
// Usage:
//   Raise:      this._eventBus.emit(new EmitEvent(Event.XXXX, data));
//   Subscribe:  this._eventBus.on(Event.XXX).subscribe((data) => { handleEvent(data); });
import { Injectable } from '@angular/core';
import { BrowserLogger } from '@class/core/browser-logger';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// add events as required
export enum Event {
  CONTROLLER_INVENTORY_STATE = 1,
  CONTROLLER_ADD,
  CONTROLLER_UPDATE,
  CONTROLLER_ADDED,
  CONTROLLER_REMOVED,
  CONTROLLER_UPDATED,
  DEVICE_REMOVED,
  DEVICE_UPDATED,
  DROPLETS_LOADED,
  DISPLAYED_DEVICE,
  DISPLAYED_CONTROLLER,
  MODAL_CLOSED,
  PERMISSIONS_APPLIED,
}

export class EmitEvent {
  constructor(
    public id: Event,
    public value?: any,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class EventBusService {
  private _subject = new Subject();

  on(event: Event): Observable<any> {
    return this._subject.asObservable().pipe(
      filter((e: EmitEvent) => e.id === event),
      map((e: EmitEvent) => e.value),
    );
  }

  emit(event: EmitEvent) {
    BrowserLogger.log('EventBusService.emit', { id: event.id, data: event.value });
    this._subject.next(event);
  }
}
