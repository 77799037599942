<ion-list>
  <ion-item lines="none">
    <ion-text>
      <h2>{{ 'VirtualPowerPlant.Policies' | translate }}</h2>
      <p>{{ 'VirtualPowerPlant.SelectPolicy' | translate }}</p>
    </ion-text>
  </ion-item>
  <div *ngFor="let policy of policies">
    <ion-item>
      <ion-label
        class="cursor-pointer ion-text-wrap"
        [ngClass]="{ backgroudSelected: policy.toggle }"
        (click)="policySelectionChange(policy)"
      >
        <ion-text color="medium">
          <h1>{{ policy.name }}</h1>
          <!-- <h4>{{ "VirtualPowerPlant.ReviewPeriod" | translate }}: <strong class="pr-5em">{{policy.review_period}} {{policy.review_period_unit}}</strong> </h4> -->
        </ion-text>
      </ion-label>
      <!-- <ion-button (click)="presentAlertConfirmGroup(group)" color="danger" fill="outline" slot="end" >
        <ion-icon slot="icon-only" name="remove"></ion-icon>
      </ion-button> -->
      <ion-button
        *ifHasPermission="[PermissionKey.VPP_CHANGE_POLICYATTRIBUTEOPTION]"
        [disabled]="policy.name == 'Ranking'"
        class="ml-5"
        color="button-primary"
        fill="outline"
        slot="end"
        (click)="policySelectionChange(policy, true)"
      >
        <ion-icon slot="icon-only" name="create"></ion-icon>
      </ion-button>
    </ion-item>
  </div>
</ion-list>
