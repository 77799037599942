import { Component, EventEmitter, Input, Output } from '@angular/core';
import { States } from '@class/commons/constants';
import { AppAssets, SvgIconNames } from '@theme/theme-map';
import { SkeletonPlaceholder } from '@class/commons/constants';

@Component({
  selector: 'app-state-wrapper',
  templateUrl: './state-wrapper.component.html',
  styleUrls: ['./state-wrapper.component.scss'],
})
export class StateWrapperComponent {
  @Input() stateType: States;
  @Input() errorMsg: string;
  @Input() successMsg: string;
  @Input() showNoData = false;
  @Input() showNoDataMsg = `No data found`;
  @Output() onRetry = new EventEmitter();
  @Output() onErrorClose = new EventEmitter();

  readonly States = States;
  readonly FAILED_MESSAGE_URL = `${AppAssets.ICONS_SVG_LIGHT}${SvgIconNames.FAILED_MESSAGE}`;
  readonly SkeletonPlaceholder = SkeletonPlaceholder;

  errorFalse = { showError: false };
  errorTrue = { showError: true };

  handleFormErrorClose() {
    this.onErrorClose.emit();
  }

  handleRetryClick() {
    this.onRetry.emit();
  }
}
