<ion-card class="card-design" *ngIf="(vm$ | async) as vm">
  <div [ngSwitch]="vm.status">
    <div *ngSwitchCase="VppModelStatus.LOADING" class="icon-text-wrapper p-20 m-30">
      <ion-icon class="status-icon pulsate-fwd" src="assets/icons/svg/select-device.svg"></ion-icon>
      <div class="fs-30 text-medium ion-text-center mt-10">{{ 'UnitPage.PleaseWait' | translate }}</div>
      <div class="fs-20 text-medium ion-text-center">{{vm.message | translate}}</div>
    </div>
    <div *ngSwitchCase="VppModelStatus.DATA">
      <ng-container *ngIf="vm.data as data">
        <div *ngIf="!isCancelDispatch" class="fs-22 ml-15 mt-26 text-dark mb-16 fw-500">
          {{'VirtualPowerPlant.ScheduleDispatch' | translate}}
        </div>
        <div *ngIf="isCancelDispatch" class="fs-22 ml-15 mt-26 text-dark mb-16 fw-500">
          {{'VirtualPowerPlant.StopDispatchTitle' | translate}}
        </div>
        <div class="bb-1 width-100-pct mt-15"></div>
        <ion-grid>
          <ion-row>
            <ion-col size="6" *ngIf="data.controlGroupName">
              <div class="field-label">{{"VirtualPowerPlant.ControlGroup" | translate}}</div>
              <div class="field-value">{{data.controlGroupName}}</div>
            </ion-col>
            <ion-col size="6" *ngIf="data.description">
              <div class="field-label">{{"VirtualPowerPlant.ControlDescription" | translate}}</div>
              <div class="field-value">{{data.description}}</div>
            </ion-col>
            <ion-col size="6" *ngIf="data.demandType">
              <div class="field-label">{{"VirtualPowerPlant.DemandType" | translate}}</div>
              <div class="field-value">{{data.demandType}}</div>
            </ion-col>
            <ion-col size="6" *ngIf="data.requestTime">
              <div class="field-label">{{"VirtualPowerPlant.RequestTime" | translate}}</div>
              <div class="field-value">{{data.requestTime}}</div>
            </ion-col>
            <ion-col size="6" *ngIf="data.deviceClass">
              <div class="field-label">{{"VirtualPowerPlant.DeviceClass" | translate}}</div>
              <div class="field-value">{{data.deviceClass}}</div>
            </ion-col>
            <ion-col size="6" *ngIf="isDRM && data.meritOrder">
              <div class="field-label">{{"VirtualPowerPlant.Merit" | translate}}</div>
              <div class="field-value">{{data.meritOrder}}</div>
            </ion-col>
            <ion-col size="6" *ngIf="isDateReadOnly">
              <div class="field-label">{{"VirtualPowerPlant.BeginDateTime" | translate}}</div>
              <div class="field-value">{{data.startTime | vppTimezoneLocale: LOCALE_OPTION | async}}</div>
            </ion-col>
            <ion-col size="6" *ngIf="isDateReadOnly">
              <div class="field-label">{{"VirtualPowerPlant.EndDateTime" | translate}}</div>
              <div class="field-value">{{data.endTime | vppTimezoneLocale: LOCALE_OPTION | async}}</div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="bb-1 width-100-pct mt-15"></div>

        <ng-container *ngIf="isFCAS && !isCancelDispatch">
          <div class="field-label">{{ 'VirtualPowerPlant.Markets' | translate }}</div>
          <div class="input-wrapper" *ngIf="(data?.fcasMarkets?.options$ | async ) as markets; else loading">
            <ion-select
              multiple="true"
              [placeholder]='"VirtualPowerPlant.SelectMulti" | translate'
              [(ngModel)]="data.fcasMarkets.selected"
              (ionChange)="onFcasMarketChange($event?.detail?.value)"
              [compareWith]="fcasMarketCompareFunc"
            >
              <ion-select-option *ngFor="let market of markets" [value]="market">{{market.name}}</ion-select-option>
            </ion-select>
          </div>
          <ng-template #loading>
            <div class="mt-5 ml-15">
              <ion-spinner name="crescent"></ion-spinner>
            </div>
          </ng-template>
          <div class="bb-1 width-100-pct mt-15"></div>
        </ng-container>

        <!-- Control Value (FCAS does not require manual input, will get set when markets selected)-->
        <ion-item lines="full" *ngIf="data.controlType && !isFCAS">
          <ion-label>
            {{ "VirtualPowerPlant.ControlValue" | translate }}
            <ion-text color="danger">*</ion-text>
          </ion-label>
          <ion-toggle
            name="controlType"
            *ngIf="data.controlType === MeritControlValueType.BOOLEAN"
            [(ngModel)]="data.controlValue"
            (ionChange)="onControlValueToggleChange(data.controlValue)"
            required
            slot="end"
            color="button-secondary"
            labelPlacement="start"
            justify="space-between"
          >
            {{ 'VirtualPowerPlant.ControlValue' | translate }}
          </ion-toggle>
          <ion-input
            name="controlType"
            class="ion-text-right"
            *ngIf="data.controlType === MeritControlValueType.INT || data.controlType === MeritControlValueType.FLOAT"
            [(ngModel)]="data.controlValue"
            (ionChange)="onControlValueNumericStringChange(data.controlValue)"
            type="number"
            required
            label="{{ 'VirtualPowerPlant.ControlValue' | translate }}"
            labelPlacement="floating"
          ></ion-input>
          <ion-input
            name="controlType"
            *ngIf="data.controlType === MeritControlValueType.STRING || data.controlType === MeritControlValueType.TEXT"
            class="ion-text-right"
            [(ngModel)]="data.controlValue"
            (ionChange)="onControlValueNumericStringChange($event?.detail?.value)"
            type="text"
            required
            label="{{ 'VirtualPowerPlant.ControlValue' | translate }}"
            labelPlacement="floating"
          ></ion-input>
        </ion-item>

        <ion-item lines="full">
          <ion-input
            name="controlType"
            class="ion-text-right"
            [(ngModel)]="data.controlPriority"
            (ionChange)="onControlPriorityChange(data.controlPriority)"
            type="number"
            required
            label="{{ 'VirtualPowerPlant.ControlPriority' | translate }}"
            labelPlacement="floating"
          ></ion-input>
        </ion-item>

        <ng-container *ngIf="!isDateReadOnly">
          <div class="begin-date-row">
            <div class="field-label">
              {{"VirtualPowerPlant.BeginDateTime" | translate}} ({{"VirtualPowerPlant.Timezone" | translate}}:
              <span class="text-dark">{{data.timezone}})</span>
            </div>
            <div class="dispatch-now-text">{{"VirtualPowerPlant.DispatchNow" | translate}}</div>
            <div class="mr-15 mt-15">
              <ion-checkbox
                slot="start"
                [(ngModel)]="dispatchNow"
                (ionChange)="onStartDateNowChange($event?.detail?.checked, data.timezone)"
                color="button-secondary"
              ></ion-checkbox>
            </div>
          </div>
          <div class="ml-15 mr-15">
            <ion-datetime-button datetime="start-time"></ion-datetime-button>

            <ion-popover [keepContentsMounted]="true">
              <ng-template>
                <ion-datetime
                  id="start-time"
                  [disabled]="dispatchNow"
                  [min]="data.startMinTime"
                  [minuteValues]="MINUTE_ARRAY"
                  hourCycle="h23"
                  [value]="data.startTime | vppTimezoneLocale:'ISO' | async"
                  (ionChange)="onStartDateChange($event?.detail?.value, data.timezone)"
                  color="brand-primary"
                  [showDefaultButtons]="true"
                ></ion-datetime>
              </ng-template>
            </ion-popover>
          </div>
          <div class="bb-1 width-100-pct mt-15"></div>
          <div class="field-label">
            {{"VirtualPowerPlant.EndDateTime" | translate}} ({{"VirtualPowerPlant.Timezone" | translate}}:
            <span class="text-dark">{{data.timezone}})</span>
          </div>
          <!--
             see vpp-operation-schedule-modal-facade.service.ts for explanation why we using this
           -->
          <div class="ml-15 mr-15" *ngIf="data.showEndTimeIonDateTimePicker">
            <ion-datetime-button datetime="end-time" *ngIf="data.showEndTimeIonDateTimePicker"></ion-datetime-button>

            <ion-popover [keepContentsMounted]="true">
              <ng-template>
                <ion-datetime
                  id="end-time"
                  [disabled]="!data.startTime"
                  [min]="data.endMinTime"
                  [minuteValues]="MINUTE_ARRAY"
                  [value]="data.endTime | vppTimezoneLocale:'ISO' | async"
                  (ionChange)="onEndDateChange($event?.detail?.value, data.timezone)"
                  hourCycle="h23"
                  color="brand-primary"
                  [showDefaultButtons]="true"
                ></ion-datetime>
              </ng-template>
            </ion-popover>
          </div>
          <div class="bb-1 width-100-pct mt-15"></div>
        </ng-container>

        <div class="m-0 button-row">
          <ion-button fill="outline" color="button-primary" mode="ios" class="m-0 cancel-button" (click)="dismiss()">
            {{ 'General.Cancel' | translate }}
          </ion-button>
          <ion-button
            [attr.disabled]="isScheduleButtonDisable(data)"
            mode="ios"
            color="button-primary"
            class="m-0 dispatch-button"
            (click)="scheduleDispatch()"
            *ngIf="!isCancelDispatch"
          >
            {{"General.Confirm" | translate }}
          </ion-button>
          <ion-button
            mode="ios"
            color="button-primary"
            class="m-0 dispatch-button"
            (click)="cancelDispatch()"
            *ngIf="isCancelDispatch"
          >
            {{"General.Confirm" | translate }}
          </ion-button>
        </div>
      </ng-container>
    </div>
    <div *ngSwitchCase="VppModelStatus.ERROR" class="icon-text-wrapper p-20">
      <ion-icon class="error-icon" src="assets/icons/svg/warning.svg"></ion-icon>
      <div class="fs-30 text-medium ion-text-center mt-10">
        {{isCancelDispatch ? ('VirtualPowerPlant.DispatchCanceledFail'| translate ):('VirtualPowerPlant.DispatchFail'|
        translate ) }}
      </div>
      <div class="fs-20 text-medium ion-text-center">{{vm.message}}</div>
      <div class="width-100-pct mt-20">
        <ion-button fill="outline" color="button-primary" mode="ios" class="width-100-pct m-0" (click)="dismiss()">
          {{'General.OK' | translate}}
        </ion-button>
      </div>
    </div>
    <div *ngSwitchCase="VppModelStatus.COMPLETE" class="icon-text-wrapper p-20 m-30">
      <ion-icon class="status-icon text-brand-primary" name="checkmark-circle"></ion-icon>
      <div class="fs-30 text-medium ion-text-center mt-10">{{vm.message | translate}}</div>
    </div>
  </div>
</ion-card>
