<ion-list>
  <ion-item lines="none">
    <ion-text>
      <h2 *ngIf="selectedPolicy != null && !selectedPolicy.edit">{{ 'VirtualPowerPlant.PolicyDetail' | translate }}</h2>
      <h2 *ngIf="selectedPolicy != null && selectedPolicy.edit">{{ 'VirtualPowerPlant.UpdatePolicy' | translate }}</h2>
    </ion-text>
  </ion-item>
</ion-list>
<div *ngIf="selectedPolicy != null && selectedPolicy.toggle">
  <div *ngIf="!selectedPolicy.edit">
    <!-- <ion-row>
      <ion-col *ngFor="let attribute of selectedPolicy.attributes" size="12" size-md="6">
        <ion-item>
          <ion-label class="ion-text-wrap">
            <ion-text color="medium">
              <h1>{{ attribute.name }}</h1>
              <h4>{{ "VirtualPowerPlant.Type" | translate }}: <strong class="pr-5em">{{attribute.attr_type}}</strong>
                  {{ "VirtualPowerPlant.Unit" | translate }}: <strong>{{attribute.attr_unit}}</strong>
              </h4>
              <div class="ion-padding-start ion-padding-top">
                <h3>
                  {{ "VirtualPowerPlant.Options" | translate }}:
                </h3>
                <p *ngFor="let option of attribute.options" >
                  {{ "General.Name" | translate }} : <strong class="pr-5em">{{option.name}}</strong>
                  {{ "General.Value" | translate }} : <strong class="pr-5em">{{option.value}}</strong>
                  {{ "VirtualPowerPlant.DeviceControl" | translate }} : <strong class="pr-5em">{{option.device_type_name}} {{option.device_control_name}} {{option.device_control_description}}</strong>
                </p>
              </div>
            </ion-text>
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row> -->
    <ion-row>
      <ion-col *ngFor="let attribute of selectedPolicy.attributes" size="12" size-md="6" class="ion-padding-start">
        <div class="bb-1 ion-padding-bottom">
          <div>
            <h1 class="ion-no-margin">{{ attribute.name }}</h1>
            <div *ngFor="let device of serviceObj.vppDeviceTypesList.devicesList">
              <h4>{{ device.name }}</h4>
              <div *ngFor="let option of attribute.options">
                <div class="ion-margin-start" *ngIf="option.device_type_name == device.name && option.value != null">
                  {{ option.device_control_name }} <strong> {{ option.value }} {{ attribute.attr_unit }} </strong> ({{
                    option.device_control_description
                  }})
                </div>
                <div class="ion-margin-start" *ngIf="option.device_type_name == device.name && option.value == null">
                  {{ option.device_control_name }} ({{ option.device_control_description }})
                  <strong>{{ 'VirtualPowerPlant.ValueNullOrNotSet' | translate }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </div>
  <div *ngIf="selectedPolicy.edit">
    <!-- <ion-item lines="none">
      <ion-label slot="start" >
        {{ "VirtualPowerPlant.ReviewPeriod" | translate }}:
      </ion-label>
      <ion-input class="inputBorder" slot="start" type="number" [(ngModel)]="policyToEdit.review_period" ></ion-input>
      <ion-label>
        {{ policyToEdit.review_period_unit }}
      </ion-label>
    </ion-item> -->
    <!-- <div *ngFor="let attribute of policyToEdit.attributes">
      <ion-item lines="none">
        <h1> {{attribute.name}} </h1>
      </ion-item>
      <div *ngFor="let option of attribute.options" >
        <ion-item class="ion-padding-bottom">
          <ion-input class="inputBorder" slot="start" type="number" [(ngModel)]="option.value" ></ion-input>
          <ion-label class="ion-text-wrap max-width-100-pct" >
            {{ attribute.attr_unit }} {{ "VirtualPowerPlant.ForDeviceControl" | translate }} <strong>{{option.device_type_name}} {{option.device_control_name}} {{option.device_control_description}}</strong>
          </ion-label>
        </ion-item>
      </div>
    </div> -->
    <ion-row>
      <ion-col *ngFor="let attribute of policyToEdit.attributes" size="12" size-md="6" class="ion-padding-start">
        <div class="bb-1 ion-padding-bottom">
          <div>
            <h1 class="ion-no-margin">{{ attribute.name }}</h1>
            <div *ngFor="let device of serviceObj.vppDeviceTypesList.devicesList">
              <h4>{{ device.name }}</h4>
              <div *ngFor="let option of attribute.options">
                <ion-item *ngIf="option.device_type_name == device.name">
                  <ion-input
                    *ngIf="attribute.attr_unit == 'minutes'"
                    type="number"
                    min="0"
                    [step]="timeInterval"
                    [(ngModel)]="option.value"
                    label="{{ option.device_control_name }} ({{ option.device_control_description }}) {{
                      attribute.attr_unit
                    }}"
                    labelPlacement="floating"
                  ></ion-input>
                  <ion-input
                    *ngIf="attribute.attr_unit != 'minutes'"
                    type="number"
                    min="0"
                    [(ngModel)]="option.value"
                  ></ion-input>
                </ion-item>
                <!-- <div style="display: flex;" class="ion-margin-start" *ngIf="option.device_type_name == device">
                  {{option.device_control_name}} <ion-input class="inputBorder" slot="start" type="number" [(ngModel)]="option.value" ></ion-input> <strong> {{attribute.attr_unit}} </strong> ({{option.device_control_description}})
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <ion-item lines="none">
      <ion-button color="button-primary" fill="outline" slot="end" (click)="updatePolicy()">
        <ion-icon slot="icon-only" name="save"></ion-icon>
      </ion-button>
      <ion-button style="margin-left: 5px" color="danger" fill="outline" slot="end" (click)="cancel()">
        <ion-icon slot="icon-only" name="remove"></ion-icon>
      </ion-button>
    </ion-item>
  </div>
  <!-- <div *ngIf="selectedPolicy.name == 'Ranking'">
    <ion-item *ngFor="let attribute of selectedPolicy.attributes">
      <ion-label class="ion-text-wrap">
        <ion-text color="medium">
          <h1>{{ attribute.name }}</h1>
          <h4>{{ "VirtualPowerPlant.Type" | translate }}: <strong class="pr-5em">{{attribute.attr_type}}</strong>
              {{ "VirtualPowerPlant.Unit" | translate }}: <strong>{{attribute.attr_unit}}</strong>
          </h4>
          <div class="ion-padding-start ion-padding-top">
            <h3>
              {{ "VirtualPowerPlant.Options" | translate }}:
            </h3>
            <p *ngFor="let option of attribute.options" >
              {{ "General.Name" | translate }} : <strong class="pr-5em">{{option.name}}</strong>
              {{ "General.Value" | translate }} : <strong class="pr-5em">{{option.value}}</strong>
            </p>
          </div>
        </ion-text>
      </ion-label>
    </ion-item>
  </div>
  <div *ngIf="selectedPolicy.name == 'Staging'">

  </div> -->
</div>
