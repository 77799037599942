import { Component, OnInit } from '@angular/core';
import { SkeletonPlaceholder } from '@class/commons/constants';
import { AlertController, ModalController } from '@ionic/angular';
import { apiStatus } from '../../../classes/commons/common';
import { TranslationsService } from '../../../services/common/translations.service';
import { UnitsService } from '../../../services/units/units.service';

export interface CloudDeviceOnboardingField {
  readonly label: string;
  readonly name: string;
  readonly is_serial_number: boolean;
  readonly input_patterns: string[];
  readonly requires_field: boolean;
}
interface BackendCloudDeviceType {
  id: string;
  uuid: string;
  model_name: string;
  model_number: number;
  default_slave_id: number;
  manufacturer_name: string;
  logical_device_type_name: string;
  logical_device_type: string;
  logical_device_type_short_name: string;
  logical_device_type_memory_map_type: string;
  protocol: string;
  droplet_driver: string;
  generic_device_type: string;
  device_type_connection_attributes: object;
  device_type_attributes: object;
  approved: boolean;
  logical_device_type_image: string | null;
  qr_code_available: boolean;
  onboarding_information: CloudDeviceOnboardingField[];
  display_name: string;
}
export interface CloudDeviceType {
  id: string;
  uuid: string;
  modelName: string;
  modelNumber: number;
  defaultSlaveId: number;
  manufacturerName: string;
  logicalDeviceTypeName: string;
  logicalDeviceType: string;
  logicalDeviceTypeShortName: string;
  logicalDeviceTypeMemoryMapType: string;
  protocol: string;
  dropletDriver: string;
  genericDeviceType: string;
  deviceTypeConnectionAttributes: object;
  deviceTypeAttributes: object;
  approved: boolean;
  logicalDeviceTypeImage: string | null;
  qrCodeAvailable: boolean;
  onboardingInformation: CloudDeviceOnboardingField[];
  displayName: string;
}

@Component({
  selector: 'app-cloud-device-list',
  templateUrl: './cloud-device-list.component.html',
  styleUrls: ['./cloud-device-list.component.scss'],
})
export class CloudDeviceListComponent implements OnInit {
  deviceTypes: { status: apiStatus; deviceTypesList: CloudDeviceType[] } = {
    status: new apiStatus(),
    deviceTypesList: [],
  };
  readonly SkeletonPlaceholder = SkeletonPlaceholder;
  constructor(
    private unitsService: UnitsService,
    private trans: TranslationsService,
    private alertController: AlertController,
    private modalController: ModalController,
  ) {}

  ngOnInit(): void {
    this.initCloudDeviceTypeList();
  }

  async initCloudDeviceTypeList(): Promise<void> {
    this.deviceTypes.status.reset();
    try {
      const response: { data: Array<BackendCloudDeviceType> } = await this.unitsService.getCloudDeviceTypes();
      this.deviceTypes.status.$callMade = true;
      if (response.data) {
        this.deviceTypes.deviceTypesList = response.data.map((deviceType: BackendCloudDeviceType) =>
          this.adaptDeviceType(deviceType),
        );
        this.deviceTypes.status.$dataExist = true;
      } else {
        this.deviceTypes.deviceTypesList = [];
        this.deviceTypes.status.$dataExist = false;
      }
      this.deviceTypes.status.$callMade = true;
    } catch (error) {
      this.deviceTypes.deviceTypesList = [];
      this.deviceTypes.status.$dataExist = false;
      this.deviceTypes.status.$callFailed = true;
      this.deviceTypes.status.$error = error;
    }
  }

  async presentAlertConfirmationManualDevice(deviceType: CloudDeviceType): Promise<void> {
    const alert = await this.alertController.create({
      header: this.trans.instant('AddDevice.AddCloudDeviceAlert.Header'),
      subHeader: this.trans.instant('AddDevice.AddCloudDeviceAlert.Subheader'),
      message: deviceType.logicalDeviceTypeName,
      buttons: [
        {
          text: this.trans.instant('General.No'),
        },
        {
          text: this.trans.instant('General.Yes'),
          handler: () => {
            this.modalController.dismiss(deviceType);
          },
        },
      ],
    });
    await alert.present();
  }

  async closeModal(): Promise<void> {
    await this.modalController.dismiss();
  }

  adaptDeviceType(model: BackendCloudDeviceType): CloudDeviceType {
    const adaptedModel: CloudDeviceType = {
      id: model.id,
      uuid: model.uuid,
      modelName: model.model_name,
      modelNumber: model.model_number,
      defaultSlaveId: model.default_slave_id,
      manufacturerName: model.manufacturer_name,
      logicalDeviceTypeName: model.logical_device_type_name,
      logicalDeviceType: model.logical_device_type,
      logicalDeviceTypeShortName: model.logical_device_type_short_name,
      logicalDeviceTypeMemoryMapType: model.logical_device_type_memory_map_type,
      protocol: model.protocol,
      dropletDriver: model.droplet_driver,
      genericDeviceType: model.generic_device_type,
      deviceTypeConnectionAttributes: model.device_type_connection_attributes,
      deviceTypeAttributes: model.device_type_attributes,
      approved: model.approved,
      logicalDeviceTypeImage: model.logical_device_type_image,
      qrCodeAvailable: model.qr_code_available,
      onboardingInformation: model.onboarding_information,
      displayName: model.display_name,
    };
    return adaptedModel;
  }
}
