<!-- TODO: Convert to Reactive Form -->
<ion-card class="card-design" *ngIf="formData">
  <ion-row>
    <ion-col>
      <ion-item lines="none" class="pl-0">
        <ion-label class="fs-22 ml-15 mt-26 text-dark mb-16 fw-500">
          {{ titleText }}
        </ion-label>
        <ng-container *ngIf="editVpp">
          <app-remove-button
            slot="end"
            *ifHasPermission="[PermissionKey.VPP_DELETE_VPP]"
            (onClick)="presentAlertConfirm(formData)"
          ></app-remove-button>
        </ng-container>
      </ion-item>
    </ion-col>
  </ion-row>

  <div class="bb-1 width-100-pct"></div>
  <div class="fs-13 fw-500 mt-15 ml-15">{{ 'VirtualPowerPlant.CreateVPP.Name' | translate }}*</div>
  <div class="input-wrapper">
    <ion-input
      [(ngModel)]="formData.name"
      class="ion-padding-start"
      [autofocus]="true"
      *ifHasPermission="[formPermissionToCheck]; disableOnFail: true"
    ></ion-input>
  </div>
  <div class="bb-1 width-100-pct"></div>
  <div class="fs-13 fw-500 mt-15 ml-15">
    {{ 'VirtualPowerPlant.CreateVPP.Description' | translate }}
  </div>
  <div class="input-wrapper">
    <ion-input
      class="ion-padding-start"
      [(ngModel)]="formData.description"
      *ifHasPermission="[formPermissionToCheck]; disableOnFail: true"
    ></ion-input>
  </div>
  <div class="bb-1 width-100-pct"></div>
  <div class="fs-13 fw-500 mt-15 ml-15">{{ 'VirtualPowerPlant.CreateVPP.Timezone' | translate }}*</div>
  <div class="input-wrapper" *ngIf="!loadingTimezones; else loading">
    <ion-input
      class="ion-padding-start"
      [(ngModel)]="formData.timezone"
      readonly
      (click)="presentTimezoneSelect()"
      *ifHasPermission="[formPermissionToCheck]; disableOnFail: true"
    ></ion-input>
  </div>
  <ng-template #loading>
    <div class="mt-5 ml-15">
      <ion-spinner name="crescent"></ion-spinner>
    </div>
  </ng-template>

  <div class="bb-1 width-100-pct"></div>

  <div class="m-0 name-button-row">
    <ion-button
      *ngIf="!editVpp"
      fill="outline"
      color="button-primary"
      mode="ios"
      class="m-0 cancel-name-button"
      (click)="dismiss()"
    >
      {{ 'General.Cancel' | translate }}
    </ion-button>
    <ion-button
      *ngIf="!editVpp || (editVpp && permissions.VPP_CHANGE_VPP)"
      [attr.disabled]="formData?.name.length <= 2 || !formData?.timezone"
      mode="ios"
      color="button-primary"
      class="m-0 update-name-button"
      (click)="submitVppData(formData)"
    >
      {{ submitButtonText }}
    </ion-button>
  </div>
</ion-card>
