import { Injectable } from '@angular/core';
import { ApiWrapper } from '@service/common/api-wrapper.service';
import { UnitStore } from './unit.store';
import { Unit } from '@class/commons/unit.model';
import {
  BehaviorSubject,
  Observable,
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { UnitHistory } from './unit-power-flow-stream-svg.store';
import { DateTime } from 'luxon';
import { AvailableAPI, RequestMethod, UseHeaderType } from '@class/commons/request-api.model';
import { CompositionBarData } from 'app/components/site-energy/vertical-composition-bars/vertical-composition-bars.component';
import { TimePeriodResolution } from '@class/commons/constants-datetime';

enum Granularity {
  hour = 'hour',
  day = 'day',
}
interface TimePeriod {
  granularity: Granularity;
  dataAvgPeriod: TimePeriodResolution;
  averageDivisor: number;
  granularityLabel: string;
}
export type SiteEnergyGranularityOptions =
  | TimePeriodResolution.DAY
  | TimePeriodResolution.WEEK
  | TimePeriodResolution.MONTH
  | TimePeriodResolution.YEAR;
const SITE_ENERGY_OPT_TO_PERIOD: Record<SiteEnergyGranularityOptions, TimePeriod> = {
  [TimePeriodResolution.DAY]: {
    granularity: Granularity.hour,
    dataAvgPeriod: TimePeriodResolution.DAY,
    averageDivisor: 1,
    granularityLabel: `SiteEnergy.AvgHour`,
  },
  [TimePeriodResolution.WEEK]: {
    granularity: Granularity.day,
    dataAvgPeriod: TimePeriodResolution.DAY,
    averageDivisor: 7,
    granularityLabel: `SiteEnergy.AvgDay`,
  },
  [TimePeriodResolution.MONTH]: {
    granularity: Granularity.day,
    dataAvgPeriod: TimePeriodResolution.WEEK,
    averageDivisor: 4.3, //weeks per 30 days
    granularityLabel: `SiteEnergy.AvgDay`,
  },
  [TimePeriodResolution.YEAR]: {
    granularity: Granularity.day,
    dataAvgPeriod: TimePeriodResolution.MONTH,
    averageDivisor: 12,
    granularityLabel: `SiteEnergy.AvgMonth`,
  },
};

const EMPTY_COMPOSITION_BAR_CONFIGS: { production: CompositionBarData; consumption: CompositionBarData } = {
  production: {
    mainHeading: 'SiteEnergy.Production',
    mainValue: 0,
    subHeading: 'Avg',
    subValue: 0,
    chartParameters: {
      consumed: {
        color: 'var(--ion-color-consumed)',
        legend: 'SiteEnergy.Consumed',
        value: 0,
      },
      stored: {
        color: 'var(--ion-color-battery)',
        legend: 'SiteEnergy.Stored',
        value: 0,
      },
      exported: {
        color: 'var(--ion-color-consumption)',
        legend: 'SiteEnergy.Exported',
        value: 0,
      },
    },
  },
  consumption: {
    mainHeading: 'SiteEnergy.Consumption',
    mainValue: 0,
    subHeading: 'Avg',
    subValue: 0,
    chartParameters: {
      solar: {
        color: 'var(--ion-color-solar)',
        legend: 'Svg.Solar',
        value: 0,
      },
      storage: {
        color: 'var(--ion-color-battery)',
        legend: 'Svg.Storage',
        value: 0,
      },
      grid: {
        color: 'var(--ion-color-consumption)',
        legend: 'Svg.Grid',
        value: 0,
      },
    },
  },
};

export interface UnitCompositionBarChartStateData {
  data: { production: CompositionBarData; consumption: CompositionBarData };
  loader: boolean;
  currentSelectedPeriod: SiteEnergyGranularityOptions;
  ionMaxDate: string;
  currentPeriod: DateTime;
  ionDatePickerPresentation: 'date' | 'month-year' | 'year';
  isFirstDayOfWeek(dateString: string): boolean;
}

const EMPTY_UNIT_COMPOSITION_BAR_CHART_DATA: UnitCompositionBarChartStateData = {
  data: EMPTY_COMPOSITION_BAR_CONFIGS,
  loader: true,
  currentSelectedPeriod: TimePeriodResolution.DAY,
  ionMaxDate: DateTime.local().startOf('day').toISODate(),
  currentPeriod: DateTime.local().startOf('day'),
  ionDatePickerPresentation: 'date',
  isFirstDayOfWeek: () => {
    return true;
  },
};

@Injectable({
  providedIn: 'root',
})
export class UnitEnergyStore {
  private _unitCompositionBarChartStore = new BehaviorSubject<UnitCompositionBarChartStateData>(
    EMPTY_UNIT_COMPOSITION_BAR_CHART_DATA,
  );
  unitCompositionBarChartStore$ = this._unitCompositionBarChartStore.asObservable();

  constructor(
    private _api: ApiWrapper,
    private _unitStore: UnitStore,
  ) {
    this.getCompositionBarChartData(DateTime.local().toISO(), TimePeriodResolution.DAY);
  }

  compositionBarChartDateChange(dateString: string): void {
    const { currentSelectedPeriod } = this._unitCompositionBarChartStore.value;
    this.getCompositionBarChartData(dateString, currentSelectedPeriod);
  }
  compositionBarChartPeriodChange(currentSelectedPeriod: SiteEnergyGranularityOptions): void {
    const { currentPeriod } = this._unitCompositionBarChartStore.value;
    this.getCompositionBarChartData(currentPeriod.toISO(), currentSelectedPeriod);
  }

  private getCompositionBarChartData(selectedDateString: string, selectedPeriod: SiteEnergyGranularityOptions) {
    this._unitStore.unit$
      .pipe(
        filter((unitData) => unitData.data !== null),
        distinctUntilKeyChanged('data', (prev, curr) => prev.uuid === curr.uuid),
        shareReplay(),
        switchMap((unitData) => this.getUnitSiteEnergyValues(unitData.data, selectedDateString, selectedPeriod)),
        map((unitHistory) => {
          const { solar, solar2load, solar2storage, solar2grid, load, storage2load, grid_import } =
            unitHistory.data.history;
          const { unitTz } = unitHistory;

          return {
            ...EMPTY_UNIT_COMPOSITION_BAR_CHART_DATA,
            data: {
              production: {
                mainHeading: 'SiteEnergy.Production',
                mainValue: solar ? parseFloat(solar?.toFixed(2)) : 0,
                subHeading: SITE_ENERGY_OPT_TO_PERIOD[selectedPeriod].granularityLabel,
                subValue: solar
                  ? parseFloat((solar / SITE_ENERGY_OPT_TO_PERIOD[selectedPeriod].averageDivisor).toFixed(2))
                  : 0,
                chartParameters: {
                  consumed: {
                    color: 'var(--ion-color-consumed)',
                    legend: 'SiteEnergy.Consumed',
                    value: solar2load ? parseFloat(solar2load?.toFixed(2)) : 0,
                  },
                  stored: {
                    color: 'var(--ion-color-battery)',
                    legend: 'SiteEnergy.Stored',
                    value: solar2storage ? parseFloat(solar2storage?.toFixed(2)) : 0,
                  },
                  exported: {
                    color: 'var(--ion-color-consumption)',
                    legend: 'SiteEnergy.Exported',
                    value: solar2grid ? parseFloat((solar2grid * -1).toFixed(2)) : 0,
                  },
                },
              },
              consumption: {
                mainHeading: 'SiteEnergy.Consumption',
                mainValue: load ? parseFloat(load?.toFixed(2)) : 0,
                subHeading: SITE_ENERGY_OPT_TO_PERIOD[selectedPeriod].granularityLabel,
                subValue: load
                  ? parseFloat((load / SITE_ENERGY_OPT_TO_PERIOD[selectedPeriod].averageDivisor).toFixed(2))
                  : 0,
                chartParameters: {
                  solar: {
                    color: 'var(--ion-color-solar)',
                    legend: 'Svg.Solar',
                    value: solar2load ? parseFloat(solar2load?.toFixed(2)) : 0,
                  },
                  storage: {
                    color: 'var(--ion-color-battery)',
                    legend: 'Svg.Storage',
                    value: storage2load ? parseFloat(storage2load?.toFixed(2)) : 0,
                  },
                  grid: {
                    color: 'var(--ion-color-consumption)',
                    legend: 'Svg.Grid',
                    value: grid_import ? parseFloat(grid_import?.toFixed(2)) : 0,
                  },
                },
              },
            },
            loader: false,
            ionMaxDate: DateTime.local({ zone: unitTz }).toISODate(),
            isFirstDayOfWeek: (dateString: string) => {
              const date = new Date(dateString);
              const utcDay = date.getUTCDay();
              let showDates = true;
              const { currentSelectedPeriod } = this._unitCompositionBarChartStore.value;
              if (currentSelectedPeriod === TimePeriodResolution.WEEK) {
                /**
                 * Date will be enabled if it is
                 * first day of the week
                 */
                showDates = utcDay === 1;
              }
              return showDates;
            },
            currentPeriod: DateTime.fromISO(selectedDateString, { zone: unitTz }).startOf(selectedPeriod),
            currentSelectedPeriod: selectedPeriod,
            ionDatePickerPresentation: this.getIonDatePresentation(selectedPeriod),
          };
        }),
        catchError(() => of(EMPTY_UNIT_COMPOSITION_BAR_CHART_DATA)),
        startWith(EMPTY_UNIT_COMPOSITION_BAR_CHART_DATA),
        tap((compositionChartData) => {
          this._unitCompositionBarChartStore.next(compositionChartData);
        }),
      )
      .subscribe();
  }

  private getUnitSiteEnergyValues(
    unit: Unit,
    dateString: string,
    selectedPeriod: SiteEnergyGranularityOptions,
  ): Observable<{ data: UnitHistory; unitTz: string }> {
    const unitTz = unit.timezone;
    const start = DateTime.fromISO(dateString, { zone: unitTz }).startOf(selectedPeriod).valueOf();
    const end = DateTime.fromISO(dateString, { zone: unitTz }).endOf(selectedPeriod).valueOf();

    return (
      this._api.handleObservableRequest({
        useAPI: AvailableAPI.SWITCHDIN,
        url: `/api/v1/units/${unit.id}/history/?fromts=${start}&tots=${end}`,
        requestMethod: RequestMethod.GET,
        useHeader: UseHeaderType.AUTHORIZED_SWDIN,
        requestData: {},
      }) as Observable<UnitHistory>
    ).pipe(
      map((history) => {
        return { data: history, unitTz };
      }),
    );
  }

  private getIonDatePresentation(timePeriod: SiteEnergyGranularityOptions): 'date' | 'month-year' | 'year' {
    let presentation: 'date' | 'month-year' | 'year' = 'date';
    switch (timePeriod) {
      case TimePeriodResolution.DAY:
      case TimePeriodResolution.WEEK:
        presentation = 'date';
        break;
      case TimePeriodResolution.MONTH:
        presentation = 'month-year';
        break;
      case TimePeriodResolution.YEAR:
        presentation = 'year';
        break;
      default:
        presentation = 'date';
    }
    return presentation;
  }
}
