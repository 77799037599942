import { LoadingController, AlertController, ModalController } from '@ionic/angular';
import { TranslationsService } from '../../../services/common/translations.service';
import { Component } from '@angular/core';
import { VppControlGroupService } from '../../../services/virtualPowerPlants/control-group/vpp-control-group.service';
import { HttpErrorResponse } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { VppControlGroupEditForm } from '../../../classes/vpp/vpp-control-group.model';

@Component({
  selector: 'app-create-control-group-modal',
  templateUrl: './create-control-group-modal.page.html',
  styleUrls: ['./create-control-group-modal.page.scss'],
})
export class CreateControlGroupModalPage {
  public newCG: VppControlGroupEditForm = {
    uuid: null,
    id: null,
    name: '',
    description: '',
  };

  constructor(
    private controlGroupService: VppControlGroupService,
    private viewCtrl: ModalController,
    private trans: TranslationsService,
    private alertController: AlertController,
    private loadingController: LoadingController,
  ) {}

  public async dismiss(data = null, errorMsg = null): Promise<void> {
    await this.viewCtrl.dismiss({ data: data, error: errorMsg });
  }

  async createNewControlGroup(): Promise<void> {
    const loading = await this.loadingController.create({
      message: this.trans.instant('VirtualPowerPlant.CreatingControlGroup'),
    });
    await loading.present();
    const createControlGroup$ = this.controlGroupService.createControlGroup(this.newCG);
    createControlGroup$.pipe(take(1)).subscribe(
      () => {
        loading.dismiss();
        this.dismiss();
      },
      async (err) => {
        await this.presentErrorAlert(err);
        await loading.dismiss();
        this.dismiss();
      },
    );
  }

  async presentErrorAlert(err: any): Promise<void> {
    const message = err instanceof HttpErrorResponse ? `${err.name} ${err.status}: ${err.statusText}` : '';
    const alert = await this.alertController.create({
      header: this.trans.instant('VirtualPowerPlant.FailedToCreateControlGroup'),
      message,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
    });
    await alert.present();
  }
}
