import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { share, switchMap } from 'rxjs/operators';
import { VirtualPowerPlantsService } from '../virtual-power-plants.service';

export const ROLLOVER_PERIOD_MIN = 5;
const ROLLOVER_PERIOD_MS = ROLLOVER_PERIOD_MIN * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class VppRollOverService {
  public rollOver$ = this.vppService.vppSelected$.pipe(
    switchMap(() => this.setRolloverInterval()),
    share(),
  );

  constructor(private vppService: VirtualPowerPlantsService) {}

  private setRolloverInterval(): Observable<number> {
    const milliSecondsToFirstTimeout = this.calculateRemainingMillisecondsToNextPeriod(new Date());
    return timer(milliSecondsToFirstTimeout, ROLLOVER_PERIOD_MS);
  }

  private calculateRemainingMillisecondsToNextPeriod(date: Date): number {
    const min = date.getMinutes();
    const sec = date.getSeconds();
    const remainingMinsToNextRollPeriod = min % ROLLOVER_PERIOD_MIN;
    const actualRemainingMins = ROLLOVER_PERIOD_MIN - 1 - remainingMinsToNextRollPeriod;
    const actualRemainingSecs = 60 - sec;
    const actualRemainingMs = (actualRemainingMins * 60 + actualRemainingSecs) * 1000;
    return actualRemainingMs;
  }
}
