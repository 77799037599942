import { Component, AfterViewInit, Input, ViewChild, ElementRef, HostListener, SimpleChanges } from '@angular/core';
import * as Plotly from 'plotly.js-dist';
import { ElementLoaderComponent } from '../element-loader/element-loader.component';
import { ThemeService } from '../../services/themes/theme.service';
import { IsPlatformValues } from '@class/commons/is-platform-values';

@Component({
  selector: 'live-metric-graph',
  templateUrl: './live-metric-graph.component.html',
  styleUrls: ['./live-metric-graph.component.scss'],
})
export class LiveMetricGraphComponent extends IsPlatformValues implements AfterViewInit {
  @Input() metricData;
  @Input() needToResize;
  @Input() valueUpdated;
  @ViewChild('streamGraph', { static: false }) graphElement: ElementRef;
  @ViewChild(ElementLoaderComponent, { static: false }) loader: ElementLoaderComponent;

  private layout: any = {
    showlegend: false,
    font: {
      size: 12,
    },
    height: 48,
    autosize: true,
    xaxis: {
      showgrid: false,
      fixedrange: this.IS_PLATFORM_MOBILE,
      showticklabels: false,
      zeroline: false,
      automargin: true,
    },
    yaxis: {
      showgrid: false,
      fixedrange: this.IS_PLATFORM_MOBILE,
      showticklabels: false,
      zeroline: false,
      automargin: true,
    },
    margin: {
      t: 0,
      l: 0,
      b: 0,
      r: 0,
    },
    plot_bgcolor: 'rgba(0, 0, 0, 0)',
    paper_bgcolor: 'rgba(0, 0, 0, 0)',
    hoverdistance: 100000,
  };

  private style: any = {
    displayModeBar: false,
  };

  public plotted = false;
  private isInit = false;

  constructor(public themeService: ThemeService) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  handleResize(event) {
    this.resize();
  }
  resize() {
    setTimeout(() => {
      this.layout.width = this.graphElement.nativeElement.clientWidth;
      try {
        if (this.plotted) {
          Plotly.relayout(this.graphElement.nativeElement, this.layout).catch((error) => {
            console.error(error);
          });
        }
      } catch (error) {
        console.error(error);
        //if we try to resize before the plotly got plotted, it's going to throw error
      }
    }, 1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (!this.isInit) {
      return;
    }

    if (changes.hasOwnProperty('valueUpdated')) {
      if (!this.plotted) {
        if (this.metricData.values.length >= 1) {
          this.plotGraph();
        }
      } else {
        this.updateGraph();
      }
    }
    if (changes.hasOwnProperty('needToResize')) {
      this.resize();
    }
  }
  ngAfterViewInit() {
    if (!this.plotted && this.metricData.values.length > 0) {
      this.plotGraph();
    }
    this.isInit = true;
  }

  plotGraph() {
    const streamChartColor = this.themeService.$userTheme.streamChartColor;
    const data = [
      {
        x: this.metricData.times,
        y: this.metricData.values,
        name: 'Actual',
        type: 'scatter',
        mode: this.metricData.values.length === 1 ? 'markers' : 'lines',
        line: { color: streamChartColor, shape: 'hvh' },
      },
    ];
    try {
      Plotly.newPlot(this.graphElement.nativeElement, data, this.layout, this.style)
        .then(() => {
          this.handleResize(null);
          this.loader.clearLoader();
          this.plotted = true;
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  updateGraph() {
    const streamChartColor = this.themeService.$userTheme.streamChartColor;
    const update = {
      x: [this.metricData.times],
      y: [this.metricData.values],
      mode: 'lines',
      line: { color: streamChartColor, shape: 'hvh' },
    };
    try {
      Plotly.update(this.graphElement.nativeElement, update).catch((error) => {
        console.error(error);
      });
    } catch (error) {
      console.error(error);
    }
  }
}
