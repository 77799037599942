import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], searchCriteria: string): any {
    if (searchCriteria?.trim() === '' || searchCriteria === null || searchCriteria === undefined || !items) {
      return items;
    }
    const searchTerms = searchCriteria.split(' ');
    return items.filter((item) => {
      return searchTerms.every((term) => {
        return JSON.stringify(item).toLowerCase().includes(term.toLowerCase());
      });
    });
  }
}
