import { Component, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { VppOperationScheduleModalPage } from '../../pages/modals/vpp-operation-schedule-modal/vpp-operation-schedule-modal.page';
import { ModalController } from '@ionic/angular';
import { VppDemandResponse } from '../../classes/vpp/vpp-demand-response.model';
import { combineLatest, Subscription } from 'rxjs';
import { isVppDemandTypeFcas, isVppModeFcas } from '../../classes/vpp/vpp-demand-type-mode.model';
import {
  VppOperationState,
  VppOperationTabFacadeService,
} from '../../services/virtualPowerPlants/facades/operation-tab/vpp-operation-tab-facade.service';
import { filter } from 'rxjs/operators';
import { VppOverviewFacadeService } from '../../services/virtualPowerPlants/facades/overview/vpp-overview-facade.service';
import {
  adaptVppDemandResponseToDispatchModalData,
  VppOperationDispatchModalType,
  VppOperationDispatchRow,
  VPP_TABLE_PAGE_SIZE_OPTIONS,
} from '../../classes/vpp/vpp-dispatch.model';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
@Component({
  selector: 'app-vpp-operation-tab-control-group',
  templateUrl: './vpp-operation-tab-control-group.component.html',
  styleUrls: ['./vpp-operation-tab-control-group.component.scss'],
})
export class VppOperationTabControlGroupComponent implements OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  // @Output() selectedDemandResponse = new EventEmitter<VppOperationDispatchRow>();

  readonly VPP_TABLE_PAGE_SIZE_OPTIONS = VPP_TABLE_PAGE_SIZE_OPTIONS;
  readonly PermissionKey = PermissionKey;
  readonly displayedColumns: string[] = [
    'group',
    'demandType',
    'order',
    'deviceClass',
    'controlDesc',
    'dispatch',
    'available',
    'capacity',
    'action',
  ];
  public dataSource: MatTableDataSource<VppDemandResponse>;
  public filterString: string;
  private operationState: VppOperationState;
  private subscription: Subscription;
  private modal: HTMLIonModalElement;

  constructor(
    private modalController: ModalController,
    private vppOperationTabFacade: VppOperationTabFacadeService,
    private vppOverviewFacade: VppOverviewFacadeService,
  ) {
    this.subscription = combineLatest([
      this.vppOperationTabFacade.vppOperationState$,
      this.vppOverviewFacade.vppOverviewModeDemandType$,
    ])
      .pipe(filter(([state, demandTypeMode]) => state != null))
      .subscribe(([state, demandTypeMode]) => {
        this.operationState = state;
        const filteredDemandResponse = state.demand_responses.filter((demand) => {
          //currently we want to show both fcas raise and lower demand responses together if fcas mode
          const isFcas = isVppModeFcas(demandTypeMode.mode) && isVppDemandTypeFcas(demand.demand_type);
          return isFcas || demand.demand_type === demandTypeMode.demandType;
        });
        this.dataSource = new MatTableDataSource(filteredDemandResponse);
        if (this.filterString) {
          this.applyFilter(this.filterString);
        }
        if (this.sort) {
          this.dataSource.sort = this.sort;
        }
        if (this.paginator) {
          this.dataSource.paginator = this.paginator;
        }
      });
  }

  applyFilter(filterValue: string): void {
    this.filterString = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onRowClick(row: VppOperationDispatchRow): void {
    this.vppOperationTabFacade.selectDemandResponseToHighlight(row);
  }

  async openScheduleModal(row: VppOperationDispatchRow): Promise<void> {
    row.capacity = (
      row.dptch_seq[this.operationState.presentIndex - 1] + row.avail_seq[this.operationState.presentIndex - 1]
    ).toFixed(1);
    row.available = row.avail_seq[this.operationState.presentIndex - 1];
    row.dispatch = row.dptch_seq[this.operationState.presentIndex - 1];
    try {
      const dispatch = adaptVppDemandResponseToDispatchModalData(row, VppOperationDispatchModalType.NOW_DISPATCH);
      this.modal = await this.modalController.create({
        component: VppOperationScheduleModalPage,
        cssClass: 'transparent-modal',
        backdropDismiss: false,
        componentProps: {
          dispatch,
          isDateReadonly: false,
        },
      });
      await this.modal.present();
    } catch (err) {
      console.error(err);
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
