<ion-card class="home-card" mode="md">
  <ion-card-header class="p-0">
    <ion-card-title class="text-dark" (click)="NameClick()">
      <ion-item lines="none" class="pl-0 mh-35">
        <app-status-circle [isCard]="true" [status]="data.onlineStatus"></app-status-circle>
        <ion-label class="pl-15 ion-text-wrap ion-no-margin">{{ data.name }}</ion-label>
      </ion-item>
    </ion-card-title>
    <ion-card-subtitle class="text-light">{{
      data.locationDescription ? data.locationDescription : '&nbsp;'
    }}</ion-card-subtitle>
  </ion-card-header>

  <ion-card-content class="p-0">
    <ion-grid>
      <ion-row>
        <ion-col size="3" class="ion-text-center light-brand-primary-div-background vertical-middle-aligner">
          <div class="number-of-units">
            <ion-text class="text-dark">
              <span class="fs-22 fw-600">{{ data.unitCount }}</span
              ><br />
              <span class="text-medium">{{ 'HomeCard.Units' | translate }}</span>
            </ion-text>
          </div>
        </ion-col>
        <ion-col class="light-brand-primary-div-background">
          <ion-grid>
            <ion-row class="vertical-align-content">
              <ion-col>
                <ion-list class="bkg-transparent">
                  <ng-container *ngFor="let indicator of data.unitStatus">
                    <ion-item lines="none" class="bkg-transparent mh-35 pl-0">
                      <app-status-circle [isCard]="true" [status]="indicator.status"></app-status-circle>
                      <ion-label class="text-medium ion-padding-start m-0 ion-text-wrap">
                        {{ indicator.count }} {{ indicator.translateText | translate }}
                      </ion-label>
                    </ion-item>
                  </ng-container>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
