import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { CostState, UnitCostService } from '../../services/units/unit-cost.service';
import { DataStructureEnum } from '../../classes/units/tariff/cost.model';
import { SkeletonPlaceholder } from '@class/commons/constants';
@Component({
  selector: 'app-unit-costs-summary',
  templateUrl: './unit-costs-summary.component.html',
  styleUrls: ['./unit-costs-summary.component.scss'],
})
export class UnitCostsSummaryComponent {
  vm$: Observable<CostState> = this.unitCostService.vm$;
  _DataStructureEnum = DataStructureEnum;
  readonly SkeletonPlaceholder = SkeletonPlaceholder;

  constructor(public unitCostService: UnitCostService) {}
}
