import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BrowserLogger } from '@class/core/browser-logger';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import _, { isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of, pipe } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { VppSettingGroupsTabControlGroup } from '../../../../../classes/vpp/vpp-control-group.model';
import {
  returnLowerVppDemandTypeGivenMode,
  returnRaiseVppDemandTypeGivenMode,
  VppDemandTypes,
  VppModes,
} from '../../../../../classes/vpp/vpp-demand-type-mode.model';
import {
  VppSettingUnitsDisplayPowerColumns,
  VppSettingUnitsDisplayRow,
  VppUnit,
  VppUnitDeviceTypes,
  VppUnitMetricKeyType,
  VppUnitMetricsMqttMsg,
  VppUnitMetricsMqttMsgMeasurement,
} from '../../../../../classes/vpp/vpp-unit.model';
import { CreateControlGroupModalPage } from '../../../../../pages/modals/create-control-group-modal/create-control-group-modal.page';
import { VppEditControlGroupModalComponent } from '../../../../../pages/modals/vpp-edit-control-group-modal/vpp-edit-control-group-modal.component';
import { TranslationsService } from '../../../../common/translations.service';
import { VppControlGroupService } from '../../../control-group/vpp-control-group.service';
import { VppUnitsService } from '../../../units/vpp-units.service';

interface VppUnitWithDeviceCount {
  unit: VppUnit;
  deviceCount: Record<VppUnitDeviceTypes, number>;
}
@Injectable({
  providedIn: 'root',
})
export class VppSettingsGroupFacadeService {
  // User interaction
  private vppControlGroupToggledSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private vppModeSelectedSubject$: BehaviorSubject<VppModes> = new BehaviorSubject<VppModes>(VppModes.DRM);
  public vppModeSelected$ = this.vppModeSelectedSubject$.asObservable().pipe(distinctUntilChanged());

  public controlGroup$: Observable<VppSettingGroupsTabControlGroup[]> = combineLatest([
    this.vppControlGroupService.settingControlGroups$,
    this.vppControlGroupToggledSubject$,
  ]).pipe(
    map(([groups, toggledGroupName]) => {
      return this.toggleControlGroupDisplayStatus(groups, toggledGroupName);
    }),
  );

  //observable stream to produce unit table rows, after filtered by selected CG, vpp mode, and pagination
  private selectedControlGroups$ = this.controlGroup$.pipe(
    map((groups) => groups.filter((group) => group.unitsDisplayed)),
  );
  private unitWithFilteredDeviceCount$ = combineLatest([this.vppUnitsService.units$, this.selectedControlGroups$]).pipe(
    map(([units, controlGroups]) => {
      const filteredUnitsBySelectedCG = this.filterOutUnitsWithoutSelectedControlGroups(units, controlGroups);
      return this.addDeviceCountInfoToUnits(filteredUnitsBySelectedCG, controlGroups);
    }),
  );
  public vppSettingUnitsDisplayRows$ = combineLatest([this.unitWithFilteredDeviceCount$, this.vppModeSelected$]).pipe(
    map(([units, vppMode]) => this.createUnitTableRows(units, vppMode)),
  );

  constructor(
    private vppUnitsService: VppUnitsService,
    private vppControlGroupService: VppControlGroupService,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private trans: TranslationsService,
  ) {
    BrowserLogger.log('VppSettingsGroupFacadeService.constructor');
  }

  private addDeviceCountInfoToUnits(
    units: VppUnit[],
    controlGroups: VppSettingGroupsTabControlGroup[],
  ): VppUnitWithDeviceCount[] {
    BrowserLogger.log('VppSettingsGroupFacadeService.addDeviceCountInfoToUnits', { units, controlGroups });
    return units.map((unit) => {
      return { unit, deviceCount: this.calculateUnitDeviceCounts(unit, controlGroups) };
    });
  }

  private calculateUnitDeviceCounts(
    unit: VppUnit,
    selectedControlGroups: VppSettingGroupsTabControlGroup[],
  ): Record<VppUnitDeviceTypes, number> {
    BrowserLogger.log('VppSettingsGroupFacadeService.calculateUnitDeviceCounts', { unit, selectedControlGroups });
    //initialise device count records
    const deviceCountsEntries: [string, number][] = Object.values(VppUnitDeviceTypes).map((deviceType) => [
      deviceType,
      0,
    ]);
    const deviceCounts: Record<VppUnitDeviceTypes, number> = Object.fromEntries(deviceCountsEntries) as Record<
      VppUnitDeviceTypes,
      number
    >;
    if (isEmpty(unit) || isEmpty(selectedControlGroups)) return deviceCounts;

    const selectedCGNames = selectedControlGroups.map((group) => group.name);
    // figure out device count for each unit based on selected control group
    unit.devices.forEach((device) => {
      const isDeviceFromSelectedControlGroups = selectedCGNames.some((n) => device.groups.includes(n));
      if (isDeviceFromSelectedControlGroups) deviceCounts[device.category] += 1;
    });
    return deviceCounts;
  }

  private filterOutUnitsWithoutSelectedControlGroups(
    units: VppUnit[],
    selectedControlGroups: VppSettingGroupsTabControlGroup[],
  ): VppUnit[] {
    if (isEmpty(units) || isEmpty(selectedControlGroups)) return [];
    BrowserLogger.log('VppSettingsGroupFacadeService.filterOutUnitsWithoutSelectedControlGroups', {
      units,
      selectedControlGroups,
    });
    return units.filter((unit) => this.doesUnitHaveAtLeastOneSelectedControlGroup(unit, selectedControlGroups));
  }

  private doesUnitHaveAtLeastOneSelectedControlGroup(
    unit: VppUnit,
    selectedControlGroups: VppSettingGroupsTabControlGroup[],
  ): boolean {
    if (isEmpty(unit) || isEmpty(selectedControlGroups)) return false;
    BrowserLogger.log('VppSettingsGroupFacadeService.doesUnitHaveAtLeastOneSelectedControlGroup', {
      unit,
      selectedControlGroups,
    });
    const unitSelectedControlGroupNames = unit.controlGroupNames.filter((name) =>
      selectedControlGroups.find((group) => group.name === name && group.unitsDisplayed),
    );
    return !isEmpty(unitSelectedControlGroupNames);
  }

  private createUnitTableRows(units: VppUnitWithDeviceCount[], mode: VppModes): VppSettingUnitsDisplayRow[] {
    BrowserLogger.log('VppSettingsGroupFacadeService.createUnitTableRows', { units, mode });
    const rows: VppSettingUnitsDisplayRow[] = units?.map((unit) => this.adaptVppUnitsToDisplayRows(unit, mode)) ?? [];
    return rows;
  }

  private adaptVppUnitsToDisplayRows(
    unitWithDeviceCount: VppUnitWithDeviceCount,
    selectedMode: VppModes,
  ): VppSettingUnitsDisplayRow {
    BrowserLogger.log('VppSettingsGroupFacadeService.adaptVppUnitsToDisplayRows', {
      unitWithDeviceCount,
      selectedMode,
    });
    const selectedRaiseDemandType = returnRaiseVppDemandTypeGivenMode(selectedMode);
    const selectedLowerDemandType = returnLowerVppDemandTypeGivenMode(selectedMode);
    const { unit, deviceCount } = unitWithDeviceCount;
    const { name, unitVppMetrics$ } = unit;

    const raiseMetrics = this.constructAvailableAndDispatchMetricObs(
      unitVppMetrics$,
      selectedRaiseDemandType,
      deviceCount,
    );
    const lowerMetrics = this.constructAvailableAndDispatchMetricObs(
      unitVppMetrics$,
      selectedLowerDemandType,
      deviceCount,
    );
    const power = { raise: raiseMetrics, lower: lowerMetrics };
    const row: VppSettingUnitsDisplayRow = {
      unitName: name,
      deviceCount,
      power,
    };
    return row;
  }

  private constructAvailableAndDispatchMetricObs(
    metric$: Observable<VppUnitMetricsMqttMsg>,
    selectedDemandType: VppDemandTypes,
    deviceCount: Record<VppUnitDeviceTypes, number>,
  ): VppSettingUnitsDisplayPowerColumns {
    BrowserLogger.log('VppSettingsGroupFacadeService.constructAvailableAndDispatchMetricObs', {
      selectedDemandType,
      deviceCount,
    });
    const available$ = metric$.pipe(
      map((metrics) =>
        this.filterAndAccumulateMetricValue(
          metrics.measurements,
          selectedDemandType,
          deviceCount,
          VppUnitMetricKeyType.AVAILABLE,
        ),
      ),
    );
    const dispatched$ = metric$.pipe(
      map((metrics) =>
        this.filterAndAccumulateMetricValue(
          metrics.measurements,
          selectedDemandType,
          deviceCount,
          VppUnitMetricKeyType.DISPATCH,
        ),
      ),
    );
    const filteredMetric: VppSettingUnitsDisplayPowerColumns = {
      available$,
      dispatched$,
    };
    return filteredMetric;
  }

  private filterAndAccumulateMetricValue(
    metrics: VppUnitMetricsMqttMsgMeasurement,
    selectedDemandType: VppDemandTypes,
    deviceCount: Record<VppUnitDeviceTypes, number>,
    metricType: VppUnitMetricKeyType,
  ): number {
    const filteredMetricKeys = this.findMetricKeysWithDemandTypeAndDeviceClass(
      metrics,
      selectedDemandType,
      deviceCount,
      metricType,
    );
    const accumulatedMetricValue = this.accumulateMetricValueWithKeys(metrics, filteredMetricKeys);
    BrowserLogger.log('VppSettingsGroupFacadeService.filterAndAccumulateMetricValue', {
      accumulatedMetricValue,
      metrics,
      selectedDemandType,
      deviceCount,
      metricType,
    });
    return accumulatedMetricValue;
  }

  private findMetricKeysWithDemandTypeAndDeviceClass(
    metrics: VppUnitMetricsMqttMsgMeasurement,
    demandType: VppDemandTypes,
    deviceCount: Record<VppUnitDeviceTypes, number>,
    metricType: VppUnitMetricKeyType,
  ): string[] {
    if (isEmpty(metrics)) return [];
    BrowserLogger.log('VppSettingsGroupFacadeService.findMetricKeysWithDemandTypeAndDeviceClass', {
      metrics,
      demandType,
      metricType,
    });
    const deviceClassesPresentInUnit = Object.keys(deviceCount).filter((key) => deviceCount[key] > 0);
    const metricKeyOfDemandType = Object.keys(metrics).filter((metricKey) => metricKey.includes(`DT[${demandType}]`));
    const metricKeyOfDemandTypeAndDeviceClass = deviceClassesPresentInUnit.flatMap((deviceClass) =>
      metricKeyOfDemandType.filter((key) => key.includes(`DC[${deviceClass}]`)),
    );
    const filteredAvailabilityOrDispatchKeys = metricKeyOfDemandTypeAndDeviceClass.filter((key) =>
      key.includes(metricType),
    );
    return filteredAvailabilityOrDispatchKeys;
  }

  private accumulateMetricValueWithKeys(metrics: VppUnitMetricsMqttMsgMeasurement, keys: string[]): number {
    const value = keys.reduce((previousValue, currentValue) => previousValue + (metrics?.[currentValue] ?? 0), 0);
    BrowserLogger.log('VppSettingsGroupFacadeService.accumulateMetricValueWithKeys', {
      metrics,
      value,
    });
    return value;
  }

  private toggleControlGroupDisplayStatus(
    groups: VppSettingGroupsTabControlGroup[],
    toggledControlGroupName: string,
  ): VppSettingGroupsTabControlGroup[] {
    if (isEmpty(toggledControlGroupName) || isEmpty(groups)) return groups;
    const matchingControlGroupIndex = groups.findIndex((group) => group.name === toggledControlGroupName);
    if (matchingControlGroupIndex === -1) return groups;
    groups[matchingControlGroupIndex].unitsDisplayed = !groups[matchingControlGroupIndex].unitsDisplayed;
    BrowserLogger.log('VppSettingsGroupFacadeService.toggleControlGroupDisplayStatus', {
      groups,
      toggledControlGroupName,
    });
    return groups;
  }
  public selectModeToDisplayUnits(mode: VppModes): void {
    BrowserLogger.log('VppSettingsGroupFacadeService.selectModeToDisplayUnits', { mode });
    this.vppModeSelectedSubject$.next(mode);
  }

  public toggleControlGroupUnitDisplay(controlGroupName: string): void {
    BrowserLogger.log('VppSettingsGroupFacadeService.toggleControlGroupUnitDisplay', { controlGroupName });
    this.vppControlGroupToggledSubject$.next(controlGroupName);
  }

  public async presentControlGroupCreateModal(): Promise<void> {
    BrowserLogger.log('VppSettingsGroupFacadeService.presentControlGroupCreateModal');
    const modal = await this.modalController.create({
      component: CreateControlGroupModalPage,
      backdropDismiss: false,
    });
    await modal.present();
  }

  public async presentControlGroupEditModal(controlGroup: VppSettingGroupsTabControlGroup): Promise<void> {
    BrowserLogger.log('VppSettingsGroupFacadeService.presentControlGroupEditModal');
    const modal = await this.modalController.create({
      component: VppEditControlGroupModalComponent,
      cssClass: 'large-modal',
      backdropDismiss: false,
      componentProps: {
        controlGroup,
      },
    });
    await modal.present();
  }

  public async removeControlGroup(controlGroup: VppSettingGroupsTabControlGroup): Promise<void> {
    BrowserLogger.log('VppSettingsGroupFacadeService.removeControlGroup', { controlGroup });
    const loading = await this.loadingController.create({
      message: this.trans.instant('VirtualPowerPlant.RemovingControlGroup'),
    });
    await loading.present();
    const removeControlGroup$ = this.vppControlGroupService.deleteControlGroup(controlGroup);
    removeControlGroup$.subscribe(
      (_) => loading.dismiss(),
      async (err) => {
        await loading.dismiss();
        await this.presentRemoveControlGroupErrorAlert(err);
      },
    );
  }

  private async presentRemoveControlGroupErrorAlert(err: any) {
    BrowserLogger.log('VppSettingsGroupFacadeService.presentRemoveControlGroupErrorAlert', { err });
    const message = err instanceof HttpErrorResponse ? `${err.name} ${err.status}: ${err.statusText}` : '';
    const alert = await this.alertController.create({
      header: this.trans.instant('VirtualPowerPlant.ControlGroupRemoveFail'),
      message,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
        },
      ],
    });
    await alert.present();
  }
}
