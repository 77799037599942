import { Injectable } from '@angular/core';
import { BrowserLogger } from '@class/core/browser-logger';
import { Adapter } from '../../commons/adapter';

export enum FirmwareUpdateMqttStatuses {
  INITIALIZED = 'INITIALIZED',
  DOWNLOADING = 'DOWNLOADING',
  INSTALLING = 'INSTALLING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export enum FirmwareUpdateMqttVerboseStatuses {
  START = 'START',
  START_SHA_DOWNLOAD = 'START_SHA_DOWNLOAD',
  START_DROPLET_DOWNLOAD = 'START_DROPLET_DOWNLOAD',
  START_VENV_DOWNLOAD = 'START_VENV_DOWNLOAD',
  START_INSTALL = 'START_INSTALL',
  FAIL_UNKNOWN = 'FAIL_UNKNOWN',
  FAIL_ALREADY_UPDATING = 'FAIL_ALREADY_UPDATING',
  FAIL_DROPLET_DOWNLOAD = 'FAIL_DROPLET_DOWNLOAD',
  FAIL_VENV_DOWNLOAD = 'FAIL_VENV_DOWNLOAD',
  SUCCESS_ALREADY_UP_TO_DATE = 'SUCCESS_ALREADY_UP_TO_DATE',
  SUCCESS_DONE = 'SUCCESS_DONE',
}
/**
 * *** SAMPLE MQTT MSGS FROM DROPLET ***
 *
 * {"status": "DOWNLOADING", "verbose_status": "START_SHA_DOWNLOAD", "progress": 10}
 * {"status": "DOWNLOADING", "verbose_status": "START_DROPLET_DOWNLOAD", "progress": 35}
 * {"status": "INSTALLING", "verbose_status": "START_INSTALL", "progress": 90}
 *
 * {"status": "SUCCESS", "verbose_status": "SUCCESS_DONE", "extra_info": {"software_version_frontend": "2.0.0", "software_version": "droplet-armv7l-SWIT-3566-fix-git-version-regex-so-it-wo-2ad92352dd163127c4df2f331278219bbc098aba.tar.gz"}, "progress": 100}
 */
export class FirmwareUpdateMqttDropletTypeDTO {
  status: FirmwareUpdateMqttStatuses;
  verbose_status: FirmwareUpdateMqttVerboseStatuses;
  progress: number;
  extra_info?: { [index: string]: number | string | boolean };

  public static adaptToDropletFirmwareUpdateMqtt(mqttMsg: FirmwareUpdateMqttDropletTypeDTO): DropletFirmwareUpdateMqtt {
    const result = new DropletFirmwareUpdateMqtt();
    result.status = mqttMsg.status;
    result.statusText = mqttMsg.status.toLowerCase();
    result.verboseStatus = mqttMsg.verbose_status;
    result.progress = mqttMsg.progress;
    result.percentage = mqttMsg.progress >= 0 && mqttMsg.progress <= 100 ? mqttMsg.progress / 100 : 0;
    result.extraInfo = mqttMsg.extra_info;
    result.extraInfoString = JSON.stringify(mqttMsg.extra_info);
    BrowserLogger.log('FirmwareUpdateMqttDropletTypeDTO.adaptToDropletFirmwareUpdateMqtt', { self: this });
    return result;
  }
}

export class DropletFirmwareUpdateMqtt {
  public status: FirmwareUpdateMqttStatuses;
  // will convert the status into lower case so that can display that on the frontend.
  // Will be using the ion-capitalize-text to make the first letter capital
  public statusText: string;
  public verboseStatus: FirmwareUpdateMqttVerboseStatuses;
  public progress: number;
  // the ion-progress accept number from 0 to 1
  // will convert the progress into the percentage i.e., divide by 100
  public percentage: number;
  public extraInfo?: { [index: string]: number | string | boolean };
  public extraInfoString?: string;
}
