import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, ToastController } from '@ionic/angular';
import { TranslationsService } from '../../../services/common/translations.service';
import moment from 'moment';
import { VirtualPowerPlantsService } from '../../../services/virtualPowerPlants/virtual-power-plants.service';
import { ROLLOVER_PERIOD_MIN } from '../../../services/virtualPowerPlants/rollover/vpp-roll-over.service';
import { VppTimezoneService } from '../../../services/virtualPowerPlants/timezone/vpp-timezone.service';
import { VppScheduleRecurrencePattern } from '@class/vpp/vpp-control-group-schedule.model';
import { SubTabEnums, VPP_SETTING_CREATE_SCHEDULE_SUB_TABS } from '@class/core/sub-tabs';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-vpp-create-complex-schedule-modal',
  templateUrl: './vpp-create-complex-schedule-modal.page.html',
  styleUrls: ['./vpp-create-complex-schedule-modal.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VppCreateComplexScheduleModalPage implements OnInit {
  readonly VppScheduleRecurrencePattern = VppScheduleRecurrencePattern;
  //componentProps
  scheduleToUpdate;
  editSchedule;
  firstInitDateChange = false;
  firstInitTimeChange = false;

  days = [
    { name: 'Monday', isChecked: false },
    { name: 'Tuesday', isChecked: false },
    { name: 'Wednesday', isChecked: false },
    { name: 'Thursday', isChecked: false },
    { name: 'Friday', isChecked: false },
    { name: 'Saturday', isChecked: false },
    { name: 'Sunday', isChecked: false },
  ];
  years = [
    { name: 'Jan', fullName: 'January', isChecked: false },
    { name: 'Feb', fullName: 'February', isChecked: false },
    { name: 'Mar', fullName: 'March', isChecked: false },
    { name: 'Apr', fullName: 'April', isChecked: false },
    { name: 'May', fullName: 'May', isChecked: false },
    { name: 'Jun', fullName: 'June', isChecked: false },
    { name: 'Jul', fullName: 'July', isChecked: false },
    { name: 'Aug', fullName: 'August', isChecked: false },
    { name: 'Sep', fullName: 'September', isChecked: false },
    { name: 'Oct', fullName: 'October', isChecked: false },
    { name: 'Nov', fullName: 'November', isChecked: false },
    { name: 'Dec', fullName: 'December', isChecked: false },
  ];
  numberOfDays = {
    Jan: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    Feb: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28],
    Mar: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    Apr: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    ],
    May: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    Jun: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    ],
    Jul: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    Aug: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    Sep: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    ],
    Oct: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    Nov: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    ],
    Dec: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
  };
  complexSchedule = {
    name: '',
    description: '',
    start_time: '',
    end_time: '',
    intervalDuration: '',
    recurrence: this.VppScheduleRecurrencePattern.DAILY,
    patternProps: {},
    start_date: '',
    end_date: '',
    day_number: null,
    yearlyStartMonth: '',
    yearlyStartMonthDay: null,
    yearlyEndMonth: '',
    yearlyEndMonthDay: null,
    tillEndOfDay: false,
    endTimeDisable: false,
    // dispatch_type:""
  };

  selectedYearStartMonthDays: number[] = [];
  selectedYearEndMonthDays: number[] = [];

  minutesArray: number[] = [];
  startMinTime: string;
  endMinTime: string;
  momentFormatDate: DateTime; //get current datetime and then round it to interval period, e.g; if its 15:03, round it to 15:05 to get the rollover period interval
  timezone$ = this.timezoneService.timezone$;

  ionStartMinDate: string;
  ionEndMinDate: string;

  selectedTabIndex: number;

  readonly tabName = SubTabEnums.VPP_SETTING_CREATE_SCHEDULE;
  constructor(
    private vppService: VirtualPowerPlantsService,
    private viewCtrl: ModalController,
    private trans: TranslationsService,
    private alertController: AlertController,
    private toast: ToastController,
    public loadingController: LoadingController,
    private timezoneService: VppTimezoneService,
  ) {}

  ngOnInit() {
    // console.log('ngInit');
    this.createArrayOfMinutes();
    if (this.editSchedule == undefined || !this.editSchedule) {
      this.getCurrentTimeInIntervalOfRollover();
    }
    if (this.editSchedule) {
      this.setTheValueOfScheduleInComplexScheduleVariable();
    }
  }
  setTheValueOfScheduleInComplexScheduleVariable() {
    // console.log('schedule: ', this.scheduleToUpdate);
    this.complexSchedule.name = this.scheduleToUpdate.name;
    this.complexSchedule.description = this.scheduleToUpdate.description;
    this.complexSchedule.recurrence = this.scheduleToUpdate.recurrence;
    this.complexSchedule.start_date = this.scheduleToUpdate.start_date;
    this.complexSchedule.end_date = this.scheduleToUpdate.end_date;
    if (this.isDateIsPast(this.complexSchedule.start_date)) {
      this.ionStartMinDate = this.scheduleToUpdate.start_date;
    } else {
      this.ionStartMinDate = moment().toISOString();
    }
    this.ionEndMinDate = this.scheduleToUpdate.start_date;

    let interval =
      this.scheduleToUpdate.intervals[0] ??
      this.scheduleToUpdate.weekly_schedules[0].intervals[0] ??
      this.scheduleToUpdate.monthly_schedules[0].intervals[0] ??
      this.scheduleToUpdate.yearly_schedules[0].intervals[0];
    interval = interval.split(' - ');

    this.complexSchedule.start_time = DateTime.local()
      .set({
        hour: parseInt(interval[0].split(':')[0]),
        minute: parseInt(interval[0].split(':')[1]),
        second: 0,
        millisecond: 0,
      })
      .toISO();
    this.complexSchedule.end_time = DateTime.local()
      .set({
        hour: parseInt(interval[1].split(':')[0]),
        minute: parseInt(interval[1].split(':')[1]),
        second: 0,
        millisecond: 0,
      })
      .toISO();
    if (parseInt(interval[1].split(':')[1]) > 55) {
      this.complexSchedule.tillEndOfDay = true;
      this.complexSchedule.endTimeDisable = true;
    } else {
      this.complexSchedule.tillEndOfDay = false;
      this.complexSchedule.endTimeDisable = false;
    }
    this.endMinTime = DateTime.fromISO(this.complexSchedule.start_time).plus({ minute: ROLLOVER_PERIOD_MIN }).toISO();
    this.endTimeChange(this.complexSchedule.start_time, this.complexSchedule.end_time);

    switch (this.scheduleToUpdate.recurrence) {
      case this.VppScheduleRecurrencePattern.ONCE:
        break;
      case this.VppScheduleRecurrencePattern.DAILY:
        this.complexSchedule['frequency_value_daily'] = this.scheduleToUpdate.frequency_value;
        break;
      case this.VppScheduleRecurrencePattern.WEEKLY:
        this.complexSchedule.recurrence = this.VppScheduleRecurrencePattern.WEEKLY;
        this.complexSchedule['frequency_value_weekly'] = this.scheduleToUpdate.frequency_value;
        this.days.forEach((day) => {
          for (let index = 0; index < this.scheduleToUpdate.weekly_schedules.length; index++) {
            const element = this.scheduleToUpdate.weekly_schedules[index];
            if (element.day == day.name) {
              day.isChecked = true;
              break;
            }
          }
        });
        break;
      case this.VppScheduleRecurrencePattern.MONTHLY:
        this.complexSchedule.recurrence = this.VppScheduleRecurrencePattern.MONTHLY;
        this.complexSchedule.day_number = this.scheduleToUpdate.monthly_schedules[0].day_number;
        this.complexSchedule['frequency_value_monthly'] = this.scheduleToUpdate.frequency_value;
        break;
      case this.VppScheduleRecurrencePattern.YEARLY:
        this.complexSchedule.recurrence = this.VppScheduleRecurrencePattern.YEARLY;
        this.complexSchedule.yearlyStartMonth = this.scheduleToUpdate.yearly_schedules[0].start_month;
        this.complexSchedule.yearlyStartMonthDay = '' + this.scheduleToUpdate.yearly_schedules[0].start_day_number;
        this.complexSchedule.yearlyEndMonth = this.scheduleToUpdate.yearly_schedules[0].end_month;
        this.complexSchedule.yearlyEndMonthDay = '' + this.scheduleToUpdate.yearly_schedules[0].end_day_number;
        this.selectedYearStartMonthDays = this.numberOfDays[this.complexSchedule.yearlyStartMonth];
        this.selectedYearEndMonthDays = this.numberOfDays[this.complexSchedule.yearlyEndMonth];
        break;
      default:
        break;
    }
    // console.log('complexSchedule: ', this.complexSchedule);
  }
  public dismiss(data = null) {
    this.viewCtrl.dismiss(data);
  }
  createArrayOfMinutes() {
    let value = 0;
    while (value <= 59) {
      // 59 is the last minute in an hour
      this.minutesArray.push(value);
      value += ROLLOVER_PERIOD_MIN;
    }
  }
  private getCurrentTimeInIntervalOfRollover() {
    this.setMomentFormatDateValue();
    this.complexSchedule.start_date = this.momentFormatDate.toISO();
    this.complexSchedule.end_date = this.momentFormatDate.toISO();
    this.complexSchedule.start_time = this.momentFormatDate.toISO();
    this.complexSchedule.end_time = this.momentFormatDate.plus({ minute: ROLLOVER_PERIOD_MIN }).toISO();
    this.complexSchedule.intervalDuration = '';
    // this.presentToast(this.trans.str.vpp.RolloverHappenSelectTimeAgain);
    this.ionStartMinDate = this.momentFormatDate.toISO();
    this.ionEndMinDate = this.momentFormatDate.toISO();
    this.startMinTime = this.momentFormatDate.toISO();
    this.endMinTime = this.momentFormatDate.plus({ minute: ROLLOVER_PERIOD_MIN }).toISO();
    this.endTimeChange(this.complexSchedule.start_time, this.complexSchedule.end_time);
  }
  private setMomentFormatDateValue() {
    // FORMAT 2019-09-27T11:30:00
    const date = new Date();
    let min = date.getMinutes();
    let hours: string | number = date.getHours();
    if (min >= 60 - ROLLOVER_PERIOD_MIN) {
      min = 0;
      if (hours == 23) hours = '00';
      else hours += 1;
    } else if (min % ROLLOVER_PERIOD_MIN != 0) {
      const difference = min % ROLLOVER_PERIOD_MIN;

      min = ROLLOVER_PERIOD_MIN - difference + min;
    } else {
      min += ROLLOVER_PERIOD_MIN;
    }
    this.momentFormatDate = DateTime.fromObject({
      hour: typeof hours === 'string' ? parseInt(hours) : hours,
      minute: min,
    });
  }
  isDateIsToday(selectedDate: DateTime) {
    const todayDate = DateTime.now();
    return (
      todayDate.hasSame(selectedDate, 'day') &&
      todayDate.hasSame(selectedDate, 'month') &&
      todayDate.hasSame(selectedDate, 'year')
    );
  }
  isDateIsPast(date) {
    // past date
    const selectedDate = moment(date);
    const todayDate = moment(new Date().toISOString());
    return selectedDate.isBefore(todayDate, 'day');
  }

  startDateChange(dateString: string) {
    const date = DateTime.fromISO(dateString);

    this.setMomentFormatDateValue();
    this.complexSchedule.end_date = date.toISO();
    this.ionEndMinDate = date.toISO();
    if (this.isDateIsToday(date)) {
      this.startMinTime = this.momentFormatDate.toISO();
      this.endMinTime = this.momentFormatDate.plus({ minute: ROLLOVER_PERIOD_MIN }).toISO();
      this.complexSchedule.start_time = this.momentFormatDate.toISO();
      this.complexSchedule.end_time = this.momentFormatDate.plus({ minute: ROLLOVER_PERIOD_MIN }).toISO();
    } else {
      this.startMinTime = this.momentFormatDate.set({ hour: 0, minute: 0, second: 0 }).toISO();
      this.endMinTime = this.momentFormatDate
        .set({ hour: 0, minute: 0, second: 0 })
        .plus({ minute: ROLLOVER_PERIOD_MIN })
        .toISO();
      this.complexSchedule.start_time = this.momentFormatDate.set({ hour: 0, minute: 0, second: 0 }).toISO();
      this.complexSchedule.end_time = this.momentFormatDate
        .set({ hour: 0, minute: 0, second: 0 })
        .plus({ minute: ROLLOVER_PERIOD_MIN })
        .toISO();
    }

    this.complexSchedule.intervalDuration = '';
  }

  startTimeChange(timeString: string) {
    // console.log('Time: ', time);
    const time = DateTime.fromISO(timeString);
    this.endMinTime = time.plus({ minute: ROLLOVER_PERIOD_MIN }).toISO();
    // console.log('this.endMinTime: ', this.endMinTime);
    this.complexSchedule.start_time = time.toISO();
    this.complexSchedule.end_time = time.plus({ minute: ROLLOVER_PERIOD_MIN }).toISO();
    this.endTimeChange(this.complexSchedule.start_time, this.complexSchedule.end_time);
  }

  endDateChange(dateString: string) {
    this.complexSchedule.end_date = dateString;
  }

  endTimeChange(startTime: string, endTime: string) {
    if (this.complexSchedule.tillEndOfDay)
      endTime = DateTime.now().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).toISO();
    else this.complexSchedule.end_time = endTime;
    if (
      startTime != undefined &&
      startTime != '' &&
      startTime.length > 0 &&
      endTime != undefined &&
      endTime != '' &&
      endTime.length > 0
    ) {
      // console.log('After startTime: ', startTime, ' endTime: ', endTime);
      const end = DateTime.fromISO(endTime);
      const start = DateTime.fromISO(startTime);
      // console.log('start: ', start, ' end: ', end);

      const { hours, minutes, seconds } = end.diff(start, ['hours', 'minutes', 'seconds']).toObject();

      this.complexSchedule.intervalDuration = '';
      // use hours minutes seconds from duration object
      // and create intervalDuration string as 1 hour & 30 minutes 0 seconds
      // and make sure to add hours, minutes and seconds if their value is greater than 0

      this.complexSchedule.intervalDuration = hours > 0 ? hours + (hours > 1 ? ' hours' : ' hour') : '';

      this.complexSchedule.intervalDuration +=
        hours > 0 && minutes > 0 && seconds > 0 ? ', ' : hours > 0 && minutes > 0 ? ' & ' : '';
      this.complexSchedule.intervalDuration += minutes > 0 ? minutes + (minutes > 1 ? ' minutes' : ' minute') : '';

      this.complexSchedule.intervalDuration += minutes > 0 && seconds > 0 ? ' & ' : '';
      this.complexSchedule.intervalDuration += seconds > 0 ? seconds + (seconds > 1 ? ' seconds' : ' second') : '';
    } else {
      this.complexSchedule.intervalDuration = '';
    }
  }
  toggleTillEndOfDay(value) {
    // console.log(value);
    this.complexSchedule.endTimeDisable = value;
    // this.complexSchedule.end_time = "23:59";
    this.endTimeChange(this.complexSchedule.start_time, this.complexSchedule.end_time);
  }
  setTheTimeToZeroSeconds(time) {
    // the ionic datetime picker attach some minor seconds with the time, have to remove that to make it to exact 15:05:00 instead of 15:05:03 or something like that
    const date = new Date(time);
    const min = date.getMinutes();
    const hours = date.getHours();
    const momentFormatDate = new Date(
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + hours + ':' + min + ':00',
    );
    return moment(momentFormatDate.toISOString()).format('YYYY-MM-DDTHH:mm:ss');
  }
  yearlyStartMonthChange(yearValue) {
    this.selectedYearStartMonthDays = this.numberOfDays[yearValue];
    this.complexSchedule.yearlyStartMonthDay = null;
  }
  yearlyEndMonthChange(yearValue) {
    this.selectedYearEndMonthDays = this.numberOfDays[yearValue];
    this.complexSchedule.yearlyEndMonthDay = null;
  }

  createComplexSchedule(data) {
    const obj = {
      name: data.name,
      description: data.description,
      recurrence: data.recurrence,
      start_date: moment(data.start_date).format('YYYY-MM-DD'),
      end_date: moment(data.end_date).format('YYYY-MM-DD'),
      frequency_value: 0,
    };

    switch (obj.recurrence) {
      case this.VppScheduleRecurrencePattern.ONCE:
        break;
      case this.VppScheduleRecurrencePattern.DAILY:
        obj['intervals'] = [
          {
            start_time: moment(data.start_time).format('HH:mm'),
            end_time: this.complexSchedule.tillEndOfDay ? '23:59:59' : moment(data.end_time).format('HH:mm'),
          },
        ];
        obj.frequency_value = data.frequency_value_daily;
        break;
      case this.VppScheduleRecurrencePattern.WEEKLY:
        obj.frequency_value = data.frequency_value_weekly;
        obj['weekly_schedules'] = [];
        this.days.forEach((day) => {
          if (day.isChecked) {
            obj['weekly_schedules'].push({
              day: day.name,
              intervals: [
                {
                  start_time: moment(data.start_time).format('HH:mm'),
                  end_time: this.complexSchedule.tillEndOfDay ? '23:59:59' : moment(data.end_time).format('HH:mm'),
                },
              ],
            });
          }
        });
        break;
      case this.VppScheduleRecurrencePattern.MONTHLY:
        obj.frequency_value = data.frequency_value_monthly;
        obj['monthly_schedules'] = [];
        obj['monthly_schedules'].push({
          day_number: data.day_number,
          intervals: [
            {
              start_time: moment(data.start_time).format('HH:mm'),
              end_time: this.complexSchedule.tillEndOfDay ? '23:59:59' : moment(data.end_time).format('HH:mm'),
            },
          ],
        });
        break;
      case this.VppScheduleRecurrencePattern.YEARLY:
        obj['yearly_schedules'] = [];
        obj['yearly_schedules'].push({
          start_month: data.yearlyStartMonth,
          start_day_number: data.yearlyStartMonthDay,
          end_month: data.yearlyEndMonth,
          end_day_number: data.yearlyEndMonthDay,
          intervals: [
            {
              start_time: moment(data.start_time).format('HH:mm'),
              end_time: this.complexSchedule.tillEndOfDay ? '23:59:59' : moment(data.end_time).format('HH:mm'),
            },
          ],
        });
        break;
      default:
        break;
    }
    // console.log('obj: ', obj);
    // return;
    if (!this.editSchedule) {
      this.createLoader(this.trans.str.vpp.CreatingSchedule + ', ' + this.trans.str.general.Wait).then((loader) => {
        loader.present();
        this.vppService.createvPPSchedule(this.vppService.selectedVpp.id, obj).then(
          () => {
            this.presentToast(this.trans.str.vpp.ScheduleCreateSuccess);
            loader.dismiss();
            this.dismiss(true);
          },
          (error) => {
            loader.dismiss();
            let msg = this.trans.str.vpp.InviteUser.UserFail.CreateMsg;
            if (error.hasOwnProperty('status')) {
              msg = msg + ' ' + error.status + ' ' + error.statusText + '. ';
            } else {
              msg += JSON.stringify(error);
            }
            this.presentAlertSimpleOk(
              this.trans.str.vpp.InviteUser.UserFail.Header,
              this.trans.str.vpp.ScheduleCreateFailed,
              msg,
            );
          },
        );
      });
    } else {
      obj['id'] = this.scheduleToUpdate.id;
      this.createLoader(this.trans.str.vpp.UpdatingSchedule + ', ' + this.trans.str.general.Wait).then((loader) => {
        loader.present();
        this.vppService.updateSchedule(obj).then(
          () => {
            this.presentToast(this.trans.str.vpp.ScheduleUpdateSuccess);
            loader.dismiss();
            this.dismiss(true);
          },
          (error) => {
            loader.dismiss();
            let msg = this.trans.str.vpp.InviteUser.UserFail.CreateMsg;
            if (error.hasOwnProperty('status')) {
              msg = msg + ' ' + error.status + ' ' + error.statusText + '. ';
            } else {
              msg += JSON.stringify(error);
            }
            this.presentAlertSimpleOk(
              this.trans.str.vpp.InviteUser.UserFail.Header,
              this.trans.str.vpp.ScheduleUpdateFailed,
              msg,
            );
          },
        );
      });
    }
  }
  disableUpdate(pattern) {
    let returnValue = true;

    if (
      this.complexSchedule.name == '' ||
      this.complexSchedule.name.length <= 0 ||
      this.complexSchedule.start_time == '' ||
      this.complexSchedule.start_time.length <= 0 ||
      this.complexSchedule.start_date == '' ||
      this.complexSchedule.start_date.length <= 0 ||
      this.complexSchedule.end_date == '' ||
      this.complexSchedule.end_date.length <= 0
    )
      return returnValue;
    if (
      !this.complexSchedule.tillEndOfDay &&
      (this.complexSchedule.end_time == '' || this.complexSchedule.end_time.length <= 0)
    ) {
      return returnValue;
    }
    switch (pattern) {
      case this.VppScheduleRecurrencePattern.ONCE:
        break;
      case this.VppScheduleRecurrencePattern.DAILY:
        returnValue = false;
        break;
      case this.VppScheduleRecurrencePattern.WEEKLY:
        for (let index = 0; index < this.days.length; index++) {
          const element = this.days[index];
          if (element.isChecked) returnValue = false;
        }
        break;
      case this.VppScheduleRecurrencePattern.MONTHLY:
        if (this.complexSchedule.day_number != null) returnValue = false;
        break;

      case this.VppScheduleRecurrencePattern.YEARLY:
        if (
          this.complexSchedule.yearlyEndMonth.length > 0 &&
          this.complexSchedule.yearlyStartMonth.length > 0 &&
          this.complexSchedule.yearlyStartMonthDay != null &&
          this.complexSchedule.yearlyEndMonthDay != null
        )
          returnValue = false;
        break;
      default:
        break;
    }
    return returnValue;
  }

  createLoader(message) {
    return this.loadingController.create({
      message: message,
    });
  }
  async presentAlertSimpleOk(header, subheader, message) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }
  presentToast(message, duration = 3000) {
    this.toast
      .create({
        message: message,
        duration: duration,
        position: 'top',
      })
      .then((toast) => {
        toast.present();
      });
  }
  captureTabChange(value: number): void {
    this.selectedTabIndex = value;
    const key = VPP_SETTING_CREATE_SCHEDULE_SUB_TABS[value].label.toUpperCase();
    this.complexSchedule.recurrence = this.VppScheduleRecurrencePattern[key];
  }
}
