import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslationsService } from '../../../services/common/translations.service';
import { TimePeriodResolution } from '@class/commons/constants-datetime';
import {
  SiteEnergyGranularityOptions,
  UnitCompositionBarChartStateData,
  UnitEnergyStore,
} from '@service/units/unit-energy.store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-site-energy-card',
  templateUrl: './site-energy-card.component.html',
  styleUrls: ['./site-energy-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteEnergyCardComponent {
  readonly timePeriods: {
    name: string;
    value: SiteEnergyGranularityOptions;
  }[] = [
    {
      name: this.translationsService.instant('General.Day') + ' View',
      value: TimePeriodResolution.DAY,
    },
    {
      name: this.translationsService.instant('General.Week') + ' View',
      value: TimePeriodResolution.WEEK,
    },
    {
      name: this.translationsService.instant('General.Month') + ' View',
      value: TimePeriodResolution.MONTH,
    },
    {
      name: this.translationsService.instant('General.Year') + ' View',
      value: TimePeriodResolution.YEAR,
    },
  ];

  unitCompositionBarChartStore$: Observable<UnitCompositionBarChartStateData>;

  constructor(
    private translationsService: TranslationsService,
    private _unitEnergyStore: UnitEnergyStore,
  ) {
    this.unitCompositionBarChartStore$ = this._unitEnergyStore.unitCompositionBarChartStore$;
  }

  capitalizeTimePeriodResolution = (timePeriodResolution: string): string =>
    timePeriodResolution ? timePeriodResolution.charAt(0).toUpperCase() + timePeriodResolution.slice(1) : '';

  dateChanged(dateString: string) {
    this._unitEnergyStore.compositionBarChartDateChange(dateString);
  }

  periodChanged(timePeriod: SiteEnergyGranularityOptions): void {
    this._unitEnergyStore.compositionBarChartPeriodChange(timePeriod);
  }
}
