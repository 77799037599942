import { Injectable, OnDestroy } from '@angular/core';
import {
  DeviceControlOrParam,
  DropletDeviceControl,
} from '../../../classes/units/droplet/droplet-device-control.model';
import { ApiWrapper } from '../../common/api-wrapper.service';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../../classes/commons/request-api.model';
import { UnitsService } from '../units.service';
import { BehaviorSubject, combineLatest, Observable, Subscription, throwError } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, take, timeoutWith, tap } from 'rxjs/operators';
import { TranslationsService } from '../../common/translations.service';
import { DeviceDetailMessageState } from '../../../components/device-detail-message/device-detail-message.component';
import { BrowserLogger } from '@class/core/browser-logger';

export interface DeviceControlStatus {
  state: DeviceDetailMessageState;
  title: string;
  subtitle: string[];
  mqttResponseRetries: number;
}
interface ControlValueApiPayload {
  control_name: string;
  control_value: number | string;
  control_type: string;
  control_id: string;
  is_iec61850: boolean;
}

export enum DeviceControlBackendState {
  CONTROL_VALUE_NOT_SENT = 0,
  WAITING_FOR_BACKEND_RESPONSE = 1,
  WAITING_FOR_MQTT_RESPONSE = 2,
  RECEIVED_MQTT_RESPONSE = 3,
}
enum DeviceControlType {
  CONTROL = 'control',
  SETTING = 'setting',
}

export interface DeviceControlState {
  endpointUUID: string | undefined;
  control: DeviceControlOrParam | undefined;
  endpointMqttValue: Observable<number> | undefined; // NOTE: currently only supports NUMERIC/TOGGLE controls as we have no STRING controls
  isDisableInput: boolean;
  status: DeviceControlStatus;
  isBackendAndMqttNotSynced: Observable<boolean> | undefined;
}

@Injectable()
export class DeviceControlService implements OnDestroy {
  private _state: DeviceControlState = {
    endpointUUID: undefined,
    control: undefined,
    endpointMqttValue: undefined,
    status: {
      state: DeviceDetailMessageState.IDLE,
      title: undefined,
      subtitle: undefined,
      mqttResponseRetries: undefined,
    },
    isDisableInput: true,
    isBackendAndMqttNotSynced: undefined,
  };
  private store = new BehaviorSubject<DeviceControlState>(this._state);
  private state$ = this.store.asObservable();
  private checkSync$: BehaviorSubject<boolean>;
  readonly DROPLET_MQTT_RESPONSE_TIMEOUT_MS = 30000;

  endpointUUID$ = this.state$.pipe(
    map((state) => state.endpointUUID),
    distinctUntilChanged(),
  );
  control$ = this.state$.pipe(
    map((state) => state.control),
    distinctUntilChanged(),
  );
  endpointMqttValue$ = this.state$.pipe(
    map((state) => state.endpointMqttValue),
    distinctUntilChanged(),
  );
  status$ = this.state$.pipe(
    map((state) => state.status),
    distinctUntilChanged(),
  );
  isDisableInput$ = this.state$.pipe(
    map((state) => state.isDisableInput),
    distinctUntilChanged(),
  );
  isBackendAndMqttNotSynced$ = this.state$.pipe(
    map((state) => state.isBackendAndMqttNotSynced),
    distinctUntilChanged(),
  );
  mqttValue: number | string;
  mqttEditValue: number | string;
  updateMqttValueSubs: Subscription;
  /**
   * Viewmodel that resolves once all the data is ready (or updated)...
   */
  vm$: Observable<DeviceControlState> = combineLatest([
    this.endpointUUID$,
    this.control$,
    this.endpointMqttValue$,
    this.status$,
    this.isDisableInput$,
    this.isBackendAndMqttNotSynced$,
  ]).pipe(
    map(([endpointUUID, control, endpointMqttValue, status, isDisableInput, isBackendAndMqttNotSynced]) => {
      return { endpointUUID, control, endpointMqttValue, status, isDisableInput, isBackendAndMqttNotSynced };
    }),
  );

  constructor(
    private unitsService: UnitsService,
    private api: ApiWrapper,
  ) {}

  setDeviceControl(control: DeviceControlOrParam): void {
    BrowserLogger.log('DeviceControlService.setDeviceControl', {
      metric: control.statusKeyWithDeviceNumber,
      control,
    });
    this.updateState({ ...this._state, control });
    if (control.statusKeyWithDeviceNumber) {
      // listen for mqtt messages for this control (via units service '/metrics') and return as a number
      // todo - what about non numeric (toggle/string) controls? do we need to upgrade support for these later
      const mqttValueObs: Observable<number> = this.unitsService.unitMetricsMqtt.mqttSubject.asObservable().pipe(
        filter((metrics) => metrics && metrics['droplet_timestamp'] != null), // make sure a valid droplet message
        map((metrics) => metrics?.[control?.statusKeyWithDeviceNumber] as number), // map to a number. note: when we get a mqtt message without the control key then that is deemed to be in 'auto' mode, and will return undefined
      );
      this.updateMqttValueSubs = combineLatest([mqttValueObs, this.isDisableInput$]).subscribe(([val, isDisable]) => {
        this.mqttValue = val;
        //only update toggle value if not busy (not disabled)
        if (!isDisable) {
          this.mqttEditValue = this.mqttValue;
        }
      });

      let notSync$: Observable<boolean>;
      this.checkSync$ = new BehaviorSubject(true);
      if (control.isSetting) {
        notSync$ = combineLatest([this.checkSync$, mqttValueObs]).pipe(
          filter(([checkSync, value]) => checkSync && value != null),
          map(([checkSync, value]) => value != control.backendValue),
        );
      }
      this.updateState({
        ...this._state,
        endpointMqttValue: mqttValueObs,
        isDisableInput: false,
        isBackendAndMqttNotSynced: notSync$,
      });
    }
  }

  setEndpointUUID(endpointUUID: string): void {
    BrowserLogger.log('DeviceControlService.setEndpointUUID', { endpointUUID });
    this.updateState({ ...this._state, endpointUUID });
  }

  async sendControlToDroplet(value: number | string): Promise<void> {
    BrowserLogger.log('DeviceControlService.sendControlToDroplet', { value, state: this._state });
    try {
      this.beforeProcessingControl();
      value = DropletDeviceControl.castValueToCorrectType(this._state.control.type, value);
      if (this.isControlValueInvalid(this._state.control, value)) {
        this.updateState({ ...this._state, isDisableInput: false });
        this.mqttEditValue = this.mqttValue;
        return Promise.resolve();
      }

      const sentPayload = await this.sendControlAndAwaitBackendResponse(value);
      const response = await this.receiveAndUpdateMqttResponse(sentPayload);
      this.AfterControlSuccess(response, this._state.control);
    } catch (err) {
      this.AfterControlError();
    }
  }

  // ------- private methods
  private updateState(state: DeviceControlState) {
    BrowserLogger.log('DeviceControlService.updateState', { state });
    this.store.next((this._state = state));
  }

  private beforeProcessingControl() {
    BrowserLogger.log('DeviceControlService.beforeProcessingControl');
    this.checkSync$.next(false);

    this.updateState({
      ...this._state,
      isDisableInput: true,
      status: {
        state: DeviceDetailMessageState.LOADING,
        title: undefined,
        subtitle: undefined,
        mqttResponseRetries: undefined,
      },
    });
  }

  public isControlValueInvalid(control: DeviceControlOrParam, value: number | string | null): boolean {
    const valueCheckResult = DropletDeviceControl.checkValueWithMqtt(control, value, this.mqttValue);
    if (!valueCheckResult.isValid) {
      this.updateState({
        ...this._state,
        status: {
          ...this._state.status,
          title: valueCheckResult.title,
          subtitle: [valueCheckResult.message],
          state: DeviceDetailMessageState.ERROR,
        },
      });
      return true;
    }
    if (this.checkIfSameValueBeforeSendingDeviceControl(value)) {
      return true;
    }
    return false;
  }

  public isControlValueValid(control: DeviceControlOrParam, value: number | string | null): boolean {
    // an inverse of isControlValueInvalid() so code can be made clearer when doing valid tests
    return !this.isControlValueInvalid(control, value);
  }

  private async sendControlAndAwaitBackendResponse(value: number | string | null): Promise<ControlValueApiPayload> {
    BrowserLogger.log('DeviceControlService.sendControlAndAwaitBackendResponse', { value });
    this.updateState({
      ...this._state,
      status: {
        state: DeviceDetailMessageState.LOADING,
        title: 'UnitPage.Sending',
        subtitle: ['UnitPage.RequestSent'],
        mqttResponseRetries: 0,
      },
      isDisableInput: true,
    });
    const payload = this.prepareApiData(this._state.control, value);
    await this.updateControlValueApi(payload, this._state.endpointUUID);
    return payload;
  }

  private prepareApiData(control: DeviceControlOrParam, controlValue: number | string | null): ControlValueApiPayload {
    const result: ControlValueApiPayload = {
      control_name: control.setKey,
      control_value: controlValue,
      control_type: control.isSetting ? DeviceControlType.SETTING : DeviceControlType.CONTROL,
      control_id: control.id,
      is_iec61850: control.isIec61850,
    };
    BrowserLogger.log('DeviceControlService.prepareApiData', { result, control, controlValue });
    return result;
  }

  private updateControlValueApi(data: ControlValueApiPayload, uuid: string): Promise<any> {
    BrowserLogger.log('DeviceControlService.updateControlValueApi', { data, uuid });
    if (!uuid) {
      BrowserLogger.error('DeviceControlService.updateControlValueApi', 'NO UUID!');
    }
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/endpoints/' + uuid + '/send_control/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  private async receiveAndUpdateMqttResponse(payload: ControlValueApiPayload): Promise<number> {
    BrowserLogger.log('DeviceControlService.receiveAndUpdateMqttResponse', { payload });
    this.updateState({
      ...this._state,
      status: {
        ...this._state.status,
        title: 'UnitPage.Listening',
        subtitle: ['UnitPage.WaitingConfirmationFromDroplet'],
      },
    });
    const mqttResponse = await this.awaitEndpointMqttResponse(payload.control_value);
    return Promise.resolve(mqttResponse);
  }

  private awaitEndpointMqttResponse(expectedValue: number | string | null): Promise<number> {
    return new Promise((resolve, reject) => {
      BrowserLogger.log('DeviceControlService.awaitEndpointMqttResponse', { expectedValue });
      this.updateState({ ...this._state, status: { ...this._state.status, mqttResponseRetries: 0 } });
      this._state.endpointMqttValue
        .pipe(
          timeoutWith(
            this.DROPLET_MQTT_RESPONSE_TIMEOUT_MS,
            throwError('Failed to receive control confirmation response from droplet mqtt before timeout'),
          ),
          tap(() => this.incrementAndCheckMqttAttempts()),
          filter((val) => val == expectedValue),
          take(1),
        )
        .subscribe(
          (metricValue) => {
            resolve(metricValue);
          },
          () => {
            reject();
          },
        );
    });
  }

  private incrementAndCheckMqttAttempts() {
    BrowserLogger.log('DeviceControlService.incrementAndCheckMqttAttempts');
    this.updateState({
      ...this._state,
      status: { ...this._state.status, mqttResponseRetries: this._state.status.mqttResponseRetries + 1 },
    });
    if (this._state.status.mqttResponseRetries > 3) {
      throw new Error('Failed to receive control confirmation response from droplet mqtt before maximum retry limit');
    }
  }

  private checkIfSameValueBeforeSendingDeviceControl(value: number | string): boolean {
    let result = false;
    if (this.mqttValue == value) {
      result = true;
    }
    BrowserLogger.log('DeviceControlService.checkIfSameValueBeforeSendingDeviceControl', {
      result,
      value,
      mqttValue: this.mqttValue,
    });
    return result;
  }

  private AfterControlError() {
    BrowserLogger.log('DeviceControlService.AfterControlError');
    this.mqttEditValue = this.mqttValue;
    const subtitles = this._state.status.subtitle;
    subtitles.push('UnitPage.ControlFailed');
    this.updateState({
      ...this._state,
      status: {
        ...this._state.status,
        title: 'UnitPage.Failed',
        subtitle: subtitles,
        state: DeviceDetailMessageState.ERROR,
      },
      endpointMqttValue: this._state.endpointMqttValue,
      isDisableInput: false,
    });
    this.checkSync$.next(true);
  }

  private AfterControlSuccess(successfulValue: number, control: DeviceControlOrParam) {
    BrowserLogger.log('DeviceControlService.AfterControlSuccess');
    if (control.isSetting) {
      control.backendValue = successfulValue;
    }
    this.checkSync$.next(true);

    this.updateState({
      ...this._state,
      status: {
        ...this._state.status,
        state: DeviceDetailMessageState.SUCCESS,
        title: 'UnitPage.Successful',
        subtitle: ['UnitPage.WaitingConfirmationReceived'],
      },
      isDisableInput: false,
    });
  }

  ngOnDestroy(): void {
    BrowserLogger.log('DeviceControlService.ngOnDestroy');
    if (this.updateMqttValueSubs) {
      this.updateMqttValueSubs.unsubscribe();
    }
  }
}
