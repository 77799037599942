import { Component, Input } from '@angular/core';
import { DropletService, DeviceState } from '../../services/units/endpoints/devices.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { DropletItemMode } from '@class/commons/constants';
import { ThemeService } from '@service/themes/theme.service';
import { ThemeMode } from '@theme/theme-map';

@Component({
  selector: 'app-devices-service-status',
  templateUrl: './devices-service-status.component.html',
  styleUrls: ['./devices-service-status.component.scss'],
})
export class DevicesServiceStatusComponent {
  @Input() mode: DropletItemMode = DropletItemMode.DEVICE;

  vm$: Observable<DeviceState> = this.dropletService.vm$;

  readonly PermissionKey = PermissionKey;
  readonly DropletItemMode = DropletItemMode;
  readonly textColourClass: string;

  constructor(
    private dropletService: DropletService,
    private router: Router,
    private themeService: ThemeService,
  ) {
    this.textColourClass = this.themeService.$userTheme.type === ThemeMode.DARK ? 'text-dark' : 'text-medium';
  }

  public dismissError(): void {
    this.dropletService.acknowledgeError();
  }

  public navigateToInstaller(): void {
    this.router.navigateByUrl('/configure');
  }
}
