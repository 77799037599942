import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { VppDemandTypes, VppModes } from '../../../../classes/vpp/vpp-demand-type-mode.model';

@Injectable({
  providedIn: 'root',
})
export class VppOverviewFacadeService {
  private vppOverviewModeDemandTypeSubject$ = new BehaviorSubject<{
    mode: VppModes;
    demandType: VppDemandTypes;
    combined: VppModes | VppDemandTypes;
  }>(null);
  public overviewDemandType: VppDemandTypes;
  public overviewMode: VppModes;
  public vppOverviewModeDemandType$ = this.vppOverviewModeDemandTypeSubject$.pipe(distinctUntilChanged());
  constructor() {
    this.changeOverviewModeAndDemandType(VppDemandTypes.DEMAND_INCREASE);
  }

  public changeOverviewModeAndDemandType(value: VppModes | VppDemandTypes): void {
    let selectedMode: VppModes = null;
    let selectedDemandType: VppDemandTypes = null;
    if (value === VppDemandTypes.DEMAND_INCREASE || value === VppDemandTypes.DEMAND_REDUCTION) {
      selectedDemandType = value;
      selectedMode = VppModes.DRM;
    } else if (value === VppModes.FCAS) {
      selectedMode = VppModes.FCAS;
    }

    this.overviewMode = selectedMode;
    this.overviewDemandType = selectedDemandType;
    this.vppOverviewModeDemandTypeSubject$.next({
      mode: selectedMode,
      demandType: selectedDemandType,
      combined: value,
    });
  }
}
