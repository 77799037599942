import { Pipe, PipeTransform } from '@angular/core';
import { DateFormats, TimePeriodResolution } from '@class/commons/constants-datetime';
import { TranslationsService } from '../services/common/translations.service';
import { DateTime } from 'luxon';

@Pipe({
  name: 'dateLabel',
})
export class DateLabelPipe implements PipeTransform {
  inputDate: DateTime;
  luxonNow: DateTime;
  constructor(private trans: TranslationsService) {}

  transform(date: DateTime, period: TimePeriodResolution): string {
    if (!date) {
      return '';
    }

    this.inputDate = date;

    // setting the local time zone to unit timezone
    this.setLocalTimeZone();

    switch (period) {
      case TimePeriodResolution.DAY:
        return this.transformPeriodFullDate();
      case TimePeriodResolution.WEEK:
        return this.transformPeriodWeek();
      case TimePeriodResolution.MONTH:
        return this.transformPeriodMonth();
      case TimePeriodResolution.YEAR:
        return this.transformPeriodYear();
      default:
        return '';
    }
  }

  private setLocalTimeZone(): void {
    const timezone = this.inputDate.zoneName;
    this.luxonNow = timezone ? DateTime.local().setZone(timezone) : DateTime.local();
  }

  private transformPeriodFullDate(): string {
    const selectedDayMonthYear = this.inputDate.toFormat(DateFormats.DAY_MONTH_YEAR_LUXON);
    if (selectedDayMonthYear === this.luxonNow.toFormat(DateFormats.DAY_MONTH_YEAR_LUXON)) {
      return this.trans.instant('Calendars.Today');
    } else if (
      selectedDayMonthYear ===
      this.luxonNow.minus({ [TimePeriodResolution.DAYS]: 1 }).toFormat(DateFormats.DAY_MONTH_YEAR_LUXON)
    ) {
      return this.trans.instant('Calendars.Yesterday');
    } else {
      return this.inputDate.toFormat(DateFormats.DAY_NAME_LUXON);
    }
  }

  private transformPeriodWeek(): string {
    const selectedWeekMonthYear = this.inputDate.toFormat(DateFormats.DAY_MONTH_YEAR_LUXON);
    if (
      selectedWeekMonthYear ===
      this.luxonNow.startOf(TimePeriodResolution.WEEK).toFormat(DateFormats.DAY_MONTH_YEAR_LUXON)
    ) {
      return this.trans.instant('Calendars.ThisWeek');
    } else if (
      selectedWeekMonthYear ===
      this.luxonNow
        .startOf(TimePeriodResolution.WEEK)
        .minus({ [TimePeriodResolution.WEEKS]: 1 })
        .toFormat(DateFormats.DAY_MONTH_YEAR_LUXON)
    ) {
      return this.trans.instant('Calendars.LastWeek');
    } else {
      return '';
    }
  }

  private transformPeriodMonth(): string {
    const selectedMonthYear = this.inputDate.toFormat(DateFormats.MONTH_YEAR);
    if (selectedMonthYear === this.luxonNow.startOf(TimePeriodResolution.MONTH).toFormat(DateFormats.MONTH_YEAR)) {
      return this.trans.instant('Calendars.ThisMonth');
    } else if (
      selectedMonthYear ===
      this.luxonNow
        .startOf(TimePeriodResolution.MONTH)
        .minus({ [TimePeriodResolution.MONTH]: 1 })
        .toFormat(DateFormats.MONTH_YEAR)
    ) {
      return this.trans.instant('Calendars.LastMonth');
    }
    return '';
  }

  private transformPeriodYear(): string {
    if (this.luxonNow.year === this.inputDate.year) {
      return this.trans.instant('Calendars.ThisYear');
    } else if (this.luxonNow.minus({ [TimePeriodResolution.YEAR]: 1 }).year === this.inputDate.year) {
      return this.trans.instant('Calendars.LastYear');
    }
    return '';
  }
}
