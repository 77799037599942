import { Injectable } from '@angular/core';
import { States } from '@class/commons/constants';
import { AvailableAPI, RequestMethod, UseHeaderType } from '@class/commons/request-api.model';
import { HTTP_ERRORS_TRANSLATION_HEADING, generateHttpErrorMessage } from '@class/error/http-error-types';
import { ApiWrapper } from '@service/common/api-wrapper.service';
import { TranslationsService } from '@service/common/translations.service';
import {
  BehaviorSubject,
  Observable,
  catchError,
  distinctUntilKeyChanged,
  filter,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { UnitStore } from './unit.store';
import { DateTime } from 'luxon';

/**
 * Sample Api Response
 * {
    "coord": {
        "lon": 151.7218,
        "lat": -32.9402
    },
    "weather": [
        {
            "id": 800,
            "main": "Clear",
            "description": "clear sky",
            "icon": "01d"
        }
    ],
    "base": "stations",
    "main": {
        "temp": 291.18,
        "feels_like": 290.4,
        "temp_min": 289.04,
        "temp_max": 292.9,
        "pressure": 1026,
        "humidity": 52
    },
    "visibility": 10000,
    "wind": {
        "speed": 0.89,
        "deg": 321,
        "gust": 3.13
    },
    "clouds": {
        "all": 0
    },
    "dt": 1690851207,
    "sys": {
        "type": 2,
        "id": 2009459,
        "country": "AU",
        "sunrise": 1690836261,
        "sunset": 1690874068
    },
    "timezone": 36000,
    "id": 2205628,
    "name": "Lambton",
    "cod": 200
}
 */
interface UnitWeatherApi {
  coord: {
    lon: number;
    lat: number;
  };
  weather: [
    {
      id: number;
      main: string;
      description: string;
      icon: string;
    },
  ];
  base: string;
  main: {
    temp: number;
    feels_like: number;
    temp_min: number;
    temp_max: number;
    pressure: number;
    humidity: number;
  };
  visibility: number;
  wind: {
    speed: number;
    deg: number;
    gust: number;
  };
  clouds: {
    all: number;
  };
  dt: number;
  sys: {
    type: number;
    id: number;
    country: string;
    sunrise: number;
    sunset: number;
  };
  timezone: number;
  id: number;
  name: string;
  cod: number;
}
interface UnitWeather {
  icon: string;
  description: string;
  name: string;
  temp: number;
  min: number;
  max: number;
  sunrise: string;
  sunset: string;
  duration: string;
}
export interface UnitWeatherStateData {
  data: UnitWeather;
  status: States;
  error?: string;
  message?: string;
}
const UNIT_CARBON_SAVING_INITIAL_STATE: UnitWeatherStateData = {
  status: States.INITIAL_LOADING,
  data: {
    icon: '',
    description: '',
    name: '',
    temp: 0,
    min: 0,
    max: 0,
    sunrise: '0',
    sunset: '0',
    duration: '0',
  },
};

@Injectable({
  providedIn: 'root',
})
export class UnitWeatherStore {
  private _unitWeatherStore = new BehaviorSubject<UnitWeatherStateData>(UNIT_CARBON_SAVING_INITIAL_STATE);
  unitWeatherStore$ = this._unitWeatherStore.asObservable();

  constructor(
    private _api: ApiWrapper,
    private _translationsService: TranslationsService,
    private _unitStore: UnitStore,
  ) {
    this.getUnitWeather();
  }

  retryUnitWeather(): void {
    this.getUnitWeather();
  }

  // private methods
  private getUnitWeather() {
    this._unitStore.unit$
      .pipe(
        filter((unitData) => unitData.data !== null),
        distinctUntilKeyChanged('data', (prev, curr) => prev.uuid === curr.uuid),
        switchMap((unit) => this.getUnitWeatherApi(unit.data.latitude, unit.data.longitude)),
        map((unitWeather) => {
          const { name, weather, main, sys } = unitWeather;
          const sunrise = DateTime.fromMillis(sys.sunrise * 1000);
          const sunset = DateTime.fromMillis(sys.sunset * 1000);
          const duration = sunset.diff(sunrise).as('hours').toFixed(1);

          return {
            data: {
              name,
              icon: weather[0]?.icon,
              description: weather[0]?.description,
              temp: main.temp - 273.15,
              min: main.temp_min - 273.15,
              max: main.temp_max - 273.15,
              sunrise: sunrise.toLocaleString(DateTime.TIME_24_SIMPLE),
              sunset: sunset.toLocaleString(DateTime.TIME_24_SIMPLE),
              duration,
            },
            status: States.INITIAL_DATA_RECEIVED,
          };
        }),
        catchError((error) => this.handleError(error)),
        startWith(UNIT_CARBON_SAVING_INITIAL_STATE),
        tap((unitWeatherData) => {
          this._unitWeatherStore.next(unitWeatherData);
        }),
      )
      .subscribe();
  }
  private getUnitWeatherApi(latitude: number, longitude: number): Observable<UnitWeatherApi> {
    return this._api.handleObservableRequest({
      useAPI: AvailableAPI.WEATHER,
      url: `/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=08cc58f3733dc6765347d678c9d745f8`,
      requestMethod: RequestMethod.GET,
      useHeader: UseHeaderType.EMPTY,
    }) as Observable<UnitWeatherApi>;
  }
  private handleError(err, state: States = States.INITIAL_ERROR): Observable<UnitWeatherStateData> {
    const message = generateHttpErrorMessage(err, this._translationsService.instant(HTTP_ERRORS_TRANSLATION_HEADING));
    return of({
      status: state,
      error: message.message,
      data: {
        icon: '',
        description: '',
        name: '',
        temp: 0,
        min: 0,
        max: 0,
        sunrise: '0',
        sunset: '0',
        duration: '0',
      },
    });
  }
}
