import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BooleanStrings } from '@class/commons/enums';
import { isEmpty } from 'lodash';
import { DateTime, ToISOTimeOptions } from 'luxon';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, startWith, take } from 'rxjs/operators';
import { isVppDemandTypeFcas } from '../../../../classes/vpp/vpp-demand-type-mode.model';
import { VppDispatchModalData, VppOperationDispatchModalType } from '../../../../classes/vpp/vpp-dispatch.model';
import { VppFcasMarket } from '../../../../classes/vpp/vpp-fcas-markets.model';
import { VppControlGroupService } from '../../control-group/vpp-control-group.service';
import { VppDispatchService } from '../../dispatch/vpp-dispatch.service';
import { ROLLOVER_PERIOD_MIN, VppRollOverService } from '../../rollover/vpp-roll-over.service';
import { VppTimezoneService } from '../../timezone/vpp-timezone.service';

export enum VppModelStatus {
  LOADING = 0,
  DATA = 1,
  ERROR = 2,
  COMPLETE = 3,
}
export interface VppOperationScheduleModalViewModel {
  message: string;
  data: ViewModalData;
  status: VppModelStatus;
}

export interface ViewModalData extends VppDispatchModalData {
  fcasMarkets?: { options$: Promise<VppFcasMarket[]>; selected: VppFcasMarket[] };
  timezone: string;
  startMinTime?: string;
  endMinTime?: string;
}

@Injectable()
export class VppOperationScheduleModalFacadeService {
  private dispatch$ = new BehaviorSubject<VppDispatchModalData>(null);
  private vmSubject = new Subject<VppOperationScheduleModalViewModel>();
  public vm$: Observable<VppOperationScheduleModalViewModel> = merge(
    this.vmSubject,
    combineLatest([
      this.dispatch$,
      this.timezoneService.timezone$,
      this.rolloverService.rollOver$.pipe(startWith(0)),
    ]).pipe(
      map(([dispatch, tz]) => this.processDispatchIntoViewModelData(dispatch, tz)),
      filter((data) => !isEmpty(data)),
      map((data) => {
        return {
          status: VppModelStatus.DATA,
          message: null,
          data,
        };
      }),
      startWith({
        status: VppModelStatus.LOADING,
        message: 'VirtualPowerPlant.PreparingDispatch',
        data: null,
      }),
      catchError((e) => this.handleError(e)),
    ),
  );
  public dismiss$ = new Subject<void>();

  constructor(
    private timezoneService: VppTimezoneService,
    private rolloverService: VppRollOverService,
    private dispatchService: VppDispatchService,
    private controlGroupService: VppControlGroupService,
  ) {}

  private processDispatchIntoViewModelData(dispatch: VppDispatchModalData, timezone: string): ViewModalData {
    if (isEmpty(dispatch)) return null;
    switch (dispatch.modalType) {
      case VppOperationDispatchModalType.GRAPH_DISPATCH:
        return this.processGraphDispatchModalData(dispatch, timezone);
      case VppOperationDispatchModalType.NOW_DISPATCH:
        return this.processNowDispatchModalData(dispatch, timezone);
      case VppOperationDispatchModalType.CANCEL:
        return this.processCancelModalData(dispatch, timezone);

      default:
        throw new Error('Unknown modal');
    }
  }

  private processGraphDispatchModalData(dispatch: VppDispatchModalData, timezone: string): ViewModalData {
    const { demandType, controlGroupName, markets } = dispatch;
    const fcasMarkets = isVppDemandTypeFcas(demandType)
      ? {
          options$: this.findAvailableMarkets(controlGroupName),
          selected: markets,
        }
      : null;
    const data: ViewModalData = {
      ...dispatch,
      timezone,
      fcasMarkets,
    };
    return data;
  }

  private processNowDispatchModalData(dispatch: VppDispatchModalData, timezone: string): ViewModalData {
    const { demandType, controlGroupName, markets } = dispatch;
    let { startTime, endTime } = dispatch;
    const startMinTime = this.roundUpDateTimeToNearestRolloverPeriod(DateTime.local().setZone(timezone)).toISO({
      suppressMilliseconds: true,
    } as ToISOTimeOptions);
    startTime =
      startTime ?? this.roundUpDateTimeToNearestRolloverPeriod(DateTime.local().setZone(timezone)).toSeconds();
    const endMinTime = this.roundUpDateTimeToNearestRolloverPeriod(DateTime.fromSeconds(startTime))
      .setZone(timezone)
      .toISO({ includeOffset: false });
    endTime =
      endTime && endTime > startTime
        ? endTime
        : this.roundUpDateTimeToNearestRolloverPeriod(DateTime.fromSeconds(startTime)).setZone(timezone).toSeconds();
    const fcasMarkets = isVppDemandTypeFcas(demandType)
      ? {
          options$: this.findAvailableMarkets(controlGroupName),
          selected: markets,
        }
      : null;
    const data: ViewModalData = {
      ...dispatch,
      timezone,
      startMinTime,
      fcasMarkets,
      endMinTime,
      startTime,
      endTime,
      showEndTimeIonDateTimePicker: dispatch.showEndTimeIonDateTimePicker ?? true,
    };
    return data;
  }

  private roundUpDateTimeToNearestRolloverPeriod(datetime: DateTime): DateTime {
    const minutesToRollover = ROLLOVER_PERIOD_MIN - (datetime.minute % ROLLOVER_PERIOD_MIN);
    const roundedMin = datetime.minute + minutesToRollover;
    return datetime.set({ minute: roundedMin, second: 0, millisecond: 0 });
  }

  private processCancelModalData(dispatch: VppDispatchModalData, timezone: string): ViewModalData {
    const data: ViewModalData = {
      ...dispatch,
      timezone,
    };
    return data;
  }
  private async findAvailableMarkets(controlGroupName: string): Promise<VppFcasMarket[]> {
    const response = await this.controlGroupService.getAvailableMarketsWithControlGroupName(controlGroupName);
    return response?.data ?? [];
  }

  async setDispatchData(dispatch: VppDispatchModalData): Promise<void> {
    // this function is called from the modal component
    // and it set the dispatch data to the service
    // but the dispatch data is getting passed from the parent component
    // and it doesn't have all the necessary data like startTime & endTime
    // so passing the dispatch to processDispatchIntoViewModelData to get the process data
    // so we have all the necessary data like startTime & endTime

    const tz = await firstValueFrom(this.timezoneService.timezone$.pipe(take(1)));
    const processedDispatchData = this.processDispatchIntoViewModelData(dispatch, tz);
    this.dispatch$.next(processedDispatchData);
  }

  public async setStartTime(date: string, timezone: string): Promise<void> {
    const startTimeEpochSec = DateTime.fromISO(date, { zone: timezone }).set({ second: 0, millisecond: 0 }).toSeconds();
    const dispatch = await firstValueFrom(this.dispatch$);
    const { endTime } = dispatch;
    const updatedEndTime =
      endTime && endTime > startTimeEpochSec
        ? endTime
        : this.roundUpDateTimeToNearestRolloverPeriod(DateTime.fromSeconds(startTimeEpochSec))
            .setZone(timezone)
            .toSeconds();

    if (dispatch?.startTime != startTimeEpochSec) {
      // If the start time is changed, we need to reset the end time to the same value
      // This is because the end time cannot be before the start time
      // and when we change the endMinTime, the value of the end-time datepicker doesn't change
      // even though the value is updated to latest but at it's core it's still with the old value
      //so doing this, getting it out of the dom and adding it again to make it work
      this.dispatch$.next({ ...dispatch, showEndTimeIonDateTimePicker: false });
      setTimeout(() => {
        this.dispatch$.next({
          ...dispatch,
          startTime: startTimeEpochSec,
          showEndTimeIonDateTimePicker: true,
          endTime: updatedEndTime,
        });
      }, 0);
    }
  }

  public async toggleStartTimeNow(value: boolean, timezone: string): Promise<void> {
    const startTimeEpochSec = value
      ? DateTime.local({ zone: timezone }).set({ second: 0, millisecond: 0 }).toSeconds()
      : null;
    const dispatch = await firstValueFrom(this.dispatch$);
    if (dispatch?.startTime != startTimeEpochSec)
      this.dispatch$.next({ ...dispatch, startTime: startTimeEpochSec, endTime: null });
  }

  public async setEndTime(date: string, timezone: string): Promise<void> {
    const endTimeEpochSec = DateTime.fromISO(date, { zone: timezone }).set({ second: 0, millisecond: 0 }).toSeconds();
    const dispatch = await firstValueFrom(this.dispatch$);
    if (dispatch?.endTime != endTimeEpochSec) this.dispatch$.next({ ...dispatch, endTime: endTimeEpochSec });
  }

  public async setFcasMarkets(markets: VppFcasMarket[]): Promise<void> {
    const dispatch = await firstValueFrom(this.dispatch$);
    // Ensure FCAS is set to a true/false value depending if we have markets selected
    const controlValue = markets.length ? 'true' : 'false';
    this.dispatch$.next({ ...dispatch, markets, controlValue });
  }

  async setControlPriority(controlPriority: number | null): Promise<void> {
    const dispatch = await firstValueFrom(this.dispatch$);
    this.dispatch$.next({ ...dispatch, controlPriority });
  }

  async setControlValue(controlValue: BooleanStrings | number | string): Promise<void> {
    const dispatch = await firstValueFrom(this.dispatch$);
    this.dispatch$.next({ ...dispatch, controlValue });
  }

  public async sendDispatch(): Promise<void> {
    this.vmSubject.next({
      status: VppModelStatus.LOADING,
      message: 'VirtualPowerPlant.SendingDispatch',
      data: null,
    });
    const [dispatch, timezone] = await Promise.all([
      await firstValueFrom(this.dispatch$),
      this.timezoneService.timezone$.pipe(take(1)).toPromise(),
    ]);
    try {
      const requestTime = DateTime.local().setZone(timezone).toISO();
      await this.dispatchService.sendUnscheduledDispatches({
        ...dispatch,
        requestTime,
        controlValue: dispatch.controlType ? dispatch.controlValue : BooleanStrings.TRUE,
      });
      this.vmSubject.next({
        status: VppModelStatus.COMPLETE,
        message: 'VirtualPowerPlant.DispatchedSuccess',
        data: null,
      });
      setTimeout(() => {
        this.dismiss$.next();
      }, 1000);
    } catch (error) {
      const message = error instanceof HttpErrorResponse ? `${error.status} ${error.statusText}` : error;
      this.vmSubject.next({
        status: VppModelStatus.ERROR,
        message,
        data: null,
      });
    }
  }

  public async cancelDispatch(): Promise<void> {
    this.vmSubject.next({
      status: VppModelStatus.LOADING,
      message: 'VirtualPowerPlant.CancellingDispatch',
      data: null,
    });
    const [dispatch, timezone] = await Promise.all([
      await firstValueFrom(this.dispatch$),
      this.timezoneService.timezone$.pipe(take(1)).toPromise(),
    ]);
    try {
      const requestTime = DateTime.local().setZone(timezone).toISO();
      await this.dispatchService.cancelDispatch({
        ...dispatch,
        requestTime,
        controlValue: null,
      });
      this.vmSubject.next({
        status: VppModelStatus.COMPLETE,
        message: 'VirtualPowerPlant.DispatchCancelled',
        data: null,
      });
      setTimeout(() => {
        this.dismiss$.next();
      }, 1000);
    } catch (error) {
      const message = error instanceof HttpErrorResponse ? `${error.status} ${error.statusText}` : error;
      this.vmSubject.next({
        status: VppModelStatus.ERROR,
        message,
        data: null,
      });
    }
  }

  private handleError(err): Observable<VppOperationScheduleModalViewModel> {
    const message = err instanceof HttpErrorResponse ? `${err.status} ${err.statusText}` : err;
    return of({
      status: VppModelStatus.ERROR,
      message,
      data: null,
    });
  }
}
