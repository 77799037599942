import { TranslationsService } from './../../services/common/translations.service';
import { PortfoliosService } from './../../services/portfolios/portfolios.service';
import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import Plotly from 'plotly.js-dist';
import { DeviceMqttKey } from '../../classes/units/droplet/droplet-metric.model';
import { PortfolioOverviewChartInputData } from '@service/portfolios/portfolio.store';
import { ChartDataDTO } from '@class/units/unitanalytics/unit-analytics.models';

interface GraphButton {
  order: number;
  metric: string;
  icon: string;
  label: string;
}

@Component({
  selector: 'app-portfolio-overview-chart',
  templateUrl: './portfolio-overview-chart.component.html',
  styleUrls: ['./portfolio-overview-chart.component.scss'],
})
export class PortfolioOverviewChartComponent implements OnInit {
  hasData = false;
  chartIndex = 0;
  loading = true;
  private availableTitles = [];

  graphButtons: GraphButton[] = [
    { order: 0, label: 'Portfolio.PV', metric: DeviceMqttKey.SOLAR, icon: 'sunny' },
    { order: 1, label: 'Portfolio.Load', metric: DeviceMqttKey.LOAD, icon: 'speedometer' },
    { order: 2, label: 'Portfolio.Battery', metric: DeviceMqttKey.BESS, icon: 'battery-full' },
    { order: 3, label: 'Portfolio.Grid', metric: DeviceMqttKey.GRID, icon: 'flash' },
  ];

  @ViewChild('portfolioOverviewChart', { static: false }) portfolioOverviewChart: ElementRef;

  @Input()
  set data(data: PortfolioOverviewChartInputData) {
    this._data = data;
  }
  get data(): PortfolioOverviewChartInputData {
    return this._data;
  }
  private _data: PortfolioOverviewChartInputData;

  constructor(
    private portfoliosService: PortfoliosService,
    private trans: TranslationsService,
  ) {
    this.availableTitles[0] = this.trans.instant('Portfolio.PV');
    this.availableTitles[1] = this.trans.instant('Portfolio.Load');
    this.availableTitles[2] = this.trans.instant('Portfolio.Battery');
    this.availableTitles[3] = this.trans.instant('Portfolio.Grid');
  }

  ngOnInit(): void {
    if (this.data?.uuidToGetData && this.data?.unitUuidKeyNameValues) {
      this.getDataToChart(this.data.uuidToGetData, DeviceMqttKey.SOLAR);
    }
  }

  barDataIconClick(label: DeviceMqttKey, index: number): void {
    this.chartIndex = index;
    this.getDataToChart(this.data.uuidToGetData, label);
  }

  getDataToChart(id: string, metric: DeviceMqttKey): void {
    const end = new Date().getTime();
    const startDate = new Date();
    const start = startDate.setDate(startDate.getDate() - 7);
    this.loading = true;
    this.portfoliosService.getChartData({ portfolioUuid: id, metric, start, end }).then((chartData) => {
      this.hasData = true;
      this.loading = false;
      this.drawChart(chartData.data, metric);
    });
  }

  drawChart(data: ChartDataDTO, metric: string): void {
    const barData = [];
    for (const [k, v] of Object.entries(data)) {
      const unitName = this.data.unitUuidKeyNameValues[k];
      const X = [];
      const Y = [];
      const date_time_offset = Math.abs(new Date().getTimezoneOffset() * 60 * 1000);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      v[metric].forEach((e: any) => {
        X.push(new Date(new Date(e.time).getTime() + date_time_offset));
        Y.push(e.value);
      });
      const markerOptions = {};
      barData.push({ x: X, y: Y, name: unitName, marker: markerOptions, type: 'line', fill: 'tozeroy' });
    }

    const layout = {
      showlegend: false,
      title: this.availableTitles[this.chartIndex],
      paper_bgcolor: 'rgba(255, 255, 255, 0)',
      plot_bgcolor: 'rgba(255, 255, 255, 0)',
      annotations: [],
      margin: { l: 50, r: 70, b: 30, t: 25, pad: 10 },
      height: 200,
      autosize: true,
      yaxis: {
        showgrid: false,
        title: {
          text: this.trans.instant('Portfolio.Energy'),
        },
      },
      xaxis: {
        showgrid: false,
        type: 'date',
      },
      hovermode: 'x',
    };

    if (barData.length > 0) {
      layout.annotations.length = 0;
    } else if (layout.annotations.length === 0) {
      layout.xaxis['tickformat'] = '%b %d %Y';
      layout.annotations.push({
        text: this.trans.instant('General.DataNotFound'),
        showarrow: false,
        font: {
          size: 18,
          color: 'medium',
        },
      });
    }

    const options = { displayModeBar: false };
    Plotly.newPlot(this.portfolioOverviewChart.nativeElement, barData, layout, options);
  }
}
