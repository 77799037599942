import { Injectable } from '@angular/core';
import { VppDispatchType } from '@class/vpp/vpp-dispatch.model';
import { DateTime } from 'luxon';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { VppControlTarget } from '../../../../classes/vpp/vpp-control-target.model';
import { VppDemandResponse } from '../../../../classes/vpp/vpp-demand-response.model';
import { VppDemandTypes } from '../../../../classes/vpp/vpp-demand-type-mode.model';
import { VppStateMqtt } from '../../../../classes/vpp/vpp-mqtt.model';
import { ROLLOVER_PERIOD_MIN } from '../../rollover/vpp-roll-over.service';
import { VppTimezoneService } from '../../timezone/vpp-timezone.service';
import { VirtualPowerPlantsService } from '../../virtual-power-plants.service';
export interface VppOperationState {
  activity: boolean;
  time_now_epoch: number;
  presentIndex: number;
  selected_demand_response: VppDemandResponse;
  rollOverHappen$: Subscription;
  ctrls_enabled: number;
  total_devs: number;
  currentDemand: number;
  currentMaxDemand: number;
  currentMinDemand: number;

  //operation graph specific
  demand_responses: VppDemandResponse[]; // dispatch and available for various control-group, merit-order combinations
  control_target: VppControlTarget;
  time_seq: string[]; // 10 points from past and 5 points forecasted from Django view
  currentTime: string;
  realTimeIndex: number;
  referenceMetricUnit: string;
  epochTimeSeries: number[];
  timezone: string;
}

interface VppOperationTimeProperties {
  time_now_epoch: number;
  epochTimeSeries: number[];
  presentTime: string;
  currentTime: string;
  presentIndex: number;
  realTimeIndex: number;
  timeSeqStrings: string[];
}

interface SelectedDemandResponseMatchingProperties {
  demandType: VppDemandTypes;
  controlGroupName: string;
  deviceClass: string;
  controlDescription: string;
}
@Injectable({
  providedIn: 'root',
})
export class VppOperationTabFacadeService {
  private activity = false;
  //if a now tab row is selected, we store that selected state in here such that it persists when another state mqtt comes in
  private selectedDemandResponse: VppDemandResponse;

  private vppOperationState: VppOperationState;
  private vppOperationStateSubject$ = new BehaviorSubject<VppOperationState>(null);
  public vppOperationState$ = this.vppOperationStateSubject$.asObservable();
  constructor(
    private vppService: VirtualPowerPlantsService,
    private timezoneService: VppTimezoneService,
  ) {
    combineLatest([this.vppService.vppStateMqtt$, this.timezoneService.timezone$]).subscribe(
      ([mqttMessage, timezone]) => {
        this.vppOperationState = this.adaptVppOperationState(mqttMessage, timezone);
        this.vppOperationStateSubject$.next(this.vppOperationState);
      },
    );
  }

  private adaptVppOperationState(msg: VppStateMqtt, timezone: string): VppOperationState {
    if (msg == null) return null;
    const timeProperties = this.adaptTimeProperties(msg, timezone);
    const { currentTime, realTimeIndex, timeSeqStrings, presentIndex, time_now_epoch, epochTimeSeries } =
      timeProperties;
    const { control_target, ctrls_enabled, total_devs } = msg;

    this.activity = !this.activity;

    const currentMaxDemand = control_target.max[presentIndex - 1];
    const currentMinDemand = control_target.min[presentIndex - 1];

    const demandResponses = msg.demand_responses.map((response) => {
      response = this.setSelectedStateForDemandResponse(response);
      response = this.createDispatchSequenceForDispatchType(response);
      return response;
    });
    const controlTarget = this.adaptControlTargetForGraph(control_target);
    let currentDemand = null;
    if (controlTarget.live.length > 0) {
      currentDemand = controlTarget.live[presentIndex - 1].toFixed(2);
    }
    const vppOperationState: VppOperationState = {
      presentIndex,
      currentMaxDemand,
      currentMinDemand,
      time_now_epoch,
      ctrls_enabled,
      total_devs,
      activity: this.activity,
      rollOverHappen$: null,
      selected_demand_response: null,
      currentTime,
      demand_responses: demandResponses,
      control_target: controlTarget,
      time_seq: timeSeqStrings,
      realTimeIndex,
      referenceMetricUnit: null,
      epochTimeSeries,
      currentDemand,
      timezone,
    };
    return vppOperationState;
  }

  private adaptControlTargetForGraph(controlTarget: VppControlTarget): VppControlTarget {
    const { max, min, live, valid } = controlTarget;
    const lastMaxValue = max[max.length - 1];
    const lastMinValue = min[min.length - 1];
    const lastLiveValue = live[live.length - 1];
    max.push(lastMaxValue);
    min.push(lastMinValue);
    live.push(lastLiveValue);
    const adaptedControlTarget: VppControlTarget = {
      max,
      min,
      live,
      valid,
    };
    return adaptedControlTarget;
  }

  private adaptTimeProperties(mqttMsg: VppStateMqtt, timezone: string): VppOperationTimeProperties {
    const { time_now, time_seq } = mqttMsg;
    const lastTimeSeqValue = time_seq[time_seq.length - 1];
    time_seq.push(lastTimeSeqValue + 60 * ROLLOVER_PERIOD_MIN);

    const timeSeqStrings = time_seq.map((time) => {
      const { minute, hour } = DateTime.fromSeconds(time).setZone(timezone);
      let minString = minute.toString();
      let hourString = hour.toString();
      if (minute < 10) {
        minString = '0' + minute;
      }
      if (hour < 10) {
        hourString = '0' + hour;
      }
      return hourString + ':' + minString;
    });
    const timeNowMs = DateTime.fromSeconds(time_now).setZone(timezone);
    let nowMin: number = timeNowMs.minute;
    const currentMin: number = timeNowMs.minute;
    let nowHours: number = timeNowMs.hour;
    const currentHours: number = timeNowMs.hour;

    let difference = nowMin % 5;
    const realTimeDifference = nowMin % 5;
    if (nowMin >= 55 && nowMin <= 59) {
      if (nowHours !== 23) {
        nowHours += 1;
      } else {
        nowHours = 0;
      }
      nowMin = 0;
    } else {
      difference = 5 - difference;
      nowMin += difference;
    }

    const currentMinString = this.formatTimeNumber(currentMin);
    const currentHoursString = this.formatTimeNumber(currentHours);
    const nowMinString = this.formatTimeNumber(nowMin);
    const nowHoursString = this.formatTimeNumber(nowHours);

    const presentTime = nowHoursString + ':' + nowMinString; // now time in the list of 5 min intervals
    const currentTime = currentHoursString + ':' + currentMinString; // it's the current time, not the 5 min interval one
    const time_now_epoch = time_now;
    const epochTimeSeries = time_seq;
    const presentIndex = timeSeqStrings.indexOf(presentTime);
    const realTimeIndex = presentIndex - 1 + (realTimeDifference / 10.0) * 2;

    return {
      time_now_epoch,
      epochTimeSeries,
      presentTime,
      currentTime,
      presentIndex,
      realTimeIndex,
      timeSeqStrings,
    };
  }

  private formatTimeNumber(timeNum: number): string {
    let timeString = timeNum.toString();
    if (timeNum < 10) {
      timeString = '0' + timeNum;
    }
    return timeString;
  }

  private setSelectedStateForDemandResponse(demandResponse: VppDemandResponse): VppDemandResponse {
    if (this.selectedDemandResponse == null) {
      return demandResponse;
    }
    const isDemandResponseCurrentlySelected = this.areDemandResponseEqual(demandResponse, this.selectedDemandResponse);
    demandResponse.selected = isDemandResponseCurrentlySelected;
    return demandResponse;
  }

  public selectDemandResponseToHighlight(selectedDemandResponse: VppDemandResponse): void {
    const demandResponse = this.vppOperationState.demand_responses;
    if (selectedDemandResponse == null) {
      console.warn('Warning, attempted to highlight demand response of null!');
      return;
    }
    const demandResponseWithSelected = demandResponse.map((demand) => {
      if (this.areDemandResponseEqual(demand, selectedDemandResponse)) {
        const toggledSelectedState = !demand.selected;
        this.selectedDemandResponse = toggledSelectedState ? demand : null;
        demand.selected = toggledSelectedState;
      } else {
        demand.selected = false;
      }
      return demand;
    });
    this.vppOperationStateSubject$.next({ ...this.vppOperationState, demand_responses: demandResponseWithSelected });
  }

  private adaptSelectedProperties(demandResponse: VppDemandResponse): SelectedDemandResponseMatchingProperties {
    const { demand_type, group, device_class, control_desc } = demandResponse;
    const demandResponseProperties: SelectedDemandResponseMatchingProperties = {
      demandType: demand_type,
      controlGroupName: group,
      deviceClass: device_class,
      controlDescription: control_desc,
    };
    return demandResponseProperties;
  }
  private areDemandResponseEqual(responseA: VppDemandResponse, responseB: VppDemandResponse): boolean {
    const responsePropertiesA = this.adaptSelectedProperties(responseA);
    const responsePropertiesB = this.adaptSelectedProperties(responseB);
    const differentProperty = Object.keys(responsePropertiesA).find(
      (key) => responsePropertiesA[key] !== responsePropertiesB[key],
    );
    return differentProperty === undefined;
  }

  private createDispatchSequenceForDispatchType(demandResponse: VppDemandResponse): VppDemandResponse {
    const auto_dptch_seq = [];
    const manual_dptch_seq = [];
    const unscheduled_dptch_seq = [];
    const unassociated_dptch_seq = [];
    demandResponse.dptch_seq.forEach((dispatch, index) => {
      const dispatchType =
        demandResponse.dtype_seq && demandResponse.dtype_seq.length > 0 ? demandResponse.dtype_seq[index] : '';
      switch (dispatchType) {
        case VppDispatchType.AUTO:
          auto_dptch_seq.push(dispatch);
          manual_dptch_seq.push(0);
          unscheduled_dptch_seq.push(0);
          unassociated_dptch_seq.push(0);
          break;
        case VppDispatchType.MANUAL:
          auto_dptch_seq.push(0);
          manual_dptch_seq.push(dispatch);
          unscheduled_dptch_seq.push(0);
          unassociated_dptch_seq.push(0);
          break;
        case VppDispatchType.UNSCHEDULED:
          auto_dptch_seq.push(0);
          manual_dptch_seq.push(0);
          unscheduled_dptch_seq.push(dispatch);
          unassociated_dptch_seq.push(0);
          break;
        default:
          unassociated_dptch_seq.push(dispatch);
          auto_dptch_seq.push(0);
          manual_dptch_seq.push(0);
          unscheduled_dptch_seq.push(0);
          break;
      }
    });

    return {
      ...demandResponse,
      auto_dptch_seq,
      manual_dptch_seq,
      unscheduled_dptch_seq,
      avail_to_display: demandResponse.avail_seq,
      unassociated_dptch_seq,
    };
  }
}
