<ion-item lines="none">
  <ion-text>
    <h2>{{ 'VirtualPowerPlant.Schedules' | translate }} <ion-icon name="calendar"></ion-icon></h2>
    <p>{{ 'VirtualPowerPlant.SchedulesDesc' | translate }}</p>
  </ion-text>
  <app-create-button
    slot="end"
    *ifHasPermission="[PermissionKey.VPP_ADD_CONTROLGROUPSCHEDULE]"
    (onClick)="openVppCreateScheduleModalForCreate()"
  ></app-create-button>
</ion-item>
<ng-container *ifHasPermission="[PermissionKey.VPP_VIEW_SCHEDULE]">
  <div class="ion-padding-horizontal" *ngIf="dataSource != undefined">
    <ion-searchbar
      class="base-input mb-10"
      showCancelButton="never"
      placeholder="Filter"
      autocomplete="on"
      [debounce]="300"
      (ionInput)="applyFilter($event.target.value)"
    ></ion-searchbar>
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z8 ion-margin-bottom">
      <ng-container matColumnDef="name">
        <th class="ion-text-left" mat-header-cell *matHeaderCellDef>{{ 'General.Name' | translate }}</th>
        <!-- mat-sort-header -->
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th class="ion-text-left" mat-header-cell *matHeaderCellDef>
          {{ 'VirtualPowerPlant.Description' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th style="width: 19%" mat-header-cell *matHeaderCellDef>{{ 'VirtualPowerPlant.Action' | translate }}</th>
        <td class="ion-text-center" mat-cell *matCellDef="let element">
          <ion-button
            *ifHasPermission="[PermissionKey.VPP_DELETE_SCHEDULE]"
            color="danger"
            fill="outline"
            (click)="presentAlertConfirmSchedule(element)"
          >
            <ion-icon slot="icon-only" name="remove"></ion-icon>
          </ion-button>
          <ion-button
            *ifHasPermission="[PermissionKey.VPP_CHANGE_SCHEDULE]"
            class="ml-5"
            color="button-primary"
            fill="outline"
            (click)="openVppCreateScheduleModal(element)"
          >
            <ion-icon slot="icon-only" name="create"></ion-icon>
          </ion-button>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
          <div class="example-element-detail">
            <ion-row style="width: 100%">
              <ion-col>
                <div class="ion-margin-top ion-margin-bottom divGrid">
                  <ion-label>
                    {{ 'VirtualPowerPlant.RecurrencePattern' | translate }}: <strong>{{ element.recurrence }}</strong>
                  </ion-label>
                  <ion-label>
                    {{ 'VirtualPowerPlant.RecurrenceRange' | translate }}:
                    <strong>{{ element.start_date }} - {{ element.end_date }}</strong>
                  </ion-label>
                  <!-- <ion-label *ngIf="element.recurrence == VppScheduleRecurrencePattern.WEEKLY">
                  {{"VirtualPowerPlant.Frequency" | translate}}: <strong>{{element.frequency_value}}</strong>
                </ion-label> -->
                </div>
              </ion-col>
              <ion-col>
                <div class="ion-margin-top ion-margin-bottom divGrid">
                  <ion-label *ngIf="element.recurrence == VppScheduleRecurrencePattern.DAILY">
                    {{ 'VirtualPowerPlant.Interval' | translate }}:
                    <strong *ngFor="let interval of element.intervals; last as isLast"
                      >{{ interval }} {{ timezoneOffset$ | async }}<span *ngIf="!isLast">, </span>
                    </strong>
                  </ion-label>
                  <div *ngIf="element.recurrence == VppScheduleRecurrencePattern.WEEKLY" style="display: grid">
                    <div *ngFor="let day of element.weekly_schedules" style="display: grid" class="ion-margin-bottom">
                      <ion-label>
                        {{ 'VirtualPowerPlant.Day' | translate }}: <strong>{{ day.day }}</strong>
                      </ion-label>
                      <ion-label>
                        {{ 'VirtualPowerPlant.Interval' | translate }}:
                        <strong *ngFor="let interval of day.intervals; last as isLast"
                          >{{ interval }} {{ timezoneOffset$ | async }}<span *ngIf="!isLast">, </span>
                        </strong>
                      </ion-label>
                    </div>
                  </div>
                  <div *ngIf="element.recurrence == VppScheduleRecurrencePattern.MONTHLY" style="display: grid">
                    <div *ngFor="let day of element.monthly_schedules" style="display: grid" class="ion-margin-bottom">
                      <ion-label>
                        {{ 'VirtualPowerPlant.Day' | translate }}: <strong>{{ day.day_number }}</strong>
                      </ion-label>
                      <ion-label>
                        {{ 'VirtualPowerPlant.Interval' | translate }}:
                        <strong *ngFor="let interval of day.intervals; last as isLast"
                          >{{ interval }} {{ timezoneOffset$ | async }}<span *ngIf="!isLast">, </span>
                        </strong>
                      </ion-label>
                    </div>
                  </div>
                  <div *ngIf="element.recurrence == VppScheduleRecurrencePattern.YEARLY" style="display: grid">
                    <div *ngFor="let day of element.yearly_schedules" style="display: grid" class="ion-margin-bottom">
                      <ion-label>
                        {{ 'VirtualPowerPlant.StartMonth' | translate }}: <strong>{{ day.start_month }}</strong
                        >, {{ 'VirtualPowerPlant.StartDayNumber' | translate }}:
                        <strong>{{ day.start_day_number }}</strong>
                      </ion-label>
                      <ion-label>
                        {{ 'VirtualPowerPlant.EndMonth' | translate }}: <strong>{{ day.end_month }}</strong
                        >, {{ 'VirtualPowerPlant.EndDayNumber' | translate }}: <strong>{{ day.end_day_number }}</strong>
                      </ion-label>
                      <ion-label>
                        {{ 'VirtualPowerPlant.Interval' | translate }}:
                        <strong *ngFor="let interval of day.intervals; last as isLast"
                          >{{ interval }} {{ timezoneOffset$ | async }}<span *ngIf="!isLast">, </span>
                        </strong>
                      </ion-label>
                    </div>
                  </div>
                  <div *ngIf="element.recurrence == VppScheduleRecurrencePattern.ONCE" style="display: grid">
                    <div *ngFor="let day of element.once_schedules" style="display: grid" class="ion-margin-bottom">
                      <ion-label>
                        {{ 'VirtualPowerPlant.Date' | translate }}: <strong>{{ day.date }}</strong>
                      </ion-label>
                      <ion-label>
                        {{ 'VirtualPowerPlant.Interval' | translate }}:
                        <strong *ngFor="let interval of day.intervals; last as isLast"
                          >{{ interval }} {{ timezoneOffset$ | async }}<span *ngIf="!isLast">, </span>
                        </strong>
                      </ion-label>
                    </div>
                  </div>
                </div>
              </ion-col>
            </ion-row>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="scheduleTableHeader"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: columnsToDisplay"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="rowClicked(element)"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="VPP_TABLE_PAGE_SIZE_OPTIONS" class="ion-margin-top"></mat-paginator>
  </div>
</ng-container>
