<ng-container *ngIf="portfolio$ | async as portfolioData">
  <app-modal-container
    [id]="vm.modal.type"
    [action]="vm.modal.action"
    [title]="title ?? vm.title"
    (onCreate)="handleSubmit()"
    (onSave)="handleModalContainerSave()"
    (onRemove)="handleRemovePortfolio()"
    [enableRemoveButton]="canRemovePortfolio"
    [isSaveDisabled]="!portfolioForm.valid || !portfolioForm.dirty"
    [showFooter]="portfolioData.status !== States.REQUEST_IN_PROCESS && portfolioData.status !== States.REQUEST_SUCCESS"
  >
    <app-state-wrapper
      [stateType]="portfolioData.status"
      [errorMsg]="portfolioData.error"
      [successMsg]="portfolioData.message"
      (onRetry)="handleErrorCloseAndErrorRetry()"
      (onErrorClose)="handleErrorCloseAndErrorRetry()"
    >
      <ion-row>
        <ion-col class="ion-no-padding ion-no-margin">
          <form [formGroup]="portfolioForm">
            <ion-row class="bb-1">
              <ion-col class="pt-10 pb-10" size="12">
                <app-input-stacked-label
                  label="{{ 'General.Name' | translate }}*"
                  formControlName="name"
                  required
                  [enableClearIcon]="true"
                  [errors]="name.errors"
                  [status]="name.invalid && (name.dirty || name.touched) ? INPUT_STATUS_INVALID : ''"
                >
                </app-input-stacked-label>
              </ion-col>
            </ion-row>
            <ion-row class="bb-1">
              <ion-col class="pt-10 pb-10 size=12">
                <ion-item lines="none">
                  <ion-toggle
                    mode="ios"
                    formControlName="mute_alerts"
                    color="button-secondary"
                    slot="end"
                    labelPlacement="start"
                    justify="space-between"
                  >
                    {{ 'UnitDetails.MuteAlarms' | translate }}
                  </ion-toggle>
                </ion-item>
              </ion-col>
            </ion-row>
          </form>
        </ion-col>
      </ion-row>
    </app-state-wrapper>
  </app-modal-container>
</ng-container>
