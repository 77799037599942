<div class="ion-padding bkgWhite">
  <ion-searchbar
    class="base-input mb-10"
    showCancelButton="never"
    placeholder="Filter"
    autocomplete="on"
    [debounce]="300"
    (ionInput)="applyFilter($event.target.value)"
  ></ion-searchbar>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="group">
        <th class="ion-text-left width-8-pct" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'VirtualPowerPlant.Group' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.group }}</td>
      </ng-container>

      <ng-container matColumnDef="demandType">
        <th class="width-8-pct" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'VirtualPowerPlant.DemandType' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.demand_type }}</td>
      </ng-container>

      <ng-container matColumnDef="order">
        <th class="width-8-pct" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'VirtualPowerPlant.Merit' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.merit_order }}</td>
      </ng-container>

      <ng-container matColumnDef="deviceClass">
        <th class="ion-text-left" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'VirtualPowerPlant.Class' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.device_class }}</td>
      </ng-container>

      <ng-container matColumnDef="controlDesc">
        <th class="ion-text-left" mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'VirtualPowerPlant.Effect' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.control_desc }}</td>
      </ng-container>

      <ng-container matColumnDef="dispatch">
        <th class="width-8-pct ion-text-center" mat-header-cell *matHeaderCellDef>
          {{ 'VirtualPowerPlant.Dispatch' | translate }}
        </th>
        <td class="ion-text-center" mat-cell *matCellDef="let element">
          {{
            element.dptch_seq[operationState.presentIndex - 1] != undefined
              ? element.dptch_seq[operationState.presentIndex - 1].toFixed(1)
              : ''
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="available">
        <th class="width-8-pct ion-text-center" mat-header-cell *matHeaderCellDef>
          {{ 'VirtualPowerPlant.Available' | translate }}
        </th>
        <td class="ion-text-center" mat-cell *matCellDef="let element">
          {{
            element.avail_seq[operationState.presentIndex - 1] != undefined
              ? element.avail_seq[operationState.presentIndex - 1].toFixed(1)
              : ''
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="capacity">
        <th class="width-8-pct ion-text-center" mat-header-cell *matHeaderCellDef>
          {{ 'VirtualPowerPlant.Capacity' | translate }}
        </th>
        <td class="ion-text-center" mat-cell *matCellDef="let element">
          {{
            (
              element.dptch_seq[operationState.presentIndex - 1] + element.avail_seq[operationState.presentIndex - 1]
            ).toFixed(1)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th class="width-8-pct ion-text-center" mat-header-cell *matHeaderCellDef>
          {{ 'VirtualPowerPlant.Action' | translate }}
        </th>
        <td class="ion-text-center" mat-cell *matCellDef="let element">
          <ion-button
            *ifHasPermission="[PermissionKey.VPP_DISPATCH_VPP]"
            size="small"
            color="button-primary"
            (click)="openScheduleModal(element)"
          >
            <ion-icon slot="icon-only" name="paper-plane"></ion-icon>
          </ion-button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="firstRowHeader"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="onRowClick(row)"
        [ngClass]="{ 'row-selected': row.selected }"
      ></tr>
    </table>
    <mat-paginator [pageSizeOptions]="VPP_TABLE_PAGE_SIZE_OPTIONS"></mat-paginator>
  </div>
</div>
