<ion-button
  (click)="handleClick()"
  color="button-primary"
  class="create-button-rounded"
  mode="ios"
  [disabled]="isDisabled"
  fill="solid"
>
  <span class="fw-600 fs-14 ion-text-capitalize"> {{ label ?? 'General.Create' | translate }} </span>
</ion-button>
