<div *ngIf="unitPowerFlowStreamStore$ | async as powerFlowStream">
  <!----------------------------->
  <!-- regular and offgrid svg -->
  <!----------------------------->

  <svg class="svg-animation" width="100%" viewBox="0 0 371 410" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dd)">
      <rect x="10" y="1" width="351" height="155" rx="12" class="fill-theme-item-background" />
    </g>
    <text
      xml:space="preserve"
      font-family="switchdin"
      font-size="13"
      letter-spacing="0em"
      class="wsp fill-text-tertiary"
    >
      <tspan x="37" y="136.783">{{ homeUsageLabel }}</tspan>
    </text>
    <text
      xml:space="preserve"
      font-family="switchdin"
      font-size="13"
      letter-spacing="0em"
      class="wsp fill-text-tertiary"
    >
      <tspan x="206.045" y="67.7295">{{ consumedTodayLabel }}</tspan>
    </text>
    <text
      xml:space="preserve"
      font-family="switchdin"
      font-size="13"
      letter-spacing="0em"
      class="wsp fill-text-tertiary"
    >
      <tspan x="206.045" y="128.283">{{ storedTodayLabel }}</tspan>
    </text>
    <!-- Home Usage Value -->
    <text
      xml:space="preserve"
      font-family="switchdin"
      font-size="22"
      letter-spacing="0em"
      class="wsp fill-text-primary"
    >
      <tspan x="37" y="119.523">
        {{ powerFlowStream.homeUsage | number: '.1-1' }}
        kW
      </tspan>
    </text>

    <!-- Consumed Today Value -->
    <text
      attr.opacity="{{ powerFlowStream.consumedTodayValue ? 1 : 0.4 }}"
      xml:space="preserve"
      font-family="switchdin"
      font-size="20"
      letter-spacing="0em"
      class="wsp fill-consumption"
    >
      <tspan x="206.045" y="50.7256">
        {{ powerFlowStream.consumedTodayValue ? powerFlowStream.consumedTodayValue : '0.00' }}
        kWh
      </tspan>
    </text>
    <!-- Stored Today Value -->
    <text
      attr.opacity="{{ powerFlowStream.storedTodayValue ? 1 : 0.4 }}"
      xml:space="preserve"
      font-family="switchdin"
      font-size="20"
      letter-spacing="0em"
      class="wsp fill-battery"
    >
      <tspan x="206.045" y="111.279">
        {{ powerFlowStream.storedTodayValue ? powerFlowStream.storedTodayValue : '0.00' }}
        kWh
      </tspan>
    </text>
    <line opacity="0.1" x1="167.001" y1="33.3013" x2="167.001" y2="131.946" class="stroke-text-tertiary" />

    <foreignObject x="33" y="10" width="94" height="94">
      <ion-icon class="custom-icon-x-large ios-icon-reset" [src]="homeIcon"></ion-icon>
    </foreignObject>

    <!-- Grid || Generator -->
    <g id="gridBlock">
      <g filter="url(#filter1_d)">
        <rect x="9" y="202" width="109" height="143" rx="12" class="fill-theme-item-background" />
      </g>
      <text
        opacity="1"
        xml:space="preserve"
        font-family="switchdin"
        font-size="13"
        letter-spacing="0em"
        class="wsp fill-text-tertiary"
      >
        <tspan x="24" y="306.247">{{ powerFlowStream.offGridView ? generatorLabel : gridLabel }}</tspan>
      </text>
      <text
        attr.opacity="{{ powerFlowStream.isGridIdle ? 0.4 : 1 }}"
        opacity="1"
        xml:space="preserve"
        font-family="switchdin"
        font-size="18"
        letter-spacing="0em"
        class="wsp fill-text-primary"
      >
        <tspan x="24" y="287.697">{{ powerFlowStream.grid | number: '.1-1' }} kW</tspan>
      </text>
      <text
        attr.opacity="{{ powerFlowStream.isGridIdle ? 0.4 : 1 }}"
        class="wsp fill-consumption"
        xml:space="preserve"
        font-family="switchdin"
        font-size="12"
        letter-spacing="0em"
      >
        <tspan attr.x="{{ powerFlowStream.isGridIdle ? 20 : 40 }}" y="333">
          {{
            powerFlowStream.isGridIdle
              ? idleLabel
              : powerFlowStream.grid > 0
                ? powerFlowStream.offGridView
                  ? generatingLabel
                  : importingLabel
                : exportingLabel
          }}
        </tspan>
      </text>

      <g>
        <path
          *ngIf="!powerFlowStream.isGridIdle"
          d="M149,327.500000C149,323.910000,151.910000,321,155.500000,321L158.500000,321C162.090000,321,165,323.910000,165,327.500000C165,331.090000,162.090000,334,158.500000,334L155.500000,334C151.910000,334,149,331.090000,149,327.500000Z"
          transform="matrix(1 0 0 1 -125 0)"
          class="fill-consumption"
        />
        <path
          *ngIf="!powerFlowStream.isGridIdle"
          d="M156.970000,327.091000L157.030000,327.091000L159.470000,329.530000L160.530000,328.470000L157,324.939000L153.470000,328.470000L154.530000,329.530000L156.970000,327.091000Z"
          transform="matrix(1 0 0 1 -125 0)"
          clip-rule="evenodd"
          fill="rgb(255,255,255)"
          fill-rule="evenodd"
          stroke="none"
          stroke-width="1"
        />
      </g>

      <svg id="battery" style="overflow: hidden" x="20" y="197" viewBox="0 0 200 200">
        <foreignObject
          attr.filter="{{ powerFlowStream.isGridIdle ? 'url(#grayscale)' : '' }}"
          x="0"
          y="0"
          width="24"
          height="24"
          *ngIf="!powerFlowStream.offGridView"
        >
          <ion-icon class="fs-24 ios-icon-reset" [src]="gridIcon"></ion-icon>
        </foreignObject>
        <foreignObject
          attr.filter="{{ powerFlowStream.isGridIdle ? 'url(#grayscale)' : '' }}"
          x="0"
          y="0"
          width="24"
          height="24"
          *ngIf="powerFlowStream.offGridView"
        >
          <ion-icon class="fs-24 ios-icon-reset" [src]="generatorIcon"></ion-icon>
        </foreignObject>
      </svg>
    </g>
    <!--Solar-->
    <g>
      <g filter="url(#filter2_dd)">
        <rect x="134" y="202" width="109" height="143" rx="12" class="fill-theme-item-background" />
      </g>
      <text
        xml:space="preserve"
        font-family="switchdin"
        font-size="13"
        letter-spacing="0em"
        class="wsp fill-text-tertiary"
      >
        <tspan x="147" y="305.837">{{ solarLabel }}</tspan>
      </text>

      <text
        attr.opacity="{{ powerFlowStream.isSolarIdle ? 0.4 : 1 }}"
        xml:space="preserve"
        font-family="switchdin"
        font-size="18"
        letter-spacing="0em"
        class="wsp fill-text-primary"
      >
        <tspan x="147" y="287.288">{{ powerFlowStream.solar | number: '.1-1' }} kW</tspan>
      </text>

      <text
        attr.opacity="{{ powerFlowStream.isSolarIdle ? 0.4 : 1 }}"
        attr.class="{{ powerFlowStream.isSolarIdle ? 'wsp fill-text-tertiary' : 'wsp fill-solar' }}"
        xml:space="preserve"
        font-family="switchdin"
        font-size="12"
        letter-spacing="0em"
      >
        <tspan attr.x="{{ powerFlowStream.isSolarIdle ? 143 : 166 }}" y="332.465">
          {{ powerFlowStream.solar > 0 ? generatingLabel : idleLabel }}
        </tspan>
      </text>

      <path
        id="arrow-background-solar"
        *ngIf="!powerFlowStream.isSolarIdle"
        d="M149 327.5C149 323.91 151.91 321 155.5 321H158.5C162.09 321 165 323.91 165 327.5C165 331.09 162.09 334 158.5 334H155.5C151.91 334 149 331.09 149 327.5Z"
        class="fill-solar"
      />
      <path
        id="arrow-solar"
        *ngIf="!powerFlowStream.isSolarIdle"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M156.97 327.091H157.03L159.47 329.53L160.53 328.47L157 324.939L153.47 328.47L154.53 329.53L156.97 327.091Z"
        class="fill-theme-item-background"
      />
      <svg id="solar" style="overflow: hidden" x="146" y="200" viewBox="0 0 200 200">
        <foreignObject
          attr.filter="{{ powerFlowStream.isSolarIdle ? 'url(#grayscale)' : '' }}"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <ion-icon class="fs-20 ios-icon-reset" [src]="solarIcon"></ion-icon>
        </foreignObject>
      </svg>
    </g>

    <!--Storage-->
    <g>
      <g filter="url(#filter3_d)">
        <rect x="252" y="202" width="109" height="143" rx="12" class="fill-theme-item-background" />
      </g>
      <text
        xml:space="preserve"
        font-family="switchdin"
        font-size="13"
        letter-spacing="0em"
        class="wsp fill-text-tertiary"
      >
        <tspan x="260" y="305.837" *ngIf="powerFlowStream.stateOfCharge < 100">
          {{ storageLabel }} {{ powerFlowStream.stateOfCharge | number: '.1-1' }}%
        </tspan>
        <tspan x="260" y="305.837" *ngIf="powerFlowStream.stateOfCharge >= 100">
          {{ storageLabel }} {{ powerFlowStream.stateOfCharge }}%
        </tspan>
      </text>
      <text
        attr.opacity="{{ powerFlowStream.isStorageIdle ? 0.4 : 1 }}"
        xml:space="preserve"
        font-family="switchdin"
        font-size="18"
        letter-spacing="0em"
        class="wsp fill-text-primary"
      >
        <tspan x="267" y="287.697">{{ powerFlowStream.storage | number: '.1-1' }} kW</tspan>
      </text>

      <svg id="battery" style="overflow: hidden" x="255" y="197" viewBox="0 0 200 200">
        <foreignObject
          attr.filter="{{ powerFlowStream.isStorageIdle ? 'url(#grayscale)' : '' }}"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <ion-icon class="fs-24 ios-icon-reset" [src]="powerFlowStream.batteryIcon"></ion-icon>
        </foreignObject>
      </svg>

      <g>
        <text
          attr.opacity="{{ powerFlowStream.isStorageIdle ? 0.4 : 1 }}"
          attr.class="{{ powerFlowStream.isStorageIdle ? 'wsp fill-text-primary' : 'wsp fill-battery' }}"
          xml:space="preserve"
          font-family="switchdin"
          font-size="12"
          letter-spacing="0em"
        >
          <tspan attr.x="{{ powerFlowStream.isStorageIdle ? 264 : 284 }}" y="332.465">
            {{ powerFlowStream.isStorageIdle ? idleLabel : powerFlowStream.storage > 0 ? savingLabel : exportingLabel }}
          </tspan>
        </text>
      </g>

      <path
        id="arrow-background-battery"
        *ngIf="!powerFlowStream.isStorageIdle"
        d="M268 327.5C268 323.91 270.91 321 274.5 321H277.5C281.09 321 284 323.91 284 327.5C284 331.09 281.09 334 277.5 334H274.5C270.91 334 268 331.09 268 327.5Z"
        class="fill-battery"
      />

      <path
        *ngIf="!powerFlowStream.isStorageIdle"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M275.97 327.091H276.03L278.47 329.53L279.53 328.47L276 324.939L272.47 328.47L273.53 329.53L275.97 327.091Z"
        class="fill-theme-item-background"
      />
    </g>

    <!--Grid to load-->
    <g id="gridToLoad">
      <path
        opacity="0.25"
        d="M65 161V198"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="stroke-brand-primary"
      />
      <g *ngIf="powerFlowStream.gridToLoad !== 0">
        <path
          opacity="0.85"
          d="M65 161V198"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="grid-to-load stroke-consumption"
        />
        <rect opacity="1" x="40" y="168" width="52" height="24" rx="12" class="fill-theme-item-background" />
        <!--rect opacity="1" filter="url(#dropshadow)" x="40" y="168" width="52" height="24" rx="12" class="fill-theme-item-background animate-shadow-short" /-->
        <text
          text-anchor="middle"
          xml:space="preserve"
          font-family="switchdin"
          font-size="12"
          font-weight="bold"
          letter-spacing="0em"
          class="wsp fill-text-tertiary"
        >
          <tspan x="67" y="185">{{ powerFlowStream.gridToLoad | number: '.1-1' }}</tspan>
        </text>
      </g>
    </g>

    <!--Storage to Load-->
    <g id="storageToLoad">
      <path
        opacity="0.15"
        d="M302 161V198"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="stroke-brand-primary"
      />
      <g *ngIf="powerFlowStream.storageToLoad !== 0">
        <path
          opacity="0.85"
          d="M302 161V198"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="battery-to-home-usage stroke-battery"
        />
        <rect opacity="1" x="275" y="168" width="52" height="24" rx="12" class="fill-theme-item-background" />
        <!--rect opacity="1" x="275" y="168" width="52" height="24" rx="12" class="fill-theme-item-background animate-shadow-short" filter="url(#dropshadow)"/-->
        <text
          text-anchor="middle"
          xml:space="preserve"
          font-family="switchdin"
          font-size="12"
          font-weight="bold"
          letter-spacing="0em"
          class="wsp fill-text-tertiary"
        >
          <tspan x="301" y="185">{{ powerFlowStream.storageToLoad | number: '.1-1' }}</tspan>
        </text>
      </g>
    </g>

    <!-- Solar to load -->
    <g id="solarToLoad">
      <path
        opacity="0.15"
        d="M185 161V198"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="stroke-brand-primary"
      />
      <g *ngIf="powerFlowStream.solarToLoad !== 0">
        <path
          opacity="0.85"
          d="M185 161V198"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="pv-to-load-usage stroke-solar"
        />
        <rect opacity="1" x="159" y="168" width="52" height="24" rx="12" class="fill-theme-item-background" />
        <!--rect opacity="1" filter="url(#dropshadow)" x="159" y="168" width="52" height="24" rx="12" class="fill-theme-item-background animate-shadow-short" /-->
        <text
          text-anchor="middle"
          xml:space="preserve"
          style="white-space: pre"
          font-family="switchdin"
          font-size="12"
          font-weight="bold"
          letter-spacing="0em"
          class="fill-text-tertiary"
        >
          <tspan x="184" y="185">{{ powerFlowStream.solarToLoad | number: '.1-1' }}</tspan>
        </text>
      </g>
    </g>

    <!--Grid to Storage-->
    <g id="gridToStorage">
      <path
        opacity="0.15"
        d="M47 354V374C47 383.941 55.0589 392 65 392H305C314.941 392 323 383.941 323 374V354"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="stroke-brand-primary"
      />
      <g *ngIf="powerFlowStream.gridToStorage !== 0">
        <path
          opacity="0.85"
          d="M47 354V374C47 383.941 55.0589 392 65 392H305C314.941 392 323 383.941 323 374V354"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
          [ngClass]="powerFlowStream.gridToStorage > 0 ? 'grid-to-storage' : 'grid-to-storage-reverse'"
        />
        <rect opacity="1" x="159" y="380" width="52" height="24" rx="12" class="fill-theme-item-background" />
        <!--rect opacity="1" filter="url(#dropshadow)" x="159" y="380" width="52" height="24" rx="12" class="fill-theme-item-background animate-shadow-long" /-->
        <text
          text-anchor="middle"
          xml:space="preserve"
          font-family="switchdin"
          font-size="12"
          font-weight="bold"
          letter-spacing="0em"
          class="wsp fill-text-tertiary"
        >
          <tspan x="185" y="396">{{ powerFlowStream.gridToStorage | number: '.1-1' }}</tspan>
        </text>
      </g>
    </g>

    <!--Solar to Grid -->
    <g id="solarToGrid" *ngIf="!powerFlowStream.offGridView">
      <path
        opacity="0.15"
        d="M66 354V361C66 367.627 71.3726 373 78 373H168C174.627 373 180 367.627 180 361V354"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="stroke-brand-primary"
      />
      <g *ngIf="powerFlowStream.solarToGrid !== 0">
        <path
          opacity="0.85"
          d="M66 354V361C66 367.627 71.3726 373 78 373H168C174.627 373 180 367.627 180 361V354"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="pv-to-grid stroke-secondary-four"
        />
        <rect opacity="1" x="95" y="360" width="52" height="24" rx="12" class="fill-theme-item-background" />
        <!--rect opacity="1" filter="url(#dropshadow)" x="94" y="360" width="52" height="24" rx="12" class="fill-theme-item-background animate-shadow-long" /-->
        <text
          text-anchor="middle"
          xml:space="preserve"
          font-family="switchdin"
          font-size="12"
          font-weight="bold"
          letter-spacing="0em"
          class="wsp fill-text-tertiary"
        >
          <tspan x="122" y="376">{{ powerFlowStream.solarToGrid | number: '.1-1' }}</tspan>
        </text>
      </g>
    </g>

    <!-- Solar to Storage -->
    <g id="solarToStorage">
      <path
        opacity="0.15"
        d="M189 354V361C189 367.627 194.373 373 201 373H291C297.627 373 303 367.627 303 361V354"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="stroke-brand-primary"
      />
      <g *ngIf="powerFlowStream.solarToStorage !== 0">
        <path
          opacity="0.85"
          d="M189 354V361C189 367.627 194.373 373 201 373H291C297.627 373 303 367.627 303 361V354"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="pv-to-battery"
        />
        <rect opacity="1" x="222" y="359" width="52" height="24" rx="12" class="fill-theme-item-background" />
        <!--rect opacity="1" filter="url(#dropshadow)" x="221" y="359" width="52" height="24" rx="12" class="fill-theme-item-background animate-shadow" /-->
        <text
          text-anchor="middle"
          xml:space="preserve"
          font-family="switchdin"
          font-size="12"
          font-weight="bold"
          letter-spacing="0em"
          class="wsp fill-text-tertiary"
        >
          <tspan x="248" y="374.875">{{ powerFlowStream.solarToStorage | number: '.1-1' }}</tspan>
        </text>
      </g>
    </g>

    <defs>
      <filter id="grayscale">
        <feColorMatrix type="saturate" values="0" />
      </filter>
      <filter
        id="filter0_dd"
        x="0.668654"
        y="0"
        width="369.663"
        height="184.331"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.223437 0 0 0 0 0.4875 0 0 0 0 0.471656 0 0 0 0.03 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feMorphology radius="10.6687" operator="erode" in="SourceAlpha" result="effect2_dropShadow" />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.223437 0 0 0 0 0.4875 0 0 0 0 0.471656 0 0 0 0.13 0" />
        <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
      <filter
        id="filter1_d"
        x="6"
        y="201"
        width="115"
        height="149"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.698039 0 0 0 0 0.6 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <filter
        id="filter2_dd"
        x="124.669"
        y="201"
        width="127.663"
        height="163.331"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.223437 0 0 0 0 0.4875 0 0 0 0 0.471656 0 0 0 0.03 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feMorphology radius="10.6687" operator="erode" in="SourceAlpha" result="effect2_dropShadow" />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.223437 0 0 0 0 0.4875 0 0 0 0 0.471656 0 0 0 0.13 0" />
        <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
      </filter>
      <filter
        id="filter3_d"
        x="249"
        y="201"
        width="115"
        height="149"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.698039 0 0 0 0 0.6 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>

      <filter id="dropshadow" x="0" y="0" width="200%" height="200%">
        <feOffset result="offOut" in="SourceAlpha" dx="2" dy="2" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="1" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
    </defs>
  </svg>

  <div class="ion-text-center ion-padding-horizontal text-secondary">
    last updated {{ powerFlowStream.lastUpdated }}
  </div>
</div>
