<!-- header/toolbar (when in mobile mode) -->
<ion-header class="ion-no-border" *ngIf="vm.showHeader">
  <app-modal-toolbar
    (onBack)="handleToolbarBackClick()"
    (onCancel)="handleToolbarCancelClick()"
    [title]="headerTitle || title"
    [showBackButton]="vm.showBackButton"
    [showCancelButton]="vm.showCancelButton"
  ></app-modal-toolbar>
</ion-header>
<ion-grid class="full-height width-100-pct pr-0 scroll-y background-color-md-max" [ngClass]="vm.ngClassGrid">
  <ion-row [ngClass]="vm.ngClassRow">
    <ion-col class="pr-0">
      <div class="modal-scroll">
        <ion-card class="modal-card" [ngClass]="vm.ngClassCard">
          <!-- popup header -->
          <ion-row class="bb-1" *ngIf="title || vm.showRemoveButton">
            <ion-col size="12" class="pt-10 pb-10">
              <ion-item lines="none">
                <!-- title -->
                <ion-label class="fs-22 fw-600 text-dark ion-text-capitalize ion-text-wrap">
                  {{ title }}
                </ion-label>
                <!-- remove button -->
                <app-remove-button
                  *ngIf="vm.showRemoveButton"
                  slot="end"
                  (onClick)="handleRemoveClick()"
                ></app-remove-button>
              </ion-item>
            </ion-col>
          </ion-row>
          <!-- inject external content -->
          <ng-content></ng-content>
          <!-- action buttons -->
          <ng-container *ngIf="showFooter">
            <ion-row class="p-15" *ngIf="vm.showCancelButton || vm.showCreateButton || vm.showSaveButton">
              <!-- cancel button -->
              <ion-col size="12" size-md="4" class="ion-hide-md-down" *ngIf="vm.showCancelButton">
                <app-cancel-outline-button (onClick)="handleCancelClick()"></app-cancel-outline-button>
              </ion-col>
              <ion-col size="12" size-md="8">
                <!-- create button -->
                <app-create-rect-button
                  *ngIf="vm.showCreateButton"
                  (onClick)="handleCreateClick()"
                  [isDisabled]="isSaveDisabled"
                  [showIcon]="false"
                ></app-create-rect-button>
                <!-- save button -->
                <app-save-rect-button
                  *ngIf="vm.showSaveButton"
                  (onClick)="handleSaveClick()"
                  [isDisabled]="isSaveDisabled"
                ></app-save-rect-button>
              </ion-col>
            </ion-row>
          </ng-container>
        </ion-card>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
