<ion-header class="ion-no-border">
  <app-modal-toolbar
    (onBack)="handleToolbarBackClick()"
    [showBackButton]="true"
    title="{{'General.Device' | translate}}"
  ></app-modal-toolbar>
</ion-header>
<ion-content
  class="background-color"
  *ngIf="
(vm$ | async) &&
!(vm$ | async).error &&
!(vm$ | async).loading &&
  (vm$ | async).data &&
  (vm$ | async).data.length > 0 &&
  (vm$ | async).displayedEndpointDeviceIndex !== undefined &&
  (vm$ | async).displayedEndpointDeviceIndex.endpointIndex !== undefined &&
  (vm$ | async).displayedEndpointDeviceIndex.deviceIndex !== undefined;
  else noEndpointSelectedOrFound
"
>
  <ion-grid class="fullHeight p-20">
    <app-device-detail [isUnitActive]="isUnitActive"></app-device-detail>
  </ion-grid>
</ion-content>
<ng-template #noEndpointSelectedOrFound>
  <div class="p-20 background-color fullHeight">
    <app-devices-service-status></app-devices-service-status>
  </div>
</ng-template>
