<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'Droplet.Setup.AddCloudDevice' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="card-slide-layout">
    <ion-row class="minHeightFull">
      <ion-col>
        <ion-card class="ion-no-margin minHeightFull">
          <ion-row class="minHeightFull">
            <ion-col>
              <div *ngIf="deviceTypes.status.$callMade; else responsePending">
                <div *ngIf="!deviceTypes.status.$callFailed; else apiError">
                  <div *ngIf="deviceTypes.status.$dataExist; else noData">
                    <ion-searchbar [(ngModel)]="deviceTypeSearch" autocomplete="on"></ion-searchbar>
                    <ion-list>
                      <ion-list-header lines="full">
                        <ion-label class="ion-text-left ion-text-capitalize"
                          >{{ 'AddDevice.DeviceTypes' | translate }} |
                          <ion-text color="medium">
                            {{ 'AddDevice.DeviceTypeWillProvideMeasurements' | translate }}
                          </ion-text>
                        </ion-label>
                      </ion-list-header>
                      <ion-item
                        *ngFor="
                          let deviceType of deviceTypes.deviceTypesList | searchFilter: deviceTypeSearch;
                          let last = last
                        "
                        [lines]="last ? 'none' : 'full'"
                      >
                        <ion-label class="ion-text-wrap">
                          {{ deviceType.displayName }}
                        </ion-label>
                        <ion-button
                          class="ion-no-margin"
                          slot="end"
                          (click)="presentAlertConfirmationManualDevice(deviceType)"
                          size="small"
                          color="button-primary"
                        >
                          <ion-icon slot="icon-only" name="add"></ion-icon>
                        </ion-button>
                      </ion-item>
                    </ion-list>
                  </div>
                  <ng-template #noData>
                    <!-- NO DATA -->
                    <ion-item lines="none" class="ion-text-center">
                      <ion-label>
                        {{ 'AddDevice.NoDeviceTypeExist' | translate }}
                      </ion-label>
                    </ion-item>
                  </ng-template>
                </div>
                <ng-template #apiError>
                  <!-- API ERROR -->
                  <app-error [data]="deviceTypes.status.$error"></app-error>
                </ng-template>
              </div>
              <ng-template #responsePending>
                <!-- API RESPONSE PENDING -->
                <ion-row>
                  <ion-col size="12">
                    <div class="ion-margin-bottom">
                      <app-skeleton-items [skeletonType]="SkeletonPlaceholder.CARD"></app-skeleton-items>
                    </div>
                    <app-skeleton-items [skeletonType]="SkeletonPlaceholder.CARD"></app-skeleton-items>
                  </ion-col>
                </ion-row>
              </ng-template>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
