<ion-button
  *ngIf="isEnrolled; else enrollIn"
  (click)="handleClick()"
  color="button-primary"
  class="enroll-button-rounded"
  mode="ios"
  [disabled]="isDisabled"
>
  <span class="ml-15 fw-600 fs-14"> {{ 'Buttons.Enrolled' | translate }} </span>
  <ion-icon class="ml-7 mr-10 fs-14" src="assets/icons/svg/check-white.svg"></ion-icon>
</ion-button>
<ng-template #enrollIn>
  <ion-button
    (click)="handleClick()"
    color="button-primary"
    fill="outline"
    class="enroll-button-rounded"
    mode="ios"
    [disabled]="isDisabled"
  >
    <span class="fw-600 fs-14 ml-5 mr-5"> {{ 'Buttons.EnrollIn' | translate }} </span>
  </ion-button>
</ng-template>
