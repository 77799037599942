<ion-row>
  <ion-col>
    <ion-segment [value]="defaultTabIndex" mode="ios" class="m-a" [swipeGesture]="false">
      <ng-container *ngFor="let tab of tabs">
        @if (isTypeOfFunction(tab.showForOperationalState) ? tab.showForOperationalState(operationalState) : true) {
          <ion-segment-button
            *ifHasPermission="tab.permissionKey ?? true"
            [value]="tab.index"
            (click)="captureSegmentChange(tab)"
          >
            <ion-label>{{ tab.label | translate }}</ion-label>
          </ion-segment-button>
        }
      </ng-container>
    </ion-segment>
  </ion-col>
</ion-row>
