<ion-grid class="p-0 pr-5 pl-5" *ngIf="vm$ | async as vm">
  <ion-row class="pl-4 pr-4 fs-07em ion-align-items-center">
    <ion-col size="12" class="flex-col ion-no-padding pt-5">
      <ion-button
        class="date-shadow ion-padding-start step-date-button"
        (click)="onPrevious()"
        [disabled]="prevDateDisabled || vm.loading"
      >
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
      <ion-datetime-button datetime="datetime" class="width-100-pct b-1 b-radius-5"></ion-datetime-button>
      <ion-popover [keepContentsMounted]="true" [showBackdrop]="false">
        <ng-template>
          <ion-datetime
            id="datetime"
            presentation="month-year"
            [max]="ionMaxDate"
            [min]="ionMinDate"
            [value]="getIsoYearMonthDate(vm.date)"
            (ionChange)="dateChangedCalendar($event?.detail?.value)"
            [disabled]="vm.loading"
            [showDefaultButtons]="true"
          >
          </ion-datetime>
        </ng-template>
      </ion-popover>
      <ion-button
        class="date-shadow ion-padding-start step-date-button"
        (click)="onNext()"
        [disabled]="nextDateDisabled || vm.loading"
      >
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="vm.error">
    <ion-card class="error-card ion-text-center width-100-pct">
      <ion-card-header>
        <ion-card-title class="ion-align-self-center">
          <ion-icon color="danger" name="alert-circle-outline"></ion-icon>
          {{ 'Cost.FailedFetchData' | translate }}
        </ion-card-title>
      </ion-card-header>
    </ion-card>
  </ion-row>

  <ng-container *ngIf="!vm.error">
    <app-unit-costs-aggregate></app-unit-costs-aggregate>
    <app-unit-costs-summary></app-unit-costs-summary>
    <app-unit-costs-tariff></app-unit-costs-tariff>
  </ng-container>

  <ion-row *ngIf="!vm.error">
    <ion-col class="p-8" size="12">
      <app-unit-costs-metrics></app-unit-costs-metrics>
    </ion-col>
  </ion-row>
</ion-grid>
