<ion-grid>
  <ion-row>
    <ion-col>
      <div class="ion-text-center">
        <h2>{{ 'General.DataNotFound' | translate }}</h2>
        {{ msg | translate }}
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
