<div class="width-100-pct flex-center">
  <input
    *ngIf="inputType === 'string'; else numberInput"
    type="string"
    [ngModel]="value"
    (ngModelChange)="valueChanged($event)"
    [placeholder]="placeholder"
    [disabled]="isDisable"
    [maxlength]="maxLength"
    class="width-100-pct b-radius-6 text-dark fw-600 pl-12"
  />
  <ng-template #numberInput>
    <input
      type="number"
      [ngModel]="value"
      (ngModelChange)="valueChanged($event)"
      [placeholder]="placeholder"
      [disabled]="isDisable"
      class="width-100-pct b-radius-6 text-dark fw-600 pl-12"
    />
  </ng-template>
  <ion-button
    *ngIf="submitButton"
    (click)="valueSubmitted()"
    [disabled]="isDisable"
    class="update-button fs-14"
    mode="ios"
    color="button-primary"
    >{{ 'General.Update' | translate }}</ion-button
  >
</div>
