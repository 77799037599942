<!-- need to move all this to ion-accordion so we don't have to maintain toggle states -->
<ng-container *ngrxLet="vm$ as vm">
  <!-- Name Card -->
  <ion-card class="card-design pt-20 pb-20 m-0 mb-12">
    <ion-item lines="none">
      <ion-text class="fs-22 fw-600 text-dark text-break-word">
        {{ vm.name }}
      </ion-text>

      <div slot="end">
        <!-- <ng-container *ngIf="automatedTestingEnabled">
          <ng-container *ngrxLet="automatedTest$ as automatedTest">
            <ng-container *ifHasPermission="[PermissionKey.GLOBAL_INSTALLER]">
              <app-device-automated-testing
                [status]="automatedTest?.test_status"
                (click)="handleDiagnosticsButtonClick()"
                size="default"
              />
            </ng-container>
          </ng-container>
        </ng-container> -->

        <ion-button
          *ngIf="vm.enableEdit && isUnitActive"
          size="default"
          color="button-primary"
          [disabled]="vm.isEditDisabled"
          (click)="onEditClicked()"
        >
          {{ vm.editLabel }}
        </ion-button>
      </div>
    </ion-item>
    <div class="fs-13 text-light ml-15 mt-5 mb-5" *ngIf="vm.type">
      {{ 'UnitPage.Type' | translate }}:&nbsp;{{ vm.type }}
    </div>
    <div class="fs-13 text-light ml-15 mt-5 mb-5">{{ vm.numberLabel }}:&nbsp;{{ vm.number }}</div>
    <div class="ml-16" *ngIf="vm.enableMqttStatus && isUnitActive">
      <app-mqtt-status [mqttKeys]="{ mqttKeys: vm.mqttStatusKeys }"></app-mqtt-status>
    </div>
  </ion-card>

  <!-- Logical Details Card -->
  <ion-card class="card-design m-0 mb-12" *ngIf="vm.enableLogicalDetails">
    <ion-item
      class="mt-10 fs-22 fw-600 cursor-pointer"
      [lines]="isLogicalDeviceDetailCardExpanded ? 'full' : 'none'"
      (click)="isLogicalDeviceDetailCardExpanded = !isLogicalDeviceDetailCardExpanded"
    >
      <ion-text slot="start" class="text-dark">
        {{ 'General.DeviceDetail' | translate }}
      </ion-text>
      <ion-icon name="chevron-up-outline" slot="end" *ngIf="isLogicalDeviceDetailCardExpanded"></ion-icon>
      <ion-icon name="chevron-down-outline" slot="end" *ngIf="!isLogicalDeviceDetailCardExpanded"></ion-icon>
    </ion-item>
    <div *ngIf="isLogicalDeviceDetailCardExpanded">
      <ion-item lines="full" class="bg-table-gray-tile">
        <ion-text class="fs-14 text-dark">{{ 'UnitPage.Manufacturer' | translate }}</ion-text>
        <ion-text class="fs-16 text-dark" slot="end">{{ vm.logicalDetails.manufacturerName }}</ion-text>
      </ion-item>
      <ion-item lines="full" class="bg-table-gray-tile">
        <ion-text class="fs-14 text-dark">{{ 'UnitPage.Model' | translate }}</ion-text>
        <ion-text class="fs-16 text-dark" slot="end">{{ vm.logicalDetails.modelName }}</ion-text>
      </ion-item>
      <ion-item lines="full" class="bg-table-gray-tile" *ngIf="vm.logicalDetails.modelNumber">
        <ion-text class="fs-14 text-dark">{{ 'UnitPage.ModelNumber' | translate }}</ion-text>
        <ion-text class="fs-16 text-dark" slot="end">{{ vm.logicalDetails.modelNumber }}</ion-text>
      </ion-item>
      <ion-item lines="full" class="bg-table-gray-tile">
        <ion-text class="fs-14 text-dark">{{ 'UnitPage.SerialNo' | translate }}</ion-text>
        <ion-text class="fs-16 text-dark" slot="end">{{ vm.logicalDetails.serialNo }}</ion-text>
      </ion-item>
      <ion-item lines="full" class="bg-table-gray-tile">
        <ion-text class="fs-14 text-dark">{{ 'UnitPage.GenericDeviceType' | translate }}</ion-text>
        <ion-text class="fs-16 text-dark" slot="end">{{ vm.logicalDetails.genericDeviceTypeName }}</ion-text>
      </ion-item>
      <ion-item lines="full" class="bg-table-gray-tile">
        <ion-text class="fs-14 text-dark">{{ 'UnitPage.DriverProtocol' | translate }}</ion-text>
        <ion-text class="fs-16 text-dark" slot="end">{{ vm.logicalDetails.protocol }}</ion-text>
      </ion-item>
      <ion-item lines="full" class="bg-table-gray-tile">
        <ion-text class="fs-14 text-dark">{{ 'UnitPage.DeviceUniqueId' | translate }}</ion-text>
        <ion-text class="fs-16 text-dark" slot="end">{{ vm.logicalDetails.dropletDeviceUniqueId }}</ion-text>
      </ion-item>
      <ion-item lines="none" class="bg-table-gray-tile">
        <ion-text class="fs-14 text-dark">{{ 'UnitPage.SlaveID' | translate }}</ion-text>
        <ion-text class="fs-16 text-dark" slot="end">{{ vm.logicalDetails.slaveId }}</ion-text>
      </ion-item>
    </div>
  </ion-card>

  <!-- Device Attributes Card -->
  @if (vm.enableDeviceAttributes) {
    <ion-card class="card-design m-0 mb-12">
      <ion-item
        class="mt-10 fs-22 fw-600 cursor-pointer"
        [lines]="isDeviceAttributesCardExpanded ? 'full' : 'none'"
        (click)="isDeviceAttributesCardExpanded = !isDeviceAttributesCardExpanded"
      >
        <ion-text slot="start" class="text-dark"> {{ 'UnitPage.DeviceAttributes' | translate }} </ion-text>
        <ion-icon name="chevron-up-outline" slot="end" *ngIf="isDeviceAttributesCardExpanded"></ion-icon>
        <ion-icon name="chevron-down-outline" slot="end" *ngIf="!isDeviceAttributesCardExpanded"></ion-icon>
      </ion-item>
      <div *ngIf="isDeviceAttributesCardExpanded">
        @for (attribute of vm.deviceAttributes; track $index) {
          <ion-item lines="full" class="bg-table-gray-tile">
            <ion-text class="fs-14 text-dark">{{ attribute.name }}</ion-text>
            <ion-text class="fs-16 text-dark" slot="end">{{ attribute.value }}</ion-text>
          </ion-item>
        }
      </div>
    </ion-card>
  }

  <!-- Connection Attributes Card -->
  <ion-card class="card-design m-0 mb-12" *ngIf="vm.enableConnectionAttributes">
    <ion-item
      class="mt-10 fs-22 fw-600 cursor-pointer"
      [lines]="isConnectionAttributeCardExpanded ? 'full' : 'none'"
      (click)="isConnectionAttributeCardExpanded = !isConnectionAttributeCardExpanded"
    >
      <ion-text slot="start" class="text-dark">
        {{ 'UnitPage.ConnectionAttributes' | translate }}
      </ion-text>
      <ion-icon name="chevron-up-outline" slot="end" *ngIf="isConnectionAttributeCardExpanded"></ion-icon>
      <ion-icon name="chevron-down-outline" slot="end" *ngIf="!isConnectionAttributeCardExpanded"></ion-icon>
    </ion-item>
    <div *ngIf="isConnectionAttributeCardExpanded" class="bb-1-child-except-last">
      <ion-item *ngFor="let connection of vm.connectionAttributes" lines="none" class="bg-table-gray-tile">
        <ion-text class="fs-14 text-dark">{{ connection.connectionAttributeName }}</ion-text>
        <ion-text class="fs-16 text-dark" slot="end">{{ connection.value }}</ion-text>
      </ion-item>
    </div>
  </ion-card>

  <!-- Controls Card -->
  <ion-card class="card-design m-0 mb-12 bb-1-child-except-last" *ngIf="vm.enableControls && isUnitActive">
    <ion-item class="mt-10 fs-22 fw-600" lines="none">
      <ion-text slot="start" class="text-dark">
        {{ 'UnitPage.Controls' | translate }}
      </ion-text>
    </ion-item>
    <div *ngFor="let control of vm.controls">
      <app-device-control [uuid]="vm.dropletUuid" [deviceControl]="control"></app-device-control>
    </div>
  </ion-card>

  <!-- Setting Controls Card -->
  <ion-card class="card-design m-0 mb-12 bb-1-child-except-last" *ngIf="vm.enableSettings && isUnitActive">
    <ion-item class="mt-10 fs-22 fw-600" lines="none">
      <ion-text slot="start" class="text-dark">
        {{ 'UnitPage.Settings' | translate }}
      </ion-text>
    </ion-item>
    <div *ngFor="let setting of vm.settings">
      <app-device-control [uuid]="vm.dropletUuid" [deviceControl]="setting"></app-device-control>
    </div>
  </ion-card>

  <!-- Controllers Card -->
  <ion-card class="card-design m-0 mb-12" *ngIf="vm.enableControllers">
    <ion-item
      class="mt-10 fs-22 fw-600 cursor-pointer"
      [lines]="isControllerCardExpanded ? 'full' : 'none'"
      (click)="isControllerCardExpanded = !isControllerCardExpanded"
    >
      <ion-text slot="start" class="text-dark">
        {{ 'UnitPage.Controllers' | translate }}
      </ion-text>
      <ion-icon name="chevron-up-outline" slot="end" *ngIf="isControllerCardExpanded"></ion-icon>
      <ion-icon name="chevron-down-outline" slot="end" *ngIf="!isControllerCardExpanded"></ion-icon>
    </ion-item>
    <div *ngIf="isControllerCardExpanded" class="bb-1-child-except-last">
      <ng-container *ngFor="let controller of vm.controllers">
        <ion-item lines="none">
          <ion-text class="text-dark fs-14 fw-500 text-break-word">
            {{ controller.name }}
          </ion-text>
        </ion-item>
        <ion-item lines="none" class="bg-table-gray-tile">
          <ion-text class="fs-14 text-dark" slot="start">{{ 'General.Type' | translate }}</ion-text>
          <ion-text class="fs-16 text-dark text-break-word" slot="end">{{ controller.controllerType }}</ion-text>
        </ion-item>
        <ion-item lines="none" class="bg-table-gray-tile">
          <ion-text class="fs-14 text-dark" slot="start">{{ 'UnitPage.Version' | translate }}</ion-text>
          <ion-text class="fs-16 text-dark" slot="end">{{ controller.controllerVersionNumber }}</ion-text>
        </ion-item>
      </ng-container>
    </div>
  </ion-card>

  <!-- Information Card (dump out information object using keyvalue pipes) -->
  <ion-card class="card-design m-0 mb-12" *ngIf="vm.enableInformation">
    <ion-item
      class="mt-10 fs-22 fw-600 cursor-pointer"
      [lines]="isInformationCardExpanded ? 'full' : 'none'"
      (click)="isInformationCardExpanded = !isInformationCardExpanded"
    >
      <ion-text slot="start" class="text-dark">
        {{ 'UnitPage.Information' | translate }}
      </ion-text>
      <ion-icon name="chevron-up-outline" slot="end" *ngIf="isInformationCardExpanded"></ion-icon>
      <ion-icon name="chevron-down-outline" slot="end" *ngIf="!isInformationCardExpanded"></ion-icon>
    </ion-item>
    <div *ngIf="isInformationCardExpanded" class="bb-1-child-except-last">
      <ng-container *ngFor="let infoSection of vm.information | keyvalue">
        <ion-item lines="none" class="ion-text-capitalize">
          <ion-text class="text-dark fs-14 fw-500">
            {{ infoSection.key }}
          </ion-text>
        </ion-item>
        <ion-item *ngFor="let infoValues of infoSection.value | keyvalue" lines="none" class="bg-table-gray-tile">
          <ion-text class="fs-14 text-dark">{{ infoValues.key }}</ion-text>
          <ion-text class="fs-16 text-dark" slot="end">{{ infoValues.value }}</ion-text>
        </ion-item>
      </ng-container>
    </div>
  </ion-card>

  <!-- Controller Parameters Card -->
  <ion-card class="card-design m-0 mb-12" *ngIf="vm.enableParameters && isUnitActive">
    <ion-item
      class="mt-10 fs-22 fw-600 cursor-pointer"
      [lines]="isParametersCardExpanded ? 'full' : 'none'"
      (click)="isParametersCardExpanded = !isParametersCardExpanded"
    >
      <ion-text slot="start" class="text-dark">
        {{ 'UnitPage.Parameters' | translate }}
      </ion-text>
      <ion-icon name="chevron-up-outline" slot="end" *ngIf="isParametersCardExpanded"></ion-icon>
      <ion-icon name="chevron-down-outline" slot="end" *ngIf="!isParametersCardExpanded"></ion-icon>
    </ion-item>
    <div *ngIf="isParametersCardExpanded" class="bb-1-child-except-last">
      <ng-container *ngIf="vm.parameters?.length; else noParams">
        <div *ngFor="let param of vm.parameters">
          <app-device-control
            [uuid]="vm.dropletUuid"
            [deviceControl]="param"
            [controllerParamOptions]="{ isReadOnly: true }"
          >
          </app-device-control>
        </div>
      </ng-container>
      <ng-template #noParams>
        <ion-item lines="none">
          <ion-label class="fs-14 text-dark">{{ 'UnitPage.NoParametersAvailable' | translate }}</ion-label>
        </ion-item>
      </ng-template>
    </div>
  </ion-card>

  <!-- Devices List Card -->

  <ion-card class="card-design m-0 mb-12" *ngIf="vm.enableDevices">
    <ion-item
      class="mt-10 fs-22 fw-600 cursor-pointer"
      [lines]="isDevicesCardExpanded ? 'full' : 'none'"
      (click)="isDevicesCardExpanded = !isDevicesCardExpanded"
    >
      <ion-text slot="start" class="text-dark">
        {{ 'General.Devices' | translate }}
      </ion-text>
      <ion-icon name="chevron-up-outline" slot="end" *ngIf="isDevicesCardExpanded"></ion-icon>
      <ion-icon name="chevron-down-outline" slot="end" *ngIf="!isDevicesCardExpanded"></ion-icon>
    </ion-item>
    <div *ngIf="isDevicesCardExpanded" class="bb-1-child-except-last">
      <ng-container *ngIf="vm.devices?.length; else noDevices">
        <ion-item *ngFor="let device of vm.devices" lines="none" class="bg-table-gray-tile">
          <ion-text class="fs-14 text-dark">
            {{ device.deviceTypeName }}
            {{ device.deviceTypeName == device.fullName ? '' : ' - ' + device.fullName }}
          </ion-text>
        </ion-item>
      </ng-container>
      <ng-template #noDevices>
        <ion-item lines="none">
          <ion-label class="fs-14 text-dark">{{ 'UnitPage.DevicesNotAdded' | translate }}</ion-label>
        </ion-item>
      </ng-template>
    </div>
  </ion-card>

  <!-- Remove Device Button -->
  <ion-row *ngIf="vm.enableRemove && isUnitActive">
    <ion-col size="12" class="p-0 pb-20">
      <app-remove-button
        [size]="CustomButtonSize.LARGE"
        [label]="vm.removeLabel"
        [disabled]="vm.isRemoveDisabled"
        (onClick)="onRemoveClicked()"
      ></app-remove-button>
    </ion-col>
  </ion-row>
</ng-container>
