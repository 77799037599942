import { Component, OnInit, ViewChild, ElementRef, NgZone, OnDestroy, Input, AfterViewInit } from '@angular/core';
// import { Geolocation } from '@ionic-native/geolocation/ngx';
import { StoredAddress } from './stored-address.model';
import { BehaviorSubject } from 'rxjs';
import { IsPlatformValues } from '@class/commons/is-platform-values';

export enum Address {
  FULL_ADDRESS_FROM_GOOGLE,
  STREET_FROM_GOOGLE,
  SUBURB_FROM_GOOGLE,
  MANUAL_ENTRY,
}

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent extends IsPlatformValues implements OnInit, AfterViewInit, OnDestroy {
  // false means it is a portfolio address
  @Input() isDisabled: boolean;
  @Input() isUnitAddress: boolean;
  private GoogleAutocomplete;
  private GooglePlaceService;
  formattedAddress;
  selectedAddress;
  google_places_result = null;
  address: { portfolio: string; unit: StoredAddress } = {
    portfolio: '',
    unit: {
      address: '',
      latitude: 0,
      longitude: 0,
      city: '',
      state: '',
      country: '',
      postcode: '',
    },
  };
  map;
  mapMarker = null;
  public results = [];

  public manualAddress = {
    unitNo: '',
    streetNo: '',
    streetName: '',
    suburbName: '',
    postCode: '',
    state: '',
    country: '',
    lat: '',
    lang: '',
  };
  public searchAddress = '';

  public googleAddress = Address.FULL_ADDRESS_FROM_GOOGLE;
  public readonly Address = Address;

  // determines when to show the google maps search results
  viewResults = true;

  @ViewChild('map', { static: false }) googleMapDiv: ElementRef;
  //
  // Google Places needs to accept a html div so created one empty div to pass in the constructor
  // Need to use Places service to get the google_places_result
  //
  @ViewChild('mapEmpty', { static: false }) googleMapDivEmpty: ElementRef;

  // if you want to update an existing address using this component you can start with the old address
  @Input() inputAddress: StoredAddress;

  // has the form been touched
  touched = new BehaviorSubject(false);

  @Input() isEditUnit: boolean;

  constructor(
    // private geolocation: Geolocation,
    private zone: NgZone,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.inputAddress) {
      // set up initial address if provided one
      this.address.unit = JSON.parse(JSON.stringify(this.inputAddress));
      this.searchAddress = this.inputAddress.address;
    }
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
  }
  ngAfterViewInit() {
    this.GooglePlaceService = new google.maps.places.PlacesService(this.googleMapDivEmpty.nativeElement);
  }

  ngOnDestroy(): void {
    this.searchAddress = '';
    this.google_places_result = null;
    this.selectedAddress = null;
    this.formattedAddress = '';
    this.results = [];
    this.mapMarker = null;
    this.address = {
      portfolio: '',
      unit: {
        address: '',
        latitude: 0,
        longitude: 0,
        city: '',
        state: '',
        country: '',
        postcode: '',
      },
    };
  }

  loadMap(): void {
    let latLng = new google.maps.LatLng(-23.897652, 135.877223);
    this.map = new google.maps.Map(this.googleMapDiv.nativeElement, {
      zoom: 3,
      center: latLng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      zoomControl: true,
      streetViewControl: false,
      fullscreenControl: false,
    });
    if (this.manualAddress.lat && this.manualAddress.lang) {
      this.latLngInputChange(this.manualAddress.lat, this.manualAddress.lang);
    }
    this.map.addListener('click', (data) => {
      latLng = new google.maps.LatLng(data.latLng.lat(), data.latLng.lng());
      this.drawPinOnMap(latLng);
      this.manualAddress.lat = data.latLng.lat();
      this.manualAddress.lang = data.latLng.lng();
      this.address.unit.latitude = data.latLng.lat();
      this.address.unit.longitude = data.latLng.lng();
    });
  }

  drawPinOnMap(latLng): void {
    if (this.mapMarker) {
      this.mapMarker.setPosition(latLng);
      this.map.panTo(latLng);
    } else {
      this.mapMarker = new google.maps.Marker({
        map: this.map,
        animation: google.maps.Animation.DROP,
        position: latLng,
      });
    }
  }

  latLngInputChange(lat, lng): void {
    if (lat && lng) {
      this.address.unit.latitude = lat;
      this.address.unit.longitude = lng;
      const latLng = new google.maps.LatLng(lat, lng);
      this.drawPinOnMap(latLng);
    }
  }

  async search() {
    this.viewResults = true;
    this.selectedAddress = null;
    this.results = [];

    if (this.searchAddress == '') {
      return;
    }
    this.GoogleAutocomplete.getPlacePredictions({ input: this.searchAddress }, (predictions, status) => {
      if (status === 'OK') {
        this.zone.run(() => {
          predictions.forEach((prediction) => {
            this.results.push(prediction);
          });
        });
      } else {
        alert('Sorry, this search produced no results.');
      }
    });
  }

  changeAddressMode(addressType) {
    this.addressTouched();
    this.searchAddress = '';
    this.google_places_result = null;
    this.googleAddress = addressType;
    this.selectedAddress = null;
    this.formattedAddress = '';
    this.results = [];
    this.mapMarker = null;
    this.address = {
      portfolio: '',
      unit: {
        address: '',
        latitude: 0,
        longitude: 0,
        city: '',
        state: '',
        country: '',
        postcode: '',
      },
    };
    if (
      addressType == this.Address.MANUAL_ENTRY ||
      addressType == this.Address.STREET_FROM_GOOGLE ||
      addressType == this.Address.SUBURB_FROM_GOOGLE
    ) {
      this.formatAddressIfAddressSelected();
    } else if (this.inputAddress) {
      // if you were given an input address, return to this now
      this.address.unit = this.inputAddress;
      this.searchAddress = this.inputAddress.address;
    }
    if (addressType == this.Address.MANUAL_ENTRY && this.isUnitAddress) {
      setTimeout(() => {
        this.loadMap();
      }, 300);
    }
  }

  async useMyPosition() {
    // this.geolocation
    //   .getCurrentPosition({ timeout: 10000, enableHighAccuracy: true })
    //   .then((res: any) => {
    //     const point = { lat: res.coords.latitude, lng: res.coords.longitude };
    //     const options: GeocoderRequest = {
    //       position: point,
    //     };
    //     Geocoder.geocode(options)
    //       .then((mvcArray: any) => {
    //         // console.log('useMyLocation: ', mvcArray);
    //         this.zone.run(() => {
    //           this.results = mvcArray;
    //         });
    //       })
    //       .catch((error) => {});
    //   })
    //   .catch((error) => {
    //     this.zone.run(() => {
    //       this.results = [];
    //     });
    //     alert('Something went wrong, please ensure your GPS is enabled or manually enter address');
    //   });
  }

  formatAddressIfAddressSelected() {
    if (this.selectedAddress != null && this.selectedAddress != undefined) {
      switch (this.googleAddress) {
        case Address.STREET_FROM_GOOGLE:
          this.formattedAddress = this.manualAddress.unitNo ? 'Unit ' + this.manualAddress.unitNo + ', ' : '';
          this.formattedAddress += this.manualAddress.streetNo + ' ' + this.selectedAddress;
          if (this.isUnitAddress) {
            this.address.unit.address = this.formattedAddress;
          } else {
            this.address.portfolio = this.formattedAddress;
          }
          break;
        case Address.SUBURB_FROM_GOOGLE:
          this.formattedAddress = this.manualAddress.unitNo ? 'Unit ' + this.manualAddress.unitNo + ', ' : '';
          this.formattedAddress +=
            this.manualAddress.streetNo +
            ' ' +
            this.returnFirstLetterUpperCase(this.manualAddress.streetName) +
            ', ' +
            this.selectedAddress;
          if (this.isUnitAddress) {
            this.address.unit.address = this.formattedAddress;
          } else {
            this.address.portfolio = this.formattedAddress;
          }
          break;
      }
    } else if (this.googleAddress == Address.MANUAL_ENTRY) {
      this.formattedAddress = this.manualAddress.unitNo ? 'Unit ' + this.manualAddress.unitNo + ', ' : '';
      this.formattedAddress +=
        this.manualAddress.streetNo +
        ' ' +
        this.returnFirstLetterUpperCase(this.manualAddress.streetName) +
        ', ' +
        this.returnFirstLetterUpperCase(this.manualAddress.suburbName) +
        ' ' +
        this.manualAddress.state.toUpperCase() +
        ' ' +
        this.manualAddress.postCode +
        ', ' +
        this.returnFirstLetterUpperCase(this.manualAddress.country);
      if (this.isUnitAddress) {
        this.address.unit.address = this.formattedAddress;
        this.address.unit.city = this.returnFirstLetterUpperCase(this.manualAddress.suburbName);
        this.address.unit.country = this.returnFirstLetterUpperCase(this.manualAddress.country);
        this.address.unit.postcode = this.manualAddress.postCode;
        this.address.unit.state = this.manualAddress.state.toUpperCase();
      } else {
        this.address.portfolio = this.formattedAddress;
      }
    }
  }

  private returnFirstLetterUpperCase(string: string): string {
    if (string == null || string == undefined) {
      return '';
    } else {
      const temp = string.split(' ');
      let tempString = '';
      temp.forEach((subString, index) => {
        tempString += subString.charAt(0).toUpperCase() + subString.substring(1).toLowerCase();
        if (index != temp.length - 1) tempString += ' ';
      });
      return tempString;
    }
  }

  public selectAddress(addr) {
    // this.results = [];
    this.selectedAddress = addr;
    this.searchAddress = addr.description;
    this.GooglePlaceService.getDetails(
      {
        placeId: addr.place_id,
        fields: [
          'address_components',
          'formatted_address',
          'geometry',
          'name',
          'place_id',
          'reference',
          'types',
          'url',
          'vicinity',
        ],
      },
      (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.zone.run(() => {
            // the result returned by placeId will be a single obj ...
            result['description'] = result.formatted_address;
            this.google_places_result = result;

            switch (this.googleAddress) {
              case this.Address.STREET_FROM_GOOGLE:
                this.formattedAddress = this.manualAddress.unitNo ? 'Unit ' + this.manualAddress.unitNo + ', ' : '';
                this.formattedAddress += this.manualAddress.streetNo + ' ' + result.formatted_address;
                break;
              case this.Address.SUBURB_FROM_GOOGLE:
                this.formattedAddress = this.manualAddress.unitNo ? 'Unit ' + this.manualAddress.unitNo + ', ' : '';
                this.formattedAddress +=
                  this.manualAddress.streetNo +
                  ' ' +
                  this.returnFirstLetterUpperCase(this.manualAddress.streetName) +
                  ', ' +
                  result.formatted_address;
                break;
            }
            if (this.isUnitAddress) {
              this.searchAddress = result.formatted_address;
              if (this.googleAddress === Address.FULL_ADDRESS_FROM_GOOGLE) {
                this.address.unit.address = result.formatted_address;
              } else {
                this.address.unit.address = this.formattedAddress;
              }
              this.address.unit.latitude = result.geometry.location.lat();
              this.address.unit.longitude = result.geometry.location.lng();
              let city = '';
              let state = '';
              let country = '';
              let code = '';
              result.address_components.forEach((component) => {
                if (component.types.includes('locality') && component.types.includes('political')) {
                  city = component.long_name ? component.long_name : component.short_name;
                }
                if (component.types.includes('administrative_area_level_1') && component.types.includes('political')) {
                  state = component.short_name;
                }
                if (component.types.includes('country') && component.types.includes('political')) {
                  country = component.long_name ? component.long_name : component.short_name;
                }
                if (component.types.includes('postal_code')) {
                  code = component.long_name ? component.long_name : component.short_name;
                }
              });
              this.address.unit.city = city;
              this.address.unit.state = state;
              this.address.unit.country = country;
              this.address.unit.postcode = code;
            } else {
              this.searchAddress = result.formatted_address;
              if (this.googleAddress === Address.FULL_ADDRESS_FROM_GOOGLE) {
                this.address.portfolio = result.formatted_address;
              } else {
                this.address.portfolio = this.formattedAddress;
              }
            }
            this.viewResults = false;
          });
        } else {
          alert('Sorry, this search produced no results.');
        }
      },
    );
  }

  isUnitAddressValid(): boolean {
    let addressIsValid = false;

    if (
      this.address.unit.address !== null &&
      this.address.unit.address.length > 0 &&
      this.address.unit.city !== null &&
      this.address.unit.city.length > 0 &&
      this.address.unit.state !== null &&
      this.address.unit.state.length > 0 &&
      this.address.unit.country !== null &&
      this.address.unit.country.length > 0 &&
      this.address.unit.postcode !== null &&
      this.address.unit.postcode.length > 0 &&
      this.address.unit.latitude !== 0 &&
      this.address.unit.longitude !== 0
    ) {
      addressIsValid = true;
    }

    return addressIsValid;
  }

  addressTouched(): void {
    this.touched.next(true);
  }
}
