import { Component, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import Plotly from 'plotly.js-dist';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, scan } from 'rxjs/operators';
import { VppTimezoneService } from 'app/services/virtualPowerPlants/timezone/vpp-timezone.service';
import { DateTime } from 'luxon';
import { ThemeService } from 'app/services/themes/theme.service';

@Component({
  selector: 'app-vpp-operation-tab-streaming-widget',
  templateUrl: './vpp-operation-tab-streaming-widget.component.html',
  styleUrls: ['./vpp-operation-tab-streaming-widget.component.scss'],
})
export class VppOperationTabStreamingWidgetComponent implements OnDestroy {
  @Input() name: string;
  @Input()
  set data$(data$: Observable<{ value: number; timestamp: number }>) {
    const graph$ = this.createObservableForStream(data$);
    this.subscribeObservableToDrawGraph(graph$);
  }
  @Input() total$: Observable<number>;
  @ViewChild('widGraph', { static: false }) widGraph: ElementRef;
  private readonly MAX_STREAM_POINTS = 20;
  private readonly layout = {
    width: 320,
    height: 88,
    margin: {
      l: 0,
      b: 0,
      t: 0,
      r: 0,
    },
    xaxis: {
      showgrid: false,
      zeroline: false,
      fixedrange: true,
      tickformat: '%H:%M:%S',
    },
    yaxis: {
      showgrid: false,
      zeroline: false,
      fixedrange: true,
    },
    hovermode: 'closest',
  };
  private subscription: Subscription;
  public latestValue: number;
  public showLoader = true;

  constructor(
    private timezoneService: VppTimezoneService,
    private themeService: ThemeService,
  ) {}

  private subscribeObservableToDrawGraph(data$: Observable<{ values: number[]; timestamps: string[] }>): void {
    const primaryColor = this.themeService.$userTheme.styles.brandPrimary[0];
    this.subscription = data$.subscribe(({ values, timestamps }) => {
      this.showLoader = false;
      this.latestValue = values[values.length - 1];
      const data = [
        {
          y: values,
          x: timestamps,
          hoverinfo: 'x+y',
          type: 'scatter',
          mode: values.length === 1 ? 'markers' : 'lines',
          line: { color: primaryColor.value, shape: 'hvh' },
        },
      ];
      this.layout.width =
        this.widGraph?.nativeElement?.offsetWidth === 0 && this.layout.width === 320
          ? 320
          : this.widGraph?.nativeElement?.offsetWidth;
      if (values.length <= 1) {
        Plotly.newPlot(this.widGraph?.nativeElement, data, this.layout, { displayModeBar: false, responsive: true });
      } else {
        Plotly.react(this.widGraph?.nativeElement, data, this.layout, { displayModeBar: false, responsive: true });
      }
    });
  }

  private createObservableForStream(
    obs: Observable<{ value: number; timestamp: number }>,
  ): Observable<{ values: number[]; timestamps: string[] }> {
    return combineLatest([obs, this.timezoneService.timezone$]).pipe(
      filter(([data]) => data?.value != null && data?.timestamp != null),
      scan(
        ({ values, timestamps }, [{ value, timestamp }, timezone]) => {
          values = [...values, value];
          timestamps = [...timestamps, DateTime.fromSeconds(timestamp).setZone(timezone).toString()];
          // remove oldest data points if exceed max points
          while (values.length >= this.MAX_STREAM_POINTS) values.shift();
          while (timestamps.length >= this.MAX_STREAM_POINTS) timestamps.shift();
          return { values, timestamps };
        },
        { values: [], timestamps: [] },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
