import { AfterViewInit, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { PermissionsService } from '@service/permissions/permissions.service';
import moment from 'moment-timezone';
import { Subscription } from 'rxjs';
import { adaptVppsEditToPutPayload, adaptVppToEditData, VppEditFormData } from '../../classes/vpp/vpp.model';
import { TranslationsService } from '../../services/common/translations.service';
import {
  appendUtcOffsetToTimezoneName,
  timezoneNames,
} from '../../services/virtualPowerPlants/timezone/timezone-names';
import { VirtualPowerPlantsService } from '../../services/virtualPowerPlants/virtual-power-plants.service';
import { FilterSelectComponent } from '../filter-select/filter-select.component';

@Component({
  selector: 'app-vpp-general-settings',
  templateUrl: './vpp-general-settings.component.html',
  styleUrls: ['./vpp-general-settings.component.scss'],
})
export class VppGeneralSettingsComponent implements AfterViewInit, OnDestroy {
  @Output() submitted = new EventEmitter<void>();
  readonly PermissionKey = PermissionKey;
  formData: VppEditFormData;
  editVpp = false;
  submitButtonText: string;
  titleText: string;
  loadingTimezones = false;
  private sub: Subscription;

  // Permissions
  // NOTE: Normally would use the IfHasPermissionDirective but that does
  //       currently not support permission and additional logic
  permissions = {
    VPP_CHANGE_VPP: true,
  };
  formPermissionToCheck = PermissionKey.GLOBAL_VPP;

  constructor(
    private vppService: VirtualPowerPlantsService,
    private trans: TranslationsService,
    private alertController: AlertController,
    private modalController: ModalController,
    private toast: ToastController,
    private permissionsService: PermissionsService,
    private loadingController: LoadingController,
    private router: Router,
  ) {
    this.sub = this.vppService.vppSelected$.subscribe((vpp) => {
      this.formData = adaptVppToEditData(vpp);
      this.editVpp = this.formData?.id != null;
      this.formPermissionToCheck = this.editVpp ? PermissionKey.VPP_CHANGE_VPP : PermissionKey.GLOBAL_VPP;
      const { UpdateVPP, CreateVPP } = this.trans.instant('VirtualPowerPlant');
      this.submitButtonText = this.editVpp ? UpdateVPP.Update : CreateVPP.Save;
      this.titleText = this.editVpp ? UpdateVPP.Title : CreateVPP.Title;
    });
  }

  ngAfterViewInit(): void {
    this.permissions.VPP_CHANGE_VPP = this.permissionsService.any([this.formPermissionToCheck]);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  async submitVppData(data: VppEditFormData): Promise<void> {
    const payload = adaptVppsEditToPutPayload(data);
    const { UpdateVPP, CreateVPP } = this.trans.instant('VirtualPowerPlant');
    const toastMsg = this.editVpp ? UpdateVPP.VppUpdatedSuccessfully : CreateVPP.VppCreatedSuccessfully;
    try {
      if (this.editVpp) {
        await this.vppService.updateVppDetails(payload, data.id);
      } else {
        await this.vppService.createVPP(payload);
      }
      await this.presentToast(toastMsg);
    } catch (error) {
      console.error(error);
      const msg = error?.status
        ? `${CreateVPP.VppFail.CreateMsg} ${error.status} ${error.statusText}. `
        : CreateVPP.VppFail.CreateMsg + error;
      await this.presentAlertSimpleOk(CreateVPP.VppFail.Header, CreateVPP.VppFail.CreateSubHeader, msg);
    } finally {
      this.dismiss();
    }
  }

  dismiss(): void {
    this.submitted.emit();
  }

  private async presentAlertSimpleOk(header: string, subheader: string, message: string): Promise<void> {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  private async presentToast(message: string, duration = 3000): Promise<void> {
    const messageToast = await this.toast.create({
      message: message,
      duration: duration,
      position: 'top',
    });

    await messageToast.present();
  }

  async presentTimezoneSelect(): Promise<void> {
    if (this.permissions.VPP_CHANGE_VPP) {
      this.loadingTimezones = true;
      const timezoneSelections = (moment.tz?.names() ?? timezoneNames).map((tz) => appendUtcOffsetToTimezoneName(tz));
      const modal = await this.modalController.create({
        component: FilterSelectComponent,
        cssClass: 'transparent-modal',
        componentProps: {
          header: 'Select Vpp timezone',
          data: timezoneSelections,
        },
      });
      await modal.present();
      this.loadingTimezones = false;

      const timezoneSelect = (await modal.onDidDismiss()).data as string;
      if (timezoneSelect != null) this.formData.timezone = timezoneSelect.split(' ')[0];
    }
  }

  async presentAlertConfirm(vpp) {
    let header = '',
      subHeader = '',
      message = '';
    header = this.trans.str.vpp.RemoveVpp.Header;
    subHeader = this.trans.str.vpp.RemoveVpp.Subheader;
    message = this.trans.str.vpp.RemoveVpp.Message + '<strong>' + vpp.name + '</strong>';
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: [
        {
          text: this.trans.instant('General.No'),
        },
        {
          text: this.trans.instant('General.Yes'),
          handler: () => {
            this.removeVpp(vpp.id);
          },
        },
      ],
    });
    await alert.present();
  }
  removeVpp(vppId) {
    this.vppService.removeVpp(vppId).then(
      () => {
        this.presentToast(this.trans.str.vpp.RemoveVpp.Success);
        this.refreshVppList();
      },
      (error) => {
        let msg = this.trans.str.vpp.RemoveVpp.VppFail.CreateMsg;
        if (error.hasOwnProperty('status')) {
          msg = msg + ' ' + error.status + ' ' + error.statusText + '. ';
        }
        this.presentAlertSimpleOk(
          this.trans.str.vpp.RemoveVpp.VppFail.Header,
          this.trans.str.vpp.RemoveVpp.VppFail.CreateSubHeader,
          msg,
        );
      },
    );
  }
  refreshVppList() {
    this.router.navigateByUrl('/virtual_power_plants');
    this.vppService.resetVppListSubs();
    this.createLoader(this.trans.str.general.Refreshing + ', ' + this.trans.str.general.Wait).then((loader) => {
      this.vppService.VppsList(loader);
    });
  }
  createLoader(message) {
    return this.loadingController.create({
      message: message,
    });
  }
}
