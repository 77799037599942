import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Unit } from '../../classes/commons/unit.model';
import { PortfolioUnitStatus } from '../status-circle/status-circle.component';
import { UnitOperationalStates } from '@class/commons/enums';
import { getUnitPendingOperationalState, getUnitOperationalState } from '@utils/unit/unit-operational-state';
import { AnalyticsService } from '@service/common/analytics.service';

@Component({
  selector: 'app-unit-card',
  templateUrl: './unit-card.component.html',
  styleUrls: ['./unit-card.component.scss'],
})
export class UnitCardComponent {
  @Input()
  set data(portfolioUnitSetData: Unit) {
    this.name = portfolioUnitSetData.name;
    this.address = portfolioUnitSetData.address;
    this.onlineStatus =
      portfolioUnitSetData.operationalState === UnitOperationalStates.DELETED
        ? PortfolioUnitStatus.DELETED
        : portfolioUnitSetData.onlineStatus;
    this.operationalStatusText = portfolioUnitSetData.actionPending
      ? getUnitPendingOperationalState[portfolioUnitSetData.operationalState]
      : getUnitOperationalState[portfolioUnitSetData.operationalState];
    this.cardColor =
      portfolioUnitSetData.operationalState === UnitOperationalStates.DELETED
        ? 'text-secondary'
        : 'background-tertiary';
    this.#unitUuid = portfolioUnitSetData.uuid;
    this.unitNameAddressAdditionalClass =
      portfolioUnitSetData.operationalState === UnitOperationalStates.DELETED ? 'text-primary' : '';
  }

  onlineStatus = PortfolioUnitStatus.UNKNOWN;
  name = '';
  address = '';
  operationalStatusText = null;
  cardColor: 'background-tertiary' | 'text-secondary' = 'background-tertiary';
  unitNameAddressAdditionalClass = '';

  #unitUuid: string;
  get unitUuid() {
    return this.#unitUuid;
  }

  readonly #analytics = inject(AnalyticsService);
  enableUnitStatusPill = this.#analytics.plugins.posthog.isFeatureEnabled('unit-status-pill');

  constructor(private router: Router) {}

  navigateToUnit(): void {
    this.router.navigate(['/unit', this.#unitUuid]);
  }
}
