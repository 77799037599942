<ion-grid class="pt-0">
  <ion-row>
    <!-- LHS: droplet list -->
    <ion-col
      size="12"
      size-lg="4"
      class="ion-no-padding"
      [ngClass]="commonService.OPEN_DEVICE_CONTROLLER_IN_MODAL ? 'pb-20' : 'scroll-y-70'"
    >
      <ng-container
        *ngIf="(vm$ | async).data?.length > 0 && !(vm$ | async).error && !(vm$ | async).loading; else notOK"
      >
        <div *ngFor="let endpoint of (vm$ | async).data; let endpointIndex = index" class="mb-10">
          <app-droplet
            [mode]="mode"
            [endpoint]="endpoint"
            [endpointIndex]="endpointIndex"
            (deviceClick)="handleDeviceSelected($event)"
            (controllerClick)="handleControllerSelected($event)"
            (addDeviceClick)="handleAddDeviceClick($event)"
            (addControllerClick)="handleAddControllerClick($event)"
            [isUnitActive]="isUnitActive"
          ></app-droplet>
        </div>
      </ng-container>
      <ng-template #notOK>
        <ng-container *ngIf="!commonService.OPEN_DEVICE_CONTROLLER_IN_MODAL; else mobileView">
          <ng-container *ngIf="!(vm$ | async).data || (vm$ | async).loading">
            <app-droplet [isLoading]="(vm$ | async).loading"></app-droplet>
          </ng-container>
        </ng-container>
        <ng-template #mobileView>
          <app-devices-service-status [mode]="mode"></app-devices-service-status>
        </ng-template>
      </ng-template>
    </ion-col>
    <!-- RHS: device/controller details (if not in mobile mode, otherwise programmatically displayed via modal) -->
    <ion-col size="12" size-lg="8" *ngIf="!commonService.OPEN_DEVICE_CONTROLLER_IN_MODAL" class="pt-0">
      <div
        class="scroll-y-70"
        *ngIf="
          (mode === DropletItemMode.DEVICE && vm.hasSelectedDevice) ||
            (mode === DropletItemMode.CONTROLLER && vm.hasSelectedController);
          else notOK
        "
      >
        <app-device-detail *ngIf="mode === DropletItemMode.DEVICE" [isUnitActive]="isUnitActive"></app-device-detail>
        <app-controller-detail
          *ngIf="mode === DropletItemMode.CONTROLLER"
          [isUnitActive]="isUnitActive"
        ></app-controller-detail>
      </div>
      <ng-template #notOK>
        <app-devices-service-status [mode]="mode"></app-devices-service-status>
      </ng-template>
    </ion-col>
  </ion-row>
</ion-grid>
