import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-mac-entry-modal-page',
  templateUrl: './mac-entry-modal.page.html',
  styleUrls: ['./mac-entry-modal.page.scss'],
})
export class MacEntryModalPage implements OnInit {
  public mac = '';
  constructor(public viewCtrl: ModalController) {}

  ngOnInit() {}

  dismiss() {
    let data = { macAddress: this.mac };
    this.viewCtrl.dismiss(data);
  }
  doNothing() {}
  close() {
    this.viewCtrl.dismiss();
  }
}
