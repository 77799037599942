import { TranslationsService } from './../../../services/common/translations.service';
import { PortfoliosService } from './../../../services/portfolios/portfolios.service';
import { VirtualPowerPlantsService } from './../../../services/virtualPowerPlants/virtual-power-plants.service';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { ThemeService } from '../../../services/themes/theme.service';
import { UnitsService } from '../../../services/units/units.service';
import { THEMES } from '../../../../theme/theme-map';
import { ProfileService } from '../../../services/profile/profile.service';
import { InviteRoleInterface } from '../../../classes/commons/invite-role-interface';
import { apiStatus } from '../../../classes/commons/common';
import { ColBreakpoint } from '@class/commons/enums';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { PortfolioUsersStore } from '@service/portfolios/portfolio-users.store';
import { UnitUserStore } from '@service/units/unit-user.store';

export enum UserInviteType {
  VPP = 'vpp',
  PORTFOLIO = 'portfolio',
  UNIT = 'unit',
}

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.page.html',
  styleUrls: ['./invite-user.page.scss'],
})
export class InviteUserPage implements OnInit {
  type = UserInviteType.VPP; // The default type of this form...
  portfolioId: string;
  unitId: string;
  User = {
    name: '',
    email: '',
    referer_browser_skin: null,
    role: null,
  };

  public customAlertOptions = {
    translucent: true,
    cssClass: 'alert-min-width-70-pct',
  };

  roles: Array<InviteRoleInterface>;
  rolesStatus: apiStatus;

  textMessages = {
    vpp: {
      loader:
        this.trans.instant('VirtualPowerPlant.InviteUser.InvitingUser') + ', ' + this.trans.instant('General.Wait'),
      description: this.trans.instant('VirtualPowerPlant.InviteUser.Description'),
      success: this.trans.instant('VirtualPowerPlant.InviteUser.InvitationSentSuccess'),
      fail: this.trans.instant('VirtualPowerPlant.InviteUser.UserFail.CreateSubHeader'),
    },
    portfolio: {
      loader: this.trans.instant('General.Wait'),
      description: this.trans.instant('Portfolio.InviteUser.Description'),
      success: this.trans.instant('Portfolio.InviteUser.Success'),
      fail: this.trans.instant('Portfolio.InviteUser.Fail'),
    },
    unit: {
      loader: this.trans.instant('General.Wait'),
      description: this.trans.instant('UnitDetails.InviteUserDescription'),
      success: this.trans.instant('Portfolio.InviteUser.Success'),
      fail: this.trans.instant('Portfolio.InviteUser.Fail'),
    },
  };

  public readonly THEMES = THEMES;
  constructor(
    public theme: ThemeService,
    public profileService: ProfileService,
    private viewCtrl: ModalController,
    private permissionsService: PermissionsService,
    private vppService: VirtualPowerPlantsService,
    private trans: TranslationsService,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private portfoliosService: PortfoliosService,
    private unitsService: UnitsService,
    private _portfolioUserStore: PortfolioUsersStore,
    private _unitUserStore: UnitUserStore,
  ) {}

  ngOnInit(): void {
    this.User.referer_browser_skin = this.theme.$userTheme.name;
    this.setIonSelectWidth();
  }

  public dismiss(data = null): void {
    this.viewCtrl.dismiss(data);
  }

  setIonSelectWidth() {
    // set the width of the ion-select modal box
    if (window.innerWidth >= ColBreakpoint.XL) {
      this.customAlertOptions.cssClass = 'alert-min-width-30-pct';
    } else if (window.innerWidth >= ColBreakpoint.LG) {
      this.customAlertOptions.cssClass = 'alert-min-width-50-pct';
    } else {
      this.customAlertOptions.cssClass = 'alert-min-width-70-pct';
    }
  }

  inviteUser(User) {
    switch (this.type) {
      case UserInviteType.PORTFOLIO:
        if (!this.permissionsService.any([PermissionKey.PORTFOLIO_ADD_PORTFOLIOMEMBERSHIP])) {
          return;
        }
        break;
      case UserInviteType.VPP:
        if (!this.permissionsService.any([PermissionKey.VPP_ADD_VPPMEMBERSHIP])) {
          return;
        }
        break;
      case UserInviteType.UNIT:
        if (!this.permissionsService.any([PermissionKey.UNIT_ADD_UNITMEMBERSHIP])) {
          return;
        }
        break;
    }
    this.createLoader(this.textMessages[this.type].loader).then((loader) => {
      loader.present();
      if (this.type === UserInviteType.PORTFOLIO) {
        this.portfoliosService
          .inviteUser(this.portfolioId, User)
          .then(() => {
            this.loadingController.dismiss();
            this.presentAlert(this.trans.instant('General.Success'), this.textMessages.portfolio.success);
            this.dismiss(true);
            this._portfolioUserStore.getPortfolioUsersById(this.portfolioId);
          })
          .catch((e) => {
            let message = '';
            if (e.error) {
              if (typeof e.error === 'object') {
                for (const p of Object.keys(e.error)) {
                  message = message + e.error[p];
                }
              } else {
                message = e.error;
              }
            } else {
              message = e;
            }
            this.presentAlert(this.textMessages[this.type].fail, message);
            this.loadingController.dismiss();
          });
      } else if (this.type === UserInviteType.VPP) {
        this.vppService.InviteUsersByVppId(this.vppService.selectedVpp.id, User).then(
          () => {
            loader.dismiss();
            this.presentAlert(this.trans.instant('General.Success'), this.textMessages[this.type].success);
            this.dismiss(true);
          },
          (error) => {
            loader.dismiss();
            let msg = this.trans.instant('VirtualPowerPlant.InviteUser.UserFail.CreateMsg');
            if (error.hasOwnProperty('status')) {
              msg = msg + ' ' + error.status + ' ' + error.statusText + '. ';
            }
            this.presentAlert(this.textMessages[this.type].fail, msg);
          },
        );
      } else if (this.type === UserInviteType.UNIT) {
        this.unitsService
          .inviteUnitUser(this.unitId, User)
          .then((data) => {
            if (data.data.ok) {
              this.loadingController.dismiss();
              this.presentAlert(this.trans.instant('General.Success'), this.textMessages.portfolio.success);
              this.dismiss(true);
              this._unitUserStore.getUnitUsersById();
            }
          })
          .catch((e) => {
            let message = '';
            if (e.error) {
              if (typeof e.error === 'object') {
                for (const p of Object.keys(e.error)) {
                  message = message + e.error[p];
                }
              } else {
                message = e.error;
              }
            } else {
              message = e;
            }
            this.presentAlert(this.textMessages[this.type].fail, message);
            this.loadingController.dismiss();
          });
      }
    });
  }

  createLoader(message) {
    return this.loadingController.create({
      message: message,
    });
  }

  async presentAlert(header, message) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [this.trans.instant('General.OK')],
    });
    await alert.present();
  }
}
