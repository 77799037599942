<ion-button
  (click)="handleClick()"
  expand="block"
  color="success-lite"
  class="resume-button"
  mode="ios"
  [disabled]="isDisabled"
>
  <ion-icon slot="start" class="icon-left" src="assets/icons/svg/play.svg"></ion-icon>
  <span class="fs-14 button-text ion-text-capitalize fw-400"> {{ 'Alerts.ResumeAlert' | translate }} </span>
</ion-button>
