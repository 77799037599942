import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CostMetricItems } from '../../classes/units/tariff/cost.model';
import { ProfileService } from '../../services/profile/profile.service';
import Plotly from 'plotly.js-dist';
import { ThemeService } from '../../services/themes/theme.service';
import { MAX_WIDTH_WIDE } from '@class/commons/constants';
@Component({
  selector: 'app-unit-costs-subcost-barchart',
  templateUrl: './unit-costs-subcost-barchart.component.html',
  styleUrls: ['./unit-costs-subcost-barchart.component.scss'],
})
export class UnitCostsSubcostBarchartComponent implements AfterViewInit {
  @ViewChild('submetricBarchart', { static: false }) submetricBarchart: ElementRef;

  @Input()
  set metrics(metrics: CostMetricItems) {
    this._metrics = metrics;
    if (this.isChartReady) {
      this.drawChart(this._metrics);
    }
  }
  private _metrics: CostMetricItems;
  private isChartReady = false;
  private chartColor = '';
  constructor(
    private profileService: ProfileService,
    private themeService: ThemeService,
  ) {}

  ngAfterViewInit(): void {
    if (this._metrics) {
      this.drawChart(this._metrics);
    } else {
      this.isChartReady = true;
    }
  }

  drawChart(metrics: CostMetricItems) {
    this.chartColor = this.themeService.getChartColors().primary.value;
    const barData = [
      {
        x: metrics.values.map((value) => value.x),
        y: metrics.values.map((value) => value.y),
        marker: { color: this.chartColor },
        name: metrics.title,
        type: 'bar',
      },
    ];
    const layout = {
      showlegend: true,
      legend: {
        x: 0.5,
        y: -0.2,
        xanchor: 'center',
        orientation: 'h',
        font: { color: this.profileService.darkThemeSelected() ? 'rgba(153,153,153,1)' : 'rgba(92,100,110,0.6)' },
      },
      dataValues: true,
      // title: this.chartTitle,
      margin: { l: 60, r: 20, b: 30, t: 25 },
      height: 200,
      // TODO: will replace this 1400 with constant - it has been defined in site energy PR
      // this will adjust the first draw, but might not work for resize
      // If I don't pass any width, then the responsive: true in the config will automatically resize this
      // but after passing the custom width, it doesn't work
      // so for the time being, went with this option
      // the first draw will be fine, if user resize the screen
      // they have to refresh the page
      width: this.submetricBarchart.nativeElement.clientWidth
        ? this.submetricBarchart.nativeElement.clientWidth
        : window.innerWidth > MAX_WIDTH_WIDE
        ? MAX_WIDTH_WIDE * 0.95
        : window.innerWidth * 0.95,
      autosize: false,
      xaxis: {
        tickformat: '%d',
        ticklen: 5,
        tickwidth: 0,
        // TODO: move these color constants to a common file
        linecolor: this.profileService.darkThemeSelected() ? 'rgba(153,153,153,0.4)' : 'rgba(92,100,110,0.4)',
        tickcolor: 'rgba(0, 0, 0, 0)',
        tickfont: { color: this.profileService.darkThemeSelected() ? 'rgba(153,153,153,1)' : 'rgba(92,100,110,0.6)' },
      },
      yaxis: {
        // TODO: move these color constants to a common file
        gridcolor: this.profileService.darkThemeSelected() ? 'rgba(153,153,153,0.2)' : 'rgba(92,100,110,0.2)',
        zeroline: false,
        ticklen: 20,
        tickwidth: 0,
        tickcolor: 'rgba(0, 0, 0, 0)',
        tickfont: { color: this.profileService.darkThemeSelected() ? 'rgba(153,153,153,1)' : 'rgba(92,100,110,0.6)' },
      },
      barmode: 'stack',
      plot_bgcolor: 'rgba(0, 0, 0, 0)',
      paper_bgcolor: 'rgba(0, 0, 0, 0)',
    };
    const options = { displayModeBar: false };
    try {
      Plotly.newPlot(this.submetricBarchart.nativeElement, barData, layout, options);
    } catch (err) {
      console.error(err);
    }
  }
}
