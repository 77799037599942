import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslationsService } from '../../../services/common/translations.service';
import {
  ChartMetricKeys,
  ComparisonChartPeriod,
  UnitComparisonChartStateData,
  UnitEnergyComparisonStore,
} from '@service/units/unit-energy-comparison.store';
import { Observable } from 'rxjs';

interface TimePeriod {
  name: string;
  value: string;
}

@Component({
  selector: 'app-comparison-chart-card',
  templateUrl: './comparison-chart-card.component.html',
  styleUrls: ['./comparison-chart-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonChartCardComponent {
  readonly availableChartMetrics = [
    {
      name: this.translationsService.instant('Svg.Solar'),
      value: ChartMetricKeys.SOLAR,
    },
    {
      value: ChartMetricKeys.GRID,
      name: this.translationsService.instant('Svg.Grid'),
    },
    {
      value: ChartMetricKeys.LOAD,
      name: this.translationsService.instant('SiteEnergy.Load'),
    },
    {
      value: ChartMetricKeys.STORAGE,
      name: this.translationsService.instant('Svg.Storage'),
    },
  ];

  readonly timePeriods: TimePeriod[] = [
    {
      name: this.translationsService.instant('SiteEnergy.Today'),
      value: ComparisonChartPeriod.TODAY,
    },
    {
      name: this.translationsService.instant('SiteEnergy.Last7Days'),
      value: ComparisonChartPeriod.LAST_7_DAYS,
    },
    {
      name: this.translationsService.instant('SiteEnergy.Last30Days'),
      value: ComparisonChartPeriod.LAST_30_DAYS,
    },
  ];

  unitEnergyComparisonStore$: Observable<UnitComparisonChartStateData>;

  constructor(
    private translationsService: TranslationsService,
    private _unitEnergyComparisonStore: UnitEnergyComparisonStore,
  ) {
    this.unitEnergyComparisonStore$ = this._unitEnergyComparisonStore.unitComparisonChartStore$;
  }

  periodChanged(period: ComparisonChartPeriod): void {
    if (period) {
      this._unitEnergyComparisonStore.comparisonChartPeriodChange(period);
    }
  }

  metricChanged(metrics: ChartMetricKeys[]): void {
    if (metrics) {
      this._unitEnergyComparisonStore.comparisonChartMetricChange(metrics);
    }
  }
}
