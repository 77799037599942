<ion-header>
  <ion-toolbar color="brand-primary">
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> {{ program.name }} </ion-title>
  </ion-toolbar>
  <ion-row class="bb-1 width-100-pct">
    <ion-col class="slide-nav-center ion-no-padding width-100-pct">
      <div id="slide-nav">
        <div class="linear-header fullHeight">
          <h4>{{currentSlide.heading | translate}}</h4>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-header>

<ion-content>
  <swiper-container #slides [allowTouchMove]="false">
    <swiper-slide>
      <!-- WELCOME -->
      <ion-content>
        <ion-grid class="gridSetting width-100-pct p-10 fullHeight">
          <ion-row class="ion-align-items-center fullHeight m-0 gridRow width-100-pct">
            <ion-row class="ion-text-start ion-align-self-end width-100-pct">
              <ion-col size="12" offset-md="2" size-md="8" class="width-100-pct">
                <h1 [ngClass]="{'CORDOVA_h1': IS_PLATFORM_CORDOVA}" class="ion-no-margin">
                  South Australia Smarter Homes Program Wizard
                </h1>
                <p class="fw-400">
                  By completing this wizard you are selecting SwitchDin as your technology provider for the Smarter
                  Homes Program. You will need to nominate an agent and provide your NMI. We will look at the devices
                  you have configured and create or update a controller for you as required.
                </p>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-self-end width-100-pct">
              <ion-col class="width-100-pct">
                <ion-button color="button-primary" expand="block" (click)="slideToAgentSlide()" size="large">
                  start
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-row>
        </ion-grid>
      </ion-content>
    </swiper-slide>
    <swiper-slide>
      <!-- AGENT & NMI -->
      <ion-content>
        <ion-grid class="gridSetting width-100-pct p-10 fullHeight">
          <ion-row class="ion-align-items-center fullHeight m-0 gridRow width-100-pct">
            <ion-row class="ion-text-start ion-align-self-end width-100-pct">
              <ion-col size="12" offset-md="2" size-md="8" class="width-100-pct">
                <ion-row class="ion-margin-bottom width-100-pct">
                  <ion-col size="12" class="width-100-pct">
                    <ion-text> Please nominate an agent and enter your NMI </ion-text>
                  </ion-col>

                  <ion-col class="ion-no-padding width-100-pct" size="12">
                    <ion-item class="md-like-swidin-input ion-margin">
                      <ion-select
                        [(ngModel)]="agentUUID"
                        required
                        name="program_agentUUID"
                        #program_agentUUID="ngModel"
                        [interfaceOptions]="programAgentsOptions"
                        labelPlacement="floating"
                      >
                        <div slot="label">Select Agent<ion-text color="danger">*</ion-text></div>
                        <ion-select-option *ngFor="let agent of program.agents" [value]="agent.uuid"
                          >{{ agent.name }}
                        </ion-select-option>
                      </ion-select>
                    </ion-item>
                    <ion-text
                      class="pl-15 fs-13"
                      *ngIf="program_agentUUID.invalid && (program_agentUUID.dirty || program_agentUUID.touched)"
                      color="danger"
                    >
                      Please select an agent
                    </ion-text>
                  </ion-col>
                  <ion-col class="ion-no-padding width-100-pct" size="12">
                    <ion-item class="md-like-swidin-input ion-margin">
                      <ion-input
                        type="text"
                        required
                        name="program_nmi"
                        [(ngModel)]="nmi"
                        ngControl="program_nmi"
                        #program_nmi="ngModel"
                        minlength="10"
                        label="NMI"
                        labelPlacement="floating"
                      ></ion-input>
                    </ion-item>
                    <ion-text
                      class="pl-15 fs-13"
                      *ngIf="program_nmi.invalid && (program_nmi.dirty || program_nmi.touched)"
                      color="danger"
                    >
                      Please enter a 10 or 11 character NMI
                    </ion-text>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
            <ion-row class="ion-align-self-end width-100-pct">
              <ion-col class="width-100-pct">
                <ion-button
                  color="button-primary"
                  expand="block"
                  (click)="submit()"
                  size="large"
                  [disabled]="!agentUUID || !nmi || nmi.length < 10"
                >
                  next
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-row>
        </ion-grid>
      </ion-content>
    </swiper-slide>
  </swiper-container>
</ion-content>
