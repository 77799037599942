<ion-grid
  class="ion-no-margin ion-no-padding"
  *ngIf="!(vm$ | async).loading && !(vm$ | async).error && (vm$ | async).data as vm"
>
  <ion-row>
    <ion-col class="p-8 fullHeight" size="12">
      <ion-card
        *ngFor="let charge of vm.charges; let last = last"
        class="m-0 new-design-card overflow-x-scroll"
        [ngClass]="{ 'mb-16': !last }"
      >
        <div class="fw-600 fs-22 mt-25 ml-24 text-dark">{{ charge.name }}</div>
        <div *ngIf="charge.description" class="pt-15 pl-24 pr-20 lh-20">{{ charge.description }}</div>

        <table class="width-100-pct mt-22 mb-14">
          <tr>
            <td>{{ 'Cost.Tariff.Description' | translate }}</td>
            <td>{{ 'Cost.Tariff.Amount' | translate }}</td>
            <td>{{ 'Cost.Tariff.Rate' | translate }}</td>
            <td>{{ 'Cost.Tariff.Cost' | translate }}</td>
          </tr>
          <tr *ngFor="let subcomponent of charge.subcomponents" class="content-row">
            <td class="description-cell fs-14 fw-600 text-dark">{{ subcomponent.name }}</td>
            <td class="amount-cell">
              {{ subcomponent.amount | number: '1.2-2' }}
              <span class="amount-unit">{{ subcomponent.amountUnit }}</span>
            </td>
            <td class="rate-cell">
              {{ subcomponent.rate | number: '1.2-4' }}
              <span class="rate-unit"> {{ subcomponent.rateUnit }}</span>
            </td>
            <td class="cost-cell">
              <span class="cost-unit">{{ subcomponent.costUnit }}</span>
              {{ subcomponent.cost | number: '1.2-2' }} {{ subcomponent.costSuffix }}
            </td>
          </tr>
        </table>

        <div *ngFor="let submetric of charge.submetrics" class="ml-20 mr-20 text-dark ion-padding-vertical">
          <div class="fw-600 fs-22">{{ submetric.title }}</div>
          <div class="ion-padding-vertical lh-20">{{ submetric.description }}</div>
          <app-unit-costs-subcost-barchart [metrics]="submetric"></app-unit-costs-subcost-barchart>
        </div>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
<ng-container *ngIf="(vm$ | async).loading">
  <app-skeleton-items [skeletonType]="SkeletonPlaceholder.TABLE_WITH_HEADER" [showCard]="true"></app-skeleton-items>
</ng-container>
