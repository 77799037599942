import { Component } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { VppSettingGroupsTabControlGroup } from '../../classes/vpp/vpp-control-group.model';
import { TranslationsService } from '../../services/common/translations.service';
import { VppSettingsGroupFacadeService } from '../../services/virtualPowerPlants/facades/setting/group/vpp-settings-group-facade.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { BrowserLogger } from '@class/core/browser-logger';
import { CreateControlGroupModalPage } from 'app/pages/modals/create-control-group-modal/create-control-group-modal.page';

@Component({
  selector: 'app-vpp-setting-control-group-list',
  templateUrl: './vpp-setting-control-group-list.component.html',
  styleUrls: ['./vpp-setting-control-group-list.component.scss'],
})
export class VppSettingControlGroupListComponent {
  controlGroup$ = this.vppSettingGroupFacade.controlGroup$;
  readonly PermissionKey = PermissionKey;

  constructor(
    private vppSettingGroupFacade: VppSettingsGroupFacadeService,
    private alertController: AlertController,
    private trans: TranslationsService,
    private modal: ModalController,
  ) {
    BrowserLogger.log('VppSettingControlGroupList.constructor');
  }

  public toggleControlGroup(controlGroup: VppSettingGroupsTabControlGroup): void {
    BrowserLogger.log('VppSettingControlGroupList.toggleControlGroup', { controlGroup });
    this.vppSettingGroupFacade.toggleControlGroupUnitDisplay(controlGroup.name);
  }

  public async editButtonHandler(controlGroup: VppSettingGroupsTabControlGroup): Promise<void> {
    BrowserLogger.log('VppSettingControlGroupList.editButtonHandler', { controlGroup });
    await this.vppSettingGroupFacade.presentControlGroupEditModal(controlGroup);
  }

  public async presentDeleteConfirmAlert(controlGroup: VppSettingGroupsTabControlGroup): Promise<void> {
    BrowserLogger.log('VppSettingControlGroupList.presentDeleteConfirmAlert', { controlGroup });
    const alert = await this.alertController.create({
      header: this.trans.instant('VirtualPowerPlant.ConfirmDeleteControlGroup'),
      buttons: [
        {
          text: this.trans.instant('General.Cancel'),
          role: 'cancel',
        },
        {
          text: 'Ok',
          handler: () => {
            this.vppSettingGroupFacade.removeControlGroup(controlGroup);
          },
        },
      ],
    });
    await alert.present();
  }
  async presentCreateControlGroupModal(): Promise<void> {
    const createModal = await this.modal.create({
      component: CreateControlGroupModalPage,
      cssClass: 'transparent-modal',
      backdropDismiss: false,
      animated: true,
    });

    await createModal.present();
  }
}
