<div *ngIf="vm$ | async as vm">
  <span class="fs-14 text-dark">{{ 'General.Status' | translate }}: </span>
  <span [ngSwitch]="vm.status">
    <span *ngSwitchCase="StatusFromMqtt.UNKNOWN" class="text-dark status-span fs-14 fw-600 ml-5"
      >{{ 'General.Unknown' | translate }}
      <div class="unknown-circle ml-5"></div>
    </span>
    <span *ngSwitchCase="StatusFromMqtt.ONLINE" class="text-dark status-span fs-14 fw-600 ml-5"
      >{{ 'General.Online' | translate }}
      <div
        class="ml-5"
        [ngClass]="{ 'online-pulse-circle': vm.heartbeat, 'online-pulse-circle2': !vm.heartbeat }"
      ></div>
    </span>
    <span *ngSwitchCase="StatusFromMqtt.OFFLINE" class="text-dark status-span fs-14 fw-600 ml-5"
      >{{ 'General.Offline' | translate }}
      <div class="offline-circle ml-5"></div>
    </span>
  </span>
  <div class="fs-11 text-medium">
    <div *ngIf="vm.lastSeenAgo">{{ 'General.Updated' | translate }} {{ vm.lastSeenAgo }}</div>
  </div>
</div>
