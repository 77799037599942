import { Unit } from './../../classes/commons/unit.model';
import { Portfolio, PortfolioDTO } from './../../classes/commons/portfolio.model';
import { CommonService } from './../common/common.service';
import { Injectable } from '@angular/core';
import { ApiWrapper } from '../common/api-wrapper.service';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../classes/commons/request-api.model';
import { isEmpty } from 'lodash';
import { PermissionArea } from '@class/commons/permissions/permission-constants';
import { PermissionsService } from '@service/permissions/permissions.service';
import { BrowserLogger } from '@class/core/browser-logger';
import { environment } from 'environments/environment';
import { CapacitorHttp } from '@capacitor/core';

const NATIVE_X_FORM_HEADERS = { 'Content-Type': 'application/json' };
export interface SwitchdinDevice {
  endpoint_id: string;
  device_type_id: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class InstallerService {
  signInButtonPressed = false;
  selectedPortfolio = {
    portfolio: {} as Portfolio,
    units: [] as Unit[],
    apiCall: false, //if the api call has been made or not
    dataExist: false, //data is null or undefined
    apiCallFailed: false, //if by any chance the api call didn't go through, internet is down or server error
    error: {}, //if any error occurred,
  };
  selectedUnit = {};
  constructor(
    private api: ApiWrapper,
    private commonService: CommonService,
    private permissionsService: PermissionsService,
  ) {}

  //FUNCTIONS
  async setNewDeviceConfiguration(config) {
    return new Promise((resolve) => {
      resolve(config);
    });
  }
  static removeDuplicates(networkList, property) {
    const arrResult = {};
    let n;
    let i;
    for (i = 0, n = networkList.length; i < n; i++) {
      const item = networkList[i];
      arrResult[item[property]] = item;
    }
    i = 0;
    const nonDuplicatedArray = [];
    for (const item in arrResult) {
      nonDuplicatedArray[i++] = arrResult[item];
    }
    return nonDuplicatedArray;
  }
  // TODO: This should be typed
  async loadSelectedPortfolioAndApplyPerms(portfolio, event = null): Promise<void> {
    BrowserLogger.log('InstallerService.loadSelectedPortfolioAndApplyPerms', { portfolio, event });
    await this.getSelectedPortfolioData(portfolio);
    this.permissionsService.apply(PermissionArea.PORTFOLIO, this.selectedPortfolio.portfolio?.permissions);
  }

  /**
   * TODO:
   * This getSelectedPortfolioData is redundant/duplicated
   * we should be using the one in portfolio service
   * but the portfolio service function is doing more than this
   * so might need to change that to accommodate this
   * And this should be type
   * @param portfolio
   * @param event
   */
  private async getSelectedPortfolioData(portfolio, event = null): Promise<void> {
    BrowserLogger.log('InstallerService.getSelectedPortfolioData', { portfolio, event });
    this.selectedPortfolio.apiCall = false;
    this.selectedPortfolio.dataExist = false;
    this.selectedPortfolio.apiCallFailed = false;
    this.selectedPortfolio.units = [];

    try {
      const response: { data: PortfolioDTO } = (await this.commonService.getPortfolioList(portfolio.id, false)) as {
        data: PortfolioDTO;
      };

      if (event) {
        event.target.complete();
      }

      this.selectedPortfolio.apiCall = true;
      if (!isEmpty(response.data)) {
        this.selectedPortfolio.portfolio = PortfolioDTO.adaptToPortfolio(response.data);
        if (response.data.unit_set && response.data.unit_set.length > 0) {
          this.selectedPortfolio.units = this.selectedPortfolio.portfolio.unitSet;
        } else {
          this.selectedPortfolio.units = [];
        }
        this.selectedPortfolio.dataExist = true;
      } else {
        this.selectedPortfolio.dataExist = false;
        this.selectedPortfolio.units = [];
      }
    } catch (error) {
      this.selectedPortfolio.apiCallFailed = true;
      this.selectedPortfolio.units = [];
    }
  }

  // API CALLS
  async browseGoogle() {
    BrowserLogger.log('InstallerService.browseGoogle');
    return CapacitorHttp.get({ url: 'https://google.com' });
  }

  // Using native http here
  // need to use angular http
  // enabling angular http will cause CORS issues
  // as web server running on the droplet is old, serving over http
  // and doesn't have any headers specified
  // so using native http for the time being
  async getDropletSSID() {
    return CapacitorHttp.get({ url: `${environment.dropletApiUrl}/get_ssid`, headers: NATIVE_X_FORM_HEADERS });
  }
  async getDropletWifiAvailable() {
    BrowserLogger.log('InstallerService.getDropletWifiAvailable');
    return CapacitorHttp.get({ url: `${environment.dropletApiUrl}/rescan_wifi`, headers: NATIVE_X_FORM_HEADERS });
  }
  async setDropletWifi(targetSSID: string, targetPassword: string) {
    BrowserLogger.log('InstallerService.setDropletWifi', { targetSSID, targetPassword });
    return CapacitorHttp.post({
      url: `${environment.dropletApiUrl}/enable_wifi`,
      data: { wifi_ssid: targetSSID, wifi_passcode: targetPassword },
      headers: NATIVE_X_FORM_HEADERS,
    });
  }

  async hasEndpoint(mac) {
    BrowserLogger.log('InstallerService.hasEndpoint', { mac });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/for-endpoint/00000000-0000-0000-0000-' + mac + '/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  async hasClaimed(mac) {
    BrowserLogger.log('InstallerService.hasClaimed', { mac });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/claimed/00000000-0000-0000-0000-' + mac + '/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  async getPortfolioList(id = '', simple = true) {
    BrowserLogger.log('InstallerService.getPortfolioList', { id, simple });
    let requestURL = '';
    if (!simple && id == '') {
      requestURL = '/api/v1/portfolios/';
    } else {
      requestURL = simple ? '/api/v1/portfolios/' + id + '?simple=true/' : '/api/v1/portfolios/' + id + '/';
    }

    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      requestURL,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }
  async getEndpoints(id) {
    BrowserLogger.log('InstallerService.getEndpoints', { id });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/' + id + '/endpoints/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  async getUnit(portfolio) {
    BrowserLogger.log('InstallerService.getUnit', { portfolio });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/portfolios/' + portfolio + '/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  async getUnits() {
    BrowserLogger.log('InstallerService.getUnits');
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  async getUnitById(id) {
    BrowserLogger.log('InstallerService.getUnitById', { id });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/' + id + '/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  async setAssociatedUnit(unitID, mac) {
    BrowserLogger.log('InstallerService.setAssociatedUnit', { unitID, mac });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/' + unitID + '/claim_endpoint/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      { uuid: '00000000-0000-0000-0000-' + mac },
    );
  }

  // this function will remove from this file  once the portfolio setting page redesign work finish
  async createPortfolio(portfolio_name, location) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/portfolios/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      { name: portfolio_name, location_description: location },
    );
  }

  //TODO: Check to see if this works
  async getDeviceTypes(configurableOnly = false) {
    BrowserLogger.log('InstallerService.getDeviceTypes', { configurableOnly });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      configurableOnly ? '/api/v1/device-types/?configurable=1' : '/api/v1/device-types/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  async createUnit(address, google_places_result, name, portfolioUUID: string, tags, nmi = '') {
    BrowserLogger.log('InstallerService.createUnit', { address, google_places_result, name, portfolioUUID, tags });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      JSON.stringify({
        name: name,
        portfolio: portfolioUUID,
        address: address.address,
        latitude: address.latitude,
        longitude: address.longitude,
        city: address.city,
        state: address.state,
        country: address.country,
        postcode: address.postcode,
        google_places_result: google_places_result,
        tags: tags,
        nmi: nmi,
      }),
    );
  }

  async getConnections() {
    BrowserLogger.log('InstallerService.getConnections');
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/connection-types/?',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  async getConnectionAttributes(connection = '') {
    BrowserLogger.log('InstallerService.getConnectionAttributes', { connection });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      connection !== ''
        ? '/api/v1/connection-attributes/for-connection/' + connection + '/'
        : '/api/v1/connection-attributes/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  async createDevice(device: SwitchdinDevice) {
    BrowserLogger.log('InstallerService.createDevice', { device });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v2/devices/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      JSON.stringify(device),
    );
  }

  async saveConnection(deviceConnection) {
    BrowserLogger.log('InstallerService.saveConnection', { deviceConnection });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/device-configuration/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      JSON.stringify(deviceConnection),
    );
  }

  async getConnectionDetails(device_id) {
    BrowserLogger.log('InstallerService.getConnectionDetails', { device_id });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/device-configuration/for-device/' + device_id + '/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  static mqttDecodeToString(message) {
    return String.fromCharCode.apply(null, message.payload);
  }

  static formatMAC(e) {
    const r = /([a-f0-9]{2})([a-f0-9]{2})/i;
    let str = e.replace(/[^a-f0-9]/gi, '');
    while (r.test(str)) {
      str = str.replace(r, '$1' + ':' + '$2');
    }
    return str.slice(0, 17);
  }

  registerCloudDevice(deviceRegisterPayload): Promise<unknown> {
    BrowserLogger.log('InstallerService.registerCloudDevice', { deviceRegisterPayload });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v2/devices/register_cloud_asset/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      deviceRegisterPayload,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validateCloudSerialNo(serialNoPayload): Promise<any> {
    BrowserLogger.log('InstallerService.validateCloudSerialNo', { serialNoPayload });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v2/devices/validate_serial_number/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      serialNoPayload,
    );
  }
}
