import { Injectable } from '@angular/core';
import { States } from '@class/commons/constants';
import { generateHttpErrorMessage, HTTP_ERRORS_TRANSLATION_HEADING } from '@class/error/http-error-types';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '@service/common/api-wrapper.service';
import { TranslationsService } from '@service/common/translations.service';
import {
  BehaviorSubject,
  Observable,
  shareReplay,
  map,
  catchError,
  startWith,
  tap,
  of,
  filter,
  switchMap,
  distinctUntilKeyChanged,
} from 'rxjs';
import { UnitStore } from './unit.store';

interface UnitUser {
  uuid: string;
  user_id: string;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  is_self: false;
  role_name: string;
  priority: number;
  level: number;
}
export interface UnitUserListState {
  data: UnitUser[];
  priority: {
    currentUserLevel: number;
    currentUserPriority: number;
  };
  status: States;
  error?: string;
  message?: string;
}
const Unit_USER_LIST_INITIAL_STATE: UnitUserListState = {
  status: States.INITIAL_LOADING,
  data: null,
  priority: {
    currentUserLevel: 0,
    currentUserPriority: 0,
  },
};

/**
 * sample data
  {
    "value": "9b956cc4-8931-11eb-b365-0242ac110003",
    "level": 1,
    "name": "Unit Manager (Basic)",
    "priority": 0
  }
 */
export interface UnitInvitees {
  value: string;
  level: number;
  name: string;
  priority: number;
}
interface UnitInviteesState {
  data: UnitInvitees[];
  status: States;
  error?: string;
  message?: string;
}
const Unit_INVITEES_INITIAL_STATE: UnitInviteesState = {
  status: States.INITIAL_LOADING,
  data: null,
};

interface InviteUser {
  name: string;
  email: string;
  referer_browser_skin: string;
  role: string;
}

@Injectable({
  providedIn: 'root',
})
export class UnitUserStore {
  private _unitUserListStore = new BehaviorSubject<UnitUserListState>(Unit_USER_LIST_INITIAL_STATE);
  unitUserList$ = this._unitUserListStore.asObservable();

  private _unitInviteesStore = new BehaviorSubject<UnitInviteesState>(Unit_INVITEES_INITIAL_STATE);
  unitInvitees$ = this._unitInviteesStore.asObservable();

  constructor(
    private _api: ApiWrapper,
    private _translationsService: TranslationsService,
    private _unitStore: UnitStore,
  ) {
    this.getUnitUsersById();
    this.getUnitInviteesById();
  }

  getUnitUsersById(): void {
    this._unitStore.unit$
      .pipe(
        filter((unitData) => unitData.data !== null),
        distinctUntilKeyChanged('data', (prev, curr) => prev.uuid === curr.uuid),
        map((unitData) => unitData.data),
        switchMap((unit) => {
          return this._api.handleObservableRequest({
            useAPI: AvailableAPI.SWITCHDIN,
            url: `/api/v1/units/${unit.id}/users/`,
            requestMethod: RequestMethod.GET,
            useHeader: UseHeaderType.AUTHORIZED_SWDIN,
            requestData: {},
          }) as Observable<UnitUser[]>;
        }),
      )
      .pipe(
        shareReplay(),
        map((users) => {
          // have to determine what kind of user level/priority it had
          // our system is so good that it couldn't send this in a separate api or something
          // so has to iterate over users list
          // and have to look for user_self
          // and cherry on top, there can be more than one entries of the self user in user list
          // so have pick the highest level of priority and level
          // have to find this in order to restrict the user to not to add the user with higher perms than them
          // and can not remove user that have higher perms than them
          let priority = 0,
            level = 0;
          users.forEach((user) => {
            if (user.is_self) {
              priority = user.priority > priority ? user.priority : priority;
              level = user.level > level ? user.level : level;
            }
          });

          // the max unit level priority is 2
          // if we try to remove a portfolio user from unit user list
          // it throws error 404 as that membership doesn't exist in unit memberships
          // but it's hard to determine where to send the request to
          // unit membership or portfolio membership
          // so for the time being
          // filtering the users that are portfolio users i.e., priority > 2
          //
          // tried this but this doesn't work so leaving it like this
          // const filteredUsers = users.filter((user) => user.priority <= 2);

          return {
            data: users,
            priority: {
              currentUserLevel: level,
              currentUserPriority: priority,
            },
            status: States.INITIAL_DATA_RECEIVED,
          };
        }),
        catchError((error) => this.handleError(error)),
        startWith(Unit_USER_LIST_INITIAL_STATE),
        tap((usersData) => {
          this._unitUserListStore.next(usersData);
        }),
      )
      .subscribe();
  }
  getUnitInviteesById(): void {
    this._unitStore.unit$
      .pipe(
        filter((unitData) => unitData.data !== null),
        distinctUntilKeyChanged('data', (prev, curr) => prev.uuid === curr.uuid),
        map((unitData) => unitData.data),
        switchMap((unit) => {
          return this._api.handleObservableRequest({
            useAPI: AvailableAPI.SWITCHDIN,
            url: `/api/v1/units/${unit.id}/invitees/`,
            requestMethod: RequestMethod.GET,
            useHeader: UseHeaderType.AUTHORIZED_SWDIN,
            requestData: {},
          }) as Observable<UnitInvitees[]>;
        }),
      )
      .pipe(
        shareReplay(),
        map((invitees) => ({
          data: invitees,
          status: States.INITIAL_DATA_RECEIVED,
        })),
        // catchError((error) => this.handleError(error)),
        startWith(Unit_INVITEES_INITIAL_STATE),
        tap((inviteesData) => {
          this._unitInviteesStore.next(inviteesData);
        }),
      )
      .subscribe();
  }
  updateUserListAfterInvitingUser(users: UnitUser[]) {
    if (users) {
      this._unitUserListStore.next({
        ...this._unitUserListStore.value,
        data: [...users],
      });
    }
  }
  updateUserListAfterRemovingUser(userUuidToBeRemoved: string) {
    const users = this._unitUserListStore.value.data.filter((user) => user.uuid !== userUuidToBeRemoved);

    this._unitUserListStore.next({
      ...this._unitUserListStore.value,
      data: [...users],
    });
  }
  // not using this as didn't wanna get sidetracked with invite users
  inviteUser(UnitId: string, user: InviteUser) {
    return this._api.handleObservableRequest({
      useAPI: AvailableAPI.SWITCHDIN,
      url: `/api/v1/units/${UnitId}/invite_user/`,
      requestMethod: RequestMethod.POST,
      useHeader: UseHeaderType.AUTHORIZED_SWDIN,
      requestData: user,
    });
  }

  // Private Methods
  private handleError(err, state: States = States.INITIAL_ERROR): Observable<UnitUserListState> {
    const message = generateHttpErrorMessage(err, this._translationsService.instant(HTTP_ERRORS_TRANSLATION_HEADING));
    return of({
      status: state,
      priority: {
        currentUserLevel: 0,
        currentUserPriority: 0,
      },
      error: message.message,
      data: null,
    });
  }
}
