import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { BrowserLogger } from '@class/core/browser-logger';

export enum DeviceDetailMessageState {
  IDLE,
  LOADING,
  SUCCESS,
  ERROR,
}
@Component({
  selector: 'app-device-detail-message',
  templateUrl: './device-detail-message.component.html',
  styleUrls: ['./device-detail-message.component.scss'],
})
export class DeviceDetailMessageComponent implements OnChanges {
  @Input() title: string;
  @Input() subtitles: string[];
  @Input() state: DeviceDetailMessageState;
  @Input() attempts: number = null;

  readonly DeviceDetailMessageState = DeviceDetailMessageState;

  constructor() {
    BrowserLogger.log('DeviceDetailMessage.constructor');
  }

  ngOnChanges(changes: SimpleChanges): void {
    BrowserLogger.log('DeviceDetailMessage.ngOnChanges', { changes });
  }
}
