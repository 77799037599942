<!--  REQUEST ERROR TEMPLATES -->
<!--
     At two places we can show the error msg template
     1- Between the header and the content
     2- Before the header
     To show the request error between header and content please add .card and .content css class into the template
     Otherwise, it will render on the top of the header
     Do not need to add any classes if you want to show on the top of the header
 -->
<ng-template #displayRequestError>
  <ion-item lines="none" class="ion-margin" color="danger-lite">
    <ion-icon slot="start" [src]="FAILED_MESSAGE_URL"></ion-icon>
    <ion-text>{{ errorMsg }} </ion-text>
    <ion-button slot="end" fill="none" (click)="handleFormErrorClose()">
      <ion-icon slot="icon-only" name="close-outline"></ion-icon>
    </ion-button>
  </ion-item>
</ng-template>

<ng-template #initial let-showError="showError">
  <ng-content select=".card"></ng-content>
  <ng-container *ngIf="showError">
    <ng-container *ngTemplateOutlet="displayRequestError"></ng-container>
  </ng-container>
  <ng-content select=".content"></ng-content>
  <ng-content></ng-content>

  <!-- show no data if there is no data found -->
  <ion-row *ngIf="showNoData">
    <ion-col size="12">
      <ion-item class="bkg-transparent" lines="none">
        <ion-label>
          {{ showNoDataMsg }}
        </ion-label>
      </ion-item>
    </ion-col>
  </ion-row>
</ng-template>

<ng-container [ngSwitch]="stateType">
  <!-- INITIAL LOADING WRAPPER  -->
  <ng-container *ngSwitchCase="States.INITIAL_LOADING">
    <!--  SKELETON CARD  -->
    <app-skeleton-items [skeletonType]="SkeletonPlaceholder.CARD"></app-skeleton-items>
  </ng-container>

  <!-- INITIAL DATA RECEIVED WRAPPER  -->
  <ng-container *ngSwitchCase="States.INITIAL_DATA_RECEIVED">
    <ng-container *ngTemplateOutlet="initial; context: errorFalse"></ng-container>
  </ng-container>

  <!--  INITIAL ERROR WRAPPER -->
  <ng-container *ngSwitchCase="States.INITIAL_ERROR">
    <div class="ion-text-center p-20 m-30">
      <ion-icon class="icon-size" [src]="FAILED_MESSAGE_URL"></ion-icon>
      <div class="fs-20 text-medium mt-10">{{ errorMsg ?? 'General.Error.ServerUnavailable' | translate }}</div>
      <ion-button color="button-primary" (click)="handleRetryClick()"> {{ 'General.Retry' | translate }} </ion-button>
    </div>
  </ng-container>

  <!-- REQUEST IN PROCESS WRAPPER -->
  <ng-container *ngSwitchCase="States.REQUEST_IN_PROCESS">
    <div class="ion-text-center p-20 m-30">
      <ion-spinner class="icon-size" name="circular" duration="1000" color="brand-primary"></ion-spinner>
      <div class="fs-20 text-medium mt-10">{{ 'General.Wait' | translate }}</div>
    </div>
  </ng-container>

  <!-- REQUEST SUCCESS WRAPPER -->
  <ng-container *ngSwitchCase="States.REQUEST_SUCCESS">
    <div class="ion-text-center p-20 m-30">
      <ion-icon class="icon-size text-brand-primary" name="checkmark-circle"></ion-icon>
      <div class="fs-20 text-medium mt-10">{{ successMsg }}</div>
    </div>
  </ng-container>

  <!-- REQUEST ERROR WRAPPER -->
  <ng-container *ngSwitchCase="States.REQUEST_ERROR">
    <ng-container *ngTemplateOutlet="initial; context: errorTrue"></ng-container>
  </ng-container>
</ng-container>
