import { EventEmitter } from '@angular/core';
import { Component, Input, Output } from '@angular/core';
@Component({
  selector: 'app-resync-device-message-button',
  templateUrl: './resync-device-message-button.component.html',
  styleUrls: ['./resync-device-message-button.component.scss'],
})
export class ResyncDeviceMessageButtonComponent {
  @Input() displayLabel: string;
  @Input() buttonLabel: string;
  @Output() resync = new EventEmitter<null>();

  resyncValue(): void {
    this.resync.emit();
  }
}
