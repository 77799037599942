import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
})
export class FilterSelectComponent {
  @Input() header: string;
  @Input() subheader: string;
  @Input() set data(data: string[]) {
    this.inputList = data;
    this.optionList = data;
  }
  @Input() initialData: string = null;
  private inputList: string[];
  public optionList: string[];

  constructor(private modalController: ModalController) {}

  public searchbarInputHandler(value: string): void {
    const searchTerm = value?.trim().toLowerCase();
    if (searchTerm)
      this.optionList = this.inputList.filter((option) => option.trim().toLowerCase().includes(searchTerm));
    else this.optionList = this.inputList;
  }

  public dismiss(data?: string): void {
    this.modalController.dismiss(data);
  }
}
