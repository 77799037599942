import { BrowserLogger } from '@class/core/browser-logger';

type Details = {
  description: string;
  title: string;
  chart_data: string;
};

type Lines = {
  amount: string;
  amount_unit: string;
  cost: string;
  cost_units: string;
  description: string;
  rate: number;
  rate_units: string;
};
interface CostPayloadItem {
  detail_sections: Details[];
  line_items: Lines[];
  description: string;
  name: string;
  short_name: string;
  total_charge: number;
  total_demand_charge: number;
  total_loc_gen: number;
  total_supply_charge: number;
  total_usage_charge: number;
}

interface CostSummaryItem {
  demand: number;
  demandSuffix: string;
  usage: number;
  usageSuffix: string;
  service: number;
  serviceSuffix: string;
  total: number;
  totalSuffix: string;
}

export interface CostMetricItems {
  title: string;
  description: string;
  values: { x: string; y: number }[];
}

interface CostSubcomponent {
  name: string;
  amount: number;
  amountUnit: string;
  rate: number;
  rateUnit: string;
  cost: number;
  costUnit: string;
  costSuffix: string;
}

interface CostComponents {
  name: string;
  description: string;
  summary: CostSummaryItem;
  subcomponents: CostSubcomponent[];
  submetrics: CostMetricItems[];
}

export enum DataStructureEnum {
  GRANTS_PLACE = 1,
  COWBOYS = 2,
  UNKNOWN = 3,
}

export class Cost {
  name: string;
  aggregate: CostSummaryItem;
  charges: CostComponents[];
  savings: { description: string; value: number };
  metrics: CostMetricItems[];
  payloadType: DataStructureEnum;

  private _hasData: boolean;
  get hasData() {
    return this._hasData;
  }

  constructor(item: any) {
    BrowserLogger.log('Cost.constructor', { item });
    this._hasData = false;
    this.adapt(item);
  }

  private adapt(item: any): void {
    switch (Cost.whichDataStructure(item)) {
      case DataStructureEnum.COWBOYS:
        this.adaptFromCowboys(item);
        break;
      case DataStructureEnum.GRANTS_PLACE:
        this.adaptFromGrantsPlace(item);
        break;
    }
    BrowserLogger.log('Cost.adapt', { self: this });
  }

  private static whichDataStructure(data: any): DataStructureEnum {
    if (!data || !data[0].name || !data[0].line_items || !data[0].cost_components) {
      return DataStructureEnum.UNKNOWN;
    }

    let dataType = DataStructureEnum.UNKNOWN;
    if (data.length > 1) {
      dataType = DataStructureEnum.COWBOYS;
    } else if (data.length == 1) {
      dataType = DataStructureEnum.GRANTS_PLACE;
    } else {
      dataType = DataStructureEnum.UNKNOWN;
    }
    return dataType;
  }

  private adaptFromGrantsPlace(item: CostPayloadItem): void {
    const data = item[0];

    const aggregate: CostSummaryItem = {
      demand: Math.abs(data.total_demand_charge),
      usage: Math.abs(data.total_usage_charge),
      service: Math.abs(data.total_supply_charge),
      total: Math.abs(data.total_charge),
      demandSuffix: Cost.assignCreditSuffix(data.total_demand_charge),
      usageSuffix: Cost.assignCreditSuffix(data.total_usage_charge),
      serviceSuffix: Cost.assignCreditSuffix(data.total_supply_charge),
      totalSuffix: Cost.assignCreditSuffix(data.total_charge),
    };

    let charges: CostComponents[];
    charges = data.line_items.map((line_item: Lines) => {
      const _subcomponent: CostSubcomponent = Cost.adaptToCostSubcomponents(data, line_item);

      const component: CostComponents = {
        name: line_item.description,
        description: '', // currently unavailable thru tariffcalc api
        summary: null,
        subcomponents: [_subcomponent],
        submetrics: null,
      };
      return component;
    });

    this.name = data.short_name;
    this.aggregate = aggregate;
    this.charges = charges;
    this.savings = null;
    this.metrics = Cost.adaptToCostMetrics(data);
    this.payloadType = DataStructureEnum.GRANTS_PLACE;
    this._hasData = true;

    BrowserLogger.log('Cost.adaptFromGrantsPlace', { self: this, item });
  }

  private static reduce_summer = (accumulator: number, currentValue: number) => accumulator + currentValue;

  private adaptFromCowboys(item: CostPayloadItem[]): void {
    const demandAggregate: number = item
      .map((charges: { total_demand_charge: number }) => charges.total_demand_charge)
      .reduce(Cost.reduce_summer);
    const usageAggregate: number = item
      .map((charges: { total_usage_charge: number }) => charges.total_usage_charge)
      .reduce(Cost.reduce_summer);
    const serviceAggregate: number = item
      .map((charges: { total_supply_charge: number }) => charges.total_supply_charge)
      .reduce(Cost.reduce_summer);
    const totalChargeAggregate: number = item
      .map((charges: { total_charge: number }) => charges.total_charge)
      .reduce(Cost.reduce_summer);

    const aggregate: CostSummaryItem = {
      demand: Math.abs(demandAggregate),
      usage: Math.abs(usageAggregate),
      service: Math.abs(serviceAggregate),
      total: Math.abs(totalChargeAggregate),
      demandSuffix: Cost.assignCreditSuffix(demandAggregate),
      usageSuffix: Cost.assignCreditSuffix(usageAggregate),
      serviceSuffix: Cost.assignCreditSuffix(serviceAggregate),
      totalSuffix: Cost.assignCreditSuffix(totalChargeAggregate),
    };

    let charges: CostComponents[] = item
      .filter((data) => data.short_name !== 'Savings')
      .map((data) => {
        let _subcomponent: CostSubcomponent[] = data.line_items.map((line_item: Lines) => {
          return Cost.adaptToCostSubcomponents(data, line_item);
        });

        const _metrics: CostMetricItems[] = Cost.adaptToCostMetrics(data);

        const { short_name, description, total_demand_charge, total_charge, total_supply_charge, total_usage_charge } =
          data;
        const component: CostComponents = {
          name: short_name,
          description: description, // currently unavailable thru tariffcalc api
          summary: {
            demand: Math.abs(total_demand_charge),
            usage: Math.abs(total_usage_charge),
            service: Math.abs(total_supply_charge),
            total: Math.abs(total_charge),
            demandSuffix: Cost.assignCreditSuffix(total_demand_charge),
            usageSuffix: Cost.assignCreditSuffix(total_usage_charge),
            serviceSuffix: Cost.assignCreditSuffix(total_supply_charge),
            totalSuffix: Cost.assignCreditSuffix(total_charge),
          },
          subcomponents: _subcomponent,
          submetrics: _metrics,
        };
        return component;
      });

    const savingsArray = item.filter((data) => data.short_name === 'Savings');
    let savings = null;
    if (savingsArray && savingsArray.length === 1) {
      savings = { description: savingsArray[0].description, value: savingsArray[0].total_loc_gen };
    }

    this.name = '';
    this.aggregate = aggregate;
    this.charges = charges;
    this.savings = savings;
    this.metrics = null;
    this.payloadType = DataStructureEnum.COWBOYS;
    this._hasData = true;

    BrowserLogger.log('Cost.adaptFromCowboys', { self: this, item });
  }

  private static adaptToCostMetrics(data: CostPayloadItem): CostMetricItems[] {
    const metrics: CostMetricItems[] = data.detail_sections.map((section: Details): CostMetricItems => {
      const metric: CostMetricItems = {
        title: section.title,
        description: section.description,
        values: null,
      };
      if (data[section.chart_data] && data[section.chart_data].length === 1) {
        metric.values = data[section.chart_data][0].values;
      }
      return metric;
    });

    return metrics;
  }

  private static adaptToCostSubcomponents(data: CostPayloadItem, line_item: Lines): CostSubcomponent {
    const { amount, amount_unit, rate, rate_units, cost, cost_units, description } = line_item;
    const { formattedAmount, formattedUnit } = Cost.formatRate(rate, rate_units);
    return {
      amount: data[amount] ? data[amount] : 0,
      amountUnit: amount_unit ? amount_unit : undefined,
      rate: formattedAmount,
      rateUnit: formattedUnit,
      cost: data[cost] ? Math.abs(data[cost]) : 0,
      costUnit: cost_units ? cost_units : undefined,
      costSuffix: Cost.assignCreditSuffix(data[cost]),
      name: description,
    };
  }

  private static formatRate(amount: number, unit: string): { formattedAmount: number; formattedUnit: string } {
    if (amount == null || unit == null) {
      return { formattedAmount: 0, formattedUnit: undefined };
    }

    const amountLn = Math.log10(amount);
    let newUnit = unit;
    let newAmount = amount;
    if (amountLn < -2 && unit.indexOf('$/') > -1) {
      newUnit = unit.replace('$/', '¢/');
      newAmount = newAmount * 100;
    }

    return { formattedAmount: newAmount, formattedUnit: newUnit };
  }

  private static assignCreditSuffix(quantity: number): string {
    return quantity < 0 ? 'CR' : '';
  }
}
