import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UnitOperationalStates } from '@class/commons/enums';
import { SUB_TABS, SubTab, SubTabEnums } from '@class/core/sub-tabs';
import { AnalyticsService } from '@service/common/analytics.service';

@Component({
  selector: 'app-sub-tabs',
  templateUrl: './sub-tabs.component.html',
  styleUrls: ['./sub-tabs.component.scss'],
})
export class SubTabsComponent {
  @Input() set tabName(value: SubTabEnums) {
    this.tabs = SUB_TABS[value];
    this.defaultTabIndex = this.tabs[0].index;
    this.captureSegmentChange(this.tabs[0]);
  }
  @Input() operationalState: UnitOperationalStates;
  tabs: SubTab[];
  defaultTabIndex: number;

  @Output() currentTabIndex = new EventEmitter<number>();

  constructor(private analyticsService: AnalyticsService) {}

  captureSegmentChange(value: SubTab) {
    this.emitTabIndex(value.index);
    this.logTabChange(value.loggingUrl);
  }
  isTypeOfFunction = (func) => typeof func === 'function';
  private emitTabIndex(index: number): void {
    this.currentTabIndex.emit(index);
  }
  private logTabChange(url: string) {
    this.analyticsService.logScreenView(url);
  }
}
