import { Component, OnInit, Input } from '@angular/core';
import { AlertDefinition, AlertTypes, CategoryNameValues } from '../alert-definitions-list/alert-definition.model';
import { ModalController } from '@ionic/angular';
import { AddEditAlertDefinitionModalPage } from '../../../modals/add-edit-alert-definition-modal/add-edit-alert-definition-modal.page';
import { AlertsService } from '../../../../services/alerts/alerts.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';

@Component({
  selector: 'app-alert-definition',
  templateUrl: './alert-definition.component.html',
  styleUrls: ['./alert-definition.component.scss'],
})
export class AlertDefinitionComponent implements OnInit {
  @Input() alertDefinition: AlertDefinition;
  @Input() isUnitActive: boolean;

  alertIcon: string;
  alertCategoryString: string;

  readonly PermissionKey = PermissionKey;

  constructor(
    private modalController: ModalController,
    private alertsService: AlertsService,
  ) {}

  ngOnInit() {
    this.getIcon();
    this.getAlertCategoryString();
  }

  edit(): void {
    this.openEditDefinitionModal();
  }

  getIcon() {
    AlertTypes.forEach((el) => {
      if (this.alertDefinition.criticality === el.name) {
        this.alertIcon = el.icon;
      }
    });
  }

  getAlertCategoryString() {
    const foundCategory = CategoryNameValues.find((el) => el.value === this.alertDefinition.category);
    this.alertCategoryString = foundCategory ? foundCategory.translationString : '';
  }

  delete(): void {
    this.alertsService.deleteDefinition(this.alertDefinition.pk);
  }

  private openEditDefinitionModal(): void {
    this.modalController
      .create({
        component: AddEditAlertDefinitionModalPage,
        showBackdrop: true,
        componentProps: { alertDefinition: <AlertDefinition>JSON.parse(JSON.stringify(this.alertDefinition)) },
      })
      .then((modal) => {
        modal.present();
        modal.style.display = 'flex';
      });
  }
}
