<ng-container *ngIf="vm.droplet && vm.controller">
  @if (isUnitActive) {
    <app-droplet-notification
      [uuid]="vm.droplet.overview.uuid"
      [controller]="vm.controller"
      (onResyncClick)="handleNotificationResyncConfigClick()"
    ></app-droplet-notification>
  }

  <!-- controller details -->
  <app-droplet-item-detail
    [droplet]="vm.droplet"
    [controller]="vm.controller"
    (onEditClick)="handleEditClick($event)"
    (onRemoveClick)="handleRemoveClick($event)"
    [isUnitActive]="isUnitActive"
  ></app-droplet-item-detail>
</ng-container>
