import { Component, Input } from '@angular/core';

export type MetricInfoProperties = {
  metricName: string;
  metricColor: string;
  metricAbbreviation: string;
};

@Component({
  selector: 'app-unit-analytic-legend',
  templateUrl: './unit-analytic-legend.component.html',
  styleUrls: ['./unit-analytic-legend.component.scss'],
})
export class UnitAnalyticLegendComponent {
  @Input()
  set info(info: Array<MetricInfoProperties>) {
    this.metrics = info;
  }
  public metrics: Array<MetricInfoProperties> = [];
  constructor() {}
}
