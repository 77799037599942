<ion-item lines="none">
  <ion-text>
    <h2>{{ 'VirtualPowerPlant.ControlGroupSchedules' | translate }} <ion-icon name="albums"></ion-icon></h2>
    <p>{{ 'VirtualPowerPlant.SchedulesDesc' | translate }}</p>
  </ion-text>
  <app-create-button
    slot="end"
    *ifHasPermission="[PermissionKey.VPP_ADD_SCHEDULE]"
    (click)="openVppCreateControlGroupModal()"
  ></app-create-button>
</ion-item>
<div class="ion-padding-horizontal" *ngIf="dataSource != undefined">
  <ion-searchbar
    class="base-input mb-10"
    showCancelButton="never"
    placeholder="Filter"
    autocomplete="on"
    [debounce]="300"
    (ionInput)="applyFilter($event.target.value)"
  ></ion-searchbar>
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z8 ion-margin-bottom">
    <ng-container matColumnDef="name">
      <th style="width: 20%" class="ion-text-left" mat-header-cell *matHeaderCellDef>
        {{ 'VirtualPowerPlant.ControlGroup' | translate }}
      </th>
      <!-- mat-sort-header -->
      <td mat-cell *matCellDef="let element">{{ element.controlGroup.name }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th class="ion-text-left" mat-header-cell *matHeaderCellDef>{{ 'VirtualPowerPlant.Schedule' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.schedule.name }}</td>
    </ng-container>
    <ng-container matColumnDef="controlPriority">
      <th class="ion-text-left" mat-header-cell *matHeaderCellDef>
        {{ 'VirtualPowerPlant.ControlPriority' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.controlPriority }}</td>
    </ng-container>
    <ng-container matColumnDef="controlValue">
      <th class="ion-text-left" mat-header-cell *matHeaderCellDef>
        {{ 'VirtualPowerPlant.ControlValue' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.controlValue }}</td>
    </ng-container>
    <ng-container matColumnDef="controlName">
      <th class="ion-text-left" mat-header-cell *matHeaderCellDef>{{ 'VirtualPowerPlant.ControlName' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.meritOrder?.control_code }}</td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th style="width: 11%" class="ion-text-left" mat-header-cell *matHeaderCellDef>
        {{ 'VirtualPowerPlant.Type' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.auto"> {{ 'VirtualPowerPlant.Auto' | translate }} </span>
        <span *ngIf="!element.auto"> {{ 'VirtualPowerPlant.Manual' | translate }} </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th style="width: 17%" mat-header-cell *matHeaderCellDef>{{ 'VirtualPowerPlant.Action' | translate }}</th>
      <td class="ion-text-center" mat-cell *matCellDef="let element">
        <ion-button
          *ifHasPermission="[PermissionKey.VPP_DELETE_CONTROLGROUPSCHEDULE]"
          color="danger"
          fill="outline"
          (click)="presentAlertConfirmSchedule(element)"
        >
          <ion-icon slot="icon-only" name="remove"></ion-icon>
        </ion-button>
        <ion-button
          *ifHasPermission="[PermissionKey.VPP_CHANGE_CONTROLGROUPSCHEDULE]"
          class="ml-5"
          color="button-primary"
          fill="outline"
          (click)="openVppControlGroupScheduleCreateModalForEdit(element)"
        >
          <ion-icon slot="icon-only" name="create"></ion-icon>
        </ion-button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="example-element-detail">
          <ion-row style="width: 100%">
            <ion-col>
              <div class="ion-margin-top ion-margin-bottom divGrid">
                <h4 class="ion-text-capitalize">{{ 'VirtualPowerPlant.ScheduleDetail' | translate }}</h4>
                <ion-label>
                  {{ 'General.Name' | translate }}: <strong>{{ element.schedule.name }}</strong>
                </ion-label>
                <ion-label>
                  {{ 'VirtualPowerPlant.Description' | translate }}: <strong>{{ element.schedule.description }}</strong>
                </ion-label>

                <div class="divGrid" *ngIf="element.meritOrder != null && !element.auto">
                  <h4 class="ion-text-capitalize ion-margin-top">
                    {{ 'VirtualPowerPlant.ControlDetail' | translate }}
                  </h4>
                  <ion-label>
                    {{ 'VirtualPowerPlant.Control' | translate }}:
                    <strong>{{ element.meritOrder.control_code }}</strong>
                  </ion-label>
                  <ion-label>
                    {{ 'VirtualPowerPlant.Order' | translate }}: <strong>{{ element.meritOrder.order }}</strong>
                  </ion-label>
                  <ion-label>
                    {{ 'VirtualPowerPlant.Description' | translate }}:
                    <strong>{{ element.meritOrder.control_description }}</strong>
                  </ion-label>
                  <ion-label>
                    {{ 'VirtualPowerPlant.DeviceClass' | translate }}:
                    <strong>{{ element.meritOrder.device_class }}</strong>
                  </ion-label>
                  <ion-label>
                    {{ 'VirtualPowerPlant.DemandType' | translate }}:
                    <strong>{{ element.meritOrder.demand_type }}</strong>
                  </ion-label>
                </div>
              </div>
            </ion-col>
            <ion-col *ifHasPermission="[PermissionKey.VPP_VIEW_CONTROLGROUPSCHEDULEPOLICY]">
              <div class="ion-margin-top ion-margin-bottom divGrid">
                <h4 class="ion-text-capitalize">{{ 'VirtualPowerPlant.Policy' | translate }}</h4>
                <div class="divGrid" *ngFor="let policy of element.policies">
                  <ion-label>
                    <strong>{{ policy.name }}</strong>
                  </ion-label>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay" class="scheduleTableHeader"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplay"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="rowClicked(element)"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="VPP_TABLE_PAGE_SIZE_OPTIONS" class="ion-margin-top"></mat-paginator>
</div>
