<ion-card class="p-20 m-0 card-design">
  <ion-grid class="p-0">
    <ion-row class="p-0">
      <ion-col size="3" class="name-value-div p-0">
        <div class="text-dark fs-13">
          {{ name | translate }}
        </div>
        <div class="fs-16">
          {{ latestValue | number: '1.0-3' }}<span *ngIf="total$ | async as total">/{{ total }} </span>
        </div>
      </ion-col>
      <ion-col size="9" class="p-0">
        <div *ngIf="showLoader" class="loader"></div>
        <div #widGraph></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-card>
