import { Component, OnDestroy, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VppDispatchService } from '../../services/virtualPowerPlants/dispatch/vpp-dispatch.service';
import { combineLatest, Subscription } from 'rxjs';
import { isVppDemandTypeFcas, isVppModeFcas } from '../../classes/vpp/vpp-demand-type-mode.model';
import { VppDispatchHistoryDisplay, VppDispatchType } from '../../classes/vpp/vpp-dispatch.model';
import { VppOverviewFacadeService } from '../../services/virtualPowerPlants/facades/overview/vpp-overview-facade.service';
import { IsPlatformValues } from '@class/commons/is-platform-values';

@Component({
  selector: 'app-vpp-operation-history-table',
  templateUrl: './vpp-operation-history-table.component.html',
  styleUrls: ['./vpp-operation-history-table.component.scss'],
})
export class VppOperationHistoryTableComponent extends IsPlatformValues implements OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public readonly LOCALE_OPTION = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  };
  public readonly columnsToDisplay = [
    'group',
    'order',
    'controlDescription',
    'dispatchType',
    'startTime',
    'endTime',
    // 'policy',
    'schedule',
    'device',
    'user',
  ]; //'avail', 'dispatch',
  public dataSource: MatTableDataSource<VppDispatchHistoryDisplay>;
  public searchValue = '';
  public readonly VppDispatchType = VppDispatchType;
  public expandedElement: VppDispatchHistoryDisplay;
  private serviceSubscription: Subscription;

  constructor(
    private vppOverviewFacade: VppOverviewFacadeService,
    private vppDispatchService: VppDispatchService,
  ) {
    super();
    this.serviceSubscription = combineLatest([
      this.vppDispatchService.dispatchPast$,
      this.vppOverviewFacade.vppOverviewModeDemandType$,
    ]).subscribe(([dispatches, modeAndDemandType]) => {
      this.searchValue = '';
      const dispatchesOfCurrentModeDemandType = dispatches.filter((dispatch) => {
        //currently we want to show both fcas raise and lower demand responses together if fcas mode
        const isFcas = isVppModeFcas(modeAndDemandType.mode) && isVppDemandTypeFcas(dispatch.demandType);
        return isFcas || dispatch.demandType === modeAndDemandType.demandType;
      });
      this.dataSource = new MatTableDataSource(dispatchesOfCurrentModeDemandType);
      if (this.sort) {
        this.dataSource.sort = this.sort;
      }
      if (this.paginator) this.dataSource.paginator = this.paginator;
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  rowClicked(element: VppDispatchHistoryDisplay): void {
    this.expandedElement = this.expandedElement === element ? null : element;
  }

  ngOnDestroy(): void {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
  }
}
