import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { filter, map, pairwise, startWith, switchMap, take, tap } from 'rxjs/operators';
import { Unit } from '@class/commons/unit.model';
import { VppMarketGetPayload } from '../../classes/vpp/vpp-markets.model';
import { adaptVppToEditData, VppEditFormData, VppPatchPayload, VppThresholdType } from '../../classes/vpp/vpp.model';
import { TranslationsService } from '../../services/common/translations.service';
import { UnitsService } from '../../services/units/units.service';
import { VirtualPowerPlantsService } from '../../services/virtualPowerPlants/virtual-power-plants.service';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { combineLatest } from 'rxjs';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { PermissionsService } from '@service/permissions/permissions.service';
interface ControlTargetFormGroup {
  thresholdType: VppThresholdType;
  [VppThresholdType.PRICE]?: {
    market: string;
    maxPrice: number;
    minPrice: number;
  };
  [VppThresholdType.POWER]?: {
    referenceUnitName: string;
    referenceUnitId: string;
    refDeviceMetricKey: string;
    refDeviceMetricName: string;
    minDemand: number;
    maxDemand: number;
  };
  takeOffs?: { unitId: string; unitName: string; deviceId: string; deviceName: string }[];
}
@Component({
  selector: 'app-vpp-setting-control-target',
  templateUrl: './vpp-setting-control-target.component.html',
  styleUrls: ['./vpp-setting-control-target.component.scss'],
})
export class VppSettingControlTargetComponent {
  vpp: VppEditFormData;
  readonly VppThresholdType = VppThresholdType;
  readonly PermissionKey = PermissionKey;
  availableMarkets: VppMarketGetPayload[];
  form: UntypedFormGroup;
  units: Unit[];

  constructor(
    private unitsService: UnitsService,
    private vppService: VirtualPowerPlantsService,
    private trans: TranslationsService,
    private alertController: AlertController,
    private toastController: ToastController,
    private formBuilder: UntypedFormBuilder,
    private modalController: ModalController,
    private permissionsService: PermissionsService,
  ) {
    combineLatest([this.vppService.vppSelected$, this.unitsService.allUnits$])
      .pipe(
        filter(([vpp]) => vpp != null),
        switchMap(([vpp, units]) => {
          this.units = units;
          this.vpp = vpp;
          const editData = adaptVppToEditData(vpp);
          this.form = this.initialiseForm(editData);
          return this.form.valueChanges.pipe(
            startWith(this.form.value),
            pairwise(),
            map((val) => {
              return { valueChanges: val, editData };
            }),
          );
        }),
        tap(({ valueChanges: [oldValue, newValue], editData }) => {
          this.handleValueChanges(oldValue, newValue, editData);
        }),
      )
      .subscribe();

    this.fetchMarkets();
  }

  private initialiseForm(vpp: VppEditFormData): UntypedFormGroup {
    const formDisabled = this.isFormDisabled();
    const form = this.formBuilder.group({
      thresholdType: [{ value: vpp.thresholdType, disabled: formDisabled }, Validators.required],
    });
    this.updateFormGroupGivenThreshold(form, vpp, vpp.thresholdType, formDisabled);
    return form;
  }

  private handleValueChanges(
    oldValues: ControlTargetFormGroup,
    newValues: ControlTargetFormGroup,
    vpp: VppEditFormData,
  ): void {
    if (oldValues.thresholdType != newValues.thresholdType) {
      const formDisabled = this.isFormDisabled();
      this.updateFormGroupGivenThreshold(this.form, vpp, newValues.thresholdType, formDisabled);
      return;
    }
  }

  private async updateFormGroupGivenThreshold(
    parentFormGroup: UntypedFormGroup,
    vpp: VppEditFormData,
    selectedThreshold: VppThresholdType,
    formDisabled: boolean,
  ): Promise<void> {
    Object.values(VppThresholdType).reduce((formGroup: UntypedFormGroup, type: VppThresholdType) => {
      if (selectedThreshold !== type) formGroup.removeControl(type);
      return formGroup;
    }, parentFormGroup);

    switch (selectedThreshold) {
      case VppThresholdType.POWER:
        if (!parentFormGroup.contains(VppThresholdType.POWER))
          this.addPowerThresholdFormGroup(parentFormGroup, vpp, formDisabled);
        break;
      case VppThresholdType.PRICE:
        if (!parentFormGroup.contains(VppThresholdType.PRICE))
          this.addPriceThresholdFormGroup(parentFormGroup, vpp, formDisabled);
        break;

      case VppThresholdType.SCADA:
        if (!parentFormGroup.contains(VppThresholdType.SCADA))
          this.addScadaThresholdFormGroup(parentFormGroup, vpp, formDisabled);
        break;
    }
  }

  private async addPowerThresholdFormGroup(
    parentFormGroup: UntypedFormGroup,
    vpp: VppEditFormData,
    formDisabled: boolean,
  ) {
    const referenceUnitName = this.findUnitWithId(vpp.referenceUnit)?.name;
    const { key, name } = await this.getDeviceMetricCombo(
      vpp?.referenceUnit,
      vpp?.referenceDevice,
      vpp?.referenceMetric,
    );
    const powerFormGroup = this.formBuilder.group({
      referenceUnitName: [{ value: referenceUnitName, disabled: formDisabled }],
      referenceUnitId: [{ value: vpp.referenceUnit, disabled: formDisabled }],
      refDeviceMetricKey: [{ value: key, disabled: formDisabled }],
      refDeviceMetricName: [{ value: name, disabled: formDisabled }],
      minDemand: [{ value: vpp.minDemand, disabled: formDisabled }, Validators.required],
      maxDemand: [{ value: vpp.maxDemand, disabled: formDisabled }, Validators.required],
    });
    parentFormGroup.addControl(VppThresholdType.POWER, powerFormGroup);
  }

  private addPriceThresholdFormGroup(parentFormGroup: UntypedFormGroup, vpp: VppEditFormData, formDisabled: boolean) {
    const priceFormGroup = this.formBuilder.group({
      market: [{ value: vpp.marketUuid, disabled: formDisabled }, Validators.required],
      minPrice: [{ value: vpp.minPrice, disabled: formDisabled }, Validators.required],
      maxPrice: [{ value: vpp.maxPrice, disabled: formDisabled }, Validators.required],
    });
    parentFormGroup.addControl(VppThresholdType.PRICE, priceFormGroup);
  }

  private async addScadaThresholdFormGroup(
    parentFormGroup: UntypedFormGroup,
    vpp: VppEditFormData,
    formDisabled: boolean,
  ) {
    const scadaFormGroup = new UntypedFormArray(
      vpp?.scada?.map((takeoff) => {
        const selectedUnit = this.findUnitWithId(takeoff.unitId);
        const selectedDeviceName = selectedUnit?.devices?.find((device) => device.id === takeoff.deviceId)?.full_name;

        return new UntypedFormGroup({
          unitId: new UntypedFormControl({ value: takeoff.unitId, disabled: formDisabled }, Validators.required),
          deviceId: new UntypedFormControl({ value: takeoff.deviceId, disabled: formDisabled }, Validators.required),
          unitName: new UntypedFormControl({ value: selectedUnit?.name, disabled: formDisabled }, Validators.required),
          deviceName: new UntypedFormControl(
            { value: selectedDeviceName, disabled: formDisabled },
            Validators.required,
          ),
        });
      }) ?? [],
    );
    parentFormGroup.addControl(VppThresholdType.SCADA, scadaFormGroup);
  }

  private async getDeviceMetricCombo(
    refUnitId: string,
    refDeviceId: string,
    refMetricId: string,
  ): Promise<{ key: string; name: string }> {
    if (refUnitId == null || refDeviceId == null || refMetricId == null) return { key: null, name: null };
    try {
      const unitMetricsList = await this.vppService.unitMetrics(refUnitId);
      if (unitMetricsList.apiCallFailed) {
        throw new Error();
      }
      const vppDeviceMetricIdCombination = refDeviceId + '_' + refMetricId;
      const matchingCombo = unitMetricsList.metrics.find(
        (metric) => metric.deviceMetricIdCombination == vppDeviceMetricIdCombination,
      );
      return {
        key: matchingCombo?.deviceMetricIdCombination,
        name: matchingCombo?.name,
      };
    } catch (err) {
      console.error(err);
      this.presentAlertSimpleOk('Error', null, 'Failed to fetch list of metrics associated with selected unit');
      return null;
    }
  }

  private async fetchMarkets(): Promise<void> {
    this.availableMarkets = await this.vppService.vppMarketsApi();
  }

  async showFilterSelectForPowerUnits(): Promise<void> {
    const { SelectReferenceUnit } = this.trans.instant('VirtualPowerPlant.CreateVPP');
    const unitFormGroup = this.form.controls[VppThresholdType.POWER] as UntypedFormGroup;
    const currentFormUnitName = unitFormGroup.get('referenceUnitName').value;
    const unitNames = this.units.map((unit) => unit.name);
    const selectedUnitName = await this.showFilterSelect(unitNames, SelectReferenceUnit, currentFormUnitName);
    if (selectedUnitName == null) return;
    const selectedUnitId = this.units?.find((unit) => unit.name === selectedUnitName)?.id;
    this.form?.patchValue({
      [VppThresholdType.POWER]: {
        referenceUnitId: selectedUnitId,
        referenceUnitName: selectedUnitName,
        refDeviceMetricKey: null,
        refDeviceMetricName: null,
      },
    });
  }

  async showFilterSelectForPowerMetric(): Promise<void> {
    const { SelectMetric } = this.trans.instant('VirtualPowerPlant.CreateVPP');
    const unitFormGroup = this.form.controls[VppThresholdType.POWER] as UntypedFormGroup;
    if (unitFormGroup.get('referenceUnitName').value) {
      const currentFormMetricName = unitFormGroup.get('refDeviceMetricKey').value;
      const currentFormUnitId = unitFormGroup.get('referenceUnitId').value;
      const unitMetricsList = await this.vppService.unitMetrics(currentFormUnitId);
      if (unitMetricsList.apiCallFailed) {
        throw new Error('Failed to fetch reference unit metrics');
      }
      const deviceMetricNames = unitMetricsList.metrics.map((metric) => metric.name);
      const selectedMetricName = await this.showFilterSelect(deviceMetricNames, SelectMetric, currentFormMetricName);
      if (selectedMetricName == null) return;
      const selectedKey = unitMetricsList.metrics.find((metric) => metric.name === selectedMetricName)
        ?.deviceMetricIdCombination;
      this.form?.patchValue({
        [VppThresholdType.POWER]: { refDeviceMetricKey: selectedKey, refDeviceMetricName: selectedMetricName },
      });
    }
  }

  async showFilterSelectForScadaUnits(index: number): Promise<void> {
    const scadaFormArray = this.form.controls[VppThresholdType.SCADA] as UntypedFormArray;
    const takeoffValues = scadaFormArray.value[index];
    const unitNames = this.units.map((unit) => unit.name);
    const selectedUnitName = await this.showFilterSelect(
      unitNames,
      'Select a take-off unit',
      takeoffValues.selectedUnitName,
    );
    if (selectedUnitName == null) return;
    const selectedUnitId = this.units?.find((unit) => unit.name === selectedUnitName)?.id;
    scadaFormArray.controls[index].patchValue({
      unitId: selectedUnitId,
      unitName: selectedUnitName,
      deviceId: null,
      deviceName: null,
    });
  }

  async showFilterSelectForScadaDevice(index: number): Promise<void> {
    const scadaFormArray = this.form.controls[VppThresholdType.SCADA] as UntypedFormArray;
    const takeoffValues = scadaFormArray.value[index];
    const selectedUnit = this.findUnitWithId(takeoffValues.unitId);
    if (selectedUnit == null) return;
    const unitDevices = selectedUnit.devices;
    const unitDevicesNames = unitDevices.map((device) => device.full_name);

    const selectedDeviceName = await this.showFilterSelect(
      unitDevicesNames,
      'Select a take-off unit device',
      takeoffValues.selectedDeviceName,
    );
    if (selectedDeviceName == null) return;
    const selectedDeviceId = unitDevices?.find((device) => device.full_name === selectedDeviceName)?.id;
    scadaFormArray.controls[index].patchValue({
      ...takeoffValues,
      deviceId: selectedDeviceId,
      deviceName: selectedDeviceName,
    });
  }

  async showFilterSelect(data: string[], header: string, initialData?: string): Promise<string> {
    const modal = await this.modalController.create({
      component: FilterSelectComponent,
      cssClass: 'transparent-modal',
      componentProps: {
        header,
        data,
        initialData,
      },
    });
    await modal.present();
    return (await modal.onDidDismiss()).data;
  }

  addTakeOffPoint(): void {
    (this.form.controls[VppThresholdType.SCADA] as UntypedFormArray)?.push(
      this.formBuilder.group({
        unitId: new UntypedFormControl(null, Validators.required),
        deviceId: new UntypedFormControl(null, Validators.required),
        unitName: new UntypedFormControl(null, Validators.required),
        deviceName: new UntypedFormControl(null, Validators.required),
      }),
    );
  }
  removeTakeOff(index: number): void {
    (this.form.controls[VppThresholdType.SCADA] as UntypedFormArray)?.removeAt(index);
  }

  async submit(data: ControlTargetFormGroup): Promise<void> {
    const { UpdateVPP, CreateVPP } = this.trans.instant('VirtualPowerPlant');
    try {
      const { id } = await this.vppService.vppSelected$.pipe(take(1)).toPromise();
      const payload = this.adaptVppsEditToPutPayload(data);
      const toastMsg = UpdateVPP.VppUpdatedSuccessfully;
      await this.vppService.patchVppDetails(payload, id);
      await this.presentToast(toastMsg);
    } catch (error) {
      console.error(error);
      const msg = error?.status
        ? `${CreateVPP.VppFail.CreateMsg} ${error.status} ${error.statusText}. `
        : CreateVPP.VppFail.CreateMsg + error;
      await this.presentAlertSimpleOk(CreateVPP.VppFail.Header, CreateVPP.VppFail.CreateSubHeader, msg);
    }
  }

  private adaptVppsEditToPutPayload(current: ControlTargetFormGroup): VppPatchPayload {
    const { thresholdType } = current;

    switch (thresholdType) {
      case VppThresholdType.POWER:
        const power = current[VppThresholdType.POWER] ?? null;
        const splitKeys = power?.refDeviceMetricKey?.split('_');
        const referenceDevice = splitKeys?.[0];
        const referenceMetric = splitKeys?.[1];
        return {
          threshold_type: thresholdType,
          reference_device: referenceDevice,
          reference_metric: referenceMetric,
          reference_unit: power.referenceUnitId,
          admt_max_demand: power.maxDemand,
          admt_min_demand: power.minDemand,
        };
      case VppThresholdType.PRICE:
        const price = current[VppThresholdType.PRICE] ?? null;
        return {
          threshold_type: thresholdType,
          spot_max_price: price.maxPrice,
          spot_min_price: price.minPrice,
          market_region: price.market,
        };
      case VppThresholdType.SCADA:
        const scada = current[VppThresholdType.SCADA] ?? null;
        const adaptedScada =
          scada?.map((takeOff) => {
            return { unit_id: takeOff.unitId, device_id: takeOff.deviceId };
          }) ?? [];
        return {
          threshold_type: thresholdType,
          scada: adaptedScada,
        };
      default:
        throw 'Unknown reference type';
    }
  }

  private async presentAlertSimpleOk(header: string, subheader: string, message: string): Promise<void> {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }
  private async presentToast(message: string, duration = 3000): Promise<void> {
    const messageToast = await this.toastController.create({
      message: message,
      duration: duration,
      position: 'top',
    });

    await messageToast.present();
  }

  private findUnitWithId(id: string): Unit {
    return this.units?.find((unit) => unit.id === id);
  }

  compareUnitFunc(unitIdA: string, unitIdB: string): boolean {
    return unitIdA == unitIdB;
  }

  compareMetricFunc(metricA: string, metricB: string): boolean {
    return metricA == metricB;
  }

  private isFormDisabled(): boolean {
    // if you have change permission the send false as you don't want to disable the form if you have perms
    return !this.permissionsService.any([PermissionKey.VPP_CHANGE_VPP]);
  }
}
