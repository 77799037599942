import { Injectable } from '@angular/core';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '../common/api-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramsService {
  constructor(private api: ApiWrapper) {}

  /**
   *
   * @param unitId
   * @param data
   *
   * some responses for 200
   * {
   *  "Status": "Not Ready",
   *  "Code": 200,
   *  "Data": {
   *     "LFDI": true,
   *     "nmi": false
   *    },
   *  "Msg": ""
   * }
   *
   * {
   *  "Status": "Registered",
   *  "Code": 200,
   *  "Data": {
   *     "LFDI": true,
   *     "nmi": false
   *    },
   *  "Msg": "success"
   * }
   *
   * for 400 - means all the requirements are okay, something wrong on the DNSP server side
   * {
   *  "Status": "Registration Failed",
   *  "Code": 400,
   *  "Data": {
   *     "LFDI": true,
   *     "nmi": true
   *    },
   *  "Msg": "NMI does not exist for one of the non-aggregator devices"
   * }
   *
   * {
   *  "Status": "Registration Failed",
   *  "Code": 400,
   *  "Data": {
   *     "LFDI": true,
   *     "nmi": true
   *    },
   *  "Msg": "NMI Checksum is invalid"
   * }
   *
   */
  public programRegistration(unitId: string, data: { program_name: string }) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/units/${unitId}/register/`,
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }
}
