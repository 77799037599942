import { Component, OnInit, ViewChild } from '@angular/core';
import { InstallerService } from '../../../services/installer/installer.service';
import { PortfoliosService } from '../../../services/portfolios/portfolios.service';
import { ModalController } from '@ionic/angular';
import { AddressComponent, Address } from '../../../components/address/address.component';
import { REGEX } from '@class/commons/constants';

@Component({
  selector: 'app-create-unit-or-portfolio-modal',
  templateUrl: './create-unit-or-portfolio-modal.page.html',
  styleUrls: ['./create-unit-or-portfolio-modal.page.scss'],
})
export class CreateUnitOrPortfolioModalPage implements OnInit {
  unitCreation: boolean; // this determines how accurate the address needs to be
  selectedPortfolio;
  portfolioName = '';
  nmi = '';
  readonly nmiRegex = REGEX.NMI;
  titleParam = { value: 'Portfolio' };
  creationString = 'Portfolio';
  disableCreate = false;
  @ViewChild(AddressComponent, { static: false }) addressComponent: AddressComponent;

  constructor(
    private installer: InstallerService,
    private portfolioService: PortfoliosService,
    private viewCtrl: ModalController,
  ) {}

  ngOnInit(): void {
    if (this.unitCreation) this.titleParam.value = 'Unit';
  }

  async createPortfolio(): Promise<void> {
    try {
      const response = (await this.installer.createPortfolio(
        this.portfolioName,
        this.addressComponent.address.portfolio,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      )) as { data: any };
      this.portfolioService.allPortfolios();
      this.installer.selectedPortfolio.portfolio = response.data;
      this.viewCtrl.dismiss(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  async createUnit(): Promise<void> {
    try {
      if (this.selectedPortfolio) {
        const portfolioUUID = this.selectedPortfolio.uuid;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response: any = await this.installer.createUnit(
          this.addressComponent.address.unit,
          this.addressComponent.google_places_result,
          this.portfolioName,
          portfolioUUID,
          [],
          this.nmi.toString(),
        );

        this.installer.loadSelectedPortfolioAndApplyPerms(this.selectedPortfolio);
        this.installer.selectedUnit = response.data;
        this.portfolioService.getSelectedPortfolioData(portfolioUUID);
        this.viewCtrl.dismiss(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  isCreateButtonDisabled(): boolean {
    let createButtonDisabled = false;

    if (this.unitCreation) {
      // creating a unit
      if (
        this.portfolioName === null ||
        this.portfolioName.length <= 0 ||
        this.addressComponent.address.unit.address === null ||
        this.addressComponent.address.unit.address.length <= 0 ||
        this.addressComponent.address.unit.city === null ||
        this.addressComponent.address.unit.city.length <= 0 ||
        this.addressComponent.address.unit.state === null ||
        this.addressComponent.address.unit.state.length <= 0 ||
        this.addressComponent.address.unit.country === null ||
        this.addressComponent.address.unit.country.length <= 0 ||
        this.addressComponent.address.unit.postcode === null ||
        this.addressComponent.address.unit.postcode.length <= 0 ||
        this.addressComponent.address.unit.latitude === 0 ||
        this.addressComponent.address.unit.longitude === 0 ||
        (this.isNmiEnabled() && (this.nmi === null || this.nmi.length <= 0 || !this.nmiRegex.test(this.nmi)))
      ) {
        createButtonDisabled = true;
      }
    } else {
      // creating a portfolio
      if (!this.addressComponent) {
        /**
         * when this function first runs there is a chance that the
         * addressComponent has not finished initialising yet. I have only observed this
         * when creating a portfolio. This accommodates for that
         */
        createButtonDisabled = true;
      } else if (this.addressComponent.googleAddress != Address.MANUAL_ENTRY) {
        // not using manual entry
        if (
          this.portfolioName === null ||
          this.portfolioName.length <= 0 ||
          this.addressComponent.address.portfolio === null ||
          this.addressComponent.address.portfolio.length <= 0
        )
          createButtonDisabled = true;
      } else if (
        // manual entry
        this.portfolioName === null ||
        this.portfolioName.length <= 0 ||
        this.addressComponent.manualAddress.streetNo === null ||
        this.addressComponent.manualAddress.streetNo.length <= 0 ||
        this.addressComponent.manualAddress.streetName === null ||
        this.addressComponent.manualAddress.streetName.length <= 0 ||
        this.addressComponent.manualAddress.suburbName === null ||
        this.addressComponent.manualAddress.suburbName.length <= 0 ||
        this.addressComponent.manualAddress.postCode === null ||
        this.addressComponent.manualAddress.postCode.length <= 0 ||
        this.addressComponent.manualAddress.state === null ||
        this.addressComponent.manualAddress.state.length <= 0 ||
        this.addressComponent.manualAddress.country === null ||
        this.addressComponent.manualAddress.country.length <= 0
      ) {
        createButtonDisabled = true;
      }
    }
    return createButtonDisabled || this.disableCreate;
  }

  isNmiEnabled(): boolean {
    return (
      this.addressComponent?.address?.unit?.country?.toLowerCase() === 'australia' ||
      this.addressComponent?.address?.unit?.country?.toLowerCase().includes('australia')
    );
  }

  async handleSubmit(): Promise<void> {
    this.disableCreate = true;
    await (this.unitCreation ? this.createUnit() : this.createPortfolio());
    this.disableCreate = false;
  }

  dismiss(): void {
    this.viewCtrl.dismiss();
  }
}
