import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CostState, UnitCostService } from '../../services/units/unit-cost.service';
import { Observable } from 'rxjs';
import { IsPlatformValues } from '@class/commons/is-platform-values';
import { SkeletonPlaceholder } from '@class/commons/constants';

@Component({
  selector: 'app-unit-costs',
  templateUrl: './unit-costs.component.html',
  styleUrls: ['./unit-costs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// should have converted this to use Luxon Datetime
// but this will do for now
export class UnitCostsComponent extends IsPlatformValues {
  vm$: Observable<CostState> = this.unitCostService.vm$;
  minDate = new Date(Date.UTC(2017, 0, 1));
  maxDate = new Date();
  ionMinDate = this.minDate.toISOString(); // This is a date starting on a Sunday to generate week and month names.
  ionMaxDate: string;
  nextDateDisabled = true;
  prevDateDisabled = false;
  readonly SkeletonPlaceholder = SkeletonPlaceholder;

  constructor(private unitCostService: UnitCostService) {
    super();
    this.maxDate.setDate(1);
    this.maxDate.setHours(0, 0, 0, 0);
    this.ionMaxDate = this.getIsoYearMonthDate(this.maxDate);
  }

  // until we implement luxon datetime, this will do for now
  getIsoYearMonthDate(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  }

  setDate(date: Date) {
    if (date.getTime() > this.maxDate.getTime()) {
      date.setFullYear(this.maxDate.getFullYear());
      date.setMonth(this.maxDate.getMonth());
    }
    if (date.getTime() < this.minDate.getTime()) {
      date.setFullYear(this.minDate.getFullYear());
      date.setMonth(this.minDate.getMonth());
    }

    this.nextDateDisabled =
      date.getFullYear() == this.maxDate.getFullYear() && date.getMonth() == this.maxDate.getMonth();
    this.prevDateDisabled =
      date.getFullYear() == this.minDate.getFullYear() && date.getMonth() == this.minDate.getMonth();
    const newDate = new Date(date.getTime());
    newDate.setDate(1);
    this.unitCostService.updateCostDate(newDate);
  }

  onPrevious() {
    const { date } = this.unitCostService.getStateSnapshot();
    const newDate = new Date(date.getTime());
    newDate.setMonth(newDate.getMonth() - 1);
    this.setDate(newDate);
  }
  onNext() {
    const { date } = this.unitCostService.getStateSnapshot();
    const newDate = new Date(date.getTime());
    newDate.setMonth(newDate.getMonth() + 1);
    this.setDate(newDate);
  }
  dateChangedCalendar(dateString: string) {
    const newDate = new Date(dateString);
    this.setDate(newDate);
  }
}
