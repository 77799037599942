<ion-button
  (click)="handleClick()"
  color="button-primary"
  class="rect-button"
  expand="block"
  mode="ios"
  [disabled]="isDisabled"
>
  <span class="fs-16 ion-text-capitalize"> {{ 'General.Create' | translate }} </span>
  <ion-icon *ngIf="showIcon" class="ml-7 mr-10 lh-100-pct" src="assets/icons/svg/plus-in-white-circle.svg"></ion-icon>
</ion-button>
