import { Router } from '@angular/router';
import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { IsPlatformValues } from '@class/commons/is-platform-values';
import { HomeCardDataType } from '@class/commons/enums';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { Unit } from '@class/commons/unit.model';
import { Portfolio } from '@class/commons/portfolio.model';

@Component({
  selector: 'app-home-list',
  templateUrl: './home-list.component.html',
  styleUrls: ['./home-list.component.scss'],
})
export class HomeListComponent extends IsPlatformValues implements OnInit, AfterViewInit {
  @Input() data: Unit | Portfolio; // Data related to portfolio or unit
  @Output() isLoaded = new EventEmitter<null>();
  bgImg = '';
  readonly PermissionKey = PermissionKey;
  readonly HomeCardDataType = HomeCardDataType;

  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
    if (this.isUnit() && !this.IS_PLATFORM_DESKTOP) {
      if ((this.data as Unit).image) {
        this.bgImg = `background-image:  linear-gradient(86.36deg, #07414E 54.45%, rgba(0, 53, 47, 0) 99.74%), url("${(this.data as Unit).image}")  !important;`;
      } else {
        this.bgImg = `background-image: linear-gradient(45.86deg, #07414e 42.09%, rgba(0, 53, 47, 0) 90.49%), url('../../../../assets/rotated-logo.svg')  !important;`;
      }
    }
  }

  ngAfterViewInit(): void {
    this.isLoaded.emit();
  }

  isUnit(): boolean {
    return this.data.dataType === HomeCardDataType.UNIT;
  }

  Nameclick(): void {
    if (this.data.dataType === HomeCardDataType.PORTFOLIO) {
      this.router.navigateByUrl('/portfolio/' + this.data.uuid ?? this.data.id);
    } else if (this.data.dataType === HomeCardDataType.UNIT) {
      this.router.navigateByUrl('/unit/' + this.data.uuid);
    }
  }
}
