<ion-toolbar color="background-primary">
  <ion-buttons slot="start" class="icon-left" *ngIf="showBackButton">
    <ion-button (click)="handleBackClick()">
      <ion-icon [src]="backButtonIcon"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-title class="ion-text-center">{{ title }}</ion-title>
  <ion-buttons class="button-right" slot="end" *ngIf="showCancelButton">
    <ion-button color="button-primary" (click)="handleCancelClick()" class="cancel-button fs-16">
      {{ 'General.Cancel' | translate }}
    </ion-button>
  </ion-buttons>
</ion-toolbar>
